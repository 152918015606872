import React, { useState, useCallback, useRef } from "react";
import "../../Enquiry/Enquiry.css";
import "../../../Styles/Common.css";
import "../../../Styles/sideBar.css";
import refreshIcon from "../../../assets/refreshIcon.png";
import Sidebar from "../../../Pages/Sidebar";
import "../../../Styles/Pagination.css";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import * as ReactBootstrap from "react-bootstrap";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import { Label } from "@material-ui/icons";
import TopBar from "./TopBar";
import DownloadPanel from "../../../components/DownloadPanel/DownloadPanel";
import Badge from "react-bootstrap/Badge";

import {
  MenuProps,
  useStyles,
  specialities,
  qualifications,
} from "../../../components/multi-select";
import {
  List,
  ListItemText,
  MenuItem,
  Select,
  Checkbox,
  ListItemIcon,
  Drawer,
  Table,
  Tooltip,
  withStyles,
  Button,
  ListItem,
} from "@material-ui/core";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes";

// import Particles from "react-particles";
// import { loadFull } from "tsparticles";
// import socketIO from "socket.io-client";
import { useEffect } from "react";
import CustomLoader from "../../../components/CustomLoader";
import useKareTripEnquiry from "./useKareTripEnquiry.logic";
import KaretripAddLead, { CustomMenu } from "../AddLead/KaretripAddLead";
import { checkPermission } from "../../../util/permissionUtils";
import KaretripDownloadPanel from "../KaretripDownloadPanel";
import { red } from "@material-ui/core/colors";
import { FiBell } from "react-icons/fi";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import AssignToModal from "./AssignToModal";
// const socket = socketIO.connect("http://localhost:4000");

const openInNewTab = (enquiryId) => {
  window.open(`KaretripEnquiryDetail/${enquiryId}`, "_blank");
};

const tooltipStyles = {
  tooltip: {
    fontSize: "20px",
    borderRadius: "18px",
    boxShadow: "0 20px 80px 0",
    backgroundColor: "#FFFFE0",
    color: "black",
  },
};

const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

function NotificationCard({ item }) {
  const dateTime = `${moment(Date(item?.dateTime)).format(
    "MMMM DD"
  )} at ${moment(Date(item?.dateTime)).format("hh:mm a")}`;
  return (
    <div class="alert">
      <Table class="not">
        <tbody>
          <tr>
            <th>Enquiry Id</th>
            <td class="tdNot">{item?.userTxnId}</td>
          </tr>
          <tr>
            <th>Patient Name</th>
            <CustomTooltip
              title={` ${item?.patientName ? item?.patientName : ""}`}
            >
              <td
                class="tdNot"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {item?.patientName}
              </td>
            </CustomTooltip>
          </tr>
          {checkPermission("LEADS_ASSIGNED_FIELD") && (
            <tr>
              <th>Assigned to</th>
              <td class="tdNot">{item?.assignTo || "N/A"}</td>
            </tr>
          )}
          <tr>
            <th>Status</th>
            <CustomTooltip title={` ${item?.status ? item?.status : ""}`}>
              <td
                class="tdNot text-1"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                  color: "orange",
                  fontWeight: "bold",
                  textAlign: "initial",
                }}
              >
                {item?.status}
              </td>
            </CustomTooltip>
          </tr>
          <tr>
            <th>Call Date Time</th>
            <td class="tdNot dateColor">{item?.dateTime}</td>
          </tr>

          <div
            style={{
              display: "flex",
              position: "absolute",
              bottom: -15,
              justifyContent: "center",
              width: "100%",
              left: 0,
            }}
          >
            {/* {item?.callThisPatient == false && (
              <Button
                size="small"
                class="notButton"
                onClick={(e) => {
                  e.stopPropagation();
                  openInNewTab(item?.userTxnId);
                }}
              >
                call this patient
              </Button>
            )} */}
            {/* {item?.changeStatus == false && ( */}
            <Button
              size="small"
              class="notButton2"
              onClick={(e) => {
                e.stopPropagation();
                openInNewTab(item?.userTxnId);
              }}
            >
              change the status
            </Button>
            {/* )} */}
          </div>
        </tbody>
      </Table>
    </div>
  );
}

function KaretripEnquiry() {
  // const scrollRef = useRef("")
  const state = useKareTripEnquiry();
  const [showAddLead, setShowAddLead] = useState(false);
  const [showDownloadPanel, setShowDownloadPanel] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [currentIndex, setCurrentIndex] = useState(-1);

  // const handleClick = () => {
  //   if (scrollRef.current) {
  //     setCurrentIndex(-1);
  //     scrollRef.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //       inline: 'nearest',
  //     });
  //   }
  // };

  const border = {
    width: "100%",
    height: "100vh",
    overflow: "scroll",
  };
  if (!state.dataLoaded) {
    return <CustomLoader />;
  }

  const flexStyle = {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    color: "white",
  };
  if (!state.dataLoaded) {
    return <CustomLoader />;
  }

  return (
    <div style={border}>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ width: "150%", padding: 10 }}>
          <div className="sticky">
            <div className="main-subContainer">
              <TopBar />

              <div className="row border1">
                {/* {checkPermission("LEADS_PARTNERSHIP_STATISTICS") && (
                                    <div className="col cor5">
                                        <b>
                                            <p className="size1">
                                                {state.enquiryStats?.totalPartnerShip}
                                            </p>
                                        </b>
                                        <b>
                                            <p className="colour">Total Partnerships</p>
                                        </b>
                                    </div>
                                )} */}
              </div>
            </div>
            <br />
            <br />
            <br />

            {/* edit start */}
            <div
              className="row"
              style={{
                width: "100%",
                height: 70,
                marginLeft: 20,
                display: "flex",
              }}
            >
              <div style={{ width: "90%" }}>
                {checkPermission("LEADS_ADD_BUTTON") && (
                  <button
                    style={{ width: 120, height: 50 }}
                    onClick={() => {
                      setShowAddLead(!showAddLead);
                      setShowDownloadPanel(false);
                    }}
                  >
                    Create Lead
                  </button>
                )}
                <button
                  style={{ width: 80, height: 50, marginLeft: 20 }}
                  onClick={() => {
                    setShowAddLead(false);
                    setShowDownloadPanel(false);
                    state.refreshTable();
                  }}
                >
                  Refresh
                </button>
                <button
                  style={{ width: 200, height: 50, marginLeft: 20 }}
                  // onClick={() => state.downloadReport()}
                  onClick={() => {
                    state.clearAllFilter();
                  }}
                >
                  Clear Filter
                </button>
                {checkPermission("LEADS_REPORT_BUTTON") && (
                  <button
                    style={{ width: 200, height: 50, marginLeft: 20 }}
                    // onClick={() => state.downloadReport()}
                    onClick={() => {
                      setShowAddLead(false);
                      setShowDownloadPanel(!showDownloadPanel);
                    }}
                  >
                    Report
                  </button>
                )}

                <AssignToModal
                  activeData={state?.activeData}
                  setActiveData={state?.setActiveData}
                  kareBuddyList={state?.assignedUser.list}
                  postAssignTo={state?.postAssignTo}
                  // assignToKareBuddy={state?.assignToKareBuddy}
                />

                <blink>
                  <button
                    style={{ width: 200, height: 50, marginLeft: 20 }}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      state.loadAllNotifications(state.assignedAgentCode);
                      // state.loadAssignedUsers();
                    }}
                  >
                    <FiBell style={{ width: 20 }}></FiBell>
                    {/* Tasks {kareChatCount > 0 && <Badge bg="danger">{kareChatCount}</Badge>} */}
                    Notifications &nbsp;
                    <span>
                      <Badge bg="danger" class="taskCount">
                        {state.notificationAllCount}
                      </Badge>
                    </span>
                  </button>
                </blink>
              </div>
              <div style={{ width: "10%", justifyContent: "flex-end" }}>
                <div> Select No. of Datas </div>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 140,
                      height: 36,
                      border: `1px solid #00997c`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.selectedPageFilter ? state.selectedPageFilter : "20"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {state.pageFilters.map((value, index) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 160 }}
                            onClick={() => {
                              state.setSelectedPageFilter(value);
                            }}
                          >
                            {value}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            {/* edit end */}

            {showAddLead ? (
              <KaretripAddLead
                // socket={socket}
                onClicked={(openPopUp) => setShowAddLead(openPopUp)}
              />
            ) : null}
            {showDownloadPanel ? (
              <KaretripDownloadPanel
                ipReport
                onClicked={(openPopUp) => setShowDownloadPanel(openPopUp)}
              />
            ) : null}
            <div
              className="row scroll"
              style={{
                marginRight: 10,
              }}
            >
              <div>
                Showing {state.offset * state.selectedPageFilter} -{" "}
                {(state.offset + 1) * state.selectedPageFilter <
                state.totalCount
                  ? (state.offset + 1) * state.selectedPageFilter
                  : state.totalCount}{" "}
                out of {state.totalCount}
              </div>

              {checkPermission("LEADS_SL_NO_FIELD") && (
                <div className="col-1 m-0">
                  <b>Sl No.</b>
                </div>
              )}

              {checkPermission("LEADS_ENQUIRY_DATE_FIELD") && (
                <div className="col-1 m-0">
                  <b>Enquiry Date</b>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 140,
                        height: 36,
                        border: `1px solid #00997c`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {state.selectedEnquiryDateFilter
                        ? state.selectedEnquiryDateFilter
                        : "All"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {state.enquiryDateFilter.map((value, index) => {
                        return (
                          <>
                            <Dropdown.Item
                              key={index}
                              href="#"
                              style={{ width: 160 }}
                              onClick={() => {
                                state.setSelectedEnquiryDateFilter(value);
                                state.setReload(true);
                              }}
                            >
                              {value}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>

                  {state.selectedEnquiryDateFilter === "Choose Date" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 160,
                        height: 40,
                        borderRadius: 5,
                        marginBottom: 7,
                      }}
                    >
                      <label style={{ marginLeft: 10 }}>From</label>
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchEnquiryFromDate
                            ? state.searchEnquiryFromDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchEnquiryFromDate(date);
                          state.setReload(true);
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  ) : null}
                  {state.selectedEnquiryDateFilter === "Choose Date" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 160,
                        height: 40,
                        borderRadius: 5,
                      }}
                    >
                      <label style={{ marginLeft: 10 }}>To</label>
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchEnquiryToDate
                            ? state.searchEnquiryToDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchEnquiryToDate(date);
                          state.setReload(true);
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  ) : null}
                </div>
              )}

              <div className="col-1 m-0">
                <b>Patient Name</b>
                <br />
                <input
                  style={{
                    width: 150,
                    height: 36,
                    border: `1px solid #00997c`,
                    borderRadius: 5,
                    marginTop: 5,
                  }}
                  name="search"
                  placeholder="Search"
                  value={state.searchItem}
                  onChange={state.handleSearchItemChange}
                />
              </div>

              {checkPermission("LEADS_PHONE_NUMBER_FIELD") && (
                <>
                  <div className="col-1 m-0">
                    <b>Phone Number</b>
                    <br />
                    <input
                      style={{
                        width: 140,
                        height: 36,
                        border: `1px solid #00997c`,
                        borderRadius: 5,
                        marginTop: 5,
                      }}
                      name="phone"
                      placeholder="Search"
                      value={state.searchPhone}
                      onChange={state.handleSearchPhone}
                    />
                  </div>
                </>
              )}

              {/* {country} */}
              <div className="col-1 m-0">
                <b>Country</b>
                <br />
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 150,
                      height: 36,
                      border: `1px solid gray`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.selectedCountryList.name
                      ? state.selectedCountryList.name
                      : "All"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    as={CustomMenu}
                    style={{
                      maxHeight: 200,
                      overflowY: "scroll",
                      overflowX: "hidden",
                      width: 250,
                    }}
                  >
                    {state.countryList.map((value, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          href="#"
                          style={{ width: 200, maxHeight: 200 }}
                          onClick={() => {
                            state.handleCountry(value);
                          }}
                        >
                          {value.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="col-1 m-0">
                <b>Enquiry ID</b>
                <br />
                <input
                  style={{
                    width: 140,
                    height: 36,
                    border: `1px solid #00997c`,
                    borderRadius: 5,
                    marginTop: 5,
                  }}
                  name="phone"
                  placeholder="Search"
                  value={state.searchEnquiryId}
                  onChange={state.handleSearchEnquiryId}
                />
              </div>

              {checkPermission("LEADS_ASSIGNED_FIELD") && (
                <div className="col-1 m-0">
                  <b>Assigned To</b>
                  <br />
                  {checkPermission("LEADS_ASSIGNED_FIELD") && (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        style={{
                          width: 140,
                          height: 36,
                          border: `1px solid #00997c`,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {state.assignedUser.selected
                          ? state.assignedUser.selected
                          : "All"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          maxHeight: 200,
                          overflowY: "scroll",
                          overflowX: "hidden",
                          width: 150,
                        }}
                      >
                        {state.assignedUser.list.map(
                          ({ name, code }, index) => {
                            return (
                              <>
                                <Dropdown.Item
                                  key={index}
                                  href="#"
                                  style={{ width: 160 }}
                                  onClick={() => {
                                    state.handleAssignedToChange(code, name);
                                    state.setReload(true);
                                  }}
                                >
                                  {name}
                                </Dropdown.Item>
                              </>
                            );
                          }
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
              )}
              <div className="col-1 m-0">
                <b>Type</b>
                <div
                  style={{
                    width: 140,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid  #00997c",
                    borderRadius: 5,
                    marginTop: 8,
                  }}
                >
                  <ReactMultiselectCheckboxes
                    options={state.type}
                    getDropdownButtonLabel={state.getDropdownButtonLabel2}
                    value={state.selectedTypes}
                    onChange={(value, event) =>
                      state.handleSelectTypes(value, event)
                    }
                    setState={state.setSelectedTypes}
                  />
                </div>
              </div>
              <div className="col-1 m-0">
                <b>Treatment</b>
                <div
                  style={{
                    width: 140,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid  #00997c",
                    borderRadius: 5,
                    marginTop: 8,
                  }}
                >
                  <ReactMultiselectCheckboxes
                    options={state.treatmentData}
                    getDropdownButtonLabel={state.getDropdownButtonLabel}
                    value={state.selectedTreatments}
                    onChange={(value, event) =>
                      state.handleSelectTreatments(value, event)
                    }
                    setState={state.setSelectedTreatments}
                  />
                </div>
              </div>

              <div className="col-1 m-0">
                <b>Status</b>
                <div
                  style={{
                    width: 140,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid  #00997c",
                    borderRadius: 5,
                    marginTop: 8,
                  }}
                >
                  <ReactMultiselectCheckboxes
                    options={state.statusData}
                    placeholderButtonLabel={"Choose"}
                    getDropdownButtonLabel={state.getDropdownButtonLabel}
                    value={state.selectedStatuses}
                    onChange={(value, event) =>
                      state.handleSelectStatuses(value, event)
                    }
                    setState={state.setSelectedStatuses}
                  />
                </div>
              </div>
              {/* statusData search and dropdown section end*/}
              <div className="col-1 m-0">
                <b>FollowUp Date</b>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 140,
                      height: 36,
                      border: `1px solid #00997c`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.selectedFollowupDateFilter
                      ? state.selectedFollowupDateFilter
                      : "All"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {state.followUpDateFilter.map((value, index) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 160 }}
                            onClick={() => {
                              state.setSelectedFollowupDateFilter(value);
                              state.setReload(true);
                            }}
                          >
                            {value}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                {state.selectedFollowupDateFilter === "Choose Date" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #00997c",
                      width: 160,
                      height: 40,
                      borderRadius: 5,
                      marginBottom: 7,
                    }}
                  >
                    <label style={{ marginLeft: 10 }}>From</label>
                    <DatePicker
                      className="mySearchEnquiryUpDatePicker followUpdate"
                      selected={
                        state.searchFollowupFromDate
                          ? state.searchFollowupFromDate
                          : new Date()
                      }
                      onChange={(date) => {
                        state.setSearchFollowupFromDate(date);
                        state.setReload(true);
                      }}
                    />
                  </div>
                ) : null}
                {state.selectedFollowupDateFilter === "Choose Date" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 160,
                        height: 40,
                        borderRadius: 5,
                      }}
                    >
                      <label style={{ marginLeft: 10 }}>To</label>
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchFollowupToDate
                            ? state.searchFollowupToDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchFollowupToDate(date);
                          state.setReload(true);
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </div>
              {/* {checkPermission("LEAD_COMMENTS_FIELD") && (
                                <div className="container col-1 m-0">
                                    <b>Comments</b>
                                </div>
                            )} */}

              {checkPermission("LEADS_ORIGIN_FIELD") && (
                <div className="col-1 m-0">
                  <b>Source</b>
                  <div
                    style={{
                      width: 140,
                      height: 35,
                      backgroundColor: "white",
                      border: "1px solid  #00997c",
                      borderRadius: 5,
                      marginTop: 8,
                    }}
                  >
                    <ReactMultiselectCheckboxes
                      options={state.sourceData}
                      getDropdownButtonLabel={state.getDropdownButtonLabel}
                      value={state.selectedSources}
                      onChange={(value, event) =>
                        state.handleSelectSources(value, event)
                      }
                      setState={state.setSelectedSources}
                    />
                  </div>
                </div>
              )}

              <div className="col-1 m-0">
                <b>Destination</b>
                <div
                  style={{
                    width: 140,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid  #00997c",
                    borderRadius: 5,
                    marginTop: 8,
                  }}
                >
                  <ReactMultiselectCheckboxes
                    options={state.locationData}
                    getDropdownButtonLabel={state.getDropdownButtonLabel}
                    value={state.selectedLocations}
                    onChange={(value, event) =>
                      state.handleSelectLocations(value, event)
                    }
                    setState={state.setSelectedLocations}
                  />
                </div>
              </div>

              {/* {checkPermission("LEAD_PAYMENT_FIELD") && (
                                <div className="container col-1 m-0">
                                    <b>Payment Mode</b>
                                </div>
                            )} */}
            </div>
            <hr />
            <List component="nav" aria-label="mailbox folders">
              {state.homeData}
            </List>
          </div>
          <ReactPaginate
            previousLabel={"PREV"}
            nextLabel={"NEXT"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={state.pageCount}
            initialPage={state.offset}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={state.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={state.offset}
          />
        </div>

        {isDrawerOpen && (
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          >
            <div
              style={{
                position: "fixed",
                backgroundColor: "#06c6a2",
                zIndex: 99,
                width: "460px",
                height: 70,
              }}
            >
              <h4 style={flexStyle}>Notifications </h4>

              <span class="closebtn" onClick={() => setIsDrawerOpen(false)}>
                &times;
              </span>
              {/* <div className="row" style={{ marginLeft: "8px" }}>
                  <button
                    onClick={() => {
                      state.setIsToday(true);
                      state.setPageNo(0);
                    }}
                    style={{
                      backgroundColor: state.isToday ? "#06c6a2" : "gray",
                      marginTop: "45px",
                      width: "42%",
                      marginLeft: "12px",
                      height: "50px",
                    }}
                  >
                    <span style={{ fontSize: "16px" }}>Today </span>
                    <span>
                      <Badge bg="danger">{state.todayCount}</Badge>
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      state.setIsToday(false);
                      state.setPageNo(0);
                    }}
                    style={{
                      backgroundColor: !state.isToday ? "#06c6a2" : "gray",
                      marginTop: "45px",
                      width: "42%",
                      marginLeft: "12px",
                      height: "50px",
                    }}
                  >
                    <span style={{ fontSize: "16px" }}>Missed </span>
                    <span>
                      <Badge bg="danger">{state.missedCount}</Badge>
                    </span>
                  </button>
                </div> */}
              {/* {checkPermission("LEADS_ASSIGNED_FIELD") && (
                  <div className="col-md-2">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        style={{
                          width: 200,
                          border: `1px solid gray`,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginLeft: "15px",
                        }}
                      >
                        {!!state.assignedAgent
                          ? state.assignedAgent
                          : "Assign To"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        as={CustomMenu}
                        style={{
                          height: 200,
                          width: 260,
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {state?.assignedUser?.list?.map((value, id) => {
                          return (
                            <Dropdown.Item
                              key={id}
                              href="#"
                              style={{ width: 200 }}
                              onClick={() => {
                                // state.setAssignedAgent(value.label);
                                // state.setAssignedAgentCode(value.value);
                                state.loadAllNotifications(value.value);
                              }}
                            >
                              {value?.label}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )} */}
            </div>
            <hr></hr>

            <div style={{ width: 460 }}>
              {state.isloader ? (
                <p>Loading....</p>
              ) : (
                <InfiniteScroll
                  style={{ marginTop: "5vh" }}
                  dataLength={state.notificationData.length}
                  next={state.fetchMoreData}
                  hasMore={state.hasMore}
                  loader={
                    state.hasMore && (
                      <h6 style={{ textAlign: "center" }}>Loading...</h6>
                    )
                  }
                  height={"105vh"}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  {state.notificationData.length ? (
                    state.notificationData.map((item, i) => {
                      return <NotificationCard item={item} />;
                    })
                  ) : (
                    <div>
                      <p style={{ margin: "auto" }}>
                        <b>Empty Notifications...</b>
                      </p>
                    </div>
                  )}
                </InfiniteScroll>
              )}
            </div>
          </Drawer>
        )}
      </div>
    </div>
  );
}

export default KaretripEnquiry;
