import * as ReactBootstrap from "react-bootstrap";
import { List } from "@material-ui/core";
import AddField from "../AddField";
import Sidebar from "../../Sidebar";
import TopBar from "../../Enquiry/TopBar";
import useHospital from "./useHospitalPage.logic";
import ReactPaginate from "react-paginate";
import { checkPermission } from "../../../util/permissionUtils";
import CustomLoader from "../../../components/CustomLoader";

function HospitalPage(props) {
  const state = useHospital();

  const border = {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  };

  if (state.dataLoaded) {
    return <CustomLoader />;
  }

  return (
    <div className="report-container">
      <div className="report-sidebar-container">
        <Sidebar />
      </div>
      <div className="report-main-container">
        <div className="row " style={border}>
          <div style={{ width: "100%", padding: 10 }}>
            <div className="main-subContainer">
              <TopBar />
            </div>
            {checkPermission("HOSPITAL_ADD") && (
              <button
                onClick={state.addHospital}
                style={{
                  width: 100,
                  height: 50,
                  marginRight: 100,
                  paddingBottom: 5,
                  backgroundColor: "#00997c",
                  color: "white",
                }}
              >
                Add
              </button>
            )}
            <br></br>
            {state.isOpen && (
              <AddField
                onClicked={(isOpen) => state.setIsOpen(state.isOpen)}
                setLocation={state.setLocation}
                setName={state.setName}
                onSave={() => state.addNewCenter()}
              />
            )}
            <div className="row">
              <div className="col-1">
                <b>Hospital Name</b>
              </div>
              <div className="col-1">
                <b>Location</b>
              </div>
              <div className="col-1">
                <b>Contact Name</b>
              </div>
              <div className="col-1">
                <b>Contact Number</b>
              </div>
              <div className="col-1">
                <b>Address</b>
              </div>
              <div className="col-1">
                <b>Google Map</b>
              </div>
              <div className="col-2">
                <b>Specialities</b>
              </div>
              <div className="col-2">
                <b>Treatments</b>
              </div>
              <div className="col-1">
                <b>Agreement Models</b>
              </div>
              {checkPermission("HOSPITAL_VIEW") ||
                (checkPermission("HOSPITAL_DELETE") && (
                  <div className="col-1">
                    <b>Action</b>
                  </div>
                ))}
            </div>
            <hr />
            <List component="nav" aria-label="mailbox folders">
              {state.homeData}
            </List>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={state.pageCount}
              initialPage={state.offset}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={state.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={state.offset}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HospitalPage;
