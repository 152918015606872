import React from "react";
import "./PopupMain.css";
import PopupTreatmentInfoTemp from "./PopupTreatmentInfoTemp";
import PopupQuestionTemp from "./PopupQuestionTemp";

function PopupMain(props) {
    const { popup, toggle, handletoggle } = props;
    if (popup.type === "info" && toggle) {
        return <PopupTreatmentInfoTemp {...props} />;
    }

    if (popup.type === "questions" && toggle) {
        return <PopupQuestionTemp {...props} />;
    }
    return "";
}

export default PopupMain;
