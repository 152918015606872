import React, { useEffect, useRef, useState } from "react";
import { TextInput } from "../../Components/TextInput";
import DatePicker from "react-datepicker";
import {
  fetchVilDetails,
  getAllDocuments,
  saveVilDetails,
  deleteDocument,
} from "../../../../../services/karetrip/enquiryService";
import moment from "moment";
import { uploadVilDocuments } from "../../../../../services/mykare/documentService";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import downloadDocument from "../../../../../util/documentUtil";
import { MdDeleteForever } from "react-icons/md";

export default function VIL({ userTrxnId, vilDetailId }) {
  const [formData, setFormData] = useState({
    centerName: "",
    doctorName: "",
    preferredCity: "",
    preferredDateTime: new Date(),
    comments: "",
    patientHighCommission : "",
  });
  const attenderFile = useRef(null);
  const [attenderFiles, setAttenderFiles] = useState([]);
  const [isEditable, setIsEditable] = useState(true);

  const removeItemAttender = (id) => {
    const filesClone = attenderFiles.filter((file) => file.id !== id);
    setAttenderFiles(filesClone);
  };

  const deleteDocumentItem = async (id) => {
    if (id === 1) {
      await deleteDocument(passportFile[0].documentId).then((res) => {
        if (res.status == 200) {
          alert("file deleted");
          getVilDetails(vilDetailId);
        }
      });
    } else if (id === 2) {
      await deleteDocument(attenderFiles[0].documentId).then((res) => {
        if (res.status == 200) {
          alert("file deleted");
          getVilDetails(vilDetailId);
        }
      });
    }
  };

  const handleAttenderFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setAttenderFiles((state) => [...state, file]);
  };
  const passport = useRef(null);
  const [passportFile, setPassportFiles] = useState([]);

  const removeItem = (id) => {
    const filesClone = passportFile.filter((file) => file.id !== id);
    setPassportFiles(filesClone);
  };

  const handleTicketFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setPassportFiles((state) => [...state, file]);
  };

  const addVilDetails = async () => {
    formData.userTxnId = userTrxnId;
    formData.preferredDateTime = moment(formData.preferredDateTime)
      .format()
      .split("+")[0];
    const response = await saveVilDetails(formData);
    if (response.status == 200) {
      alert("Saved Succesfully");
      uploadAttenderDocs(response.data.vilDetailId);
      uploadPassportDocs(response.data.vilDetailId);
      getVilDetails(response.data.vilDetailId);
    } else {
      alert("Something went wrong");
    }
  };

  const getVilDetails = async (detailId) => {
    const response = await fetchVilDetails(detailId);
    if (response.status == 200) {
      if (response.data) {
        setIsEditable(false);
        loadAttenderDocuments(detailId);
        loadPassportDocuments(detailId);
        setFormData(response.data);
      }
    }
  };

  const loadAttenderDocuments = (detailId) => {
    getAllDocuments(detailId, "VIL_DOCUMENT", "attender_passport")
      .then((res) => {
        setAttenderFiles(res.data);
      })
      .catch((error) => {})
      .finally(() => {});
  };
  const loadPassportDocuments = (detailId) => {
    getAllDocuments(detailId, "VIL_DOCUMENT", "patient_passport")
      .then((res) => {
        setPassportFiles(res.data);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const uploadAttenderDocs = (detailId) => {
    let formData = new FormData();
    attenderFiles.forEach((doc) => {
      formData.append("vilDocs", doc);
    });
    formData.append("documentContext", "VIL_DOCUMENT");
    formData.append("vilDetailId", detailId);
    formData.append("category", "attender_passport");

    uploadVilDocuments(formData)
      .then((data) => {
        alert("Uploaded Successfully");
        loadAttenderDocuments(detailId);
      })
      .catch((err) => {});
  };
  const uploadPassportDocs = (detailId) => {
    let formData = new FormData();
    passportFile.forEach((doc) => {
      formData.append("vilDocs", doc);
    });
    formData.append("documentContext", "VIL_DOCUMENT");
    formData.append("vilDetailId", detailId);
    formData.append("category", "patient_passport");

    uploadVilDocuments(formData)
      .then((data) => {
        alert("Uploaded Successfully");
        loadPassportDocuments(detailId);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getVilDetails(vilDetailId);
  }, []);

  return (
    <div>
      <div className="row">
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, centerName: e.target.value }))
          }
          label="Hospital Name"
          value={formData.centerName}
        />
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, doctorName: e.target.value }))
          }
          label="Doctor Name"
          value={formData.doctorName}
        />
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, preferredCity: e.target.value }))
          }
          label="Preferred City"
          value={formData.preferredCity}
        />
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, patientHighCommission: e.target.value }))
          }
          label="Patient High Commission"
          value={formData.patientHighCommission}
        />
        <div className="col-lg-2">
          {isEditable ? (
            <>
              <p
                style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}
              >
                Preferred Date of Travel
              </p>

              <DatePicker
                className="myDatePicker"
                selected={
                  formData.preferredDateTime
                    ? new Date(formData.preferredDateTime)
                    : new Date()
                }
                onChange={(date) => {
                  setFormData((s) => ({ ...s, preferredDateTime: date }));
                }}
                // minDate={new Date()}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </>
          ) : (
            <Grid container direction="column">
              <b> Preferred Date of Travel</b>
              <span>
                {moment(formData.preferredDateTime).format(
                  "DD-MM-YYYY hh:mm a"
                )}
              </span>
            </Grid>
          )}
        </div>

        <hr style={{ marginTop: "20px" }} />

        {/* Passenger Passport Doc */}
        <div className="col-10" style={{ marginBottom: 20 }}>
          {isEditable ? (
            <div>
              <label
                for="ticketDoc"
                className="btn"
                style={{
                  width: 250,
                  backgroundColor: "#06c6a2",
                  color: "white",
                }}
              >
                Upload Patient Passport
              </label>
              <br></br>
              <input
                id="ticketDoc"
                style={{
                  width: 100,
                  cursor: "pointer ",
                  display: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 103,
                  paddingTop: 3,
                }}
                type="file"
                ref={passport}
                onChange={handleTicketFile.bind(this)}
              />
              {passportFile.length > 0 &&
                passportFile?.map((item, index) => {
                  return (
                    <>
                      <span
                        style={{
                          border: "1px solid gray",
                          marginLeft: 10,
                          cursor: "pointer ",
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingTop: 5,
                          backgroundColor: "#e5e5e5",
                        }}
                        onClick={() => removeItem(item.id)}
                      >
                        {item.name}
                      </span>
                      <span
                        style={{
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingBottom: 10,
                          paddingTop: 5,
                          backgroundColor: "#06c6a2",
                          border: "1px solid gray",
                          color: "white",
                          cursor: "pointer ",
                        }}
                        onClick={() => removeItem(item.id)}
                      >
                        X
                      </span>
                    </>
                  );
                })}
            </div>
          ) : (
            <Grid container direction="column">
              <b>Patient Passport</b>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                }}
              >
                {passportFile.length > 0 &&
                  passportFile?.map((doc, index) => {
                    return (
                      <b
                        style={{
                          width: 160,
                          height: 40,
                          backgroundColor: "lightgray",
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          borderRadius: 20,
                        }}
                      >
                        <Link
                          to="#"
                          style={{ marginLeft: 10 }}
                          className="pointer"
                          onClick={() =>
                            downloadDocument(
                              doc.documentType,
                              doc.document,
                              doc.documentName
                            )
                          }
                        >
                          {`Document ${index + 1}`}
                        </Link>
                        <MdDeleteForever
                          color="red"
                          size={25}
                          onClick={() => deleteDocumentItem(1)}
                        />
                      </b>
                    );
                  })}
              </div>
            </Grid>
          )}
        </div>
        {/* Passenger Passport Doc */}

        {/* Attender Passport Doc */}
        <div className="col-10" style={{ marginBottom: 20 }}>
          {isEditable ? (
            <div>
              <label
                for="attenderDoc"
                className="btn"
                style={{
                  width: 250,
                  backgroundColor: "#06c6a2",
                  color: "white",
                }}
              >
                Upload Attender Passport
              </label>
              <br></br>
              <input
                id="attenderDoc"
                style={{
                  width: 100,
                  cursor: "pointer ",
                  display: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 3,
                  paddingTop: 3,
                }}
                type="file"
                ref={attenderFile}
                onChange={handleAttenderFile.bind(this)}
              />

              {attenderFiles.length > 0 &&
                attenderFiles?.map((item, index) => {
                  return (
                    <>
                      <span
                        style={{
                          border: "1px solid gray",
                          marginLeft: 10,
                          cursor: "pointer ",
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingTop: 5,
                          backgroundColor: "#e5e5e5",
                        }}
                        onClick={() => removeItemAttender(item.id)}
                      >
                        {item.name}
                      </span>
                      <span
                        style={{
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingBottom: 10,
                          paddingTop: 5,
                          backgroundColor: "#06c6a2",
                          border: "1px solid gray",
                          color: "white",
                          cursor: "pointer ",
                        }}
                        onClick={() => removeItemAttender(item.id)}
                      >
                        X
                      </span>
                    </>
                  );
                })}
            </div>
          ) : (
            <Grid container direction="column">
              <b>Atender Passport</b>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                }}
              >
                {attenderFiles.length > 0 &&
                  attenderFiles?.map((doc, index) => {
                    return (
                      <b
                        style={{
                          width: 160,
                          height: 40,
                          backgroundColor: "lightgray",
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          borderRadius: 20,
                        }}
                      >
                        <Link
                          to="#"
                          style={{ marginLeft: 10 }}
                          className="pointer"
                          onClick={() =>
                            downloadDocument(
                              doc.documentType,
                              doc.document,
                              doc.documentName
                            )
                          }
                        >
                          {`Document ${index + 1}`}
                        </Link>
                        <MdDeleteForever
                          color="red"
                          size={25}
                          onClick={() => deleteDocumentItem(2)}
                        />
                      </b>
                    );
                  })}
              </div>
            </Grid>
          )}
        </div>
        {/* Attender Passport Doc */}
      </div>

      <div class="col-lg-5 mt-5">
        {isEditable ? (
          <>
            <span>Comments</span>
            <textarea
              className="comments-section"
              rows={5}
              value={formData.comments}
              onChange={(e) =>
                setFormData((s) => ({ ...s, comments: e.target.value }))
              }
              placeholder="Comments"
            ></textarea>
          </>
        ) : (
          <Grid container direction="column">
            <b>Comments</b>
            <span>{formData.comments}</span>
          </Grid>
        )}
      </div>

      {isEditable ? (
        <div className="col-lg-2">
          <button
            onClick={() => addVilDetails()}
            style={{ width: 70, padding: 8, marginTop: 20 }}
          >
            Save
          </button>
        </div>
      ) : (
        <div className="col-lg-2">
          <button
            onClick={() => setIsEditable(true)}
            style={{ width: 70, padding: 8, marginTop: 20 }}
          >
            Update
          </button>
        </div>
      )}
    </div>
  );
}
