import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import { Link } from "react-router";

export default function AdDetails({ isAdPopUp, setIsAdPopUp, adDetails }) {
  const handleClose = () => setIsAdPopUp(false);
  return (
    <React.Fragment>
      <Dialog
        open={isAdPopUp}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid sx={{ width: 450 }}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{ fontWeight: 600, backgroundColor: "#39A7FF", color: "white" }}
          >
            {"Ad Details"}
          </DialogTitle>
          <DialogContent sx={{ marginRight: "40px" }}>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ textAlign: "left", marginTop: 2, fontWeight: 600 }}
            >
              AdID : <span style={{ color: "red" }}>{adDetails?.adId}</span>
            </DialogContentText>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ textAlign: "left", marginTop: 2, fontWeight: 600 }}
            >
              Ad Name :{" "}
              <span style={{ color: "black" }}>{adDetails?.adName}</span>
            </DialogContentText>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ textAlign: "left", marginTop: 2, fontWeight: 600 }}
            >
              Ad URL : <a href={adDetails?.imageUrl} style={{cursor:"pointer"}} target="_blank">imageURL</a>
              <img src={adDetails?.imageUrl} alt="url" style={{width:100,height:130,marginLeft:50}}/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
