import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  deletePatientDetail,
  getPatientDetails,
} from "../../../services/associate/patientService";

function usePatientDetail() {
  const history = useHistory();
  const [type, setType] = useState("");
  const [open, setOpen] = React.useState(false);
  const [enquiryId, setEnquiryId] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const data = history?.location?.state?.enquiryDetails;
  const [patientDetail, setPatientDetail] = useState([]);
  const [patientDetails, setPatientDetails] = useState([]);
  const [isActive, setIsActive] = useState({ isTrue: true });

  const handleClickOpen = (patientData) => {
    setOpen(true);
    setType(patientData?.type);
    setEnquiryId(patientData?.id);
  };

  const handleDelete = () => {
    deletePatientDetail(enquiryId)
    setOpen(false);
    setTimeout(()=>{
      window.location.reload();
    },300);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleHistoryBtn = () => {
    setIsActive({ isTrue: true });
    window.location.reload();
  };

  const handleAddBtn = () => {
    setIsActive({ isTrue: false });
  };

  const loadPatientDetails = () => {
    getPatientDetails(data?.id).then((data) => {
      const filteredData = data?.filter((item) => item?.status !== "cancelled");
      setPatientDetails(filteredData);
    });
  };

  const editPatientDetails = (data, tabLabel) => {
    setActiveTab(tabLabel);
    setPatientDetail(data);
    setIsActive({ isTrue: false, type: data?.type });
  };

  React.useEffect(() => {
    loadPatientDetails();
  }, [isActive]);

  return {
    type,
    open,
    isActive,
    activeTab,
    setIsActive,
    handleClose,
    handleDelete,
    handleAddBtn,
    patientDetail,
    patientDetails,
    handleClickOpen,
    handleHistoryBtn,
    editPatientDetails,
  };
}

export default usePatientDetail;
