import axios from "axios";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import * as appConstants from "../../util/AppConstants";
import { useLocation, useHistory } from "react-router-dom";
import {
  getEnquiryByContext,
  getLostReasons,
  getUserTrxnStatuses,
  updateEnquiry,
} from "../../services/mykare/enquiryService";
import {
  getCallerDetails,
  getCallRecordingLinks,
  getCenters,
  getDoctors,
  getTreatments,
} from "../../services/mykare/dataService";
import { getUserTrxnHistory } from "../../services/mykare/enquiryService";
import { clickToCall } from "../../services/mykare/callService";
import { getEnquiryDetailsById } from "../../services/associate/enquiryService";
import { getAllLocations } from "../../services/mykare/centerService";
import { getRejectedReasons } from "../../services/mykare/insuranceService";
import {
  getAppointmentDetails,
  saveAppointmentDetail,
} from "../../services/mykare/AppointmentService";
import { checkLocation } from "../../util/getAdditionalInfo";
import { getUserTxnId } from "../../store/DataStore";
import { checkPermission } from "../../util/permissionUtils";
import { saveEventActivityLogs } from "../../util/EventActivity";
import { getAgentData } from "../../services/commonService/commonService";

const useMykareEnquiry = () => {
  let history = useHistory();
  const [location, setLocation] = useState(useLocation());
  const [isIPDone, setIsIPDone] = useState(false);
  const [isOPDone, setIsOPDone] = useState(false);
  const [age, setAge] = useState();
  const [data, setData] = useState({});
  const [type, setType] = useState(null);
  const [offset, setOffset] = useState(0);
  const [transId, setTransId] = useState();
  const [message, setMessage] = useState();
  const [gender, setGender] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [comments, setComments] = useState();
  // const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [replied, setReplied] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [centerData, setCenterData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const [smoDetails, setSmoDetails] = useState(null);
  const [assignedCode, setAssignedCode] = useState();
  const userRole = localStorage.getItem("user-role");
  const [assignedAgent, setAssignedAgent] = useState();
  const [resAgentCode, setResAgentCode] = useState("");
  const [patientName, setPatientName] = useState(null);
  const [insuranceId, setInsuranceId] = useState(null);
  // const [isCabDetail, setisCabDetail] = useState(false);
  const [enquiryStatus, setEnquiryStatus] = useState("");
  const [surgeryDetail, setSurgeryDetail] = useState(null);
  const [enquiryDetail, setEnquiryDetail] = useState(null);
  // const [isAppointment, setisAppointment] = useState(false);
  // const [isPatientsDocs, setIsPatientsDocs] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [selectedEnquiryDateFilter, setSelectedEnquiryDateFilter] =
    useState("All");
  const [searchEnquiryFromDate, setSearchEnquiryFromDate] = useState(null);
  const [searchEnquiryToDate, setSearchEnquiryToDate] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedStatusIds, setSelectedStatusIds] = useState([0]);

  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([0]);

  const [selectedFollowupDateFilter, setSelectedFollowupDateFilter] =
    useState("All");
  const [searchFollowupFromDate, setSearchFollowupFromDate] = useState(null);
  const [searchFollowupToDate, setSearchFollowupToDate] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);

  const [followUpDate, setFollowUpDate] = useState(null);
  // const [isPatientDetail, setIsPatientsDetail] = useState(false);
  const [selectedTransferTo, setSelectedTransferTo] = useState();
  const [preventiveCareDetail, setPreventiveCareDetail] = useState(null);
  const [cabDetailsId, setCabDetailsId] = useState(null);
  const [loanDetailId, setLoanDetailId] = useState(null);
  const [appointmentDetailId, setAppointmentDetailId] = useState(null);
  const [usersHistory, setUsersHistory] = useState();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([0]);
  const [selectedTreatment, setselectedTreatment] = useState("");
  const [treatments, setTreatments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedSourceIds, setSelectedSourceIds] = useState([0]);
  const [callDetails, setCallDetails] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState(null);
  const [isTreatment, setIsTreatment] = useState(false);
  const [isLocation, setIsLocation] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState({
    isOpen: true,
    isInsurance: false,
    isAppointment: false,
    isCabDetail: false,
    isPatientsDocs: false,
    isPatientsDetail: false,
    isEmi: false,
  });
  const [selectedPaymentType, setSelectedPaymentType] = useState([]);

  const ipPaymentModes = ["EMI", "CASH", "CASHLESS", "REIMBURSEMENT"];

  const getUserEnquiryId = () => {
    const url = window?.location?.pathname;
    let path = url.slice(url?.lastIndexOf("/")).split("/")[1];
    return path;
  };

  const getEnquiryDetails = async () => {
    const enquiryId = data?.enquiryId ? data?.enquiryId : getUserEnquiryId();
    const response = await getEnquiryDetailsById(enquiryId,"lead");
    if (response.data) {
      var enquiryData = response.data;
      setData(enquiryData);
      setPatientId(enquiryData.patientId);
      setCabDetailsId(enquiryData.cabDetailsId);
      setLoanDetailId(enquiryData.loanDetailId);
      setInsuranceId(enquiryData.insuranceDetailId);
      setAppointmentDetailId(enquiryData.appointmentDetailId);
      getUserTxnHistory(enquiryData.enquiryId);
      // setComments(enquiryData.notes);
      setType(enquiryData.categoryContext);
      getCategoryDetails(
        enquiryData.categoryContextId,
        enquiryData.categoryContext
      );
      setSelectedReasons(enquiryData.lostReason);
      setStatus(enquiryData.status);
      setStatusId(enquiryData.statusId);
      setGender(enquiryData.gender);
      setPatientName(enquiryData.patientName);
      setAge(enquiryData.age);
      setResAgentCode(enquiryData.assignTo);
      if (enquiryData.followUpDate) {
        setFollowUpDate(new Date(enquiryData.followUpDate));
      }
      if (enquiryData.status == "replied" || replied) {
        setReplied(true);
      }
      if (enquiryData.location == null) {
        setIsLocation(true);
      }
      // if(enquiryData)
    }
  };

  const getLostReason = async (statusId) => {
    const response = await getLostReasons(statusId);
    if (response.status === 200) {
      if (response.data) {
        setReasons(response.data);
      }
    }
  };

  useEffect(() => {
    loadTreatments();
    loadAllLocations();
    loadCallDetails();
  }, [data]);

  const loadCallDetails = async () => {
    if (data.enquiryId) {
      const response = await getCallerDetails(data.enquiryId);
      if (response.data) {
        setCallDetails(response.data.details);
      }
    }
  };

  const getCallLinks = async (callSid) => {
    const response = await getCallRecordingLinks(callSid);
    if (response?.data?.recordingUrl) {
      window.confirm(`Do you want to play the record ?`) &&
        window.open(response.data.recordingUrl, "_blank");
    } else {
      alert("Sorry audio not found..!");
    }
  };

  useEffect(() => {
    if (data.statusId) {
      getLostReason(data.statusId);
    }
  }, [data]);

  useEffect(() => {
    if (!!resAgentCode && userData.length > 0) {
      const assignedName = userData
        .filter((item) => item.code === resAgentCode)
        .map((item) => item.name)[0];
      setAssignedAgent(assignedName);
    }
  }, [resAgentCode, userData]);

  useEffect(() => {
    if (enquiryDetail && treatments.length > 0 && locations.length > 0) {
      const treatmentName = handleTreatmentName(enquiryDetail);
      if (!treatmentName) {
        setIsTreatment(true);
      }
      const treatment = treatments
        .filter(
          (item) => item.name?.toUpperCase() === treatmentName?.toUpperCase()
        )
        .map((item) => item)[0];
      setselectedTreatment(treatment);
      const location = locations
        .filter(
          (item) =>
            item.name?.toUpperCase() === enquiryDetail.location?.toUpperCase()
        )
        .map((item) => item)[0];
      setSelectedLocation(location);
    }
    if (data && reasons.length > 0) {
      const reason = reasons
        ?.filter(
          (item) => item.name?.toUpperCase() === data.lostReason?.toUpperCase()
        )
        .map((item) => item)[0];
      setSelectedReasons(reason);
    }
    setSelectedPaymentType(enquiryDetail?.paymentType)

  }, [enquiryDetail, treatments, locations, reasons, data, isTreatment]);

  useEffect(() => {
    if (checkPermission("ENQUIRY_UPDATE_SUB_TAB")) {
      handleHideFun(1);
    } else {
      handleHideFun(2);
    }
  }, []);

  useEffect(() => {
    if (!location.state) {
      // history.push("/enquiry");
      if (window.state) {
        // setLocation(window);
      } else {
        getEnquiryDetails();
      }
    } else {
      var enquiryData = location.state.enquiryDetails;
      setData(enquiryData);
      setPatientId(enquiryData.patientId);
      setCabDetailsId(enquiryData.cabDetailsId);
      setLoanDetailId(enquiryData.loanDetailId);
      setInsuranceId(enquiryData.insuranceDetailId);
      setAppointmentDetailId(enquiryData.appointmentDetailId);
      getUserTxnHistory(enquiryData.enquiryId);
      setOffset(location.state.offset);
      setEnquiryStatus(location.state.enquiryStatus);
      setSelectedEnquiryDateFilter(location.state.selectedEnquiryDateFilter);
      setSearchEnquiryFromDate(location.state.searchEnquiryFromDate);
      setSearchEnquiryToDate(location.state.searchEnquiryToDate);
      setAssignedUser(location.state.assignedUser);
      setSelectedStatuses(location.state.selectedStatuses);
      setSelectedStatusIds(location.state.selectedStatusIds);
      setSelectedTreatments(location.state.selectedTreatments);
      setSelectedTreatmentIds(location.state.selectedTreatmentIds);

      setSelectedLocations(location.state.selectedLocations);
      setSelectedLocationIds(location.state.selectedLocationIds);

      setSelectedSources(location.state.selectedSources);
      setSelectedSourceIds(location.state.selectedSourceIds);

      setSelectedFollowupDateFilter(location.state.selectedFollowupDateFilter);
      setSearchFollowupFromDate(location.state.searchFollowupFromDate);
      setSearchFollowupToDate(location.state.searchFollowupToDate);
      setSelectedTransferTo(enquiryData.transferTo?.name);
      // setComments(enquiryData.notes);
      setType(enquiryData.categoryContext);
      getCategoryDetails(
        enquiryData.categoryContextId,
        enquiryData.categoryContext
      );
      setStatus(enquiryData.status);
      setStatusId(enquiryData.statusId);
      setGender(enquiryData.gender);
      setPatientName(enquiryData.patientName);
      setAge(enquiryData.age);
      setResAgentCode(enquiryData.assignTo);
      if (enquiryData.followUpDate) {
        setFollowUpDate(new Date(enquiryData.followUpDate));
      }
      if (enquiryData.status == "replied" || replied) {
        setReplied(true);
      }
    }
  }, [location]);

  const click2Call = (phoneType) => {
    try {
      clickToCall(data.enquiryId, phoneType).then((res) => {
        alert("Please wait, your call is connecting!");
      });
    } catch (error) { }
  };

  const showFollowUpField = () => {
    if (statusId !== 12 && statusId !== 13 && statusId !== 14) {
      return true;
    } else {
      return true;
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkAppointment = async () => {
    if (data.appointmentDetailId) {
      getAppointmentDetails(data.appointmentDetailId).then((res) => {
        setAppointmentDetails(res.data);
        if (res.data?.ipDetails !== null) {
          setIsIPDone(true);
        }
        if (res.data?.opDetails !== null) {
          setIsOPDone(true);
        }
      });
    }
  };

  useEffect(() => {
    if (statusId == 19 || statusId == 18 || statusId == 21 || statusId == 20 || statusId == 39 || statusId == 40 || statusId == 43) {
      checkAppointment();
    }
  }, [statusId, data]);

  const showEnquiryActionFields = () => {
    if (
      statusId !== 17 &&
      statusId !== 16 &&
      statusId !== 23 &&
      statusId !== 15 &&
      statusId !== 22
    ) {
      return true;
    } else {
      return false;
    }
  };

  const loadTreatments = async () => {
    try {
      getTreatments().then((res) => {
        setTreatments(res.data);
      });
    } catch (error) { }
  };
  const loadAllLocations = async () => {
    try {
      getAllLocations().then((data) => {
        setLocations(data);
      });
    } catch (error) { }
  };

  const handleTreatmentName = (detail) => {
    var treatmentName = "";
    if (detail.packages) {
      treatmentName = detail.packages;
    } else if (detail.treatmentPackage) {
      treatmentName = detail.treatmentPackage;
    } else if (detail.speciality) {
      treatmentName = detail.speciality;
    }
    return treatmentName;
  };

  function handlePick(selectedItem, tnxId) {
    setSelectedTransferTo(selectedItem);
    setTransId(tnxId);
  }
  const handleStatus = (status, id) => {
    if (id === 1) {
      setComments(null);
    }
    setStatus(status);
    setStatusId(id);
  };
  const handleHideFun = (select) => {
    console.log('=======handleHideFun==========', select)
    if (select === 1) {
      getEnquiryDetails();
      setSelectedTab((prev) => ({ isOpen: true }));
    } else if (select === 2) {
      setSelectedTab((prev) => ({ isInsurance: true }));
    } else if (select === 3) {
      setSelectedTab((prev) => ({ isEmi: true }));
    } else if (select === 4) {
      setSelectedTab((prev) => ({ isAppointment: true }));
    } else if (select === 5) {
      setSelectedTab((prev) => ({ isCabDetail: true }));
    } else if (select === 6) {
      setSelectedTab((prev) => ({ isPatientsDocs: true }));
    } else if (select === 7) {
      setSelectedTab((prev) => ({ isPatientsDetail: true }));
    }
  };
  const loadCenters = async () => {
    try {
      getCenters().then((res) => {
        setCenterData(res.data);
      });
    } catch (error) { }
  };
  const loadDoctors = async () => {
    try {
      getDoctors().then((res) => {
        setDoctorData(res.data);
      });
    } catch (error) { }
  };
  const loadStatuses = async () => {
    try {
      getUserTrxnStatuses().then((res) => {
        setStatusList(res.data);
      });
    } catch (error) { }

    // if (status === "Rejected") {
    //   postData.rejectedReasonId = selectedReasons.id
    //   if (selectedReasons.length !== 0) {
    //     updateInsuranceDetails(postData)
    //       .then((res) => {
    //         loadInsuranceDetails(insuranceDetailId);
    //       })
    //       .catch((err) => {

    //       });
    //   } else {
    //     alert("Please Select the reason");
    //   }
    // }
  };

  const getCategoryDetails = async (categoryContextId, categoryContext) => {
    try {
      getEnquiryByContext(categoryContextId, categoryContext).then(
        (response) => {
          if (response.data.smoDetail) {
            setSmoDetails(response.data.smoDetail);
          } else if (response.data.preventiveCareDetail) {
            setPreventiveCareDetail(response.data.preventiveCareDetail);
          } else if (response.data.surgeryDetail) {
            setSurgeryDetail(response.data.surgeryDetail);
          } else if (response.data.enquiryDetail) {
            setEnquiryDetail(response.data.enquiryDetail);
          }
        }
      );
    } catch (error) { }
  };

  const getUserTxnHistory = async (enquiryId) => {
    try {
      getUserTrxnHistory(enquiryId).then((res) => {
        setUsersHistory(res.data);
      });
    } catch (error) { }
  };

  const saveEnquiryData = async (postData) => {
    try {
      const data = await updateEnquiry(postData);
      const response = { ...data };
      if (response.status === 200) {
        getEnquiryDetails();
        saveEventActivityLogs('Update Enquiry')
        alert("Successfully Updated");
      }
      return response;
    } catch (error) { }
  };

  const saveAppointmentDetails = async () => {
    let postData = null;
    if (statusId == 19 || statusId == 18) {
      const myDate = moment(appointmentDetails.ipDetails.arrivalDateTime)
        .format()
        .split("+")[0];
      postData = {
        userTxnId: data.enquiryId,
        iPDetail: { ...appointmentDetails.ipDetails, arrivalDateTime: myDate },
      };
    } else {
      const myDate = moment(appointmentDetails.opDetails?.dateTime)
        .format()
        .split("+")[0];
      postData = {
        userTxnId: data.enquiryId,
        oPDetail: { ...appointmentDetails.opDetails, dateTime: myDate },
      };
    }
    const response = await saveAppointmentDetail(postData);
    if (response.status == 200) {
      let myDate = null;
      if (followUpDate !== null) {
        myDate = moment(followUpDate).format().split("+")[0];
      }
      const postData = {
        userTxnId: data.enquiryId,
        statusId: statusId,
        transferTo: transId,
        followUpDate: myDate,
        treatmentId: selectedTreatment?.id,
        locationId: selectedLocation?.id,
        notes: comments,
        reasonId: selectedReasons?.id !== undefined ? selectedReasons.id : null,
        age: age,
        assignTo: assignedCode,
        gender: gender ? gender.toUpperCase() : null,
      };
      saveEnquiryData(postData);
    } else {
      // alert("Failed to update !! Please check if you have filled all details?");
    }
    handleClose();
  };

  const postStatus = async () => {
    let myDate = null;
    if (followUpDate !== null) {
      myDate = moment(followUpDate).format().split("+")[0];
    }
    const postData = {
      userTxnId: data.enquiryId,
      statusId: statusId,
      transferTo: transId,
      followUpDate: myDate,
      treatmentId: selectedTreatment?.id,
      locationId: selectedLocation?.id,
      notes: comments,
      reasonId: selectedReasons?.id !== undefined ? selectedReasons.id : null,
      age: age,
      assignTo: assignedCode,
      gender: gender ? gender.toUpperCase() : null,

      paymentType: selectedPaymentType,
    };
    if (statusId == 28 || statusId == 30) {
      if (postData.reasonId !== null) {
        saveEnquiryData(postData);
      } else {
        alert("Please select any reason!");
      }
    } else if (statusId == 19 || statusId == 18 || statusId == 39 || statusId == 40 || statusId == 43) {
      if (isIPDone) {
        handleClickOpen();
      } else {
        handleHideFun(4);
        alert("Please fill the appointment IPD details to update the enquiry.");
      }
    } else if (statusId == 21 || statusId == 20 || statusId == 41 || statusId == 42 || statusId == 44) {
      if (isOPDone) {
        // popup open
        handleClickOpen();
      } else {
        handleHideFun(4);
        alert("Please fill the appointment OPD details to update the enquiry.");
      }
    } else if (userRole == "SALES_AGENT") {
      if (data.type == "Enquiry") {
        if (statusId == 12 || statusId == 13 || statusId == 14) {
          if (followUpDate) {
            saveEnquiryData(postData);
          } else {
            alert("Followup date is manditory...!");
          }
        } else {
          saveEnquiryData(postData);
        }
      } else {
        saveEnquiryData(postData);
      }
    } else {
      saveEnquiryData(postData);
    }
  };

  useEffect(() => {
    loadAssignedUsers();
    loadCenters();
    loadDoctors();
    loadStatuses();
  }, []);

  const handleToAssigned = (assignTo, assignCode) => {
    setAssignedAgent(assignTo);
    setAssignedCode(assignCode);
  };
  const buttonStyle = {
    padding: 10,
    backgroundColor: "#06c6a2",
    width: 100,
  };
  const refreshPersonalDetails = (personalData) => {
    setPatientName(personalData.patientName);
    setAge(personalData.age);
    setGender(personalData.gender);
  }; 

  const loadAssignedUsers = () => {
    getAgentData(true).then((res)=>{
      setUserData(res?.data?.users);
    })
  }
  const updateReply = () => {
    setReplied(false);
  };

  function back() {
    history.push({
      pathname: "/addOnService",
      state: {
        offset,
        selectedStatuses,
        selectedStatusIds,
        selectedTreatments,
        selectedTreatmentIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedSourceIds,
        selectedSources,
        selectedLocations,
        selectedLocationIds,
        assignedUser,
      },
    });
  }

  return {
    age,
    data,
    gender,
    // isOpen,
    replied,
    message,
    userData,
    userRole,
    comments,
    smoDetails,
    // isCabDetail,
    patientName,
    // isInsurance,
    buttonStyle,
    centerData,
    followUpDate,
    assignedCode,
    resAgentCode,
    // isAppointment,
    enquiryStatus,
    surgeryDetail,
    enquiryDetail,
    assignedAgent,
    // isPatientsDocs,
    // isPatientDetail,
    selectedTransferTo,
    preventiveCareDetail,
    statusList,
    status,
    doctorData,
    usersHistory,
    selectedTab,
    cabDetailsId,
    setCabDetailsId,
    appointmentDetailId,
    setAppointmentDetailId,
    loanDetailId,
    setLoanDetailId,
    setInsuranceId,
    showEnquiryActionFields,
    insuranceId,
    back,
    setAge,
    handleStatus,
    handlePick,
    postStatus,
    updateReply,
    handleHideFun,
    setFollowUpDate,
    setComments,
    handleToAssigned,
    handleTreatmentName,
    refreshPersonalDetails,
    showEnquiryActionFields,
    click2Call,
    patientId,
    selectedTreatment,
    setselectedTreatment,
    selectedLocation,
    setSelectedLocation,
    treatments,
    locations,
    setLocations,
    showFollowUpField,
    callDetails,
    getCallLinks,
    reasons,
    setSelectedReasons,
    selectedReasons,
    getLostReason,
    statusId,
    isTreatment,
    isLocation,
    open,
    setOpen,
    handleClickOpen,
    handleClose,
    appointmentDetails,
    setAppointmentDetails,
    saveAppointmentDetails,
    ipPaymentModes,
    selectedPaymentType,
    setSelectedPaymentType
  };
};

export default useMykareEnquiry;
