import "./Arrival.css";
import axios from "axios";
import moment from "moment";
import "../../Styles/sideBar.css";
import "../../Styles/Pagination.css";
import { useHistory } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import idFormatter from "../../util/idFormatter";
import ListItem from "@material-ui/core/ListItem";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as appConstants from "../../util/AppConstants";
import ListItemText from "@material-ui/core/ListItemText";
import { getFilteredEnquiries } from "../../services/associate/enquiryService";

const useArrival = () => {
  let history = useHistory();
  const [perPage] = useState(20);
  const [offset, setOffset] = useState(0);
  const [homeData, setHomeData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      marginTop: 10,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));

  const getName = (agentName, enquiryByAgentName) => {
    if (agentName === null && !!enquiryByAgentName) {
      return enquiryByAgentName;
    }
    if (!!agentName && enquiryByAgentName === null) {
      return agentName;
    }
    if (!!agentName && !!enquiryByAgentName) {
      return `${enquiryByAgentName} (${agentName})`;
    }
  };

  const [pageData, setPageData] = useState(null);
  const [allData, setAllData] = useState(null);

  const [selectType, setSelectType] = useState({
    list: ["all"],
    selected: "",
  });
  const handleSelectTypeChange = (e) => {
    setSelectType((s) => ({ ...s, selected: e.target.value }));
  };

  const [selectStatus, setSelectStatus] = useState({
    list: ["all"],
    selected: "",
  });
  const handleSelectStatusChange = (e) => {
    setSelectStatus((s) => ({ ...s, selected: e.target.value }));
  };

  const [searchItem, setSearchItem] = useState("");
  const handleSearchItemChange = (e) => setSearchItem(e.target.value);

  useEffect(() => {
    if (!pageData && !allData) return;

    setPageData((s) =>
      allData.filter((item) => {
        return !!item.patientName.toLowerCase().match(searchItem.toLowerCase());
      })
    );
  }, [searchItem]);

  useEffect(() => {
    if (!!pageData) {
      const postData = pageData.map((data, index) => (
        <div className="row pointer" onClick={() => handleClickTale(data)}>
          <div className="container col-2 m-0">
            <ListItem>
              <ListItemText
                primary={moment(data.dateTime).format("DD-MM-YYYY")}
              />
            </ListItem>
          </div>
          <div className="container col-2 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            >
              {`ID : ${idFormatter(data.patientId)}`}
            </Typography>

            <ListItem>
              <ListItemText primary={`Name: ${data.patientName}`} />
            </ListItem>
          </div>
          <div class="container col-2 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            >
              {`ID : ${idFormatter(data.agentId)}`}
            </Typography>
            <ListItem>
              <ListItemText
                primary={`Name:${getName(
                  data.agentName,
                  data.enquiryByAgentName
                )}`}
              />
            </ListItem>
          </div>
          <div class="container col-2 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            >
              {`Enquiry Id : ${idFormatter(data.id)}`}
            </Typography>
            <ListItem>
              <ListItemText
                primary={`${data.type && data.type.toUpperCase()}`}
              />
            </ListItem>
          </div>

          <div class="container col-2 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={`${data.centerName}`} />
            </ListItem>
          </div>

          <div class="container col-2 m-0 capitalize">
            <p className={data.status === "replied" ? "text-2" : "text-1"}>
              {data.status}
            </p>
          </div>
          <hr />
        </div>
      ));
      setHomeData(postData);
    }
  }, [pageData]);

  const getData = async () => {

    getFilteredEnquiries(offset, perPage, 'arrival_appointment')
      .then((res) => {
        const data = res.data.enquiries;
        setPageCount(Math.ceil(res.data.count / perPage));

        const typeList = Array.from(new Set(data.map(({ type }) => type)));
        const statusList = Array.from(
          new Set(data.map(({ status }) => status))
        );
        setSelectType((s) => ({ ...s, list: [...s.list, ...typeList] }));
        setSelectStatus((s) => ({ ...s, list: [...s.list, ...statusList] }));
        setPageData(data);
        setAllData(data);
      })
      .catch((error) => {

      })
      .finally(() => {
        setDataLoaded(true);
      });
  };

  useEffect(() => {
    getData();
  }, [offset]);

  useEffect(() => {}, [homeData]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage);
  };

  function handleClickTale(enquiryData) {
    history.push({
      pathname: "/enquiryDetails",
      state: { enquiryDetails: enquiryData },
    });
  }
  function back() {
    history.push({
      pathname: "/arrival",
    });
  }
  
  const classes = useStyles();
  return {
    back,
    offset,
    homeData,
    pageCount,
    useStyles,
    dataLoaded,
    setDataLoaded,
    handlePageClick,
    handleClickTale,

    searchItem,
    handleSearchItemChange,
    selectType,
    handleSelectTypeChange,
    selectStatus,
    handleSelectStatusChange,
  };
};
export default useArrival;