import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { loanStatus, loanType } from "../../../util/getEnum";
import { checkPermission } from "../../../util/permissionUtils";
import useLoanDetails from "./useLoanDetails";

export default function LoanDetails({ userTxnId, loanDetailsId,callBackFn }) {
  const {
    saveLoanDetail,
    loanDetails,
    setLoanDetails,
    setEditableMode,
    editableMode,
  } = useLoanDetails({userTxnId, loanDetailsId,callBackFn});
  const typeList = ["EMI", "LOAN"];
  const statusList = ["APPROVED", "PARTIALLY_APPROVED", "REJECTED"];

  return (
    <div>
      <div>
        <hr />
        <div className="row" style={{ display: "flex" }}>
          <div className="col-lg-2 col-6">
            <div style={{ marginTop: 10 }}>
              <p
                style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
              >
                Loan ID
              </p>
              {editableMode ? (
                <input
                  placeholder="Loan ID"
                  value={loanDetails.loanId}
                  style={{
                    width: 200,
                    height: 40,
                    borderRadius: 5,
                    paddingLeft: 10,
                    border: `1px solid gray`,
                  }}
                  onChange={(e) =>
                    setLoanDetails((s) => ({ ...s, loanId: e.target.value }))
                  }
                />
              ) : (
                <span>{loanDetails.loanId}</span>
              )}
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div style={{ marginTop: 10 }}>
              <p
                style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
              >
                Choose Loan Type
              </p>
              {editableMode ? (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 200,
                      border: `1px solid gray`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "0px",
                    }}
                  >
                    {loanDetails.loanType ? loanType(loanDetails.loanType).small : "Emi"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {typeList.map((value, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          href="#"
                          style={{ width: 200 }}
                          onClick={() =>
                            setLoanDetails((s) => ({ ...s, loanType: value }))
                          }
                        >
                          {loanType(value).small}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <span>{loanType(loanDetails.loanType).small}</span>
              )}
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div style={{ marginTop: 10 }}>
              <p
                style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
              >
                Choose Loan Status
              </p>
              {editableMode ? (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 200,
                      border: `1px solid gray`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "0px",
                    }}
                  >
                    {loanDetails.loanStatus
                      ? loanStatus(loanDetails.loanStatus).small
                      : "Approved"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {statusList.map((value, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          href="#"
                          style={{ width: 200 }}
                          onClick={() =>
                            setLoanDetails((s) => ({ ...s, loanStatus: value }))
                          }
                        >
                          {loanStatus(value).small}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <span>{loanStatus(loanDetails.loanStatus).small}</span>
              )}
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div style={{ marginTop: 10 }}>
              <p
                style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
              >
                Amount
              </p>
              {editableMode ? (
                <input
                  placeholder="Amount"
                  value={loanDetails.amount}
                  style={{
                    width: 200,
                    height: 40,
                    borderRadius: 5,
                    paddingLeft: 10,
                    border: `1px solid gray`,
                  }}
                  onChange={(e) =>
                    setLoanDetails((s) => ({ ...s, amount: e.target.value }))
                  }
                />
              ) : (
                <span>{loanDetails.amount}</span>
              )}
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <p style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}>
              Comments
            </p>
            { editableMode ? <textarea
              className="comments-section"
              rows={5}
              value={loanDetails.comment}
              onChange={(e) =>
                setLoanDetails((s) => ({ ...s, comment: e.target.value }))
              }
              placeholder="Comments"
            ></textarea>:
            <span>{loanDetails.comment}</span>
            }
          </div>
          {checkPermission("LOAN_DETAIL_SAVE_BTN") && (
          <div class="col-lg-1 col-6">
            <div style={{ marginTop: 20 }}>
              <div>
                {editableMode ? (
                  <button
                    style={{ width: 70, padding: 8 }}
                    onClick={() => saveLoanDetail()}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    style={{ width: 70, padding: 8 }}
                    onClick={() => setEditableMode(true)}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}
