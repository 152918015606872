import axios from "axios";
import { getAuthHeader } from "../../store/DataStore";
import { APP_URL } from "../../util/AppConstants";

export const getAllDoctors = async (offset, perPage) => {
    const headers = {
      Authorization: getAuthHeader(),
    };
    const postData = {
      pageNo: 0,
      pageSize: 20
    }


  
    const {data} = await axios.post(
      `${APP_URL}/internal/mykare/doctors/filtered`, postData,
      {
        headers,
      }
    );
    return data;
  };

export const addDoctor = async (postData) => {
    const headers = {
      Authorization: getAuthHeader(),
    };
  
    const response = await axios.post(
      `${APP_URL}/internal/mykare/doctor/save`,postData,
      {
        headers,
      }
    );
    return response;
  };

  export const getAllDoctorsByCenter = async (centerId) => {

    const headers = {
      Authorization: getAuthHeader(),
    };
  
    const {data} = await axios.get(
      `${APP_URL}/internal/mykare/doctors/${centerId}`,
      {
        headers,
      }
    );
    return data;
  };

  export const deleteDcotorById = async(doctorId) => {
    const headers = {
      Authorization: getAuthHeader(),
    };
  
    const data = await axios.delete(
      `${APP_URL}/internal/mykare/deleteDoctor/${doctorId}`,
      {
        headers,
      }
    );
    return data;
  };

  export const uploadDoctorDoc = async (postData) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const response = await axios.post(`${APP_URL}/internal/mykare/uploadDoctorDoc`, postData, {
      headers,
    });
    return response;
  };

  export const updateDoctorDoc = async (postData) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const response = await axios.put(`${APP_URL}/internal/mykare/updateDoctorDoc`, postData, {
      headers,
    });
    return response;
  };