import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import {
  fetchArrivalDetails,
  getAllDocuments,
  saveArrivalDetails,
  deleteDocument,
} from "../../../../../services/karetrip/enquiryService";
import { uploadArrivalDocuments } from "../../../../../services/mykare/documentService";
import downloadDocument from "../../../../../util/documentUtil";
import { TextInput } from "../../Components/TextInput";
import PatientDoc from "../PatietDocTab/PatientDoc";
import Dropdown from "react-bootstrap/Dropdown";
import { MdDeleteForever } from "react-icons/md";

export default function Arrival({ userTrxnId, arrivalDetailId }) {
  const attenderVisa = useRef(null);
  const visa = useRef(null);

  const ticketFile = useRef(null);
  const [ticketFiles, setTicketFiles] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [visaFile, setVisaFile] = useState([]);
  const [attenderVisaFile, setAttenderVisaFile] = useState([]);

  const pickupArrangedBy = ["KARETRIP", "HOSPITAL", "PATIENT", "OTHERS"];

  const [formData, setFormData] = useState({
    centerName: "",
    preferredCity: "",
    arrivalDate: new Date(),
    flightDetails: "",
    numberOfPassengers: "",
    comments: "",
    contactNumber: "",
    preferredDoctor: "",
    originCity: "",
    fromAirport: "",
    toAirport: "",
  });

  const removeItem = (id) => {
    const filesClone = ticketFiles.filter((file) => file.id !== id);
    setTicketFiles(filesClone);
  };

  const removeVisaDoc = (id) => {
    const filesClone = visaFile.filter((file) => file.id !== id);
    setVisaFile(filesClone);
  };

  const removeAttenderVisaDoc = (id) => {
    const filesClone = attenderVisaFile.filter((file) => file.id !== id);
    setAttenderVisaFile(filesClone);
  };

  const deleteDocumentItem = async (id) => {
    if (id === 1) {
      await deleteDocument(ticketFiles[0].documentId).then((res) => {
        if (res.status == 200) {
          alert("file deleted");
          getArrivalDetails(arrivalDetailId);
        }
      });
    } else if (id === 2) {
      await deleteDocument(visaFile[0].documentId).then((res) => {
        if (res.status == 200) {
          alert("file deleted");
          getArrivalDetails(arrivalDetailId);
        }
      });
    } else if (id === 3) {
      await deleteDocument(attenderVisaFile[0].documentId).then((res) => {
        if (res.status == 200) {
          alert("file deleted");
          getArrivalDetails(arrivalDetailId);
        }
      });
    }
  };

  const handleTicketFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setTicketFiles((state) => [...state, file]);
  };

  const addArrivalDetails = async () => {
    formData.userTxnId = userTrxnId;
    formData.arrivalDate = moment(formData.arrivalDate).format().split("+")[0];
    const response = await saveArrivalDetails(formData);
    if (response.status == 200) {
      alert("Saved Succesfully");
      getArrivalDetails(response.data.arrivalDetailId);
      uploadPatientDocs(response.data.arrivalDetailId);
      uploadVisaDocs(response.data.arrivalDetailId);
      uploadAttenderVisaDocs(response.data.arrivalDetailId);
    } else {
      alert("Something went wrong");
    }
  };

  const getArrivalDetails = async (detailId) => {
    const response = await fetchArrivalDetails(detailId);
    if (response.status == 200) {
      if (response.data) {
        setIsEditable(false);
        loadDocuments(detailId);
        loadVisaDocuments(detailId);
        loadAttenderVisaDocuments(detailId);
        setFormData(response.data);
      }
    }
  };

  const uploadPatientDocs = (detailId) => {
    if (ticketFiles.length === 0) {
      return;
    }

    let formData = new FormData();
    ticketFiles.forEach((doc) => {
      formData.append("arrivalDocs", doc);
    });
    formData.append("documentContext", "ARRIVAL_DOCUMENT");
    formData.append("arrivalDetailId", detailId);
    formData.append("category", "ticket_copy");

    uploadArrivalDocuments(formData)
      .then((data) => {
        loadDocuments(detailId);
      })
      .catch((err) => {});
  };

  const loadDocuments = (detailId) => {
    getAllDocuments(detailId, "ARRIVAL_DOCUMENT", "ticket_copy")
      .then((res) => {
        setTicketFiles(res.data);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleVisaFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setVisaFile((state) => [...state, file]);
  };

  const handleAttenderVisaFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setAttenderVisaFile((state) => [...state, file]);
  };

  const loadVisaDocuments = (detailId) => {
    getAllDocuments(detailId, "ARRIVAL_DOCUMENT", "patient_visa")
      .then((res) => {
        setVisaFile(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const loadAttenderVisaDocuments = (detailId) => {
    getAllDocuments(detailId, "ARRIVAL_DOCUMENT", "attender_visa")
      .then((res) => {
        setAttenderVisaFile(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const uploadVisaDocs = (detailId) => {
    if (visaFile.length === 0) {
      return;
    }

    let formData = new FormData();
    visaFile.forEach((doc) => {
      formData.append("arrivalDocs", doc);
    });
    formData.append("documentContext", "ARRIVAL_DOCUMENT");
    formData.append("arrivalDetailId", detailId);
    formData.append("category", "patient_visa");

    uploadArrivalDocuments(formData)
      .then((data) => {
        loadVisaDocuments(detailId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadAttenderVisaDocs = (detailId) => {
    if (attenderVisaFile.length === 0) {
      return;
    }

    let formData = new FormData();
    attenderVisaFile.forEach((doc) => {
      formData.append("arrivalDocs", doc);
    });
    formData.append("documentContext", "ARRIVAL_DOCUMENT");
    formData.append("arrivalDetailId", detailId);
    formData.append("category", "attender_visa");

    uploadArrivalDocuments(formData)
      .then((data) => {
        loadAttenderVisaDocuments(detailId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getArrivalDetails(arrivalDetailId);
  }, []);

  return (
    <div>
      <div className="row">
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, centerName: e.target.value }))
          }
          label="Hospital Name"
          value={formData.centerName}
        />
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, preferredCity: e.target.value }))
          }
          label="Preffered City"
          value={formData.preferredCity}
        />
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, preferredDoctor: e.target.value }))
          }
          label="Preferred Doctor"
          value={formData.preferredDoctor}
        />
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, contactNumber: e.target.value }))
          }
          label="Contact Number"
          value={formData.contactNumber}
        />
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, originCity: e.target.value }))
          }
          label="Origin City"
          value={formData.originCity}
        />

        <div className="col-lg-2">
          {isEditable ? (
            <>
              <p
                style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}
              >
                Arrival Date
              </p>

              <ReactDatePicker
                className="myDatePicker"
                selected={
                  formData.arrivalDate
                    ? new Date(formData.arrivalDate)
                    : new Date()
                }
                onChange={(date) => {
                  setFormData((s) => ({ ...s, arrivalDate: date }));
                }}
                // minDate={new Date()}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </>
          ) : (
            <Grid container direction="column">
              <b>Date Time</b>
              <span>
                {moment(formData.arrivalDate).format("DD-MM-YYYY hh:mm a")}
              </span>
            </Grid>
          )}
        </div>

        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, fromAirport: e.target.value }))
          }
          label="From Airport"
          value={formData.fromAirport}
        />
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, toAirport: e.target.value }))
          }
          label="To Airport"
          value={formData.toAirport}
        />
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, flightDetails: e.target.value }))
          }
          label="Flight Details"
          value={formData.flightDetails}
        />
        <TextInput
          isEditable={isEditable}
          onChange={(e) =>
            setFormData((s) => ({ ...s, numberOfPassengers: e.target.value }))
          }
          label="Number of Passengers"
          value={formData.numberOfPassengers}
        />

        <div>
          <label style={{ fontWeight: isEditable ? "normal" : "bold" }}>
            Pickup Type
          </label>
          <select
            style={{
              marginLeft: "12px",
              marginTop: "25px",
              width: "200px",
              height: "40px",
            }}
            value={formData.pickupArrangedBy}
            onChange={(e) =>
              setFormData((s) => ({
                ...s,
                pickupArrangedBy: e.target.value === "" ? null : e.target.value,
              }))
            }
            disabled={!isEditable}
          >
            <option value="">Select Pickup Type</option>
            {pickupArrangedBy.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <hr style={{ marginTop: "10px" }} />

        {/* Tickets Document */}
        <div className="col-10" style={{ marginBottom: 20 }}>
          {isEditable ? (
            <div>
              <label
                for="ticketDoc"
                className="btn"
                style={{
                  width: 160,
                  backgroundColor: "#06c6a2",
                  color: "white",
                }}
              >
                Upload Tickets
              </label>
              <br></br>
              <input
                id="ticketDoc"
                style={{
                  width: 100,
                  cursor: "pointer ",
                  display: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 3,
                  paddingTop: 3,
                }}
                type="file"
                ref={ticketFile}
                onChange={handleTicketFile.bind(this)}
              />
              {ticketFiles.length > 0 &&
                ticketFiles?.map((item, index) => {
                  return (
                    <>
                      <span
                        key={index}
                        style={{
                          border: "1px solid gray",
                          marginLeft: 10,
                          cursor: "pointer ",
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingTop: 5,
                          backgroundColor: "#e5e5e5",
                        }}
                        onClick={() => removeItem(item.id)}
                      >
                        {item.name}
                      </span>
                      <span
                        style={{
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingBottom: 10,
                          paddingTop: 5,
                          backgroundColor: "#06c6a2",
                          border: "1px solid gray",
                          color: "white",
                          cursor: "pointer ",
                        }}
                        onClick={() => removeItem(item.id)}
                      >
                        X
                      </span>
                    </>
                  );
                })}
            </div>
          ) : (
            <Grid container direction="column">
              <b>Ticket Copy</b>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                }}
              >
                {ticketFiles.length > 0 &&
                  ticketFiles?.map((doc, index) => {
                    return (
                      <b
                        style={{
                          width: 160,
                          height: 40,
                          backgroundColor: "lightgray",
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          borderRadius: 20,
                        }}
                      >
                        <Link
                          to="#"
                          className="pointer"
                          onClick={() =>
                            downloadDocument(
                              doc.documentType,
                              doc.document,
                              doc.documentName
                            )
                          }
                        >
                          {`Document ${index + 1}`}
                        </Link>
                        <MdDeleteForever
                          color="red"
                          size={25}
                          onClick={() => deleteDocumentItem(1)}
                        />
                      </b>
                    );
                  })}
              </div>
            </Grid>
          )}
        </div>
        {/* Tickets Document */}

        {/* Passenger Visa Doc */}
        <div className="col-10" style={{ marginBottom: 20 }}>
          {isEditable ? (
            <div>
              <label
                for="visaDoc"
                className="btn"
                style={{
                  width: 200,
                  backgroundColor: "#06c6a2",
                  color: "white",
                }}
              >
                Upload Patient Visa
              </label>
              <br></br>
              <input
                id="visaDoc"
                style={{
                  width: 100,
                  cursor: "pointer ",
                  display: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 3,
                  paddingTop: 3,
                }}
                type="file"
                ref={visa}
                onChange={handleVisaFile.bind(this)}
              />
              {visaFile.length > 0 &&
                visaFile?.map((item, index) => {
                  return (
                    <>
                      <span
                        key={index}
                        style={{
                          border: "1px solid gray",
                          marginLeft: 10,
                          cursor: "pointer ",
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingTop: 5,
                          backgroundColor: "#e5e5e5",
                        }}
                        onClick={() => removeVisaDoc(item.id)}
                      >
                        {item.name}
                      </span>
                      <span
                        style={{
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingBottom: 10,
                          paddingTop: 5,
                          backgroundColor: "#06c6a2",
                          border: "1px solid gray",
                          color: "white",
                          cursor: "pointer ",
                        }}
                        onClick={() => removeVisaDoc(item.id)}
                      >
                        X
                      </span>
                    </>
                  );
                })}
            </div>
          ) : (
            <Grid container direction="column">
              <b>Patient Visa</b>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                }}
              >
                {visaFile.length > 0 &&
                  visaFile?.map((doc, index) => {
                    return (
                      <b
                        style={{
                          width: 160,
                          height: 40,
                          backgroundColor: "lightgray",
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          borderRadius: 20,
                        }}
                      >
                        <Link
                          to="#"
                          className="pointer"
                          onClick={() =>
                            downloadDocument(
                              doc.documentType,
                              doc.document,
                              doc.documentName
                            )
                          }
                        >
                          {`Document ${index + 1}`}
                        </Link>
                        <MdDeleteForever
                          color="red"
                          size={25}
                          onClick={() => deleteDocumentItem(2)}
                        />
                      </b>
                    );
                  })}
              </div>
            </Grid>
          )}
        </div>
        {/* Passenger Visa Doc */}

        {/* Attender Visa Doc */}
        <div className="col-10" style={{ marginBottom: 20 }}>
          {isEditable ? (
            <div>
              <label
                for="attenderVisaDoc"
                className="btn"
                style={{
                  width: 200,
                  backgroundColor: "#06c6a2",
                  color: "white",
                }}
              >
                Upload Attender Visa
              </label>
              <br></br>
              <input
                id="attenderVisaDoc"
                style={{
                  width: 100,
                  cursor: "pointer ",
                  display: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 3,
                  paddingTop: 3,
                }}
                type="file"
                ref={attenderVisa}
                onChange={handleAttenderVisaFile.bind(this)}
              />
              {attenderVisaFile.length > 0 &&
                attenderVisaFile.map((item, index) => {
                  return (
                    <>
                      <span
                        key={index}
                        style={{
                          border: "1px solid gray",
                          marginLeft: 10,
                          cursor: "pointer ",
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingTop: 5,
                          backgroundColor: "#e5e5e5",
                        }}
                        onClick={() => removeAttenderVisaDoc(item.id)}
                      >
                        {item.name}
                      </span>
                      <span
                        style={{
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingBottom: 10,
                          paddingTop: 5,
                          backgroundColor: "#06c6a2",
                          border: "1px solid gray",
                          color: "white",
                          cursor: "pointer ",
                        }}
                        onClick={() => removeAttenderVisaDoc(item.id)}
                      >
                        X
                      </span>
                    </>
                  );
                })}
            </div>
          ) : (
            <Grid container direction="column">
              <b>Attender Visa</b>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                }}
              >
                {attenderVisaFile.length > 0 &&
                  attenderVisaFile?.map((doc, index) => {
                    return (
                      <b
                        style={{
                          width: 160,
                          height: 40,
                          backgroundColor: "lightgray",
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          borderRadius: 20,
                        }}
                      >
                        <Link
                          to="#"
                          className="pointer"
                          onClick={() =>
                            downloadDocument(
                              doc.documentType,
                              doc.document,
                              doc.documentName
                            )
                          }
                        >
                          {`Document ${index + 1}`}
                        </Link>
                        <MdDeleteForever
                          color="red"
                          size={25}
                          onClick={() => deleteDocumentItem(3)}
                        />
                      </b>
                    );
                  })}
              </div>
            </Grid>
          )}
        </div>
        {/* Attender Visa Doc */}

        <div class="col-lg-4 mt-5">
          {isEditable ? (
            <>
              <span>Comments</span>
              <textarea
                className="comments-section"
                rows={5}
                value={formData.comments}
                onChange={(e) =>
                  setFormData((s) => ({ ...s, comments: e.target.value }))
                }
                placeholder="Comments"
              ></textarea>
            </>
          ) : (
            <Grid container direction="column">
              <b>Comments</b>
              <span>{formData.comments}</span>
            </Grid>
          )}
        </div>

        <hr style={{ marginTop: "10px" }} />
      </div>
      {isEditable ? (
        <div className="col-lg-2">
          <button
            onClick={() => addArrivalDetails()}
            style={{ width: 70, padding: 8, marginTop: 20 }}
          >
            Save
          </button>
        </div>
      ) : (
        <div className="col-lg-2">
          <button
            onClick={() => setIsEditable(true)}
            style={{ width: 70, padding: 8, marginTop: 20 }}
          >
            Update
          </button>
        </div>
      )}
    </div>
  );
}
