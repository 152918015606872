import axios from "axios";
import { APP_URL } from "../../util/AppConstants";

import { getAuthHeader, getUserCode, getUserRole } from "../../store/DataStore";

const userRole = getUserRole();

export const getAllReports = async (postData) => {

  if (userRole === 'SALES_AGENT') {
    postData.assignTo = getUserCode();
    postData.sourceId = 0;
  }

  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.post(`${APP_URL}/mykare/report/all`, postData,
    {
      headers,
    }
  );
  return data;
};
