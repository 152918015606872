import React,{useEffect,useState} from 'react'
import "./VoiceMessage.css"

import MicRecorder from 'mic-recorder-to-mp3';
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

// Materail ui Icons
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import TemplateRecored from './TemplateRecored';

function VoiceMessage(props) {
    // const [isRecording, setIsRecording] = useState(false) 
    // const [blobURL, setBlobURL] = useState("") 
    // const [isBlocked, setBlocked] = useState(false) 



    // props 
    const {globalVoiceMessage,
            sendDocument,
            patientId,
            isrecording,
            Recorder,
            blobURL,
            file,
            isBlocked} = props

    // useEffect 
    useEffect(() => {
        console.log('permission req')
        navigator.mediaDevices
        .getUserMedia({audio:true})
        .then((stream) => {
    /* use the stream */
         console.log("premission granded" ,stream)
        //  setBlocked(false)
         globalVoiceMessage({
            Recorder:false,
            isrecording:false,
            isBlocked:false,
            blobURL:"",
            file:""
        })
        })
            .catch((err) => {
                /* handle the error */
                globalVoiceMessage({
                    Recorder:false,
                    isrecording:false,
                    isBlocked:true,
                    blobURL:"",
                    file:""
                })
                // setBlocked(true)
                console.log(err)
            });
        },[]);

    //
    const start = () => {
        if (isBlocked) {
          console.log('Permission Denied');
        } else {
          Mp3Recorder
            .start()
            .then(() => {
                globalVoiceMessage({
                    Recorder:true,
                    isrecording:true,
                    isBlocked:true,
                    blobURL:"",
                    file:""
                })
             
            }).catch((e) => console.error(e));
        }
      };
      
    
     const  stop = () => {
        Mp3Recorder
          .stop()
          .getMp3()
          .then(([buffer, blob]) => {

            const file = new File(buffer, 'me-at-thevoice.mp3', {
                type: blob.type,
                lastModified: Date.now()
              });
            const blobURL = URL.createObjectURL(blob)
            globalVoiceMessage({
                Recorder:true,
                isrecording:false,
                isBlocked:false,
                blobURL:blobURL,
                file:file
            })

            // this.setState({ blobURL, isRecording: false });
          }).catch((e) => console.log(e));
      };
      const onSend =async()=>{
        await sendDocument(file,patientId)
        await globalVoiceMessage({
            Recorder:false,
            isrecording:false,
            isBlocked:false,
            blobURL:"",
            file:""
        })
      }
        const onDelete = async()=>{
        await stop();
        await globalVoiceMessage({
            Recorder:false,
            isrecording:false,
            isBlocked:false,
            blobURL:"",
            file:""
        })
      }
      

  return (
    <div>
       
        <button 
            onClick={isrecording?stop:start} 
            className='VoiceMessage-Toggle-Btn'
            style={isrecording?{backgroundColor:"red"}:{backgroundColor:"green"}}
        >
            <KeyboardVoiceIcon fontSize ='large'/>
            
        </button>
       
    </div>
  )
}

export default VoiceMessage

// isrecording,Recorder,blobURL, file,



// const  stop = () => {
//     Mp3Recorder
//       .stop()
//       .getMp3()
//       .then(([buffer, blob]) => {

//         const file = new File(buffer, 'me-at-thevoice.mp3', {
//             type: blob.type,
//             lastModified: Date.now()
//           });
//         const blobURL = URL.createObjectURL(blob)
//         setBlobURL(blobURL)
//         setIsRecording(false)
//         sendDocument(file,patientId)
           
//         // this.setState({ blobURL, isRecording: false });
//       }).catch((e) => console.log(e));
//   };