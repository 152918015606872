import React, { Fragment } from "react";
import "./../Patients.css";
import DatePicker from "react-datepicker";
import useDiagnostic from "./useDiagnostic.logic";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { Autocomplete, Grid, TextField, Typography, Link } from "@mui/material";
import AlertDialogSlide from "../../PopUp/PopUp";

function Diagnostic({ patientDetail, activeTab }) {
  const state = useDiagnostic(patientDetail);
  const tabTitle = (activeTab ? "Edit" : "Add") + " Diagnostic Details";
  return (
    <Grid item sx={{ width: "100%" }}>
      <Grid
        item
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ width: "100%", height: 100, backgroundColor: "#EFF5F5" }}
      >
        <Typography sx={{ fontSize: "25px", fontWeight: "600" }}>
          {tabTitle}
        </Typography>
      </Grid>
      <Grid item container sx={{ width: "100%" }}>
        <Grid item lg={2} sx={{ width: "10%", height: 100 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Center Name
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={state?.centersList || []}
            sx={{
              width: 245,
              marginTop: "5px",
            }}
            value={state?.selectedCenter || ""}
            onChange={(e, val) => state?.handleCenterDetails(val)}
            isOptionEqualToValue={(option, value) =>
              option === value || value === ""
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Name" />
            )}
          />
        </Grid>
        <Grid item lg={2} sx={{ width: "10%", height: 100 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Enquiry Name
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={state?.agentsList || []}
            sx={{
              width: 245,
              marginTop: "5px",
            }}
            value={state?.selectedAgent || ""}
            onChange={(e, val) => state?.handleAgentDetails(val)}
            isOptionEqualToValue={(option, value) =>
              option === value || value === ""
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Enquiry By" />
            )}
          />
        </Grid>
        <Grid item lg={2} sx={{ width: "10%", height: 100 }}>
          <Grid item sx={{ marginTop: "5px" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              Preferred Date
            </Typography>
            <DatePicker
              placeholderText="Date"
              className="searchField"
              selected={state?.selectedPreferredDate}
              onChange={(date) => {
                state?.setSelectedPreferredDate(date);
              }}
            />
          </Grid>
        </Grid>
        <Grid item lg={2} sx={{ width: "10%", height: 100 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Test Name
          </Typography>
          <input
            className=""
            placeholder="Name"
            style={{
              height: 55,
              borderRadius: 5,
              border: `1px solid #c4c4c4`,
              display: "flex",
              width: 245,
              paddingLeft: 10,
              marginTop: "5px",
            }}
            value={state?.testName}
            onChange={(e) => state?.setTestName(e?.target?.value)}
          />
        </Grid>
        <Grid item lg={2} sx={{ width: "10%", height: 100 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Preferred City
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={state?.citiesList || []}
            sx={{
              width: 245,
              marginTop: "5px",
            }}
            value={state?.selectedCity || ""}
            onChange={(e, val) => state?.setSelectedCity(val?.label)}
            isOptionEqualToValue={(option, value) =>
              option === value || value === ""
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="City" />
            )}
          />
        </Grid>

        <input
          type="file"
          accept="image/png"
          onChange={state?.handleFileUploadObj}
          ref={state?.fileInputRef}
          style={{ display: "none" }}
        />
        <Grid item lg={12} container>
          <Grid item lg={3} sx={{ width: "100%", height: 100 }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              Add Comments
            </Typography>
            <textarea
              placeholder="Comments..."
              style={{
                width: "100%",
                minHeight: 100,
                border: `1px solid #c4c4c4`,
              }}
              value={state?.comments}
              onChange={(e) => state?.setComments(e?.target?.value)}
            />
          </Grid>
          <Grid item container lg={9}>
            <Grid
              item
              sx={{
                marginTop: "35px",
                display: "flex",
                marginLeft: "20px",
              }}
            >
              {!state?.isEdit ? (
                <Fragment>
                  {!state?.documentFile?.length > 0 ? (
                    <>
                      {state?.fileList &&
                        state?.fileList.map((file, index) => {
                          return (
                            <Grid
                              item
                              sx={{ width: "200px" }}
                              container
                              key={index}
                            >
                              <Grid
                                item
                                container
                                justifyContent={"flex-end"}
                                alignItems={"flex-end"}
                                sx={{
                                  width: 180,
                                  height: 100,
                                  backgroundColor: "#CCF2F4",
                                  border: "3px dotted black",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={URL.createObjectURL(file?.image)}
                                  alt=""
                                  style={{ width: "100%", height: "100%" }}
                                />
                                {!state?.isDataLoaded && (<Grid
                                  item
                                  container
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  onClick={() => state?.handleDeleteFile(index)}
                                  sx={{
                                    width: "30px",
                                    height: "30px",
                                    backgroundColor: "#a4ebf3",
                                    position: "absolute",
                                    cursor: "pointer",
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </Grid>)}
                              </Grid>
                              <Grid
                                item
                                container
                                sx={{
                                  width: 180,
                                  backgroundColor: "#34b3f1",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  border: "1px solid #ccc",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "14px", color: "white" }}
                                >
                                  {file?.name}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                    </>
                  ) : (
                    <div
                      style={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {state?.documentFile?.map((doc, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              marginRight: "20px",
                            }}
                          >
                            <div
                              style={{
                                width: "100px",
                                height: "40px",
                                backgroundColor: "#34b3f1",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              <Link
                                className="pointer"
                                onClick={() =>
                                  state?.downloadDocument(doc.fileName)
                                }
                                style={{
                                  color: "white",
                                  textDecoration: "none",
                                }}
                              >
                                {`Document ${index + 1}`}
                              </Link>
                            </div>

                            <div
                              style={{
                                width: "50px",
                                height: "40px",
                                backgroundColor: "#6da9e4",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => state?.handleClickOpen(doc?.id)}
                            >
                              <DeleteSweepIcon />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {state?.fileList &&
                    state?.fileList.map((file, index) => {
                      return (
                        <Grid
                          item
                          sx={{ width: "200px" }}
                          container
                          key={index}
                        >
                          <Grid
                            item
                            container
                            justifyContent={"flex-end"}
                            alignItems={"flex-end"}
                            sx={{
                              width: 180,
                              height: 100,
                              backgroundColor: "#CCF2F4",
                              border: "3px dotted black",
                              position: "relative",
                            }}
                          >
                            <img
                              src={URL?.createObjectURL(file?.image)}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                            {!state?.isDataLoaded && (
                              <Grid
                                item
                                container
                                justifyContent={"center"}
                                alignItems={"center"}
                                onClick={() => state?.handleDeleteFile(index)}
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  backgroundColor: "#a4ebf3",
                                  position: "absolute",
                                  cursor: "pointer",
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </Grid>
                            )}
                          </Grid>
                          <Grid
                            item
                            container
                            sx={{
                              width: 180,
                              backgroundColor: "#34b3f1",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              border: "1px solid #ccc",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", color: "white" }}
                            >
                              {file?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Fragment>
              )}

              <Grid
                sx={{
                  display: "flex",
                  width: "240px",
                  gap: "20px",
                }}
              >
                <Grid
                  item
                  lg={7}
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => state?.handleFileUpload()}
                  sx={{
                    width: 80,
                    height: 50,
                    backgroundColor: "#A4EBF3",
                    borderRadius: 2,
                    cursor: "pointer",
                  }}
                >
                  <Typography sx={{ fontWeight: "600" }}>Upload</Typography>
                  <UploadFileIcon />
                </Grid>

                <Grid item lg={4}>
                  <button
                    style={{
                      width: "80px",
                      height: "50px",
                      backgroundColor: "#34B3F1",
                      color: "white",
                      margin: 0,
                    }}
                    onClick={state?.submitEnquiryDetails}
                  >
                    Save
                  </button>
                </Grid>
                <AlertDialogSlide
                  open={state?.open}
                  handleDelete={state?.handleDelete}
                  handleClose={state?.handleClose}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Diagnostic;
