import React, { useEffect, useState } from "react";
import "./Enquiry.css";
import "../../Styles/Common.css";
import "../../Styles/sideBar.css";
import { NavLink } from "react-router-dom";
import { checkPermission } from "../../util/permissionUtils";
import Badge from "react-bootstrap/Badge";
import { getFirebaseToken, onForegroundMessage } from "../../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  getTopBarStatistics,
  saveUserBreaks,
} from "../../services/mykare/enquiryService";
import {
  ButtonBase,
  Dialog,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { FiBell } from "react-icons/fi";
import { Grid } from "@material-ui/core";
import { getBreakButtonText } from "../../util/getBreakText";
import { getUserRole } from "../../store/DataStore";

function TopBar(props) {
  const [kareChatCount, setKareChatCount] = useState(null);
  const [startForeground, setStartForground] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [open, setOpen] = useState(false);

  const menuOpen = Boolean(anchorEl);

  const role = getUserRole();

  const menuItems = [
    { name: "Tea Break ☕", value: "TEA" },
    { name: "Lunch Break 🍚", value: "LUNCH" },
    { name: "Emergency Break 🚨", value: "EMERGENCY" },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setOpen(false);
  const handleMenuClose = async (breakType) => {
    const postData = {
      breakType: breakType,
    };
    const response = await saveUserBreaks(postData);
    if (response.status == 200) {
      props.onBreakClick();
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    getTopBarStatistics()
      .then((response) => {
        setKareChatCount(response.data.kareChatCount);
      })
      .catch((err) => console.log("getTopBarStatistics ", err));
  }, []);

  useEffect(() => {
    console.log("=======onForegroundMessage======");
    onForegroundMessage()
      .then((payload) => {
        console.log("Received foreground message: ", payload);
        const {
          notification: { title, body },
        } = payload;
        setModalMessage(body);
        setModalTitle(title);

        // toast(body, {onClose: () => {
        //   console.log('Called when I close');
        //   setStartForground(!startForeground);
        // }});
        setOpen(true);
      })
      .catch((err) =>
        console.log(
          "An error occured while retrieving foreground message. ",
          err
        )
      );

    //handleGetFirebaseToken();
  }, [startForeground]);

  const flexStyle = {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <>
      {/* <ToastContainer
      position="top-center"
      bodyClassName={{
        backgroundColor: 'blue',
        height: '100%',
        width: "500px",
      }}
      
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      /> */}

      <Dialog open={open} onClose={handleClose}>
        <div
          style={{
            backgroundColor: "#f1f3f4",
            width: "400px",
            height: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              backgroundColor: "#00997c",
              width: "100%",
              height: 40,
              color: "white",
            }}
          >
            <span
              style={{
                marginLeft: "10px",
                fontSize: "20px",
              }}
            >
              {modalTitle}
            </span>
            <span
              style={{
                color: "white",
                width: "60px",
                fontSize: "25px",
                marginLeft: "230px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              &times;
            </span>
          </div>
          <h4
            style={{
              fontSize: "18px",
            }}
          >
            <center>{modalMessage}</center>
          </h4>
          <div style={{ marginLeft: "auto", padding: "10px" }}>
            <Button
              style={{
                backgroundColor: "red",
                borderRadius: "10px",
                color: "white",
                width: "30px",
                textTransform: "capitalize",
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
      <Grid
        container
        item
        lg={12}
        style={{ width: "100%", marginBottom: "10px" }}
      >
        <Grid
          lg={role == "SALES_AGENT" ? 8 : 12}
          item
          style={{ display: "flex", gap: 20 }}
          className="my-header-label"
        >
          {checkPermission("LEADS_TAB") ? (
            <NavLink to="/enquiry" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  cursor: "pointer",
                  marginBottom: 0,
                }}
              >
                Leads
              </h5>
            </NavLink>
          ) : null}
          {checkPermission("LEADS_TAB") ? (
            <a
              href={`${process.env.REACT_APP_REPORT_MODULE_URL}${
                localStorage.getItem("user-role") === "SALES_AGENT"
                  ? "/kareflow-focus"
                  : "/kareflow-focus/admin"
              } `}
              style={flexStyle}
            >
              <h5
                style={{
                  color: "white",
                  cursor: "pointer",
                  marginBottom: 0,
                }}
              >
                Focus Mode Beta
              </h5>
            </a>
          ) : null}
          {checkPermission("LEADS_TAB") ? (
            <NavLink to="/otherlead" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  cursor: "pointer",
                  marginBottom: 0,
                }}
              >
                Other Leads
              </h5>
            </NavLink>
          ) : null}

          {checkPermission("SCAN_TAB") && (
            <NavLink to="/scan" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  cursor: "pointer",
                  marginBottom: 0,
                }}
              >
                Scan
              </h5>
            </NavLink>
          )}

          <NavLink to="/kareplus" style={flexStyle}>
            <h5
              style={{
                color: "white",
                cursor: "pointer",
                marginBottom: 0,
              }}
            >
              Kare Plus
            </h5>
          </NavLink>

          {checkPermission("PRE_LEADS_TAB") && (
            <NavLink to="/preleads" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  cursor: "pointer",
                  marginBottom: 0,
                }}
              >
                Pre Leads
              </h5>
            </NavLink>
          )}
          {checkPermission("PC_TAB") && (
            <NavLink to="/preventiveCare" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                Preventive Care
              </h5>
            </NavLink>
          )}
          {checkPermission("SMO_TAB") && (
            <NavLink to="/smo" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                SMO
              </h5>
            </NavLink>
          )}
          {checkPermission("ADD_ON_TAB") && (
            <NavLink to="/addOnService" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                Add On Service
              </h5>
            </NavLink>
          )}
          {checkPermission("PARTNERSHIP_TAB") && (
            <NavLink to="/partnership" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                Partnership
              </h5>
            </NavLink>
          )}
          {checkPermission("STATISTICS_TAB") && (
            <NavLink to="/report" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                Statistics
              </h5>
            </NavLink>
          )}
          {checkPermission("HOSPITAL_TAB") && (
            <NavLink to="/hospital" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                Hospitals
              </h5>
            </NavLink>
          )}
          {checkPermission("DOCTOR_TAB") && (
            <NavLink to="/doctors" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                Doctors
              </h5>
            </NavLink>
          )}
          {checkPermission("INSURANCE_TAB") && (
            <NavLink to="/insurance" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                Insurance Leads
              </h5>
            </NavLink>
          )}
          {checkPermission("IP_OP_TAB") && (
            <NavLink to="/ipOp" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                Ip/Op Leads
              </h5>
            </NavLink>
          )}
          {checkPermission("KARECHAT_TAB") && (
            <NavLink to="/chatpage" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                KareChat{" "}
                {kareChatCount > 0 && (
                  <Badge bg="danger">{kareChatCount}</Badge>
                )}
              </h5>
            </NavLink>
          )}
          {checkPermission("SETTINGS_TAB") && (
            <NavLink to="/settings" style={flexStyle}>
              <h5
                style={{
                  color: "white",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                Settings
              </h5>
            </NavLink>
          )}
        </Grid>
        {role == "SALES_AGENT" && (
          <Grid item lg={4}>
            <Grid
              item
              lg={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                container
                item
                style={{
                  justifyContent: "flex-end",
                  alignItems: "start",
                }}
                spacing={2}
              >
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={menuOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                    onClick={handleClick}
                    sx={{
                      padding: "20px 15px",
                      height: 50,
                      width: "200px",
                      fontSize: "14px",
                      margin: "5px 5px",
                      bgcolor: props.isOnBreak ? "#CCCCCC" : "#1a1a1a",
                      color: "#FFFFFF ",
                      fontWeight: 600,
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#FFFFFF",
                        color: "#1a1a1a",
                      },
                    }}
                    variant="contained"
                  >
                    {getBreakButtonText(props.isOnBreak)}
                  </Button>
                  <Menu
                    sx={{
                      mt: "1px",
                      "& .MuiMenu-paper": {
                        backgroundColor: "#1a1a1a",
                        color: "white",
                        width: "200px",
                        ":hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#1a1a1a",
                        },
                      },
                    }}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    onClose={() => setAnchorEl(null)}
                  >
                    {menuItems.map((val) => {
                      return (
                        <MenuItem onClick={() => handleMenuClose(val.value)}>
                          {val.name}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
                <Button
                  onClick={props.onClock}
                  sx={{
                    padding: "20px",
                    height: 50,
                    fontSize: "14px",
                    width: "200px",
                    margin: "5px 5px",
                    bgcolor: props.isClockIn ? "#1a1a1a" : "#FFFFFF",
                    color: props.isClockIn ? "#FFFFFF" : "#000000 ",
                    fontWeight: 600,
                    textTransform: "capitalize",
                    ":hover": { backgroundColor: "#404040", color: "#FFFFFF" },
                  }}
                  variant="contained"
                >
                  {props.isClockIn ? `⏰ Clock-Out` : "⏰ Clock-In"}
                </Button>
              </Grid>
            </Grid>
            <div
              style={{
                fontWeight: "normal",
                fontSize: "12px",
                color: "white",
                alignSelf: "center",
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "8px",
              }}
            >
              {props.isClockIn
                ? `Last clock-in (${props?.lastCheckedIn})`
                : `Last clock-out (${props?.lastCheckedOut})`}
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default TopBar;
