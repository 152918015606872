import moment from "moment";
import fileDownload from "js-file-download";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import {
  getAllCities,
  getAllCenters,
  getAllAgents,
  saveEnquiryDetails,
  saveEnquiryDocuments,
  deleteDocumentFile,
  loadDocumentFiles,
  downloadDocumentFile,
} from "../../../../../services/associate/patientService";

export default function useDiagnostic(patientDetail) {
  const history = useHistory();
  const fileInputRef = useRef();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [comments, setComments] = useState("");
  const [testName, setTestName] = useState("");
  const [fileList, setFileList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [centersList, setCentersList] = useState([]);
  const [documentFile, setDocumentFile] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const data = history?.location?.state?.enquiryDetails;
  const [selectedAgent, setSelectedAgent] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState("");
  const [documentFileId, setDocumentFileId] = useState({});
  const [selectedAgentId, setSelectedAgentId] = useState({});
  const [selectedCenterId, setSelectedCenterId] = useState({});
  const [selectedArrivalDate, setSelectedArrivalDate] = useState("");
  const [selectedPreferredDate, setSelectedPreferredDate] = useState("");
  

  const formattedDate = moment(selectedPreferredDate).format();
  const handleDateChange = (e) => {
    setSelectedArrivalDate(e.target.value);
  };

  const handleFileUploadObj = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files).map((file) => ({
      image: file,
      name: file.name,
    }));
    setFileList([...fileList, ...newFiles]);
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
    setIsEdit(true);
  };

  const handleClickOpen = (documentId) => {
    setDocumentFileId(documentId);
    setOpen(true);
  };

  const handleDelete = () => {
    deleteDocumentFile(documentFileId).then((res) => {
      if (res?.status === 200) {
        setIsDataLoaded(true);
        setOpen(false);
      }
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteFile = (indexToDelete) => {
    setFileList((prevFileList) => {
      const updatedFileList = prevFileList.filter(
        (_, index) => index !== indexToDelete
      );
      return updatedFileList;
    });
  };
  const submitDocumentsFile = async (getEnquiryId) => {
    try {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("enquiryDoc", file?.image);
      });
      formData.append("enquiryId", getEnquiryId);
      formData.append("type", "DIAGNOSTIC");
      const response = await saveEnquiryDocuments(formData);
      console.log("Response from saveEnquiryDocuments:", response?.data);
    } catch (error) {
      console.error("Error submitting documents:", error);
    }
  };

  const loadDocuments = () => {
    loadDocumentFiles(patientDetail?.id).then((res) => {
      setDocumentFile(res?.data);
    });
  };

  const downloadDocument = (fileName) => {
    downloadDocumentFile(fileName).then((res) => {
      fileDownload(res.data, fileName);
    });
  };

  const handleAgentDetails = (val) => {
    setSelectedAgentId(val?.id);
    setSelectedAgent(val?.label);
  };
  const handleCenterDetails = (val) => {
    setSelectedCenterId(val?.id);
    setSelectedCenter(val?.label);
  };

  const loadAllCities = () => {
    getAllCities().then((data) => {
      setCitiesList(
        data?.map((list) => {
          return {
            label: list?.name,
            id: list?.id,
          };
        })
      );
    });
  };
  const loadAllCenters = () => {
    getAllCenters().then((data) => {
      setCentersList(
        data?.map((list) => {
          return {
            label: list?.name,
            id: list?.id,
          };
        })
      );
    });
  };

  const submitEnquiryDetails = () => {
    if (
      formattedDate &&
      selectedCenter &&
      comments &&
      selectedAgent &&
      selectedCity &&
      testName
    ) {
      const postData = {
        arrivalDate: formattedDate,
        centerId: selectedCenterId,
        centerName: selectedCenter,
        dateTime: formattedDate,
        comment: comments,
        enquiryByAgentId: selectedAgentId,
        enquiryByAgentName: selectedAgent,
        preferredCity: selectedCity,
        type: "diagnostic",
        patientId: data?.id,
        patientName: data?.name,
        testName,
        id: patientDetail?.id ? patientDetail?.id : null,
      };
      saveEnquiryDetails(postData).then((res) => {
        submitDocumentsFile(res?.data?.id);
        if (res.status === 200) {
          alert("successfully save");
          setIsDataLoaded(true);
          window.location.reload();
        }
      });
    } else {
      alert("Please fill in all required fields before saving.");
    }
  };
  const loadAllAgents = () => {
    getAllAgents().then((data) => {
      setAgentsList(
        data?.map((list) => {
          return {
            label: list?.name,
            id: list?.id,
          };
        })
      );
    });
  };
  useEffect(() => {
    loadDocuments();
    loadAllCities();
    loadAllAgents();
    loadAllCenters();
    if (patientDetail?.enquiryByAgentId) {
      setComments(patientDetail?.comment);
      setTestName(patientDetail?.testName);
      setSelectedCenter(patientDetail?.centerName);
      setSelectedCenterId(patientDetail?.centerId);
      setSelectedCity(patientDetail?.preferredCity);
      setSelectedAgent(patientDetail?.enquiryByAgentName);
      setSelectedAgentId(patientDetail?.enquiryByAgentId);
      setSelectedPreferredDate(new Date(patientDetail?.arrivalDate));
    }
  }, []);
  useEffect(()=>{loadDocuments()},[isDataLoaded])

  return {
    open,
    isEdit,
    fileList,
    comments,
    testName,
    agentsList,
    citiesList,
    centersList,
    setComments,
    setTestName,
    handleClose,
    isDataLoaded,
    fileInputRef,
    documentFile,
    selectedCity,
    handleDelete,
    selectedAgent,
    selectedCenter,
    setSelectedCity,
    handleClickOpen,
    handleFileUpload,
    handleDeleteFile,
    handleDateChange,
    downloadDocument,
    handleAgentDetails,
    selectedArrivalDate,
    handleFileUploadObj,
    handleCenterDetails,
    submitDocumentsFile,
    submitEnquiryDetails,
    selectedPreferredDate,
    setSelectedPreferredDate,
  };
}
