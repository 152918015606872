
import "../../Enquiry/Enquiry.css";
import "../../../Styles/Common.css";
import "../../../Styles/sideBar.css";
import "../../../Styles/Pagination.css";
import "react-pro-sidebar/dist/css/styles.css";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  getOpSubStatus,
  getSources,
  getUserTrxnStatuses,
} from "../../../services/mykare/enquiryService";
import {
  assignToKareBuddyUser,
  getAllKareBuddy,
  getFilteredIpOpEnquiries,
  getIpOpservice,
} from "../../../services/mykare/AppointmentService";
import { checkPermission } from "../../../util/permissionUtils";
import { getTreatments } from "../../../services/mykare/dataService";
import { getAllLocations } from "../../../services/mykare/centerService";
import { getAgentData } from "../../../services/commonService/commonService";

const useIpOp = () => {
  let history = useHistory();
  const location = useLocation();
  const [perPage] = useState(20);
  const [offset, setOffset] = useState(0);
  const [homeData, setHomeData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [ipOpStats, setIpOpStats] = useState(null);
  const [reload, setReload] = useState(false);
  const [type, setType] = useState("IP");
  const [sourceData, setSourceData] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedSourceIds, setSelectedSourceIds] = useState([0]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([0]);
  const [treatmentData, setTreatmentData] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [salesBdDetail, setSalesBdDetail] = useState({});
  const [kareBuddyCode, setKareBuddyCode] = useState([]);
  const [activeData, setActiveData] = useState({ isActive: false });

  const [selectedLeadStatuses, setSelectedLeadStatuses] = useState([]);
  const [leadStatusData, setLeadStatusData] = useState([]);
  const [selectedLeadStatusIds, setSelectedLeadStatusIds] = useState([0]);
  const [selectedStatusIds, setSelectedStatusIds] = useState([0]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([0]);
  const [locationData, setLocationData] = useState([]);
  const [subStatusId, setSubStatusId] = useState(null);
  const [subStatus, setSubStatus] = useState([]);
  const [selectedSubStatus, setSelectedSubStatus] = useState("");
  const [agents, setAgents] = useState([]);
  const [enquiryDateFilter, setEnquiryDateFilter] = useState([
    "All",
    "Today",
    "Yesterday",
    "Choose Date",
    "Previous Month",
  ]);
  const [selectedEnquiryDateFilter, setSelectedEnquiryDateFilter] =
    useState("All");

  const [searchEnquiryFromDate, setSearchEnquiryFromDate] = useState(
    new Date()
  );
  const [searchEnquiryToDate, setSearchEnquiryToDate] = useState(new Date());
  const [appointmentDateFilter, setAppointmentDateFilter] = useState([
    "All",
    "Today",
    "Yesterday",
    "Choose Date",
    "Previous Month",
    "This Month",
    "Tomorrow",
  ]);

  const [mainMenuItems, setMainMenuItems] = useState([
    { id: 1, label: "IP" },
    {
      id: 2,
      label: "OP",
      consultationType: [
        { id: 1, label: "ONLINE" },
        { id: 2, label: "OFFLINE" },
      ],
    },
  ]);

  const [selectedConsultationType, setSelectedCelectedConsultationType] =
    useState("IP");
  const [activeType, setIsActiveType] = useState(null);
  const [selectedConsultationMode, setSelectedConsultationMode] = useState();

  const getSubStatus = async () => {
    const response = await getOpSubStatus();
    if (response.status == 200) {
      response.data.unshift({ id: 0, name: "ALL" });
      setSubStatus(response.data);
    }
  };

  const loadAgentData = async () => {
    const response = await getAllKareBuddy();
    if (response.status === 200) {
      if (response.data) {
        setAgents(response.data);
      }
    }
  };

  const assignToKareBuddy = async (
    enquiryId,
    appointmentType,
    ipDetail,
    opDetail,
    buddyCode
  ) => {
    setKareBuddyCode(buddyCode);
    const postData = {
      userTrxnId: enquiryId,
      assignTo: buddyCode,
      type: appointmentType,
      ipDetailId: ipDetail,
      opDetailId: opDetail,
    };
    const response = await assignToKareBuddyUser(postData);
    if (response.status === 200) {
      window.location.reload();
      getData();
      alert("Successfully assigned");
    }
  };

  const handleSubStatus = (id, name) => {
    setSubStatusId(id);
    setSelectedSubStatus(name);
  };

  const [selectedAppointmentDateFilter, setSelectedAppointmentDateFilter] =
    useState("All");
  const [searchAppointmentFromDate, setSearchAppointmentFromDate] = useState(
    new Date()
  );
  const [searchAppointmentToDate, setSearchAppointmentToDate] = useState(
    new Date()
  );

  const [totalCount, setTotalCount] = useState(0);
  const appointmentType = ["IP", "OP"];
  const opStatusList = [
    "ALL",
    "SCHEDULED",
    "COMPLETED",
    "RESCHEDULED",
    "CANCELLED",
    "PLANNED",
    "REPLANNED",
  ];

  const ipStatusList = [
    "ALL",
    "SCHEDULED",
    "COMPLETED",
    "RESCHEDULED",
    "CANCELLED",
    "PLANNED",
    "REPLANNED",
  ];
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedAssignedToIds, setSelectedAssignedToIds] = useState([]);
  const [statusList, setStatusList] = useState(ipStatusList);

  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      marginTop: 10,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));

  const [pageData, setPageData] = useState(null);
  const [allData, setAllData] = useState(null);
  const getIpOpCount = async () => {
    try {
      getIpOpservice().then((res) => {
        setIpOpStats(res.data);
      });
    } catch (error) {}
  };
  const [searchItem, setsearchItem] = useState("");
  const handleSearchItemChange = (e) => {
    setsearchItem(e.target.value);
  };
  const [searchDoctor, setSearchDoctor] = useState("");
  const handleSearchDoctorChange = (e) => {
    setSearchDoctor(e.target.value);
  };
  const [searchCenter, setSearchCenter] = useState("");
  const handleSearchCenterChange = (e) => {
    setSearchCenter(e.target.value);
  };

  const [searchEnquiryId, setSearchEnquiryId] = useState("");
  const handleSearchEnquiry = (e) => {
    setSearchEnquiryId(e.target.value);
  };
  function handleClickTile(enquiryData) {
    history.push({
      pathname: "/appointmentInner",
      state: {
        enquiryData,
        selectedAssignedTo,
        selectedAssignedToIds,
        selectedStatus,
        // type,
        selectedConsultationType,
        selectedTreatmentIds,
        selectedTreatments,
        selectedAppointmentDateFilter,
        selectedEnquiryDateFilter,
        searchAppointmentFromDate,
        searchAppointmentToDate,
      },
    });
  }

  // const handleMode = (type, id) => {
  //   setType(type);
  //   if (type == "IP") {
  //     setStatusList(ipStatusList);
  //     setSubStatusId(null);
  //   } else {
  //     setStatusList(opStatusList);
  //   }
  //   setOffset(0);
  // };

  const handleMode = () => {
    if (selectedConsultationType == "IP") {
      setStatusList(ipStatusList);
      setSubStatusId(null);
    } else {
      setStatusList(opStatusList);
    }
    setOffset(0);
  };

  const handleStatus = (status, id) => {
    setSelectedStatus(status);
    setOffset(0);
  };

  const handleSelectedLeadStatuses = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedLeadStatuses(
        selectedLeadStatuses.length === leadStatusData.length
          ? []
          : leadStatusData
      );
      ids = leadStatusData.map((item) => item.value);
      setSelectedLeadStatusIds(
        selectedLeadStatuses.length === leadStatusData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === leadStatusData.length) {
        value = leadStatusData;
      }
      setSelectedLeadStatuses(value);
      ids = value.map((item) => item.value);
      setSelectedLeadStatusIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedLeadStatuses(data);
      ids = data.map((item) => item.value);
      setSelectedLeadStatusIds(ids);
    }
  };

  const loadSources = async () => {
    try {
      const response = await getSources();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setSourceData(data);
    } catch (error) {}
  };

  useEffect(() => {
    loadAgentData();
  }, []);

  const loadTreatments = async () => {
    try {
      const response = await getTreatments();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setTreatmentData(data);
    } catch (error) {}
  };

  const loadLeadStatus = async () => {
    try {
      const response = await getUserTrxnStatuses();
      setLeadStatusData(response.data);
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setLeadStatusData(data);
    } catch (error) {}
  };

  const tooltipStyles = {
    tooltip: {
      fontSize: "20px",
      borderRadius: "18px",
      boxShadow: "0 20px 80px 0",
      backgroundColor: "#FFFFE0",
      color: "black",
    },
  };

  const handleClickOpen = (
    enquiryId,
    appointmentType,
    ipDetail,
    opDetail,
    assignedName
  ) => {
    setActiveData({
      isActive: true,
      enquiryId: enquiryId,
      appointmentType: appointmentType,
      ipDetail: ipDetail,
      opDetail: opDetail,
      assignedName: assignedName,
    });
  };

  useEffect(() => {
    if (!!pageData) {
      const postData = pageData.map((data, index) => {
        return (
          <div
            key={index}
            className="row pointer scroll"
            onClick={() => handleClickTile(data)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="container col-1 m-0">
              <ListItem>
                <ListItemText
                  primary={`${
                    data.ipDetail
                      ? data.ipDetail.arrivalDateTime?.split("T")[0]
                      : data.opDetail
                      ? data.opDetail.dateTime?.split("T")[0]
                      : ""
                  }`}
                />
              </ListItem>
              <p style={{ textAlign: "left" }}>{`${
                data.opDetail
                  ? data.opDetail.dateTime?.split("T")[1].split(".")[0]
                  : data.opDetail
                  ? data.opDetail.dateTime?.split("T")[1]
                  : ""
              }`}</p>
            </div>

            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText primary={`${data.source && data.source}`} />
              </ListItem>
            </div>
            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>{data?.enquiryId}</ListItem>
              {data?.opDetail?.consultationMode && (
                <p
                  style={{
                    margin: 0,

                    border:
                      data?.opDetail?.consultationMode === "OFFLINE"
                        ? "1px solid #FA4659"
                        : "1px solid #79D70F",
                    color:
                      data?.opDetail?.consultationMode === "OFFLINE"
                        ? "#FA4659"
                        : "#79D70F",
                    width: "80px",
                    backgroundColor: "#ECF3F6",
                    fontWeight: 800,
                    borderRadius: "0px",
                    fontSize: "14px",
                    marginLeft: "-12px",
                  }}
                >
                  {data?.opDetail?.consultationMode}
                </p>
              )}
            </div>

            <div className="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText primary={` ${data.patientName}`} />
              </ListItem>
            </div>
            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText
                  primary={`${
                    data.ipDetail
                      ? data.ipDetail.amount
                      : data.opDetail
                      ? data.opDetail.fee
                      : ""
                  }`}
                />
              </ListItem>
            </div>

            <div className="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText primary={data?.assignedName || "Not Assigned"} />
              </ListItem>
              {checkPermission("KARE_BUDDY_ASSING_TO") && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "290px",
                  }}
                >
                  <button
                    style={{
                      padding: "5px 10px",
                      fontSize: "15px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickOpen(
                        data.enquiryId,
                        data.appointmentType,
                        data.ipDetail?.id,
                        data.opDetail?.id,
                        data?.assignedName
                      );
                    }}
                  >
                    Change
                  </button>
                </div>
              )}
            </div>

            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText primary={` ${data.location}`} />
              </ListItem>
            </div>

            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText
                  primary={`${
                    data.ipDetail
                      ? data.ipDetail.doctorName
                      : data.opDetail
                      ? data.opDetail.doctorName
                      : ""
                  }`}
                />
              </ListItem>
            </div>
            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText
                  primary={`${
                    data.ipDetail
                      ? data.ipDetail.centerName
                      : data.opDetail
                      ? data.opDetail.centerName
                      : ""
                  }`}
                />
              </ListItem>
            </div>
            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText
                  primary={`${
                    data.ipDetail
                      ? data.ipDetail.treatment
                      : data.opDetail
                      ? data.opDetail.treatment
                      : ""
                  }`}
                />
              </ListItem>
            </div>
            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText
                  primary={`${data.assignToName ? data.assignToName : ""}`}
                />
              </ListItem>
            </div>
            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText
                  primary={`${data.paymentMode ? data.paymentMode : ""}`}
                />
              </ListItem>
            </div>
            <hr />
          </div>
        );
      });
      setHomeData(postData);
    }
  }, [pageData]);
  useEffect(() => {
    loadAssignedUsers();
    getSubStatus();
    getData();
    if (selectedConsultationMode || selectedConsultationType) {
      getData();
    }
  }, [selectedConsultationMode, selectedConsultationType]);

  const handleSelectSources = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedSources(
        selectedSources.length === sourceData.length ? [] : sourceData
      );
      ids = sourceData.map((item) => item.id);
      setSelectedSourceIds(
        selectedSources.length === sourceData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === sourceData.length) {
        value = sourceData;
      }
      setSelectedSources(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedSourceIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedSources(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedSourceIds(ids);
    }
  };

  const handleSelectLocations = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedLocations(
        selectedLocations.length === locationData.length ? [] : locationData
      );
      ids = locationData.map((item) => item.value);
      setSelectedLocationIds(
        selectedLocations.length === locationData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === locationData.length) {
        value = locationData;
      }
      setSelectedLocations(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedLocationIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedLocations(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedLocationIds(ids);
    }
  };

  const handleSelectTreatments = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedTreatments(
        selectedTreatments.length === treatmentData.length ? [] : treatmentData
      );
      ids = treatmentData.map((item) => item.value);
      setSelectedTreatmentIds(
        selectedTreatments.length === treatmentData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === treatmentData.length) {
        value = treatmentData;
      }
      setSelectedTreatments(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedTreatmentIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedTreatments(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedTreatmentIds(ids);
    }
  };
  const handleAssignedAgents = (value, event) => {
    var ids;
    if (event.option.value === "ALL") {
      setSelectedAssignedTo(
        selectedAssignedTo.length === assignedUser?.list?.length
          ? []
          : assignedUser?.list
      );
      ids = assignedUser?.list.map((item) => item.value);
      setSelectedAssignedToIds(
        selectedAssignedTo.length === assignedUser?.list.length ? [] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === assignedUser?.list.length) {
        value = assignedUser?.list;
      }
      setSelectedAssignedTo(value);
      ids = value.length === 0 ? [] : value.map((item) => item.value);
      setSelectedAssignedToIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedAssignedTo(data);
      ids = data.length === 0 ? [] : data.map((item) => item.value);
      setSelectedAssignedToIds(ids);
    }
  };

  const loadAssignedUsers = async () => {
    try {
      getAgentData(false).then((res) => {
        let users = [
          { id: 0, name: "All", code: "ALL" },
          { id: 1, name: "Not Assigned", code: "NOT_ASSIGNED" },
        ];
        users.push.apply(users, res.data.users);
        const data = users?.map((val, i) => {
          return { id: i, value: val.code, label: val.name };
        });
        setAssignedUser((s) => ({ ...s, list: data }));
      });
    } catch (error) {}
  };
  const [assignedUser, setAssignedUser] = useState({
    list: [{ id: 0, name: "All", code: "ALL" }],
    selected: "All",
    selectedCode: "ALL",
  });

  const handleAssignedToChange = (assignedCode, assignedName) => {
    setAssignedUser((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
  };
  const getData = async (
    offset,
    searchItem,
    searchDoctor,
    searchCenter,
    selectedSourceIds,
    selectedStatusIds,
    selectedTreatmentIds,
    assignedUser,
    searchEnquiryId,
    selectedStatus,
    selectedLocationIds,
    selectedLeadStatusIds,
    subStatusId
  ) => {
  await getFilteredIpOpEnquiries(
      selectedAppointmentDateFilter,
      searchAppointmentFromDate,
      searchAppointmentToDate,
      selectedConsultationType,
      perPage,
      offset,
      selectedSourceIds,
      searchItem,
      searchDoctor,
      searchCenter,
      selectedStatusIds,
      selectedTreatmentIds,
      assignedUser,
      searchEnquiryId,
      selectedStatus,
      selectedLocationIds,
      selectedLeadStatusIds,
      subStatusId,
      selectedConsultationMode,
      activeType,
    )
      .then((res) => {
        const data = res.data.ipOpEnquiries;
        setTotalCount(res.data.count);
        setPageCount(Math.ceil(res.data.count / perPage));
        setPageData(data);
        setAllData(data);
      })
      .catch((error) => {})
      .finally(() => {
        setDataLoaded(true);
      });
  };



  useEffect(() => {
    if (location.state) {
      setSelectedAssignedTo(location.state.selectedAssignedTo);
      setSelectedAssignedToIds(location.state.selectedAssignedToIds);
      setSelectedStatus(location.state?.selectedStatuses);
      setSelectedSources(location.state?.selectedSources);
      setSelectedAppointmentDateFilter(
        location.state.selectedAppointmentDateFilter
      );
      setSearchAppointmentToDate(location?.state?.searchAppointmentToDate);
      setSearchAppointmentFromDate(location?.state?.searchAppointmentFromDate);
      setSelectedSourceIds(location.state?.selectedSourceIds);
      setSelectedTreatments(location.state?.selectedTreatments);
      setSelectedTreatmentIds(location.state?.selectedTreatmentIds);
      setSelectedLocations(location.state.selectedLocations);
      setSelectedLocationIds(location.state.selectedLocationIds);
      setSelectedLeadStatusIds(location.state.selectedLeadStatusIds);
      setType(location.state?.selectedConsultationType);
      setSubStatusId(location.state?.subStatus);
      setReload(true);
    } else {
      setReload(true);
    }
  }, []);

  useEffect(() => {
    loadSources();
    loadTreatments();
    getIpOpCount();
    loadLocations();
    loadLeadStatus();
    if (reload) {
      setDataLoaded(false);
      getData(
        offset,
        searchItem,
        searchDoctor,
        searchCenter,
        selectedSourceIds,
        selectedStatusIds,
        selectedTreatmentIds,
        selectedAssignedToIds,
        searchEnquiryId,
        selectedStatus,
        selectedLocationIds,
        selectedLeadStatusIds,
        subStatusId
      );
      setReload(false);
    }
  }, [reload]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage);
    if (selectedPage != offset) {
      setReload(true);
    }
  };

  function handleClickTale(enquiryData) {
    history.push({
      pathname: "/ipOp",
      state: {
        offset,
        selectedAssignedTo,
        selectedAssignedToIds,
      },
    });
  }

  const loadLocations = async () => {
    try {
      const response = await getAllLocations();
      var status = [
        { id: 0, name: "ALL" },
        { id: -1, name: "Blank" },
      ];
      status.push.apply(status, response);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setLocationData(data);
    } catch (error) {}
  };

  function getDropdownButtonLabel({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Select";
      return `${values}`;
    }
    return "Select";
  }
  const classes = useStyles();
  const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

  return {
    offset,
    classes,
    homeData,
    useStyles,
    pageCount,
    dataLoaded,
    setDataLoaded,
    handleClickTale,
    handlePageClick,
    appointmentType,
    type,
    setSalesBdDetail,
    setType,
    setReload,
    handleMode,
    searchEnquiryToDate,
    setSearchEnquiryToDate,
    searchEnquiryFromDate,
    setSearchEnquiryFromDate,
    enquiryDateFilter,
    setEnquiryDateFilter,
    appointmentDateFilter,
    setAppointmentDateFilter,
    selectedEnquiryDateFilter,
    selectedAppointmentDateFilter,
    setSelectedAppointmentDateFilter,
    setSelectedEnquiryDateFilter,
    appointmentDateFilter,
    setAppointmentDateFilter,
    searchAppointmentToDate,
    setSearchAppointmentToDate,
    searchAppointmentFromDate,
    setSearchAppointmentFromDate,
    perPage,
    agents,
    totalCount,
    setOffset,
    sourceData,
    getDropdownButtonLabel,
    selectedSources,
    handleSelectSources,
    setSelectedSources,
    ipOpStats,
    searchItem,
    handleSearchItemChange,
    searchDoctor,
    handleSearchDoctorChange,
    searchCenter,
    handleSearchCenterChange,
    setSelectedAssignedTo,
    selectedAssignedTo,
    searchEnquiryId,
    handleSearchEnquiry,
    selectedStatuses,
    statusData,
    setSelectedStatuses,
    handleAssignedAgents,
    selectedTreatments,
    treatmentData,
    setSelectedTreatments,
    handleSelectTreatments,
    handleAssignedToChange,
    assignedUser,
    opStatusList,
    ipStatusList,
    statusList,
    setStatusList,
    handleStatus,
    selectedStatus,
    setSelectedStatus,
    setSelectedLocations,
    handleSelectLocations,
    locationData,
    selectedLocations,
    selectedLocationIds,
    assignToKareBuddy,
    selectedLeadStatuses,
    leadStatusData,
    selectedLeadStatusIds,
    handleSelectedLeadStatuses,
    handleSubStatus,
    subStatus,
    subStatusId,
    getData,
    selectedSubStatus,
    activeData,
    setActiveData,
    mainMenuItems,
    setSelectedCelectedConsultationType,
    setSelectedConsultationMode,
    selectedConsultationType,
    setIsActiveType
  };
};
export default useIpOp;
