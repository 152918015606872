import React from "react";

const AdditionalInfoDetails = ({ additionalInfo }) => {
  const renderDetail = (key, value) => {
    return (
      <div key={key}>
        <strong>
          {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}:
        </strong>{" "}
        {value}
      </div>
    );
  };

  return (
    <div>
      {Object.entries(additionalInfo).map(([key, value]) =>
        renderDetail(key, value)
      )}
    </div>
  );
};

export default AdditionalInfoDetails;
