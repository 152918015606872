import { List } from "@material-ui/core";
import * as ReactBootstrap from "react-bootstrap";
import ReactPaginate from "react-paginate";
import CustomLoader from "../../../components/CustomLoader";
import { checkPermission } from "../../../util/permissionUtils";
import TopBar from "../../Enquiry/TopBar";
import Sidebar from "../../Sidebar";
import AddDoctor from "../AddDoctor/AddDoctor";
import useDoctorPage from "./useDoctorPage.logic";

function DoctorPage(props) {
  const border = {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  };
  const flexStyle = {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const state = useDoctorPage();

  if (state.dataLoaded) {
    return <CustomLoader />;
  }

  return (
    <div className="report-container">
      <div className="report-sidebar-container">
        <Sidebar />
      </div>
      <div className="report-main-container">
        <div className="row " style={border}>
          <div style={{ width: "100%", padding: 10 }}>
            <div className="main-subContainer">
              <TopBar />
            </div>
            {checkPermission("DOCTOR_ADD") && (
              <button
                style={{ width: 80, height: 50 }}
                onClick={() => state.setIsOpen(!state.isOpen)}
              >
                Add
              </button>
            )}
            <br></br>
            {state.isOpen && (
              <AddDoctor
                setName={state.setName}
                setSelectedCenter={state.setSelectedCenter}
                selectedCenter={state.selectedCenter}
                setSpecialization={state.setSpecialization}
                onClicked={(isOpen) => state.setIsOpen(isOpen)}
                onSave={() => state.addNewDoctor()}
              />
            )}
            <div className="row">
              <div className="col-1">
                <b>Code</b>
              </div>
              <div className="col-2">
                <b>Name</b>
              </div>
              <div className=" col-3 ">
                <b>Speciality</b>
              </div>
              <div className=" col-3">
                <b>Qualification</b>
              </div>
              <div className=" col-2">
                <b>Experience</b>
              </div>
              {checkPermission("DOCTOR_VIEW") ||
                (checkPermission("DOCTOR_DELETE") && (
                  <div className="col-1">
                    <b>Action</b>
                  </div>
                ))}
            </div>
            <hr />
            <List component="nav" aria-label="mailbox folders">
              {state.homeData}
            </List>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={state.pageCount}
              initialPage={state.offset}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={state.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={state.offset}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorPage;
