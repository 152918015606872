import axios from "axios";
import "../../Styles/Common.css";
import "../../Styles/sideBar.css";
import "../../Styles/Pagination.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import * as ReactBootstrap from "react-bootstrap";
import ListItem from "@material-ui/core/ListItem";
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as appConstants from "../../util/AppConstants";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import "./Enquiry.css";
import { statusColorFinder } from "../../util/statusColorFinder";

import {
  getEnquiryPreventiveCareStatistics,
  getFilteredPreventiveCareEnquiries,
  getUserTrxnStatuses,
  getDownloadReportData,
  getSources,
} from "../../services/mykare/enquiryService";

import { getAgentData } from "../../services/commonService/commonService";
import Tooltip from "@material-ui/core/Tooltip";
import { useStyles } from "../../components/multi-select";
import { getTreatments } from "../../services/mykare/dataService";
import { checkPermission } from "../../util/permissionUtils";

const useEnquiry = () => {
  let history = useHistory();
  const location = useLocation();
  const [perPage] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [homeData, setHomeData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [filterLoaded, setFilterLoaded] = useState(true);
  const [enquiryStats, setEnquiryStats] = useState(null);
  const userRole = localStorage.getItem("user-role");
  const [reload, setReload] = useState(false);
  const [searchEnquiryFromDate, setSearchEnquiryFromDate] = useState(
    new Date()
  );
  const [searchEnquiryToDate, setSearchEnquiryToDate] = useState(new Date());
  const [searchFollowupFromDate, setSearchFollowupFromDate] = useState(
    new Date()
  );
  const [searchFollowupToDate, setSearchFollowupToDate] = useState(new Date());

  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedSource, setSelectedSource] = useState("All");
  const [sources, setSources] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [selectedStatusIds, setSelectedStatusIds] = useState([0]);
  const isAllStatus =
    statusData.length > 0 && selectedStatuses.length === statusData.length;

  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([0]);
  const [treatmentData, setTreatmentData] = useState([]);

  const isAllTreatment =
    treatmentData.length > 0 &&
    selectedTreatments.length === treatmentData.length;

  const [enquiryDateFilter, setEnquiryDateFilter] = useState([
    "All",
    "Today",
    "Yesterday",
    "Choose Date",
    "Previous Month",
  ]);
  const followUpDateFilter = ["All", "Today", "Tomorrow", "Choose Date"];
  const [selectedEnquiryDateFilter, setSelectedEnquiryDateFilter] =
    useState("All");
  const [selectedFollowupDateFilter, setSelectedFollowupDateFilter] =
    useState("All");
  const [assignedUser, setAssignedUser] = useState({
    list: [{ id: 0, name: "All", code: "ALL" }],
    selected: "All",
    selectedCode: "ALL",
  });

  const useTableStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      marginTop: 10,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));

  const tooltipStyles = {
    tooltip: {
      fontSize: "20px",
      borderRadius: "18px",
      boxShadow: "0 20px 80px 0",
      backgroundColor: "#FFFFE0",
      color: "black",
    },
  };

  const [pageData, setPageData] = useState(null);
  const [allData, setAllData] = useState(null);

  const [selectType, setSelectType] = useState({
    list: ["PREVENTIVE_CARE"],
    selected: "PREVENTIVE_CARE",
  });
  //=====================varun=====4/mar/2023======started===============//
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedAssignedToIds, setSelectedAssignedToIds] = useState([]);

  function getDropdownButtonLabel({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Select";
      return `${values}`;
    }
    return "Select";
  }

  const handleAssignedAgents = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedAssignedTo(
        selectedAssignedTo.length === assignedUser?.list?.length
          ? []
          : assignedUser?.list
      );
      ids = assignedUser?.list.map((item) => item.value);
      setSelectedAssignedToIds(
        selectedAssignedTo.length === assignedUser?.list.length ? [] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === assignedUser?.list.length) {
        value = assignedUser?.list;
      }
      setSelectedAssignedTo(value);
      ids = value.length === 0 ? [] : value.map((item) => item.value);
      setSelectedAssignedToIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedAssignedTo(data);
      ids = data.length === 0 ? [] : data.map((item) => item.value);
      setSelectedAssignedToIds(ids);
    }

    loadFilteredData(
      searchItem,
      searchPhone,
      selectedStatusIds,
      ids,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds
    );
  };

  const loadAssignedUsers = async () => {
    try {
      getAgentData(false).then((res) => {
        let users = [{ id: 0, name: "All", code: "ALL" }];
        users.push.apply(users, res.data.users);
        users.push({ id: -1, name: "Not Assigned", code: "NOT_ASSIGNED" });
        const data = res?.data?.users
          .map((val, i) => {
            return { id: i, value: val.code, label: val.name };
          })
          .sort((a, b) => {
            return a.label.localeCompare(b.label);
          });
        setAssignedUser((s) => ({ ...s, list: data }));
      });
    } catch (error) {}
  };
  //=====================varun===========ended===============//

  const handleSelectTypeChange = (type) => {
    setSelectType((s) => ({ ...s, selected: type }));
    loadFilteredData(
      searchItem,
      searchPhone,
      type,
      selectedStatusIds,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds
    );
  };

  const handleSelectTreatments = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 0) {
      setSelectedTreatments(
        selectedTreatments.length === treatmentData.length ? [] : treatmentData
      );
      const ids = treatmentData.map((item) => item.id);
      setSelectedTreatmentIds(
        selectedTreatments.length === treatmentData.length ? [] : ids
      );
      //  setAllSelectedStatuses(statusData.length > 0 && ids.length === statusData.length);
      loadFilteredData(
        searchItem,
        searchPhone,
        selectType.selected,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        ids
      );

      return;
    }
    const ids = value.map((item) => item.id);
    setSelectedTreatmentIds(ids);
    setSelectedTreatments(value);
    //  setAllSelectedStatuses(statusData.length > 0 && ids.length === statusData.length);
    loadFilteredData(
      searchItem,
      searchPhone,
      selectType.selected,
      selectedStatusIds,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      ids
    );
  };

  const handleSelectStatuses = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === 0) {
      setSelectedStatuses(
        selectedStatuses.length === statusData.length ? [] : statusData
      );
      const ids = statusData.map((item) => item.id);
      setSelectedStatusIds(
        selectedStatuses.length === statusData.length ? [] : ids
      );
      //  setAllSelectedStatuses(statusData.length > 0 && ids.length === statusData.length);
      loadFilteredData(
        searchItem,
        searchPhone,
        selectType.selected,
        ids,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds
      );

      return;
    }
    const ids = value.map((item) => item.id);

    setSelectedStatusIds(ids);
    setSelectedStatuses(value);
    //  setAllSelectedStatuses(statusData.length > 0 && ids.length === statusData.length);
    loadFilteredData(
      searchItem,
      searchPhone,
      selectType.selected,
      ids,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds
    );
  };

  const [selectStatus, setSelectStatus] = useState({
    list: [],
    selected: "ALL",
  });

  const loadStatus = async () => {
    try {
      const response = await getUserTrxnStatuses();
      setStatusData(response.data);
      /*  var status = [{ id: 0, name: "ALL" }];
        status.push.apply(status, response.data);
        setSelectStatus((pre) => ({ ...pre, list: status }));*/
    } catch (error) {}
  };

  const loadTreatments = async () => {
    try {
      const response = await getTreatments();
      setTreatmentData(response.data);
      /*  var status = [{ id: 0, name: "ALL" }];
        status.push.apply(status, response.data);
        setSelectStatus((pre) => ({ ...pre, list: status }));*/
    } catch (error) {}
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.offset || location.state.selectedStatusIds) {
        // setSelectStatus((s) => ({
        //   ...s,
        //   selected: location.state.selectedStatus,
        // }));
        setSelectedStatuses(location.state.selectedStatuses);
        setSelectedStatusIds(location.state.selectedStatusIds);
        setSelectedTreatmentIds(location.state.selectedTreatmentIds);
        setSelectedTreatments(location.state.selectedTreatments);
        setOffset(location.state.offset);
        setSelectedEnquiryDateFilter(location.state.selectedEnquiryDateFilter);
        setSearchEnquiryFromDate(location.state.searchEnquiryFromDate);
        setSearchEnquiryToDate(location.state.searchEnquiryToDate);
        setSelectedFollowupDateFilter(
          location.state.selectedFollowupDateFilter
        );
        setSearchFollowupFromDate(location.state.searchFollowupFromDate);
        setSearchFollowupToDate(location.state.searchFollowupToDate);
        setAssignedUser(location.state.assignedUser);
        setSelectedAssignedTo(location.state.selectedAssignedTo);
        setSelectedAssignedToIds(location.state.selectedAssignedToIds);
        setReload(true);
      }
    } else {
      setReload(true);
    }
  }, []);

  // const handleSelectStatusChange = (status) => {
  //   setSelectStatus((s) => ({ ...s, selected: status }));
  //   loadFilteredData(
  //     searchItem,
  //     searchReason,
  //     selectType.selected,
  //     status,
  //     assignedUser.selectedCode,
  //     selectedEnquiryDateFilter,
  //     searchEnquiryFromDate,
  //     searchEnquiryToDate,
  //     selectedFollowupDateFilter,
  //     searchFollowupFromDate,
  //     searchFollowupToDate
  //   );
  // };

  const handleAssignedToChange = (assignedCode, assignedName) => {
    setAssignedUser((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
    /*loadFilteredData(
      searchItem,
      selectType.selected,
      selectStatus.selected,
      assignedCode,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate, searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate
    );*/
  };

  const [searchItem, setSearchItem] = useState("");
  const [searchPhone, setSearchPhone] = useState("");

  const handleSearchItemChange = (e) => {
    setSearchItem(e.target.value);
    //setReload(true);
  };

  const handleSearchPhone = (e) => {
    setSearchPhone(e.target.value);
    //setReload(true);
  };

  const getTotalEnquiries = async () => {
    try {
      getEnquiryPreventiveCareStatistics().then((res) => {
        setEnquiryStats(res.data);
      });
    } catch (error) {}
  };

  useEffect(() => {
    loadAssignedUsers();
    getSource();
  }, []);

  useEffect(() => {
    loadStatus();
    loadTreatments();

    //setDataLoaded(true);
    if (reload) {
      setDataLoaded(false);
      getTotalEnquiries();
      getData(
        offset,
        searchItem,
        searchPhone,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedSource.id
      );
      setReload(false);
    }
  }, [reload, selectedAssignedToIds]);

  useEffect(() => {
    if (!pageData && !allData) return;

    if (searchItem === "") {
      setReload(true);
    } else {
      getData(
        offset,
        searchItem,
        searchPhone,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds
      );
    }
  }, [searchItem, selectedAssignedToIds]);

  useEffect(() => {
    if (!pageData && !allData) return;

    if (searchPhone === "") {
      setReload(true);
    } else {
      getData(
        offset,
        searchItem,
        searchPhone,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds
      );
    }
  }, [searchPhone, selectedAssignedToIds]);

  const loadFilteredData = async (
    searchItem,
    searchPhone,
    enquiryType,
    statusIds,
    assignedTo,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    followUpFromDate,
    followUpToDate,
    treatmentIds
  ) => {
    const enquiryFilterFromDate = moment(enquiryFromDate)
      .format()
      .split("+")[0];
    const enquiryFilterToDate = moment(enquiryToDate).format().split("+")[0];
    // const followupFilterDate = moment(followUpDate).format().split("+")[0];
    // const endFollowupFilterDate = moment(endFollowUpDate).format().split("+")[0];
    const followupFilterFromDate = moment(followUpFromDate)
      .format()
      .split("+")[0];
    const followupFilterToDate = moment(followUpToDate).format().split("+")[0];

    const response = await getFilteredPreventiveCareEnquiries(
      0,
      perPage,
      enquiryType,
      statusIds,
      searchItem,
      searchPhone,
      assignedTo,
      enquiryDateFilter,
      enquiryFilterFromDate,
      enquiryFilterToDate,
      followUpDateFilter,
      followupFilterFromDate,
      followupFilterToDate,
      treatmentIds,
      selectedSource.id ? selectedSource.id : 0
    );

    if (response) {
      const data = response.data.enquiries;
      setTotalCount(response.data.count);
      setPageData(data);
      setPageCount(Math.ceil(response.data.count / perPage));
    }
  };

  const refreshTable = () => {
    setSelectedStatuses([]);
    setSelectedStatusIds([0]);
    setSelectedTreatments([]);
    setSelectedTreatmentIds([0]);
    setDataLoaded(true);
    getData(
      offset,
      searchItem,
      searchPhone,
      [0],
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      [0],
      0
    );
    getTotalEnquiries();
    setDataLoaded(false);
  };

  const getSource = async () => {
    let defaultSource = [{ id: 0, name: "All" }];
    const response = await getSources();
    if (response.status === 200) {
      if (response.data) {
        defaultSource.push.apply(defaultSource, response.data);
        setSources(defaultSource);
      }
    }
  };

  useEffect(() => {
    if (!!pageData) {
      const postData = pageData.map((data, index) => (
        <div
          key={index}
          className="row pointer scroll"
          onClick={() => handleClickTale(data)}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="container col-1 m-0" style={{ width: 70 }}>
            {offset === 0 ? index + 1 : offset * 20 + (index + 1)}
          </div>

          {checkPermission("LEADS_ENQUIRY_DATE_FIELD") && (
            <div className="container col-1 m-0">
              <ListItem>
                <ListItemText primary={`${data.enquiryDate.split("T")[0]}`} />
              </ListItem>
              <p style={{ textAlign: "left" }}>{`${
                data.enquiryDate.split("T")[1].split(".")[0]
              }`}</p>
            </div>
          )}
          <div className="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>

            <ListItem>
              <ListItemText
                primary={` ${data.patientName} (Id: #${data.enquiryId})`}
              />
            </ListItem>
          </div>

          {checkPermission("LEADS_PHONE_NUMBER_FIELD") && (
            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText primary={` ${data.phoneNumber}`} />
              </ListItem>
              {data.alternateContact && (
                <p
                  style={{ textAlign: "left" }}
                >{`${data.alternateContact}`}</p>
              )}
            </div>
          )}

          <div className="container col-1 m-0">
            <ListItemText primary={`# ${data.enquiryId}`} />
            <br />
          </div>

          <div className="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>

            <ListItem>
              <ListItemText
                primary={` ${
                  data.assignToName ? data.assignToName : "Not Assigned"
                }`}
              />
            </ListItem>
          </div>

          <div className="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>

            <ListItem>
              <ListItemText primary={` ${data.reason}`} />
            </ListItem>
          </div>

          <div class="container col-1 m-0 capitalize">
            <p className={statusColorFinder(data.status)}>
              <ListItem>
                <ListItemText
                  className="status-bold"
                  primary={data.status ? data.status : ""}
                />
              </ListItem>
            </p>
          </div>

          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText
                primary={`${data.followUpDate ? data.followUpDate : " "}`}
              />
            </ListItem>
          </div>
          <div className="container col-1 m-0 ">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>

            <ListItem>
              <CustomTooltip title={` ${data.notes ? data.notes : ""}`}>
                <ListItemText
                  primary={` ${data.notes ? data.notes : ""}`}
                  style={{
                    whiteSpace: "nowrap",
                    width: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                />
              </CustomTooltip>
            </ListItem>
          </div>

          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={` ${data.location}`} />
            </ListItem>
          </div>

          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={` ${data.paymentMode}`} />
            </ListItem>
          </div>
          <hr />
        </div>
      ));
      setHomeData(postData);
    }
  }, [pageData]);

  const getData = async (
    offset,
    searchItem,
    searchPhone,
    selectedStatusIds,
    assignedTo,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    followUpFromDate,
    followUpToDate,
    selectedTreatmentIds,
    sourceId
  ) => {
    getFilteredPreventiveCareEnquiries(
      offset,
      perPage,
      selectType.selected,
      selectedStatusIds,
      searchItem,
      searchPhone,
      assignedTo,
      enquiryDateFilter,
      enquiryFromDate,
      enquiryToDate,
      followUpDateFilter,
      followUpFromDate,
      followUpToDate,
      selectedTreatmentIds,
      (sourceId = sourceId ? sourceId : 0)
    )
      .then((res) => {
        const data = res.data.enquiries;
        setTotalCount(res.data.count);
        setPageCount(Math.ceil(res.data.count / perPage));
        setPageData(data);
        setAllData(data);
      })
      .catch((error) => {})
      .finally(() => {
        setDataLoaded(true);
      });
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage);
    if (selectedPage != offset) {
      setReload(true);
    }
  };

  function handleClickTale(enquiryData) {
    history.push({
      pathname: "/mykareEnquiryDetail",
      state: {
        statusColorFinder: statusColorFinder(),
        enquiryDetails: enquiryData,
        offset,
        selectedStatuses,
        selectedStatusIds,
        selectedTreatmentIds,
        selectedTreatments,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        assignedUser,
      },
    });
  }

  const classes = useTableStyles();
  const menuClasses = useStyles();
  const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

  return {
    totalCount,
    offset,
    perPage,
    classes,
    menuClasses,
    homeData,
    userRole,
    pageCount,
    dataLoaded,
    setDataLoaded,
    handleClickTale,
    handlePageClick,
    enquiryStats,
    selectStatus,
    assignedUser,
    searchItem,

    enquiryDateFilter,

    handleSearchItemChange,
    handleAssignedToChange,
    selectType,
    handleSelectTypeChange,
    selectStatus,
    //handleSelectStatusChange,
    filterLoaded,
    selectedEnquiryDateFilter,
    setSelectedEnquiryDateFilter,
    selectedFollowupDateFilter,
    setSelectedFollowupDateFilter,
    searchFollowupToDate,
    setSearchFollowupToDate,
    searchFollowupFromDate,
    setSearchFollowupFromDate,
    searchEnquiryFromDate,
    setSearchEnquiryFromDate,
    searchEnquiryToDate,
    setSearchEnquiryToDate,
    setReload,
    refreshTable,
    followUpDateFilter,

    selectedStatuses,
    handleSelectStatuses,
    isAllStatus,
    statusData,
    selectedStatusIds,

    selectedTreatments,
    handleSelectTreatments,
    isAllTreatment,
    treatmentData,
    selectedTreatmentIds,
    assignedUser,
    searchPhone,
    handleSearchPhone,
    sources,
    selectedSource,
    setSelectedSource,
    getDropdownButtonLabel,
    selectedAssignedTo,
    handleAssignedAgents,
    setSelectedAssignedTo,
  };
};
export default useEnquiry;
