import { Grid } from "@material-ui/core";

export function TextInput(props) {
  return (
    <Grid item xs={2}>
      {props.isEditable ? (
        <Grid container direction="column">
          <label>{props.label}</label>
          <input
            value={props.value}
            style={{
              width: 200,
              height: 40,
              borderRadius: 5,
              paddingLeft: 10,
              border: `1px solid gray`,
            }}
            onChange={props.onChange}
          />
        </Grid>
      ) : (
        <Grid container direction="column">
          <b>{props.label}</b>
          <span>{props.value}</span>
        </Grid>
      )}
    </Grid>
  );
}
