import React, { useEffect } from "react";
import "./PreLeadsDetail.css";
import Sidebar from "../../Sidebar";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import { statusColorFinder } from "../../../util/statusColorFinder";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { getAdditionalInfo } from "../../../util/getAdditionalInfo";
import { checkPermission } from "../../../util/permissionUtils";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { DialogContent, Button } from "@mui/material";
import { handleStatusType } from "../../../util/handleStatus";
import verifiedIcon from "./../../../assets/icons/checkboxIcon.webp";
import usePreLeadsDetail from "./usePreLeadsDetail.logic";
import ChatPopUp from "../../myKareEnquiryDetail/ChatPopUp/ChatPopUp";
import OtpVerified from "../../../components/OtpVerified";
import LotieAnimation from "../../LotieAnimation/LotieAnimation";
import BasicDetailsPopup from "../../myKareEnquiryDetail/BasicDetailsPopup/BasicDetailsPopup";
import OPiPpopup from "../../myKareEnquiryDetail/Components/oPiPpopup";
import PatientsDetail from "../../myKareEnquiryDetail/PatientDetail/PatientsDetail";
import PatientsDoc from "../../myKareEnquiryDetail/PatientDocs/PatientsDoc";
import TreatmentInfoBtn from "../../myKareEnquiryDetail/TreatmentInfoPopup/TreatmentInfoBtn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PreLoadsFaqs from "./PreLeadFaqs/PreLoadsFaqs";

const PreLeadsDetail = () => {
  const state = usePreLeadsDetail();
  const userRole = localStorage.getItem("user-role");
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));
  const classes = useStyles();

  const openInNewTab = (enquiryId, type) => {
    window.open(`/activity/${enquiryId}/${type}`, "_blank");
  };

  return (
    <>
      {state.isMotivation ? (
        <LotieAnimation />
      ) : (
        <div className="d-flex">
          <Sidebar />

          <div className={classes.root}>
            <button className="width1" onClick={() => state.back()}>
              BACK
            </button>
            <hr />
            <div>
              <div className="row">
                <div class="col-md-1">
                  <p className="text-start">EnquiryId</p>
                  <p className="ed-txt-1 text-start">
                    <div className="textColor">{`${state.data.enquiryId}`}</div>
                  </p>
                </div>
                {state.data.enquiryDate &&
                  checkPermission("LEADS_ENQUIRY_DATE_FIELD") && (
                    <div className="col-md-1">
                      <p className="text-start">Enquiry Date</p>
                      <div className="ed-txt-1 text-start">
                        <div className="textColor">
                          {state.data?.enquiryDate.split("T")[0]}
                        </div>
                      </div>
                    </div>
                  )}

                <div className="col-md-1">
                  <p className="text-start">Enquiry Time</p>
                  <div className="ed-txt-1 text-start">
                    <div className="textColor">
                      {moment(state?.data?.enquiryDate).format("LTS")}
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <p className="text-start">PatientName</p>
                  <p className="ed-txt-1 text-start">
                    <div className="textColor">{state.patientName}</div>
                  </p>
                </div>
                <div class="col-md-1">
                  <p className="text-start ">Age</p>
                  <p className="ed-txt-1 text-start">
                    <div className="textColor">{state.age}</div>
                  </p>
                </div>
                <div class="col-md-1">
                  <p className="text-start ">Gender</p>
                  <p className="ed-txt-1 text-start">
                    <div className="textColor">{state.gender}</div>
                  </p>
                </div>
                {(checkPermission("CLICK_TO_CALL_BTN") ||
                  checkPermission("FEEDBACK_CALL_BTN")) && (
                  <div class="col-2">
                    <h6>Choose Exotel Number</h6>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        style={{
                          width: 200,
                          border: `1px solid gray`,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {!!state?.exoNumber?.label
                          ? state?.exoNumber?.label
                          : state?.exoPhone[0]?.label}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {state?.exoPhone?.map((value, id) => {
                          return (
                            <Dropdown.Item
                              key={id}
                              href="#"
                              style={{ width: 200 }}
                              onClick={() => state.handlePhonumber(value)}
                            >
                              {value?.label}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
                {checkPermission("CLICK_TO_CALL_BTN") && (
                  <div class="col-md-1">
                    <p className="text-start ">Phone Number</p>
                    <p className="ed-txt-1 text-start">
                      {checkPermission("LEADS_PHONE_NUMBER_FIELD") && (
                        <div className="textColor">
                          {state.data.phoneNumber}
                          {state.data.alternateContact
                            ? ` /${state.data.alternateContact}`
                            : ""}
                        </div>
                      )}

                      <button
                        style={{ width: 100 }}
                        onClick={() => state.click2Call("PRIMARY")}
                      >
                        Click2Call 1
                      </button>
                      {state.isOpen &&
                        state.patientId &&
                        state.data.enquiryId && (
                          <BasicDetailsPopup
                            userTrxnId={state.data.enquiryId}
                            userRole={userRole}
                            onclose={() => state.setIsOpen((prev) => false)}
                            patientId={state.data.patientId}
                            patientName={state.patientName}
                          />
                        )}
                      {state.data.alternateContact ? (
                        <button
                          style={{ marginLeft: 2, width: 100 }}
                          onClick={() => state.click2Call("ALTERNATE")}
                        >
                          Click2Call 2
                        </button>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                )}
                <div class="col-md-1">
                  <p className="text-start ">Type</p>
                  <p className="ed-txt-1 text-start">
                    <div className="textColor">{` ${state.data.type}`}</div>
                  </p>
                </div>
                {checkPermission("LEADS_ORIGIN_FIELD") && (
                  <div class="col-md-1">
                    <p className="text-start">Origin</p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">{`${state.data.source}`}</div>
                    </p>
                  </div>
                )}
                <div className="col-md-1 capitalize">
                  <p className="text-start ">Status</p>
                  <p
                    className={statusColorFinder(state?.data?.preLeadStatus)}
                    style={{ textAlign: "left" }}
                  >
                    {state?.data?.preLeadStatus}
                  </p>
                </div>
                <div class="col-md-1">
                  <p className="text-start">Email</p>
                  <p className="ed-txt-1 text-start">
                    <div className="textColor">{`${state.data.email}`}</div>
                  </p>
                </div>

                <div class="col-md-1">
                  <p className="text-start">Pre Assigned To</p>
                  <p className="ed-txt-1 text-start">
                    <div className="textColor">{`${state.data.preAssignToName}`}</div>
                  </p>
                </div>

                <div class="col-md-1">
                  <p className="text-start">Kare Buddy</p>
                  <p className="ed-txt-1 text-start">
                    <div className="textColor">{`${state.data.kareBuddyName}`}</div>
                  </p>
                </div>

                <div class="col-md-1">
                  <p className="text-start">Otp Verified</p>
                  <OtpVerified
                    imageSrc={state?.data?.isValidated ? verifiedIcon : null}
                    message="OTP Verified!"
                  />
                </div>
                {handleStatusType(state?.data?.status) !== undefined && (
                  <div class="col-md-2">
                    <button
                      style={{ width: 200, height: 60 }}
                      onClick={() =>
                        openInNewTab(
                          state.data.enquiryId,
                          handleStatusType(state.status)
                        )
                      }
                    >
                      View Karebuddy Activity
                    </button>
                  </div>
                )}
              </div>
              <hr />
              {state.surgeryDetail && (
                <div className="row">
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Treatment Name</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.handleTreatmentName(state.surgeryDetail)}
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Category</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.surgeryDetail.category
                          ? state.surgeryDetail.category
                          : state.surgeryDetail.otherCategory}
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Location</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.surgeryDetail.location}
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Sub Location</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.surgeryDetail.subLocation}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {state.enquiryDetail && (
                <div className="row">
                  <div className="col-3">
                    <div className="d-flex ml-2 align-items-center">
                      <p className="mb-0 mt-4 text-start">Treatment Name</p>
                    </div>
                    <div className="ed-txt-2 d-flex mb-4 text-start">
                      <div className="textColor">
                        {state.handleTreatmentName(state.enquiryDetail)}
                      </div>
                      <TreatmentInfoBtn
                        id={state.enquiryDetail.treatmentPackageId}
                        treatmentPackage={state.enquiryDetail.treatmentPackage}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Location</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.enquiryDetail.location}
                      </div>
                    </div>
                  </div>

                  {state.enquiryDetail.circle && (
                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Circle</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.enquiryDetail.circle}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Center</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.enquiryDetail.center}
                      </div>
                    </div>
                  </div>
                  {state.enquiryDetail.additionalInfo && (
                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Additional Info</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {getAdditionalInfo(
                            state.enquiryDetail.additionalInfo
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {state.smoDetails && (
                <div>
                  <div className="row">
                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Reason</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.smoDetails.reason}
                        </div>
                      </div>
                    </div>

                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Description</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.smoDetails.description}
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              )}
              {state.preventiveCareDetail && (
                <div className="row">
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Packages</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.preventiveCareDetail.packages}
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Location</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.preventiveCareDetail.location}
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Sub Location</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.preventiveCareDetail.subLocation}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                {state.data.repeatedUserTxns?.length !== 0 &&
                  state.data.repeatedUserTxns !== null && (
                    <div className="col-3">
                      <b className="mb-0 mt-4 text-start">Repeated Enquiry</b>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div
                          style={{
                            width: 400,
                            height: 150,
                            border: "1px solid gray",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: 40,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                width: 192,
                                height: 40,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "baseline",
                                border: "1px solid black",
                                backgroundColor: "#d3d3d3",
                              }}
                            >
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: 700,
                                  marginTop: 7,
                                  paddingLeft: 10,
                                }}
                              >
                                Source
                              </p>
                            </div>
                            <div
                              style={{
                                width: 208,
                                height: 40,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                border: "1px solid black",
                                backgroundColor: "#d3d3d3",
                              }}
                            >
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: 700,
                                  marginTop: 20,
                                  paddingLeft: 10,
                                }}
                              >
                                Date
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              maxHeight: 108,
                              display: "flex",
                              overflowY: "scroll",
                            }}
                          >
                            <div
                              style={{
                                width: "50%",
                                height: "100%",
                                border: "1px solid gray",
                              }}
                            >
                              {state.data.repeatedUserTxns?.map((val,index) => {
                                return (
                                  <p
                                  key={index}
                                    style={{
                                      textAlign: "left",
                                      fontSize: 14,
                                      paddingLeft: 10,
                                    }}
                                  >
                                    {val.source}
                                  </p>
                                );
                              })}
                            </div>
                            <div
                              style={{
                                width: "50%",
                                height: "100%",
                                border: "1px solid gray",
                              }}
                            >
                              {state.data.repeatedUserTxns?.map((val,index) => {
                                return (
                                  <p
                                    key={index}
                                    style={{
                                      textAlign: "left",
                                      fontSize: 14,
                                      paddingLeft: 10,
                                    }}
                                  >
                                    {val.createdDate}
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {checkPermission("CALL_RECORDING_VIEW") && (
                  <>
                    {state.callDetails?.length !== 0 &&
                      state.callDetails !== null && (
                        <div className="col-3">
                          <b className="mb-0 mt-4 text-start">
                            Call Recordings
                          </b>
                          <div className="ed-txt-2 mb-4 text-start">
                            <div
                              style={{
                                width: 400,
                                height: 200,
                                border: "1px solid gray",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: 40,
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    width: "48.5%",
                                    height: 40,
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "baseline",
                                    border: "1px solid black",
                                    backgroundColor: "#d3d3d3",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: 700,
                                      marginTop: 7,
                                      paddingLeft: 10,
                                    }}
                                  >
                                    Date
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "51.5%",
                                    height: 40,
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    border: "1px solid black",
                                    backgroundColor: "#d3d3d3",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: 700,
                                      marginTop: 20,
                                      paddingLeft: 10,
                                    }}
                                  >
                                    Call Type
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  maxHeight: 155,
                                  display: "flex",
                                  overflowY: "scroll",
                                }}
                              >
                                <div
                                  style={{
                                    width: "50%",
                                    height: "100%",
                                    border: "1px solid gray",
                                  }}
                                >
                                  {state.callDetails?.map((val,index) => {
                                    return (
                                      <>
                                        <p
                                          key={index}
                                          style={{
                                            textAlign: "left",
                                            fontSize: 14,
                                            paddingLeft: 10,
                                            // cursor: "pointer",
                                            // color: "blue",
                                          }}
                                        >
                                          {val.date}
                                        </p>
                                        <hr />
                                      </>
                                    );
                                  })}
                                </div>
                                <div
                                  style={{
                                    width: "50%",
                                    height: "100%",
                                    border: "1px solid gray",
                                  }}
                                >
                                  {state.callDetails?.map((val,index) => {
                                    return (
                                      <>
                                        <div style={{ display: "flex" }} key={index}>
                                          <p
                                            style={{
                                              textAlign: "left",
                                              fontSize: 14,
                                              margin: 0,
                                              padding: 0,
                                            }}
                                          >
                                            {val.callType}
                                          </p>
                                          <p
                                            onClick={() =>
                                              state.getCallLinks(val?.callSid)
                                            }
                                            style={{
                                              margin: 0,
                                              paddingLeft: 10,
                                              paddingTop: -10,
                                            }}
                                          >
                                            <PlayCircleOutlineIcon
                                              style={{ cursor: "pointer" }}
                                            />
                                          </p>
                                        </div>
                                        <hr />
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}

                {checkPermission("MERGE_LEAD_BUTTON") &&
                  state.statusId === 26 && (
                    <button
                      style={{ width: 180, height: 50, marginLeft: 10 }}
                      onClick={() => {
                        state.setOpen(true);
                        state.setPopUpType(true);
                      }}
                    >
                      {" "}
                      Merge Duplicate Lead
                    </button>
                  )}

                {checkPermission("SEND_FEEDBACK_LINK_BTN") &&
                  state.statusId === 19 && (
                    <button
                      style={{ width: 180, height: 50, marginLeft: 10 }}
                      onClick={() => {
                        state.feedbackLink();
                      }}
                    >
                      {" "}
                      Send Feedback Link
                    </button>
                  )}

                {checkPermission("PATIENT_REFERRAL_LINK_BTN") &&
                  state.statusId === 19 && (
                    <button
                      style={{ width: 200, height: 50, marginLeft: 10 }}
                      onClick={() => {
                        state.sendPatientReferral();
                      }}
                    >
                      {" "}
                      Send Patient Referral Link
                    </button>
                  )}

                {checkPermission("SEND_FEEDBACK_MESSAGE_BTN") &&
                  state.statusId === 19 && (
                    <button
                      style={{ width: 180, height: 50, marginLeft: 10 }}
                      onClick={() => {
                        state.feedbackMessage();
                      }}
                    >
                      {" "}
                      Send Feedback Message
                    </button>
                  )}

                {checkPermission("FEEDBACK_CALL_NOT_PICKED_BTN") &&
                  state.statusId === 19 && (
                    <button
                      style={{ width: 240, height: 50, marginLeft: 10 }}
                      onClick={() => {
                        state.sendFeedbackCallNotPicked();
                      }}
                    >
                      {" "}
                      Send Feedback Call Not Picked Message
                    </button>
                  )}

                <hr />
              </div>
              <div
                style={{
                  display: "flex",
                  paddingRight: 20,
                  gap: "10px",
                }}
              >
                {checkPermission("ENQUIRY_UPDATE_SUB_TAB") && (
                  <div>
                    <button
                      className={
                        state.selectedTab.isOpen
                          ? "active-class"
                          : "disabled-class"
                      }
                      style={{ padding: 10, width: 180 }}
                      onClick={() => state.handleHideFun(1)}
                    >
                      Enquiry Update
                    </button>
                  </div>
                )}
                {checkPermission("PATIENT_DOCS_SUB_TAB") && (
                  <div>
                    <button
                      className={
                        state.selectedTab.isPatientsDocs
                          ? "active-class"
                          : "disabled-class"
                      }
                      style={{ padding: 10, width: 180 }}
                      onClick={() => state.handleHideFun(3)}
                    >
                      Patient Docs
                    </button>
                  </div>
                )}
                {checkPermission("PATIENT_DETAIL_TAB") && (
                  <div>
                    <button
                      className={
                        state.selectedTab.isPatientsDetail
                          ? "active-class"
                          : "disabled-class"
                      }
                      style={{ padding: 10, width: 180 }}
                      onClick={() => state.handleHideFun(4)}
                    >
                      Patient Detail
                    </button>
                  </div>
                )}
              </div>
              <hr />
              {state.selectedTab.isOpen ? (
                <div>
                  <div>
                    <Row
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Col xl={2}>
                        <h5>Status</h5>
                        <div>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="none"
                              style={{
                                width: 200,
                                height: 36,
                                border: `1px solid gray`,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {state?.selectedPreLeadSatus ||
                                state?.data?.preLeadStatus}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              as={CustomMenu}
                              style={{
                                maxHeight: 200,
                                overflowY: "scroll",
                                overflowX: "hidden",
                                width: 260,
                              }}
                            >
                              {state.statusList.map((value, index) => {
                                return (
                                  <Dropdown.Item
                                    key={index}
                                    href="#"
                                    style={{
                                      width: 200,
                                      maxHeight: 200,
                                    }}
                                    onClick={() => {
                                      state.handleStatus(value.name, value.id);
                                      state.getLostReason(value.id);
                                    }}
                                  >
                                    {value.name}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </Col>

                      {state.showEnquiryActionFields() && (
                        <Col xl={2}>
                          <h5 style={{ marginBottom: -2 }}>Follow Up</h5>
                          <DatePicker
                            className="myDatePicker"
                            selected={
                              state.followUpDate ? state.followUpDate : null
                            }
                            filterTime={state.filterPassedTime}
                            onChange={(date) => state.setFollowUpDate(date)}
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mm aa"
                            minDate={new Date()}
                          />
                        </Col>
                      )}

                      {checkPermission("ENQUIRY_UPDATE_ASSIGNED") ? (
                        <Col xl={2}>
                          <h5>Assign To</h5>
                          <div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="none"
                                style={{
                                  width: 200,
                                  height: 36,
                                  border: `1px solid gray`,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {state.assignedAgent ||
                                  state?.data?.preAssignToName}
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                as={CustomMenu}
                                style={{
                                  maxHeight: 200,
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  width: 260,
                                }}
                              >
                                {state?.preLeadTeams.map((value, id) => {
                                  return (
                                    <Dropdown.Item
                                      key={id}
                                      href="#"
                                      style={{
                                        width: 200,
                                        maxHeight: 200,
                                      }}
                                      onClick={() => {
                                        state.handleToAssigned(
                                          value?.label,
                                          value?.value
                                        );
                                        state.getLostReason(value.id);
                                      }}
                                    >
                                      {value?.label}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                      ) : null}
                      <>
                        {(state?.data?.type == "Enquiry" ||
                          state?.data?.type == "Other Leads") &&
                          checkPermission("ENQUIRY_UPDATE_TREATMENT") && (
                            <Col xl={2}>
                              <h5>Treatment</h5>
                              <div>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="none"
                                    style={{
                                      width: 200,
                                      height: 36,
                                      border: `1px solid gray`,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    {!!state.selectedTreatment
                                      ? state.selectedTreatment.name
                                      : "Treatment"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    as={CustomMenu}
                                    style={{
                                      maxHeight: 200,
                                      overflowY: "scroll",
                                      overflowX: "hidden",
                                      width: 260,
                                    }}
                                  >
                                    {state.treatments.map((value, index) => {
                                      return (
                                        <Dropdown.Item
                                          key={index}
                                          href="#"
                                          style={{ width: 200, maxHeight: 200 }}
                                          onClick={() =>
                                            state.setselectedTreatment(value)
                                          }
                                        >
                                          {value.name}
                                        </Dropdown.Item>
                                      );
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </Col>
                          )}
                        {(state?.data?.type == "Enquiry" ||
                          state?.data?.type == "Other Leads") &&
                          checkPermission("ENQUIRY_UPDATE_LOCATION") && (
                            <Col xl={2}>
                              <h5>Location</h5>
                              <div>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="none"
                                    style={{
                                      width: 200,
                                      height: 36,
                                      border: `1px solid gray`,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    {!!state.selectedLocation
                                      ? state.selectedLocation.name
                                      : "Location"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    as={CustomMenu}
                                    style={{
                                      maxHeight: 200,
                                      overflowY: "scroll",
                                      overflowX: "hidden",
                                      width: 260,
                                    }}
                                  >
                                    {state.locations.map((value, index) => {
                                      return (
                                        <Dropdown.Item
                                          key={index}
                                          href="#"
                                          style={{
                                            width: 200,
                                            maxHeight: 200,
                                          }}
                                          onClick={() =>
                                            state.setSelectedLocation(value)
                                          }
                                        >
                                          {value.name}
                                        </Dropdown.Item>
                                      );
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </Col>
                          )}
                        <Col xl={2}>
                          <h5>Payment Type</h5>
                          <div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="none"
                                style={{
                                  width: 200,
                                  height: 36,
                                  border: `1px solid gray`,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {!!state.selectedPaymentType
                                  ? state.selectedPaymentType
                                  : "Payment Type"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {state.ipPaymentModes.map((value, index) => {
                                  return (
                                    <Dropdown.Item
                                      key={index}
                                      href="#"
                                      style={{ width: 200 }}
                                      onClick={() =>
                                        state.setSelectedPaymentType(value)
                                      }
                                    >
                                      {value}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                        <hr style={{ margin: "30px 0px" }} />

                        <div>
                          <PreLoadsFaqs
                            faqData={state?.faqData}
                            faqAnswer={state?.faqAnswer}
                            handleChange={state?.handleChange}
                            isPreLeadEdit={state?.isPreLeadEdit}
                            setIsPreLeadEdit={state?.setIsPreLeadEdit}
                            setIsEditing={state?.setIsEditing}
                            isEditing={state?.isEditing}
                            setFaqData={state?.setFaqData}
                            preLeadStatus={state?.data?.preLeadStatus}
                          />
                        </div>

                        {state.showIntentFields() && (
                          <Col xl={2}>
                            <h5>Intent</h5>
                            <div>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="none"
                                  style={{
                                    width: 200,
                                    height: 36,
                                    border: `1px solid gray`,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {!!state.selectedIntent
                                    ? state.selectedIntent
                                    : "Intent"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {state.intentModes.map((value, index) => {
                                    return (
                                      <Dropdown.Item
                                        key={index}
                                        href="#"
                                        style={{ width: 200 }}
                                        onClick={() =>
                                          state.setSelectedIntent(value)
                                        }
                                      >
                                        {value}
                                      </Dropdown.Item>
                                    );
                                  })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </Col>
                        )}
                      </>
                      <Col xl={2}>
                        {state.statusId === 21 ? (
                          <div style={{ marginTop: 10 }}>
                            <p
                              style={{
                                textAlign: "left",
                                fontWeight: 600,
                                marginBottom: 0,
                              }}
                            >
                              Sub Status
                            </p>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="none"
                                style={{
                                  width: 270,
                                  border: `1px solid gray`,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  margin: "0px",
                                }}
                              >
                                {state.subStatusId
                                  ? state.subStatusId.name
                                  : "Select Reasons"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {state.subStatus.map((value, index) => {
                                  return (
                                    <Dropdown.Item
                                      key={index}
                                      href="#"
                                      style={{ width: 270, maxHeight: 200 }}
                                      onClick={() =>
                                        state.handleSubStatus(value)
                                      }
                                    >
                                      {value.name}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ) : null}
                      </Col>
                      <Col xl={2}>
                        {state.statusId === 28 || state.statusId === 30 ? (
                          <div style={{ marginTop: 10 }}>
                            <p
                              style={{
                                textAlign: "left",
                                fontWeight: 600,
                                marginBottom: 0,
                              }}
                            >
                              {state.status} Reason
                            </p>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="none"
                                style={{
                                  width: 270,
                                  border: `1px solid gray`,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  margin: "0px",
                                }}
                              >
                                {state.selectedReasons
                                  ? state.selectedReasons.name
                                  : "Select Reasons"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {state.reasons.map((value, index) => {
                                  return (
                                    <Dropdown.Item
                                      key={index}
                                      href="#"
                                      style={{ width: 270, maxHeight: 200 }}
                                      onClick={() =>
                                        state.setSelectedReasons(value)
                                      }
                                    >
                                      {value.name}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="marginleft border-top"
                    style={{ marginTop: 20 }}
                  >
                    <div>
                      <div className="row border-top pt-4">
                        <div className="col-lg-6 col-10">
                          <div className="row">
                            <div className="col-lg-6">
                              <div style={{ fontWeight: 600 }}>
                                Add Your Comments
                              </div>
                              <div
                                className="col-lg-12"
                                style={{
                                  height: "auto",
                                  width: "100%",
                                }}
                              >
                                {state?.selectedComments?.length > 0 && (
                                  <div
                                    style={{
                                      height: "auto",
                                      display: "flex",
                                      width: "100%",
                                      flexWrap: "wrap",
                                      gap: "10px",
                                      overflow: "scroll",
                                      marginTop: "20px",
                                    }}
                                  >
                                    {state?.selectedComments?.map(
                                      (data, id) => {
                                        return (
                                          <div key={id}>
                                            <span
                                              style={{
                                                margin: "0px",
                                                padding: "7px 20px",
                                                backgroundColor: "#F5F7F8",
                                                borderRadius: "50px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              {data}
                                              <span
                                                style={{
                                                  width: 25,
                                                  height: 25,
                                                  cursor: "pointer",
                                                  borderRadius: "50px",
                                                  marginLeft: "10px",
                                                  marginRight: "-10px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                                onClick={() =>
                                                  state?.handleDelete(id)
                                                }
                                              >
                                                <HighlightOffIcon />
                                              </span>
                                            </span>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>

                              <div
                                className="col-lg-3"
                                style={{
                                  width: "100%",
                                  height: "40px",
                                }}
                              >
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="none"
                                    style={{
                                      width: "100%",
                                      height: "60px",
                                      border: `1px solid gray`,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    {"Add Comments"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    style={{
                                      height: 288,
                                      overflow: "scroll",
                                      zIndex: 99,
                                      marginTop: "5px",
                                    }}
                                  >
                                    {state?.allComments?.map((value, id) => {
                                      return (
                                        <Dropdown.Item
                                          key={id}
                                          href="#"
                                          style={{ width: 550 }}
                                          onClick={() =>
                                            state?.handleComments(
                                              value.value,
                                              value.id
                                            )
                                          }
                                        >
                                          {value.value}
                                        </Dropdown.Item>
                                      );
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <p
                                style={{
                                  textAlign: "left",
                                  fontWeight: 600,
                                  margin: 0,
                                  marginBottom: "10px",
                                }}
                              >
                                Add Your Notes
                              </p>
                              <textarea
                                style={{
                                  width: "100%",
                                  height: 100,
                                  borderRadius: 5,
                                  paddingLeft: 10,
                                  border: `1px solid gray`,
                                }}
                                type="text"
                                value={state?.selectedNotes}
                                placeholder="Notes..."
                                onChange={(e) =>
                                  state?.setSelectedNotes(e?.target?.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {checkPermission("ENQUIRY_UPDATE_BTN") && (
                          <div class="col-1">
                            <div>
                              <button
                                style={state.buttonStyle}
                                onClick={() => {
                                  state.saveEnquiryPreLeadData();
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="col-lg-5 col-12">
                          {state.selectedTab.isOpen ? (
                            <>
                              {" "}
                              {state.status !== "New Lead" && (
                                <div
                                  style={{
                                    width: "100%",
                                    height: 360,
                                    backgroundColor: "white",
                                    overflowX: "hidden",
                                    overflowY: "scroll",
                                  }}
                                >
                                  {state.usersHistory
                                    ? state.usersHistory.map((item, index) => {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              backgroundColor: "#EFEFEF",
                                              padding: 30,
                                              borderRadius: 10,
                                              marginTop: 10,
                                              marginRight: 10,
                                            }}
                                          >
                                            <div style={{ display: "flex" }}>
                                              <h6 style={{ color: "#242F9B" }}>
                                                {item.addedBy}
                                              </h6>
                                              <p
                                                style={{
                                                  marginLeft: 10,
                                                  marginBottom: 6,
                                                }}
                                              >
                                                Added a comment on
                                              </p>
                                              <p
                                                style={{
                                                  marginLeft: 10,
                                                  marginBottom: 6,
                                                  color: "#242F9B",
                                                }}
                                              >
                                                {
                                                  moment(item.addedDate)
                                                    .format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    )
                                                    .split("+")[0]
                                                }
                                              </p>
                                            </div>
                                            <span>{item.comments} </span>
                                            <br></br>
                                            <br></br>
                                            <span
                                              style={{
                                                marginLeft: 0,
                                                marginBottom: 6,
                                              }}
                                            >
                                              Status changed from
                                            </span>
                                            <span
                                              style={{
                                                color: "#242F9B",
                                              }}
                                            >
                                              {item.oldStatus &&
                                                item.newStatus &&
                                                ` ${item.oldStatus} to ${item.newStatus} `}
                                            </span>
                                            <br></br>
                                            <br></br>
                                            {item.followupDate && (
                                              <>
                                                <span
                                                  style={{
                                                    marginLeft: 0,
                                                    marginBottom: 6,
                                                  }}
                                                >
                                                  Updated followup date to
                                                </span>
                                                <span
                                                  style={{
                                                    color: "#242F9B",
                                                  }}
                                                >
                                                  {` ${item.followupDate}`}
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        );
                                      })
                                    : null}
                                </div>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ color: "green" }}>{state.message}</p>
                  </div>
                </div>
              ) : null}
              {state.selectedTab.isPatientsDocs ? (
                <PatientsDoc userTrxnId={state.data.enquiryId} />
              ) : null}
              {state.activeCheckBoxTwo ? (
                <div>
                  <h1>tutyiyuiyu</h1>
                </div>
              ) : null}
              {state.selectedTab.isPatientsDetail ? (
                <PatientsDetail
                  patientId={state.data.patientId}
                  refreshFn={state.refreshPersonalDetails}
                  handleHide={state.handleHideFun}
                />
              ) : null}
            </div>
          </div>
          <div>
            <Dialog
              open={state?.open}
              onClose={state?.handleClose}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth={state.popUpType ? "xs" : "md"}
            >
              {state?.popUpType ? (
                <>
                  <DialogTitle>
                    <b>Merge Enquiry Data</b>
                  </DialogTitle>
                  <DialogContent>
                    <input
                      style={{ height: "30px" }}
                      type="text"
                      placeholder="Enter original enquiry id"
                      onChange={(e) => {
                        state.setOriginalUserTxnId(e.target.value);
                      }}
                      value={state.originalUserTxnId}
                    ></input>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={state?.handleClose} variant="outlined">
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        state.mergeLead();
                      }}
                      variant="contained"
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </>
              ) : (
                !state.popUpType &&
                state.open && (
                  <>
                    <DialogTitle>Confirm the date and time</DialogTitle>
                    <DialogContent>
                      {state.statusId == 19 || state.statusId == 18 ? (
                        <OPiPpopup
                          label={"Arrival Date Time"}
                          statusId={state.statusId}
                          date={
                            state.appointmentDetails?.ipDetails?.arrivalDateTime
                          }
                          data={state.appointmentDetails}
                          setFollowUpDate={state.setAppointmentDetails}
                        />
                      ) : (
                        <OPiPpopup
                          label={"Appointment Date Time"}
                          statusId={state.statusId}
                          data={state.appointmentDetails}
                          date={state?.appointmentDetails?.opDetails?.dateTime}
                          setFollowUpDate={state.setAppointmentDetails}
                        />
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={state?.handleClose} variant="outlined">
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          state.saveAppointmentDetails();
                        }}
                        variant="contained"
                      >
                        Confirm
                      </Button>
                    </DialogActions>
                  </>
                )
              )}
            </Dialog>
          </div>
          <ChatPopUp />
        </div>
      )}
    </>
  );
};
export default PreLeadsDetail;

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Search..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child?.props?.children?.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);
