import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Bye from "../../assets/bye.svg";
import Time1 from "../../assets/time2.svg";
import { Grid, Typography } from "@mui/material";
// import { getWorkingHours } from "../../util/getHours";

export default function ClockOutPopUp({
  open,
  handleClose,
  handleClockOut,
  hour,
}) {
  return (
    <React.Fragment>
      <Dialog maxWidth="sm" fullWidth open={open}>
        <DialogTitle
          style={{
            textTransform: "capitalize",
            fontSize: "30px",
            fontWeight: "bolder",
            fontFamily: "'Inter', sans-serif",
          }}
        >
          Clock Out
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box
              width="50%"
              height="50%"
              component="img"
              src={hour >= 8 ? Bye : Time1}
            ></Box>
            <div>
              {hour < 8 && (
                <h3 style={{ textAlign: "center" }}>{hour}hrs Completed</h3>
              )}
              <br />
              <h4
                style={{
                  textTransform: "capitalize",
                  fontSize: "20px",
                  fontWeight: "bolder",
                  textAlign: "center",
                  fontFamily: "'Inter', sans-serif",
                }}
              >
                {hour >= 8 ? `Good Job` : `It seems you are early`}
              </h4>
              <p
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {hour >= 8
                  ? `Keep up the great work ✌️`
                  : `You haven't completed 8hrs.Are you sure you want to clock-out ?`}
              </p>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                padding: "10px 20px",
                margin: "0px 10px",
                backgroundColor: "#666867",
                ":hover": { backgroundColor: "#424444" },
              }}
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#00997c",
                ":hover": { backgroundColor: "#00996c" },
              }}
              variant="contained"
              onClick={handleClockOut}
            >
              Clock-Out
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
