import React from "react";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./Pages/Login/Login";
import Home from "./Pages/Home/Home";
import Arrival from "./Pages/Arrival/Arrival";
import PartnerPatients from "./Pages/Partner/Patients/patients";
import EnquiryDetails from "./Pages/enquiryDetails/EnquiryDetails.js";
import PartnerPatientDetail from "./Pages/Partner/PatientInner/PatientDetail.js";
import Enquiry from "./Pages/Enquiry/Enquiry";
import Partnership from "./Pages/Enquiry/Partnership";
import MykareEnquiryDetail from "./Pages/myKareEnquiryDetail/MykareEnquiryDetail";
import MyKarePartnerShipDetail from "./Pages/myKarePartnerShipDetail/MyKarePartnerShipDetail";
import Summary from "./Pages/Summary/Summary";
import Reports from "./Pages/Reports/Reports";
import HospitalPage from "./Pages/Hospitals/HospitalPage/HospitalPage";
import HospitalDetail from "./Pages/Hospitals/HospitalDetail/HospitalDetail";
import DoctorPage from "./Pages/Doctors/DoctorPage/DoctorsPage";
import InsurancePage from "./Pages/Insurance/InsurancePage/InsurancePage";
import IpOp from "./Pages/IpOpDetails/IpOpDetailsPage/IpOpDetailsPage";
import BulkMessage from "./Pages/Message/BulkMessage";
import AddonServiceEnquiryDetail from "./Pages/AddOnService/AddOnServiceEnquiryDetail";
import MssgSec from "./Pages/KareChat/MssgSec";
import AppointmentInner from "./Pages/IpOpDetails/AppointmentInner/AppointmentInner";
import InsuranceInnerPage from "./Pages/Insurance/InsuranceInnerPage/InsuranceInnerPage";
import PreventiveCare from "./Pages/PreventiveCare/preventiveCareEnquiry";
import SMO from "./Pages/SMO/smoEnquiry";
import AddOnService from "./Pages/AddOnService/addOnServiceEnquiry";
import DoctorInnerPage from "./Pages/Doctors/DoctorPage/DoctorInnerPage/DoctorInnerPage";
import Settings from "./Pages/Settings/Settings";
import PartnerSettings from "./Pages/Partner/Settings/Settings";
import Scan from "./Pages/Scan/Scan.js";
import ScanDetail from "./Pages/Scan/ScanDetails.js";

import KareTripEnquiry from "./Pages/Karetrip/Enquiry/KaretripEnquiry";
import KaretripEnquiryDetail from "./Pages/Karetrip/Enquiry/KaretripEnquiryDetail";
import KareMessage from "./Pages/KareMessage/KareMessage";
import SidebarSettings from "./Pages/Settings/SidebarSettings";
import LotieAnimation from "./Pages/LotieAnimation/LotieAnimation";
import KareTripExicutiveReports from "./Pages/Karetrip/Enquiry/kareTripExicutiveReports";
import KareBuddyActivity from "./Pages/KarebuddyActivity/KareBuddyActivity";
import { BASE_PATH } from "./util/AppConstants";
import OtherLeadEnquiry from "./Pages/OtherLeads/OtherLeadEnquiry";
import PreLeads from "./Pages/PreLeads/PreLeads";
import PreLeadsDetail from "./Pages/PreLeads/PreLeadsDetail/PreLeadsDetail";
import SessionExpired from "./Pages/Session/SessionExpired.js";
import KarePlus from "./Pages/Kareplus/KarePlus.js";
import KarePlusDetail from "./Pages/Kareplus/KarePlusDetails.js";

function AppNavigator() {
  return (
    <Router basename={BASE_PATH}>
      <div>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/sessionexpired">
            <SessionExpired />
          </Route>
          <Route path="/arrival">
            <Arrival />
          </Route>
          <Route path="/patients">
            <PartnerPatients />
          </Route>
          <Route path="/PartnerPatientDetail">
            <PartnerPatientDetail />
          </Route>
          <Route path="/partner-settings">
            <PartnerSettings />
          </Route>
          <Route path="/enquiryDetails">
            <EnquiryDetails />
          </Route>
          <Route path="/enquiry">
            <Enquiry />
          </Route>
          <Route path="/kareTripEnquiry">
            <KareTripEnquiry />
          </Route>{" "}
          <Route path="/kareTripExicutiveReports">
            <KareTripExicutiveReports />
          </Route>
          <Route path="/lotieAnimation">
            <LotieAnimation />
          </Route>
          <Route path="/preventiveCare">
            <PreventiveCare />
          </Route>
          <Route path="/otherlead">
            <OtherLeadEnquiry />
          </Route>
          <Route path="/scan">
            <Scan />
          </Route>
          <Route path="/kareplus">
            <KarePlus />
          </Route>
          <Route path="/scanDetail/categoryContextId::categoryContextId/userTxnCode::userTxnCode">
            <ScanDetail />
          </Route>
          <Route path="/kareplusDetail/categoryContextId::categoryContextId/userTxnCode::userTxnCode">
            <KarePlusDetail />
          </Route>
          <Route path="/preleads">
            <PreLeads />
          </Route>
          <Route path="/smo">
            <SMO />
          </Route>
          <Route path="/addOnService">
            <AddOnService />
          </Route>
          <Route path="/report">
            <Reports />
          </Route>
          <Route path="/hospital">
            <HospitalPage />
          </Route>
          <Route path="/hospitalDetail">
            <HospitalDetail />
          </Route>
          <Route path="/doctors">
            <DoctorPage />
          </Route>
          <Route path="/insurance">
            <InsurancePage />
          </Route>
          <Route path="/ipOp">
            <IpOp />
          </Route>
          <Route path="/summary">
            <Summary />
          </Route>
          <Route path="/partnership">
            <Partnership />
          </Route>
          <Route path="/mykareEnquiryDetail">
            <MykareEnquiryDetail />
          </Route>
          <Route path="/preLeadsDetail">
            <PreLeadsDetail />
          </Route>
          <Route path="/KaretripEnquiryDetail">
            <KaretripEnquiryDetail />
          </Route>
          <Route path="/mykarePartnerShipDetail">
            <MyKarePartnerShipDetail />
          </Route>
          <Route path="/addOnServiceEnquiryDetail">
            <AddonServiceEnquiryDetail />
          </Route>
          {/* <Route path="/bulkMessage">
            <BulkMessage />
          </Route> */}
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/sidebarsettings">
            <SidebarSettings />
          </Route>
          <Route path="/chatpage">
            <MssgSec />
          </Route>
          <Route path="/karemessage">
            <KareMessage />
          </Route>
          <Route path="/appointmentInner">
            <AppointmentInner />
          </Route>
          <Route path="/insuranceInner">
            <InsuranceInnerPage />
          </Route>
          <Route path="/doctorInner">
            <DoctorInnerPage />
          </Route>
          <Route path="/activity">
            <KareBuddyActivity />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default AppNavigator;
