import "./AutoCall.css";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import useAutoCall from "./useAutoCall.logic";
import DatePicker from "react-datepicker";
import { checkPermission } from "../../util/permissionUtils";

function AutoCallPanel(props) {
  const state = useAutoCall(props);
  return (
    <div>
      <hr />
      <div className="row popup-view">
        <div className="col-5">
          <div className="row">
            <div className="col-3">
              From<br></br>
              <DatePicker
                className="mySearchEnquiryUpDatePicker"
                selected={
                  state.fromDate ? new Date(state.fromDate) : new Date()
                }
                onChange={(date) => {
                  state.setFromDate(date);
                }}
                maxDate={new Date()}
              />
            </div>
            <div className="col-3">
              To<br></br>
              <DatePicker
                className="mySearchEnquiryUpDatePicker"
                selected={state.toDate ? new Date(state.toDate) : new Date()}
                onChange={(date) => {
                  state.setToDate(date);
                  // state.setReload(true);
                }}
                maxDate={new Date()}
              />
            </div>
            <div className="col-3">
              Status
              <br></br>
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 160,
                    height: 36,
                    border: `1px solid #00997c`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {state.status ? state.status?.name : "Status"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    maxHeight: 200,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    width: 200,
                  }}
                >
                  {state.statusList?.length
                    ? state.statusList?.map((value, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 200 }}
                            onClick={() => {
                              state.handleStatus(value);
                              console.log("value=================", value);
                            }}
                          >
                            {value.name}
                          </Dropdown.Item>
                        );
                      })
                    : null}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {checkPermission("LEADS_ASSIGNED_FIELD") && (
              <div className="col-md-2">
                Assign To<br></br>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 160,
                      height: 36,
                      border: `1px solid #00997c`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.assignedUser.selected
                      ? state.assignedUser.selected
                      : "Select"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      maxHeight: 200,
                      overflowY: "scroll",
                      overflowX: "hidden",
                      width: 150,
                    }}
                  >
                    {state.assignedUser.list.map(({ name, code }, index) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 160 }}
                            onClick={() => {
                              state.handleAssignedToChange(code, name);
                            }}
                          >
                            {name}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-1">
          <button
            style={{ padding: 12, width: 80 }}
            onClick={() => props.onClicked(false)}
          >
            Cancel
          </button>
        </div>
        <div className="col-lg-1">
          <button
            onClick={() => {
              if (state?.scheduleStatus === "STOP" || !state.scheduleStatus) {
                state.scheduleAutoCall();
              } else {
                state.stopAutoCallSchedule();
              }
            }}
            style={{ padding: 12, width: 100 }}
          >
            {!state.scheduleStatus || state?.scheduleStatus === "STOP"
              ? "Start"
              : "Stop"}
          </button>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default AutoCallPanel;
