import React, { useState, useEffect } from "react";
import {
  getPatientsDetails,
  updatePatientsDetails,
} from "../../../services/mykare/PatientsService";
import "./BasicDetailsForm.css";
import {
  getUserEducationList,
  getUserLanguageList,
  getUserLifestyleList,
  getUserMedicalHistoryList,
  getUserOccupationsList,
  getUserLocationList,
} from "../../../services/mykare/enquiryService";
import {
  getUserName,
  getUserCode,
  getUserTxnId,
} from "../../../store/DataStore";

// material Ui
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function BasicDetailForm({
  patientId,
  userRole,
  onclose,
  userTrxnId,
  onClick,
}) {
  const theme = useTheme();

  // State
  const [userData, setUserData] = useState("");

  const [value, setValue] = useState({
    addedBy: "",
    addedByCode: "",
    age: "",
    city: "",
    educationId: "",
    email: "",
    gender: "",
    healthInsurance: false,
    languageIds: [],
    lifestyleIds: [],
    maritalStatus: "",
    medicalHistoryIds: [],
    occupationId: "",
    patientName: "",
    userId: 0,
    userTxnId: 0,
  });

  const [lists, setLists] = useState({
    educaitonList: "",
    languageList: "",
    lifestyleList: "",
    medicalHistoryList: "",
    occupationList: "",
    locationList: "",
  });

  function checkDataValidity(patientData) {
    for (const prop in patientData) {
      if (patientData[prop] === null || patientData[prop] === undefined) {
        return false; // Data is null or undefined
      }
    }
    return true; // All data is valid
  }



  useEffect(() => {
    // setUserResData(userData)
    let isMounted = true; //

    try {
      fetch = async (id) => {
        const res = await getPatientsDetails(patientId,userTrxnId);
        // console.log(res.data,">>>>>>>res.data<<<<<<<<")
        const educaitonList = await getUserEducationList();

        const languageList = await getUserLanguageList();

        const lifestyleList = await getUserLifestyleList();

        const medicalHistoryList = await getUserMedicalHistoryList();

        const occupationList = await getUserOccupationsList();

        const locationList = await getUserLocationList();

        const addedBy = getUserName();
        const addedByCode = getUserCode();

        console.log();

        if (locationList.data && occupationList.data)
          setLists({
            educaitonList: educaitonList?.data,
            languageList: languageList?.data,
            lifestyleList: lifestyleList?.data,
            medicalHistoryList: medicalHistoryList?.data,
            occupationList: occupationList?.data,
            locationList: locationList?.data,
          });

        const data = res.data;

        if (data) {
          setUserData(data);
          const isdataNull = checkDataValidity(data);
          if (isdataNull) {
            onClick();
          }

          setValue({
            addedBy: addedBy,
            addedByCode: addedByCode,
            age: data.age || "",
            city: data.city || "",
            educationId: data.education ? data.education.id : "",
            email: data.email ? data.email : "",
            gender: data.gender ? data.gender : "",
            healthInsurance: data.healthInsurance
              ? data.healthInsurance
              : false,
            languageIds: data.languages
              ? data.languages.map((item) => item.id)
              : [],
            lifestyleIds: data.lifestyle
              ? data.lifestyle.map((item) => item.id)
              : [],
            maritalStatus: data.maritalStatus ? data.maritalStatus : "",
            medicalHistoryIds: data.medicalHistories
              ? data.medicalHistories.map((item) => item.id)
              : [],
            occupationId: data.occupation ? data.occupation.id : "",
            patientName: data.patientName,
            userId: data.userId,
            userTxnId: userTrxnId,
          });

          setTimeout(() => {}, 1000);
        }
      };
      fetch(patientId);
    } catch (e) {
      alert(e);
    }

    return () => {};
  }, []);

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const formData = {
    age: userData?.age || value?.age,
    patientName: userData?.patientName || value?.patientName,
    gender: userData?.gender || value?.gender,
    maritalStatus: userData?.maritalStatus || value?.maritalStatus,
    occupationId: userData?.occupation?.name || value?.occupationId,
    city: userData?.city || value?.city,
    languages:userData?.languages || value?.languageIds,
  };
  const isFormDataValid = Object.values(formData).every(value => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return value !== undefined && value !== null && value !== "";
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    // onclose();
    // if (isFormDataValid) {
      const res = await updatePatientsDetails(value);
      if (res.status === 200) {
        alert("data saved successfully");
        onclose()
      } else {
        alert("data not saved error");
      }
    // }else{
    //   alert("please Fill Every Field")
    // }
  };

  return (
    <div className="BasicDetailsForm mt-3">
      {userData && (
        <div className="BasicDetialsForm-inner">
          {/* Patient Name */}
          <div className="mb-3">
            {!userData.patientName && (
              <TextField
                className="input"
                fullWidth
                id="patientName"
                label="Patient Name"
                value={value.patientName}
                name="patientName"
                onChange={handleInputChange}
              />
            )}
          </div>

          {/* Email */}
          <div className="mb-3">
            {!userData.email && (
              <TextField
                className="input"
                fullWidth
                id="email"
                label="Email"
                value={value.email}
                name="email"
                onChange={handleInputChange}
                type="email"
              />
            )}
          </div>

          {/* Age */}
          <div className="mb-3">
            {!userData.age && (
              <TextField
                className="input"
                fullWidth
                id="age"
                label="Patient Age"
                value={value.age}
                name="age"
                onChange={handleInputChange}
                type="number"
              />
            )}
          </div>

          {/* Gender */}
          {!userData.gender && (
            <div className="mb-3">
              <FormControl fullWidth>
                <InputLabel id="gender-label">gender</InputLabel>
                <Select
                  labelId="gender-label"
                  id="gender"
                  value={value.gender}
                  label="gender"
                  name="gender"
                  onChange={handleInputChange}
                >
                  <MenuItem value={""}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"MALE"}>Male</MenuItem>
                  <MenuItem value={"FEMALE"}>Female</MenuItem>
                  <MenuItem value={"OTHERS"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          {/* Marital Status */}
          {!userData.maritalStatus && (
            <div className="mb-3">
              <FormControl fullWidth>
                <InputLabel id="maritalStatus-label">MaritalStatus</InputLabel>
                <Select
                  labelId="maritalStatus-label"
                  id="maritalStatus"
                  value={value.maritalStatus}
                  label="maritalStatus"
                  name="maritalStatus"
                  onChange={handleInputChange}
                >
                  <MenuItem value={null}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"MARRIED"}>Married</MenuItem>
                  <MenuItem value={"UNMARRIED"}>Unmarried</MenuItem>
                  <MenuItem value={"WIDOWED"}>Widowed</MenuItem>
                  <MenuItem value={"DIVORCED"}>Divorced</MenuItem>
                  <MenuItem value={"SEPARATED"}>Separated</MenuItem>
                  {/* MARRIED, UNMARRIED, WIDOWED, DIVORCED, SEPARATED */}
                </Select>
              </FormControl>
            </div>
          )}

          {/* Occupation */}
          {!userData.occupation && (
            <div className="mb-3">
              <FormControl fullWidth>
                <InputLabel id="occupationId-label">Occupation</InputLabel>
                <Select
                  labelId="occupationId-label"
                  id="occupationId"
                  value={value.occupationId}
                  label="occupationId"
                  name="occupationId"
                  onChange={handleInputChange}
                >
                  <MenuItem value={""}>
                    <em>None</em>
                  </MenuItem>
                  {lists.occupationList &&
                    lists?.occupationList.map((item) => {
                      return (
                        <MenuItem key={`occupation:${item.id}`} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          )}

          {/* Education */}
          {userData.education == null && (
            <div className="mb-3">
              <FormControl fullWidth>
                <InputLabel id="educationId-label">Education</InputLabel>
                <Select
                  labelId="educationId-label"
                  id="occupationId"
                  value={value.educationId}
                  label="educationId"
                  name="educationId"
                  onChange={handleInputChange}
                >
                  <MenuItem value={""}>
                    <em>None</em>
                  </MenuItem>
                  {lists.educaitonList &&
                    lists?.educaitonList.map((item) => (
                      <MenuItem key={`educationId:${item.id}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}

          {/* city */}
          <div className="mb-3"></div>
          {!userData.city && (
            <TextField
              className="input"
              fullWidth
              id="city"
              label="Patient Location"
              value={value.city}
              name="city"
              onChange={handleInputChange}
              type="text"
            />
          )}

          {/* language */}
          {userData.languages?.length === 0 || userData.languages == null && (
            <div className="my-3">
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="languageIds-chip-label">Language</InputLabel>
                <Select
                  labelId="languageIds-chip-label"
                  id="languageIds-chip"
                  multiple
                  value={value.languageIds}
                  onChange={handleInputChange}
                  name="languageIds"
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Language" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const language = lists.languageList.find(
                          (item) => item.id === value
                        );
                        return (
                          <Chip
                            key={value}
                            label={language ? language.name : ""}
                          />
                        );
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {lists.languageList &&
                    lists.languageList.map((item) => (
                      <MenuItem
                        key={`languageIds${item.id}`}
                        value={item.id}
                        style={getStyles(item.name, value.languageIds, theme)}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}

          {/* Insurance  */}
          {!userData.healthInsurance && (
            <div className="mb-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.healthInsurance}
                    value={value.healthInsurance}
                    onChange={(event) => {
                      event.preventDefault();
                      setValue((prev) => ({
                        ...prev,
                        healthInsurance: event.target.checked,
                      }));
                    }}
                    name="healthInsurance"
                  />
                }
                label="Health Insurance"
              />
            </div>
          )}

          {/* Lifestyle */}
          {!userData.lifestyle?.length == 0 && (
            <div className="mb-3">
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="lifestyleIds-chip-label">Life Style</InputLabel>
                <Select
                  labelId="lifestyleIds-chip-label"
                  id="lifestyleIds-chip"
                  multiple
                  value={value.lifestyleIds}
                  onChange={handleInputChange}
                  name="lifestyleIds"
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="lifestyleIds"
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const lifestyleIds = lists.lifestyleList.find(
                          (item) => item.id === value
                        );
                        return (
                          <Chip
                            key={value}
                            label={lifestyleIds ? lifestyleIds.name : ""}
                          />
                        );
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {lists.lifestyleList &&
                    lists.lifestyleList.map((item) => (
                      <MenuItem
                        key={`lifestyleList${item.id}`}
                        value={item.id}
                        style={getStyles(item.name, value.lifestyleIds, theme)}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}

          {/* Medical Condition */}
          {userData?.medicalHistories?.length == 0 && (
            <div className="mb-3">
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="medicalHistoryIds-chip-label">
                  Medical History
                </InputLabel>
                <Select
                  labelId="medicalHistoryIds-chip-label"
                  id="medicalHistoryIds-chip"
                  multiple
                  value={value.medicalHistoryIds}
                  onChange={handleInputChange}
                  name="medicalHistoryIds"
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="lifestyleIds"
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const medicalHistoryIds = lists.medicalHistoryList.find(
                          (item) => item.id === value
                        );
                        return (
                          <Chip
                            key={value}
                            label={
                              medicalHistoryIds ? medicalHistoryIds.name : ""
                            }
                          />
                        );
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {lists.medicalHistoryList &&
                    lists.medicalHistoryList.map((item) => (
                      <MenuItem
                        key={`medicalHistoryIds${item.id}`}
                        value={item.id}
                        style={getStyles(
                          item.name,
                          value.medicalHistoryIds,
                          theme
                        )}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}
          <button onClick={handleSubmit}>submit</button>
        </div>
      )}
    </div>
  );
}

export default BasicDetailForm;
