import React from "react";
import moment from "moment";
import Sidebar from "../Sidebar";
import Link from "@material-ui/core/Link";
import useEnquiry from "./useEnquiry.logic";
import idFormatter from "../../util/idFormatter";
import { makeStyles } from "@material-ui/core/styles";
import "./EnquiryDetail.css";

const EnquiryDetails = () => {
  const state = useEnquiry();
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));
  const classes = useStyles();
  return (
    <div className="d-flex">
      <Sidebar />
      <div className={classes.root}>
        <button className="width1" onClick={() => state.back()}>
          BACK
        </button>
        <hr />
        <div>
          <div className="row">
            <div class="col-md-4">
              <p className="text-start">{state.data.patientName}</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">
                  {`Patient ID : ${idFormatter(state.data.patientId)}`}
                </div>
              </p>
            </div>
            <div class="col-md-3">
              <p className="text-start uppercase">{state.data.type}</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">
                  {`Age : ${state.data.patientAge} Years`}
                </div>
              </p>
            </div>
            <div class="col-md-3 capitalize">
              <p
                className={
                  state.data.status === "replied" ||
                  state.data.status === "completed" ||
                  state.data.status === "transferred"
                    ? "text-2"
                    : "text-1"
                }
              >
                {state.data.status}
              </p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">
                  {`Enquiry ID : ${idFormatter(state.data.id)}`}
                </div>
              </p>
            </div>
            <div class="bottan col-md-2">
              <div className="row">
                <div className="col-12">
                  <button
                    className=" share-button"
                    onClick={() => state.Mailto("Enquiry Details", state.data)}
                  >
                    Share
                  </button>
                </div>
                {state.data.type === "appointment" ||
                state.data.type === "arrival" ? (
                  <>
                    {state.data.status !== "transferred" ? (
                      <div className="col-12">
                        <button
                          className=" share-button"
                          onClick={() => state.updateEnquiry()}
                        >
                          Transfer
                        </button>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="marginleft border-top">
            <div>
              <div className="row">
                <div className="col-3">
                  <p className="mb-0 mt-4 text-start">Center Name</p>
                  <div className="ed-txt-2 mb-4 text-start">
                    <div className="textColor">{state.data.centerName}</div>
                  </div>
                </div>

                {
                  (state.data.type !== "diagnostic",
                  "arrival" && (
                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Speciality</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.data.specialityName}
                        </div>
                      </div>
                    </div>
                  ))
                }
                {state.data.type !== "diagnostic" &&
                  state.data.type !== "arrival" && (
                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Doctor Name</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">{state.data.doctorName}</div>
                      </div>
                    </div>
                  )}
                {state.data.type == "vil" && (
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">
                      Preferred Date of Travel
                    </p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {moment(state.data.preferredDate).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div class="row">
                {state.data.type == "appointment" && (
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Preferred Date</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {moment(state.data.preferredDate).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </div>
                )}

                {state.data.type == "diagnostic" && (
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Preferred Date</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {moment(state.data.preferredDate).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </div>
                )}

                {state.data.type == "diagnostic" && (
                  <div className="col-3">
                    <p className="m-0 mt-4 text-start">Test Name</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">{state.data.testName}</div>
                    </div>
                  </div>
                )}

                {state.data.type !== "vil" && (
                  <div className="col-3">
                    <p className="m-0 mt-4 text-start">Preferred City</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.data.preferredCity}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row">
                {state.data.type == "arrival" && (
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Arrival Date</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {moment(state.data.arrivalDate).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </div>
                )}

                {state.data.type == "arrival" && (
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Flight Details</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.data.flightDetails}
                      </div>
                    </div>
                  </div>
                )}

                {state.data.type == "arrival" && (
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Number Of Passengers</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {state.data.numOfPassengers}
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  <p className="mb-0 mt-4 text-start">Comment</p>
                  <div className="ed-txt-2 mb-4 text-start">
                    <div className="textColor">{state.data.comment}</div>
                  </div>
                </div>
              </div>
              <div>
                <p className="mb-0 mt-4 text-start">Documents</p>
                {state.documents && state.documents.length > 0 && (
                  <button
                    className="download-button"
                    disabled={state.disableDownload}
                    onClick={() => state.downloadAllDocuments()}
                  >
                    Download All
                  </button>
                )}

                {state.disableDownload && <p>Downloading, please wait!</p>}
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {state.documents.map((doc, index) => (
                    <div className="row mb-2">
                      <div class="col-6"></div>
                      <div>
                        <b>
                          <Link
                            className="pointer"
                            onClick={() => state.downloadDocument(doc.fileName)}
                          >
                            {`Document ${index + 1}`}
                          </Link>
                        </b>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {!state.replied && (
                <div className="row border-top pt-4">
                  <div class="col-5">
                    <textarea
                      className="comments-section"
                      rows={5}
                      value={state.replyComments}
                      onChange={(e) => state.setReplyComments(e.target.value)}
                      placeholder="Reply Comments"
                    ></textarea>
                  </div>
                  <div className="col-3">
                    {state.replyDocuments.map((doc, index) => (
                      <div className="row">
                        <div class="col-6">
                          <div className="ed-txt-2 text-start"></div>
                        </div>
                        <div>
                          <b>
                            <Link
                              className="pointer"
                              onClick={() =>
                                state.downloadReplyDocument(doc.fileName)
                              }
                            >
                              {`Reply Document ${index + 1}`}
                            </Link>
                          </b>
                        </div>
                      </div>
                    ))}
                    <div className="text-start">
                      <div id="dynamicInput">
                        {state.inputs.map((input) => (
                          <input
                            className="m-2"
                            type="file"
                            id={input}
                            onChange={state.onFileChange}
                          />
                        ))}
                      </div>
                      <button className="m-2" onClick={state.appendInput}>
                        Add File
                      </button>
                      {<button onClick={state.removeInput}>Cancel</button>}
                    </div>
                  </div>
                  <div class="col-3">
                    <button
                      className="btn-upload"
                      onClick={() => state.replyEnquiry(state.data)}
                    >
                      Reply
                    </button>
                  </div>
                </div>
              )}
              {state.replied && (
                <div className="row border-top pt-4">
                  <div>
                    Reply Comments:
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">{state.replyComments}</div>
                    </div>
                  </div>

                  <p className="mb-0 mt-4 text-start">Reply Documents</p>
                  <div style={{ display: "flex" }}>
                    {state.replyDocuments.map((doc, index) => (
                      <div className="row">
                        <div class="col-6">
                          <div className="ed-txt-2 text-start"></div>
                        </div>
                        <div>
                          <b>
                            <Link
                              className="pointer"
                              onClick={() =>
                                state.downloadReplyDocument(doc.fileName)
                              }
                            >
                              {`Reply Document ${index + 1}`}
                            </Link>
                          </b>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div class="col-4">
                    <button
                      className="btn-upload"
                      onClick={() => state.updateReply(state.data)}
                    >
                      Update
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EnquiryDetails;
