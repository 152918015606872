import React from "react";
import useInsurance from "./useInsurance.logic";
import Link from "@material-ui/core/Link";
import Dropdown from "react-bootstrap/Dropdown";
import downloadDocument from "../../../util/documentUtil";
import { checkPermission } from "../../../util/permissionUtils";

import DatePicker from "react-datepicker";
import moment from "moment";

function InsuranceDetail({
  userTrxnId,
  insuranceDetailId,
  callBackFn,
  handleHide,
}) {
  const state = useInsurance({
    userTrxnId,
    insuranceDetailId,
    callBackFn,
    handleHide,
  });

  return (
    <div>
      <div>
        <div>
          <div className="row">
            {checkPermission("INSURANCE_SUB_TAB") && (
              <div className="col-lg-12">
                <div style={{ display: "flex" }}>
                  <p className="mb-0 mt-3 text-start">Insurance Card: </p>
                  {state.savedInsuranceDocs &&
                    state.savedInsuranceDocs.map((doc, index) => (
                      <div className="row">
                        <div class="col-6">
                          <div className="ed-txt-2 text-start"></div>
                        </div>
                        <div>
                          <b>
                            <Link
                              style={{ marginLeft: 10 }}
                              className="pointer"
                              onClick={() =>
                                downloadDocument(
                                  doc.documentType,
                                  doc.document,
                                  doc.documentName
                                )
                              }
                            >
                              {`Insurance Document ${index + 1}`}
                            </Link>
                          </b>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {checkPermission("INSURANCE_AGREEMENT_UPLOAD") && (
        <div className="row">
          <div className="col-10" style={{ marginBottom: 20 }}>
            <div>
              <label
                for="insuranceFiles"
                class="btn"
                style={{
                  width: 160,
                  backgroundColor: "#06c6a2",
                  color: "white",
                }}
              >
                Upload Insurance
              </label>
              <input
                id="insuranceFiles"
                style={{
                  width: 100,
                  cursor: "pointer ",
                  display: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 3,
                  paddingTop: 3,
                }}
                type="file"
                ref={state.inputInsuranceFile}
                onChange={state.handleInsuranceFile.bind(this)}
              />

              {state.insuranceFiles.map((item, index) => {
                return (
                  <>
                    <span
                      style={{
                        border: "1px solid gray",
                        marginLeft: 10,
                        cursor: "pointer ",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingBottom: 10,
                        paddingTop: 5,
                        backgroundColor: "#e5e5e5",
                      }}
                      onClick={() => state.removeItem(item.id)}
                    >
                      {item.name}
                    </span>
                    <span
                      style={{
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingBottom: 10,
                        paddingTop: 5,
                        backgroundColor: "#06c6a2",
                        border: "1px solid gray",
                        color: "white",
                        cursor: "pointer ",
                      }}
                      onClick={() => state.removeItem(item.id)}
                    >
                      X
                    </span>
                  </>
                );
              })}
            </div>
          </div>
          <div className="col-2">
            <div>
              <button
                style={{ width: 70, padding: 8 }}
                onClick={() => state.addInsuranceDocs()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {checkPermission("INSURANCE_DETAILS_UPDATE") && insuranceDetailId && (
        <div>
          <hr />
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Policy Number
                </p>
                {state.editableMode ? (
                  <input
                    placeholder="Policy Number"
                    value={state.policyNumber}
                    style={{
                      width: 200,
                      height: 40,
                      borderRadius: 5,
                      paddingLeft: 10,
                      border: `1px solid gray`,
                    }}
                    onChange={(e) => state.setPolicyNumber(e.target.value)}
                  />
                ) : (
                  <span>{state.policyNumber}</span>
                )}
              </div>
              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Balance Sum Insured
                </p>
                {state.editableMode ? (
                  <input
                    placeholder="Balance Sum Insured"
                    value={state.balanceSumInsured}
                    style={{
                      width: 200,
                      height: 40,
                      borderRadius: 5,
                      paddingLeft: 10,
                      border: `1px solid gray`,
                    }}
                    onChange={(e) => state.setBalanceSumInsured(e.target.value)}
                  />
                ) : (
                  <span>{state.balanceSumInsured}</span>
                )}
              </div>
            </div>

            <div className="col-lg-2  col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Insurance Company
                </p>
                {state.editableMode ? (
                  <input
                    placeholder="Insurance Company"
                    value={state.insuranceCompany}
                    style={{
                      width: 200,
                      height: 40,
                      borderRadius: 5,
                      paddingLeft: 10,
                      border: `1px solid gray`,
                    }}
                    onChange={(e) => state.setInsuranceCompany(e.target.value)}
                  />
                ) : (
                  <span>{state.insuranceCompany}</span>
                )}
              </div>
              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Room Rent
                </p>
                {state.editableMode ? (
                  <input
                    placeholder="Room Rent"
                    value={state.roomRent}
                    style={{
                      width: 200,
                      height: 40,
                      borderRadius: 5,
                      paddingLeft: 10,
                      border: `1px solid gray`,
                    }}
                    onChange={(e) => state.setRoomRent(e.target.value)}
                  />
                ) : (
                  <span>{state.roomRent}</span>
                )}
              </div>
            </div>

            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  TPA
                </p>
                {state.editableMode ? (
                  <input
                    placeholder="TPA"
                    value={state.tpa}
                    style={{
                      width: 200,
                      height: 40,
                      borderRadius: 5,
                      paddingLeft: 10,
                      border: `1px solid gray`,
                    }}
                    onChange={(e) => state.setTpa(e.target.value)}
                  />
                ) : (
                  <span>{state.tpa}</span>
                )}
              </div>
              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Room Type
                </p>
                {state.editableMode ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 200,
                        border: `1px solid gray`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "0px",
                      }}
                    >
                      {state.selectedRoomType
                        ? state.selectedRoomType
                        : "Single"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {state.roomTypes.map((value, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 200 }}
                            onClick={() => state.setSelectedRoomType(value)}
                          >
                            {value}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <span>{state.selectedRoomType}</span>
                )}
              </div>
            </div>

            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Sum Insured
                </p>
                {state.editableMode ? (
                  <input
                    placeholder="Sum Insured"
                    value={state.sumInsured}
                    style={{
                      width: 250,
                      height: 40,
                      borderRadius: 5,
                      paddingLeft: 10,
                      border: `1px solid gray`,
                    }}
                    onChange={(e) => state.setSumInsured(e.target.value)}
                  />
                ) : (
                  <span>{state.sumInsured}</span>
                )}
              </div>

              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Insurance Status
                </p>
                {state.editableMode ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 250,
                        border: `1px solid gray`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "0px",
                      }}
                    >
                      {state.insuranceStatus
                        ? state.insuranceStatus?.name
                        : "Choose"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {state?.statuses.length &&
                        state?.statuses?.map((value, index) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              href="#"
                              style={{ width: 250 }}
                              onClick={() => state.handleInsuranceStatus(value)}
                            >
                              {value.name}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <span>
                    {state.insuranceStatus?.name
                      ? state.insuranceStatus?.name
                      : "nill"}
                  </span>
                )}
              </div>
            </div>

            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Initial Approval Amount
                </p>
                {state.editableMode ? (
                  <input
                    placeholder="Initial Approval Amount"
                    value={state.initialApprovalAmount}
                    style={{
                      width: 200,
                      height: 40,
                      borderRadius: 5,
                      paddingLeft: 10,
                      border: `1px solid gray`,
                    }}
                    onChange={(e) =>
                      state.setInitialApprovalAmount(e.target.value)
                    }
                  />
                ) : (
                  <span>{state.initialApprovalAmount}</span>
                )}
              </div>

              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Final Approval Amount
                </p>
                {state.editableMode ? (
                  <input
                    placeholder="Final Approval Amount"
                    value={state.finalApprovalAmount}
                    style={{
                      width: 200,
                      height: 40,
                      borderRadius: 5,
                      paddingLeft: 10,
                      border: `1px solid gray`,
                    }}
                    onChange={(e) =>
                      state.setFinalApprovalAmount(e.target.value)
                    }
                  />
                ) : (
                  <span>{state.finalApprovalAmount}</span>
                )}
              </div>
            </div>

            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Insurance Type
                </p>
                {state.editableMode ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 200,
                        border: `1px solid gray`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "0px",
                      }}
                    >
                      {state.insuranceType
                        ? state.insuranceType
                        : "Select Type"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {state.insuranceTypes.map((value, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 200 }}
                            onClick={() => state.setInsuranceType(value)}
                          >
                            {value}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <span>{state.insuranceType}</span>
                )}
              </div>

              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Mode of Payment
                </p>
                {state.editableMode ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 200,
                        border: `1px solid gray`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "0px",
                      }}
                    >
                      {state.paymentMode ? state.paymentMode : "Payment Mode"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {state.paymentModes.map((value, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 200 }}
                            onClick={() => state.setPaymentMode(value)}
                          >
                            {value}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <span>{state.paymentMode}</span>
                )}
              </div>
            </div>

            {state.insuranceStatus?.id === 4 && (
              <div
                onClick={() =>
                  state.getInsuranceRejectedReasons(state.insuranceStatus?.id)
                }
                style={{ marginTop: 10 }}
              >
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 500,
                    marginBottom: 0,
                  }}
                >
                  <b>Rejected Reason</b>
                </p>
                {state.editableMode ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 270,
                        border: `1px solid gray`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "0px",
                      }}
                    >
                      {state.selectedReasons
                        ? state.selectedReasons?.name
                        : "Select Reasons"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {state.reasons.map((value, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 270 }}
                            onClick={() => state.setSelectedReasons(value)}
                          >
                            {value.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <span>{state.selectedReasons?.name}</span>
                )}
              </div>
            )}
            {state.insuranceStatus?.id === 4 && state.editableMode && (
              <div className="row">
                <div className="col-10" style={{ marginBottom: 20 }}>
                  <div>
                    <br></br>
                    <label
                      for="insuranceRejectedFiles"
                      class="btn"
                      style={{
                        width: 260,
                        backgroundColor: "#06c6a2",
                        color: "white",
                      }}
                    >
                      Upload Rejected Insurance Doc
                    </label>
                    <input
                      id="insuranceRejectedFiles"
                      style={{
                        width: 100,
                        cursor: "pointer ",
                        display: "none",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingBottom: 3,
                        paddingTop: 3,
                      }}
                      type="file"
                      ref={state.inputInsuranceRejectedFile}
                      onChange={state.handleInsuranceRejectedFile.bind(this)}
                    />

                    {state.insuranceRejectedFiles.map((item, index) => {
                      return (
                        <>
                          <span
                            style={{
                              border: "1px solid gray",
                              marginLeft: 10,
                              cursor: "pointer ",
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#e5e5e5",
                            }}
                            onClick={() => state.removeRejectedItem(item.id)}
                          >
                            {item.name}
                          </span>
                          <span
                            style={{
                              paddingLeft: 12,
                              paddingRight: 12,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#06c6a2",
                              border: "1px solid gray",
                              color: "white",
                              cursor: "pointer ",
                            }}
                            onClick={() => state.removeRejectedItem(item.id)}
                          >
                            X
                          </span>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            <div style={{}}>
              {state.savedInsuranceApprovedDocs.length !== 0 &&
                [2].includes(state.insuranceStatus?.id) && (
                  <>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        marginBottom: 0,
                      }}
                    >
                      Approved Initial Documents
                    </p>
                    {state.savedInsuranceApprovedDocs.map((doc, index) => (
                      <div className="row">
                        <div class="col-6">
                          <div className="ed-txt-2 text-start"></div>
                        </div>
                        <div>
                          <b>
                            <Link
                              to="#"
                              style={{ marginLeft: 10 }}
                              className="pointer"
                              onClick={() =>
                                downloadDocument(
                                  doc.documentType,
                                  doc.document,
                                  doc.documentName
                                )
                              }
                            >
                              {`Approved Initial Document ${index + 1}`}
                            </Link>
                          </b>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              {state.savedInsuranceApprovedDocs.length !== 0 &&
                [6].includes(state.insuranceStatus?.id) && (
                  <>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        marginBottom: 0,
                      }}
                    >
                      Approved Final Documents
                    </p>
                    {state.savedInsuranceApprovedDocs.map((doc, index) => (
                      <div className="row">
                        <div class="col-6">
                          <div className="ed-txt-2 text-start"></div>
                        </div>
                        <div>
                          <b>
                            <Link
                              to="#"
                              style={{ marginLeft: 10 }}
                              className="pointer"
                              onClick={() =>
                                downloadDocument(
                                  doc.documentType,
                                  doc.document,
                                  doc.documentName
                                )
                              }
                            >
                              {`Approved Final Document ${index + 1}`}
                            </Link>
                          </b>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              {state.savedInsurancePreAuthDocs.length !== 0 &&
                [3].includes(state.insuranceStatus?.id) && (
                  <>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        marginBottom: 0,
                      }}
                    >
                      Pre Auth Documents
                    </p>
                    {state.savedInsurancePreAuthDocs.map((doc, index) => (
                      <div className="row">
                        <div class="col-6">
                          <div className="ed-txt-2 text-start"></div>
                        </div>
                        <div>
                          <b>
                            <Link
                              to="#"
                              style={{ marginLeft: 10 }}
                              className="pointer"
                              onClick={() =>
                                downloadDocument(
                                  doc.documentType,
                                  doc.document,
                                  doc.documentName
                                )
                              }
                            >
                              {`Pre Auth Document ${index + 1}`}
                            </Link>
                          </b>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              {state.savedInsuranceQueryRaisedDocs.length !== 0 &&
                [5].includes(state.insuranceStatus?.id) && (
                  <>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        marginBottom: 0,
                      }}
                    >
                      Query Raised Documents
                    </p>
                    {state.savedInsuranceQueryRaisedDocs.map((doc, index) => (
                      <div className="row">
                        <div class="col-6">
                          <div className="ed-txt-2 text-start"></div>
                        </div>
                        <div>
                          <b>
                            <Link
                              to="#"
                              style={{ marginLeft: 10 }}
                              className="pointer"
                              onClick={() =>
                                downloadDocument(
                                  doc.documentType,
                                  doc.document,
                                  doc.documentName
                                )
                              }
                            >
                              {`Query Raised Document ${index + 1}`}
                            </Link>
                          </b>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              {state.savedInsuranceRejectedDocs.length !== 0 &&
                state.insuranceStatus?.id === 4 && (
                  <>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        marginBottom: 0,
                      }}
                    >
                      Rejected Documents
                    </p>
                    {state.savedInsuranceRejectedDocs.map((doc, index) => (
                      <div className="row">
                        <div class="col-6">
                          <div className="ed-txt-2 text-start"></div>
                        </div>
                        <div>
                          <b>
                            <Link
                              to="#"
                              className="pointer"
                              onClick={() =>
                                downloadDocument(
                                  doc.documentType,
                                  doc.document,
                                  doc.documentName
                                )
                              }
                            >
                              {`Rejected Document ${index + 1}`}
                            </Link>
                          </b>
                        </div>
                      </div>
                    ))}
                  </>
                )}
            </div>

            {/* {[2, 6].includes(state.insuranceStatus?.id) && (
              <div className="row">
                <div className="col-10" style={{ marginBottom: 20 }}>
                  <div>
                    <br></br>
                    <label
                      for="insuranceApprovedFiles"
                      class="btn"
                      style={{
                        width: 260,
                        backgroundColor: "#06c6a2",
                        color: "white",
                      }}
                    >
                      Upload Approved Insurance Doc
                    </label>
                    <input
                      id="insuranceApprovedFiles"
                      style={{
                        width: 100,
                        cursor: "pointer ",
                        display: "none",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingBottom: 3,
                        paddingTop: 3,
                      }}
                      type="file"
                      ref={state.inputInsuranceApprovedFile}
                      onChange={state.handleInsuranceApprovedFile.bind(this)}
                    />

                    {state.insuranceApprovedFiles.map((item, index) => {
                      return (
                        <>
                          <span
                            style={{
                              border: "1px solid gray",
                              marginLeft: 10,
                              cursor: "pointer ",
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#e5e5e5",
                            }}
                            onClick={() => state.removeApprovedItem(item.id)}
                          >
                            {item.name}
                          </span>
                          <span
                            style={{
                              paddingLeft: 12,
                              paddingRight: 12,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#06c6a2",
                              border: "1px solid gray",
                              color: "white",
                              cursor: "pointer ",
                            }}
                            onClick={() => state.removeApprovedItem(item.id)}
                          >
                            X
                          </span>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )} */}

            {[2].includes(state.insuranceStatus?.id) && state.editableMode && (
              <div className="row">
                <div className="col-10" style={{ marginBottom: 20 }}>
                  <div>
                    <br></br>
                    <label
                      for="insuranceInitialApprovedFiles"
                      class="btn"
                      style={{
                        width: 260,
                        backgroundColor: "#06c6a2",
                        color: "white",
                      }}
                    >
                      Upload Initial Approved Doc
                    </label>
                    <input
                      id="insuranceInitialApprovedFiles"
                      style={{
                        width: 100,
                        cursor: "pointer ",
                        display: "none",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingBottom: 3,
                        paddingTop: 3,
                      }}
                      type="file"
                      ref={state.inputInsuranceApprovedFile}
                      onChange={state.handleInsuranceInitialFile.bind(this)}
                    />

                    {state.initialApprovalFile.map((item, index) => {
                      return (
                        <>
                          <span
                            style={{
                              border: "1px solid gray",
                              marginLeft: 10,
                              cursor: "pointer ",
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#e5e5e5",
                            }}
                            onClick={() => state.removeInitialFileItem(item.id)}
                          >
                            {item.name}
                          </span>
                          <span
                            style={{
                              paddingLeft: 12,
                              paddingRight: 12,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#06c6a2",
                              border: "1px solid gray",
                              color: "white",
                              cursor: "pointer ",
                            }}
                            onClick={() => state.removeInitialFileItem(item.id)}
                          >
                            X
                          </span>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {[6].includes(state.insuranceStatus?.id) && state.editableMode && (
              <div className="row">
                <div className="col-10" style={{ marginBottom: 20 }}>
                  <div>
                    <br></br>
                    <label
                      for="insuranceFinalApprovedFiles"
                      class="btn"
                      style={{
                        width: 260,
                        backgroundColor: "#06c6a2",
                        color: "white",
                      }}
                    >
                      Upload Final Approved Doc
                    </label>
                    <input
                      id="insuranceFinalApprovedFiles"
                      style={{
                        width: 100,
                        cursor: "pointer ",
                        display: "none",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingBottom: 3,
                        paddingTop: 3,
                      }}
                      type="file"
                      ref={state.inputInsuranceApprovedFile}
                      onChange={state.handleInsuranceApprovedFile.bind(this)}
                    />

                    {state.insuranceApprovedFiles.map((item, index) => {
                      return (
                        <>
                          <span
                            style={{
                              border: "1px solid gray",
                              marginLeft: 10,
                              cursor: "pointer ",
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#e5e5e5",
                            }}
                            onClick={() => state.removeApprovedItem(item.id)}
                          >
                            {item.name}
                          </span>
                          <span
                            style={{
                              paddingLeft: 12,
                              paddingRight: 12,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#06c6a2",
                              border: "1px solid gray",
                              color: "white",
                              cursor: "pointer ",
                            }}
                            onClick={() => state.removeApprovedItem(item.id)}
                          >
                            X
                          </span>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {[5].includes(state.insuranceStatus?.id) && state.editableMode && (
              <div className="row">
                <div className="col-10" style={{ marginBottom: 20 }}>
                  <div>
                    <br></br>
                    <label
                      for="insuranceEnquiryRaisedFiles"
                      class="btn"
                      style={{
                        width: 260,
                        backgroundColor: "#06c6a2",
                        color: "white",
                      }}
                    >
                      Upload Enquiry Raised Doc
                    </label>
                    <input
                      id="insuranceEnquiryRaisedFiles"
                      style={{
                        width: 100,
                        cursor: "pointer ",
                        display: "none",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingBottom: 3,
                        paddingTop: 3,
                      }}
                      type="file"
                      ref={state.inputInsuranceApprovedFile}
                      onChange={state.handleInsuranceQueryRaisedFile.bind(this)}
                    />

                    {state.queryRaisedFile.map((item, index) => {
                      return (
                        <>
                          <span
                            style={{
                              border: "1px solid gray",
                              marginLeft: 10,
                              cursor: "pointer ",
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#e5e5e5",
                            }}
                            onClick={() =>
                              state.removeQueryRaisedFileItem(item.id)
                            }
                          >
                            {item.name}
                          </span>
                          <span
                            style={{
                              paddingLeft: 12,
                              paddingRight: 12,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#06c6a2",
                              border: "1px solid gray",
                              color: "white",
                              cursor: "pointer ",
                            }}
                            onClick={() =>
                              state.removeQueryRaisedFileItem(item.id)
                            }
                          >
                            X
                          </span>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {[3].includes(state.insuranceStatus?.id) && state.editableMode && (
              <div className="row">
                <div className="col-10" style={{ marginBottom: 20 }}>
                  <div>
                    <br></br>
                    <label
                      for="insurancePreAuthFiles"
                      class="btn"
                      style={{
                        width: 260,
                        backgroundColor: "#06c6a2",
                        color: "white",
                      }}
                    >
                      Upload Pre Auth Doc
                    </label>
                    <input
                      id="insurancePreAuthFiles"
                      style={{
                        width: 100,
                        cursor: "pointer ",
                        display: "none",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingBottom: 3,
                        paddingTop: 3,
                      }}
                      type="file"
                      ref={state.inputInsuranceApprovedFile}
                      onChange={state.handlePreAuthFile.bind(this)}
                    />

                    {state.preAuthFile.map((item, index) => {
                      return (
                        <>
                          <span
                            style={{
                              border: "1px solid gray",
                              marginLeft: 10,
                              cursor: "pointer ",
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#e5e5e5",
                            }}
                            onClick={() => state.removePreAuthFileItem(item.id)}
                          >
                            {item.name}
                          </span>
                          <span
                            style={{
                              paddingLeft: 12,
                              paddingRight: 12,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#06c6a2",
                              border: "1px solid gray",
                              color: "white",
                              cursor: "pointer ",
                            }}
                            onClick={() => state.removePreAuthFileItem(item.id)}
                          >
                            X
                          </span>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            <div style={{ marginTop: "20px" }}>
              <b style={{ textAlign: "left", paddingRight: "10px" }}>Co-Pay</b>

              <input
                type="checkbox"
                checked={state.copayChecked}
                onChange={(e) => {
                  state.setCopayChecked(!state.copayChecked);
                }}
              />

              {state.copayChecked && (
                <div style={{ display: "flex" }}>
                  <div className="col-2">
                    <b>Collected Date</b>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 160,
                        height: 40,
                        borderRadius: 5,
                        marginBottom: 7,
                      }}
                    >
                      {state.editableMode ? (
                        <DatePicker
                          className="myDatePicker"
                          selected={
                            state.selectedDate ? state.selectedDate : new Date()
                          }
                          onChange={(date) => {
                            state.setSelectedDate(date);
                          }}
                          minDate={new Date()}
                        />
                      ) : (
                        <span>
                          {moment(state.selectedDate).format("DD-MM-YYYY")}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-2">
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        marginBottom: 0,
                      }}
                    >
                      Co-pay Amount
                    </p>

                    {state.editableMode ? (
                      <input
                        placeholder="Co-pay Amount"
                        value={state.copayAmount}
                        style={{
                          width: 200,
                          height: 40,
                          borderRadius: 5,
                          paddingLeft: 10,
                          border: `1px solid gray`,
                        }}
                        onChange={(e) => state.setCopayAmount(e.target.value)}
                      />
                    ) : (
                      <span>{state.copayAmount}</span>
                    )}
                  </div>

                  <div className="col-2">
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        marginBottom: 0,
                        width: 200,
                      }}
                    >
                      Select Center
                    </p>
                    {state.editableMode ? (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          style={{
                            width: 200,
                            height: 36,
                            border: `1px solid gray`,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {state.selectedCenter
                            ? state.selectedCenter.name
                            : "Center"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            maxHeight: 100,
                            overflowY: "scroll",
                            overflowX: "hidden",
                            width: 200,
                          }}
                        >
                          {state.centerData.map((value, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                href="#"
                                style={{ width: 200, maxHeight: 200 }}
                                onClick={() => state.setSelectedCenter(value)}
                              >
                                {value.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <span>{state.selectedCenter?.name}</span>
                    )}
                  </div>

                  <div className="col-2">
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: 700,
                        marginBottom: 0,
                      }}
                    >
                      Upload Payment Bill
                    </p>
                    <div>
                      <label
                        for="insurancePaymentFiles"
                        class="btn"
                        style={{
                          width: 200,
                          backgroundColor: "#06c6a2",
                          color: "white",
                        }}
                      >
                        Upload
                      </label>
                      <input
                        id="insurancePaymentFiles"
                        style={{
                          cursor: "pointer ",
                          display: "none",
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingBottom: 3,
                          paddingTop: 3,
                        }}
                        type="file"
                        ref={state.inputPaymentBill}
                        onChange={state.handleCopayPaymentBill.bind(this)}
                      />

                      {state.copayPaymentBillFiles.map((item, index) => {
                        return (
                          <>
                            <span
                              style={{
                                border: "1px solid gray",
                                marginLeft: 10,
                                cursor: "pointer ",
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingBottom: 10,
                                paddingTop: 5,
                                backgroundColor: "#e5e5e5",
                              }}
                              onClick={() => state.removeCopayItem(item.id)}
                            >
                              {item.name}
                            </span>
                            <span
                              style={{
                                paddingLeft: 12,
                                paddingRight: 12,
                                paddingBottom: 10,
                                paddingTop: 5,
                                backgroundColor: "#06c6a2",
                                border: "1px solid gray",
                                color: "white",
                                cursor: "pointer ",
                              }}
                              onClick={() => state.removeCopayItem(item.id)}
                            >
                              X
                            </span>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {state.copayChecked &&
              state.savedCopayPaymentBillDocs.length !== 0 && (
                <>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                      marginTop: "20px",
                    }}
                  >
                    Copay Payment Bill
                  </p>
                  {state.savedCopayPaymentBillDocs.map((doc, index) => {
                    return (
                      <div className="row">
                        <div class="col-6">
                          <div className="ed-txt-2 text-start"></div>
                        </div>
                        <div>
                          <b>
                            <Link
                              to="#"
                              className="pointer"
                              onClick={() =>
                                downloadDocument(
                                  doc.documentType,
                                  doc.document,
                                  doc.documentName
                                )
                              }
                            >
                              {`Copay Payment Bill ${index + 1}`}
                            </Link>
                          </b>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

            <>
              {state.editableMode ? (
                <div class="col-6">
                  <textarea
                    className="comments-section"
                    rows={5}
                    value={state.comments}
                    onChange={(e) => state.setComments(e.target.value)}
                    placeholder="Remarks"
                  ></textarea>
                </div>
              ) : (
                <div>
                  Remarks: <span>{state.comments}</span>
                </div>
              )}
            </>

            <div style={{ marginTop: 20 }}>
              <div>
                <div className="row pt-4">
                  <br />
                  <br />

                  <div class="col-6">
                    <div className="col-lg-2 col-6">
                      <div>
                        <div>
                          {state.editableMode ? (
                            <button
                              style={{ width: 70, padding: 8 }}
                              onClick={() => {
                                state.saveInsuranceDetails();
                              }}
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              style={{ width: 70, padding: 8 }}
                              onClick={() => state.setEditableMode(true)}
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {checkPermission("INSURANCE_POLICY_NO_FIELD")  && insuranceDetailId &&

        <div>
          <hr />
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
                >
                  Policy Number
                </p>
                <span>{state.policyNumber}</span>
              </div>
              <div style={{ marginTop: 10 }}>
                <p
                  style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
                >
                  Balance Sum Insured
                </p>
                <span>{state.balanceSumInsured}</span>
              </div>
            </div>
            <div className="col-lg-2  col-6">
              <div>
                <p
                  style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
                >
                  Insurance Company
                </p>
                <span>{state.insuranceCompany}</span>
              </div>
              <div style={{ marginTop: 10 }}>
                <p
                  style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
                >
                  Room Rent
                </p>
                <span>{state.roomRent}</span>
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
                >
                  TPA
                </p>
                <span>{state.tpa}</span>
              </div>
              <div style={{ marginTop: 10 }}>
                <p
                  style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
                >
                  Room Type
                </p>
                <span>{state.selectedRoomType}</span>
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
                >
                  Sum Insured
                </p>
                <span>{state.sumInsured}</span>
              </div>

              <div style={{ marginTop: 10 }}>
                <p
                  style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }}
                >
                  Insurance Status
                </p>
                <span>{state.insuranceStatus}</span>
              </div>
            </div>

            <div style={{}}>
              {state.savedInsuranceApprovedDocs &&
                state.savedInsuranceApprovedDocs.map((doc, index) => (
                  <div className="row">
                    <div class="col-6">
                      <div className="ed-txt-2 text-start"></div>
                    </div>
                    <div>
                      <b>
                        <Link to='#'
                          style={{ marginLeft: 10 }}
                          className="pointer"
                          onClick={() =>
                            downloadDocument(
                              doc.documentType,
                              doc.document,
                              doc.documentName
                            )
                          }
                        >
                          {`Agreement Document ${index + 1}`}
                        </Link>
                      </b>
                    </div>
                  </div>
                ))}
              {state.savedInsuranceRejectedDocs &&
                state.savedInsuranceRejectedDocs.map((doc, index) => (
                  <div className="row">
                    <div class="col-6">
                      <div className="ed-txt-2 text-start"></div>
                    </div>
                    <div>
                      <b>
                        <Link to='#'
                          style={{ marginLeft: 10 }}
                          className="pointer"
                          onClick={() =>
                            downloadDocument(
                              doc.documentType,
                              doc.document,
                              doc.documentName
                            )
                          }
                        >
                          {`Agreement Document ${index + 1}`}
                        </Link>
                      </b>
                    </div>
                  </div>
                ))}
            </div>

            <div style={{ marginTop: 20 }}>
              <div>
                <div className="row pt-4">
                  <div>Remarks: <span>{state.comments}</span></div>
                  <div class="col-6">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>} */}

      {checkPermission("INSURANCE_DETAILS_VIEW") && insuranceDetailId && (
        <>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Policy Number
                </p>
                {<span>{state.policyNumber}</span>}
              </div>
              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Balance Sum Insured
                </p>
                {<span>{state.balanceSumInsured}</span>}
              </div>
            </div>

            <div className="col-lg-2  col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Insurance Company
                </p>
                {<span>{state.insuranceCompany}</span>}
              </div>
              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Room Rent
                </p>
                {<span>{state.roomRent}</span>}
              </div>
            </div>

            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  TPA
                </p>
                {<span>{state.tpa}</span>}
              </div>
              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Room Type
                </p>
                {<span>{state.selectedRoomType}</span>}
              </div>
            </div>

            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Sum Insured
                </p>
                {<span>{state.sumInsured}</span>}
              </div>

              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Insurance Status
                </p>
                {
                  <span>
                    {state.insuranceStatus
                      ? state.insuranceStatus?.name
                      : "Nill"}
                  </span>
                }
              </div>
            </div>

            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Initial Approval Amount
                </p>
                {<span>{state.initialApprovalAmount}</span>}
              </div>

              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Final Approval Amount
                </p>
                {<span>{state.finalApprovalAmount}</span>}
              </div>
            </div>

            <div className="col-lg-2 col-6">
              <div>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Insurance Type
                </p>
                {<span>{state.insuranceType}</span>}
              </div>

              <div style={{ marginTop: 10 }}>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Mode of Payment
                </p>
                {<span>{state.paymentMode}</span>}
              </div>
            </div>

            {state.insuranceStatus?.id === 4 && (
              <div className="col-lg-2 col-6">
                <div>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Rejected Reason
                  </p>
                  {<span>{state.selectedReasons.name}</span>}
                </div>
              </div>
            )}
          </div>

          <div style={{}}>
            {state.savedInsuranceApprovedDocs.length !== 0 && (
              // [2, 6].includes(state.insuranceStatus?.id) && (
              <>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Approved Documents
                </p>
                {state.savedInsuranceApprovedDocs.map((doc, index) => (
                  <div className="row">
                    <div class="col-6">
                      <div className="ed-txt-2 text-start"></div>
                    </div>
                    <div>
                      <b>
                        <Link
                          to="#"
                          style={{ marginLeft: 10 }}
                          className="pointer"
                          onClick={() =>
                            downloadDocument(
                              doc.documentType,
                              doc.document,
                              doc.documentName
                            )
                          }
                        >
                          {`Approved Document ${index + 1}`}
                        </Link>
                      </b>
                    </div>
                  </div>
                ))}
              </>
            )}
            {state.savedInsuranceRejectedDocs.length !== 0 && (
              // state.insuranceStatus === "REJECTED" && (
              <>
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    marginBottom: 0,
                  }}
                >
                  Rejected Documents
                </p>
                {state.savedInsuranceRejectedDocs.map((doc, index) => (
                  <div className="row">
                    <div class="col-6">
                      <div className="ed-txt-2 text-start"></div>
                    </div>
                    <div>
                      <b>
                        <Link
                          to="#"
                          className="pointer"
                          onClick={() =>
                            downloadDocument(
                              doc.documentType,
                              doc.document,
                              doc.documentName
                            )
                          }
                        >
                          {`Rejected Document ${index + 1}`}
                        </Link>
                      </b>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <hr />
          <div style={{ marginTop: "20px" }}>
            <h5 style={{ textAlign: "left", fontWeight: 700 }}>Co-Pay</h5>

            {state.copayChecked && (
              <div style={{ display: "flex" }}>
                <div className="col-2">
                  <b>Collected Date</b>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #00997c",
                      width: 160,
                      height: 40,
                      borderRadius: 5,
                      marginBottom: 7,
                    }}
                  >
                    <span>
                      {moment(state.selectedDate).format("DD-MM-YYYY")}
                    </span>
                  </div>
                </div>

                <div className="col-2">
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Co-pay Amount
                  </p>
                  <span>{state.copayAmount}</span>
                </div>

                <div className="col-2">
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                      width: 200,
                    }}
                  >
                    Select Center
                  </p>
                  <span>{state.selectedCenter?.name}</span>
                </div>
              </div>
            )}
          </div>

          <>
            {state.copayChecked &&
              state.savedCopayPaymentBillDocs.length !== 0 && (
                <>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Copay Payment Bill
                  </p>
                  {state.savedCopayPaymentBillDocs.map((doc, index) => {
                    return (
                      <div className="row">
                        <div class="col-6">
                          <div className="ed-txt-2 text-start"></div>
                        </div>
                        <div>
                          <b>
                            <Link
                              to="#"
                              className="pointer"
                              onClick={() =>
                                downloadDocument(
                                  doc.documentType,
                                  doc.document,
                                  doc.documentName
                                )
                              }
                            >
                              {`Copay Payment Bill ${index + 1}`}
                            </Link>
                          </b>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
          </>
        </>
      )}
    </div>
  );
}

export default InsuranceDetail;
