import React, { useState } from "react";
import "../../Enquiry/Enquiry.css";
import "../../../Styles/Common.css";
import "../../../Styles/sideBar.css";
import Sidebar from "../../Sidebar";
import "../../../Styles/Pagination.css";
import List from "@material-ui/core/List";
import ReactPaginate from "react-paginate";
import usePatients from "./usePatient.logic";
import CustomLoader from "../../../components/CustomLoader";
import PartnerTopBar from "../../../components/Partner_TopBar";
import AddPatient from "../../Home/AddLead/AddPatient";

function Patients() {
  const state = usePatients();
  const [showAddLead, setShowAddLead] = useState(false);
  const border = {
    width: "100%",
    height: "100vh",
    overflow: "scroll",
  };

  if (!state.dataLoaded) {
    return <CustomLoader />;
  }

  return (
    <div style={border}>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ width: "150%", padding: 10 }}>
          <div className="sticky">
            <div className="main-subContainer">
              <PartnerTopBar />
              <div className="row border1">
                <div className="col-3 cor1">
                  <b>
                    <p className="size1">{state.totalCount}</p>
                  </b>
                  <b>
                    <p className="colour">Total Query</p>
                  </b>
                </div>
                <div className="col-3 cor2">
                  <b>
                    <p className="size1">{state.pendingCount}</p>
                  </b>
                  <b>
                    <p className="colour">Pendings</p>
                  </b>
                </div>
                <div className="col-3 cor3">
                  <b>
                    <p className="size1">{state.arrivalCount}</p>
                  </b>
                  <b>
                    <p className="colour">Arrivals</p>
                  </b>
                </div>
                <div className="col-3 cor4">
                  <b>
                    <p className="size1">{state.appointmentCount}</p>
                  </b>
                  <b>
                    <p className="colour">Appointments</p>
                  </b>
                </div>
              </div>
            </div>

            <br />
            <br />
            <div
              className="row scroll"
              style={{
                minHeight: "3rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "90%" }}>
                <button
                  style={{ width: 120, height: 50 }}
                  onClick={() => {
                    setShowAddLead(!showAddLead);
                  }}
                >
                  Add Patient
                </button>
              </div>
              {showAddLead ? (
                <AddPatient
                  onClicked={(openPopUp) => setShowAddLead(openPopUp)}
                />
              ) : null}
              <hr></hr>

              <div className="container col-1 m-0" style={{ width: 70 }}>
                <b>SL.NO</b>
              </div>
              <div className="container col-1 m-0">
                <b>Id No.</b>
              </div>
              <div className="container col-1 m-0">
                <b>Patient Name</b>
                <input
                  style={{
                    width: 150,
                    height: 36,
                    border: `1px solid #00997c`,
                    borderRadius: 5,
                    marginTop: 5,
                  }}
                  name="search"
                  placeholder="Search"
                  value={state.searchItem}
                  onChange={state.handleSearchItemChange}
                />
              </div>
              <div className="container col-1 m-0">
                <b>Phone Number</b>
                {/* <input
                  style={{
                    width: 140,
                    height: 36,
                    border: `1px solid #00997c`,
                    borderRadius: 5,
                    marginTop: 5,
                  }}
                  name="phone"
                  placeholder="Search"
                  value={state?.phoneNumber}
                  onChange={(event) => {
                    state.setPhoneNumber(event.target.value);
                  }}
                /> */}
              </div>

              <div className="container col-1 m-0">
                <b>Age</b>
              </div>

              <div className="container col-1 m-0">
                <b>Country</b>
              </div>

              <div className="container col-1 m-0">
                <b>State</b>
              </div>
              {/* <div className="container col-1 m-0">
                <b>Agent Name</b>
              </div> */}
            </div>
            <hr />
            <List component="nav" aria-label="mailbox folders">
              {state.homeData}
            </List>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={state.pageCount}
              initialPage={state.offset}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={state.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={state.offset}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Patients;
