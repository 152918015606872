import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import CoffeeBreak from "../../assets/coffee-break.svg"

export default function BreakPopUp({open,handleClose}) {
 
  return (
    <React.Fragment>
      <Dialog
      maxWidth='lg'
      fullWidth
        open={open}
      >
        <DialogTitle style={{textAlign:'center',textTransform:'capitalize',fontSize:'48px',fontWeight:"bolder",fontFamily:"'Archivo Black', sans-serif"}}>Break Time</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems:'center'
            }}
          >
           <Box
           width="50%"
           height="50%"
           component='img'
           src={CoffeeBreak}
           >

           </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button  sx={{backgroundColor:'#00997c',":hover":{backgroundColor:"#00996c"}}} variant='contained'  onClick={handleClose}>Back to Work</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}