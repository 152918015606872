import React, { useEffect, useState, useRef } from "react";
import TopBar from "../../Pages/Enquiry/TopBar";
import Sidebar from "../Sidebar";
import styled, { css } from "styled-components";
import profileIcon from "../KareChat/user.png";
import menuIcon from "../KareChat/menu-bar.png";
import readIcon from "../../assets/check-blue.svg";
import messageSendIcon from "../../assets/sent-icon.svg";
import deliveredIcon from "../../assets/check-grey.svg";
import Wallpaper from "../KareChat/background.jpg";
import searchIcon from "../KareChat/search-icon.svg";
import filterIcon from "../KareChat/filter_home.svg";
import { FaFileDownload } from "react-icons/fa";
import sendIcon from "../KareChat/send-icon.svg";
import ScrollTo from "react-scroll-into-view";
import {
  getListOfTeam,
  getTeamByCode,
} from "../../services/mykare/messageService";
import "react-spring-bottom-sheet/dist/style.css";

import "./karechat.css";
import {
  downloadAttachment,
  getAllChats,
  getAllMessages,
  getAllMessageTemplates,
  sendDocuments,
  sendWaMessage,
  updateMessage,
} from "../../services/mykare/messageService";
import moment from "moment";
import CustomLoader from "../../components/CustomLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { Badge, Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import ReactModal from "react-modal";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, {
  anchorRef,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { BottomSheet } from "react-spring-bottom-sheet";
import { template } from "lodash";
import downloadDocument from "../../util/documentUtil";
import { checkPermission } from "../../util/permissionUtils";

import { CleaningServices } from "@mui/icons-material";
import { async } from "q";
import VoiceMessage from "./VoiceMessage/VoiceMessage";
import TemplateRecored from "./VoiceMessage/TemplateRecored";

const borderStyle = {
  width: "100%",
  height: "100vh",
  overflow: "hidden",
};

function MssgSec() {
  const targetRef = useRef(null);
  const fileInput = useRef(null);

  const userRole = localStorage.getItem("user-role");
  const userCode = localStorage.getItem("user-code");

  const [name, setClickProfile] = useState(null);
  const [data, setData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [limit, setLimit] = useState(10);

  const [dataList, setDataList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [patientId, setPatientId] = useState(chatList[0]?.userId);
  const [pageNo, setPageNo] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasmore, setHasMore] = useState(true);
  const [file, setFile] = useState(null);
  const [read, setRead] = useState("All");

  const [selectedItem, setSelectedItem] = useState({
    id: 0,
    name: "Company Number",
    numberType: "NOTIFIER",
    whatsappNumberId: 0,
  });

  const [agentList, setAgentList] = useState([]);

  // Voice Message State
  const [voiceMessage,setVoiceMessage] = useState({
    Recorder:false,
    isrecording:false,
    isBlocked:false,
    blobURL:"",
    file:""
  })

  useEffect(() => {
    if (userRole === "SALES_AGENT") {
      getTeamByCode().then((res) => {
        const teams = res?.data;
        setAgentList([
          {
            id: 0,
            name: "Company Number",
            numberType: "NOTIFIER",
            whatsappNumberId: 0,
          },
          teams,
        ]);
      });
    } else {
      getListOfTeam().then((res) => {
        const teams = res?.data?.teamResponses;
        const activeTeams = teams.filter((x) => x.whatsappNumberId != null);

        setAgentList([
          {
            id: 0,
            name: "Company Number",
            numberType: "NOTIFIER",
            whatsappNumberId: 0,
          },
          ...activeTeams,
        ]);
      });
    }
  }, []);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    getMessage();
  }, [patientId]);

  const handleFilter = () => {
    if (read == "All") {
      setPageNo(0);
      setCount(0);
      setChatList([]);
      setRead("Unread");
    } else {
      setPageNo(0);
      setCount(0);
      setChatList([]);
      setRead("All");
    }
  };

  const fetchMoreData = () => {
    // if (chatList.length >= count) {
    //   setHasMore(false);
    //   return;
    // }
    // let pageno = pageNo + 1;
    // setPageNo(pageno);
    if (chatList.length >= count) {
      setHasMore(false);
      return;
    }
    // a fake async api call like which sends
    // 20 more records in .5 secs
    setPageNo(pageNo + 1);
    if (read == "Unread") {
      getUnreadList(pageNo + 1);
    } else {
      getChatListMore(pageNo + 1);
    }
    // setTimeout(() => {
    //   setChatList(chatList.concat(Array.from({ length: 20 })));
    // }, 500);
  };

  useEffect(() => {
    if (dataList?.messages?.length > 0) {
      targetRef.current.scrollTop = targetRef.current.scrollHeight;
      const postData = {
        patientId,
        isRead: true,
      };
      updateMessage(postData).then((data) => {
        const newItem = chatList[selectedIndex];
        newItem.unReadCount = null;
        // setChatList((current) => [...current, newItem]);
      });
    }
  }, [dataList]);

  useEffect(() => {
    if (read == "Unread") {
      getUnreadList(pageNo);
    } else {
      console.log("===================1==========");
      getChatList(pageNo);
    }
    loadTemplates();
    //scrollEffect(targetRef);
  }, [read]);

  useEffect(() => {
    loadTemplates();
    //scrollEffect(targetRef);
  }, [selectedItem]);

  function onDismiss() {
    setOpen(false);
  }
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: "Personal Number" },
    { id: 2, label: "Company Number" },
  ]);
  // Global function to set voiceMessage 
  const GlobalVoiceMessage = (value) =>{
    setVoiceMessage(value)
  }


  const handleNumberType = (item) => {
    setSelectedItem(item);
    if (item.id == 0) {
      getChatList(0);
    } else {
      const postData = {
        pageNo: 0,
        pageSize: 30,
        whatsappNumberType: "PERSONAL",
        whatsappNumberId: item?.whatsappNumberId,
      };

      getPersonalChatList(postData);
    }
  };

  const sendDocument = async (data, patientId) => {
    let whatsappNumberId =  selectedItem?.whatsappNumberId;

    const response = await sendDocuments(data, patientId, whatsappNumberId);
    if (response.status == 200) {
      const responseData = response.data;
      if (responseData) {
        setDataList((current) => ({
          ...current,
          messages: [...current.messages, responseData],
        }));
        refreshChatList();
      }
    }
  };

  const handleTemplate = (template) => {
    const data = {
      id: 1,
      data: { text: template.value, documentId: null },
      type: "text",
      author: "me",
      date: Date.now(),
      value: null,
      senderName: null,
    };

    setDataList((current) => ({
      ...current,
      messages: [...current.messages, data],
    }));

    const postData = {
      patientId: patientId,
      templateId: template.id,
      whatsappNumberId: selectedItem?.whatsappNumberId,
      salesAgentCode:  selectedItem?.code,
    };
    sendWaMessage(postData).then((data) => {
      getMessage();
      refreshChatList();
    });
    onDismiss();
  };

  function sendMessage(message) {
    const data = {
      id: 1,
      data: { text: message, documentId: null },
      type: "text",
      author: "me",
      date: Date.now(),
      value: null,
      senderName: null,
    };
    setDataList((current) => ({
      ...current,
      messages: [...current.messages, data],
    }));
    const postData = {
      patientId: patientId,
      message: message,
      whatsappNumberId: selectedItem?.whatsappNumberId,
      salesAgentCode:  selectedItem?.code,
    };

    sendWaMessage(postData).then((data) => {
      getMessage();
      refreshChatList();
    });
  }

  function refreshChatList() {
    const newItem = chatList[selectedIndex];
    const foundIdx = chatList.findIndex((el) => el == newItem);
    chatList.splice(foundIdx, 1);
    chatList.unshift(newItem);
    setChatList(chatList);
    setSelectedIndex(0);
  }

  const getUnreadList = async (pageNo) => {
    const postData = { pageNo, pageSize: 30, type: read };
    const data = await getAllChats(postData);
    if (data) {
      setCount(data.count);
      setChatList(data.userResponses);
      if (data?.userResponses?.length >= data.count) {
        setHasMore(false);
        return;
      }
    }
  };

  const getChatListMore = async (pageNo) => {
    const postData = { pageNo, pageSize: 30 };
    setIsLoaded(true);
    const data = await getAllChats(postData);
    if (data) {
      setCount(data.count);
      let response = chatList.concat(data.userResponses);
      setChatList(response);
    }
  };

  const getChatList = async (pageNo) => {
    const postData = { pageNo, pageSize: 30 };
    setIsLoaded(true);
    const data = await getAllChats(postData);
    if (data) {
      setCount(data.count);
      let response = data.userResponses; //chatList.concat(data.userResponses);
      setChatList(response);
    }
  };

  const getPersonalChatList = async (postData) => {
    setIsLoaded(true);
    const data = await getAllChats(postData);
    if (data) {
      setCount(data.count);

      let response = data.userResponses; //chatList.concat(data.userResponses);
      console.log("=====response33============", response);
      setChatList(response);
    }
  };

  const getMessage = async () => {
    const messageResponse = await getAllMessages(
      patientId,
      selectedItem?.whatsappNumberId
    );
    if (messageResponse) {
      setDataList(messageResponse);
    }
  };

  const handleChange = (sendingData) => {
    sendMessage(sendingData);
    setData("");
  };

  const [templateList, setTemplates] = useState([]);
  const loadTemplates = async () => {
    try {
      getAllMessageTemplates(selectedItem?.numberType, false).then((data) => {
        setTemplates(data);
      });
    } catch (error) {}
  };

  function onFileDownload(documentId) {
    downloadAttachment(documentId)
      .then((data) => {
        downloadDocument(data.documentType, data.document, data.documentName);
      })
      .catch((err) => {});
  }

  const handleSearch = async (e) => {
    const search = e.target.value;
    const postData = { pageNo: 0, pageSize: 30, search };
    const data = await getAllChats(postData);
    if (data) {
      // setIsLoaded(false);
      setCount(data.count);
      //let response = chatList.concat(data.userResponses);
      setChatList(data.userResponses);
      // setPatientId(data.userResponses[0].userId);
      //setClickProfile(data.userResponses[0].name);
      setHasMore(false);
    }
  };

  const handleFileUpload = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.target.files[0]) {
      sendDocument(event.target.files[0], patientId);
    }
  };

  const useStyles = makeStyles({
    paper: {
      position: "absolute",
      left: 0,
      bottom: 0,
    },
  });
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  // onSend = (messages: IMessage[]) =>
  // messages.filter(msg => msg.text !== "").forEach(msg => sendMyMessage(msg));

  return (
    <div style={borderStyle}>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ width: "100%", padding: 0 }}>
          <div>
            <div className="main-subContainer">
              <TopBar />
            </div>
            <div className="webchat">
              <PageContainerDiv>
                {false ? (
                  <CustomLoader />
                ) : (
                  <>
                    <Sidebarmain>
                      <SidebarHeader id="scrollhead">
                        <br></br>

                        <div
                          style={{
                            display: "flex",
                            position: "relative",
                          }}
                        >
                          <span
                            style={{
                              width: "70%",
                              // marginLeft: "1px",
                              position: "relative",
                              display: "block",
                            }}
                          >
                            <img
                              src={searchIcon}
                              style={{
                                width: "18px",

                                marginLeft: "35px",
                                position: "absolute",
                                top: "9px",
                                left: "6px",
                              }}
                            />
                            <input
                              onChange={(e) => {
                                handleSearch(e);
                              }}
                              style={{
                                maxHeight: "0px",
                                overflow: "hidden scroll",
                                width: "100%",
                                borderColor: "rgb(221 204 204 / 0%)",
                                marginLeft: "30px",
                                padding: "17px 16px 14px 39px",
                                borderRadius: "10px",
                                background: "rgb(227, 236, 239)",
                              }}
                              placeholder="Search"
                            />
                          </span>
                          <span
                            style={{
                              width: 30,
                              height: 30,
                              position: "relative",
                              marginLeft: "35px",
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: 30,
                              cursor: "pointer",
                              backgroundColor:
                                read == "Unread" ? "#06c6a2" : "transparent",
                            }}
                            onClick={() => handleFilter()}
                          >
                            <img
                              src={filterIcon}
                              style={{
                                width: "18px",
                              }}
                            />
                          </span>

                          <PopupState variant="popover" popupId="demoMenu">
                            {(popupState) => (
                              <div>
                                <div id="filterButton">
                                  <IconButton
                                    {...bindTrigger(popupState)}
                                    size="large"
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </div>
                                <Menu
                                  {...bindMenu(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  sx={{ gap: "10px" }}
                                >
                                  {agentList.map((item, index) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          backgroundColor:
                                            selectedItem === item
                                              ? "#06c6a2"
                                              : "white",
                                          color:
                                            selectedItem === item
                                              ? "white"
                                              : "black",
                                        }}
                                        onClick={() => {
                                          handleNumberType(item);
                                          popupState.close();
                                        }}
                                        key={index}
                                      >
                                        <Checkbox
                                          checked={selectedItem === item}
                                          onChange={() =>
                                            handleNumberType(item)
                                          }
                                          inputProps={{
                                            "aria-label": "primary checkbox",
                                          }}
                                        />
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Menu>
                              </div>
                            )}
                          </PopupState>
                        </div>

                        <hr color="rgb(227, 236, 239)"></hr>
                        <InfiniteScroll
                          dataLength={chatList.length}
                          next={fetchMoreData}
                          hasMore={hasmore}
                          loader={<h6>Loading...</h6>}
                          height={800}
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                        >
                          <SidebarChat>
                            {chatList?.map((value, index) => {
                              return (
                                <InnerSiderTile
                                  key={index}
                                  selected={
                                    selectedIndex === index ? true : false
                                  }
                                  onClick={() => {
                                    setPatientId(value.userId);
                                    setSelectedIndex(index);
                                    setClickProfile(value.name);
                                  }}
                                >
                                  <AboutSection>
                                    <img
                                      src={profileIcon}
                                      style={{
                                        width: "34px",
                                        height: "34px",
                                        borderRadius: "100%",
                                        marginBottom: "130px",
                                        marginTop: "2px",
                                      }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "68%",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "4px",
                                          padding: "5px 9px 12px 0px",
                                        }}
                                      >
                                        <h5>
                                          {value.name?.length > 20
                                            ? `${value.name.substring(
                                                0,
                                                16
                                              )}...`
                                            : value.name}
                                        </h5>
                                        {checkPermission(
                                          "LEADS_PHONE_NUMBER_FIELD"
                                        ) && <h5>{value.phoneNumber}</h5>}
                                      </div>
                                      {value?.unReadCount && (
                                        <Badge
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            display: "grid",
                                            fontSize: "11px",
                                            alignItems: "center",
                                          }}
                                          bg="danger"
                                        >
                                          {value?.unReadCount}
                                        </Badge>
                                      )}
                                    </div>
                                  </AboutSection>
                                </InnerSiderTile>
                              );
                            })}
                          </SidebarChat>
                          {/* <SidebarChat>
                            {chatList?.map((value, index) => {
                              return (
                                <InnerSiderTile
                                  key={index}
                                  selected={
                                    selectedIndex === index ? true : false
                                  }
                                  onClick={() => {
                                    setPatientId(value.userId);
                                    setSelectedIndex(index);
                                    setClickProfile(value.name);
                                  }}
                                >
                                  <AboutSection>
                                    <img
                                      src={profileIcon}
                                      style={{
                                        width: "34px",
                                        height: "34px",
                                        borderRadius: "100%",
                                        marginBottom: "130px",
                                        marginTop: "2px",
                                      }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "68%",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: "4px",
                                          padding: "5px 9px 12px 0px",
                                        }}
                                      >
                                        <h5>
                                          {value.name.length > 20
                                            ? `${value.name.substring(
                                                0,
                                                16
                                              )}...`
                                            : value.name}
                                        </h5>
                                        {checkPermission(
                                          "LEADS_PHONE_NUMBER_FIELD"
                                        ) && <h5>{value.phoneNumber}</h5>}
                                      </div>
                                      {value?.unReadCount && (
                                        <Badge
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                            display: "grid",
                                            fontSize: "11px",
                                            alignItems: "center",
                                          }}
                                          bg="danger"
                                        >
                                          {value?.unReadCount}
                                        </Badge>
                                      )}
                                    </div>
                                  </AboutSection>
                                </InnerSiderTile>
                              );
                            })}
                          </SidebarChat> */}
                        </InfiniteScroll>
                        <hr color="rgb(227, 236, 239)"></hr>
                      </SidebarHeader>
                    </Sidebarmain>
                    <MessageSection>
                      <span
                        style={{
                          width: "88%",
                          marginLeft: "1px",
                          position: "relative",
                          height: "70px",
                          borderBottom: "0.5px solid #e7e7e7",
                          display: "block",
                        }}
                      >
                        <img
                          src={profileIcon}
                          style={{
                            height: "40px",
                            width: "40px",
                            marginLeft: "4px",
                            position: "absolute",
                            top: "15px",
                            left: "6px",
                          }}
                        />
                        {/* <ChatHeader> */}

                        <div
                          style={{
                            marginLeft: "50px",
                            padding: "13px 5px 5px 4px",
                          }}
                        >
                          <h5>{name}</h5>
                          {dataList?.userTxnIds?.length ? (
                            <div style={{ display: "flex" }}>
                              Enquiry Id : &nbsp;{" "}
                              {dataList?.userTxnIds?.map((val) => {
                                return <p>{val} &nbsp;</p>;
                              })}
                            </div>
                          ) : null}
                        </div>
                        {/* </ChatHeader> */}
                        <img
                          src={menuIcon}
                          style={{
                            height: "30px",
                            width: "30px",
                            marginLeft: "1370px",
                            position: "absolute",
                            top: "9px",
                            left: "6px",
                          }}
                        />
                        <img
                          src={searchIcon}
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "1350px",
                            position: "absolute",
                            top: "15px",
                            left: "6px",
                          }}
                        />
                      </span>

                      <ChatArea ref={targetRef}>
                        {dataList &&
                          dataList?.messages?.map((value, key) => {
                            if (value) {
                              return value.author == "me" ? (
                                <OutMssgSec key={key}>
                                  <div>
                                    <OutgoingMessage>
                                      <ContetntTag fntSize={"20px"}>
                                        {value.data?.text && value.data?.text}
                                        {value.data?.documentId ? (
                                          <>
                                            <FaFileDownload
                                              className="icon-black"
                                              onClick={() =>
                                                onFileDownload(
                                                  value.data?.documentId
                                                )
                                              }
                                            />{" "}
                                            {value.data?.documentId}{" "}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </ContetntTag>
                                      <ContetntTag
                                        fntSize={"10px"}
                                        pad={"0px 0px 0px 20px"}
                                      >
                                        {moment(value.date).format(
                                          "DD-MM-YYYY hh:mm a"
                                        )}
                                      
                                        {value.messageStatus &&  <img
                                          src={ value.messageStatus == "sent" ? messageSendIcon : value.messageStatus == "delivered" ?  deliveredIcon : value.messageStatus == "read" && readIcon}
                                          style={{
                                            height: "40px",
                                            width: "40px",
                                            padding:5
                                          }}
                                        />}
                                      </ContetntTag>
                                    </OutgoingMessage>
                                  </div>
                                </OutMssgSec>
                              ) : (
                                <IncMssgSec>
                                  <div>
                                    <IncomingMessage>
                                      <ContetntTag fntSize={"20px"}>
                                        {value.data?.text && value.data?.text}
                                        {value.data?.documentId ? (
                                          <>
                                            <FaFileDownload
                                              className="icon-black"
                                              onClick={() =>
                                                onFileDownload(
                                                  value.data?.documentId
                                                )
                                              }
                                            />{" "}
                                            {value.data?.documentId}{" "}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </ContetntTag>
                                      <ContetntTag
                                        fntSize={"10px"}
                                        pad={"0px 0px 0px 20px"}
                                      >
                                        {moment(value.date).format(
                                          "DD-MM-YYYY hh:mm a"
                                        )}
                                      </ContetntTag>
                                    </IncomingMessage>
                                  </div>
                                </IncMssgSec>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </ChatArea>
                      <Grid
                        xs={12}
                        container
                        item
                        justifyContent="space-between"
                      >
                        <Grid xs={3} justifyContent="center" item container>
                          <CustomButton
                            type="button"
                            onClick={() => setOpen(true)}
                            className="Sndbtn"
                          >
                            Choose Template
                          </CustomButton>
                          <label
                            for="agreementFile"
                            class="btn"
                            style={{
                              backgroundColor: "#06c6a2",
                              color: "white",
                              height: 50,
                              marginTop: 6,
                            }}
                          >
                            Upload Document
                          </label>
                          <input
                            id="agreementFile"
                            ref={fileInput}
                            onChange={handleFileUpload.bind(this)}
                            style={{ display: "none" }}
                            type="file"
                            // multiple={false}
                          />
                        </Grid>
                        {open && (
                          <span>
                            <BottomSheet open={open} onDismiss={onDismiss}>
                              <div
                                style={{
                                  height: "10vh",
                                  marginLeft: "100px",
                                }}
                              >
                                <div> Choose Template</div>
                                {templateList?.map((template) => (
                                  <button
                                    type="button"
                                    style={{
                                      width: "20%",
                                      marginRight: "10px",
                                    }}
                                    className="Sndbtn"
                                    onClick={() => handleTemplate(template)}
                                  >
                                    {template.name}
                                  </button>
                                ))}
                              </div>
                            </BottomSheet>
                          </span>
                        )}
                        <Grid item xs={6}  container sx={{ bgcolor: "green" }}>
                          {
                            // voice message component mount 
                            voiceMessage.Recorder?
                              
                            <TemplateRecored 
                                {...voiceMessage} 
                                sendDocument={sendDocument} 
                                patientId={patientId}
                                globalVoiceMessage={GlobalVoiceMessage}
                            />
                            :
                            <textarea
                              rows="2"
                              cols="160"
                              placeholder="Your Message"
                              onChange={(e) => setData(e.target.value)}
                              value={data}
                              />
                          }
                          
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          container
                          justifyContent="flex-start"
                          alignContent="center"
                          sx={{ bgcolor: "green" }}
                        >

                            {/* voice message button component */}
                          <VoiceMessage 
                            {...voiceMessage}
                            globalVoiceMessage={GlobalVoiceMessage}
                            sendDocument={sendDocument}
                            patientId={patientId}
                          />
                          {!voiceMessage.Recorder&&<CustomButton
                            type="button"
                            onClick={() => handleChange(data)}
                            className="Sndbtn"
                          >
                            Send
                          </CustomButton>}
                           
                        </Grid>
                      </Grid>
                    </MessageSection>
                  </>
                )}
              </PageContainerDiv>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MssgSec;

export const CustomMenu = React.forwardRef(
  (
    { children, style, className, "aria-labelledby": labeledBy, searchStyle },
    ref
  ) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          style={searchStyle}
          autoFocus
          className={!searchStyle && "mx-3 my-2 w-auto"}
          placeholder=""
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child?.props?.children?.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const PageContainerDiv = styled.div`
  width: 100%;
  height: 85vh;
  background-color: white;
  display: flex;
`;

const Sidebarmain = styled.div`
  background-color: whitesmoke;
  width: 30%;
  max-height: 100vh;
`;
const SidebarHeader = styled.div`
  background-color: whitesmoke;
  width: 100%;
  height: 11.7%;
  margin-left: 10px !important;
  #filterButton {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: #06c6a2;
      transition: 0.9s;
    }
  }
  /* border-bottom: 2px solid gray; */

  & div > textarea {
    border-radius: 5px;
    border: none;
    margin-top: 0px;

    border: 0.5px solid black;
  }
`;
const SidebarChat = styled.div`
  cursor: pointer;
  // width: 100%;
  // max-height: 77vh;
  // padding-top: 10px;
  // overflow-y: hidden;
`;
const MessageSection = styled.div`
  width: 100%;
  height: 100%;
  overflow: "scroll";
  border-left: 0.5px solid gray;
`;
const ChatHeader = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  border-bottom: 0.5px solid gray;
  background-color: whitesmoke;
`;
const ChatArea = styled.div`
  height: 70vh;
  background-image: url(${Wallpaper});
  overflow-x: hidden;
  overflow-y: auto;
`;

const IncMssgSec = styled.div`
  & > div {
    width: 100%;
    // height: 100px;
    display: flex;
    align-items: center;
  }
`;
const OutMssgSec = styled.div`
  & > div {
    width: 100%;
    // height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const IncomingMessage = styled.div`
  margin-left: 30px;
  background-color: white;
  max-width: 50%;
  padding: 12px 20px;
  border-radius: 10px;
  border: 0.5px solid #e7e7e7;
  margin-bottom: 20px;
  margin-top: 20px;
`;
const OutgoingMessage = styled.div`
  background-color: white;
  max-width: 50%;
  padding: 12px 20px;
  border-radius: 10px;
  border: 0.5px solid #e7e7e7;
  width: fit-content;
  margin-right: 20px;
  margin-top: 20px;
`;
const ContetntTag = styled.span`
  font-size: ${({ fntSize }) => (fntSize ? fntSize : "14px")};
  padding: ${({ pad }) => (pad ? pad : "10px 10px 10px 10px")};
  margin-left: ${({ mLeft }) => (mLeft ? mLeft : "auto")};
`;

const CustomButton = styled.button`
  height: 50px;
  width: 35%;
  border-radius: 20px;
  margin-left: 20px;
  margin-right: 10px;
`;

const ChatFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  width: 100%;
  background-color: lightgray;

  & div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  & div > input {
    width: 70%;
    height: 50px;
    border-radius: 30px;
    border: none;
    margin-top: 10px;
    margin-left: 20px;
  }

  & div > button {
    height: 50px;
    width: 20%;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 10px;
  }

  & div > span {
    height: 50px;
    width: 20%;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 10px;
  }
`;

const SearchBar = styled.div`
  max-height: 300;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 360;
  border-color: #3ed0e5;
  margin-left: 30;
`;

const InnerSiderTile = styled.div`
  width: 150%;
  height: 80px;
  font-size: 5px;
  display: flex;
  margin-bottom: 8px;
  border-bottom: 0.5px solid #e7e7e7;
  padding-left: 20px;

  &:hover {
    background: rgb(227, 236, 239);
  }

  background-color: ${({ selected }) => (selected ? "rgb(227, 236, 239)" : "")};
`;
const AboutSection = styled.div`
  width: 80%;
  height: 100px;
  display: flex;
  gap: 10px;
`;

const TimeSection = styled.div`
  width: 40%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
