export const consultationMode = (mode) => {
  switch (mode) {
    case "Online":
      return "ONLIINE";
    case "Offline":
      return "OFFLINE";
    case "Home Visit":
      return "HOME_VISIT";
    default:
      return;
  }
};

export const loanStatus = (value) => {
  switch (value) {
    case "APPROVED":
      return { caps: "APPROVED", small: "Approved" };
    case "PARTIALLY_APPROVED":
      return { caps: "PARTIALLY_APPROVED", small: "Partially-Approved" };
    case "REJECTED":
      return { caps: "REJECTED", small: "Rejected" };
    default:
      return;
  }
};
export const loanType = (value) => {
  switch (value) {
    case "EMI":
      return {caps:"EMI",small:"Emi"};
    case "LOAN":
      return {caps:"LOAN",small:"Loan"};
    default:
      return;
  }
};
