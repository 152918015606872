import {
  List,
  ListItemText,
  MenuItem,
  Select,
  Checkbox,
  InputLabel,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import Sidebar from "../../Sidebar";
import TopBar from "../../Enquiry/TopBar";
import Dropdown from "react-bootstrap/Dropdown";
import { MenuProps } from "../../../components/multi-select";
import useHospitalDetail from "./useHospitalDetail.logic";
import downloadDocument from "../../../util/documentUtil";
import { Link } from "react-router-dom";
import { checkPermission } from "../../../util/permissionUtils";

const HospitalDetail = (props) => {
  const state = useHospitalDetail();
  const border = {
    width: "100%",
    height: "100vh",
    overflow: "scroll",
  };

  return (
    <div className="report-container">
      <div className="report-sidebar-container">
        <Sidebar />
      </div>
      <div className="report-main-container">
        <div className="row " style={border}>
          <div style={{ width: "100%", padding: 10 }}>
            <div className="main-subContainer">
              <TopBar />
            </div>
            <button className="width1" onClick={() => state.back()}>
              BACK
            </button>
            <div style={{ marginTop: 10 }}>
              <h3>Hospital Details</h3>
            </div>

            <div className="row" style={{ display: "flex" }}>
              <div className="col-lg-2 col-6">
                <div style={{ marginTop: 10 }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Hospital Name
                  </p>
                  <br></br>

                  {state.editableMode ? (
                    <input
                      placeholder="Enter hospital name"
                      value={state.name}
                      style={{
                        width: 250,
                        height: 40,
                        borderRadius: 5,
                        paddingLeft: 10,
                        border: `1px solid gray`,
                        marginTop: 5,
                      }}
                      onChange={(e) => state.setName(e.target.value)}
                    />
                  ) : (
                    <span>{state.name}</span>
                  )}
                </div>
              </div>

              <div className="col-lg-2 col-6">
                <div style={{ marginTop: 10 }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Location
                  </p>
                  <br></br>

                  {state.editableMode ? (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        style={{
                          width: 200,
                          height: 36,
                          border: `1px solid gray`,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {state.selectedLocation
                          ? state.selectedLocation.name
                          : "Location"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          maxHeight: 100,
                          overflowY: "scroll",
                          overflowX: "hidden",
                          width: 200,
                        }}
                      >
                        {state.locations.map((value, index) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              href="#"
                              style={{ width: 200, maxHeight: 200 }}
                              onClick={() =>
                                state.setSelectedLocation((prev) => ({
                                  name: value.name,
                                  id: value.id,
                                }))
                              }
                            >
                              {value.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <span>{state?.selectedLocation?.name}</span>
                  )}
                </div>
              </div>

              <div className="col-lg-2 col-6">
                <div style={{ marginTop: 10 }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Speciality
                  </p>
                  <br></br>

                  {state.editableMode ? (
                    <Select
                      labelId="mutiple-select-label"
                      placeholder="Select treatment"
                      multiple
                      value={state.selectedSpecialities}
                      onChange={state.handleChangeSpecialities}
                      renderValue={(value) =>
                        value.map((item) => item.name).join(", ")
                      }
                      MenuProps={MenuProps}
                      style={{
                        width: 250,
                        height: 40,
                        borderRadius: 5,
                        paddingLeft: 10,
                        border: `1px solid gray`,
                      }}
                    >
                      <MenuItem
                        key="0"
                        value={{ id: 0, value: "All" }}
                        classes={{
                          root: state.isAllSelectedSpecialities
                            ? state.classes.selectedAll
                            : "",
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            classes={{
                              indeterminate: state.classes.indeterminateColor,
                            }}
                            checked={state.isAllSelectedSpecialities}
                            indeterminate={
                              state.selectedSpecialities.length > 0 &&
                              state.selectedSpecialities.length <
                                state.selectedSpecialities.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: state.classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {state.specialityData.map((speciality) => (
                        <MenuItem key={speciality.id} value={speciality}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                state.specialityIds.indexOf(speciality.id) > -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText primary={speciality.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <span>
                      {state.specialityNames
                        ? state.specialityNames.join(", ")
                        : ""}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-lg-2 col-6">
                <div style={{ marginTop: 10 }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Treatments
                  </p>
                  <br></br>

                  {state.editableMode ? (
                    <Select
                      labelId="mutiple-select-label"
                      placeholder="Select treatment"
                      multiple
                      value={state.selectedTreatments}
                      onChange={state.handleChangeTreatments}
                      renderValue={(value) =>
                        value.map((item) => item.name).join(", ")
                      }
                      MenuProps={MenuProps}
                      style={{
                        width: 250,
                        height: 40,
                        borderRadius: 5,
                        paddingLeft: 10,
                        border: `1px solid gray`,
                      }}
                    >
                      <MenuItem
                        key="0"
                        value={{ id: 0, value: "All" }}
                        classes={{
                          root: state.isAllSelectedTreatments
                            ? state.classes.selectedAll
                            : "",
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            classes={{
                              indeterminate: state.classes.indeterminateColor,
                            }}
                            checked={state.isAllSelectedTreatments}
                            indeterminate={
                              state.selectedTreatments.length > 0 &&
                              state.selectedTreatments.length <
                                state.selectedTreatments.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: state.classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {state.treatmentData.map((treatment) => (
                        <MenuItem key={treatment.id} value={treatment}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                state.treatmentIds.indexOf(treatment.id) > -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText primary={treatment.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <span>
                      {state.treatmentNames
                        ? state.treatmentNames.join(", ")
                        : ""}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-lg-2 col-6">
                <div style={{ marginTop: 10 }}>
                  {/* style={{ textAlign: "left", fontWeight: 700, marginBottom: 0 }} */}

                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Model
                  </p>
                  <br></br>

                  {state.editableMode ? (
                    <Select
                      labelId="mutiple-select-label"
                      multiple
                      value={state.selectedModels}
                      onChange={state.handleChangeModels}
                      renderValue={(value) =>
                        value.map((item) => item.type).join(", ")
                      }
                      MenuProps={MenuProps}
                      style={{
                        width: 250,
                        height: 40,
                        borderRadius: 5,
                        paddingLeft: 10,
                        border: `1px solid gray`,
                      }}
                    >
                      <MenuItem
                        key="0"
                        value={{ id: 0, value: "All" }}
                        classes={{
                          root: state.isAllSelectedModels
                            ? state.classes.selectedAll
                            : "",
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            classes={{
                              indeterminate: state.classes.indeterminateColor,
                            }}
                            checked={state.isAllSelectedModels}
                            indeterminate={
                              state.selectedModels.length > 0 &&
                              state.selectedModels.length <
                                state.agreementModelsData.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: state.classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {state.agreementModelsData.map((model) => (
                        <MenuItem key={model.id} value={model}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                state.agreementModelIds.indexOf(model.id) > -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText primary={model.type} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <span>
                      {state.agreementModelTypes
                        ? state.agreementModelTypes.join(", ")
                        : ""}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-2 col-6">
                <div style={{ marginTop: 10 }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Contact Name
                  </p>
                  <br></br>
                  {state.editableMode ? (
                    <input
                      placeholder="Enter a contact name"
                      value={state.contactName}
                      style={{
                        width: 250,
                        height: 40,
                        borderRadius: 5,
                        paddingLeft: 10,
                        border: `1px solid gray`,
                      }}
                      onChange={(e) => state.setContactName(e.target.value)}
                    />
                  ) : (
                    <span>{state.contactName}</span>
                  )}
                </div>
              </div>

              <div className="col-lg-2 col-6">
                <div style={{ marginTop: 10 }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Contact Number
                  </p>
                  <br></br>
                  {state.editableMode ? (
                    <input
                      placeholder="Enter a contact number"
                      value={state.contactNumber}
                      style={{
                        width: 200,
                        height: 40,
                        borderRadius: 5,
                        paddingLeft: 10,
                        border: `1px solid gray`,
                      }}
                      onChange={(e) => state.setContactNumber(e.target.value)}
                    />
                  ) : (
                    <span>{state.contactNumber}</span>
                  )}
                </div>
              </div>

              <div className="col-lg-2">
                <div style={{ marginTop: 10 }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Google Map Link
                  </p>
                  <br></br>
                  {state.editableMode ? (
                    <textarea
                      placeholder="Add a google map link"
                      value={state.googleMapLink}
                      style={{
                        width: 200,
                        height: 100,
                        borderRadius: 5,
                        paddingLeft: 10,
                        border: `1px solid gray`,
                      }}
                      onChange={(e) => state.setGoogleMapLink(e.target.value)}
                    />
                  ) : (
                    <span>{state.googleMapLink}</span>
                  )}
                </div>
              </div>

              <div className="col-lg-2 ">
                <div style={{ marginTop: 10 }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Address
                  </p>
                  <br></br>
                  {state.editableMode ? (
                    <textarea
                      placeholder="Enter Hospital Address"
                      value={state.address}
                      style={{
                        width: 250,
                        height: 150,
                        borderRadius: 5,
                        paddingLeft: 10,
                        border: `1px solid gray`,
                      }}
                      onChange={(e) => state.setAddress(e.target.value)}
                    />
                  ) : (
                    <span>{state.address}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 ">
                <div style={{ marginTop: 10 }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Agreement
                  </p>
                  
                  <div style={{  }}>
                        {/* <p className="mb-0 mt-3 text-start">Agreement : </p> */}
                        {state.savedCenterDocs &&
                          state.savedCenterDocs.map((doc, index) => (
                            <div className="row">
                              <div class="col-6">
                                <div className="ed-txt-2 text-start"></div>
                              </div>
                              <div>
                                <b>
                                  <Link                                                                                                                                                                                                       
                                  to="#"
                                    style={{ marginLeft: 0 , marginBottom : -100}}
                                    className="pointer"
                                    onClick={() =>
                                      downloadDocument(
                                        doc.documentType,
                                        doc.document,
                                        doc.documentName
                                      )
                                    }
                                  >
                                    {`Agreement Document ${index + 1}`}
                                  </Link>
                                </b>
                              </div>
                            </div>
                          ))}
                          {/* {state.savedCenterDocs ?  <button
                          style={{
                            width: 200,
                            height: 50,
                            marginLeft: 30,
                            paddingBottom: 5,
                          }}
                          onClick={() => state.setEditableMode(true)}
                        >
                          Update Agreement
                        </button>  :<button
                          style={{
                            width: 200,
                            height: 50,
                            marginLeft: 30,
                            paddingBottom: 5,
                          }}
                          onClick={() => state.setEditableMode(true)}
                        >
                          Upload Agreement
                        </button> } */}
                      </div>
                      <br></br>
                  {state.editableMode ? (
                    <div
                      style={{
                        height: 50,
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {" "}
                      <label
                        for="agreementFile"
                        class="btn"
                        style={{
                          width: 200,
                          height: 40,
                          backgroundColor: "#06c6a2",
                          color: "white",
                          marginBottom: 30,
                        }}
                      >
                       {state.savedCenterDocs ? "Update Agreement" : "Upload Agreement"}
                      </label>
                      <div>
                        <input
                          id="agreementFile"
                          style={{
                            cursor: "pointer ",
                            display: "none",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 3,
                            paddingTop: 3,
                          }}
                          type="file"
                          ref={state.inputFile}
                          onChange={state.handleAgreementFile.bind(this)}
                        />
                        {state.agreementFiles && (
                          <>
                            <span
                              style={{
                                border: "1px solid gray",
                                marginTop: 40,
                                cursor: "pointer ",
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingBottom: 10,
                                paddingTop: 5,
                                backgroundColor: "#e5e5e5",
                              }}
                              onClick={() => state.removeItem()}
                            >
                              {state.agreementFiles?.name}
                            </span>
                            <span
                              style={{
                                paddingLeft: 12,
                                paddingRight: 12,
                                paddingBottom: 10,
                                paddingTop: 5,
                                backgroundColor: "#06c6a2",
                                border: "1px solid gray",
                                color: "white",
                                cursor: "pointer ",
                              }}
                              onClick={() => state.removeItem()}
                            >
                              X
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  ) :null}
                  <>

                    </>
                </div>
              </div>
              {/* {state.editableMode ? (
                <button
                  style={{
                    width: 100,
                    height: 50,
                    marginLeft: 30,
                    paddingBottom: 5,
                    marginTop: 50,
                  }}
                  onClick={() => state.addCenterDocs()}
                >
                  Save Agreement
                </button>
              ) : (
                <button
                  style={{
                    width: 100,
                    height: 50,
                    marginLeft: 30,
                    paddingBottom: 5,
                    marginTop: 50,
                  }}
                  onClick={() => state.setEditableMode(true)}
                >
                  Update Agreement
                </button>
              )} */}

              {/* <Button
                style={{ width: 160, height: 50, paddingBottom : 5 }}
                onClick={() => setIsOpen(!isOpen)}
              >
                Add Doctor Details
              </Button> */}
            {/* {checkPermission("LEADS_ASSIGNED_FIELD") && ( */}
              {state.editableMode ? (
                <button
                  style={{
                    width: 80,
                    height: 50,
                    marginLeft: 30,
                    paddingBottom: 5,
                    marginTop: 50,
                  }}
                  onClick={() => {
                    state.addNewCenter()
                  }}
                >
                  Save
                </button>
              ) : (checkPermission("HOSPITAL_UPDATE_BTN") &&
                <button
                  style={{
                    width: 80,
                    height: 50,
                    marginLeft: 30,
                    paddingBottom: 5,
                    marginTop: 50,
                  }}
                  onClick={() => state.setEditableMode(true)}
                >
                  Update
                </button>
              )}
            </div>

            <div>
              <Dialog
                open={state.open}
                onClose={state.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Doctor Details</DialogTitle>
                {/* <PhotoScrolling></PhotoScrolling> */}
                <DialogActions>
                  <div className="row" style={{ display: "flex" }}>
                    <div className="col-6">
                      <div style={{ marginTop: 10 }}>
                        <p
                          style={{
                            textAlign: "left",
                            fontWeight: 700,
                            marginBottom: 0,
                            marginLeft: 10,
                          }}
                        >
                          Doctor Name
                        </p>
                        {/* {editableMode ? ( */}
                        <input
                          //   placeholder="Enter Hospital Name"
                          //   value={loanDetails.loanId}
                          style={{
                            width: 250,
                            height: 40,
                            borderRadius: 5,
                            paddingLeft: 10,
                            border: `1px solid gray`,
                            marginLeft: 10,
                          }}
                          onChange={(e) => state.setDoctorName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div style={{ marginTop: 10 }}>
                        <p
                          style={{
                            textAlign: "left",
                            fontWeight: 700,
                            marginBottom: 0,
                          }}
                        >
                          Speciality
                        </p>

                        <Select
                          labelId="mutiple-select-label"
                          multiple
                          value={state.selectedDoctorSpecialities}
                          onChange={state.handleChangeDoctorSpecialities}
                          renderValue={(value) =>
                            value.map((item) => item.name).join(", ")
                          }
                          MenuProps={MenuProps}
                          style={{
                            width: 250,
                            height: 40,
                            borderRadius: 5,
                            paddingLeft: 10,
                            border: `1px solid gray`,
                          }}
                        >
                          <MenuItem
                            key="0"
                            value={{ id: 0, value: "All" }}
                            classes={{
                              root: state.isAllSelectedDoctorSpecialities
                                ? state.classes.selectedAll
                                : "",
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                classes={{
                                  indeterminate:
                                    state.classes.indeterminateColor,
                                }}
                                checked={state.isAllSelectedDoctorSpecialities}
                                indeterminate={
                                  state.selectedDoctorSpecialities.length > 0 &&
                                  state.selectedDoctorSpecialities.length <
                                    state.specialityDoctorData.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              classes={{ primary: state.classes.selectAllText }}
                              primary="Select All"
                            />
                          </MenuItem>
                          {state.specialityDoctorData.map((speciality) => (
                            <MenuItem key={speciality.id} value={speciality}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={
                                    state.doctorSpecialityIds.indexOf(
                                      speciality.id
                                    ) > -1
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText primary={speciality.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div style={{ marginTop: 10 }}>
                        <p
                          style={{
                            textAlign: "left",
                            fontWeight: 700,
                            marginBottom: 0,
                            marginLeft: 10,
                          }}
                        >
                          Qualification
                        </p>

                        <Select
                          labelId="mutiple-select-label"
                          multiple
                          value={state.selectedQualifications}
                          onChange={state.handleChangeQualifications}
                          renderValue={(value) =>
                            value.map((item) => item.name).join(", ")
                          }
                          MenuProps={MenuProps}
                          style={{
                            width: 250,
                            height: 40,
                            borderRadius: 5,
                            paddingLeft: 10,
                            border: `1px solid gray`,
                            marginLeft: 10,
                          }}
                        >
                          <MenuItem
                            key="0"
                            value={{ id: 0, value: "All" }}
                            classes={{
                              root: state.isAllSelectedQualification
                                ? state.classes.selectedAll
                                : "",
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                classes={{
                                  indeterminate:
                                    state.classes.indeterminateColor,
                                }}
                                checked={state.isAllSelectedQualification}
                                indeterminate={
                                  state.selectedQualifications.length > 0 &&
                                  state.selectedQualifications.length <
                                    state.qualificationData.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              classes={{ primary: state.classes.selectAllText }}
                              primary="Select All"
                            />
                          </MenuItem>
                          {state.qualificationData.map((qualification) => (
                            <MenuItem
                              key={qualification.id}
                              value={qualification}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  checked={
                                    state.qualificationIds.indexOf(
                                      qualification.id
                                    ) > -1
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText primary={qualification.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div style={{ marginTop: 10 }}>
                        <p
                          style={{
                            textAlign: "left",
                            fontWeight: 700,
                            marginBottom: 0,
                            width: 200,
                          }}
                        >
                          Experience
                        </p>
                        <input
                          //   placeholder="Enter Hospital Name"
                          //   value={loanDetails.loanId}
                          style={{
                            width: 250,
                            height: 40,
                            borderRadius: 5,
                            paddingLeft: 10,
                            border: `1px solid gray`,
                          }}
                          onChange={(e) => state.setExperience(e.target.value)}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <Button
                        onClick={state.handleClose}
                        style={{
                          width: 80,
                          height: 50,
                          marginLeft: 200,
                          paddingBottom: 5,
                          backgroundColor: "#00997c",
                          color: "white",
                        }}
                      >
                        Close
                      </Button>
                      <Button
                        onClick={() => state.addNewDoctor()}
                        style={{
                          width: 80,
                          height: 50,
                          marginLeft: 20,
                          paddingBottom: 5,
                          backgroundColor: "#00997c",
                          color: "white",
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </DialogActions>
              </Dialog>
            </div>
            <hr></hr>

            {checkPermission("DOCTOR_ADD") && state.centerId !== null && (
              <div>
                <Button
                  onClick={state.handleClickOpen}
                  style={{
                    width: 210,
                    height: 50,
                    marginRight: 100,
                    paddingBottom: 5,
                    backgroundColor: "#00997c",
                    color: "white",
                  }}
                >
                  Add Doctor
                </Button>
                <div style={{ marginTop: 10 }}>
                  <h3>Doctor Details</h3>
                </div>
                <hr></hr>
                {/* { isOpen && <AddField onClicked={(isOpen) => setIsOpen(isOpen)} setLocation={setLocation} setName={setName} onSave={()=>addNewCenter()}/>} */}
                <div className="row">
                  <div className="col-2">
                    <b>Code</b>
                  </div>
                  <div className="col-2">
                    <b>Name</b>
                  </div>
                  <div className=" col-3 ">
                    <b>Speciality</b>
                  </div>
                  <div className=" col-3">
                    <b>Qualification</b>
                  </div>
                  <div className=" col-2">
                    <b>Experience</b>
                  </div>
                </div>
                <hr></hr>
              </div>
            )}

            <List component="nav" aria-label="mailbox folders">
              {state.homeData}
            </List>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalDetail;
