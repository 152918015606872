import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

export default function IpOpPopUp({
  activeData,
  setActiveData,
  kareBuddyList,
  assignToKareBuddy,
}) {
  const [buddyCode, setBuddyCode] = React.useState({});
  const handleClose = () => {
    setActiveData({ isActive: false });
  };

  const handleHandleSubmit = () => {
    assignToKareBuddy(
      activeData?.enquiryId,
      activeData?.appointmentType,
      activeData?.ipDetail,
      activeData?.opDetail,
      buddyCode
    );
  };
  return (
    <React.Fragment>
      <Dialog
        open={activeData?.isActive}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ width: "400px", px: "20px" }}
        >
          <span style={{fontWeight:600,color:"black"}}>{`Enquiry ID : `}</span>
          <span style={{fontWeight:600,color:"red"}}>{activeData?.enquiryId}</span>
        </DialogTitle>
        <Typography sx={{ textAlign: "left", paddingLeft: "20px",fontWeight:600,color:"gray" }}>
          Karebuddy Assign
        </Typography>
        <Grid sx={{ height: 180, px: "20px" }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={kareBuddyList}
            getOptionLabel={(option) => option.name || activeData?.assignedName}
            value={activeData?.assignedName}
            sx={{ width: 400 }}
            onChange={(e, value) => setBuddyCode(value?.code)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Assign" />
            )}
          />
        </Grid>

        <DialogActions sx={{ px: "20px", marginBottom: "10px" }}>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "red",
              color: "white",
              width: 90,
              "&:hover": {
                backgroundColor: "red",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleHandleSubmit}
            sx={{
              backgroundColor: "#06c6a2",
              color: "white",
              width: 90,
              "&:hover": {
                backgroundColor: "#06c6a2",
              },
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
