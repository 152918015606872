import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  addDoctor,
  getAllDoctors,
  deleteDcotorById,
} from "../../../services/mykare/doctorsService";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useStyles } from "../../../components/multi-select";
import { checkPermission } from "../../../util/permissionUtils";
import { saveEventActivityLogs } from "../../../util/EventActivity";

const useDoctorPage = () => {
  const classes = useStyles();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [homeData, setHomeData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [name, setName] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [pageData, setPageaData] = useState([]);
  const [perPage] = useState(20);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [reload, setReload] = useState(false);
  let history = useHistory();

  useEffect(() => {
    loadAllDoctors(offset);
  }, [offset]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage);
    if (selectedPage != offset) {
      setReload(true);
    }
  };

  const tooltipStyles = {
    tooltip: {
      fontSize: "20px",
      borderRadius: "18px",
      boxShadow: "0 20px 80px 0",
      backgroundColor: "#FFFFE0",
      color: "black",
    },
  };

  const deleteDoctor = async (doctorId) => {
    if (window.confirm("Are you sure you want to delete ?")) {
      const data = await deleteDcotorById(doctorId);
      if (data.status === 200) {
        saveEventActivityLogs('Delete Doctor')
        loadAllDoctors(0, perPage);
      }
    }
  };

  const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

  const loadAllDoctors = async (offset, perPage) => {
    setDataLoaded(true);
    // const centerData = await getAllCenters(offset, perPage)
    getAllDoctors(0, perPage).then((res) => {
      const data = res.doctors;
      setPageCount(Math.ceil(res.count / perPage));
      if (data) {
        setPageaData(data);
      }
      setDataLoaded(false);
    });
    // // if (centerData) {
    //   setPageaData(data);
    // // }
  };

  const addNewDoctor = async () => {
    if (name && specialization && selectedCenter) {
      const postData = {
        name: name,
        specialization: specialization,
        centerId: selectedCenter.id,
      };
      const response = await addDoctor(postData);
      if (window.confirm("Are you sure you want to add doctor ! ")) {
        if (response.status === 200) {
          setIsOpen(!isOpen);
          loadAllDoctors();
        }
      }
    }
  };

  function handleClickTale(data) {
    history.push({
      pathname: "/doctorInner",
      state: { data: data },
    });
  }
  useEffect(() => {
    if (!!pageData) {
      const postData = pageData.map((data, index) => (
        <div
          key={index}
          className="row pointer"
          // style={{overflow:"scroll"}}
        >
          <div className=" col-1">
            <ListItem>
              <ListItemText primary={`${data.code}`} />
            </ListItem>
          </div>
          <div className=" col-2">
            <ListItem>
              <ListItemText primary={`${data.name}`} />
            </ListItem>
          </div>
          <div className=" col-3 ">
            <ListItem>
              <ListItemText
                primary={`${data.specializations.map((item) => item.name)}`}
              />
            </ListItem>
          </div>
          <div className=" col-3 ">
            <ListItem>
              <CustomTooltip
                title={` ${data.qualifications.map((data, index) => {
                  return data.name;
                })}`}
              >
                <ListItemText
                  primary={`${data.qualifications.map((item) => item.name)}`}
                  style={{
                    whiteSpace: "nowrap",
                    width: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                />
              </CustomTooltip>
            </ListItem>
          </div>
          <div className=" col-2 ">
            <ListItem>
              <ListItemText primary={`${data.experience}`} />
            </ListItem>
          </div>
          <div className="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            {checkPermission("DOCTOR_VIEW") && (
              <button
                style={{ width: 60, backgroundColor: "" }}
                onClick={() => handleClickTale(data)}
              >
                View
              </button>
            )}
            {checkPermission("DOCTOR_DELETE") && (
              <button
                style={{ width: 60, backgroundColor: "red" }}
                onClick={() => deleteDoctor(data.id)}
              >
                Delete
              </button>
            )}
          </div>
          <hr />
        </div>
      ));
      setHomeData(postData);
    }
  }, [pageData]);

  return {
    homeData,
    setHomeData,
    isOpen,
    setIsOpen,
    selectedCenter,
    setSelectedCenter,
    name,
    setName,
    specialization,
    setSpecialization,
    pageData,
    setPageaData,
    dataLoaded,
    setDataLoaded,
    addNewDoctor,
    handlePageClick,
  };
};

export default useDoctorPage;
