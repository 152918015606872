import React from "react";
import useCabDetail from "./useCabDetail.logic";
import DatePicker from "react-datepicker";
import * as ReactBootstrap from "react-bootstrap";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import downloadDocument from "../../../util/documentUtil";
import moment from "moment";

function TextField(props) {
  return (
    <Grid item xs={2}>
      {props.isEditable ? (
        <Grid container direction="column">
          <label>{props.label}</label>
          <input
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            style={{
              width: 200,
              height: 40,
              borderRadius: 5,
              paddingLeft: 10,
              border: `1px solid gray`,
            }}
            onChange={(e) => props.onChange(e)}
          />
        </Grid>
      ) : (
        <Grid container direction="column">
          <b>{props.label}</b>
          <span>{props.value}</span>
        </Grid>
      )}
    </Grid>
  );
}

function DateTimeField(props) {
  return (
    <Grid item xs={2}>
      {props.isEditable ? (
        <Grid container direction="column">
          <label>Date Time</label>
          <DatePicker
            className="myDatePicker "
            selected={
              props.pickupDateTime ? new Date(props.pickupDateTime) : new Date()
            }
            onChange={(date) =>
              props.setCabDetails((s) => ({ ...s, pickupDateTime: date }))
            } // minDate={new Date()}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm a"
          />
        </Grid>
      ) : (
        <Grid container direction="column">
          <b>Date Time</b>
          <span>
            {moment(props.pickupDateTime).format("DD-MM-YYYY hh:mm a")}
          </span>
        </Grid>
      )}
    </Grid>
  );
}

function UploadDocument(props) {
  return (
    <Grid item xs={1.5}>
      {props.isEditable ? (
        <div>
          <div>Upload a file:</div>

          {/* 👇 Our custom button to select and upload a file */}
          <button
            style={{
              minWidth: 200,
              maxWidth: "500px",
              height: 50,
              margin: 0,
              backgroundColor: "Highlight",
            }}
            onClick={props.state.handleUploadClick}
          >
            {props.state.file ? `${props.state.file.name}` : "Click to select"}
          </button>

          {/* 👇 Notice the `display: hidden` on the input */}
          <input
            type="file"
            ref={props.state.inputRef}
            onChange={props.state.handleFileChange}
            style={{
              display: "none",
            }}
          />
        </div>
      ) : (
        <Grid direction="column" container xs={2}>
          <b>Bill</b>
          <b>
            <Link
              to="#"
              // style={{ marginLeft: 10 }}
              className="pointer"
              onClick={props.onDownload}
            >
              Download
            </Link>
          </b>
        </Grid>
      )}
    </Grid>
  );
}

function CabTiles({
  onUpdateClick,
  isEditable,
  pickupFrom,
  dropTo,
  otp,
  pickupDateTime,
  setCabDetails,
  amount,
  state,
  returnCabNeeded,
  onSave,
  onCancel,
  onDownload,
}) {
  return (
    <Grid
      container
      sx={{ marginBottom: 20, backgroundColor: "red" }}
      xs={12}
      spacing={2}
    >
      <TextField
        type="text"
        isEditable={isEditable}
        onChange={(e) =>
          setCabDetails((s) => ({
            ...s,
            pickup: e.target.value,
          }))
        }
        value={pickupFrom}
        placeholder="From"
        label="From"
      />
      <TextField
        type="text"
        isEditable={isEditable}
        onChange={(e) => setCabDetails((s) => ({ ...s, drop: e.target.value }))}
        value={dropTo}
        placeholder="To"
        label="To"
      />

      <TextField
        type="text"
        isEditable={isEditable}
        onChange={(e) => setCabDetails((s) => ({ ...s, otp: e.target.value }))}
        value={otp}
        placeholder="Otp"
        label="OTP"
      />

      <DateTimeField
        isEditable={isEditable}
        pickupDateTime={pickupDateTime}
        setCabDetails={setCabDetails}
      />
      <TextField
        type="number"
        isEditable={isEditable}
        onChange={(e) =>
          setCabDetails((s) => ({ ...s, amount: e.target.value }))
        }
        value={amount}
        placeholder="0"
        label="Amount"
      />

      <UploadDocument
        state={state}
        isEditable={isEditable}
        onDownload={onDownload}
      />

      <FormControlLabel
        control={
          <Checkbox
            disabled={!isEditable}
            defaultChecked={returnCabNeeded}
            color="primary"
            onClick={() =>
              setCabDetails((s) => ({
                ...s,
                returnCab: !s.returnCab,
              }))
            }
          />
        }
        label="Return Cab"
      />

      <Grid item xs={1.5}>
        {isEditable && (
          <>
            <button
              style={{
                width: 120,
                height: 50,
                marginRight: 10,
              }}
              onClick={onSave}
            >
              Save
            </button>
            <button
              style={{
                width: 120,
                height: 50,
                backgroundColor: "gray",
              }}
              onClick={onCancel}
            >
              Cancel
            </button>
          </>
        )}
        {!isEditable && (
          <button
            style={{
              width: 120,
              height: 50,
              marginRight: 10,
            }}
            onClick={onUpdateClick}
          >
            Update
          </button>
        )}
      </Grid>
    </Grid>
  );
}

function CabDetail({ userTrxnId, cabDetailsId, callBackFn }) {
  const state = useCabDetail({ userTrxnId, cabDetailsId, callBackFn });

  if (!state.dataLoaded) {
    return (
      <div
        className="spinner"
        style={{
          position: "absolute",
          bottom: "25%",
        }}
      >
        <ReactBootstrap.Spinner animation="grow light" />
      </div>
    );
  }

  return (
    <div>
      <Grid>
        <button
          style={{ width: 120, height: 50 }}
          onClick={() => {
            state.setIsAdd(true);
          }}
        >
          Add CabDetail
        </button>
        {state.isAdd && (
          <CabTiles
            amount={state?.cabDetails?.amount}
            dropTo={state?.cabDetails.drop}
            pickupDateTime={state?.cabDetails?.pickupDateTime}
            pickupFrom={state?.cabDetails?.pickup}
            otp={state?.cabDetails?.otp}
            returnCabNeeded={state?.cabDetails?.returnCab}
            setCabDetails={state.setCabDetails}
            onSave={() => {

              state.saveCabDetails();
            }}
            onCancel={() => {
              state.clearDetails();
              state.setIsAdd(false);
            }}
            isEditable={state.isAdd}
            state={state}
          ></CabTiles>
        )}
        <hr />
        <div>
          {state?.data?.length !== 0 &&
            state?.data?.map((val, i) => {
              let currentlist = i == state.selectedIndex;
              return (
                <>
                  <CabTiles
                    isEditable={currentlist}
                    onUpdateClick={() => {
                      state.setSelectedIndex(i);
                      state.setCabDetails(val);
                    }}
                    amount={currentlist ? state.cabDetails.amount : val?.amount}
                    dropTo={currentlist ? state.cabDetails.drop : val.drop}
                    otp={currentlist ? state.cabDetails.otp : val.otp}
                    pickupDateTime={
                      currentlist
                        ? state.cabDetails.pickupDateTime
                        : val?.pickupDateTime
                    }
                    pickupFrom={
                      currentlist ? state.cabDetails.pickup : val?.pickup
                    }
                    returnCabNeeded={
                      currentlist ? state.cabDetails.returnCab : val?.returnCab
                    }
                    setCabDetails={state.setCabDetails}
                    onDownload={() => state.downloadDocuments(val.cabDetailId)}
                    onSave={() => {
                      state.saveCabDetails(val.cabDetailId);
                      state.setSelectedIndex(null);
                    }}
                    onCancel={() => {
                      state.clearDetails();
                      state.setSelectedIndex(null);
                    }}
                    state={state}
                  ></CabTiles>
                  <hr />
                </>
              );
            })}
        </div>
      </Grid>
      <p style={{ color: "green", textAlign: "center" }}>{state.errorMsg}</p>
    </div>
  );
}

export default CabDetail;
