import "./Arrival.css";
import React from "react";
import "../../Styles/sideBar.css";
import Sidebar from ".././Sidebar";
import "../../Styles/Pagination.css";
import List from "@material-ui/core/List";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import useArrival from "./useArrival.logic";
import "react-pro-sidebar/dist/css/styles.css";
import * as ReactBootstrap from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import CustomLoader from "../../components/CustomLoader";
import PartnerTopBar from "../../components/Partner_TopBar";

const Arrival = () => {
  const state = useArrival();
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      marginTop: 10,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));
  const classes = useStyles();
  // if (!state.dataLoaded) {
  //   return <CustomLoader />;
  // }
  const flexStyle = {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  if (!state.dataLoaded) {
    return <CustomLoader />;
  }
  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div className={classes.root}>
          <div className="arrival-header">
            <PartnerTopBar />
          </div>
          <div className="sticky">
            <div className="row scroll" style={{ minHeight: "3rem" }}>
              <div className="container col-2 m-0">
                <b>Enquiry Date</b>
              </div>
              <div className="container col-2 m-0">
                <b>Patient Details</b>
                <input
                  name="search"
                  placeholder="Search"
                  value={state.searchItem}
                  onChange={state.handleSearchItemChange}
                />
              </div>
              <div className="container col-2 m-0">
                <b>Agent Details</b>
              </div>
              <div className="container col-2 m-0">
                <b>Type</b>
                <br />
                <select
                  value={state.selectType.selected}
                  onChange={state.handleSelectTypeChange}
                >
                  {state.selectType.list.map((item, i) => (
                    <option key={i} value={item}>
                      {item.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="container col-2 m-0">
                <b>Center Name</b>
              </div>
              <div className="container col-2 m-0">
                <b>Status</b>
                <br />
                <select
                  value={state.selectType.selected}
                  onChange={state.handleSelectTypeChange}
                >
                  {state.selectType.list.map((item, i) => (
                    <option key={i} value={item}>
                      {item.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <hr />
            <List component="nav" aria-label="mailbox folders">
              {state.homeData}
            </List>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={state.pageCount}
              initialPage={state.offset}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={state.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              // forcePage={pageOffset}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Arrival;
