import React from "react";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import usePatientsDetail from "./usePatientsDetail.logic";

function PatientsDetail({patientId, refreshFn,userTransaxtionId}) {
  const state = usePatientsDetail({patientId, refreshFn,userTransaxtionId});
  return (
    <div>
      <div className="row">
        <div className="col-lg-2">
          <div>
            <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
              Patient Name
            </p>
            <input
              placeholder="Name"
              value={state.name}
              style={{
                width: 200,
                height: 40,
                borderRadius: 5,
                paddingLeft: 10,
                border: `1px solid gray`,
              }}
              onChange={(e) => state.setName(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div>
            <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
             Patient Age
            </p>
            <input
              placeholder="Age"
              value={state.age}
              style={{
                width: 200,
                height: 40,
                borderRadius: 5,
                paddingLeft: 10,
                border: `1px solid gray`,
              }}
              onChange={(e) => state.setAge(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div>
            <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Patient Email
            </p>
            <input
              placeholder="Email"
              value={state.email}
              style={{
                width: 200,
                height: 40,
                borderRadius: 5,
                paddingLeft: 10,
                border: `1px solid gray`,
              }}
              onChange={(e) => state.setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div>
            <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
              Gender
            </p>
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin:0,
                }}
              >
                {state.selectedGender ? state.selectedGender : "Male"}
              </Dropdown.Toggle>
              <Dropdown.Menu >
                {state.gender.map((value, id) => {
                  return (
                    <Dropdown.Item
                      key={id}
                      href="#"
                      style={{ width: 200 }}
                      onClick={() => state.setSelectedGender(value)}
                    >
                      {value}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="col-lg-2">
          <div>
            <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
             Alternate Contact
            </p>
            <input
              placeholder="Alternate Contact"
              value={state.alternateContact}
              style={{
                width: 200,
                height: 40,
                borderRadius: 5,
                paddingLeft: 10,
                border: `1px solid gray`,
              }}
              onChange={(e) => state.setAlternateContact(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-2">
          <button
            onClick={() => state.editPatientDetail()}
            style={{ width: 70, padding: 8, marginTop: 20 }}
          >
            Save
          </button>
        </div>
        <p style={{ color: "green" }}>{state.successMessage}</p>
      </div>
    </div>
  );
}

export default PatientsDetail;
