import axios from "axios";
import { getAuthHeader } from "../../store/DataStore";
import { APP_URL } from "../../util/AppConstants";

export const getAllKaretripLocations = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(`${APP_URL}/karetrip/enquiry/locations`, {
    headers,
  });
  return data;
};

export const getAllKaretripHospitals = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(`${APP_URL}/karetrip/enquiry/hospitals`, {
    headers,
  });
  return data;
};

export const getAllKaretripDoctors = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(`${APP_URL}/karetrip/enquiry/doctors`, {
    headers,
  });
  return data;
};

export const getAllKaretripTreatments = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(`${APP_URL}/karetrip/enquiry/treatments`, {
    headers,
  });
  return data;
};

export const getAllKaretripSources = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(`${APP_URL}/karetrip/enquiry/sources`, {
    headers,
  });
  return data;
};

export const getAllKaretripCountries = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(`${APP_URL}/karetrip/enquiry/country/all`, {
    headers,
  });
  return data;
};
