import "../../Styles/Common.css";
import "../../Styles/sideBar.css";
import "../../Styles/Pagination.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import ListItem from "@material-ui/core/ListItem";
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import StarIcon from "@mui/icons-material/Star";
import "./scan.css";
import { statusColorFinder } from "../../util/statusColorFinder";
import {
 
  getEnquiryStatistics,
  getFilteredEnquiries,
  getUserTrxnStatuses,
  getSources,
  getTask,
  getTestPushNotification,
  getTaskCount,
  getTopPerfomer,
  getUpcomingTask,
  saveUserActivity,
  getTotalWorkingTime,
  getUserStatus,
  saveUserBreaks,
} from "../../services/mykare/enquiryService";
import { getScan } from "../../services/mykare/enquiryService";
// import { getScan } from "../../services/mykare/scanService";

import Tooltip from "@material-ui/core/Tooltip";
import { useStyles } from "../../components/multi-select";
import { getTreatments } from "../../services/mykare/dataService";
import { getAllLocations } from "../../services/mykare/centerService";
import CountComponent from "../../components/CountContainer";
import { checkPermission } from "../../util/permissionUtils";
import { getWorkingHours } from "../../util/getHours";
import OtpVerified from "../../components/OtpVerified";
import verifiedIcon from "./../../assets/icons/checkboxIcon.webp";
import { getAgentData } from "../../services/commonService/commonService";
const useScan= () => {
  let history = useHistory();
  const location = useLocation();
  const [perPage] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [marquee, setMarquee] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [homeData, setHomeData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isloader, setLoader] = useState(false);
  const [filterLoaded, setFilterLoaded] = useState(true);
  const [enquiryStats, setEnquiryStats] = useState(null);
  const [reload, setReload] = useState(false);
  const [searchEnquiryFromDate, setSearchEnquiryFromDate] = useState(
    new Date()
  );
  const [searchEnquiryToDate, setSearchEnquiryToDate] = useState(new Date());
  const [searchFollowupFromDate, setSearchFollowupFromDate] = useState(
    new Date()
  );
  const [searchFollowupToDate, setSearchFollowupToDate] = useState(new Date());
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [selectedStatusIds, setSelectedStatusIds] = useState([0]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([0]);
  const [treatmentData, setTreatmentData] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([0]);
  const [locationData, setLocationData] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedSourceIds, setSelectedSourceIds] = useState([0]);
  const [sourceData, setSourceData] = useState([]);
  const [count, setCount] = useState(0);
  const [taskData, setTaskData] = useState([]);
  const [todayCount, setTodayCount] = useState(0);
  const [missedCount, setMissedCount] = useState(0);
  const [isClear, setIsClear] = useState(false);
  const [isToday, setIsToday] = useState(true);
  const [showMessenger, setShowMessenger] = useState(true);
  const pageFilters = ["20", "50", "100", "200"];
  const [selectedPageFilter, setSelectedPageFilter] = useState("20");
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedAssignedToIds, setSelectedAssignedToIds] = useState([]);
  const [enquiryDateFilter, setEnquiryDateFilter] = useState([
    "All",
    "Today",
    "This Month",
    "Yesterday",
    "Choose Date",
    "Previous Month",
  ]);
  const [monthList, setMonthList] = useState(moment.months());
  const [assignedAgent, setAssignedAgent] = useState("All");
  const [assignedAgentCode, setAssignedAgentCode] = useState("ALL");
  const paymentModeFilter = ["All", "CASH", "EMI", "CASHLESS", "REIMBURSEMENT"];
  const intentFilter = ["All", "HOT", "WARM", "COLD"];
  const followUpDateFilter = ["All", "Today", "Tomorrow", "Choose Date"];
  const [selectedEnquiryDateFilter, setSelectedEnquiryDateFilter] =
    useState("All");
  const [selectedFollowupDateFilter, setSelectedFollowupDateFilter] =
    useState("All");
  const [selectedPaymentModeFilter, setSelectedPaymentModeFilter] =
    useState("All");
  const [selectedIntentFilter, setSelectedIntentFilter] = useState("All");
  const [assignedUser, setAssignedUser] = useState({
    list: [{ id: 0, name: "All", code: "ALL" }],
    selected: "All",
    selectedCode: "ALL",
  });
  const [hasMore, setHasMore] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [isOnBreak, setIsOnBreak] = useState(false);
  const [isClockIn, setIsClockIn] = useState(false);
  const [popUpon, setPopupOn] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [checkInTime, setCheckedInTime] = React.useState();
  const [checkOutTime, setCheckedOutTime] = React.useState();
  const [searchKeyName, setSearchKeyName] = useState("");
  const [searchKeyNumber, setSearchKeyNumber] = useState("");
  const [searchByEnqiuryId, setSearchByEnqiuryId] = useState({});
  const [totalTime, setTotalTime] = React.useState("");
  const [lastBreakType, setLastBreak] = React.useState("");
  const handleClickOpen = () => {
    if (isClockIn) {
      setOpen(true);
      checkUserStatus();
      setIsOnBreak(true);
    } else {
      alert("You need to clock-in first!");
    }
  };

  const handleClose = () => {
    saveBreak();
  };

  function getDropdownButtonLabel({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Select";
      return `${values}`;
    }
    return "Select";
  }
  function getDropdownButtonLabel({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Select";
      return `${values}`;
    }
    return "Select";
  }
  const useTableStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      marginTop: 10,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));
  const tooltipStyles = {
    tooltip: {
      fontSize: "20px",
      borderRadius: "18px",
      boxShadow: "0 20px 80px 0",
      backgroundColor: "#FFFFE0",
      color: "black",
    },
  };
  const [pageData, setPageData] = useState(null);
  const [allData, setAllData] = useState(null);
  const clearAllFilter = () => {
    setAssignedUser({ list: [{ id: 0, name: "All", code: "ALL" }] });
    setSelectedPageFilter(20);
    setSelectedStatusIds([0]);
    setSelectedStatuses([]);
    setSelectedSources([]);
    setSelectedSourceIds([0]);
    setSelectedLocations([]);
    setSelectedLocationIds([0]);
    setSelectedEnquiryDateFilter("All");
    setSearchKeyName("");
    setSelectedFollowupDateFilter("All");
    setSelectedPaymentModeFilter("All");
    setSelectedIntentFilter("All");
    setSelectedTreatmentIds([0]);
    setSelectedTreatments([]);
    setSearchItem("");
    setSearchEnquiryFromDate(new Date());
    setSearchEnquiryToDate(new Date());
    setSearchFollowupFromDate(new Date());
    setSearchFollowupToDate(new Date());
    loadAssignedUsers();
    setIsClear((s) => !s);
    setSelectedAssignedTo([]);
  };

  const handleSelectSources = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedSources(
        selectedSources.length === sourceData.length ? [] : sourceData
      );
      ids = sourceData.map((item) => item.value);
      setSelectedSourceIds(
        selectedSources.length === sourceData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === sourceData.length) {
        value = sourceData;
      }
      setSelectedSources(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedSourceIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedSources(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedSourceIds(ids);
    }
    loadFilteredData(
      searchItem,
      searchPhone,
      selectedStatusIds,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      selectedPaymentModeFilter,
      selectedIntentFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      ids,
      selectedLocationIds
    );
  };

  const handleSelectLocations = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedLocations(
        selectedLocations.length === locationData.length ? [] : locationData
      );
      ids = locationData.map((item) => item.value);
      setSelectedLocationIds(
        selectedLocations.length === locationData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === locationData.length) {
        value = locationData;
      }
      setSelectedLocations(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedLocationIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedLocations(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedLocationIds(ids);
    }
    loadFilteredData(
      searchItem,
      searchPhone,
      selectedStatusIds,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      selectedPaymentModeFilter,
      selectedIntentFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      ids
    );
  };
  const handleSelectTreatments = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedTreatments(
        selectedTreatments.length === treatmentData.length ? [] : treatmentData
      );
      ids = treatmentData.map((item) => item.value);
      setSelectedTreatmentIds(
        selectedTreatments.length === treatmentData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === treatmentData.length) {
        value = treatmentData;
      }
      setSelectedTreatments(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedTreatmentIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedTreatments(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedTreatmentIds(ids);
    }
    loadFilteredData(
      searchItem,
      searchPhone,
      selectedStatusIds,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      selectedPaymentModeFilter,
      selectedIntentFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      ids,
      selectedSourceIds,
      selectedLocationIds
    );
  };

  useEffect(() => {
   
    
    loadAllUpcomingTask();
    checkUserStatus();
    
  
  }, []);

  const handleAssignedAgents = (value, event) => {
    var ids;
    if (event.option.value === "ALL") {
      setSelectedAssignedTo(
        selectedAssignedTo.length === assignedUser?.list?.length
          ? []
          : assignedUser?.list
      );
      ids = assignedUser?.list.map((item) => item.value);

      setSelectedAssignedToIds(
        selectedAssignedTo.length === assignedUser?.list.length ? [] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === assignedUser?.list.length) {
        value = assignedUser?.list;
      }
      setSelectedAssignedTo(value);
      ids = value.length === 0 ? [] : value.map((item) => item.value);
      setSelectedAssignedToIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedAssignedTo(data);
      ids = data.length === 0 ? [] : data.map((item) => item.value);
      setSelectedAssignedToIds(ids);
    }

    loadFilteredData(
      searchItem,
      searchPhone,
      selectedStatusIds,
      ids,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      selectedPaymentModeFilter,
      selectedIntentFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds
    );
  };

  const handleSelectStatuses = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedStatuses(
        selectedStatuses.length === statusData.length ? [] : statusData
      );
      ids = statusData.map((item) => item?.value);
      setSelectedStatusIds(
        selectedStatuses.length === statusData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === statusData.length) {
        value = statusData;
      }
      setSelectedStatuses(value);
      ids = value.map((item) => item.value);
      setSelectedStatusIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedStatuses(data);
      ids = data.map((item) => item.value);
      setSelectedStatusIds(ids);
    }
    loadFilteredData(
      searchItem,
      searchPhone,
      ids,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      selectedPaymentModeFilter,
      selectedIntentFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds
    );
  };

  const [selectStatus, setSelectStatus] = useState({
    list: [],
    selected: "ALL",
  });

 

  const loadLocations = async () => {
    try {
      const response = await getAllLocations();
      var status = [
        { id: 0, name: "ALL" },
        { id: -1, name: "Blank" },
      ];
      status.push.apply(status, response);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setLocationData(data);
    } catch (error) {}
  };

  

  
  useEffect(() => {
    if (location.state) {
      if (
        location.state.offset ||
        location.state.selectedStatusIds ||
        location.state.selectedTreatmentIds ||
        location.state.selectedSourceIds ||
        location.state.selectedLocationIds ||
        location.state.selectedAssignedToIds
      ) {
        setSelectedStatuses(location.state.selectedStatuses);
        setSelectedStatusIds(location.state.selectedStatusIds);
        setSelectedTreatments(location.state.selectedTreatments);
        setSelectedTreatmentIds(location.state.selectedTreatmentIds);
        setSelectedSources(location.state.selectedSources);
        setSelectedSourceIds(location.state.selectedSourceIds);
        setSelectedLocations(location.state.selectedLocations);
        setSelectedLocationIds(location.state.selectedLocationIds);
        setOffset(location.state.offset);
        setSelectedEnquiryDateFilter(location.state.selectedEnquiryDateFilter);
        setSearchEnquiryFromDate(location.state.searchEnquiryFromDate);
        setSearchEnquiryToDate(location.state.searchEnquiryToDate);
        setSelectedFollowupDateFilter(
          location.state.selectedFollowupDateFilter
        );
        setSelectedPaymentModeFilter(location.state.selectedPaymentModeFilter);
        setSelectedIntentFilter(location.state.selectedIntent);
        setSearchFollowupFromDate(location.state.searchFollowupFromDate);
        setSearchFollowupToDate(location.state.searchFollowupToDate);
        setAssignedUser(location.state.assignedUser);
        setSelectedAssignedTo(location.state.selectedAssignedTo);
        setSelectedAssignedToIds(location.state.selectedAssignedToIds);
        setReload(true);
      }
    } else {
      setReload(true);
    }
  }, []);

  const handleAssignedToChange = (assignedCode, assignedName) => {
    setAssignedUser((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
  };

  const [searchItem, setSearchItem] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [searchEnquiryId, setSearchEnquiryId] = useState("");

  const handleSearchItemChange = (e) => {
    setSearchKeyName(e.target.value);
    if (e.target.value === "") {
      window.location.reload();
    }
  };
  const handleSearchPhone = (e) => {
    setSearchKeyNumber(e.target.value);
    if (e.target.value === "") {
      window.location.reload();
    }
  };
  const handleSearchEnquiryId = (e) => {
    setSearchByEnqiuryId(e.target.value);
    if (e.target.value === "") {
      window.location.reload();
    }
  };
  const handleSearch = (id) => {
    if (id === 1) {
      setSearchItem(searchKeyName);
    } else if (id === 2) {
      setSearchPhone(searchKeyNumber);
    } else if (id === 3) {
      setSearchEnquiryId(searchByEnqiuryId);
    }
  };
  const getTotalEnquiries = async () => {
    
    try {
      getEnquiryStatistics().then((res) => {

        setEnquiryStats(res.data);
      });
    } catch (error) {}
  };
  useEffect(() => {
   

    loadAssignedUsers();
   
    loadLocations();
    getTotalEnquiries()
  }, []);

  const loadAssignedUsers = async () => {
    try {
      getAgentData(false).then((res) => {
        let users = [
          { id: 0, label: "All", value: "ALL" },
          { id: 1, label: "Not Assigned", value: "NOT_ASSIGNED" },
        ];
        const data = res.data.users
          .map((val, i) => {
            return { id: i, value: val.code, label: val.name };
          })
          .sort((a, b) => {
            return a.label.localeCompare(b.label);
          });
        users.push.apply(users, data);
        setAssignedUser((s) => ({ ...s, list: users }));
      });
    } catch (error) {}
  };
  useEffect(() => {
    if (reload) {
      setDataLoaded(false);
      getTotalEnquiries();
      getData(
        offset,
        selectedPageFilter,
        searchItem,
        searchPhone,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        selectedPaymentModeFilter,
        selectedIntentFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedSourceIds,
        selectedLocationIds
      );
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (!pageData && !allData) return;

    if (searchItem === "") {
      setReload(true);
    } else {
      getData(
        offset,
        selectedPageFilter,
        searchItem,
        searchPhone,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        selectedPaymentModeFilter,
        selectedIntentFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedSourceIds,
        selectedLocationIds
      );
    }
  }, [searchItem]);

  useEffect(() => {
    if (!pageData && !allData) return;

    if (searchPhone === "") {
      setReload(true);
    } else {
      getData(
        offset,
        selectedPageFilter,
        searchItem,
        searchPhone,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        selectedPaymentModeFilter,
        selectedIntentFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedSourceIds,
        selectedLocationIds
      );
    }
  }, [searchPhone]);

  useEffect(() => {
    if (!pageData && !allData) return;

    if (searchEnquiryId === "") {
      setReload(true);
    } else {
      getData(
        offset,
        selectedPageFilter,
        searchItem,
        searchPhone,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        selectedPaymentModeFilter,
        selectedIntentFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedSourceIds,
        selectedLocationIds,
        searchEnquiryId
      );
    }
  }, [searchEnquiryId]);

  const loadAllTask = async (code) => {
    setLoader(true);
    let posData = {
      pageNo: pageNo,
      pageSize: 10,
      taskCategory: isToday ? "TODAY" : "MISSED",
    };
    // const response = await getTask(posData, code);
    // if (response.status == 200) {
    //   setLoader(false);
    //   setTodayCount(response.data.todayTaskCount);
    //   setMissedCount(response.data.missedTaskCount);
    //   setTaskData(response.data.tasks);
    //   if (isToday) {
    //     setCount(response.data.todayTaskCount);
    //   } else {
    //     setCount(response.data.missedTaskCount);
    //   }
    // } else {
    //   console.log("===🔥🔥🔥🔥count🔥🔥🔥🔥=======>", count);
    //   if (isToday) {
    //     setCount(0);
    //   } else {
    //     setCount(0);
    //   }
    // }
    setLoader(false);
  };

  const testPushNotification = async () => {
    const response = await getTestPushNotification();
  };
  const loadMoreTask = async (pageNo) => {
    let posData = {
      pageNo: pageNo,
      pageSize: 10,
      taskCategory: isToday ? "TODAY" : "MISSED",
    };
    const res = await getTask(posData, assignedAgentCode);

    if (res.status == 200) {
      let count = 0;
      if (isToday) {
        setCount(res.data.todayTaskCount);
        count = res.data.todayTaskCount;
      } else {
        setCount(res.data.missedTaskCount);
        count = res.data.missedTaskCount;
      }
      if (count) {
        let response = taskData.concat(res.data.tasks);
        setTaskData(response);
      }
    } else {
      if (isToday) {
        setCount(0);
      } else {
        setCount(0);
      }
    }
  };

  const fetchMoreData = () => {
    if (taskData.length >= count) {
      setHasMore(false);
      return;
    }
    setPageNo(pageNo + 1);
    loadMoreTask(pageNo + 1);
  };

  const loadFilteredData = async (
    searchItem,
    searchPhone,
    statusIds,
    assignedTo,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    paymentModeFilter,
    intentFilter,
    followUpFromDate,
    followUpToDate,
    treatmentIds,
    sourceIds,
    locationIds
  ) => {
    
    const enquiryFilterFromDate = moment(enquiryFromDate)
      .format()
      .split("+")[0];
    const enquiryFilterToDate = moment(enquiryToDate).format().split("+")[0];
    const followupFilterFromDate = moment(followUpFromDate)
      .format()
      .split("+")[0];
    const followupFilterToDate = moment(followUpToDate).format().split("+")[0];

    const response = await getScan(
      0,
      selectedPageFilter,
      statusIds,
      searchItem,
      searchPhone,
      assignedTo,
      enquiryDateFilter,
      enquiryFilterFromDate,
      enquiryFilterToDate,
      followUpDateFilter,
      paymentModeFilter,
      intentFilter,
      followupFilterFromDate,
      followupFilterToDate,
      treatmentIds,
      sourceIds,
      locationIds
    );

    if (response) {
      const data = response.data.enquiries;
      setTotalCount(response.data.count);
      setPageData(data);
      setPageCount(Math.ceil(response.data.count / selectedPageFilter));
    }
  };

  const refreshTable = () => {
    setDataLoaded(true);
    getData(
      offset,
      selectedPageFilter,
      searchItem,
      searchPhone,
      selectedStatusIds,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      selectedPaymentModeFilter,
      selectedIntentFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds
    );
    getTotalEnquiries();
    setDataLoaded(false);
  };

  const openInNewTab = (enquiryData) => {
    var newWindow = window.open(
      `mykareEnquiryDetail/${enquiryData.enquiryId}`,
      "_blank"
    );
    newWindow.state = {
      statusColorFinder: statusColorFinder(),
      enquiryDetails: enquiryData,
      offset,
      selectedStatuses,
      selectedStatusIds,
      selectedTreatmentIds,
      selectedTreatments,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      selectedPaymentModeFilter,
      selectedIntentFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      assignedUser,
      selectedSourceIds,
      selectedLocationIds,
    };
  };
  useEffect(() => {
    if (!!pageData) {
      const postData = pageData.map((data, index) => {
        return (
          <div
            key={index}
            className="row pointer scroll"
            onClick={() => {
              setShowMessenger(false);
              handleClickTale(data);
            }}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "-10px",
            }}
          >
            {checkPermission("LEADS_SL_NO_FIELD") && (
              <div className="container col-1 m-0" style={{ width: 70 }}>
                {offset === 0 ? index + 1 : offset * 20 + (index + 1)}
              </div>
            )}
            {checkPermission("LEADS_ENQUIRY_DATE_FIELD") && (
              <div className="container col-1 m-0">
                <ListItem>
                  <ListItemText
                    primary={
                      checkPermission("TRANSFERRED_DATE_VIEW")
                        ? `TD:${data.enquiryDate.split("T")[0]}`
                        : `${data.enquiryDate.split("T")[0]}`
                    }
                  />
                </ListItem>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    marginTop: "-15px",
                  }}
                >
                  {moment(data?.enquiryDate).format("LTS")}
                </p>

                {checkPermission("TRANSFERRED_DATE_VIEW") && (
                  <div>
                    <ListItem style={{ marginTop: "-20px" }}>
                      <ListItemText
                        primary={`ED:${data.createdDate.split("T")[0]}`}
                      />
                    </ListItem>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        marginTop: "-15px",
                      }}
                    >
                      {moment(data?.createdDate).format("LTS")}
                    </p>
                  </div>
                )}
              </div>
            )}
            <div className="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText primary={` ${data.patientName} `} />
              </ListItem>

              <div>
                <OtpVerified
                  imageSrc={data?.isValidated ? verifiedIcon : null}
                  message="OTP Verified!"
                />
              </div>
            </div>
            {checkPermission("LEADS_PHONE_NUMBER_FIELD") && (
              <div class="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                {checkPermission("LEADS_PHONE_NUMBER_FIELD") && (
                  <>
                    {" "}
                    <ListItem>
                      <ListItemText primary={` ${data.phoneNumber}`} />
                    </ListItem>
                    {data.alternateContact && (
                      <p
                        style={{
                          textAlign: "left",
                        }}
                      >{`${data.alternateContact}`}</p>
                    )}
                  </>
                )}
              </div>
            )}

            <div className="container col-1 m-0">
              <ListItem>
                <ListItemText primary={`# ${data.enquiryId}`} />
                <br />
                <button
                  style={{ width: 40, height: 30 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openInNewTab(data);
                  }}
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/722/722216.png"
                    style={{ width: 20, height: 20 }}
                  />
                </button>
              </ListItem>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  maxHeight: 50,
                  minHeight: 40,
                  justifyContent: "center",
                }}
              >
                {data.starLead && (
                  <StarIcon
                    style={{
                      cursor: "pointer",
                      color: "#ffd250",
                      fontSize: "30px",
                    }}
                  />
                )}

                {/* <CountComponent
                  value="Incoming Call"
                  count={data?.countResponse?.incomingCallsCount}
                  color="green"
                />
                <CountComponent
                  value="Outgoing Call"
                  count={data?.countResponse?.outgoingCallsCount}
                  color="red"
                />
                <CountComponent
                  value="Repeated Enquiry"
                  count={data?.countResponse?.repeatedEnquiriesCount}
                  color="blue"
                />
                <CountComponent
                  value="New message"
                  count={data?.countResponse?.messageCount}
                  color="orange"
                /> */}
              </div>
            </div>

            {checkPermission("LEADS_ASSIGNED_FIELD") && (
              <div className="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>

                <ListItem>
                  <ListItemText
                    primary={` ${
                      data.assignToName ? data.assignToName : "Not Assigned"
                    }`}
                  />
                </ListItem>
              </div>
            )}

            <div className="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText primary={` ${data.scanName}`} />
              </ListItem>
            </div>

            <div class="container col-1 m-0 capitalize">
              <p className={statusColorFinder(data.status)}>
                <ListItem>
                  <ListItemText
                    className="status-bold"
                    primary={data?.status}
                  />
                </ListItem>
              </p>
            </div>

            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText
                  primary={`${
                    data.followUpDate ? data.followUpDate.split("T")[0] : " "
                  }`}
                />
              </ListItem>
            </div>
            {checkPermission("LEAD_COMMENTS_FIELD") && (
              <div className="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>

                <ListItem>
                  <CustomTooltip title={` ${data.notes ? data.notes : ""}`}>
                    <ListItemText
                      primary={` ${data.notes ? data.notes : ""}`}
                      style={{
                        whiteSpace: "nowrap",
                        width: 100,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />
                  </CustomTooltip>
                </ListItem>
              </div>
            )}
            {checkPermission("LEADS_ORIGIN_FIELD") && (
              <div class="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText primary={`${data.source && data.source}`} />
                </ListItem>
              </div>
            )}
            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText primary={` ${data.subLocation}`} />
              </ListItem>
            </div>
            <div class="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText
                    primary={` ${data.price}`}
                  />
                </ListItem>
              </div>
            {/* {checkPermission("LEAD_PAYMENT_FIELD") && (
              <div class="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText
                    primary={` ${data.paymentMode ? data.paymentMode : ""}`}
                  />
                </ListItem>
              </div>
            )} */}
            {checkPermission("LEAD_INTENT_FIELD") && (
              <div className="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>

                <ListItem>
                  <ListItemText
                    primary={` ${data.intent ? data.intent : ""}`}
                  />
                </ListItem>
              </div>
            )}
            <hr />
          </div>
        );
      });
      setHomeData(postData);
    }
  }, [pageData]);

  useEffect(() => {
    // loadFilteredData(
    //   searchItem,
    //   searchPhone,
    //   selectedStatusIds,
    //   assignedUser.selectedCode,
    //   selectedEnquiryDateFilter,
    //   searchEnquiryFromDate,
    //   searchEnquiryToDate,
    //   selectedFollowupDateFilter,
    //   searchFollowupFromDate,
    //   searchFollowupToDate,
    //   selectedTreatmentIds,
    //   selectedSourceIds,
    //   selectedLocationIds
    // );
  }, [selectedPageFilter]);

  useEffect(() => {
   
    if (isClear) {
      getData(
        offset,
        selectedPageFilter,
        searchItem,
        searchPhone,
        selectedStatusIds,
        "ALL",
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        selectedPaymentModeFilter,
        selectedIntentFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedSourceIds,
        selectedLocationIds
      );
    }
  }, [isClear]);

  const getData = async (
    offset,
    selectedPageFilter,
    searchItem,
    searchPhone,
    selectedStatusIds,
    assignedTo,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    paymentModeFilter,
    intentFilter,
    followUpFromDate,
    followUpToDate,
    selectedTreatmentIds,
    selectedSourceIds,
    selectedLocationIds,
    searchEnquiryId
  ) => {
       
    getScan(
      offset,
      selectedPageFilter,
      selectedStatusIds,
      searchItem,
      searchPhone,
      assignedTo,
      enquiryDateFilter,
      enquiryFromDate,
      enquiryToDate,
      followUpDateFilter,
      paymentModeFilter,
      intentFilter,
      followUpFromDate,
      followUpToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds,
      searchEnquiryId
    )
      .then((res) => {
        const data = res.data.enquiries;
        setTotalCount(res.data.count);
        setPageCount(Math.ceil(res.data.count / selectedPageFilter));
        setPageData(data);
        setAllData(data);
      })
      .catch((error) => {})
      .finally(() => {
        setDataLoaded(true);
      });
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage);
    if (selectedPage != offset) {
      setReload(true);
      setDataLoaded(false);
    }
  };

  function handleClickTale(scanData) {
    
    history.push({
      pathname: `/scanDetail/categoryContextId:${scanData.categoryContextId}/userTxnCode:${scanData.enquiryId}/` ,
      state: {
        statusColorFinder: statusColorFinder(),
        enquiryDetails: scanData,
        offset,
        selectedStatuses,
        selectedStatusIds,
        selectedTreatmentIds,
        selectedTreatments,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        selectedPaymentModeFilter,
        selectedIntentFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        assignedUser,
        selectedSources,
        selectedLocations,
        selectedSourceIds,
        selectedLocationIds,
        selectedAssignedTo,
        selectedAssignedToIds,
      },
    });
  }

  const classes = useTableStyles();
  const menuClasses = useStyles();
  const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

  useEffect(() => {
    loadAllTask(assignedAgentCode);
  }, [isToday]);

  
  const loadAllUpcomingTask = async () => {
    const response = await getUpcomingTask();
    if (response.status == 200) {
      let data = response.data.map((val) => {
        return `(Time: ${moment(val.dateTime).format(
          "hh:mm a"
        )} -  EnquiryId: ${val.enquiryId}  -  Patient Name: ${
          val.userName
        }${` ${
          val.leadAssignedTo ? "- Assigned-To : " + val.leadAssignedTo : ""
        }`})`;
      });
      setMarquee((s) => ({ ...s, upComingTask: data }));
    }
  };

  const handleCloseClockIn = () => {
    setPopupOn(false);
  };

  const getWorkingTime = async () => {
    const response = await getTotalWorkingTime();
    if (response.status == 200) {
      const hour = getWorkingHours(response.data.totalWorkingTime);
      if (true) {
        setTotalTime(hour);
      } else {
        setTotalTime(false);
      }
      console.log("===🔥🔥🔥🔥workingTime🔥🔥🔥🔥=======>", hour);
    }
  };

  const saveBreak = async () => {
    const postData = {
      breakType: lastBreakType,
    };
    const response = await saveUserBreaks(postData);
    if (response.status == 200) {
      setOpen(false);
      setIsOnBreak(false);
    }
  };

  const saveActivity = async () => {
    const response = await saveUserActivity();
    if (response.status == 200) {
      setIsClockIn(!isClockIn);
    } else {
      setIsClockIn(isClockIn);
    }
    checkUserStatus();
  };

  const checkUserStatus = async () => {
    const response = await getUserStatus();
    if (response.status == 200) {
      if (response.data.lastBreakType == "IDEAL") {
        setOpen(false);
        setIsOnBreak(false);
      } else {
        setIsOnBreak(response.data.onBreak);
        setOpen(response.data.onBreak);
      }
      setIsClockIn(response.data.activityStatus);
      setLastBreak(response.data.lastBreakType);
      setCheckedInTime(
        moment(response?.data?.checkInTime).format("DD-MM-YYYY hh:mm a")
      );
      setCheckedOutTime(
        moment(response?.data?.checkOutTime).format("DD-MM-YYYY hh:mm a")
      );
    }
  };

  return {
    getWorkingTime,
    checkInTime,
    checkOutTime,
    handleCloseClockIn,
    setPopupOn,
    popUpon,
    totalCount,
    offset,
    selectedPageFilter,
    classes,
    menuClasses,
    homeData,
    pageCount,
    dataLoaded,
    setDataLoaded,
    handleClickTale,
    handlePageClick,
    enquiryStats,
    selectStatus,
    assignedUser,
    searchItem,
    enquiryDateFilter,
    handleSearchItemChange,
    handleAssignedToChange,
    selectStatus,
    filterLoaded,
    selectedEnquiryDateFilter,
    setSelectedEnquiryDateFilter,
    selectedFollowupDateFilter,
    setSelectedFollowupDateFilter,
    selectedPaymentModeFilter,
    setSelectedPaymentModeFilter,
    selectedIntentFilter,
    setSelectedIntentFilter,
    searchFollowupToDate,
    setSearchFollowupToDate,
    searchFollowupFromDate,
    setSearchFollowupFromDate,
    searchEnquiryFromDate,
    setSearchEnquiryFromDate,
    searchEnquiryToDate,
    setSearchEnquiryToDate,
    setReload,
    refreshTable,
    followUpDateFilter,
    selectedStatuses,
    handleSelectStatuses,
    statusData,
    selectedStatusIds,

    handleSelectTreatments,
    handleAssignedAgents,
    treatmentData,
    selectedTreatmentIds,
    selectedTreatments,
    searchPhone,
    handleSearchPhone,
    searchEnquiryId,
    handleSearchEnquiryId,
    sourceData,
    selectedSourceIds,
    selectedSources,
    setSelectedSources,
    handleSelectSources,
    locationData,
    selectedLocationIds,
    selectedLocations,
    setSelectedLocations,
    handleSelectLocations,

    selectedPageFilter,
    setSelectedPageFilter,
    pageFilters,
    getDropdownButtonLabel,
    setSelectedTreatments,
    setSelectedStatuses,
    perPage,
    clearAllFilter,
    paymentModeFilter,
    intentFilter,
    showMessenger,
    loadAllTask,
    taskData,
    fetchMoreData,
    hasMore,
    taskCount,
    monthList,
    selectedAssignedTo,
    setSelectedAssignedTo,
    isToday,
    setIsToday,
    testPushNotification,
    missedCount,
    todayCount,
    setPageNo,
    isloader,
    setIsClockIn,
    isOnBreak,
    isClockIn,
    open,
    handleClose,
    handleClickOpen,
    saveActivity,
    totalTime,
    assignedAgent,
    handleSearch,
    setAssignedAgent,
    assignedAgentCode,
    setAssignedAgentCode,
    loadAssignedUsers,
    marquee,
  };
};
export default useScan;
