import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import { getAuthHeader } from "../../store/DataStore";
export const getAdDetails = async (adId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/enquiry/adDetails?adId=${adId}`,
    {
      headers,
    }
  );
  return response;
};
