import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ListItem, ListItemText, Tooltip } from "@material-ui/core";
import {
  addCenter,
  deleteCenterById,
  getAllCenters,
} from "../../../services/mykare/centerService";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { checkPermission } from "../../../util/permissionUtils";
import { saveEventActivityLogs } from "../../../util/EventActivity";
const useHospital = () => {
  let history = useHistory();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      marginTop: 10,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));

  const classes = useStyles();

  function handleClickTale(centerData) {
    history.push({
      pathname: "/hospitalDetail",
      state: { centerData: centerData },
    });
  }

  const deleteCenter = async (centerId) => {
    if (window.confirm("Are you sure you want to delete ?")) {
      const data = await deleteCenterById(centerId);
      if (data.status === 200) {
        saveEventActivityLogs('Delete Hospital')
        loadAllCenters();
      }
    }
  };

  const [homeData, setHomeData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [pageData, setPageData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(20);
  const [reload, setReload] = useState(false);

  const tooltipStyles = {
    tooltip: {
      fontSize: "20px",
      borderRadius: "18px",
      boxShadow: "0 20px 80px 0",
      backgroundColor: "#FFFFE0",
      color: "black",
    },
  };

  const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

  useEffect(() => {
    loadAllCenters();
  }, []);

  useEffect(() => {
    if (!!pageData) {
      const postData = pageData.map((data, index) => {
        return (
          <div
            // key={index}
            className="row pointer"
            // onClick={() => handleClickTale(data)}
          >
            <div
              key={index}
              className="row pointer"
              style={{ display: "flex" }}
            >
              <div className="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText primary={`${data.name}`} />
                </ListItem>
              </div>
              <div className="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText primary={`${data.location?.name}`} />
                </ListItem>
              </div>
              <div className="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText primary={`${data.contactName}`} />
                </ListItem>
              </div>
              <div className="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText primary={`${data.contactNumber}`} />
                </ListItem>
              </div>
              <div className="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText primary={`${data.address}`} />
                </ListItem>
              </div>
              <div className="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText primary={`${data.googleMapLink}`} />
                </ListItem>
              </div>
              <div className="container col-2 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText
                    primary={`${data.specialities.map((data, index) => {
                      return data.name;
                    })}`}
                  />
                </ListItem>
              </div>
              <div className="container col-2 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText
                    primary={`${data.treatments.map((data, index) => {
                      return " " + data.name;
                    })}`}
                  />
                </ListItem>
              </div>
              <div className="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                <ListItem>
                  <ListItemText
                    primary={`${data.agreementModels.map((data, index) => {
                      return data.type;
                    })}`}
                  />
                </ListItem>
              </div>

              <div className="container col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                {checkPermission("HOSPITAL_VIEW") && (
                  <button
                    style={{ width: 60, backgroundColor: "" }}
                    onClick={() => handleClickTale(data)}
                  >
                    View
                  </button>
                )}

                {checkPermission("HOSPITAL_DELETE") && (
                  <button
                    style={{ width: 60, backgroundColor: "red" }}
                    onClick={() => deleteCenter(data.id)}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>

            <hr />
          </div>
        );
      });
      setHomeData(postData);
    }
  }, [pageData]);

  const loadAllCenters = async () => {
    setDataLoaded(true);
    // const centerData = await getAllCenters(offset, perPage)
    getAllCenters().then((res) => {
      const data = res.data.hospitals;
      setPageCount(Math.ceil(res.count / perPage));

      if (data) {
        setPageData(data);
      }
      setDataLoaded(false);
    });
    // // if (centerData) {
    //   setPageaData(data);
    // // }
  };

  function addHospital() {
    history.push({
      pathname: "/hospitalDetail",
    });
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage);
    if (selectedPage != offset) {
      setReload(true);
    }
  };

  const addNewCenter = async () => {
    if (name && location) {
      const postData = {
        name: name,
        location: location,
        contactName: contactName,
        contactNumber: contactNumber,
        address: address,
        specialities: [],
      };
      const response = await addCenter(postData);

      if (response.status === 200) {
        alert("Added new Center");
        saveEventActivityLogs('Added New Hospital')
        setIsOpen(!isOpen);
        loadAllCenters();
      }
    }
  };

  return {
    handleClickTale,
    addNewCenter,
    homeData,
    setHomeData,
    isOpen,
    setIsOpen,
    dataLoaded,
    setDataLoaded,
    name,
    setName,
    location,
    setLocation,
    contactName,
    setContactName,
    contactNumber,
    setContactNumber,
    address,
    setAddress,
    pageData,
    setPageData,
    addHospital,
    offset,
    perPage,
    handlePageClick,
    pageCount,
    setPageCount,
  };
};

export default useHospital;
