import { useEffect, useState } from "react";
import { getScanDetailsById } from "../../services/mykare/enquiryService";
import { useParams } from "react-router-dom";
import { getEnquiryDetailsById } from "../../services/associate/enquiryService";
import { useLocation, useHistory } from "react-router-dom";
import { getAllDocuments } from "../../services/mykare/documentService";
import { PRESCRIPTION_DOCUMENT } from "../../util/documentUtil";
import { updateEnquiry } from "../../services/mykare/enquiryService";
const useKareplusDetails = () => {
  let history = useHistory();
  const { categoryContextId, userTxnCode } = useParams();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [preferredDate, setPreferredDate] = useState("");
  const [price, setPrice] = useState("");
  const [scanCenterId, setScanCenterId] = useState("");
  const [scanCenterName, setScanCenterName] = useState("");
  const [scanSubCategory, setScanSubCategory] = useState("");
  const [scanType, setScanType] = useState("");
  const [sublocation, setSubLocation] = useState("");

  const [patientName, setPatientName] = useState("");
  const [enquiryId, setEnquiryId] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("");
  const [followingDate, setFollowingDate] = useState("");
  const [origin, setOrigin] = useState("");
  const [userType, setUserType] = useState("");
  const [scanName, setScanName] = useState("");
  const [personDetail, setPersonDetail] = useState({});
  const [enquiryDate, setEnquiryDate] = useState("");
  const [source, setSource] = useState("");
  const [mrp, setMrp] = useState("");
  const [percentageDiscount, setPercentAgeDiscount] = useState("");

  const [paymentStatus, setPaymentStatus] = useState("");

  const [transcationId, setTransactionId] = useState("");
  const [selectedTab, setSelectedTab] = useState({
    isOpen: true,
    isPatientsDocs: false,
    isPatientsDetail: false,
  });

  const [comments, setComments] = useState();
  const [prescriptionFiles, setPrescriptionFiles] = useState([]);

  const handleHideFun = (select) => {
    if (select == 1) {
      setSelectedTab((prev) => ({ isOpen: true }));
    } else if (select == 2) {
      setSelectedTab((prev) => ({ isPatientsDocs: true }));
    } else if (select == 3) {
      setSelectedTab((prev) => ({ isPatientsDetail: true }));
    }
  };

  function back() {
    history.push({
      pathname: "/kareplus",
    });
  }

  const type = "HEALTH_PACKAGE";
  const getUserScanDetails = async () => {
    const response1 = await getScanDetailsById(
      categoryContextId,
      type,
      userTxnCode
    );
    const response2 = await getEnquiryDetailsById(userTxnCode, type);
    if (response2.data) {
      setPatientName(response2.data.patientName);
      if (response2.data?.healthPackageEnquiryResponse?.userType == "SELF") {
        setLocation(
          response2.data.healthPackageEnquiryResponse?.personDetail?.city
        );
        setPersonDetail({
          personPhoneNumber:
            response2.data.healthPackageEnquiryResponse?.personDetail
              ?.personPhoneNumber,
          personName:
            response2.data.healthPackageEnquiryResponse?.personDetail
              ?.personName,
          relationship:
            response2.data.healthPackageEnquiryResponse?.personDetail
              ?.relationship,
          address1:
            response2.data?.healthPackageEnquiryResponse?.personDetail
              ?.address1,
          address2:
            response2.data?.healthPackageEnquiryResponse?.personDetail
              ?.address2,
          dob: response2.data.healthPackageEnquiryResponse?.personDetail?.dob,
          pinCode:
            response2.data.healthPackageEnquiryResponse?.personDetail?.pinCode,
        });
      } else {
        setPersonDetail({
          relationship:
            response2.data.healthPackageEnquiryResponse?.personDetail
              ?.relationship,
          address1: response2.data?.healthPackageEnquiryResponse?.address1,
          address2: response2.data?.healthPackageEnquiryResponse?.address2,
          personPhoneNumber:
            response2.data.healthPackageEnquiryResponse?.phoneNumber,
          personName: response2.data.healthPackageEnquiryResponse?.name,
        });
        setLocation(response2.data.healthPackageEnquiryResponse?.city);
      }
      setPaymentStatus(response2.data.paymentStatus);
      setPhoneNumber(response2.data.phoneNumber);
      setEnquiryId(response2.data.enquiryId);
      setAssignTo(response2.data.assignToName);
      setStatus(response2.data.status);
      setFollowingDate(response2.data.followUpDate);
      setOrigin(response2.data.source);
      setScanName(
        response2.data.healthPackageEnquiryResponse?.healthPackageName
      );
      setEnquiryDate(response2.data.enquiryDate);
      setPrice(response2?.data?.healthPackageEnquiryResponse?.price);
      setUserType(response2?.data?.healthPackageEnquiryResponse?.userType);
    }
  };

  const loadDocuments = (contextId, context, category, setFiles) => {
    // debugger;
    getAllDocuments(contextId, context, category)
      .then((res) => {
        console.log("ressdeff", res);
        setFiles(res.data);
        setPrescriptionFiles(res.data);
      })
      .catch((error) => {})
      .finally(() => {
        // setDataLoaded(true);
      });
  };

  useEffect(() => {
    getUserScanDetails();
    if (categoryContextId) {
      loadDocuments(
        categoryContextId,
        "KARE_SCAN_DOC",
        "scan_doc",
        setPrescriptionFiles
      );
    }
  }, []);

  const saveEnquiryData = async (postData) => {
    try {
      const data = await updateEnquiry(postData);
      const response = { ...data };
      if (response.status === 200) {
        // getEnquiryDetails();
        alert("Successfully Updated");

        setComments("");
      }
      return response;
    } catch (error) {
      console.log("error", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert("Error: " + error.response.data.message);
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  };

  const postStatus = () => {
    const posData = {
      userTxnId: userTxnCode,
      notes: comments,
      statusId: 1,
    };
    saveEnquiryData(posData);
  };

  return {
    enquiryDate,
    patientName,
    phoneNumber,
    enquiryId,
    assignTo,
    scanName,
    status,
    followingDate,
    origin,
    location,
    preferredDate,
    price,
    scanCenterName,
    scanSubCategory,
    scanType,
    source,
    back,
    mrp,
    percentageDiscount,
    paymentStatus,
    transcationId,
    selectedTab,
    handleHideFun,
    comments,
    setComments,
    prescriptionFiles,
    postStatus,
    userType,
    personDetail,
  };
};

export default useKareplusDetails;
