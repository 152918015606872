import React from "react";
import "./BasicDetailsPopup.css";
import BasicDetailsForm from "./BasicDetailsForm";

function BasicDetailsPopup(props) {
  const { patientId, onclose} = props;

  return (
    <div className="BasicDetailsPopup">
      <div className="d-flex justify-content-between ">
        <h2>Basic Details Form</h2>
        <button className="BasicDetailsPopup-closeBtn" onClick={onclose}>
          ❌
        </button>
      </div>
      <div className="BasicDetailsPopup-inner">
        {patientId && (
          <BasicDetailsForm
            onClick={onclose}
            patientId={patientId}
            {...props}
          />
        )}
      </div>
    </div>
  );
}

export default BasicDetailsPopup;

export const MakeACallPopUp = (props) => {
  const { onclose } = props;

  return (
    <div className="BasicDetailsPopup">
      <div className="d-flex justify-content-between ">
        <h2>Make a call</h2>
        <button className="BasicDetailsPopup-closeBtn" onClick={onclose}>
          ❌
        </button>
      </div>
      <div className="BasicDetailsPopup-inner">{props.children}</div>
    </div>
  );
};
