import React, { useState } from "react";
import "./Enquiry.css";
import "../../Styles/Common.css";
import "../../Styles/sideBar.css";
import refreshIcon from "../../assets/refreshIcon.png";
import Sidebar from ".././Sidebar";
import "../../Styles/Pagination.css";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import useEnquiry from "../PreventiveCare/useEnquiry.logic";
import * as ReactBootstrap from "react-bootstrap";
import AddLead from "../../components/AddLead/AddLead";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import { Label } from "@material-ui/icons";
import TopBar from "../Enquiry/TopBar";
import DownloadPanel from "../../components/DownloadPanel/DownloadPanel";
import {
  MenuProps,
  useStyles,
  specialities,
  qualifications,
} from "../../components/multi-select";
import {
  List,
  ListItemText,
  MenuItem,
  Select,
  Checkbox,
  ListItemIcon,
} from "@material-ui/core";
import { checkPermission } from "../../util/permissionUtils";
import CustomLoader from "../../components/CustomLoader";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes";

function Enquery() {
  const state = useEnquiry();
  const [showAddLead, setShowAddLead] = useState(false);
  const [showDownloadPanel, setShowDownloadPanel] = useState(false);
  const border = {
    width: "100%",
    height: "100vh",
    overflow: "scroll",
  };

  if (!state.dataLoaded) {
    return <CustomLoader />;
  }
  return (
    <div style={border}>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ width: "150%", padding: 10 }}>
          <div className="sticky">
            <div className="main-subContainer">
              <TopBar />

              <div className="row border1">
                <div className="col cor1">
                  <b>
                    <p className="size1">
                      {state.enquiryStats?.totalEnquiries}
                    </p>
                  </b>
                  <b>
                    <p className="colour">Total Enquiries</p>
                  </b>
                </div>

                <div className="col cor2">
                  <b>
                    <p className="size1">
                      {state.enquiryStats?.totalEnquiryFollowupCount}
                    </p>
                  </b>
                  <b>
                    <p className="colour">Total Followups</p>
                  </b>
                </div>
                <div className="col cor3">
                  <b>
                    <p className="size1">
                      {state.enquiryStats?.todayEnquiryCountNew}
                    </p>
                  </b>
                  <b>
                    <p className="colour">Today's Total</p>
                  </b>
                </div>
                <div className="col cor4">
                  <b>
                    <p className="size1">
                      {state.enquiryStats?.todayEnquiryCountFollowup}
                    </p>
                  </b>
                  <b>
                    <p className="colour">Today's Followup</p>
                  </b>
                </div>
                {checkPermission("LEADS_PARTNERSHIP_STATISTICS") && (
                  <div className="col cor5">
                    <b>
                      <p className="size1">
                        {state.enquiryStats?.totalPartnerShip}
                      </p>
                    </b>
                    <b>
                      <p className="colour">Total Partnerships</p>
                    </b>
                  </div>
                )}
              </div>
            </div>

            <br />
            <br />
            <br />

            <div
              className="row"
              style={{ width: "100%", height: 70, marginLeft: 20 }}
            >
              <button
                style={{ width: 80, height: 50, marginLeft: 20 }}
                onClick={() => {
                  setShowAddLead(false);
                  setShowDownloadPanel(false);
                  state.refreshTable();
                }}
              >
                Refresh
              </button>
            </div>
            {showAddLead ? (
              <AddLead onClicked={(openPopUp) => setShowAddLead(openPopUp)} />
            ) : null}
            {showDownloadPanel ? (
              <DownloadPanel
                onClicked={(openPopUp) => setShowDownloadPanel(openPopUp)}
              />
            ) : null}
            <div
              className="row scroll"
              style={{
                minHeight: "3rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {" "}
                Showing {state.offset * state.perPage} -{" "}
                {(state.offset + 1) * state.perPage < state.totalCount
                  ? (state.offset + 1) * state.perPage
                  : state.totalCount}{" "}
                out of {state.totalCount}
              </div>

              {checkPermission("LEADS_SL_NO_FIELD") && (
                <div className="container col-1 m-0" style={{ width: 70 }}>
                  <b>Sl No.</b>
                </div>
              )}

              {checkPermission("LEADS_ENQUIRY_DATE_FIELD") && (
                <div className="container col-1 m-0">
                  <b>Enquiry Date</b>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 160,
                        height: 36,
                        border: `1px solid #00997c`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {state.selectedEnquiryDateFilter
                        ? state.selectedEnquiryDateFilter
                        : "All"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {state.enquiryDateFilter.map((value, index) => {
                        return (
                          <>
                            <Dropdown.Item
                              key={index}
                              href="#"
                              style={{ width: 160 }}
                              onClick={() => {
                                state.setSelectedEnquiryDateFilter(value);
                                state.setReload(true);
                              }}
                            >
                              {value}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>

                  {state.selectedEnquiryDateFilter === "Choose Date" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 200,
                        borderRadius: 5,
                        marginBottom: 7,
                      }}
                    >
                      <label style={{ marginLeft: 10 }}>From</label>
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchEnquiryFromDate
                            ? state.searchEnquiryFromDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchEnquiryFromDate(date);
                          state.setReload(true);
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  ) : null}
                  {state.selectedEnquiryDateFilter === "Choose Date" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 200,
                        borderRadius: 5,
                      }}
                    >
                      <label style={{ marginLeft: 10 }}>To</label>
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchEnquiryToDate
                            ? state.searchEnquiryToDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchEnquiryToDate(date);
                          state.setReload(true);
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  ) : null}
                </div>
              )}
              <div className="container col-1 m-0">
                <b>Patient Name</b>
                <br />
                <input
                  style={{
                    width: 150,
                    height: 36,
                    border: `1px solid #00997c`,
                    borderRadius: 5,
                    marginTop: 5,
                  }}
                  name="search"
                  placeholder="Search"
                  value={state.searchItem}
                  onChange={state.handleSearchItemChange}
                />
              </div>
              {checkPermission("LEADS_PHONE_NUMBER_FIELD") && (
                <>
                  <div className="container col-1 m-0">
                    <b>Phone Number</b>
                    <br />
                    <input
                      style={{
                        width: 140,
                        height: 36,
                        border: `1px solid #00997c`,
                        borderRadius: 5,
                        marginTop: 5,
                      }}
                      name="phone"
                      placeholder="Search"
                      value={state.searchPhone}
                      onChange={state.handleSearchPhone}
                    />
                  </div>
                </>
              )}

              <div className="container col-1 m-0">
                <b>Enquiry ID</b>
                <br />
                <input
                  style={{
                    width: 140,
                    height: 36,
                    border: `1px solid #00997c`,
                    borderRadius: 5,
                    marginTop: 5,
                  }}
                  name="phone"
                  placeholder="Search"
                  // value={state.searchPhone}
                  // onChange={state.handleSearchPhone}
                />
              </div>

              {checkPermission("LEADS_ASSIGNED_FIELD") && (
                <div className="container col-1 m-0">
                  <b>Assigned To</b>
                  <br />

                  <div
                    style={{
                      width: 120,
                      height: 35,
                      backgroundColor: "white",
                      border: "1px solid  #00997c",
                      borderRadius: 5,
                      marginTop: 8,
                    }}
                  >
                    <ReactMultiselectCheckboxes
                      options={state?.assignedUser?.list}
                      getDropdownButtonLabel={state.getDropdownButtonLabel}
                      value={state.selectedAssignedTo}
                      onChange={(value, event) =>
                        state.handleAssignedAgents(value, event)
                      }
                      setState={state.setSelectedAssignedTo}
                    />
                  </div>

                  {/* {checkPermission("LEADS_ASSIGNED_FIELD") &&
                    state.assignedUser && (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          style={{
                            width: 140,
                            height: 36,
                            border: `1px solid #00997c`,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {state.assignedUser.selected
                            ? state.assignedUser.selected
                            : "All"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            maxHeight: 200,
                            overflowY: "scroll",
                            overflowX: "hidden",
                            width: 150,
                          }}
                        >
                          {state.assignedUser.list.map(
                            ({ name, code }, index) => {
                              return (
                                <>
                                  <Dropdown.Item
                                    key={index}
                                    href="#"
                                    style={{ width: 160 }}
                                    onClick={() => {
                                      state.handleAssignedToChange(code, name);
                                      state.setReload(true);
                                    }}
                                  >
                                    {name}
                                  </Dropdown.Item>
                                </>
                              );
                            }
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )} */}
                </div>
              )}
              <div className="container col-1 m-0">
                <b>Treatment</b>
                <br />
                <Select
                  labelId="mutiple-select-label"
                  placeholder="Choose Treatment"
                  multiple
                  value={state.selectedTreatments}
                  onChange={state.handleSelectTreatments}
                  renderValue={(value) =>
                    value.map((item) => item.name).join(", ")
                  }
                  MenuProps={MenuProps}
                  style={{
                    width: 150,
                    height: 40,
                    borderRadius: 5,
                    paddingLeft: 10,
                    border: `1px solid #00997c`,
                  }}
                >
                  <MenuItem
                    key="0"
                    value={0}
                    classes={{
                      root: state.isAllTreatment
                        ? state.menuClasses.selectedAll
                        : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{
                          indeterminate: state.menuClasses.indeterminateColor,
                        }}
                        checked={state.isAllTreatment}
                        indeterminate={
                          state.selectedTreatments.length > 0 &&
                          state.selectedTreatments.length <
                            state.selectedTreatments.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: state.menuClasses.selectAllText }}
                      primary="All"
                    />
                  </MenuItem>
                  {state.treatmentData.map((treatment) => (
                    <MenuItem key={treatment.id} value={treatment}>
                      <ListItemIcon>
                        <Checkbox
                          checked={
                            state.selectedTreatmentIds.indexOf(treatment.id) >
                            -1
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary={treatment.name} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="container col-1 m-0">
                <b>Status</b>
                <br />
                {/* <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 160,
                      height: 36,
                      border: `1px solid #00997c`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.selectStatus.selected ?  state.selectStatus.selected : "All" }
                  </Dropdown.Toggle>
                  <Dropdown.Menu   style={{
                            maxHeight: 200,
                            overflowY: "scroll",
                            overflowX: "hidden",
                            width: 150,
                          }}>
                    {state.selectStatus.list.map(({name},value, index) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 160 }}
                            onClick={() =>
                              state.handleSelectStatusChange(name)
                            }
                          >
                            {name}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown> */}
                <Select
                  labelId="mutiple-select-label"
                  placeholder="Choose Status"
                  multiple
                  value={state.selectedStatuses}
                  onChange={state.handleSelectStatuses}
                  renderValue={(value) =>
                    value.map((item) => item.name).join(", ")
                  }
                  MenuProps={MenuProps}
                  style={{
                    width: 150,
                    height: 40,
                    borderRadius: 5,
                    paddingLeft: 10,
                    border: `1px solid #00997c`,
                  }}
                >
                  <MenuItem
                    key="0"
                    value={0}
                    classes={{
                      root: state.isAllStatus
                        ? state.menuClasses.selectedAll
                        : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{
                          indeterminate: state.menuClasses.indeterminateColor,
                        }}
                        checked={state.isAllStatus}
                        indeterminate={
                          state.selectedStatuses.length > 0 &&
                          state.selectedStatuses.length <
                            state.selectedStatuses.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: state.menuClasses.selectAllText }}
                      primary="All"
                    />
                  </MenuItem>
                  {state.statusData.map((status) => (
                    <MenuItem key={status.id} value={status}>
                      <ListItemIcon>
                        <Checkbox
                          checked={
                            state.selectedStatusIds.indexOf(status.id) > -1
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary={status.name} />
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className="container col-1 m-0">
                <b>FollowUp Date</b>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 150,
                      height: 36,
                      border: `1px solid #00997c`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.selectedFollowupDateFilter
                      ? state.selectedFollowupDateFilter
                      : "All"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {state.followUpDateFilter.map((value, index) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 160 }}
                            onClick={() => {
                              state.setSelectedFollowupDateFilter(value);
                              state.setReload(true);
                            }}
                          >
                            {value}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                {state.selectedFollowupDateFilter === "Choose Date" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #00997c",
                      width: 160,
                      height: 40,
                      borderRadius: 5,
                      marginBottom: 7,
                    }}
                  >
                    <label style={{ marginLeft: 10 }}>From</label>
                    <DatePicker
                      className="mySearchEnquiryUpDatePicker followUpdate"
                      selected={
                        state.searchFollowupFromDate
                          ? state.searchFollowupFromDate
                          : new Date()
                      }
                      onChange={(date) => {
                        state.setSearchFollowupFromDate(date);
                        state.setReload(true);
                      }}
                      //maxDate={new Date()}
                    />
                  </div>
                ) : null}
                {state.selectedFollowupDateFilter === "Choose Date" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 160,
                        height: 40,
                        borderRadius: 5,
                      }}
                    >
                      <label style={{ marginLeft: 10 }}>To</label>
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchFollowupToDate
                            ? state.searchFollowupToDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchFollowupToDate(date);
                          state.setReload(true);
                        }}
                        //maxDate={new Date()}
                      />
                    </div>
                  </>
                ) : null}
              </div>

              <div className="container col-1 m-0">
                <b>Comments</b>
              </div>

              <div className="container col-1 m-0">
                <b>Location</b>
              </div>
              <div className="container col-1 m-0">
                <b>Payment Mode</b>
              </div>
            </div>
            <hr />
            <List component="nav" aria-label="mailbox folders">
              {state.homeData}
            </List>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={state.pageCount}
              initialPage={state.offset}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={state.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={state.offset}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Enquery;
