import React from "react";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import "react-datepicker/dist/react-datepicker.css";
import useAppointment from "./useAppointment.logic";
import IpDetail from "./IpDetail/IpDetail";
import { consultationMode } from "../../../util/getEnum";
import OpDetail from "./OpDetail/OpDetail";

function AppointmentDetail(props) {
  const {
    userTrxnId,
    detailId,
    centerData,
    doctorData,
    callBackFn,
    treatment,
    handleHide,
    patientId,
    locationData,
  } = props;
  const state = useAppointment({ patientId, userTrxnId });
  return (
    <div>
      <div className="row">
        <div className="col-3">
          <button
            className={state.ipCheckBox ? "active-class" : "disabled-class"}
            style={{ padding: 10, width: 180 }}
            onClick={() => state.handleCheckBox(1)}
          >
            IP
          </button>
        </div>
        <div className="col-3">
          <button
            className={state.opCheckBox ? "active-class" : "disabled-class"}
            style={{ padding: 10, width: 180 }}
            onClick={() => state.handleCheckBox(2)}
          >
            OP
          </button>
        </div>
        {state.opCheckBox ? (
          <OpDetail
            treatment={treatment}
            userTrxnId={userTrxnId}
            handleHide={handleHide}
            detailId={detailId}
            centerData={centerData}
            doctorData={doctorData}
            locationData={locationData}
            callBackFn={callBackFn}
            patientDetail={state.patientDetail}
          />
        ) : null}
      </div>
      {state.ipCheckBox ? (
        <IpDetail
          treatment={treatment}
          userTrxnId={userTrxnId}
          detailId={detailId}
          handleHide={handleHide}
          centerData={centerData}
          doctorData={doctorData}
          locationData={locationData}
          callBackFn={callBackFn}
          patientDetail={state.patientDetail}
          patientAadhar={state.aadhaarFiles}
        />
      ) : null}
    </div>
  );
}

export default AppointmentDetail;
