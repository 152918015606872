import { useState, useEffect } from "react";
import { getUserTrxnStatuses } from "../../services/mykare/enquiryService";
import moment from "moment";
import {
  getCallSchedules,
  setScheduleAutoCall,
  stopAutoCall,
} from "../../services/mykare/callService";
import { getAgentData } from "../../services/commonService/commonService";

function useAutoCall(...props) {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [status, setStatus] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [scheduleStatus, setScheduleStatus] = useState(null);
  const [assignedUser, setAssignedUser] = useState({
    list: [],
    selected: null,
    selectedCode: null
  });

  useEffect(() => {
    loadStatuses();
    getCallSchedule();
    loadAssignedUsers();
  }, []);

  const loadStatuses = async () => {
    try {
      getUserTrxnStatuses().then((res) => {
        setStatusList(res.data);
      });
    } catch (error) {}
  };

  const handleStatus = (value) => {
    setStatus(value);
  };

  const handleAssignedToChange = (assignedCode, assignedName) => {
    setAssignedUser((s) => ({ ...s, selected: assignedName, selectedCode: assignedCode }));
  }

  const loadAssignedUsers = async () => {
    try {
      getAgentData(true).then((res) => {
        setAssignedUser((s) => ({ ...s, list:  res.data.users }));
      });
    } catch (error) {

    }
  };

  const scheduleAutoCall = () => {
    const startDate = moment(fromDate).format().split("T")[0];
    const endDate = moment(toDate).format().split("T")[0];

    setScheduleAutoCall(startDate, endDate, status?.id,assignedUser.selectedCode).then((res) => {
    
        if (res?.status === 200) {
            alert("Auto Call Started Now !");
        }
    });
  };

  const stopAutoCallSchedule = () => {
    console.log("=======stopAutoCall======");
    stopAutoCall().then((res) => {
        if (res.status == 200) {
            alert("Auto Call will be stopped !");
        }
    });
  };

  const getCallSchedule = () => {
    getCallSchedules().then((res) => {
      setScheduleStatus(res.data.scheduleStatus);
      setFromDate(res.data.fromDate);
      setToDate(res.data.toDate);
      setStatus(res.data.status);
    });
  };

  return {
    fromDate,
    setFromDate,
    toDate,
    status,
    setStatus,
    handleStatus,
    setToDate,
    statusList,
    scheduleAutoCall,
    scheduleStatus,
    setScheduleStatus,
    stopAutoCall,
    stopAutoCallSchedule,
    assignedUser,
    handleAssignedToChange,
  };
}

export default useAutoCall;
