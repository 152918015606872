export const handleStatusType = (status) => {
  switch (status) {
    case "OPD Scheduled":
      return "OP";
    case "IPD Scheduled":
      return "IP";
    case "IPD Done":
      return "IP";
    case "OPD Done":
      return "OP";
    default:
      return;
  }
};
