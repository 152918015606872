import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getAllCenters, getAllQualifications, getAllSpecialisations } from '../../../services/mykare/centerService';
import { addDoctor } from '../../../services/mykare/doctorsService';
import { useStyles } from "../../../components/multi-select";
import { getCenters } from '../../../services/mykare/dataService';
import { saveEventActivityLogs } from '../../../util/EventActivity';


const useAddDoctor = () => {

    const routeParams = useLocation();
    const [homeData, setHomeData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    let history = useHistory();
    const classes = useStyles();
    //Doctors const
    const [doctorName, setDoctorName] = useState('')
    const [experience, setExperience] = useState('')
    //   const [centerId, setCenterId] = useState(null)
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [selectedQualifications, setSelectedQualifications] = useState([]);
    const [qualificationIds, setSelectedQualificationIds] = useState([]);
    const [qualificationData, setQualificationData] = useState([]);
    const isAllSelectedQualification = qualificationData.length > 0 && selectedQualifications.length === qualificationData.length;

    const [selectedDoctorSpecialities, setSelectedDoctorSpecialities] = useState([]);
    const [doctorSpecialityIds, setSelectedDoctorSpecialityIds] = useState([]);
    const [specialityDoctorData, setDoctorSpecialityData] = useState([]);
    const isAllSelectedDoctorSpecialities = specialityDoctorData.length > 0 && selectedDoctorSpecialities.length === specialityDoctorData.length;
    const [editableMode, setEditableMode] = useState(true);

    useEffect(() => {
        loadAllCenters();
        loadAllQualifications();
        loadAllDoctorSpecialisations();

    }, [])

    const [centerData, setCenterData] = useState([]);

    useEffect(() => {
        loadCenters()
    }, [])

    const loadCenters = async () => {
        try {
            getCenters().then((res) => {
                setCenterData(res.data);
            });
        } catch (error) {

        }
    };


    const [pageData, setPageData] = useState([]);
    const [pageDoctorData, setPageDoctorData] = useState([]);

    const loadAllCenters = async () => {
        const data = await getAllCenters();
        if (data) {
            setPageData(data);
        }
    }

    const loadAllQualifications = async () => {
        const data = await getAllQualifications();
        if (data) {
            setQualificationData(data);
        }
    }

    const loadAllDoctorSpecialisations = async () => {
        const data = await getAllSpecialisations();
        if (data) {
            setDoctorSpecialityData(data);
        }
    }

    const handleChangeQualifications = (event) => {

        const value = event.target.value;
        if (value[value.length - 1].id === 0) {
            setSelectedQualifications(selectedQualifications.length === qualificationData.length ? [] : qualificationData);
            const ids = qualificationData.map(item => item.id);
            setSelectedQualificationIds(selectedQualifications.length === qualificationData.length ? [] : ids);
            return;
        }
        const ids = value.map(item => item.id);
        const isAllSelected = ids.includes(0);
        setSelectedQualificationIds(ids);
        setSelectedQualifications(value);
    }

    const handleChangeDoctorSpecialities = (event) => {

        const value = event.target.value;
        if (value[value.length - 1].id === 0) {
            setSelectedDoctorSpecialities(selectedDoctorSpecialities.length === specialityDoctorData.length ? [] : specialityDoctorData);
            const ids = specialityDoctorData.map(item => item.id);
            setSelectedDoctorSpecialityIds(selectedDoctorSpecialities.length === specialityDoctorData.length ? [] : ids);
            return;
        }
        const ids = value.map(item => item.id);
        const isAllSelected = ids.includes(0);
        setSelectedDoctorSpecialityIds(ids);
        setSelectedDoctorSpecialities(value);
    }

    const addNewDoctor = async () => {
        const postData = {
            centerId: selectedCenter.id,
            name: doctorName,
            specialityIds: doctorSpecialityIds,
            qualificationIds: qualificationIds,
            experience: experience,
        }
        const response = await addDoctor(postData);
        if (window.confirm("Are you sure you want to add doctor ! ")) {
            if (response.status === 200) {   
              saveEventActivityLogs('Added New Doctor')
              setIsOpen(!isOpen)
              setEditableMode(false);
            }
        }    
    }

    function back() {
        history.push({
            pathname: "/hospital",
        });
    }

    return {
        doctorName,
        experience,
        selectedQualifications,
        qualificationIds,
        qualificationData,
        isAllSelectedQualification,
        selectedDoctorSpecialities,
        doctorSpecialityIds,
        specialityDoctorData,
        isAllSelectedDoctorSpecialities,
        selectedQualifications,
        homeData,
        editableMode,
        setEditableMode,
        setDoctorName,
        setExperience,
        setHomeData,
        handleChangeQualifications,
        handleChangeDoctorSpecialities,
        loadAllQualifications,
        loadAllDoctorSpecialisations,
        addNewDoctor,
        pageData,
        pageDoctorData,
        back,
        classes,
        selectedCenter,
        setSelectedCenter,
        centerData,
        setCenterData
    }
}

export default useAddDoctor