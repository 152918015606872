import React, { useState, useEffect } from "react";
import {
  getLoanDetailApi,
  saveLoanDetailApi,
} from "../../../services/mykare/loanDetailService";


const useLoanDetails = ({userTxnId, loanDetailsId,callBackFn}) => {
  const [loanDetails, setLoanDetails] = useState({
    loanId: null,
    loanType: "EMI",
    loanStatus: "APPROVED",
    amount: null,
    comment: "",
  });
  const [editableMode, setEditableMode] = useState(true);
  const [loanDetailId, setLoanDetailId] = useState(loanDetailsId);

  useEffect(() => {
    if(loanDetailId) {
      getLoanDetail();
    }
   
  }, [loanDetailId]);

  const saveLoanDetail = async () => {
    const postData = {
      loanId: loanDetails.loanId,
      loanType: loanDetails.loanType,
      loanStatus: loanDetails.loanStatus,
      amount: loanDetails.amount,
      loanDetailId: loanDetailId,
      comment: loanDetails.comment,
      userTxnId: userTxnId,
    };
    try {
      const response = await saveLoanDetailApi(postData);
      if (response.status === 200) {
        alert("Saved Successfully");
        if (response.data) {
         setLoanDetailId(response.data.loanDetailId);
         callBackFn(response.data.loanDetailId)
        }
        setEditableMode(false);
      }
    } catch (error) {

    }
  };

  const getLoanDetail = async () => {
   try {
    const response = await getLoanDetailApi(loanDetailId);
    if (response.status === 200) {
      if (response.data) {
        setLoanDetailId(response.data.loanDetailId)
        setLoanDetails({
          loanId: response.data.loanId,
          loanStatus: response.data.loanStatus,
          loanType: response.data.loanType,
          amount: response.data.amount,
          comment: response.data.comment,
        });
        callBackFn(response.data.loanDetailId);
      }
      setEditableMode(false);
    }
   } catch (error) {

   }
  };

  return {
    saveLoanDetail,
    loanDetails,
    setLoanDetails,
    editableMode,
    setEditableMode,
  };
};

export default useLoanDetails;
