import React from "react";
import "../../Enquiry/Enquiry.css";
import Sidebar from "../../../Pages/Sidebar";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import useMykareEnquiry from "./useKaretripEnquiryDetail.logic";
import { statusColor } from "../../../util/statusColorFinder";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { getAdditionalInfo } from "../../../util/getAdditionalInfo";
import { checkPermission } from "../../../util/permissionUtils";
import PatientsDetail from "../../Karetrip/PatientDetail/PatientsDetail";
import VIL from "./Tabs/VILTab/VIL";
import Arrival from "./Tabs/ArrivalTab/Arrival";
import PatientDoc from "./Tabs/PatietDocTab/PatientDoc";
import AdditionalInfoDetails from "./Components/AdditionalInfo";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";

import {
  Box,
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

const MykareEnquiryDetail = () => {
  const state = useMykareEnquiry();
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));

  const [isOpen, setIsOpen] = useState(false);
  const [newService, setNewService] = useState({});

  console.log("=================d", isOpen);

  const classes = useStyles();
  return (
    <div className="d-flex">
      <Sidebar />

      <div className={classes.root}>
        <button className="width1" onClick={() => state.back()}>
          BACK
        </button>
        <hr />
        <div>
          <div className="row">
            <div class="col-md-1">
              <p className="text-start">EnquiryId</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{`${state.data.enquiryId}`}</div>
              </p>
            </div>
            {state.data.enquiryDate &&
              checkPermission("LEADS_ENQUIRY_DATE_FIELD") && (
                <div className="col-md-1">
                  <p className="text-start">Enquiry Date</p>
                  <div className="ed-txt-1 text-start">
                    <div className="textColor">
                      {state.data?.enquiryDate.split("T")[0]}
                    </div>
                  </div>
                </div>
              )}
            <div class="col-md-1">
              <p className="text-start">PatientName</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{state.data.patientName}</div>
              </p>
            </div>
            <div class="col-md-1">
              <p className="text-start ">Age</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{state.data.age}</div>
              </p>
            </div>
            <div class="col-md-1">
              <p className="text-start ">Gender</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{state.data.gender}</div>
              </p>
            </div>
            {checkPermission("CLICK_TO_CALL_BTN") && (
              <div class="col-md-1">
                <p className="text-start ">Phone Number</p>
                <p className="ed-txt-1 text-start">
                  {checkPermission("LEADS_PHONE_NUMBER_FIELD") && (
                    <div className="textColor">
                      {state.data.countryCode + state.data.phoneNumber}
                      {state.data.alternateContact
                        ? ` /${
                            state.data.countryCode + state.data.alternateContact
                          }`
                        : ""}
                    </div>
                  )}
                </p>
              </div>
            )}
            <div class="col-md-1">
              <p className="text-start ">Type</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{` ${state.data.type}`}</div>
              </p>
            </div>

            <div class="col-md-1">
              <p className="text-start ">Destination</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{` ${state.data.location}`}</div>
              </p>
            </div>

            {checkPermission("LEADS_ORIGIN_FIELD") && (
              <div class="col-md-1">
                <p className="text-start">Source</p>
                <p className="ed-txt-1 text-start">
                  <div className="textColor">{`${state.data.source}`}</div>
                </p>
              </div>
            )}
            <div className="col-md-1 capitalize">
              <p className="text-start ">Status</p>
              <p
                className={statusColor(state.status)}
                style={{ textAlign: "left" }}
              >
                {state.status ? state.status : ""}
              </p>
            </div>
            <div class="col-md-1">
              <p className="text-start">Email</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{`${state.data.email}`}</div>
              </p>
            </div>
          </div>
          <hr />

          <div className="row">
            {state.data.type === "Hospital" && (
              <>
                <div className="col-3">
                  <p className="mb-0 mt-4 text-start">Hospital Name</p>
                  <div className="ed-txt-2 mb-4 text-start">
                    <div className="textColor">
                      {state.data.hospital ? state.data.hospital : "N/A"}
                    </div>
                  </div>
                </div>
              </>
            )}

            {state.data.type === "Doctor" && (
              <>
                <div className="col-1">
                  <p className="mb-0 mt-4 text-start">Doctor Name</p>
                  <div className="ed-txt-2 mb-4 text-start">
                    <div className="textColor">
                      {state.data.doctor ? state.data.doctor : "N/A"}
                    </div>
                  </div>
                </div>
              </>
            )}

            <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen} />

            {
              <>
                <div className="col-1">
                  <p className="mb-0 mt-4 text-start">Treatment Name</p>
                  <div className="ed-txt-1 mb-4 text-start">
                    <div className="textColor">
                      {state.data.treatment ? state.data.treatment : "N/A"}
                    </div>
                  </div>
                </div>

                <div className="col-2">
                  <p className="mb-0 mt-4 text-start">Department Name</p>
                  <div className="ed-txt-1 mb-4 text-start">
                    <div className="textColor">
                      {state.data.department ? state.data.department : "N/A"}
                    </div>
                  </div>
                </div>

                {state.data.additionalInfo && (
                  <div className="col-2">
                    <p className="mb-0 mt-4 text-start">Additional Info</p>
                    <div className="ed-txt-1 mb-4 text-start">
                      <div className="textColor">
                        <AdditionalInfoDetails
                          additionalInfo={state?.data?.additionalInfo}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-2">
                  <p className="mb-0 mt-4 text-start">Origin</p>
                  <div className="ed-txt-1 mb-4 text-start">
                    <div className="textColor">
                      {state.data.source ? state.data.source : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <p className="mb-0 mt-4 text-start">CTA Label</p>
                  <div className="ed-txt-1 mb-4 text-start">
                    <div className="textColor">
                      {state.data.ctaLabel ? state.data.ctaLabel : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <p className="mb-0 mt-4 text-start">URL</p>
                  <div className="ed-txt-1 mb-4 text-start">
                    <div className="textColor">
                      {state.data.url ? state.data.url : "N/A"}
                    </div>
                  </div>
                </div>

                <div className="row col-12 d-inline-flex justify-content-start align-items-center">
                  <div className="col-1">
                    <h5 className="mb-0 mt-4 text-start">Description</h5>
                    <div className="ed-txt-1 mb-4 text-start">
                      <div
                        className="textColor"
                        style={{
                          overflowWrap: "break-word",
                        }}
                      >
                        {state?.data.description
                          ? state?.data.description
                          : "N/A"}
                      </div>
                    </div>
                  </div>

                  <div className="col-1" onClick={() => setIsOpen(true)}>
                    <div
                      className="row d-inline-flex justify-content-center align-items-center bg-success "
                      onClick={() => setIsOpen(true)}
                    >
                      <p
                        style={{
                          margin: 0,
                          textAlign: "center",
                          lineHeight: "36px",
                          color: "white",
                          cursor: "pointer",
                        }}
                      >
                        Add New Service
                      </p>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
          {state.surgeryDetail && (
            <div className="row">
              <div className="col-3">
                <p className="mb-0 mt-4 text-start">Treatment Name</p>
                <div className="ed-txt-2 mb-4 text-start">
                  <div className="textColor">
                    {state.handleTreatmentName(state.surgeryDetail)}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <p className="mb-0 mt-4 text-start">Category</p>
                <div className="ed-txt-2 mb-4 text-start">
                  <div className="textColor">
                    {state.surgeryDetail.category
                      ? state.surgeryDetail.category
                      : state.surgeryDetail.otherCategory}
                  </div>
                </div>
              </div>

              <div className="col-3">
                <p className="mb-0 mt-4 text-start">Location</p>
                <div className="ed-txt-2 mb-4 text-start">
                  <div className="textColor">
                    {state.surgeryDetail.location}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <p className="mb-0 mt-4 text-start">Sub Location</p>
                <div className="ed-txt-2 mb-4 text-start">
                  <div className="textColor">
                    {state.surgeryDetail.subLocation}
                  </div>
                </div>
              </div>
            </div>
          )}

          {state.enquiryDetail && (
            <div className="row">
              <div className="col-3">
                <p className="mb-0 mt-4 text-start">Treatment Name</p>
                <div className="ed-txt-2 mb-4 text-start">
                  <div className="textColor">
                    {state.handleTreatmentName(state.enquiryDetail)}
                  </div>
                </div>
              </div>

              <div className="col-3">
                <p className="mb-0 mt-4 text-start">Location</p>
                <div className="ed-txt-2 mb-4 text-start">
                  <div className="textColor">
                    {state.enquiryDetail.location}
                  </div>
                </div>
              </div>

              {state.enquiryDetail.circle && (
                <div className="col-3">
                  <p className="mb-0 mt-4 text-start">Circle</p>
                  <div className="ed-txt-2 mb-4 text-start">
                    <div className="textColor">
                      {state.enquiryDetail.circle}
                    </div>
                  </div>
                </div>
              )}

              <div className="col-3">
                <p className="mb-0 mt-4 text-start">Hospital</p>
                <div className="ed-txt-2 mb-4 text-start">
                  <div className="textColor">{state.enquiryDetail.center}</div>
                </div>
              </div>

              {state.enquiryDetail.additionalInfo && (
                <>
                  <div className="col-3">
                    <p className="mb-0 mt-4 text-start">Additional Info</p>
                    <div className="ed-txt-2 mb-4 text-start">
                      <div className="textColor">
                        {getAdditionalInfo(state.enquiryDetail.additionalInfo)}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {state.smoDetails && (
            <div>
              <div className="row">
                <div className="col-3">
                  <p className="mb-0 mt-4 text-start">Reason</p>
                  <div className="ed-txt-2 mb-4 text-start">
                    <div className="textColor">{state.smoDetails.reason}</div>
                  </div>
                </div>

                <div className="col-3">
                  <p className="mb-0 mt-4 text-start">Description</p>
                  <div className="ed-txt-2 mb-4 text-start">
                    <div className="textColor">
                      {state.smoDetails.description}
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          )}
          {state.preventiveCareDetail && (
            <div className="row">
              <div className="col-3">
                <p className="mb-0 mt-4 text-start">Packages</p>
                <div className="ed-txt-2 mb-4 text-start">
                  <div className="textColor">
                    {state.preventiveCareDetail.packages}
                  </div>
                </div>
              </div>

              <div className="col-3">
                <p className="mb-0 mt-4 text-start">Location</p>
                <div className="ed-txt-2 mb-4 text-start">
                  <div className="textColor">
                    {state.preventiveCareDetail.location}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <p className="mb-0 mt-4 text-start">Sub Location</p>
                <div className="ed-txt-2 mb-4 text-start">
                  <div className="textColor">
                    {state.preventiveCareDetail.subLocation}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className="row">
            {state.data.repeatedUserTxns?.length !== 0 &&
              state.data.repeatedUserTxns !== null && (
                <div className="col-3">
                  <b className="mb-0 mt-4 text-start">Repeated Enquiry</b>
                  <div className="ed-txt-2 mb-4 text-start">
                    <div
                      style={{
                        width: 400,
                        height: 150,
                        border: "1px solid gray",
                      }}
                    >
                      <div
                        style={{ width: "100%", height: 40, display: "flex" }}
                      >
                        <div
                          style={{
                            width: 192,
                            height: 40,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "baseline",
                            border: "1px solid black",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          <p
                            style={{
                              color: "black",
                              fontWeight: 700,
                              marginTop: 7,
                              paddingLeft: 10,
                            }}
                          >
                            Source
                          </p>
                        </div>
                        <div
                          style={{
                            width: 208,
                            height: 40,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            border: "1px solid black",
                            backgroundColor: "#d3d3d3",
                          }}
                        >
                          <p
                            style={{
                              color: "black",
                              fontWeight: 700,
                              marginTop: 20,
                              paddingLeft: 10,
                            }}
                          >
                            Date
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          maxHeight: 108,
                          display: "flex",
                          overflowY: "scroll",
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            height: "100%",
                            border: "1px solid gray",
                          }}
                        >
                          {state.data.repeatedUserTxns?.map((val) => {
                            return (
                              <p
                                style={{
                                  textAlign: "left",
                                  fontSize: 14,
                                  paddingLeft: 10,
                                }}
                              >
                                {val.source}
                              </p>
                            );
                          })}
                        </div>
                        <div
                          style={{
                            width: "50%",
                            height: "100%",
                            border: "1px solid gray",
                          }}
                        >
                          {state.data.repeatedUserTxns?.map((val) => {
                            return (
                              <p
                                style={{
                                  textAlign: "left",
                                  fontSize: 14,
                                  paddingLeft: 10,
                                }}
                              >
                                {val.createdDate}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <hr />
          </div> */}
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: 80,
            }}
          >
            {checkPermission("ENQUIRY_UPDATE_SUB_TAB") && (
              <div className="col-lg-1 col-6">
                <button
                  className={
                    state.selectedTab.isOpen ? "active-class" : "disabled-class"
                  }
                  style={{ padding: 10, width: 180 }}
                  onClick={() => state.handleHideFun(1)}
                >
                  Enquiry Update
                </button>
              </div>
            )}
            {checkPermission("PATIENT_DETAIL_TAB") && (
              <div className="col-lg-1  col-6">
                <button
                  className={
                    state.selectedTab.isPatientsDetail
                      ? "active-class"
                      : "disabled-class"
                  }
                  style={{ padding: 10, width: 180 }}
                  onClick={() => state.handleHideFun(7)}
                >
                  Patient Detail
                </button>
              </div>
            )}

            <div className="col-lg-1 col-6">
              <button
                className={
                  state.selectedTab.isVIL ? "active-class" : "disabled-class"
                }
                style={{ padding: 10, width: 180 }}
                onClick={() => state.handleHideFun(8)}
              >
                VIL
              </button>
            </div>
            <div className="col-lg-1 col-6">
              <button
                className={
                  state.selectedTab.isArrival
                    ? "active-class"
                    : "disabled-class"
                }
                style={{ padding: 10, width: 180 }}
                onClick={() => state.handleHideFun(9)}
              >
                Arrival
              </button>
            </div>
            <div className="col-lg-1 col-6">
              <button
                className={
                  state.selectedTab.isPatientsDocs
                    ? "active-class"
                    : "disabled-class"
                }
                style={{ padding: 10, width: 180 }}
                onClick={() => state.handleHideFun(6)}
              >
                Patient Docs
              </button>
            </div>
            {/* {checkPermission("INSURANCE_SUB_TAB") && (
              <div className="col-lg-1 col-6">
                <button
                  className={
                    state.selectedTab.isInsurance
                      ? "active-class"
                      : "disabled-class"
                  }
                  style={{ padding: 10, width: 180 }}
                  onClick={() => state.handleHideFun(2)}
                >
                  Insurance
                </button>
              </div>
            )} */}
            {/* {checkPermission("LOAN_DETAIL_SUB_TAB") && (
              <div className="col-lg-1  col-6">
                <button
                  className={
                    state.selectedTab.isEmi ? "active-class" : "disabled-class"
                  }
                  style={{ padding: 10, width: 180 }}
                  onClick={() => state.handleHideFun(3)}
                >
                  Loan Detail
                </button>
              </div>
            )} */}
            {/* {checkPermission("APPOINTMENTS_SUB_TAB") && (
              <div className="col-lg-1  col-6">
                <button
                  className={
                    state.selectedTab.isAppointment
                      ? "active-class"
                      : "disabled-class"
                  }
                  style={{ padding: 10, width: 180 }}
                  onClick={() => state.handleHideFun(4)}
                >
                  Appointments
                </button>
              </div>
            )} */}
            {/* {checkPermission("CAB_DETAIL_SUB_TAB") && (
              <div className="col-lg-1  col-6">
                <button
                  className={
                    state.selectedTab.isCabDetail
                      ? "active-class"
                      : "disabled-class"
                  }
                  style={{ padding: 10, width: 180 }}
                  onClick={() => state.handleHideFun(5)}
                >
                  Cab Detail
                </button>
              </div>
            )} */}
            {/* {checkPermission("PATIENT_DOCS_SUB_TAB") && (
              <div className="col-lg-1  col-6">
                <button
                  className={
                    state.selectedTab.isPatientsDocs
                      ? "active-class"
                      : "disabled-class"
                  }
                  style={{ padding: 10, width: 180 }}
                  onClick={() => state.handleHideFun(6)}
                >
                  Patient Docs
                </button>
              </div>
            )} */}
          </div>
          <hr />
          {state.selectedTab.isOpen ? (
            <div>
              <div>
                <Row style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Col xl={2}>
                    <h5>Status</h5>
                    <div>
                      {" "}
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          style={{
                            width: 320,
                            height: 36,
                            border: `1px solid gray`,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {state.status ? state.status : "Status"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          as={CustomMenu}
                          style={{
                            maxHeight: 200,
                            overflowY: "scroll",
                            overflowX: "hidden",
                            width: 320,
                          }}
                        >
                          {state.statusList.map((value, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                href="#"
                                style={{ width: 330, maxHeight: 200 }}
                                onClick={() =>
                                  state.handleStatus(value.name, value.id)
                                }
                              >
                                {value.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>

                  <Col xl={2}>
                    <h5 style={{ marginBottom: -2 }}>Follow Up</h5>
                    <DatePicker
                      className="myDatePicker"
                      selected={state.followUpDate ? state.followUpDate : null}
                      onChange={(date) => state.setFollowUpDate(date)}
                      minDate={new Date()}
                    />
                  </Col>

                  {/* {state.showEnquiryActionFields() ? (
                    <Col xl={2}>
                      <h5>Transfer To</h5>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            style={{
                              width: 200,
                              border: `1px solid gray`,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {state.selectedTransferTo
                              ? state.selectedTransferTo
                              : "Transfer"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {state.centerData
                              ? state.centerData.map((value, index) => {
                                  return (
                                    <Dropdown.Item
                                      key={index}
                                      href="#"
                                      style={{ width: 200 }}
                                      onClick={() =>
                                        state.handlePick(value.name, value.id)
                                      }
                                    >
                                      {value.name}
                                    </Dropdown.Item>
                                  );
                                })
                              : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                  ) : null} 

                  


                  <>
                    {state?.data?.type == "Enquiry" &&
                      (checkPermission("ENQUIRY_UPDATE_TREATMENT") ||
                        state.sourceId == 11 ||
                        state.sourceId == 15 ||
                        state.sourceId == 16 ||
                        state.sourceId == 20) && (
                        <Col xl={2}>
                          <h5>Treatment</h5>
                          <div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="none"
                                style={{
                                  width: 200,
                                  height: 36,
                                  border: `1px solid gray`,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {!!state.selectedTreatment
                                  ? state.selectedTreatment.name
                                  : "Treatment"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                as={CustomMenu}
                                style={{
                                  maxHeight: 200,
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  width: 260,
                                }}
                              >
                                {state.treatments.map((value, index) => {
                                  return (
                                    <Dropdown.Item
                                      key={index}
                                      href="#"
                                      style={{ width: 200, maxHeight: 200 }}
                                      onClick={() =>
                                        state.setselectedTreatment(value)
                                      }
                                    >
                                      {value.name}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                      )}
                    {state?.data?.type == "Enquiry" &&
                      (checkPermission("ENQUIRY_UPDATE_LOCATION") ||
                        state.sourceId == 11 ||
                        state.sourceId == 15 ||
                        state.sourceId == 16 ||
                        state.sourceId == 20) && (
                        <Col xl={2}>
                          <h5>Location</h5>
                          <div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="none"
                                style={{
                                  width: 200,
                                  height: 36,
                                  border: `1px solid gray`,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {!!state.selectedLocation
                                  ? state.selectedLocation.name
                                  : "Location"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                as={CustomMenu}
                                style={{
                                  maxHeight: 200,
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  width: 260,
                                }}
                              >
                                {state.locations.map((value, index) => {
                                  return (
                                    <Dropdown.Item
                                      key={index}
                                      href="#"
                                      style={{ width: 200, maxHeight: 200 }}
                                      onClick={() =>
                                        state.setSelectedLocation(value)
                                      }
                                    >
                                      {value.name}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                      )}

                    {/* <Col xl={2}>
                      <h5>Payment Type</h5>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            style={{
                              width: 200,
                              height: 36,
                              border: `1px solid gray`,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {!!state.selectedPaymentType
                              ? state.selectedPaymentType
                              : "Payment Type"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {state.ipPaymentModes.map((value, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  href="#"
                                  style={{ width: 200 }}
                                  onClick={() =>
                                    state.setSelectedPaymentType(value)
                                  }
                                >
                                  {value}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col> */}

                  <Col xl={2}>
                    <h5>Intent</h5>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          className={statusColor(state.selectedIntent)}
                          variant="none"
                          style={{
                            width: 200,
                            height: 36,
                            border: `1px solid gray`,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {!!state.selectedIntent
                            ? state.selectedIntent
                            : "Intent"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {state.intentModes.map((value, index) => {
                            return (
                              <Dropdown.Item
                                className={statusColor(value)}
                                key={index}
                                href="#"
                                style={{ width: 200 }}
                                onClick={() => state.setSelectedIntent(value)}
                              >
                                {value}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>

                  <Col xl={2}>
                    <h5>Treatment</h5>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          style={{
                            width: 350,
                            height: 36,
                            border: `1px solid gray`,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {state.selectedTreatment
                            ? state.selectedTreatment.name
                            : state.data.treatment}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          as={CustomMenu}
                          style={{
                            maxHeight: 200,
                            overflowY: "scroll",
                            overflowX: "hidden",
                            width: 350,
                          }}
                        >
                          {state.treatments.map((value, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                href="#"
                                style={{ width: 350, maxHeight: 200 }}
                                onClick={() =>
                                  state.setselectedTreatment(value)
                                }
                              >
                                {value.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>

                  {checkPermission("ENQUIRY_UPDATE_ASSIGNED") ? (
                    <Col xl={2}>
                      <h5>Assign To</h5>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            style={{
                              width: 200,
                              border: `1px solid gray`,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {!!state.assignedAgent
                              ? state.assignedAgent
                              : "Assign To"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {state.userData.map((value, id) => {
                              return (
                                <Dropdown.Item
                                  key={id}
                                  href="#"
                                  style={{ width: 200 }}
                                  onClick={() =>
                                    state.handleToAssigned(
                                      value.name,
                                      value.code
                                    )
                                  }
                                >
                                  {value.name}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </div>
              <div className="marginleft border-top" style={{ marginTop: 20 }}>
                <div>
                  <div className="row border-top pt-4">
                    <div class="col-lg-6 col-10">
                      <textarea
                        className="comments-section"
                        rows={5}
                        value={state.comments ? state.comments : null}
                        onChange={(e) => state.setComments(e.target.value)}
                        placeholder="Comments"
                      ></textarea>
                    </div>
                    {checkPermission("ENQUIRY_UPDATE_BTN") && (
                      <div class="col-1">
                        <div>
                          <button
                            style={state.buttonStyle}
                            onClick={() => {
                              // state.handleClickOpen();
                              state.postStatus();
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-5 col-12">
                      {state.selectedTab.isOpen ? (
                        <>
                          {" "}
                          {state.status !== "New Lead" && (
                            <div
                              style={{
                                width: "100%",
                                height: 360,
                                backgroundColor: "white",
                                overflowX: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              {state.usersHistory
                                ? state.usersHistory.map(
                                    (activities, index) => {
                                      return (
                                        <div
                                          style={{
                                            backgroundColor: "#EFEFEF",
                                            padding: 30,
                                            borderRadius: 10,
                                            marginTop: 10,
                                            marginRight: 10,
                                          }}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <h6 style={{ color: "#242F9B" }}>
                                              {activities.addedBy}
                                            </h6>
                                            <p
                                              style={{
                                                marginLeft: 10,
                                                marginBottom: 6,
                                              }}
                                            >
                                              Added a comment on
                                            </p>
                                            <p
                                              style={{
                                                marginLeft: 10,
                                                marginBottom: 6,
                                                color: "#242F9B",
                                              }}
                                            >
                                              {
                                                moment(activities.createdDate)
                                                  .format("YYYY-MM-DD HH:mm:ss")
                                                  .split("+")[0]
                                              }
                                            </p>
                                          </div>
                                          <span>{activities.comments} </span>

                                          <span
                                            style={{
                                              color: "#242F9B",
                                            }}
                                          >
                                            {activities.activities.map(
                                              (item, index) => {
                                                return (
                                                  <div
                                                    key={index}
                                                    style={{ display: "flex" }}
                                                  >
                                                    <p>
                                                      {`${item.fieldName} Changed from
                                                      ${item.oldValue} To
                                                      ${item.newValue} `}
                                                    </p>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )
                                : null}
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ color: "green" }}>{state.message}</p>
              </div>
            </div>
          ) : null}

          {/* {state.selectedTab.isCabDetail ? (
            <CabDetail
              userTrxnId={state.data.enquiryId}
              cabDetailsId={state.cabDetailsId}
              callBackFn={state.setCabDetailsId}
            />
          ) : null} */}
          {/* {state.selectedTab.isPatientsDocs ? (
            <PatientsDoc userTrxnId={state.data.enquiryId} />
          ) : null} */}
          {/* {state.selectedTab.isAppointment ? (
            <AppointmentDetail
              userTrxnId={state.data.enquiryId}
              detailId={state.appointmentDetailId}
              callBackFn={state.setAppointmentDetailId}
              centerData={state.centerData}
              doctorData={state.doctorData}
              handleHide={state.handleHideFun}
              treatment={
                state.enquiryDetail &&
                state.handleTreatmentName(state.enquiryDetail)
              }
            />
          ) : null} */}
          {/* {state.activeCheckBoxTwo ? (
            <div>
              <h1>tutyiyuiyu</h1>
            </div>
          ) : null} */}
          {/* {state.selectedTab.isInsurance ? (
            <InsuranceDetail
              userTrxnId={state.data.enquiryId}
              insuranceDetailId={state.insuranceId}
              callBackFn={state.setInsuranceId}
            />
          ) : null} */}
          {/* {state.selectedTab.isEmi ? (
            <LoanDetails
              userTxnId={state.data.enquiryId}
              loanDetailsId={state.loanDetailId}
              callBackFn={state.setLoanDetailId}
            />
          ) : null} */}
          {state.selectedTab.isPatientsDetail ? (
            <PatientsDetail
              patientId={state.data.patientId}
              refreshFn={state.refreshPersonalDetails}
              userTransaxtionId={state?.data?.enquiryId}
            />
          ) : null}

          {state.selectedTab.isVIL && (
            <VIL
              vilDetailId={state?.data?.vilDetailId}
              userTrxnId={state?.data?.enquiryId}
            />
          )}
          {state.selectedTab.isArrival && (
            <Arrival
              arrivalDetailId={state?.data?.arrivalDetailId}
              userTrxnId={state?.data?.enquiryId}
            />
          )}
          {state.selectedTab.isPatientsDocs && (
            <PatientDoc
              patientId={state?.data?.patientId}
              userTrxnId={state?.data?.enquiryId}
            />
          )}
        </div>
      </div>
      <div>
        {/* <Dialog
          open={state?.open}
          onClose={state?.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"md"}
        >
          <DialogTitle>Confirm the date and time</DialogTitle>
          <DialogContent>
            {state.statusId == 19 || state.statusId == 18 ? (
              <OPiPpopup
                label={"Arrival Date Time"}
                statusId={state.statusId}
                date={state.appointmentDetails?.ipDetails?.arrivalDateTime}
                data={state.appointmentDetails}
                setFollowUpDate={state.setAppointmentDetails}
              />
            ) : (
              <OPiPpopup
                label={"Appointment Date Time"}
                statusId={state.statusId}
                data={state.appointmentDetails}
                date={state?.appointmentDetails?.opDetails?.dateTime}
                setFollowUpDate={state.setAppointmentDetails}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={state?.handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={() => {
                state.saveAppointmentDetails();
              }}
              variant="contained"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    </div>
  );
};
export default MykareEnquiryDetail;

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Search Location"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child?.props?.children?.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);
