const tokenProperty = "token";
const userTrxnId = "user-txn-id";
const userRoleProperty = "user-role";
const userCodeProperty = "user-code";
const userNameProperty = "user-name";
const userPermission = "user-permission";

export const isInsuranceAgent = () => {
  return getUserRole() == "INSURANCE_AGENT";
};
export const getUserTxnId = () => {
  const enquiryId = localStorage.getItem(userTrxnId);
  return enquiryId;
};
export const getUserRole = () => {
  const userRole = localStorage.getItem(userRoleProperty);
  return userRole;
};
export const getUserCode = () => {
  const userCode = localStorage.getItem(userCodeProperty);
  return userCode;
};
export const getUserName = () => {
  const userName = localStorage.getItem(userNameProperty);
  return userName;
};
export const getUserPermission = () => {
  const permission = JSON.parse(localStorage.getItem(userPermission));
  return permission;
};
export const getAuthHeader = () => {
  const authHeader = `Bearer ${localStorage.getItem(tokenProperty)}`;
  return authHeader;
};
