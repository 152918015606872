import "./Summary.css";
import React from "react";
import Sidebar from "../Sidebar";
import patients from "./patients.png";
import agents from "./agents.png";
import centers from "./centers.jpg";
import enquiry from "./enquiry.png";
import { Bar, Pie } from "react-chartjs-2";
import { useSummary } from "./useSummary.logic";
import { Chart as ChartJS } from "chart.js/auto";
function Summary() {
  const state = useSummary()
  return (
    <div className="chart-page">
      <div className="d-flex">
        <div className="side-bar">
          <Sidebar />
        </div>
        <div className="main-page ">
          <section className="border p-2 mb-4 d-flex  flex-column main-page-container">
            <nav className="navbar navbar-expand-lg ">
              <div className="input-container">
                {/* <div className="row ">
                  <div className="col-8 ">
                  <form className="form-control">
                  <input placeholder="Search" />
                </form>

                  </div>
                  <div className="col-4 chart-button">
                   <button>Select</button>

                  </div>

                </div>
                */}
              </div>
            </nav>
          </section>

          <h2>Summary</h2> 
          <div className="row ">
            <div className="col-12 col-lg-3 mb-sm-4 mb-3 mb-lg-0">
              <div className="division-one">
                <img src={patients} />
                <h1>{state.data.totalPatients}</h1>
                <p>Total Patients</p>
              </div>
            </div>
            <div className="col-12 col-lg-3 mb-sm-4  mb-3 mb-lg-0">
              <div className="division-two">
                <img src={agents} />
                <h1>{state.data.totalAgents}</h1>
                <p>Total Agents</p>
              </div>
            </div>
            <div className="col-12 col-lg-3 mb-sm-4  mb-3 mb-lg-0">
              <div className="division-three">
                <img src={centers} />
                <h1>{state.data.totalCenters}</h1>
                <p>Total Centers</p>
              </div>
            </div>
            <div className="col-12 col-lg-3 mb-sm-4  mb-3 mb-lg-0">
              <div className="division-four">
                <img src={enquiry} />
                <h1>{state.data.totalEnquiries}</h1>
                <p>Total Enquiries</p>
              </div>
            </div>
          </div>
          <div className="apex-chart-container">
            <div className="row">
              <div className="col-lg-8 col-12">
                <div className="userChart">
                  <Bar
                    data={{
                      labels: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "jun",
                        "july",
                        "Aug",
                      ],
                      datasets: [
                        {
                          label: "MyKare",
                          data: [100, 200, 830, 300, 600, 400, 700, 800],
                          backgroundColor: "#00997c",
                          barThickness: 50,
                          fill: false,
                          borderColor: "blue",
                        },
                        {
                          label: "Partner",
                          data: [200, 400, 530, 100, 600, 200, 400, 700],
                          backgroundColor: "#d0f2ff",
                          barThickness: 50,
                          fill: false,
                          borderColor: "blue",
                        },
                        {
                          label: "Praktice",
                          data: [10, 20, 30, 40, 50, 400, 70, 80],
                          backgroundColor: "#fff7cd",
                          barThickness: 50,
                          fill: false,
                          borderColor: "blue",
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        xAxes: [
                          {
                            ticks: { display: false },
                            gridLines: {
                              color: "red",
                              display: true,
                              drawBorder: false,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: { display: false },
                            gridLines: {
                              display: false,
                              drawBorder: false,
                            },
                          },
                        ],
                      },
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="pie-chart">
                  <Pie
                    data={{
                      labels: ["Mykare", "Partner", "Praktice"],
                      datasets: [
                        {
                          label: "Store 1",
                          data: [600, 200, 10],
                          backgroundColor: [
                            "#00997c",
                            "#d0f2ff",
                            "#fff7cd",
                          ],
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        xAxes: [
                          {
                            ticks: { display: false },
                            gridLines: {
                              color: "red",
                              display: true,
                              drawBorder: false,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: { display: false },
                            gridLines: {
                              display: false,
                              drawBorder: false,
                            },
                          },
                        ],
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
