import React, { Fragment } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Divider, Grid, ListItem, ListItemText } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

function PatientHistory({
  handleClickOpen,
  patientDetails,
  editPatientDetails,
}) {
  return (
    <Grid
      className="row"
      style={{
        width: "100%",
        height: "600px",
        margin: "2px 22px",
      }}
    >
      <Grid
        item
        container
        justifyContent={"start"}
        alignItems={"center"}
        sx={{ height: 50, backgroundColor: "#CAEDFF" }}
      >
        <Grid item lg={1}>
          <ListItemText>Date</ListItemText>
        </Grid>

        <Grid item lg={1}>
          <ListItemText>Enquiry ID</ListItemText>
        </Grid>
        <Grid item lg={1.5}>
          <ListItemText>centerName</ListItemText>
        </Grid>
        <Grid item lg={1.5}>
          <ListItemText>Agent Name</ListItemText>
        </Grid>
        <Grid item lg={1.5}>
          <ListItemText>flightDetails</ListItemText>
        </Grid>
        <Grid item lg={1.5}>
          <ListItemText>Passengers</ListItemText>
        </Grid>
        <Grid item lg={1.5}>
          <ListItemText>preferred City</ListItemText>
        </Grid>
        <Grid item lg={1}>
          <ListItemText>Type</ListItemText>
        </Grid>
      </Grid>
      <Fragment>
        <Grid
          style={{
            height: 700,
            width: "100%",
            overflow: "scroll",
          }}
        >
          {patientDetails?.map((patientData, index) => {
            return (
              <>
                <Grid item container sx={{ height: 50 }} key={index}>
                  <Grid
                    item
                    lg={1}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ListItem>
                      {patientData?.arrivalDate?.split("T")[0]}
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ListItem>{patientData?.id}</ListItem>
                  </Grid>
                  <Grid
                    item
                    lg={1.5}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ListItem>{patientData?.centerName}</ListItem>
                  </Grid>
                  <Grid
                    item
                    lg={1.5}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ListItem>{patientData?.enquiryByAgentName}</ListItem>
                  </Grid>
                  <Grid
                    item
                    lg={1.5}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ListItem>{patientData?.flightDetails}</ListItem>
                  </Grid>
                  <Grid
                    item
                    lg={1.5}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ListItem>{patientData?.numOfPassengers}</ListItem>
                  </Grid>
                  <Grid
                    item
                    lg={1.5}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ListItem>{patientData?.preferredCity}</ListItem>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ListItem>{patientData?.type}</ListItem>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    container
                    gap={"20px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        width: 50,
                        height: 30,
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      bgcolor={"#279EFF"}
                      onClick={() => editPatientDetails(patientData,patientData?.type)}
                    >
                      <DriveFileRenameOutlineIcon fontSize="small" />
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        width: 50,
                        height: 30,
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      bgcolor={"red"}
                      onClick={() => handleClickOpen(patientData)}
                    >
                      <DeleteForeverIcon fontSize="small" />
                    </Grid>{" "}
                  </Grid>
                </Grid>
                <Divider />
              </>
            );
          })}
        </Grid>
      </Fragment>
    </Grid>
  );
}

export default PatientHistory;
