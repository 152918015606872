import React from "react";
import { Tooltip } from "@mui/material";

function OtpVerified({ imageSrc, message }) {
  return (
    <>
      {imageSrc && (
        <div>
          <Tooltip title={message}>
          <img
            src={imageSrc}
            alt="Image"
            style={{
              width: 18,
              height: 18,
              borderRadius: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default OtpVerified;