import axios from "axios";
import { APP_URL } from "./../../util/AppConstants";
import {
  getAuthHeader,
  getUserCode,
  getUserName,
  getUserRole,
} from "../../store/DataStore";

export const saveAppointmentDetail = async (postData) => {
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();

  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/appointment/save`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getAppointmentDetails = async (detailId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/appointment/detail/${detailId}`,
    {
      headers,
    }
  );
  return response;
};

export const getFilteredIpOpEnquiries = async (
  selectedAppointmentDateFilter,
  searchAppointmentFromDate,
  searchAppointmentToDate,
  type,
  perPage,
  offset,
  selectedSourceIds,
  searchItem,
  searchDoctor,
  searchCenter,
  selectedStatusIds,
  selectedTreatmentIds,
  assignedUser,
  searchEnquiryId,
  selectedStatus,
  locationIds,
  selectedLeadStatusIds,
  subStatusId,
  selectedConsultationMode,
  activeType
 
) => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };
  const postData = {
    pageNo: offset,
    perPage:perPage,
    role:role,
    code:code,
    type,
    consultationMode:activeType?selectedConsultationMode:null,
    appointmentDateFilter: selectedAppointmentDateFilter,
    appointmentFromDate: searchAppointmentFromDate,
    appointmentToDate: searchAppointmentToDate,
    patientName: searchItem,
    doctorName: searchDoctor,
    centerName: searchCenter,
    assignedTo: assignedUser.length ? assignedUser : [],
    sourceIds: selectedSourceIds,
    treatmentIds: selectedTreatmentIds,
    enquiryId: searchEnquiryId,
    ipOpStatus: selectedStatus,
    locationIds: locationIds,
    statusIds: selectedLeadStatusIds,
    subStatusId: subStatusId,
    kareBuddyAssignTo: [],
  };
  console.log(selectedTreatmentIds);
  const response = await axios.post(
    `${APP_URL}/mykare/appointment/filtered`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getAllKareBuddy = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/internal/karebuddy/all`, {
    headers,
  });
  return response;
};

export const assignToKareBuddyUser = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.put(
    `${APP_URL}/internal/mykare/assign`,
    postData,
    {
      headers,
    }
  );
  return response;
};

const role = getUserRole();
const code = getUserCode();

export const getIpOpservice = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/appointment/ipOp/statistics`,
    { role, code },
    {
      headers,
    }
  );

  console.log("response -------------- > ".response);
  return response;
};
