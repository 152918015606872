import React, { useEffect } from "react";

//  components>>>>>>>>>>
const StrComponent = ({ head, data }) => {
    return (
        <>
            {head && <h2 className="Popup-title">{head}</h2>}
            <span>{data}</span>
        </>
    );
};
const InnerStrComponent = ({ head, data }) => {
    return (
        <>
            {head && <h2 className="Popup-title">{head}</h2>}
            <p>{data}</p>
        </>
    );
};
const ArrStrComponent = ({ head, data }) => {
    return (
        <>
            {head && <h2>{head}</h2>}
            <ul>
                {data?.map((list, ind) => (
                    <li key={`${ind}pa`}>{list}</li>
                ))}
            </ul>
        </>
    );
};

const ArrObjComponent = ({ data,head }) => {
    return (
        <>
            <h2>{head}</h2>
            {data?.map((list) => {
                return (
                    <>
                        {list.label && <h4>{list.label}</h4>}

                        {typeof list?.description === "string" ? (
                            <InnerStrComponent data={list?.description} />
                        ) : Array.isArray(list?.description) ? (
                            <ArrStrComponent data={list?.description} />
                        ) : (
                            <>no data </>
                        )}
                    </>
                );
            })}
        </>
    );
};

// >>>>>>>>>>>>>>>>>>>> main component <<<<<<<<<<<<<<<<<<<<<<<<<<<<

function PopupTreatmentInfoTemp(props) {
    const { toggle, popup, handletoggle } = props;

    return (
        <div className={!toggle ? "Popup toggle" : "Popup"}>
            <div className="Popup-scroll">
                {/* close button */}
                <button className="Popup-close-btn" onClick={handletoggle}>
                    ❌
                </button>

                {popup?.info.map((list, ind) => {
                    if (typeof list?.description === "string") {
                        return (
                            <StrComponent
                                key={`${ind}:xa`}
                                head={list?.title}
                                data={list?.description}
                            />
                        );
                    } else if (
                        Array.isArray(list?.description) &&
                        typeof list?.description[0] === "string"
                    ) {
                        return (
                            <ArrStrComponent
                                head={list?.title}
                                data={list?.description}
                            />
                        );
                    } else if (
                        typeof list?.description[0] === "object" &&
                        list?.description[0]?.label
                    ) {
                        return <ArrObjComponent  data={list?.description} head = {list.title} />;
                    }
                })}
            </div>
        </div>
    );
}

export default PopupTreatmentInfoTemp;
