import axios from "axios";
import {
  getAuthHeader,
  getUserCode,
  getUserName,
  getUserRole,
} from "../../store/DataStore";
import { APP_URL } from "../../util/AppConstants";
import fileDownload from "js-file-download";

export const getFilteredEnquiries = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/karetrip/enquiry/filtered`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getEnquiryDetailsById = async (enquiryId,type) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/karetrip/enquiry/getUserTrxnById?userTrxnId=${enquiryId}&type=${type}`,
    {
      headers,
    }
  );

  return response;
};

export const getEnquiryDetailBySelectEnquiry = async (userTxnId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/karetrip/enquiry/byDetail?userTxnId=${userTxnId}`,
    {
      headers,
    }
  );
  return response;
};

export const updateEnquiry = async (postData) => {
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.put(
    `${APP_URL}/karetrip/enquiry/update`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getUserTrxnHistory = async (enquiryId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.get(
    `${APP_URL}/mykare/enquiry/getUserTrxnHistory/${enquiryId}`,
    {
      headers,
    }
  );
  return response;
};

export const getKaretripUserTrxnStatuses = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.get(
    `${APP_URL}/karetrip/enquiry/getAllUserTxnStatus`,
    {
      headers,
    }
  );
  return response;
};

export const saveVilDetails = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.post(
    `${APP_URL}/karetrip/vil/detail/save`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const saveKaretripEnquiry = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  if (postData.assignedTo == null) {
    postData.assignedTo = getUserCode();
  }
  const response = await axios.post(
    `${APP_URL}/karetrip/enquiry/saveEnquiry`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const saveArrivalDetails = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.post(
    `${APP_URL}/karetrip/arrival/detail/save`,
    postData,
    {
      headers,
    }
  );
  return response;
};
export const fetchArrivalDetails = async (userTrxnId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.get(
    `${APP_URL}/karetrip/arrival/detail/${userTrxnId}`,
    {
      headers,
    }
  );
  return response;
};
export const fetchVilDetails = async (userTrxnId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.get(
    `${APP_URL}/karetrip/vil/detail/${userTrxnId}`,
    {
      headers,
    }
  );
  return response;
};

export const getAllDocuments = async (contextId, context, category) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.get(
    `${APP_URL}/karetrip/document/all?contextId=${contextId}&context=${context}&category=${category}`,
    {
      headers,
    }
  );
  return response;
};

export const getDownloadkaretripReportData = async (fromDate, toDate) => {
  axios

    .get(
      `${APP_URL}/karetrip/enquiry/downloadReport?fromDate=${fromDate}&toDate=${toDate}&assignedTo=ALL`,
      {
        responseType: "blob",
        headers: {
          Authorization: getAuthHeader(),
        },
      }
    )
    .then((res) => {
      fileDownload(res.data, "Enquiry Report.xlsx");
    });
};

export const getAgentData = async () => {
  try {
    const { data } = await axios.get(
      APP_URL + `/user/all?userRole=INTL_SALES_AGENT`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return data;
  } catch (error) { }
};

export const getExecutivesReports = async () => {
  try {
    const { data } = await axios.get(APP_URL + `/karetrip/enquiry/report`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return data;
  } catch (error) { }
};

export const deleteDocument = async (documentId) => {
  try {
    const response = await axios.delete(
      APP_URL + `/karetrip/document/delete?documentId=${documentId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (error) { }
};

export const getNotifications = async (postData) => {
  postData.role = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.post(
    `${APP_URL}/karetrip/enquiry/notificationData`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getNotificationCount = async () => {
  const assignTo = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/karetrip/enquiry/notificationCount?assignTo=${assignTo}`,
    {
      headers,
    }
  );
  return response;
};
