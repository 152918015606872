import useKaretripDownloadPanel from "./Enquiry/useKaretripDownloadPanel";
import DatePicker from "react-datepicker";

function KaretripDownloadPanel({ ipReport = false, ...props }) {
  const state = useKaretripDownloadPanel(props);
  return (
    <div>
      <hr />
      <div className="row popup-view">
        <div className="col-5">
          <div className="row">
            <div className="col-3">
              From
              <DatePicker
                className="mySearchEnquiryUpDatePicker"
                selected={state.fromDate ? state.fromDate : new Date()}
                onChange={(date) => {
                  state.setFromDate(date);
                  // state.setReload(true);
                }}
                maxDate={new Date()}
              />
            </div>
            <div className="col-3">
              To
              <DatePicker
                className="mySearchEnquiryUpDatePicker"
                selected={state.toDate ? state.toDate : new Date()}
                onChange={(date) => {
                  state.setToDate(date);
                  // state.setReload(true);
                }}
                maxDate={new Date()}
              />
            </div>
            {!ipReport && (
              <div className="col-3">
                Assign To
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 160,
                      height: 36,
                      border: `1px solid #00997c`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    {state.assignedUser.selected
                      ? state.assignedUser.selected
                      : "All"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      maxHeight: 200,
                      overflowY: "scroll",
                      overflowX: "hidden",
                      width: 150,
                    }}>
                    {state.assignedUser.list.map(({ name, code }, index) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 160 }}
                            onClick={() => {
                              state.handleAssignedToChange(code, name);
                            }}>
                            {name}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-1">
          <button
            style={{ padding: 12, width: 80 }}
            onClick={() => props.onClicked(false)}>
            Cancel
          </button>
        </div>

        <div className="col-lg-1">
          <button
            onClick={() => {
              state.downloadReport();
            }}
            style={{ padding: 12, width: 100 }}>
            Download
          </button>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default KaretripDownloadPanel;
