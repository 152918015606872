
import { useEffect, useState } from "react";
import { getTreatments } from "../../../services/mykare/dataService";
import { getAllLocations } from "../../../services/mykare/centerService";
import { getAllPreLeadsTeam } from "../../../services/mykare/PreLeadsService";
import { getSources, savePreLeadEnquiry } from "../../../services/mykare/enquiryService";

function useAddLead(props) {
  const [name, setName] = useState("");
  const [sources, setSources] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [comment, setComment] = useState("");
  const [userData, setUserData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [selectedOrigin, setselectedOrigin] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [preAssignedCode, setPreAssignedCode] = useState(null);
  const [preAssignedAgent, setPreAssignedAgent] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedTreatment, setselectedTreatment] = useState("");

  const countryCodes = [
    { id: 1, name: "+91" },
    { id: 2, name: "+880" },
  ];

  const loadAllPreLeadsTeam = () => {
    getAllPreLeadsTeam(false).then((res)=>{
      setUserData(res?.data?.users)
    })
  }
  useEffect(() => {
    loadAllPreLeadsTeam()
    loadTreatments();
    loadAllLocations();
    getSource();
  }, []);

  const loadTreatments = async () => {
    try {
      getTreatments().then((res) => {
        setTreatments(res.data);
      });
    } catch (error) {}
  };

  const getSource = async () => {
    const response = await getSources();
    if (response.status === 200) {
      if (response.data) {
        setSources(response.data);
      }
    }
  };
  const handleToPreAssigned = (assignTo, assignCode) => {
    setPreAssignedAgent(assignTo);
    setPreAssignedCode(assignCode);
  };

  const loadAllLocations = async () => {
    try {
      getAllLocations().then((data) => {
        setLocations(data);
      });
    } catch (error) {}
  };

  const saveEnquiryDetails = async () => {
    if (name && phoneNumber && selectedSource) {
      let code = countryCode ? countryCode.name : "+91";
      const postData = {
        enquiryContext: "CENTER",
        enquiryContextId: 0,
        isPackage: true,
        name: name,
        sourceId: selectedSource.id,
        packageId: selectedTreatment.id,
        locationId: selectedLocation.id,
        phoneNumber: code + phoneNumber,
        platform: "MYKARE_WEB",
        preLeadAssignedTo: preAssignedCode,
        notes: comment,
        additionalInfo: { notes: comment },
      };
      try {
        const response = await savePreLeadEnquiry(postData);
        if (response.status === 200) {
          alert("New Lead added successfully");
          window.location.reload()
          props.onClicked(false);
        }
      } catch (error) {}
    } else {
      alert("Please fill all the Fields");
    }
  };

  return {
    setName,
    setPhoneNumber,
    saveEnquiryDetails,
    name,
    phoneNumber,
    treatments,
    selectedTreatment,
    selectedLocation,
    setselectedTreatment,
    locations,
    setSelectedLocation,
    origins,
    selectedOrigin,
    setselectedOrigin,
    handleToPreAssigned,
    setPreAssignedCode,
    setPreAssignedAgent,
    preAssignedCode,
    preAssignedAgent,
    userData,
    countryCode,
    countryCodes,
    setCountryCode,
    selectedSource,
    setSelectedSource,
    sources,
    setSources,
    setComment,
  };
}

export default useAddLead;
