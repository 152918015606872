import axios from "axios";
import { getAuthHeader } from "../../store/DataStore";
import { APP_URL } from "../../util/AppConstants";

export const createUser = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.post(`${APP_URL}/user/register`, postData, {
    headers,
  });
  return response;
};
export const getAllUsers = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.get(`${APP_URL}/user/allUsers`, {
    headers,
  });
  return response;
};


