import React from "react";
import "./Reports.css";
import { NavLink } from "react-router-dom";
import Sidebar from "../Sidebar";
import patients from "../Summary/patients.png";
import agents from "../Summary/agents.png";
import centers from "../Summary/centers.jpg";
import enquiry from "../Summary/enquiry.png";
import Dropdown from "react-bootstrap/Dropdown";
import useReport from "./useReport.logic";
import DatePicker from "react-datepicker";
import TopBar from "../Enquiry/TopBar";
import { checkPermission } from "../../util/permissionUtils";

function Reports() {
  const state = useReport();
  const border = {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  };
  const flexStyle = {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <div className="report-container">
      <div className="report-sidebar-container">
        <Sidebar />
      </div>
      <div className="report-main-container">
        <div className="row " style={border}>
          <div style={{ width: "100%", padding: 10 }}>
            <div className="main-subContainer">
              <TopBar />
              <div
                className="row border1  cor2"
                style={{ display: "flex", }}
              >
                <div
                  className="col-12 cor2"
                  style={{ padding: 15, borderRadius: 30 }}
                >
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",

                    }}
                  >
                    <div className="col-md-1" >
                      <h6>Range</h6>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          style={{
                            width: 200,
                            height: 36,
                            border: `1px solid gray`,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {state.selectedRange ? state.selectedRange : "Today"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {state.range.map((value, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                href="#"
                                style={{ width: 200 }}
                                onClick={() =>
                                  state.setSelectedRange(value.name)
                                }
                              >
                                {value.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {state.selectedRange === "Choose Date" && (
                      <div className="col-3">
                        <div className="row" >
                          <div className="col-4" >
                            From
                            <DatePicker
                              className="mySearchEnquiryUpDatePicker"
                              selected={
                                state.fromDate ? state.fromDate : new Date()
                              }
                              onChange={(date) => {
                                state.setFromDate(date);
                                // state.setReload(true);
                              }}
                              maxDate={new Date()}
                            />
                          </div>
                          <div className="col-4" >
                            To
                            <DatePicker
                              className="mySearchEnquiryUpDatePicker"
                              selected={state.toDate ? state.toDate : new Date()}
                              onChange={(date) => {
                                state.setToDate(date);
                                // state.setReload(true);
                              }}
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                      </div>
                    )}


                    {checkPermission('STATISTICS_ASSIGNED_FIELD') &&
                      <div className="col-md-1" style={{ marginLeft: 20 }}>
                        <h6>Assign To</h6>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            style={{
                              width: 200,
                              height: 36,
                              border: `1px solid gray`,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {state.selectedAgentName
                              ? state.selectedAgentName
                              : "All"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {state.assignedUser.list
                              ? state.assignedUser.list.map((value, index) => {
                                return (
                                  <Dropdown.Item
                                    key={index}
                                    href="#"
                                    style={{ width: 200 }}
                                    onClick={() => {
                                      state.setSelectedAgentName(value.name)
                                      if (value.code) {
                                        state.setSelectedAgentCode(value.code)
                                      } else {
                                        state.setSelectedAgentCode(value.name)
                                      }
                                    }
                                    }
                                  >
                                    {value.name}
                                  </Dropdown.Item>
                                );
                              })
                              : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>}

                    <div className="col-md-1">
                      <h6>Location</h6>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          style={{
                            width: 200,
                            height: 36,
                            border: `1px solid gray`,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {state.selectedLocation.name
                            ? state.selectedLocation.name
                            : "ALL"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            maxHeight: 100,
                            overflowY: "scroll",
                            overflowX: "hidden",
                            width: 200,
                          }}
                        >
                          {state.locations.map((value, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                href="#"
                                style={{ width: 200, maxHeight: 200 }}
                                onClick={() => state.setSelectedLocation(value)}
                              >
                                {value.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-md-1">
                      <h6>Treatment</h6>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          style={{
                            width: 200,
                            height: 36,
                            border: `1px solid gray`,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {state.selectedTreatment.name
                            ? state.selectedTreatment.name
                            : "ALL"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            maxHeight: 100,
                            overflowY: "scroll",
                            overflowX: "hidden",
                            width: 200,
                          }}
                        >
                          {state.treatments.map((value, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                href="#"
                                style={{ width: 200, maxHeight: 200 }}
                                onClick={() => state.setselectedTreatment(value)}
                              >
                                {value.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="col-md-1">
                      <h6>Types</h6>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          style={{
                            width: 200,
                            height: 36,
                            border: `1px solid gray`,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {state.selectedType
                            ? state.selectedType
                            : "ALL"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            maxHeight: 100,
                            overflowY: "scroll",
                            overflowX: "hidden",
                            width: 200,
                          }}
                        >
                          {state.type.map((value, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                href="#"
                                style={{ width: 200, maxHeight: 200 }}
                                onClick={() => state.setSelectedType(value)}
                              >
                                {value}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    {checkPermission('STATISTICS_ORIGIN_FIELD') && (
                      <div className="col-md-1">
                        <h6>Origin</h6>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            style={{
                              width: 200,
                              height: 36,
                              border: `1px solid gray`,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {state.selectedSource ? state.selectedSource.name : "All"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            style={{
                              maxHeight: 100,
                              overflowY: "scroll",
                              overflowX: "hidden",
                              width: 200,
                            }}
                          >
                            {state.sources.list
                              ? state.sources.list.map((value, index) => {
                                return (
                                  <Dropdown.Item
                                    key={index}
                                    href="#"
                                    style={{ width: 200, maxHeight: 200 }}
                                    onClick={() => state.setSelectedSource(value)}
                                  >
                                    {value.name}
                                  </Dropdown.Item>
                                );
                              })
                              : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>)}

                    <div
                      className="col-1 cor2"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        borderRadius: 30,
                      }}
                    >
                      <button
                        onClick={() => state.getReportsData()}
                        style={{ width: 100, height: 40, marginTop: 25 }}
                      >
                        Search
                      </button>
                    </div>{" "}
                  </div>
                </div>{" "}
              </div>
            </div>

            <div style={{ padding: 40, marginTop: 30 }}>

              <div
                className="row "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-12 col-lg-2 mb-sm-4 mb-3 mb-lg-0">
                  <div className="division-one-report">
                    <h1>{state.reportData?.totalEnquiry}</h1>
                    <p>Total Leads</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-two-report">
                    <h1>{state.reportData?.totalPendingCount}</h1>
                    <p>Total New Leads</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-three-report">
                    <h1>{state.reportData?.totalJunkCount}</h1>
                    <p>Total Junk</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-four-report">
                    <h1>{state.reportData?.totalFollowupCount}</h1>
                    <p>Total FollowUp</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-five-report">
                    <h1>{state.reportData?.totalDNPCount}</h1>
                    <p>Total DNP</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-six-report">
                    <h1>
                      {state.reportData?.totalNotInterested
                        ? state.reportData?.totalNotInterested
                        : "0"}
                    </h1>
                    <p>Not Interested</p>
                  </div>
                </div>
              </div>
              <div
                className="row "
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <div className="col-12 col-lg-2 mb-sm-4 mb-3 mb-lg-0">
                  <div className="division-one-report">
                    <h1>{state.reportData?.totalExhausted}</h1>
                    <p>Total Exhausted</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-two-report">
                    <h1>{state.reportData?.totalIPDScheduled}</h1>
                    <p>IPD Sheduled</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-three-report">
                    <h1>{state.reportData?.totalIPDDone}</h1>
                    <p>IPD Done</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-four-report">
                    <h1>{state.reportData?.totalOPDScheduled}</h1>
                    <p>OPD Sheduled</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-five-report">
                    <h1>{state.reportData?.totalOPDDone}</h1>
                    <p>OPD Done</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-six-report">
                    <h1>{state.reportData?.totalOutOfCity}</h1>
                    <p>Out Of City</p>
                  </div>
                </div>
              </div>

              <div
                className="row "
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <div className="col-12 col-lg-2 mb-sm-4 mb-3 mb-lg-0">
                  <div className="division-one-report">
                    <h1>{state.reportData?.totalSupplyGap}</h1>
                    <p>Supply Gap</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-two-report">
                    <h1>{state.reportData?.totalSurgeryIntentCount}</h1>
                    <p>Surgery Intent</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-three-report">
                    <h1>{state.reportData?.totalSurgerySuggestedCount}</h1>
                    <p>Surgery Suggested</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-four-report">
                    <h1>{state.reportData?.totalCallbackCount}</h1>
                    <p>Call Back</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-five-report">
                    <h1>{state.reportData?.totalCrossBDCount}</h1>
                    <p>Cross BD</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-six-report">
                  <h1>{state.reportData?.totalIPDLostCount}</h1>
                    <p>IPD Lost</p>
                  </div>
                </div>
              </div>

              <div
                className="row "
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <div className="col-12 col-lg-2 mb-sm-4 mb-3 mb-lg-0">
                  <div className="division-one-report">
                  <h1>{state.reportData?.totalWorkableExhaustedCount}</h1>
                    <p>Workable Exhausted</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-two-report">
                  <h1>{state.reportData?.totalNonWorkableExhaustedCount}</h1>
                    <p>Non Workable Exhausted</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-three-report">
                  <h1>{state.reportData?.totalMedicalManagementSLCount}</h1>
                    <p>Medical Management (SL)</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-four-report">
                  <h1>{state.reportData?.totalMedicalManagementCount}</h1>
                    <p>Medical Management</p>
                  </div>
                </div>
                <div className="col-12 col-lg-2 mb-sm-4  mb-3 mb-lg-0">
                  <div className="division-five-report">
                  <h1>{state.reportData?.totalDiagnosticsPrescribedCount}</h1>
                    <p>Diagnostics Prescribed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default Reports;
