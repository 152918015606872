import axios from "axios";
import { useEffect, useState } from "react";
import * as appConstants from "../../../util/AppConstants";
import { saveEventActivityLogs } from "../../../util/EventActivity";
import { getAllLocations } from "../../../services/mykare/centerService";
import { getTreatments } from "../../../services/mykare/dataService";
import {
  getSources,
  saveEnquiry,
} from "../../../services/mykare/enquiryService";

function useOtherLead(props) {
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedTreatment, setselectedTreatment] = useState("");
  const [treatments, setTreatments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedOrigin, setselectedOrigin] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [origins, setOrigins] = useState([]);
  const [assignedAgent, setAssignedAgent] = useState();
  const [assignedCode, setAssignedCode] = useState();
  const [userData, setUserData] = useState([]);
  const countryCodes = [
    { id: 1, name: "+91" },
    { id: 2, name: "+880" },
  ];
  const [selectedSource, setSelectedSource] = useState("");
  const [sources, setSources] = useState([
    {
      id: 21,
      name: "Medical Camp",
    },
    {
      id: 28,
      name: "Just Dial",
    },
  ]);

  useEffect(() => {
    loadTreatments();
    // loadOrigins();
    loadAllLocations();
    getAgentData();
    // getSource();
  }, []);

  const loadTreatments = async () => {
    try {
      getTreatments().then((res) => {
        setTreatments(res.data);
      });
    } catch (error) {}
  };

  // const getSource = async () => {
  //   const response = await getSources()
  //       setSources(response.data);
  // };

  const handleToAssigned = (assignTo, assignCode) => {
    setAssignedAgent(assignTo);
    setAssignedCode(assignCode);
  };

  const loadAllLocations = async () => {
    try {
      getAllLocations().then((data) => {
        setLocations(data);
      });
    } catch (error) {}
  };

  const getAgentData = async () => {
    try {
      const data = await axios.get(
        appConstants.APP_URL + `/user/all?userRole=SALES_AGENT&active=true`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      setUserData(data.data.users);

      return data;
    } catch (error) {}
  };

  const saveEnquiryDetails = async () => {
    if (name && phoneNumber && selectedSource) {
      let code = countryCode ? countryCode.name : "+91";
      const postData = {
        enquiryContext: "TREATMENT_PACKAGE",
        categoryContext: "OTHER_LEADS",
        enquiryContextId: 0,
        isPackage: true,
        name: name,
        sourceId: selectedSource.id,
        packageId: selectedTreatment.id,
        locationId: selectedLocation.id,
        phoneNumber: code + phoneNumber,
        platform: "MYKARE_WEB",
        assignedTo: assignedCode,
        notes: comment,
        additionalInfo: { notes: comment },
      };
      try {
        const response = await saveEnquiry(postData);
        //   setCabDetailId(response.data.cabDetailId);
        if (response.status === 200) {
          alert("Lead Created");
          props.onClicked(false);
          // saveEventActivityLogs("Create Lead");
        }
      } catch (error) {}
    } else {
      alert("Please fill all the Fields");
    }
  };

  return {
    setName,
    setPhoneNumber,
    saveEnquiryDetails,
    name,
    phoneNumber,
    treatments,
    selectedTreatment,
    selectedLocation,
    setselectedTreatment,
    locations,
    setSelectedLocation,
    origins,
    selectedOrigin,
    setselectedOrigin,
    handleToAssigned,
    setAssignedAgent,
    setAssignedCode,
    assignedAgent,
    assignedCode,
    userData,
    countryCode,
    countryCodes,
    setCountryCode,
    selectedSource,
    setSelectedSource,
    sources,
    setSources,
    setComment,
    comment,
  };
}

export default useOtherLead;
