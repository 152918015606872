import axios from "axios";
import { useEffect, useState } from "react";
import {
  getAllKaretripCountries,
  getAllKaretripDoctors,
  getAllKaretripHospitals,
  getAllKaretripLocations,
  getAllKaretripSources,
  getAllKaretripTreatments,
} from "../../../services/karetrip/centerService";
import { saveKaretripEnquiry } from "../../../services/karetrip/enquiryService";
import { getTreatments } from "../../../services/mykare/dataService";
import {
  getSources,
  saveEnquiry,
} from "../../../services/mykare/enquiryService";
import { APP_URL } from "../../../util/AppConstants";
import { saveEventActivityLogs } from "../../../util/EventActivity";

function usePopUp(props) {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);

  const [selectedLocation, setSelectedLocation] = useState("");
  const [locations, setLocations] = useState([]);

  const [selectedHospital, setSelectedHospital] = useState("");
  const [hospitals, setHospitals] = useState([]);

  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [doctors, setDoctors] = useState([]);

  const [selectedTreatment, setselectedTreatment] = useState("");
  const [treatments, setTreatments] = useState([]);

  const [selectedSource, setSelectedSource] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [sources, setSources] = useState([]);

  const [assignedUser, setAssignedUser] = useState([]);
  const [selectedAssignedUser, setSelectedAssignedUser] = useState({});
  const [countryCode, setCountryCode] = useState("+91");
  const [country, setCountry] = useState([]);
  const [selectedOrigin, setselectedOrigin] = useState("");
  const [origins, setOrigins] = useState([]);
  const [assignedAgent, setAssignedAgent] = useState();
  const [assignedCode, setAssignedCode] = useState();
  const [userData, setUserData] = useState([]);
  const [nameErr, setnameErr] = useState(false);
  const [phoneNumberErr, setPhoneNumberErr] = useState(false);

  const [oldEnquiryId, setOldEnquiryId] = useState(null);

  const enquiryType = ["NEW", "REPEAT", "REFERRAL"];
  const [selectedEnquiryType, setSelectedEnquiryType] = useState("");

  const type = [
    "ALL",
    "HOSPITAL",
    "DOCTOR",
    "PARTNER_REQUEST",
    "MEDICAL_OPINION",
  ];
  const [selectedType, setSelectedType] = useState("");

  const regexpPhoneNumber = "(?:\\+?(\\d{1,3}))?(\\d{10,12})";
  const regexpName = "^[a-zA-Z ]*$";

  const validateName = (name) => {
    const regex = new RegExp(regexpName);
    return regex.test(name);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = new RegExp(regexpPhoneNumber);
    return regex.test(phoneNumber);
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    if (validateName(name)) {
      setName(name);
      setnameErr(false);
    } else {
      console.log("===🔥🔥🔥🔥nameError🔥🔥🔥🔥=======>");
      setnameErr(true);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value;
    if (validatePhoneNumber(phoneNumber)) {
      setPhoneNumber(phoneNumber);
      setPhoneNumberErr(false);
    } else {
      setPhoneNumberErr(true);
      console.log("===🔥🔥🔥🔥phoneNumber🔥🔥🔥🔥====errr===>");
    }
  };

  const handleOldEnquiryId = (e) => {
    const oldEnquiryId = e.target.value;
  };

  const getAllAgentData = async () => {
    try {
      const data = await axios.get(APP_URL + `/user/intlSalesTeam/all`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setAssignedUser(data.data.users);
    } catch (error) {}
  };

  useEffect(() => {
    getAllAgentData();
    loadKaretripLocations();
    loadKaretripHospitals();
    loadKaretripDoctors();
    loadKaretripTreatments();
    loadKaretripSources();
    getAgentData();
    loadKaretripCountries();
    // getSource();
  }, []);

  const loadKaretripLocations = async () => {
    try {
      getAllKaretripLocations().then((data) => {
        setLocations(data);
      });
    } catch (error) {}
  };

  const loadKaretripHospitals = async () => {
    try {
      getAllKaretripHospitals().then((res) => {
        setHospitals(res);
      });
    } catch (error) {}
  };

  const loadKaretripDoctors = async () => {
    try {
      getAllKaretripDoctors().then((res) => {
        setDoctors(res);
      });
    } catch (error) {}
  };

  const loadKaretripTreatments = async () => {
    try {
      getAllKaretripTreatments().then((res) => {
        setTreatments(res);
      });
    } catch (error) {}
  };

  const loadKaretripSources = async () => {
    try {
      getAllKaretripSources().then((res) => {
        setSources(res);
      });
    } catch (error) {}
  };
  const loadKaretripCountries = async () => {
    try {
      getAllKaretripCountries().then((res) => {
        setCountry(res);
        console.log("===🔥🔥🔥🔥res🔥🔥🔥🔥=======>", res);
      });
    } catch (error) {}
  };

  // const getSource = async () => {
  //   const response = await getSources();
  //   if (response.status === 200) {
  //     if (response.data) {
  //       setSources(response.data)
  //     }
  //   }
  // }

  const handleToAssigned = (assignTo, assignCode) => {
    setAssignedAgent(assignTo);
    setAssignedCode(assignCode);
  };

  const getAgentData = async () => {
    try {
      const data = await axios.get(
        APP_URL + `/user/all?userRole=SALES_AGENT&active=true`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      setUserData(data.data.users);

      return data;
    } catch (error) {}
  };

  const saveKaretripEnquiryDetails = async () => {
    const postData = {};
    postData.hospitalId = selectedHospital.id;
    postData.doctorId = selectedDoctor.id;
    postData.treatmentId = selectedTreatment.id;
    postData.locationId = selectedLocation.id;
    postData.sourceId = selectedSource.id;
    postData.enquiryType = selectedEnquiryType;

    if (selectedEnquiryType === "REPEAT") {
      postData.oldUserTrxnId = oldEnquiryId;
    } else if (selectedEnquiryType === "REFERRAL") {
      const code = countryCode ? countryCode : "+91";
      postData.name = name;
      postData.countryCode = code;
      postData.categoryContext = selectedType;
      postData.referralUserTrxnId = oldEnquiryId;
    } else if (selectedEnquiryType === "NEW") {
      if (name && phoneNumber && selectedType) {
        const code = countryCode ? countryCode : "+91";
        postData.name = name;
        postData.phoneNumber = phoneNumber;
        postData.countryCode = code;
        postData.categoryContext = selectedType;
        postData.assignedTo = selectedAssignedUser?.code;
      } else {
        alert("Please fill all the Fields");
        return;
      }
    }

    try {
      const response = await saveKaretripEnquiry(postData);
      if (response.status === 200) {
        alert("Lead saved successfully");
        props.onClicked(false);
        saveEventActivityLogs("Create Lead");
      }
    } catch (error) {
      // Handle error
    }
  };

  return {
    handleOldEnquiryId,
    setOldEnquiryId,
    oldEnquiryId,

    setName,
    setPhoneNumber,
    saveKaretripEnquiryDetails,
    name,
    phoneNumber,

    treatments,
    setTreatments,
    selectedTreatment,
    setselectedTreatment,

    locations,
    setLocations,
    setSelectedLocation,
    selectedLocation,

    hospitals,
    setHospitals,
    selectedHospital,
    setSelectedHospital,

    doctors,
    setDoctors,
    selectedDoctor,
    setSelectedDoctor,

    sources,
    setSources,
    selectedSource,
    setSelectedSource,

    origins,
    selectedOrigin,
    setselectedOrigin,
    handleToAssigned,
    setAssignedAgent,
    setAssignedCode,
    assignedAgent,
    assignedCode,
    userData,
    countryCode,
    setCountryCode,

    type,
    setSelectedType,
    selectedType,

    enquiryType,
    setSelectedEnquiryType,
    selectedEnquiryType,

    setCountry,
    country,
    selectedCountry,
    setSelectedCountry,
    handleNameChange,
    handlePhoneNumberChange,
    phoneNumberErr,
    nameErr,
    assignedUser,
    setSelectedAssignedUser,
    selectedAssignedUser,
  };
}

export default usePopUp;
