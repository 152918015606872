import React, { useEffect, useRef, useState } from "react";
import "../../Pages/KareMessage/KareMessage.css";
import profile from "../../assets/icons/avatar_m.jpg";
import {
  downloadAttachment,
  getAllChats,
  getAllMessages,
  getAllMessageTemplates,
  sendDocuments,
  sendWaMessage,
} from "../../services/mykare/messageService";
import icon from "../../assets/icons/icon.png";
import download from "../../assets/icons/download.png";
import file_upload from "../../assets/icons/upload.png";
import temp_icon from "../../assets/icons/temp_icon.png";
import arrow from "../../assets/icons/arrow.png";
import avatar from "../../assets/icons/avatar.png";
import moment from "moment";
import { TextField } from "@material-ui/core";
import downloadDocument from "../../util/documentUtil";

function KareMessage() {
  const refObj = useRef();
  const targetRef = useRef(null);

  const [openChat, setOpenChat] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [count, setCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [read, setRead] = useState("All");
  const [listData, setListData] = useState();
  const [patientId, setPatientId] = useState();
  const [data, setData] = useState();
  const [show, setShow] = useState();
  const [image, setImage] = useState("");
  const [patientName, setPatientName] = useState("");
  const [templateList, setTemplates] = useState([]);
  const loadTemplates = async () => {
    try {
      getAllMessageTemplates('NOTIFIER', false).then((data) => {
        setTemplates(data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log("========chatList========", chatList);

  const handleClick = () => {
    refObj.current.click();
  };

  const handleScrollTo = () => {
    if (targetRef.current) {
      targetRef.current.scrollTop = targetRef.current.scrollHeight;
    }
  };

  const getMessage = async () => {
    const message = await getAllMessages(patientId, 0);
    if (message) {
      setListData(message);
    }
  };

  const handleFileUpload = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.target.files[0]) {
      sendDocument(event.target.files[0], patientId);
    }
  };

  const sendDocument = async (data, patientId) => {
    const response = await sendDocuments(data, patientId);
    if (response.status == 200) {
      const responseData = response.data;
      if (responseData) {
        setListData((current) => ({
          ...current,
          messages: [...current.messages, responseData],
        }));
      }
    }
  };

  const handleChange = (sendingData) => {
    sendMessage(sendingData);
    setData("");
  };

  const fileDownload = (documentId) => {
    downloadAttachment(documentId).then((data) => {
      downloadDocument(data.documentType, data.document, data.documentName);
    });
  };

  const getUnreadList = async (pageNo) => {
    const postData = { pageNo, pageSize: 30, type: read };
    const data = await getAllChats(postData);
    if (data) {
      setCount(data.count);
      setChatList(data.userResponses);
      if (data?.userResponses?.length >= data.count) {
        setHasMore(false);
        return;
      }
    }
  };

  const getChatList = async (pageNo) => {
    const postData = { pageNo, pageSize: 30 };
    setIsLoaded(true);
    const data = await getAllChats(postData);
    if (data) {
      setCount(data.count);
      let response = chatList.concat(data.userResponses);
      setChatList(response);
    }
  };

  const handleSelect = () => {
    setShowMessage(!showMessage);
  };

  const handleTemplate = (template) => {
    const data = {
      id: 1,
      data: { text: template.value, documentId: null },
      type: "text",
      author: "me",
      date: Date.now(),
      value: null,
      senderName: null,
    };

    setListData((current) => ({
      ...current,
      messages: [...current.messages, data],
    }));

    const postData = {
      patientId: patientId,
      templateId: template.id,
    };
    sendWaMessage(postData).then((data) => {
      getMessage();
    });
  };

  const sendMessage = (message) => {
    const data = {
      id: 1,
      data: { text: message, documentId: null },
      type: "text",
      author: "me",
      date: Date.now(),
      value: null,
      senderName: null,
    };
    setListData((current) => ({
      ...current,
      messages: [...current.messages, data],
    }));
    const postData = {
      patientId: patientId,
      message: message,
    };

    sendWaMessage(postData).then(() => {
      getMessage();
    });
  };

  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.scrollTop = targetRef.current.scrollHeight;
    }
  }, [listData]);

  useEffect(() => {
    if (read == "Unread") {
      getUnreadList(pageNo);
    } else {
      getChatList(pageNo);
    }
  }, [read]);

  useEffect(() => {
    loadTemplates();
  }, []);

  useEffect(() => {
    getMessage();
  }, [patientId]);

  return (
    <div>
      <div className="message-container">
        {openChat && (
          <div>
            <img
              className="show"
              onClick={() => {
                setShowMessage(false);
                setOpenChat(false);
                setShowChat(true);
              }}
              src={icon}></img>
          </div>
        )}
        {showChat && (
          <div className="message">
            <div className="message-header">
              {showMessage && (
                <div className="back">
                  <img
                    onClick={() => setShowMessage(false)}
                    className="back-btn"
                    src={arrow}></img>
                </div>
              )}
              <div className="chat-head">
                <h4>Kare Chat</h4>
              </div>
              <div className="hide-button">
                <h3
                  onClick={() => {
                    setShowChat(false);
                    setOpenChat(true);
                  }}>
                  X
                </h3>
              </div>
            </div>
            {showMessage && (
              <div className="msg-header">
                <div className="profile-div">
                  <img className="profile" src={avatar} alt="avatar" />
                </div>
                <div className="details">
                  <h5>{patientName}</h5>
                </div>
              </div>
            )}
            <div ref={targetRef} className="message-body">
              {!showMessage &&
                chatList.map((val, index) => {
                  return (
                    <div
                      onClick={() => {
                        handleSelect(val.userId);
                        setPatientId(val.userId);
                        setPatientName(val.name);
                        handleScrollTo();
                      }}
                      key={index}
                      className="message-section">
                      <div className="image-div">
                        <img className="image" src={avatar} alt=""></img>
                      </div>
                      <div className="name-div">
                        <p>{val.name}</p>
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          padding: "10px",
                          marginLeft: "15%",
                        }}
                        pad={"0px 0px 0px 20px"}>
                        {moment(val.date).format("DD-MM-YYYY hh:mm a")}
                      </div>
                    </div>
                  );
                })}
              {showMessage &&
                listData &&
                listData.messages.map((val, id) => {
                  return (
                    <div key={id}>
                      <div className="msg-me">
                        {val.author == "me" && val.data.text && (
                          <div className="msg-out">
                            <p>{val.data.text}</p>
                            <div
                              style={{
                                fontSize: "10px",
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: " 0 5px 5px",
                              }}>
                              {moment(val.date).format("DD-MM-YYYY hh:mm a")}
                            </div>
                          </div>
                        )}
                        {val.data?.documentId && val.author == "me" && (
                          <div className="document-dwnliad">
                            <div style={{ display: "flex" }}>
                              <img
                                className="download"
                                src={download}
                                alt=""
                                onClick={() =>
                                  fileDownload(val.data?.documentId)
                                }></img>
                              <h3
                                className="icon-dwnload"
                                onClick={() =>
                                  fileDownload(val.data?.documentId)
                                }>
                                {val.data?.documentId}
                              </h3>
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: " 0 5px 5px",
                              }}>
                              {moment(val.date).format("DD-MM-YYYY hh:mm a")}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="msg-them">
                        {val.author == "them" && val.data.text && (
                          <div className="msg-in">
                            <p>{val.data.text}</p>
                            <div
                              style={{
                                fontSize: "10px",
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: " 0 5px 5px",
                              }}>
                              {moment(val.date).format("DD-MM-YYYY hh:mm a")}
                            </div>
                          </div>
                        )}
                        {val.data?.documentId && val.author == "them" && (
                          <div className="document-dwnliad">
                            <div style={{ display: "flex" }}>
                              <img
                                className="download"
                                src={download}
                                alt=""
                                onClick={() =>
                                  fileDownload(val.data?.documentId)
                                }></img>
                              <h3
                                className="icon-dwnload"
                                onClick={() =>
                                  fileDownload(val.data?.documentId)
                                }>
                                {val.data?.documentId}
                              </h3>
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: " 0 5px 5px",
                              }}>
                              {moment(val.date).format("DD-MM-YYYY hh:mm a")}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            {show && (
              <div className="msg-temp">
                {templateList?.map((template) => (
                  <button
                    style={{
                      width: "100%",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      handleTemplate(template);
                      setShow(false);
                    }}>
                    {template.name}
                  </button>
                ))}
              </div>
            )}
            {showMessage && (
              <div className="popup-footer">
                <div className="chat">
                  <TextField
                    multiline
                    onChange={(e) => setData(e.target.value)}
                    value={data}
                    style={{
                      paddingLeft: "10px",
                      width: "100%",
                      fontSize: "14px",
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                  <img
                    onClick={handleClick}
                    src={file_upload}
                    alt=""
                    style={{ width: "6%", cursor: "pointer" }}
                  />
                  <input
                    ref={refObj}
                    onChange={handleFileUpload.bind(this)}
                    type="file"
                    className="file-upload"
                  />
                  {image && <img src={image} alt="preview" />}
                  <img
                    src={temp_icon}
                    alt=""
                    onClick={() => {
                      setShow(true);
                    }}
                    style={{ width: "20%", cursor: "pointer" }}
                  />
                </div>
                <button
                  onClick={() => handleChange(data)}
                  className="send-button">
                  send
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default KareMessage;
