import React, { useState } from "react";
import { Box, Button, Grid, Stack, TextField, Typography, FormControl, InputLabel, MenuItem } from "@mui/material";

function ServiceForm() {
  const [formState, setFormState] = useState({
    partnerName: "",
    countryId: "",
    age: "",
    gender: "",
    selectedDate: "",
    phoneNumber: "",
    email: "",
    notes: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value
    });
  };

  const formFields = [
    { name: "partnerName", label: "Partner Name", type: "text", placeholder: "Enter customer name" },
    { name: "countryId", label: "Country", type: "select", options: [] },
    { name: "age", label: "Age", type: "text", placeholder: "Enter age", halfWidth: true },
    { name: "gender", label: "Gender", type: "select", options: ["Male", "Female", "Other"], halfWidth: true },
    { name: "selectedDate", label: "Date Of Birth", type: "date" },
    { name: "phoneNumber", label: "Phone Number", type: "text", placeholder: "Enter phone number" },
    { name: "email", label: "Email (Optional)", type: "text", placeholder: "Enter email" },
    { name: "notes", label: "Notes", type: "multiline", rows: 4, placeholder: "Notes" }
  ];

  console.log("=================formFields",formFields.map((field, index)=>{
    console.log("===============field","hhhhhhhhhhhhhhhh",formFields[index + 1]?.halfWidth)
  }))

  return (
    <Grid
      display="flex"
      height="100%"
      padding={4}
      width="100%"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Box display="flex" gap={12}>
          <Typography fontSize={22} fontWeight={500} textAlign={"left"}>
            Add New Service
          </Typography> 
        </Box>
        <Box marginTop={6}>
          <Stack direction="column">
            {formFields.map((field, index) => {
              if (field.halfWidth) {
                if (index % 2 === 0 && formFields[index + 1]?.halfWidth) {
                  return (
                    <Box key={index} display="flex" width="100%" gap={2}>
                      {[formFields[index], formFields[index + 1]]?.map((subField, subIndex) => (
                        <FormControl key={subField?.name} fullWidth variant="outlined" size="small">
                          <InputLabel sx={{ marginLeft: "-14px" }}>{subField?.label}</InputLabel>
                          <TextField
                            name={subField?.name}
                            type={subField?.type === "date" ? "date" : "text"}
                            placeholder={subField?.placeholder}
                            fullWidth
                            size="small"
                            sx={{ marginTop: "40px" }}
                            value={formState[subField?.name]}
                            onChange={handleInputChange}
                            select={subField?.type === "select"}
                            multiline={subField?.type === "multiline"}
                            rows={subField?.rows || 1}
                          >
                            {subField?.type === "select" &&
                              subField?.options.map((option, optionIndex) => (
                                <MenuItem key={optionIndex} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                          </TextField>
                        </FormControl>
                      ))}
                    </Box>
                  );
                }
              }
              if (field.halfWidth && index % 2 !== 0) return null;
              return (
                <FormControl key={index} fullWidth variant="outlined" size="small">
                  <InputLabel sx={{ marginLeft: "-14px" }}>{field?.label}</InputLabel>
                  <TextField
                    name={field?.name}
                    type={field?.type === "date" ? "date" : "text"}
                    placeholder={field?.placeholder}
                    fullWidth
                    size="small"
                    sx={{ marginTop: "40px" }}
                    value={formState[field?.name]}
                    onChange={handleInputChange}
                    select={field?.type === "select"}
                    multiline={field?.type === "multiline"}
                    rows={field?.rows || 1}
                  >
                    {field?.type === "select" &&
                      field?.options.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
              );
            })}
            <Typography fontWeight={500} textAlign={"left"}>
              Manager
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Box>
        <Button
          fullWidth
          variant="contained"
          sx={{
            padding: "8px",
            bgcolor: "#7865FD",
            marginY: "15px",
          }}
          // onClick={handleSave}
        >
          Submit
        </Button>
      </Box>
    </Grid>
  );
}

export default ServiceForm;

