import { makeStyles } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import downloadDocument from "../../../../util/documentUtil";
import { checkPermission } from "../../../../util/permissionUtils";
import Sidebar from "../../../Sidebar";
import useDoctorInnerLogic from "./useDoctorInner.logic";

function DoctorInnerPage() {
  const state = useDoctorInnerLogic();

  // useEffect(() => {
  //   if(history.location.state.data){
  //    state.setData(history.location.state.data)
  //   }
  // }, [])

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));
  const classes = useStyles();

  return (
    <div className="d-flex">
      <Sidebar />
      <div className={classes.root}>
        <button className="width1" onClick={() => state.back()}>
          BACK
        </button>
        <hr />
        <div className="row">
          <div class="col-md-1">
            <p className="text-start">Doctor Name</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${state.data?.name}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Code</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${state.data?.code}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Center Name</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${state.data?.centerName}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Experience</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${state.data?.experience}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Qualification</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${state.data?.qualifications.map(
                (val) => val.name
              )}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Specializations</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${state.data?.specializations.map(
                (val) => val.name
              )}`}</div>
            </p>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-3 ">
              {checkPermission("DOCTOR_AGREEMENT") && (
                <div style={{ marginTop: 10 }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                      marginBottom: 0,
                    }}
                  >
                    Agreement
                  </p>
                  <div style={{ display: "flex" }}>
                    {state.savedDoctorDoc.length !== 0 &&
                      state.savedDoctorDoc.map((doc, index) => (
                        <div className="row">
                          <div class="col-6">
                            <div className="ed-txt-2 text-start"></div>
                          </div>
                          <div>
                            <b>
                              <Link
                                to="#"
                                style={{ marginLeft: 10 }}
                                className="pointer"
                                onClick={() =>
                                  downloadDocument(
                                    doc.documentType,
                                    doc.document,
                                    doc.documentName
                                  )
                                }
                              >
                                {`Agreement Document ${index + 1}`}
                              </Link>
                            </b>
                          </div>
                        </div>
                      ))}
                  </div>
                  <br></br>
                  <div
                    style={{
                      height: 200,
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <label
                      for="agreementFile"
                      class="btn"
                      style={{
                        width: 200,
                        height: 40,
                        backgroundColor: "#06c6a2",
                        color: "white",
                        marginBottom: 30,
                      }}
                    >
                      {state.savedDoctorDoc.length !== 0
                        ? "Update Agreement"
                        : "Upload Agreement"}
                    </label>
                    <div>
                      <input
                        id="agreementFile"
                        style={{
                          cursor: "pointer ",
                          display: "none",
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingBottom: 3,
                          paddingTop: 3,
                        }}
                        type="file"
                        ref={state.inputFile}
                        onChange={state.handleAgreementFile.bind(this)}
                      />
                      {state.agreementFiles && (
                        <>
                          <span
                            style={{
                              border: "1px solid gray",
                              marginTop: 40,
                              cursor: "pointer ",
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#e5e5e5",
                            }}
                            onClick={() => state.removeItem()}
                          >
                            {state.agreementFiles?.name}
                          </span>
                          <span
                            style={{
                              paddingLeft: 12,
                              paddingRight: 12,
                              paddingBottom: 10,
                              paddingTop: 5,
                              backgroundColor: "#06c6a2",
                              border: "1px solid gray",
                              color: "white",
                              cursor: "pointer ",
                            }}
                            onClick={() => state.removeItem()}
                          >
                            X
                          </span>
                        </>
                      )}
                      <br></br>
                      {state.agreementFiles && (
                        <button
                          style={{
                            width: 180,
                            height: 50,
                            marginTop: 30,
                            paddingBottom: 5,
                          }}
                          onClick={() => state.addDoctorDocs()}
                        >
                          Save Agreement
                        </button>
                      )}
                    </div>
                  </div>
                  <></>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorInnerPage;
