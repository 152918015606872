import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  addCenter,
  getAllAgreementModels,
  getAllCenters,
  getAllCentersByCenterId,
  getAllLocations,
  getAllQualifications,
  getAllSpecialisations,
  getCenterById,
  updateCenterDocs,
  uploadCenterDocs,
} from "../../../services/mykare/centerService";
import {
  getAllDoctorsByCenter,
  addDoctor,
} from "../../../services/mykare/doctorsService";
import { MenuProps, useStyles } from "../../../components/multi-select";
import {
  List,
  ListItem,
  ListItemText,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { getTreatments } from "../../../services/mykare/dataService";
import { useRef } from "react";
import { getAllDocuments } from "../../../services/mykare/documentService";
import { saveEventActivityLogs } from "../../../util/EventActivity";

const useHospitalDetail = (callBackFn) => {
  const routeParams = useLocation();
  const inputFile = useRef(null);
  const [homeData, setHomeData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  let history = useHistory();
  //Hospital const
  const [name, setName] = useState("");
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    name: "Choose Location",
  });
  const [contactName, setContactName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [googleMapLink, setGoogleMapLink] = useState("");
  const [address, setAddress] = useState("");

  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [specialityNames, setSelectedSpecialityNames] = useState(null);
  const [specialityIds, setSelectedSpecialityIds] = useState([]);
  const [specialityData, setSpecialityData] = useState([]);
  const isAllSelectedSpecialities =
    specialityData.length > 0 &&
    selectedSpecialities.length === specialityData.length;

  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [treatmentNames, setSelectedTreatmentNames] = useState(null);
  const [treatmentIds, setSelectedTreatmentIds] = useState([]);
  const [treatmentData, setTreatmentData] = useState([]);
  const isAllSelectedTreatments =
    treatmentData.length > 0 &&
    selectedTreatments.length === treatmentData.length;

  const [selectedModels, setSelectedModels] = useState([]);
  const [agreementModelIds, setSelectedModelIds] = useState([]);
  const [agreementModelTypes, selectedAgreementModelTypes] = useState(null);
  const [agreementModelsData, setAgreementModelsData] = useState([]);
  const isAllSelectedModels =
    agreementModelsData.length > 0 &&
    selectedModels.length === agreementModelsData.length;

  //Doctors const
  const [doctorName, setDoctorName] = useState("");
  const [experience, setExperience] = useState("");
  const [centerId, setCenterId] = useState(null);
  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [qualificationIds, setSelectedQualificationIds] = useState([]);
  const [qualificationData, setQualificationData] = useState([]);
  const isAllSelectedQualification =
    qualificationData.length > 0 &&
    selectedQualifications.length === qualificationData.length;

  const [selectedDoctorSpecialities, setSelectedDoctorSpecialities] = useState(
    []
  );
  const [doctorSpecialityIds, setSelectedDoctorSpecialityIds] = useState([]);
  const [specialityDoctorData, setDoctorSpecialityData] = useState([]);
  const isAllSelectedDoctorSpecialities =
    specialityDoctorData.length > 0 &&
    selectedDoctorSpecialities.length === specialityDoctorData.length;
  const [editableMode, setEditableMode] = useState(true);
  const [agreementFiles, setAgreementFiles] = useState(null);
  const [savedCenterDocs, setSavedCenterDocs] = useState([]);

  const loadData = (centerData) => {
    if (centerData) {
      setCenterId(centerData.id);
      setSelectedLocation(centerData.location);
      setSelectedSpecialities(centerData.specialities);
      setSelectedTreatments(centerData.treatments);
      setSelectedSpecialityNames(
        centerData.specialities.map((speciality) => speciality.name)
      );
      setSelectedTreatmentNames(
        centerData.treatments.map((treatment) => treatment.name)
      );
      selectedAgreementModelTypes(
        centerData.agreementModels.map((agreementModel) => agreementModel.type)
      );
      setSelectedModelIds(
        centerData.agreementModels.map((agreementModel) => agreementModel.id)
      );
      setSelectedSpecialityIds(
        centerData.specialities.map((speciality) => speciality.id)
      );
      setSelectedTreatmentIds(
        centerData.treatments.map((treatment) => treatment.id)
      );
      setSelectedModels(centerData.agreementModels);
      setName(centerData.name);
      setContactName(centerData.contactName);
      setContactNumber(centerData.contactNumber);
      setGoogleMapLink(centerData.googleMapLink);
      setAddress(centerData.address);
    }
  };

  useEffect(() => {
    if (!routeParams.state) {
      // history.push("/hospital");
    } else {
      const centerData = routeParams.state.centerData;
      loadCenterDetails(centerData.id);
      setEditableMode(false);
      loadAllDoctorsByCenter(centerData.id);
    }
  }, []);

  useEffect(() => {
    loadAllLocations();
    loadAllCenters();
    loadAllSpecialisations();
    loadAllTreatments();
    loadAllAgreementModels();
    loadAllQualifications();
    loadAllDoctorSpecialisations();
  }, []);

  const [pageData, setPageData] = useState([]);
  const [pageDoctorData, setPageDoctorData] = useState([]);
  const classes = useStyles();

  const loadAllCenters = async () => {
    const data = await getAllCenters();
    if (data) {
      setPageData(data);
    }
  };

  const loadAllLocations = async () => {
    try {
      getAllLocations().then((data) => {
        setLocations(data);
      });
    } catch (error) {}
  };

  const loadCenterDetails = async (centerId) => {
    const data = await getCenterById(centerId);
    if (data) {
      loadData(data);
    }
  };

  const loadAllDoctorsByCenter = async (centerId) => {
    const data = await getAllDoctorsByCenter(centerId);
    if (data) {
      setPageDoctorData(data);
    }
  };

  useEffect(() => {
    if (!!pageDoctorData) {
      const postData = pageDoctorData.map((data, index) => (
        <div
          key={index}
          className="row pointer"
          // style={{overflow:"scroll"}}
        >
          <div className=" col-2">
            <ListItem>
              <ListItemText primary={`${data.code}`} />
            </ListItem>
          </div>
          <div className=" col-2">
            <ListItem>
              <ListItemText primary={`${data.name}`} />
            </ListItem>
          </div>
          <div className=" col-3 ">
            <ListItem>
              <ListItemText
                primary={`${data.specializations.map((item) => item.name)}`}
              />
            </ListItem>
          </div>
          <div className=" col-3 ">
            <ListItem>
              <ListItemText
                primary={`${data.qualifications.map((item) => item.name)}`}
              />
            </ListItem>
          </div>
          <div className=" col-2 ">
            <ListItem>
              <ListItemText primary={`${data.experience}`} />
            </ListItem>
          </div>
          <hr />
        </div>
      ));
      setHomeData(postData);
    }
  }, [pageDoctorData]);

  useEffect(() => {
    if (centerId) {
      loadCenterDocs();
    }
  }, [centerId]);

  const loadAllSpecialisations = async () => {
    const data = await getAllSpecialisations();
    if (data) {
      setSpecialityData(data);
    }
  };

  const loadAllTreatments = async () => {
    const resp = await getTreatments();
    if (resp) {
      setTreatmentData(resp.data);
    }
  };

  const loadAllAgreementModels = async () => {
    const data = await getAllAgreementModels();
    if (data) {
      setAgreementModelsData(data);
    }
  };

  const loadAllQualifications = async () => {
    const data = await getAllQualifications();
    if (data) {
      setQualificationData(data);
    }
  };

  const loadAllDoctorSpecialisations = async () => {
    const data = await getAllSpecialisations();
    if (data) {
      setDoctorSpecialityData(data);
    }
  };

  const handleAgreementFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setAgreementFiles(file);
  };

  const removeItem = () => {
    setAgreementFiles(null);
  };

  const loadCenterDocs = () => {
    getAllDocuments(centerId, "AGREEMENT_DOCUMENT", "hospital_agreement")
      .then((res) => {
        setSavedCenterDocs(res.data);
      })
      .catch((error) => {

      });
  };

  const addCenterDocs = async () => {
    let formData = new FormData();
    // agreementFiles.forEach((file) => {
    formData.append("centerDoc", agreementFiles);
    // });
    formData.append("centerId", centerId);

    if(savedCenterDocs){

      updateCenterDocs(formData)
      .then((data) => {
        alert("Updated Successfully");
        saveEventActivityLogs('Update Hospital Document')
        setAgreementFiles(null);
        loadCenterDocs();
        callBackFn(data.data);
      })
      .catch((err) => {

      });
    }else{
      uploadCenterDocs(formData)
      .then((data) => {
        alert("Upload Successfully");
        saveEventActivityLogs('Upload Hospital Document')
        setAgreementFiles(null);
        loadCenterDocs();
        callBackFn(data.data);
      })
      .catch((err) => {

      });
    }
  };

  const handleChangeQualifications = (event) => {
    const value = event.target.value;
    if (value[value.length - 1].id === 0) {
      setSelectedQualifications(
        selectedQualifications.length === qualificationData.length
          ? []
          : qualificationData
      );
      const ids = qualificationData.map((item) => item.id);
      setSelectedQualificationIds(
        selectedQualifications.length === qualificationData.length ? [] : ids
      );
      return;
    }
    const ids = value.map((item) => item.id);
    const isAllSelected = ids.includes(0);
    setSelectedQualificationIds(ids);
    setSelectedQualifications(value);
  };

  const handleChangeSpecialities = (event) => {
    const value = event.target.value;
    if (value[value.length - 1]?.id === 0) {
      setSelectedSpecialities(
        selectedSpecialities.length === specialityData.length
          ? []
          : specialityData
      );
      const ids = specialityData.map((item) => item.id);
      setSelectedSpecialityIds(
        selectedSpecialities.length === specialityData.length ? [] : ids
      );
      return;
    }
    const ids = value.map((item) => item.id);
    const isAllSelected = ids.includes(0);
    setSelectedSpecialityIds(ids);
    setSelectedSpecialities(value);
  };

  const handleChangeTreatments = (event) => {
    const value = event.target.value;
    if (value[value.length - 1]?.id === 0) {
      setSelectedTreatments(
        selectedTreatments.length === treatmentData.length ? [] : treatmentData
      );
      const ids = treatmentData.map((item) => item.id);
      setSelectedTreatmentIds(
        selectedTreatments.length === treatmentData.length ? [] : ids
      );
      return;
    }
    const ids = value.map((item) => item.id);
    const isAllSelected = ids.includes(0);
    setSelectedTreatmentIds(ids);
    setSelectedTreatments(value);
  };

  const handleChangeModels = (event) => {
    const value = event.target.value;
    if (value[value.length - 1]?.id === 0) {
      setSelectedModels(
        selectedModels.length === agreementModelsData.length
          ? []
          : agreementModelsData
      );
      const ids = agreementModelsData.map((item) => item.id);
      setSelectedModelIds(
        selectedModels.length === agreementModelsData.length ? [] : ids
      );
      return;
    }
    const ids = value.map((item) => item.id);
    const isAllSelected = ids.includes(0);
    setSelectedModelIds(ids);
    setSelectedModels(value);
  };

  const handleChangeDoctorSpecialities = (event) => {
    const value = event.target.value;
    if (value[value.length - 1].id === 0) {
      setSelectedDoctorSpecialities(
        selectedDoctorSpecialities.length === specialityDoctorData.length
          ? []
          : specialityDoctorData
      );
      const ids = specialityData.map((item) => item.id);
      setSelectedDoctorSpecialityIds(
        selectedDoctorSpecialities.length === specialityDoctorData.length
          ? []
          : ids
      );
      return;
    }
    const ids = value.map((item) => item.id);
    const isAllSelected = ids.includes(0);
    setSelectedDoctorSpecialityIds(ids);
    setSelectedDoctorSpecialities(value);
  };

  const addNewCenter = async () => {
    addCenterDocs();
    const postData = {
      centerId: centerId,
      name: name,
      locationId: selectedLocation?.id,
      contactName: contactName,
      contactNumber: contactNumber,
      googleMapLink: googleMapLink,
      address: address,
      specialityIds: specialityIds,
      treatmentIds,
      treatmentIds,
      agreementModelIds: agreementModelIds,
    };
   if(postData){
    const response = await addCenter(postData);
    if (response.status === 200) {
      loadData(response.data);
      alert("Added new Center");
      setIsOpen(!isOpen);
      setEditableMode(false);
    }
   }
  };

  const addNewDoctor = async () => {
    const postData = {
      centerId: centerId,
      name: doctorName,
      specialityIds: doctorSpecialityIds,
      qualificationIds: qualificationIds,
      experience: experience,
    };
    const response = await addDoctor(postData);
    if (window.confirm("Are you sure you want to add doctor ! ")) {
      if (response.status === 200) {
        // loadData(response.data);
        setIsOpen(!isOpen);
        setEditableMode(false);
      }
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function back() {
    history.push({
      pathname: "/hospital",
    });
  }

  return {
    name,
    locations,
    selectedLocation,
    contactName,
    contactNumber,
    googleMapLink,
    address,
    selectedSpecialities,
    specialityIds,
    specialityData,
    isAllSelectedSpecialities,
    selectedModels,
    agreementModelIds,
    agreementModelsData,
    isAllSelectedModels,
    doctorName,
    experience,
    centerId,
    selectedQualifications,
    qualificationIds,
    qualificationData,
    isAllSelectedQualification,
    selectedDoctorSpecialities,
    doctorSpecialityIds,
    specialityDoctorData,
    isAllSelectedDoctorSpecialities,
    selectedSpecialities,
    selectedTreatments,
    selectedQualifications,
    isAllSelectedTreatments,
    treatmentIds,
    treatmentNames,
    treatmentData,
    open,
    homeData,
    specialityNames,
    editableMode,
    setEditableMode,
    setContactName,
    setContactNumber,
    setSelectedLocation,
    setLocations,
    setGoogleMapLink,
    setAddress,
    setName,
    setDoctorName,
    setExperience,
    setHomeData,
    handleClickOpen,
    handleClose,
    handleChangeQualifications,
    handleChangeSpecialities,
    handleChangeTreatments,
    handleChangeModels,
    handleChangeDoctorSpecialities,
    addNewCenter,
    addNewDoctor,
    loadAllLocations,
    loadAllDoctorsByCenter,
    loadAllSpecialisations,
    loadAllAgreementModels,
    loadAllQualifications,
    loadAllDoctorSpecialisations,
    agreementModelTypes,
    selectedAgreementModelTypes,
    classes,
    pageData,
    pageDoctorData,
    back,
    handleAgreementFile,
    agreementFiles,
    inputFile,
    removeItem,
    savedCenterDocs,
    setSavedCenterDocs,
    addCenterDocs,
    setAgreementFiles,
    loadCenterDocs,
  };
};

export default useHospitalDetail;
