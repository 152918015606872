import { useState } from "react";
import axios from "axios";

//MUI Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { CLIENT_URL, TOKEN } from "../../../util/AppConstants";
import { InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";

const Modalpop = ({ open, onClose }) => {
  const initialFormData = {
    type: "ipd",
    data: {
      patientName: "",
      doctorDetails: "",
      hospitalDetails: "",
      procedureDetails: "",
      admissionDetails: "",
      otherAdmissionInfos: "Yes",
      surgeryCost: "",
      paymentMode: "",
      roomType: "",
    },
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errorMessage, setErrorMessage] = useState(null);

  const token = TOKEN;

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Generated PDF");

    errorMessage;

    axios({
      method: "post",
      url: CLIENT_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: formData,
      responseType: "blob",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        const url = window.URL.createObjectURL(data);
        window.open(url);
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(
          "An error occurred. Please check console and try again."
        );
      });

    setFormData(initialFormData);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle textAlign={"center"}>Patient Information</DialogTitle>
        {errorMessage && (
          <span style={{ color: "red", textAlign: "center" }}>
            {errorMessage}
          </span>
        )}
        <DialogContent>
          <DialogContentText>
            <form onSubmit={handleSubmit}>
              <Stack spacing={2} margin={2} fontSize={4}>
                <InputLabel sx={{ alignSelf: "flex-start" }}>
                  Patient Full Name
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="patientname"
                  value={formData.data.patientName}
                  required
                  sx={{ paddingTop: "10px !important" }}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      data: {
                        ...prevFormData.data,
                        patientName: e.target.value,
                      },
                    }))
                  }
                  multiline
                ></TextField>

                <InputLabel
                  sx={{ alignSelf: "flex-start", marginTop: "8px !important" }}
                >
                  Doctor Name & Qualification
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="doctorDetails"
                  value={formData.data.doctorDetails}
                  required
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      data: {
                        ...prevFormData.data,
                        doctorDetails: e.target.value,
                      },
                    }))
                  }
                  multiline
                ></TextField>

                <InputLabel
                  sx={{ alignSelf: "flex-start", marginTop: "8px !important" }}
                >
                  Hospital Name and Address
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="hospitalname"
                  required
                  value={formData.data.hospitalDetails}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      data: {
                        ...prevFormData.data,
                        hospitalDetails: e.target.value,
                      },
                    }))
                  }
                  multiline
                ></TextField>

                <InputLabel
                  sx={{ alignSelf: "flex-start", marginTop: "8px !important" }}
                >
                  Procedure Name & Technique
                </InputLabel>
                <TextField
                  variant="outlined"
                  value={formData.data.procedureDetails}
                  name="procedure"
                  required
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      data: {
                        ...prevFormData.data,
                        procedureDetails: e.target.value,
                      },
                    }))
                  }
                  multiline
                ></TextField>
                <InputLabel
                  sx={{ alignSelf: "flex-start", marginTop: "8px !important" }}
                >
                  Admission Date & Time
                </InputLabel>
                <TextField
                  variant="outlined"
                  placeholder=""
                  format="dd.MM.yyyy"
                  value={formData.data.admissionDetails}
                  name="admissiondateandtime"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  onChange={(e) => {
                    const dateTimeValue = e.target.value;
                    const momentDateTime = moment(dateTimeValue);
                    const formattedDateTime =
                      momentDateTime.format("YYYY-MM-DD HH:mm");

                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      data: {
                        ...prevFormData.data,
                        admissionDetails: formattedDateTime,
                      },
                    }));
                  }}
                ></TextField>

                <InputLabel
                  sx={{ alignSelf: "flex-start", marginTop: "8px !important" }}
                >
                  Advised 1 day prior admission
                </InputLabel>

                <Select
                  required
                  defaultValue="Yes"
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      data: {
                        ...prevFormData.data,
                        otherAdmissionInfos: e.target.value,
                      },
                    }))
                  }
                  style={{ textAlign: "left" }}
                >
                  <MenuItem style={{ textAlign: "left" }} value="Yes">
                    Yes
                  </MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>

                <InputLabel sx={{ alignSelf: "flex-start" }}>
                  Indicative Surgery Cost
                </InputLabel>

                <TextField
                  variant="outlined"
                  value={formData.data.surgeryCost}
                  name="surgerycost"
                  required
                  type="number"
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      data: {
                        ...prevFormData.data,
                        surgeryCost: e.target.value,
                      },
                    }))
                  }
                  multiline
                ></TextField>

                <InputLabel sx={{ alignSelf: "flex-start" }}>
                  Mode of payment
                </InputLabel>

                <TextField
                  variant="outlined"
                  value={formData.data.paymentMode}
                  name=" paymentMode"
                  required
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      data: {
                        ...prevFormData.data,
                        paymentMode: e.target.value,
                      },
                    }))
                  }
                  multiline
                ></TextField>

                <InputLabel sx={{ alignSelf: "flex-start" }}>
                  Room Type
                </InputLabel>

                <TextField
                  variant="outlined"
                  value={formData.data.roomType}
                  name=" roomType"
                  required
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      data: {
                        ...prevFormData.data,
                        roomType: e.target.value,
                      },
                    }))
                  }
                  multiline
                ></TextField>

                <Button
                  type="submit"
                  sx={{ alignSelf: "center" }}
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  Submit
                </Button>
              </Stack>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modalpop;
