import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import {
  saveCabDetailApi,
  updateCabDetailApi,
  getCabDetailApi,
} from "../../../services/mykare/cabDetailService";
import { async } from "@firebase/util";
import {
  getAllDocuments,
  uploadDocuments,
} from "../../../services/mykare/documentService";
import downloadDocument from "../../../util/documentUtil";

function useCabDetail({ userTrxnId, cabDetailsId, callBackFn }) {
  const [errorMsg, setErrorMsg] = useState("");
  const [editableMode, setEditableMode] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(true);
  const [isAdd, setIsAdd] = useState(false);
  const [isReturn, setIsReturn] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [data, setData] = useState(null);
  const [cabDetails, setCabDetails] = useState({
    cabDetailId: null,
    pickupDateTime: new Date(),
    returnCab: false,
    pickup: "",
    drop: "",
    otp: "",
    amount: 0,
  });

  const [file, setFile] = useState();
  const inputRef = useRef(null);

  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current?.click();
  };

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }

    setFile(e.target.files[0]);

    // 🚩 do the file upload here normally...
  };

  const clearDetails = () => {
    setCabDetails({
      cabDetailId: null,
      pickupDateTime: new Date(),
      returnCab: false,
      pickup: "",
      drop: "",
      otp: "",
      amount: 0,
    });
  };

  const downloadDocuments = async (cabDetailId) => {
    await getAllDocuments(cabDetailId, "CAB_PAYMENT_BILL", "cab_payment_bill")
      .then((response) => {
        downloadDocument(
          response.data[response.data.length - 1].documentType,
          response.data[response.data.length - 1].document,
          response.data[response.data.length - 1].documentName
        );
      })
      .catch((e) => { });
  };

  const uploadCabDetailsDocs = async (contextId) => {
    const context = "CAB_PAYMENT_BILL";
    let formData = new FormData();
    formData.append("documents", file);
    formData.append("context", context);
    formData.append("category", "cab_payment_bill");
    formData.append("contextId", contextId);

    await uploadDocuments(formData)
      .then((data) => {
        alert("Uploaded Successfully");
        setFile(null);
      })
      .catch((err) => { });
  };

  const saveCabDetails = async (cabDetailId) => {
    const myDate = moment(cabDetails.pickupDateTime).format().split("+")[0];
    const postData = {
      amount: cabDetails.amount,
      pickup: cabDetails.pickup,
      pickupDateTime: myDate,
      returnCab: cabDetails.returnCab,
      drop: cabDetails.drop,
      otp: cabDetails.otp,
      userTxnId: userTrxnId,
    };
    if (cabDetailId) {
      postData.cabDetailId = cabDetailId;
      try {
        const response = await saveCabDetailApi(postData);
        if (response.status === 200) {
          alert("Successfully Saved");
          clearDetails();
          loadCabDetails(userTrxnId);
          if (response.data) {
            if (file) {
              uploadCabDetailsDocs(response.data.cabDetailId);
            }
          }
        }
      } catch (error) { }
    } else {
      if (file) {
        try {
          const response = await saveCabDetailApi(postData);
          if (response.status === 200) {
            setIsAdd(false);
            alert("Successfully Saved");
            clearDetails();
            loadCabDetails(userTrxnId);
          }
          if (response.data) {
            uploadCabDetailsDocs(response.data.cabDetailId);
          }
        } catch (error) { }
      } else {
        setIsAdd(true);
        alert("Upload Bill to save cab details");
      }
    }
  };
  useEffect(() => {
    loadCabDetails(userTrxnId);
  }, []);

  const loadCabDetails = (userTrxnId) => {
    setDataLoaded(false);
    getCabDetailApi(userTrxnId)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => { })
      .finally(() => {
        setDataLoaded(true);
      });
  };

  return {
    errorMsg,
    saveCabDetails,
    cabDetailsId,
    getCabDetailApi,
    cabDetails,
    editableMode,
    setEditableMode,
    setCabDetails,
    dataLoaded,
    isAdd,
    setIsAdd,
    file,
    handleFileChange,
    handleUploadClick,
    inputRef,
    isReturn,
    setIsReturn,
    selectedIndex,
    setSelectedIndex,
    downloadDocuments,
    data,
    clearDetails,
  };
}

export default useCabDetail;
