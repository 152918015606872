import React, { useEffect, useState } from "react";
import StepperComponent from "./StepperComponent";
import {
  getAllActivities,
  getAllActivityDetails,
  getDocuments,
  getUpdatedActivity,
} from "../../services/karebuddy/activityService";
import { Card } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import downloadDocument from "../../util/documentUtil";

function DetailsItem({ head, sub }) {
  return (
    <div className="col-2">
      <span
        style={{
          fontSize: 18,
        }}
      >
        {head}
      </span>
      <br />
      <h5>{sub}</h5>
    </div>
  );
}
function DetailsDoc({ head, docs }) {
  return (
    <div className="col-2">
      <span
        style={{
          fontSize: 18,
        }}
      >
        {head}
      </span>
      <br />
      {docs?.length > 0 &&
        docs?.map((doc, index) => {
          return (
            <b>
              <Link
                to="#"
                style={{ marginRight: 10 }}
                className="pointer"
                onClick={() =>
                  downloadDocument(
                    doc.documentType,
                    doc.document,
                    doc.documentName
                  )
                }
              >
                {`Document ${index + 1}`}
              </Link>
            </b>
          );
        })}
    </div>
  );
}

const category = {
  prescription: "kare_buddy_opd_done_prescription",

  insurance: "kare_buddy_insurance",

  admitted: "kare_buddy_patient_admitted",

  otInvestigation: "kare_buddy_initial_investigation",

  surgery: "kare_buddy_surgery_completed",

  discharge: "kare_buddy_discharge_bill",

  summary: "kare_buddy_discharge_summary",
};

const context = {
  prescription: "KARE_BUDDY_OPD_DONE_PRESCRIPTION",

  insurance: "KARE_BUDDY_INSURANCE",

  admitted: "KARE_BUDDY_PATIENT_ADMITTED",

  otInvestigation: "KARE_BUDDY_INITIAL_INVESTIGATION",

  surgery: "KARE_BUDDY_SURGERY_COMPLETED",

  discharge: "KARE_BUDDY_DISCHARGE_BILL",

  summary: "KARE_BUDDY_DISCHARGE_SUMMARY",
};

export default function KareBuddyActivity() {
  const [steps, setsteps] = useState([]);
  const [activities, setActivities] = useState([]);
  const [details, setDetails] = useState([]);
  const [types, setType] = useState("");
  const [docPres, setDocPres] = useState(null);
  const [docInsurance, setDocInsurance] = useState(null);
  const [otInvestigation, setOTInvestigation] = useState(null);
  const [admittedDoc, setAdmittedDoc] = useState(null);
  const [surgeryDoc, setSurgeryDoc] = useState(null);
  const [dischargeDoc, setDischargeDoc] = useState(null);
  const [summaryDoc, setSummaryDoc] = useState(null);

  const getAllActivity = async (type) => {
    if (type) {
      const response = await getAllActivities(type);
      if (response.status == 200) {
        setsteps(response.data);
      }
    }
  };

  useEffect(() => {
    const url = window?.location?.pathname;
    const type = url.split("/")[url.split("/").length - 1];
    console.log(url.split("/").length);
    setType(url.split("/")[url.split("/").length - 1]);
  }, []);

  const getUserEnquiryId = () => {
    const url = window?.location?.pathname;
    let path = url.split("/")[url.split("/").length - 2];
    return path;
  };

  const getAllUpdatedActivityDetail = async () => {
    const userTrxnId = getUserEnquiryId();
    if (userTrxnId) {
      const response = await getUpdatedActivity(userTrxnId);
      if (response.status == 200) {
        setActivities(response.data);
      }
    }
  };
  const getAllActivityDetail = async () => {
    const userTrxnId = getUserEnquiryId();
    if (userTrxnId) {
      const response = await getAllActivityDetails(userTrxnId);
      if (response.status == 200) {
        setDetails(response.data);
      }
    }
  };

  const getDocument = async (context, category, setDocument) => {
    const userTrxnId = getUserEnquiryId();
    getDocuments(context, userTrxnId, category).then((res) => {
      if (res.status == 200) {
        setDocument(res.data);
      }
    });
  };

  console.log("dischargeDoc================= ", dischargeDoc);

  useEffect(() => {
    getAllActivity(types);
    getAllUpdatedActivityDetail();
    getAllActivityDetail();
  }, [types]);

  useEffect(() => {
    getDocument(context.discharge, category.discharge, setDischargeDoc);
    getDocument(context.summary, category.summary, setSummaryDoc);
    getDocument(context.insurance, category.insurance, setDocInsurance);
    getDocument(context.prescription, category.prescription, setDocPres);
  }, []);

  return (
    <div style={{ padding: "50px 15px 15px 15px" }}>
      <div style={{ display: "flex" }}>
        <button
          onClick={() => setType("OP")}
          style={{
            width: 100,
            height: 40,
            marginRight: 10,
            backgroundColor: types == "OP" ? "#00997c" : "grey",
          }}
        >
          OP Activity
        </button>
        <button
          onClick={() => setType("IP")}
          style={{
            width: 100,
            height: 40,
            marginRight: 10,
            backgroundColor: types == "IP" ? "#00997c" : "grey",
          }}
        >
          IP Activity
        </button>
      </div>

      <Card style={{ padding: 15 }}>
        <h1>Karebuddy Activity</h1>
        <br />
        <StepperComponent steps={steps} activities={activities} />
      </Card>
      <br />
      <h1>{types} Details</h1>
      <br />
      <Card style={{ padding: 15 }}>
        {types == "OP" ? (
          details?.opDoneDetail ? (
            <>
              <div
                style={{
                  display: "flex",
                }}
              >
                <DetailsItem
                  head="Consultation Status"
                  sub={details?.opDoneDetail?.userTxnSubStatus?.name}
                />
                {details?.opDoneDetail?.surgeryDate && (
                  <DetailsItem
                    head="Potential Surgery Date"
                    sub={`${moment(details?.opDoneDetail?.surgeryDate).format(
                      "DD-MM-YYYY"
                    )}`}
                  />
                )}
                {details?.opDoneDetail?.paymentType && (
                  <DetailsItem
                    head="Potential Payment Type for Surgery"
                    sub={details?.opDoneDetail?.paymentType}
                  />
                )}
                <DetailsDoc
                  head="Insurance Pre-Auth Document"
                  docs={docInsurance}
                />
                <DetailsDoc head="Prescription" docs={docPres} />
              </div>
              <hr />
              <div style={{ display: "flex" }}>
                <DetailsItem
                  head="Comments"
                  sub={details?.opDoneDetail?.remarks}
                />
              </div>
            </>
          ) : (
            <h3 style={{ color: "grey" }}>No Details Updated</h3>
          )
        ) : details?.patientDischargedDetail?.billAmount ||
          details?.admittedDetail?.paymentType ? (
          <>
            <div
              style={{
                display: "flex",
              }}
            >
              <DetailsItem
                head="Bill Amount"
                sub={details?.patientDischargedDetail?.billAmount}
              />
              <DetailsItem
                head="Payment Type"
                sub={details?.admittedDetail?.paymentType}
              />
              <DetailsDoc head="Discharge Bill" docs={dischargeDoc} />
              <DetailsDoc head="Discharge Summary" docs={summaryDoc} />
            </div>
            <hr />
            <div style={{ display: "flex" }}>
              <DetailsItem
                head="Comments"
                sub={details?.admittedDetail?.remarks}
              />
            </div>
          </>
        ) : (
          <h3 style={{ color: "grey" }}>No Details Updated</h3>
        )}
      </Card>
    </div>
  );
}
