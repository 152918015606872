export function getBreakButtonText(isOnBreak) {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  let breakText = "☕";
  if (currentHour >= 7 && currentHour < 11) {
    breakText = "☕";
  } else if (currentHour >= 12 && currentHour <= 14) {
    breakText = "🍔";
  } else if (currentHour >= 15 && currentHour < 18) {
    breakText = "☕";
  }

  return isOnBreak
    ? `I'm on a break ${breakText}`
    : `Take a break ${breakText}`;
}
