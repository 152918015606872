import React from "react";
import Sidebar from "../Sidebar";
import useMykarePartnerShip from "./useMykarePartnerShip.logic";

const MyKarePartnerShipDetail = () => {
  const state = useMykarePartnerShip();

  return (
    <div className="d-flex">
      <Sidebar />
      <div className={state.classes.root}>
        <button className="width1" onClick={() => state.back()}>
          BACK
        </button>
        <hr />
        <div>
          <div className="row">
            <div className="col-md-2">
              <p className="text-start">EnquiryId</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{state.data.enquiryId}</div>
              </p>
            </div>
            <div className="col-md-2">
              <p className="text-start">Name</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{state.data.name}</div>
              </p>
            </div>
            <div className="col-md-2">
              <p className="text-start ">Email</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{` ${state.data.email}`}</div>
              </p>
            </div>
            <div className="col-md-2">
              <p className="text-start ">Phone</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{` ${state.data.phone}`}</div>
              </p>
            </div>
            <div className="col-md-2">
              <p className="text-start ">Type</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{`${state.data.enquiryType}`}</div>
              </p>
            </div>
            <div className="col-md-2 capitalize">
              <p className="text-start ">Status</p>
              <p
                className={
                  state.data.status === "replied" ? "text-2" : "text-1"
                }
              >
                {!state.replied ? state.data.enquiryStatus : "replied"}
              </p>
            </div>
          </div>

          <div className="marginleft border-top" style={{ marginTop: 20 }}>
            <div>
              {!state.replied && (
                <div className="row border-top pt-4">
                  <div className="col-6">
                    <textarea
                      value={state.comments}
                      className="comments-section"
                      rows={5}
                      placeholder=" Comments"
                      onChange={(e)=>state.handleComments(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="col-6">
                    <div>
                      <button onClick={()=>state.postCommentsApi()} style={state.buttonStyle}>Save</button>
                     
                    </div>
                  </div>
                  <p style={{color:"green"}}>{state.saveMsg}</p>
                </div>
              )}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};
export default MyKarePartnerShipDetail;
