import { useState } from "react";

import moment from "moment";
import { getDownloadkaretripReportData } from "../../../services/karetrip/enquiryService";

function useKaretripDownloadPanel(props) {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const downloadReport = () => {
    const startDate = moment(fromDate).format().split("T")[0];
    const endDate = moment(toDate).format().split("T")[0];
    getDownloadkaretripReportData(startDate, endDate).then((res) => {});
  };

  return {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    downloadReport,
  };
}

export default useKaretripDownloadPanel;
