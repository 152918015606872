import axios from "axios";
import { getAuthHeader, getUserCode, getUserRole } from "../../store/DataStore";
import { APP_URL } from "../../util/AppConstants";
import { checkPermission } from "../../util/permissionUtils";

export const clickToCall = async (userTrxnId, phoneType, bridgePhoneId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const postData = {
    userTrxnId,
    phoneType,
    bridgePhoneId,
  };
  const response = await axios.post(
    `${APP_URL}/mykare/call/clickToCall`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const feedbackCall = async (userTrxnId, phoneType) => {
  const agentCode = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };
  const postData = {
    userTrxnId,
    phoneType,
    agentCode,
  };
  const response = await axios.post(
    `${APP_URL}/mykare/call/feedbackCall`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const makeACall = async (postData) => {
  const agentCode = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };
  postData.agentCode = agentCode;
  const response = await axios.post(
    `${APP_URL}/mykare/call/supportCall`,
    postData,
    {
      headers,
    }
  );
  return response;
};


export const createACall = async (postData) => {
  const agentCode = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };
  postData.agentCode = agentCode;
  const response = await axios.post(
    `${APP_URL}/mykare/call/makeACall`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const setScheduleAutoCall = async (
  fromDate,
  toDate,
  statusId,
  userCode
) => {
  try {
    const code = getUserCode();
    const role = getUserRole();
    const agentCode = role !== "SALES_AGENT" ? userCode : code;

    if (!agentCode || !statusId) {
      alert(
        !agentCode
          ? "Agent must be selected"
          : "You forgot to choose the status"
      );
    }

    const headers = {
      Authorization: getAuthHeader(),
    };

    const postData = {
      fromDate,
      toDate,
      statusId,
      assignedTo: agentCode,
    };

    const response = await axios.post(
      `${APP_URL}/mykare/call/clickToAutoCall`,
      postData,
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    // Handle error or display appropriate error message to the user
    console.error("Error while setting schedule:", error.message);
    throw error; // Re-throw the error to be caught by the caller or error boundary
  }
};

export const stopAutoCall = async () => {
  const agentCode = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };
  const postData = {
    isStop: true,
    assignTo: agentCode,
  };
  const response = await axios.post(
    `${APP_URL}/mykare/call/schedule/stop`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getCallSchedules = async () => {
  const agentCode = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/call/schedule?assignTo=${agentCode}`,
    {
      headers,
    }
  );
  return response;
};
