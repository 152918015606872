import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SidebarHeader,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import arrowIcon from "../assets/arrow-icon.svg";

import {
  FiUsers,
  FiHeart,
  FiBarChart,
  FiLogOut,
  FiSettings,
  FiPhoneCall,
  FiUserX,
} from "react-icons/fi";
import { TbReportAnalytics } from "react-icons/tb";
import "../Styles/sideBar.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { TiLocationArrow } from "react-icons/ti";
import { checkPermission } from "../util/permissionUtils";
import MakeCall from "../components/MakeCallComponent/MakeCall";

const Sidebar = () => {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const item = localStorage.getItem("user-role");
  const userRol = item?.includes("_") ? item.replaceAll("_", " ") : item;
  const name = localStorage.getItem("user-name");
  const userName = name?.split("@")[0];

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  let history = useHistory();

  if (!localStorage.getItem("token")) {
    history.push("/");
  }
  function Logout() {
    const confirmBox = window.confirm("Are you sure you want to logout?");
    if (confirmBox === true) {
      localStorage.removeItem("token");
      history.push({
        pathname: "/",
      });
    }
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const mykarePlatformClick = () => {
    if (item === "INTL_SALES_HEAD") {
      return <NavLink to="/addOnService" />;
    } else {
      return <NavLink to="/enquiry" />;
    }
  };

  const kareTripPlatformClick = () => {
    return <NavLink to="/kareTripEnquiry" />;
  };

  return (
    <ProSidebar collapsed={menuCollapse} className="border-gray">
      <SidebarHeader>
        <div className="logotext">
          <p className="admin-tag">
            {userName && !menuCollapse ? userName : ""}
          </p>
          <p className="admin-tag">{userRol && !menuCollapse ? userRol : ""}</p>
        </div>
        <div className="closemenu" onClick={menuIconClick}>
          {menuCollapse ? (
            <img
              src={arrowIcon}
              style={{
                width: "20px",
                height: "20px",

                marginBottom: "130px",
                marginTop: "695px",
                marginRight: "25px",
              }}
            />
          ) : (
            <img
              src={arrowIcon}
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "100%",
                marginBottom: "130px",
                marginTop: "2px",
                transform: "rotate(180deg)",
                marginTop: "695px",
                marginRight: "25px",
              }}
            />
          )}
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="square" style={{ marginTop: 10 }}>
          {checkPermission("ASSOCIATE_VIEW") && (
            <>
              <MenuItem
                className="icon-cont"
                title="Partner Platform"
                id="partnerPlatform"
                active={true}
                icon={<FiUsers className="icon-blck" />}
              >
                Partner Platform
                <NavLink to="/home" />
              </MenuItem>
              <hr className="margin"></hr>
            </>
          )}
          {checkPermission("KAREMITRA_PLATFORM_VIEW") && (
            <>
              <MenuItem
                className="icon-cont"
                title=" Karemitra Platform"
                id="karemitraPlatform"
                active={true}
                icon={<FiUserX className="icon-blck" />}
              >
                Karemitra Platform
                {/* <NavLink to="/home" /> */}
                <a
                  href={`${process.env.REACT_APP_REPORT_MODULE_URL}/karemitra`}
                  target="_blank"
                />
              </MenuItem>
              <hr className="margin"></hr>
            </>
          )}

          {checkPermission("MYKARE_PLATFORM_VIEW") && (
            <>
              <MenuItem
                className="icon-cont"
                title="Mykare Platform"
                id="mykarePlatform"
                icon={<FiHeart className="icon-blck" />}
              >
                Mykare Platform
                {mykarePlatformClick()}
              </MenuItem>
              <hr className="margin"></hr>
            </>
          )}

          {checkPermission("KARE_TRIP_PLATFORM_VIEW") && (
            <>
              <MenuItem
                className="icon-cont"
                title="Kare Trip Platform"
                id="kareTripPlatform"
                icon={<TiLocationArrow className="icon-blck" />}
              >
                Kare Trip Platform
                {kareTripPlatformClick()}
              </MenuItem>
              <hr className="margin"></hr>
            </>
          )}

          {checkPermission("REPORTS_PLATFORM_VIEW") && (
            <>
              <MenuItem
                className="icon-cont"
                title="Reports"
                id="reports"
                icon={<TbReportAnalytics className="icon-blck" />}
              >
                Reports
                <a
                  href={`${process.env.REACT_APP_REPORT_MODULE_URL}/kareflow-report/mykare-reports/leads-report`}
                  target="_blank"
                />
              </MenuItem>
              <hr className="margin"></hr>
            </>
          )}

          {checkPermission("SUMMARY_VIEW") && (
            <>
              <MenuItem
                className="icon-cont"
                title="Mykare Summary"
                id="mykareSummary"
                icon={<FiBarChart className="icon-blck" />}
              >
                Summary
                <NavLink to="/summary" />
              </MenuItem>
              <hr className="margin"></hr>
            </>
          )}
          {checkPermission("SETTINGS_VIEW") && (
            <>
              <MenuItem
                className="icon-cont"
                title="Sidebar Settings"
                id="sidebarSettings"
                icon={<FiSettings className="icon-blck" />}
              >
                Settings
                <NavLink to="/sidebarsettings" />
              </MenuItem>
              <hr className="margin"></hr>
            </>
          )}
          <MakeCall />
          {checkPermission("MAKE_A_CALL_BTN") && (
            <>
              <MenuItem
                className="icon-cont"
                title="Make a call"
                id="makeACall"
                onClick={() => handleClickOpen()}
                icon={<FiPhoneCall className="icon-blck" />}
              >
                Make Call
              </MenuItem>
              <hr className="margin"></hr>
            </>
          )}

          <MenuItem
            className="icon-cont"
            title="Logout"
            id="logout"
            onClick={() => Logout()}
            icon={<FiLogOut className="icon-blck" />}
          >
            Logout
          </MenuItem>
          <div class="mb-auto"></div>
          <MakeCall setOpen={setOpen} open={open} />
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
};
export default Sidebar;
