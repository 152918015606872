import axios from "axios";
import { APP_URL } from "../../util/AppConstants";

export const getAllDocuments = async (contextId, context, category) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/document/all?contextId=${contextId}&context=${context}&category=${category}`,
    {
      headers,
    }
  );
  return response;
};

export const uploadDocuments = async (postData) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/document/all`,
    postData,
    {
      headers,
    }
  );
  return response;
};
export const uploadArrivalDocuments = async (postData) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.post(
    `${APP_URL}/karetrip/arrival/uploadDoc`,
    postData,
    {
      headers,
    }
  );
  return response;
};
export const uploadVilDocuments = async (postData) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.post(
    `${APP_URL}/karetrip/vil/uploadDoc`,
    postData,
    {
      headers,
    }
  );
  return response;
};
export const uploadUserDocuments = async (postData) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.post(
    `${APP_URL}/karetrip/user/uploadDoc`,
    postData,
    {
      headers,
    }
  );
  return response;
};
