import axios from "axios";
import "../../../Styles/Common.css";
import "../../../Styles/sideBar.css";
import "../../../Styles/Pagination.css";
import "react-pro-sidebar/dist/css/styles.css";
import ListItem from "@material-ui/core/ListItem";
import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory, useLocation } from "react-router-dom";
import * as appConstants from "../../../util/AppConstants";
import { useStyles } from "../../../components/multi-select";
import { getFilteredPatients } from "../../../services/associate/patientService";

const usePatient = () => {
  let history = useHistory();
  const location = useLocation();
  const [perPage] = useState(20);
  const [offset, setOffset] = useState(0);
  const [homeData, setHomeData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [filterLoaded, setFilterLoaded] = useState(true);
  const [reload, setReload] = useState(false);
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [arrivalCount, setArrivalCount] = useState(0);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [statsLoaded, setStatsLoaded] = useState(false);

  useEffect(() => {
    setStatsLoaded(false);
    getStatistics();
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.offset) {
        setOffset(location.state.offset);
        setReload(true);
      }
    } else {
      setReload(true);
    }
  }, []);

  const [pageData, setPageData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [enquiryStats, setEnquiryStats] = useState(null);
  const [searchItem, setSearchItem] = useState("");

  const handleSearchItemChange = (e) => {
    setSearchItem(e.target.value);
  };

  useEffect(() => {
    setDataLoaded(false);
    getData(offset);
  }, [offset]);

  useEffect(() => {
    if (!pageData && !allData) return;
    loadFilteredData(searchItem);
  }, [searchItem]);

  useEffect(() => {
    handleFilterdByPatients();
  }, []);

  const handleFilterdByPatients = async (event, type) => {
    await getFilteredPatients(offset, perPage).then((res) => {
      const data = res.data.patients;
      setPageData(data);
    });
  };

  const loadFilteredData = async (searchItem) => {
    const response = await getFilteredPatients(0, perPage, searchItem);
    if (response) {
      const data = response.data.patients;
      setPageData(data);
      setPageCount(Math.ceil(response.data.count / perPage));
    }
  };

  useEffect(() => {

    if (!!pageData) {
      const postData = pageData.map((data, index) => (
       
        <div
          key={index}
          className="row pointer scroll"
          onClick={() => handleClickTale(data)}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="container col-1 m-0" style={{ width: 70 }}>
            <ListItem>
              <ListItemText
                primary={`${
                  offset === 0 ? index + 1 : offset * 20 + (index + 1)
                }`}
              />
            </ListItem>
          </div>
          <div className="container col-1 m-0">
            <ListItem>
              <ListItemText primary={`${data.id}`} />
            </ListItem>
          </div>
          <div className="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>

            <ListItem>
              <ListItemText primary={`${data.name}`} />
            </ListItem>
          </div>
          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={`${data.mobile}`} />
            </ListItem>
          </div>
          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={`${data.age}`} />
            </ListItem>
          </div>
          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={`${data.country}`} />
            </ListItem>
          </div>
          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={`${data.state}`} />
            </ListItem>
          </div>
          <hr />
        </div>
      ));
      setHomeData(postData);
    }
  }, [pageData]);

  const getTotalPatientEnquiries = async (offset, perPage) => {
    try {
      getFilteredPatients(offset, perPage).then((res) => {
        setEnquiryStats(res.data);
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (reload) {
      setDataLoaded(false);
      getTotalPatientEnquiries(offset, perPage);
      getData(offset, perPage, searchItem);
      setReload(false);
    }
  }, [reload, offset]);

  const getData = async (offset, perPage) => {
    getFilteredPatients(offset, perPage)
      .then((res) => {
        const data = res.data.patients;
        setPageCount(Math.ceil(res.data.count / perPage));
        setPageData(data);
        setAllData(data);
      })
      .catch((error) => {})
      .finally(() => {
        setDataLoaded(true);
      });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage);
    if (selectedPage != offset) {
      setReload(true);
    }
  };

  const getStatistics = async () => {
    axios
      .get(appConstants.APP_URL + "/internal/partner/getStatistics", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const data = res.data;
        setTotalCount(data.totalCount);
        setPendingCount(data.pendingCount);
        setArrivalCount(data.arrivalCount);
        setAppointmentCount(data.appointmentCount);
      })
      .catch((error) => {})
      .finally(() => {
        setStatsLoaded(true);
      });
  };

 
  function handleClickTale(enquiryData,patientId) {
    history.push({
      pathname: "/PartnerPatientDetail",
      state: {
        enquiryDetails: enquiryData,
        offset,
      },
    });
  }

  const classes = useStyles();

  return {
    offset,
    classes,
    homeData,
    useStyles,
    pageCount,
    dataLoaded,
    setDataLoaded,
    handleClickTale,
    handlePageClick,
    filterLoaded,
    setReload,
    enquiryStats,
    name,
    setName,
    searchItem,
    handleSearchItemChange,
    phoneNumber,
    setPhoneNumber,
    totalCount,
    pendingCount,
    arrivalCount,
    appointmentCount,
  };
};
export default usePatient;
