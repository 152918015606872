import axios from "axios";
import {
  getAuthHeader,
  getUserCode,
  getUserName,
  getUserRole,
} from "../../store/DataStore";
import { APP_URL } from "../../util/AppConstants";

export const uploadInsuranceDocs = async (postData) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/insurance/uploadDocs`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const updateInsuranceDetails = async (postData) => {
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.put(
    `${APP_URL}/mykare/insurance/updateDetails`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getInsuranceDetails = async (detailId) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/insurance/details/${detailId}`,
    {
      headers,
    }
  );
  return response;
};

export const getFilteredInsuranceEnquiries = async (offset, perPage, name, phoneNumber, selectedStatusIds) => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const postData = {
    pageNo: offset,
    pageSize: perPage,
    role,
    code,
    patientName: name,
    phoneNumber,
    insuranceStatusIds: selectedStatusIds
  };
  const response = await axios.post(
    `${APP_URL}/mykare/insurance/filtered`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getRejectedReasons = async (statusId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/internal/mykare/reasons?reasonContext=INSURANCE&statusId=${statusId}`,
    {
      headers,
    }
  );
  return response;
};

export const getAllInsuranceStatuses = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.get(
    `${APP_URL}/internal/mykare/insurance/status`,
    {
      headers,
    }
  );
  return response;
};
