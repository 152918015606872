import React from "react";
import "./PopupMain.css";

function PopupQuestionTemp(props) {
    const { popup, handletoggle } = props;

    return (
        <div className="Popup">
            {/* close button */}
            <button className="Popup-close-btn" onClick={handletoggle}>
                ❌
            </button>
            <h2 className="Popup-title"> {popup.treatmentPackage}</h2>

            <ul>
                {popup.questions.map((ele, ind) => (
                    <li key={`${ind}:points`}>{ele}</li>
                ))}
            </ul>
        </div>
    );
}

export default PopupQuestionTemp;
