import React, { useState } from "react";
import "../../Enquiry/Enquiry.css";
import "../../../Styles/Common.css";
import "../../../Styles/sideBar.css";
import Sidebar from "../../Sidebar";
import "../../../Styles/Pagination.css";
import List from "@material-ui/core/List";
import ReactPaginate from "react-paginate";
import * as ReactBootstrap from "react-bootstrap";
import TopBar from "../../Enquiry/TopBar";
import useInsurance from "./useInsurancePage.logic";
import CustomLoader from "../../../components/CustomLoader";
import { checkPermission } from "../../../util/permissionUtils";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes";

function Insurance() {
  const state = useInsurance();


  const border = {
    width: "100%",
    height: "100vh",
    overflow: "scroll",
  };

  if (!state.dataLoaded) {
    return <CustomLoader />;
  }

  return (
    <div style={border}>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ width: "150%", padding: 10 }}>
          <div className="sticky">
            <div className="main-subContainer">
              <TopBar />
            </div>

            <br />

            <div
              className="row scroll"
              style={{
                minHeight: "3rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="container col-1 m-0" style={{ width: 70 }}>
                <b>SL.NO</b>
              </div>
              <div className="container col-1 m-0">
                <b>Enquiry Date</b>
              </div>
              <div className="container col-1 m-0">
                <b>Patient Name</b>
                <input
                  style={{
                    width: 140,
                    height: 36,
                    border: `1px solid #00997c`,
                    borderRadius: 5,
                    marginTop: 5,
                  }}
                  name="name"
                  placeholder="Search"
                  value={state?.name}
                  onChange={(event) => {
                    state.setName(event.target.value)
                  }}
                />
              </div>
              <div className="container col-1 m-0">
                <b>Phone Number</b>
                <input
                  style={{
                    width: 140,
                    height: 36,
                    border: `1px solid #00997c`,
                    borderRadius: 5,
                    marginTop: 5,
                  }}
                  name="phone"
                  placeholder="Search"
                  value={state?.phoneNumber}
                  onChange={(event) => {
                    state.setPhoneNumber(event.target.value)
                  }}
                />
              </div>

              {checkPermission("LEADS_ASSIGNED_FIELD") && (
                <div className="container col-1 m-0">
                  <b>Assigned To</b>
                </div>
              )}

              <div className="container col-1 m-0">
                <b>Insurance Status</b>
                <div
                  style={{
                    width: 140,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid  #00997c",
                    borderRadius: 5,
                    marginTop: 8,
                  }}
                >
                  <ReactMultiselectCheckboxes
                    options={state.statusData}
                    placeholderButtonLabel="Choose..."
                    getDropdownButtonLabel={state.getDropdownButtonLabel}
                    value={state.selectedStatuses}
                    onChange={(value, event) => state.handleSelectStatuses(value, event)}
                    setState={state.setSelectedStatuses}
                  />
                </div>
              </div>

              <div className="container col-1 m-0">
                <b>Insurance Company</b>
              </div>
            </div>
            <hr />
            <List component="nav" aria-label="mailbox folders">
              {state.homeData}
            </List>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={state.pageCount}
              initialPage={state.offset}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={state.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={state.offset}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Insurance;
