import axios from "axios";
import "../../../Styles/Common.css";
import "../../../Styles/sideBar.css";
import "../../../Styles/Pagination.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import * as ReactBootstrap from "react-bootstrap";
import ListItem from "@material-ui/core/ListItem";
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import "../../Enquiry/Enquiry.css";
import {
  insuranceStatusColor,
  statusColorFinder,
} from "../../../util/statusColorFinder";

import {
  getEnquiryStatistics,
  getUserTrxnStatuses,
  getDownloadReportData,
} from "../../../services/mykare/enquiryService";

import Tooltip from "@material-ui/core/Tooltip";
import { getAllInsuranceStatuses, getFilteredInsuranceEnquiries } from "../../../services/mykare/insuranceService";
import { checkPermission } from "../../../util/permissionUtils";

const useInsurance = () => {
  let history = useHistory();
  const location = useLocation();
  const [perPage] = useState(20);
  const [offset, setOffset] = useState(0);
  const [homeData, setHomeData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [filterLoaded, setFilterLoaded] = useState(true);
  const [enquiryStats, setEnquiryStats] = useState(null);
  const [reload, setReload] = useState(false);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");


  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedStatusIds, setSelectedStatusIds] = useState([0]);
  const [statusData, setStatusData] = useState([]);


  function getDropdownButtonLabel({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Choose...";
      return `${values}`;
    }
    return "Choose...";
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      marginTop: 10,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));

  const tooltipStyles = {
    tooltip: {
      fontSize: "20px",
      borderRadius: "18px",
      boxShadow: "0 20px 80px 0",
      backgroundColor: "#FFFFE0",
      color: "black",
    },
  };

  const [pageData, setPageData] = useState(null);
  const [allData, setAllData] = useState(null);

  const [selectStatus, setSelectStatus] = useState({
    list: [],
    selected: "ALL",
  });

  const loadStatus = async () => {
    try {
      const response = await getUserTrxnStatuses();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      setSelectStatus((pre) => ({ ...pre, list: status }));
    } catch (error) { }
  };


  useEffect(() => {
    if (location.state) {
      if (location.state.offset || location.state.selectedStatus) {
        setSelectStatus((s) => ({
          ...s,
          selected: location.state.selectedStatus,
        }));
        setOffset(location.state.offset);
        setReload(true);
      }
    } else {
      setReload(true);
    }
  }, []);

  useEffect(() => {
    handleFilterdByInsurance()
  }, [name, phoneNumber])


  const handleFilterdByInsurance = async (event, type) => {

    await getFilteredInsuranceEnquiries(offset, perPage, name, phoneNumber, selectedStatusIds).then((res) => {
      const data = res.data.insuranceEnquiries;
      setPageData(data)
    })
  }

  const loadInsuranceStatus = async () => {
    try {
      const response = await getAllInsuranceStatuses();
      setStatusData(response.data);
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setStatusData(data);
    } catch (error) { }
  };

  const handleSelectStatuses = async (value, event) => {
    let ids;
    if (event.option.value === 0) {
      setSelectedStatuses(
        selectedStatuses.length === statusData.length ? [] : statusData
      );
      ids = statusData.map((item) => item.value);
      ids = selectedStatuses.length === statusData.length ? [0] : ids

    } else if (event.action === "select-option") {
      if (value.length + 1 === statusData.length) {
        value = statusData;
      }
      setSelectedStatuses(value);
      ids = value.map((item) => item.value);

    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedStatuses(data);
      ids = data.map((item) => item.value);
    }
    await getFilteredInsuranceEnquiries(offset, perPage, name, phoneNumber, ids).then((res) => {
      const data = res.data.insuranceEnquiries;
      setPageData(data)
    })
  };


  const getTotalInsuranceEnquiries = async (offset, perPage) => {
    try {
      getFilteredInsuranceEnquiries(offset, perPage).then((res) => {
        setEnquiryStats(res.data);
      });
    } catch (error) { }
  };

  useEffect(() => {
    loadInsuranceStatus();
    loadStatus();
    //setDataLoaded(true);
    if (reload) {
      setDataLoaded(false);
      getTotalInsuranceEnquiries(offset, perPage);
      getData(offset, perPage);
      setReload(false);
    }
  }, [reload, offset]);

  const loadFilteredData = async () => {
    getFilteredInsuranceEnquiries(0, perPage)
      .then((res) => {
        const data = res.data.insuranceEnquiries;
        setPageCount(Math.ceil(res.data.count / perPage));
        setPageData(data);
      })
      .catch((error) => { })
      .finally(() => {
        //setDataLoaded(true);
      });
  };

  useEffect(() => {
    // console.log("===useEffect1=======");
    if (!!pageData) {
      const postData = pageData.map((data, index) => (
        <div
          key={index}
          className="row pointer scroll"
          onClick={() => handleClickTale(data)}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="container col-1 m-0" style={{ width: 70 }}>
            <ListItem>
              <ListItemText
                primary={`${offset === 0 ? index + 1 : offset * 20 + (index + 1)
                  }`}
              />
            </ListItem>
          </div>
          <div className="container col-1 m-0">
            <ListItem>
              <ListItemText primary={`${data.enquiryDate.split("T")[0]}`} />
            </ListItem>
            <p style={{ textAlign: "left" }}>{`${data.enquiryDate.split("T")[1].split(".")[0]
              }`}</p>
          </div>
          <div className="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>

            <ListItem>
              <ListItemText primary={` ${data.patientName}`} />
            </ListItem>
          </div>
          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={` ${data.phoneNumber}`} />
            </ListItem>
            {data.alternateContact && (
              <p style={{ textAlign: "left" }}>{`${data.alternateContact}`}</p>
            )}
          </div>
          {checkPermission("LEADS_ASSIGNED_FIELD") && (
            <div className="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText
                  primary={` ${data.assignToName ? data.assignToName : "Not Assigned"
                    }`}
                />
              </ListItem>
            </div>
          )}
          <div class="container col-1 m-0 capitalize">
            <p className={insuranceStatusColor(data.insuranceStatus)}>
              <ListItem>
                <ListItemText
                  className="status-bold"
                  primary={data.insuranceStatus ? data.insuranceStatus : ""}
                />
              </ListItem>
            </p>
          </div>
          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={` ${data.insuranceCompany}`} />
            </ListItem>
          </div>
          <hr />
        </div>
      ));
      setHomeData(postData);
    }
  }, [pageData]);

  const getData = async (offset, perPage) => {
    getFilteredInsuranceEnquiries(offset, perPage)
      .then((res) => {
        const data = res.data.insuranceEnquiries;
        setPageCount(Math.ceil(res.data.count / perPage));
        setPageData(data);
        setAllData(data);
      })
      .catch((error) => { })
      .finally(() => {
        setDataLoaded(true);
      });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage);
    if (selectedPage != offset) {
      setReload(true);
    }
  };

  function handleClickTale(enquiryData) {
    history.push({
      pathname: "/insuranceInner",
      state: {
        enquiryDetails: enquiryData,
        offset,
      },
    });
  }

  const classes = useStyles();
  const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

  return {
    offset,
    classes,
    homeData,
    useStyles,
    pageCount,
    dataLoaded,
    setDataLoaded,
    handleClickTale,
    handlePageClick,
    enquiryStats,
    selectStatus,

    filterLoaded,

    setReload,

    name,
    setName,

    handleFilterdByInsurance,
    phoneNumber,
    setPhoneNumber,

    statusData,
    getDropdownButtonLabel,

    handleSelectStatuses,
    setSelectedStatuses,
    selectedStatuses,
    selectedStatusIds,
    setSelectedStatusIds,

  };
};
export default useInsurance;
