import moment from "moment";
import { async } from "q";
import { useState, useEffect } from "react";
import { getAllLocations } from "../../../services/mykare/centerService";
import {
  getCampaignCount,
  getCampaignSchedules,
  getCampaignTemplates,
  getTreatments,
  sendCallCampaign,
  sendCampaign,
} from "../../../services/mykare/dataService";
import {
  getSources,
  getUserStatus,
  getUserTrxnStatuses,
} from "../../../services/mykare/enquiryService";
import { getAllFlowTypeTemplates } from "../../../services/mykare/messageService";

export default function useCallCampaign() {
  const [selectedCampStatus, setCampStatus] = useState([]);
  const [selectedCampStatusId, setCampStatusId] = useState([]);
  const [selectedTreatment, setselectedTreatment] = useState([]);
  const [selectedTreatmentId, setselectedTreatmentId] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [statusLists, setStatusLists] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [template, setTemplate] = useState([]);
  const [locations, setLocations] = useState([]);
  const [schedules, setSchedules] = useState({});
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [selectedSource, setSelectedSources] = useState([]);
  const [selectedSourcesIds, setSelectedSourceIds] = useState([]);
  const [selectedTemplate, setselectedTemplate] = useState(0);
  const [preview, setPreview] = useState("preview");
  const [assignedData, setAssignedData] = useState({
    chooseDate: "All",
    fromDate: new Date(),
    toDate: new Date(),
  });
  const [searchEnquiryFromDate, setSearchEnquiryFromDate] = useState(
    new Date()
  );
  const [scheduleStartDateTime, setScheduleStartDateTime] = useState(
    new Date()
  );
  const [scheduleEndDateTime, setScheduleEndDateTime] = useState(new Date());
  const [searchEnquiryToDate, setSearchEnquiryToDate] = useState(new Date());
  const [enquiryDateFilter, setEnquiryDateFilter] = useState([
    "All",
    "Today",
    "This Month",
    "Previous Month",
    "Choose Date",
  ]);
  const [selectedEnquiryDateFilter, setSelectedEnquiryDateFilter] =
    useState("All");

  function getDropdownButtonLabel({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Select";
      return `${values}`;
    }
    return "Select";
  }

  useEffect(() => {
    loadAllLocations();
    loadTreatments();
    loadSources();
    loadAllStatus();
    loadTemplates();
  }, []);

  const loadAllLocations = async () => {
    try {
      const response = await getAllLocations();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setLocations(data);
    } catch (error) {}
  };
  const loadAllStatus = async () => {
    try {
      const response = await getUserTrxnStatuses();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setStatusLists(data);
    } catch (error) {}
  };

  const loadTreatments = async () => {
    try {
      const response = await getTreatments();
      var defaultData = [{ id: 0, name: "ALL" }];
      defaultData.push.apply(defaultData, response.data);
      const data = defaultData.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setTreatments(data);
    } catch (error) {}
  };

  const loadSources = async () => {
    try {
      const response = await getSources();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setSourceData(data);
    } catch (error) {}
  };

  const loadTemplates = async () => {
    try {
      getAllFlowTypeTemplates(null, true).then((res) => {
        if (res) {
          setTemplate(res);
        } else {
          setTemplate([]);
        }
      });
    } catch (error) {}
  };

  const checkDateRange = (date) => {
    const timeDiff = date - new Date();
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    if (minutesDiff >= 10) {
      state.setScheduleStartDateTime(date);
    } else {
      alert("Please select a time range 10 minutes greater than current time");
    }
  };

  const createCallCampign = async () => {
    const startDate = moment(searchEnquiryFromDate).format().split("T")[0];
    const endDate = moment(searchEnquiryToDate).format().split("T")[0];
    const scheduleFromDate = moment(scheduleStartDateTime)
      .format()
      .split("+")[0];
    const scheduleEndDate = moment(scheduleEndDateTime).format().split("+")[0];
    const postData = {
      locationIds: selectedLocationId,
      treatmentIds: selectedTreatmentId,
      statusIds: selectedCampStatusId,
      flowTypeId: selectedTemplate.flowTypeId,
      enquiryFromDate: startDate,
      enquiryToDate: endDate,
      sourceIds: selectedSourcesIds,
      scheduleStartDateTime: scheduleFromDate,
      scheduleEndDateTime: scheduleEndDate,
      enquiryDateFilter: assignedData.chooseDate,
    };
    if (postData.flowTypeId && postData.enquiryDateFilter) {
      await sendCallCampaign(postData).then((res) => {
        if (
          res.data.count === 0 ||
          res.data.count === null ||
          res.data.count === undefined
        ) {
          alert("No Leads found");
        } else {
          if (
            window.confirm(
              "Found " +
                res.data.count +
                "Patients to schedule IVR call, Please confirm"
            )
          ) {
            postData.schedule = true;
            sendCallCampaign(postData).then((res) => {
              alert("Set Schedule successfully!");
            });
          }
        }
      });
    } else {
      alert("Select All Fields to create campaign");
    }
  };

  const handleSelecCampStatus = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setCampStatus(
        selectedCampStatus.length === statusLists.length ? [] : statusLists
      );
      ids = statusLists.map((item) => item.id);
      setCampStatusId(
        selectedCampStatus.length === statusLists.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === statusLists.length) {
        value = statusLists;
      }
      setCampStatus(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setCampStatusId(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setCampStatus(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setCampStatusId(ids);
    }
  };

  const handleSelectedLocation = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedLocation(
        selectedLocation.length === locations.length ? [] : locations
      );
      ids = locations.map((item) => item.id);
      setSelectedLocationId(
        selectedLocation.length === locations.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === locations.length) {
        value = locations;
      }
      setSelectedLocation(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedLocationId(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedLocation(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedLocationId(ids);
    }
  };

  const handleSelectedTreatment = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setselectedTreatment(
        selectedTreatment.length === treatments.length ? [] : treatments
      );
      ids = treatments.map((item) => item.id);
      setselectedTreatmentId(
        selectedTreatment.length === treatments.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === treatments.length) {
        value = treatments;
      }
      setselectedTreatment(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setselectedTreatmentId(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setselectedTreatment(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setselectedTreatmentId(ids);
    }
  };

  const handleSelectSources = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedSources(
        selectedSource.length === sourceData.length ? [] : sourceData
      );
      ids = sourceData.map((item) => item.value);
      setSelectedSourceIds(
        selectedSource.length === sourceData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === sourceData.length) {
        value = sourceData;
      }
      setSelectedSources(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedSourceIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedSources(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedSourceIds(ids);
    }
  };

  console.log(template);

  return {
    handleSelecCampStatus,
    getDropdownButtonLabel,
    selectedCampStatus,
    setCampStatus,
    statusLists,
    locations,
    setSelectedLocation,
    selectedLocation,
    setselectedTreatment,
    selectedTreatment,
    treatments,
    enquiryDateFilter,
    searchEnquiryFromDate,
    searchEnquiryToDate,
    setSearchEnquiryFromDate,
    setSearchEnquiryToDate,
    selectedEnquiryDateFilter,
    setSelectedEnquiryDateFilter,
    setAssignedData,
    assignedData,
    setselectedTemplate,
    selectedTemplate,
    template,
    preview,
    setPreview,
    createCampign: createCallCampign,
    schedules,
    handleSelectedLocation,
    handleSelectedTreatment,
    sourceData,
    handleSelectSources,
    selectedSource,
    setSelectedSources,
    setScheduleStartDateTime,
    scheduleStartDateTime,
    scheduleEndDateTime,
    setScheduleEndDateTime,
    checkDateRange,
  };
}
