import moment from "moment";
import { useState, useEffect } from "react";
import { getAllLocations } from "../../services/mykare/centerService";
import { getTreatments } from "../../services/mykare/dataService";
import { getSources } from "../../services/mykare/enquiryService";
import { getAllReports } from "../../services/mykare/reportService";
import { getAgentData } from "../../services/commonService/commonService";

function useReport() {
  const [selectedRange, setSelectedRange] = useState("Today");
  const [selectedType, setSelectedType] = useState('All');
  const [selectedAgentName, setSelectedAgentName] = useState("ALL");
  const [selectedAgentCode, setSelectedAgentCode] = useState("All");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedLocation, setSelectedLocation] = useState("ALL");
  const [locations, setLocations] = useState([]);
  const [selectedTreatment, setselectedTreatment] = useState("ALL");
  const [treatments, setTreatments] = useState([]);
  const [selectedSource, setSelectedSource] = useState("");
  const [reportData, setReportData] = useState({})
  const [range, setRange] = useState([
    { id: 1, name: "Today" },
    { id: 2, name: "Yesterday" },
    { id: 3, name: "LastWeek" },
    { id: 4, name: "ThisMonth" },
    { id: 5, name: "PreviousMonth" },
    { id: 6, name: "Choose Date" }
  ]);

  const [type, setType] = useState([
    "ALL",
    "Leads",
    "Preventive Care",
    "SMO",
    "Add On Service",
  ]);

  const [assignedUser, setAssignedUser] = useState({
    list: [{ id: 0, name: "ALL", code: "ALL" }],
    selected: "ALL",
  });

  const [sources, setSources] = useState({
    list: [{ id: 0, name: "ALL" }],
    selected: "ALL",
  });

  useEffect(() => {
    loadAssignedUsers();
    getReportsData();
    loadAllLocations();
    loadTreatments();
    getSource();
  }, []);
  const loadAssignedUsers = async () => {
    try {
      const res = await getAgentData(false);
      var users = [{ id: 0, name: "All", code: "All" }];
      users.push.apply(users, res.data.users);
      //  users.push({ id: -1, name: "Not Assigned", code: "NOT_ASSIGNED" });
      setAssignedUser((s) => ({ ...s, list: users }));
    } catch (error) {

    }
  };

  const getSource = async () => {
    const response = await getSources();
    var sources = [{ id: 0, name: "All" }];
    if (response.status === 200) {
      if (response.data) {
        sources.push.apply(sources, response.data);

        setSources((s)=>({...s,list:sources}))
      }
    }
  }

  const loadAllLocations = async () => {

    try {
      getAllLocations().then((data) => {
        let allLocations = [{ id: 0, name: "ALL" }]
        allLocations.push.apply(allLocations, data)
        setLocations(allLocations);
      });
    } catch (error) {
    }
  };

  const loadTreatments = async () => {
    try {
      getTreatments().then((res) => {
        let allTreatments = [{ id: 0, name: "ALL" }]
        allTreatments.push.apply(allTreatments)
        setTreatments(res.data);
      });
    } catch (error) {

    }
  };

  const getReportsData = async () => {
    const startDate = moment(fromDate).format().split("T")[0]
    const endDate = moment(toDate).format().split("T")[0]
    try {

      const postData = {
        range: selectedRange,
        assignTo: selectedAgentCode,
        fromDate: startDate,
        toDate: endDate,
        sourceId: selectedSource.id ? selectedSource.id : 0,
        treatmentId: selectedTreatment.id ?  selectedTreatment.id : 0,
        locationId: selectedLocation.id ? selectedLocation.id : 0,
        type: selectedType
      };





      getAllReports(postData).then((data) => {
        setReportData(data);
      });
    } catch (error) {

    }
  };

  return {
    range,
    selectedRange,
    selectedAgentName,
    setSelectedRange,
    setSelectedAgentName,
    assignedUser,
    reportData,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    getReportsData,
    setSelectedAgentCode,
    locations,
    setSelectedLocation,
    selectedLocation,
    selectedTreatment,
    setselectedTreatment,
    treatments,
    type,
    selectedType,
    setSelectedType,
    selectedSource,
    setSelectedSource,
    sources,
    setSources,
  };
}

export default useReport;
