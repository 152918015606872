import React, { useEffect } from "react";
import Sidebar from "../Sidebar";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import { statusColorFinder } from "../../util/statusColorFinder";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { getAdditionalInfo } from "../../util/getAdditionalInfo";
import { checkPermission } from "../../util/permissionUtils";
import { FiPlay } from "react-icons/fi";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { DialogContent, Button, Box } from "@mui/material";
// import useMykareEnquiry from "./useMykareEnquiry.logic";
import useKareplusDetails from "./useKareplusDetails";
import { useParams } from "react-router-dom";
import downloadDocument from "../../util/documentUtil";
import Link from "@material-ui/core/Link";

const KarePlusDetail = () => {
  const { categoryContextId, userTxnCode } = useParams();

  const state = useKareplusDetails();
  const [document, setdocument] = useState([]);

  const msgs = ["hey", "hellow", "ok", "bye", "welcome"];
  // const getMessage = (msg) => {};

  const userRole = localStorage.getItem("user-role");
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));

  const classes = useStyles();

  const getStatusColor = (status) => {
    switch (status) {
      case "Success":
        return "green";
      case "Failure":
        return "red";
      case "Aborted":
        return "orange";
      case "Pending":
        return "orange";
      default:
        return "black";
    }
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div className={classes.root}>
        <button className="width1" onClick={() => state.back()}>
          BACK
        </button>
        <hr />
        <div className="row">
          {state.enquiryDate && checkPermission("LEADS_ENQUIRY_DATE_FIELD") && (
            <div className="col-md-1">
              <p className="text-start">Enquiry Date</p>
              <div className="ed-txt-1 text-start">
                <div className="textColor">
                  {state.data?.enquiryDate.split("T")[0]}
                </div>
              </div>
            </div>
          )}
          <div class="col-md-1">
            <p className="text-start">EnquiryId</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${state?.enquiryId}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">PatientName</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state.patientName}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">PhoneNumber</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state.phoneNumber}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Assign To</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state.assignTo}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Status</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state.status}</div>
            </p>
          </div>
          {/* <div class="col-md-1">
            <p className="text-start">Following Date</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state.followingDate}</div>
            </p>
          </div> */}
          <div class="col-md-1">
            <p className="text-start">Package Name</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state.scanName}</div>
            </p>
          </div>
          {/* <div class="col-md-1">
            <p className="text-start">Scan Sub Category</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state.scanSubCategory}</div>
            </p>
          </div> */}
          <div class="col-md-1">
            <p className="text-start">Origin</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state.origin}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Location</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state.location}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Price</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">₹{state.price}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Package For</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state?.userType}</div>
            </p>
          </div>
          {state?.userType == "OTHER" && (
            <div class="col-md-1">
              <p className="text-start">Person Name</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">
                  {state?.personDetail?.personName}
                </div>
              </p>
            </div>
          )}
          {state?.userType == "OTHER" && (
            <div class="col-md-1">
              <p className="text-start">Person Phone Number</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">
                  {state.personDetail?.personPhoneNumber}
                </div>
              </p>
            </div>
          )}
          <div class="col-md-1">
            <p className="text-start">Relationship</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">
                {state?.personDetail?.relationship}
              </div>
            </p>
          </div>

          <div class="col-md-2">
            <p className="text-start">Address</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state.personDetail?.address1}</div>
              <div className="textColor">{state.personDetail?.address2}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">DOB</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state?.personDetail?.dob}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Pincode</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{state?.personDetail?.pinCode}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Payment Status</p>
            <p className="ed-txt-1 text-start">
              <div
                className="textColor"
                style={{ color: getStatusColor(state.paymentStatus) }}
              >
                <b>{state.paymentStatus}</b>
              </div>
            </p>
          </div>
        </div>

        <hr />
        <div>
          <div style={{ display: "flex" }}>
            <p>Prescription: </p>
            <div>
              {state.prescriptionFiles && (
                <Link
                  style={{ marginLeft: "1rem" }}
                  className="pointer"
                  onClick={() =>
                    downloadDocument(
                      state?.prescriptionFiles[0]?.documentType,
                      state?.prescriptionFiles[0]?.document,
                      state?.prescriptionFiles[0].documentName
                    )
                  }
                >
                  Prescription
                </Link>
              )}
            </div>
          </div>
        </div>
        <hr></hr>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 80,
          }}
        >
          <div className="col-lg-1 col-6">
            <button
              className={
                state.selectedTab.isOpen ? "active-class" : "disabled-class"
              }
              style={{ padding: 10, width: 180 }}
              onClick={() => state.handleHideFun(1)}
            >
              Enquiry Update
            </button>
          </div>

          <div className="col-lg-1  col-6">
            <button
              className={
                state.selectedTab.isPatientsDocs
                  ? "active-class"
                  : "disabled-class"
              }
              style={{ padding: 10, width: 180 }}
              onClick={() => state.handleHideFun(2)}
            >
              Patient Docs
            </button>
          </div>

          <div className="col-lg-1  col-6">
            <button
              className={
                state.selectedTab.isPatientsDetail
                  ? "active-class"
                  : "disabled-class"
              }
              style={{ padding: 10, width: 180 }}
              onClick={() => state.handleHideFun(3)}
            >
              Patient Detail
            </button>
          </div>
        </div>
        {state.selectedTab.isOpen && (
          <>
            <div>
              <div className="marginleft border-top" style={{ marginTop: 20 }}>
                <div className="row border-top pt-4">
                  <div class="col-lg-6 col-10">
                    <textarea
                      className="comments-section"
                      rows={5}
                      value={state.comments ? state.comments : null}
                      onChange={(e) => state.setComments(e.target.value)}
                      placeholder="Comments"
                    ></textarea>
                  </div>
                  <div class="col-1">
                    <div>
                      <button
                        style={state.buttonStyle}
                        onClick={() => {
                          state.postStatus();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default KarePlusDetail;
