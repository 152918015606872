import axios from "axios";
import { APP_URL } from "./util/AppConstants";
import { BASE_PATH } from "./util/AppConstants";

const api = axios.create({
  baseURL: APP_URL,
});
api.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = BASE_PATH + "/sessionexpired";
    }
    return Promise.reject(error);
  }
);
export default api;



