import { makeStyles } from "@material-ui/core";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { checkPermission } from "../../../util/permissionUtils";
import { statusColorFinder } from "../../../util/statusColorFinder";
import Sidebar from "../../Sidebar";
import useAppointmentInner from "./useAppointmentInner.logic";

function AppointmentInner() {
  let history = useHistory();
  const data = history.location.state.enquiryData;

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));
  const classes = useStyles();
  const state = useAppointmentInner(data);

  const openInNewTab = (enquiryId, type) => {
    window.open(`/activity/${enquiryId}/${type}`, "_blank");
  };
  return (
    <div className="d-flex">
      <Sidebar />
      <div className={classes.root}>
        <button className="width1" onClick={() => state.back()}>
          BACK
        </button>
        <hr />
        <div className="row">
          {data.ipDetail && (
            <div class="col-md-2">
              <p className="text-start">Arrival Date</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{`${
                  data.ipDetail.arrivalDateTime.split("T")[0]
                }`}</div>
              </p>
            </div>
          )}
          <div class="col-md-1">
            <p className="text-start">Enquiry Id</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.enquiryId}`}</div>
            </p>
          </div>
          {data.opDetail && (
            <>
              {" "}
              <div class="col-md-2">
                <p className="text-start">Appointment Date</p>
                <p className="ed-txt-1 text-start">
                  <div className="textColor">{`${
                    data.opDetail.dateTime.split("T")[0]
                  }`}</div>
                </p>
              </div>
              <div class="col-md-2">
                <p className="text-start">Appointment Time</p>
                <p className="ed-txt-1 text-start">
                  <div className="textColor">{`${
                    data.opDetail.dateTime.split("T")[1]
                  }`}</div>
                </p>
              </div>
            </>
          )}
          <div class="col-md-1">
            <p className="text-start">Patient Name</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.patientName}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Phone Number</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.phoneNumber}`}</div>
            </p>
          </div>

          {/* bridge phone start */}

          {checkPermission("FEEDBACK_CALL_BTN") && (
            <div class="col-2">
              <h6>Choose Exotel Number</h6>
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 200,
                    border: `1px solid gray`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {!!state?.exoNumber?.label
                    ? state?.exoNumber?.label
                    : state?.exoPhone[0]?.label}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {state?.exoPhone.length &&
                    state?.exoPhone?.map((value, id) => {
                      return (
                        <Dropdown.Item
                          key={id}
                          href="#"
                          style={{ width: 200 }}
                          onClick={() => state.handlePhoneumber(value)}
                        >
                          {value?.label}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

          {/* bridge phone end */}

          {/* feedback back call start */}

          {checkPermission("FEEDBACK_CALL_BTN") && (
            <div class="col-md-2">
              <p className="text-start ">Feedback Call</p>
              <p className="ed-txt-1 text-start">
                <div class="col-md-1">
                  <button
                    style={{ width: 110 }}
                    onClick={() => state.feedbackCallToUser("PRIMARY")}
                  >
                    Feedback Call 1
                  </button>

                  {state.data?.alternateContact ? (
                    <button
                      style={{ marginLeft: 2, width: 110 }}
                      onClick={() => state.feedbackCallToUser("ALTERNATE")}
                    >
                      Feedback Call 2
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </p>
            </div>
          )}

          {/* feedback back call end */}

          <div class="col-md-1">
            <p className="text-start">Assigned To</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.assignToName}`}</div>
            </p>
          </div>
          <div class="col-md-2">
            <p className="text-start">Appointment Type</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data.appointmentType}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Doctor Name</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${
                data.ipDetail
                  ? data.ipDetail.doctorName
                  : data.opDetail
                  ? data.opDetail.doctorName
                  : ""
              }`}</div>
            </p>
          </div>
          <div class="col-md-2">
            <p className="text-start">Center Name</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${
                data.ipDetail
                  ? data.ipDetail.centerName
                  : data.opDetail
                  ? data.opDetail.centerName
                  : ""
              }`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Treatment</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${
                data.ipDetail
                  ? data.ipDetail.treatment
                  : data.opDetail
                  ? data.opDetail.treatment
                  : ""
              }`}</div>
            </p>
          </div>
          {checkPermission("KARE_BUDDY_ACTIVITY_VIEW") && (
          <div style={{height:100,width:200}}>
              <button
                style={{ marginLeft: 2, width: 220,height:50,cursor:"pointer" }}
                onClick={() =>  openInNewTab(
                  data?.enquiryId,
                  data?.appointmentType
                )}
              >
                View KareBuddy Activity
              </button>
            </div>
            )}
          <hr />
          <div className="row">
            {data.source && (
              <div class="col-md-1">
                <p className="text-start">Lead Origin</p>
                <p className="ed-txt-1 text-start">
                  <div className="textColor">{`${data?.source}`}</div>
                </p>
              </div>
            )}
            {data.ipDetail && (
              <div class="col-md-1">
                <p className="text-start">City</p>
                <p className="ed-txt-1 text-start">
                  <div className="textColor">{`${data?.location}`}</div>
                </p>
              </div>
            )}
            <div class="col-md-2">
              <p className="text-start">
                {data?.paymentMode ? "Mode of Payment " : "Consultation Mode"}
              </p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{`${data?.paymentMode}`}</div>
              </p>
            </div>
            <div class="col-md-1">
              <p className="text-start">{data.ipDetail ? "Amount" : "Fee"}</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{`${
                  data.ipDetail
                    ? data.ipDetail.amount
                    : data.opDetail
                    ? data.opDetail.fee
                    : ""
                }`}</div>
              </p>
            </div>
            <div class="col-md-1">
              <p className="text-start">
                {data.ipDetail ? "Ip Status" : "Op Status"}
              </p>
              <p className="ed-txt-1 text-start">
                <div
                  className="textColor"
                  style={{ color: "red", fontWeight: "bold" }}
                >{`${
                  data.ipDetail
                    ? data.ipDetail.ipStatus
                    : data.opDetail
                    ? data.opDetail.opStatus
                    : ""
                }`}</div>
              </p>
            </div>
            <div className="col-md-2 capitalize">
              <p className="text-start ">Status</p>
              <p
                className={statusColorFinder(data.status)}
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                {data.status ? data.status : ""}
              </p>
            </div>

            
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppointmentInner;
