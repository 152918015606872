import { Paper, TextField } from "@mui/material";
import React from "react";
import PartnerTopBar from "../../../components/Partner_TopBar";
import Sidebar from "../../Sidebar";
import useSettings from "./useSettings";
import Dropdown from "react-bootstrap/Dropdown";
import { CustomMenu } from "../../../components/AddLead/AddLead";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes";
import { reverse } from "lodash";
import DownloadIcon from "@mui/icons-material/Download";
import { useRef } from "react";



export default function PartnerSettings() {

  const state = useSettings();
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <div
        style={{
          width: "100%",
          height: "100vh",
          margin: 10,
          overflow: "hidden",
        }}
      >
        <div className="main-subContainer">
          <PartnerTopBar />
        </div>
        <div style={{ width: "100%", display: "flex" }}>
          <div className="container col">
            <b>Name</b>
            <br />
            <input
              style={{
                width: "auto",
                height: 36,
                border: `1px solid #00997c`,
                borderRadius: 5,
                marginTop: 5,
              }}
              name="name"
              placeholder="Name"
              value={state.form?.name}
              onChange={(e) =>
                state.setform((s) => ({ ...s, name: e.target.value }))
              }
            />
          </div>
          <div className="container col">
            <b>Email</b>
            <br />
            <input
              style={{
                width: "auto",
                height: 36,
                border: `1px solid #00997c`,
                borderRadius: 5,
                marginTop: 5,
              }}
              name="email"
              placeholder="Email"
              value={state.form.email}
              onChange={(e) =>
                state.setform((s) => ({ ...s, email: e.target.value }))
              }
            />
          </div>
          <div className="col">
            <div>
              <b style={{ textAlign: "left", marginBottom: 0 }}>Gender</b>
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 200,
                    border: `1px solid gray`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: 0,
                  }}
                >
                  {state.form.gender ? state.form.gender : "select"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {state.genderList.map((value, id) => {
                    return (
                      <Dropdown.Item
                        key={id}
                        href="#"
                        style={{ width: 200 }}
                        onClick={() =>
                          state.setform((s) => ({ ...s, gender: value }))
                        }
                      >
                        {value}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="col">
            <div>
              <b style={{ textAlign: "left", marginBottom: 0 }}>Manager</b>
              <div
                style={{
                  width: 140,
                  height: 35,
                  backgroundColor: "white",
                  border: "1px solid  #3ed0e5",
                  borderRadius: 5,
                  marginTop: 8,
                }}
              >
                <ReactMultiselectCheckboxes
                  options={state.manager}
                  getDropdownButtonLabel={state.getDropdownButtonLabel}
                  value={state.selectedManagers}
                  onChange={(value, event) =>
                    state.handleSelectManagers(value, event)
                  }
                  setState={state.setSelectedManagers}
                />
              </div>
            </div>
          </div>

          <div className="col">
            <b style={{ textAlign: "left", marginBottom: 0 }}>Country</b>
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  height: 36,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.form.country ? state.form.country : "select"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                as={CustomMenu}
                style={{
                  height: 200,
                  width: 260,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {state.countries.map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{ width: 200, maxHeight: 200 }}
                      onClick={() =>
                        state.setform((s) => ({ ...s, country: value }))
                      }
                    >
                      {value}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="container col m-0">
            <b>State</b>
            <br />
            <input
              style={{
                width: "auto",
                height: 36,
                border: `1px solid #00997c`,
                borderRadius: 5,
                marginTop: 5,
              }}
              name="country"
              placeholder="State"
              value={state.form.state}
              onChange={(e) =>
                state.setform((s) => ({ ...s, state: e.target.value }))
              }
            />
          </div>
          <div className="col mt-3">
            <button
              style={{ height: 40, width: 70 }}
              onClick={() => state.saveAgents(state.form)}
            >
              Create
            </button>
          </div>
          <div className="col mt-3" >
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "100px",
                backgroundColor: "#00FFCA",
                display: "flex",
                border:"3px solid #06c6a2",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                cursor:"pointer"
              }}
            >
              <DownloadIcon />
            </div>
          </div>
        </div>

        {/* ===============================================================================================Table============================================================================= */}

        <div className="row" style={{ width: "100%"}}>
          <hr style={{ marginTop: 20 }} />

          <div
            style={{
              width: "100%",
              height: "55vh",
              overflow: "auto",
              padding: 30,
            }}
          >
            <table class="table table-bordered" >
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Country</th>
                  <th scope="col">Manager</th>
                </tr>
              </thead>
              <tbody>
                {state.allAgents &&
                  state.allAgents?.map((val) => {
                    return (
                      <tr>
                        <td>
                          <p style={{ textAlign: "left", fontSize: 14 }}>
                            {val?.name || "--"}
                          </p>
                        </td>
                        <td>
                          <p style={{ textAlign: "left", fontSize: 14 }}>
                            {val?.email || "--"}
                          </p>
                        </td>
                        <td>
                          <p style={{ textAlign: "left", fontSize: 14 }}>
                            {val?.country || "--"}
                          </p>
                        </td>
                        <td>
                          <p style={{ textAlign: "left", fontSize: 14 }}>
                            {val?.managerName || "--"}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
}
