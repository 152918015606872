import React from "react";
import { NavLink } from "react-router-dom";
import { checkPermission } from "../util/permissionUtils";

const flexStyle = {
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function PartnerTopBar() {
  return (
    <div style={{ display: "flex", gap: 20 }} className="my-header">
      <NavLink to="/home" style={flexStyle}>
        <h5
          style={{
            color: "white",
            cursor: "pointer",
            marginBottom: 0,
          }}
        >
          Home
        </h5>
      </NavLink>
      
      {checkPermission("PARTNER_ARRIVAL_VIEW") && (
      <NavLink to="/arrival" style={flexStyle}>
        <h5
          style={{
            color: "white",
            marginBottom: 0,
            cursor: "pointer",
          }}
        >
          Arrival
        </h5>
      </NavLink>
      )}

      {checkPermission("PARTNER_PAITENTS_VIEW") && (
      <NavLink to="/patients" style={flexStyle}>
        <h5
          style={{
            color: "white",
            marginBottom: 0,
            cursor: "pointer",
          }}
        >
          Patients
        </h5>
      </NavLink>
      )}

      {checkPermission("PARTNER_SETTINGS") && (
      <NavLink to="/partner-settings" style={flexStyle}>
        <h5
          style={{
            color: "white",
            marginBottom: 0,
            cursor: "pointer",
          }}
        >
          Settings
        </h5>
      </NavLink>
      )}
    </div>
  );
}
