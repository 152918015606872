const downloadDocument = (contentType, base64Data, fileName) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};


export const PATIENT_IDENTITY_DOCUMENT = 'PATIENT_IDENTITY_DOCUMENT';
export const PRESCRIPTION_DOCUMENT = 'PRESCRIPTION_DOCUMENT';
export default downloadDocument;