import { useState } from "react";
import axios from "axios";

//MUI Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { APP_URL } from "../../../util/AppConstants";

// form data handling
const GenerateLink = ({ open, onClose, enquiryId }) => {
  const [isGeneratedLink, setNowGeneratedLink] = useState(null)
  const [iscopyLinkAlert, setNowcopyLinkAlert] = useState(null)
  const copyToClipboard = () => {
    navigator.clipboard.writeText(isGeneratedLink).then(() => {
      setNowcopyLinkAlert('Link copied to clipboard!');
      setTimeout(() => {
        setNowcopyLinkAlert(null);
      }, 1000);
    });
  };
  const formSubmit = async(event) => {
    event.preventDefault();
    var data = new FormData(event.target);
    let formObject = Object.fromEntries(data.entries());
    formObject.userTxnId = enquiryId;
    console.log(formObject);  
    try {
      const response = await axios.post(`${APP_URL}/mykare/enquiry/payment/save`, 
      formObject,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // console.log(response.data);
      setNowGeneratedLink(response.data)
    } catch (error) {
      console.error('An error occurred while submitting the form:', error);
    }
  };
  return (
    <div style={{ textAlign: "center" }}>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle textAlign={"center"}>Generate Payment Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form onSubmit={formSubmit}>
              <Stack spacing={2} margin={2} fontSize={4}>
                <TextField
                  variant="outlined"
                  label="Enter the amount"
                  name="amount"
                  required
                  type="number"
                  maxRows={2}
                  multiline
                ></TextField>
                <TextField
                  variant="outlined"
                  label="Reason"
                  name="reason"
                  multiline
                  maxRows={2}
                  required
                ></TextField>
                <Button
                  type="submit"
                  sx={{ alignSelf: "center" }}
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  Submit
                </Button>

              </Stack>
              <p>{isGeneratedLink ? isGeneratedLink : "To get the payment link, please fill out the form"}</p>
              {isGeneratedLink && (
                <>
                <Button onClick={copyToClipboard} variant="contained" color="success">
                  Copy Link
                </Button>
                <p style={{marginTop:"10px"}}>{iscopyLinkAlert && iscopyLinkAlert}</p>
                </>
              )}
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GenerateLink;
