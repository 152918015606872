import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import {getUserRole, getUserCode, getAuthHeader} from "../../store/DataStore";

export const updatePatientsDetails = async (postData) => {
    const headers = {
        Authorization: getAuthHeader(),
    };
    const response = await axios.put(`${APP_URL}/karetrip/user/updateBasicDetail`, postData, {
        headers,
    });
    return response;
};

export const getPatientsDetails = async (userId,userTxnId) => {
    const headers = {
        Authorization: getAuthHeader(),
    };
    const response = await axios.get(`${APP_URL}/karetrip/user/getBasicDetail?userId=${userId}&userTxnId=${userTxnId}`, {
        headers,
    });
    return response;
};