import React, { useState } from "react";
import "../../Enquiry/Enquiry.css";
import "../../../Styles/Common.css";
import Sidebar from "../../Sidebar";
import "../../../Styles/sideBar.css";
import Form from "react-bootstrap/Form";
import "../../../Styles/Pagination.css";
import List from "@material-ui/core/List";
import TopBar from "../../Enquiry/TopBar";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import Dropdown from "react-bootstrap/Dropdown";
import TextField from "@mui/material/TextField";
import useIpOp from "./useIpOpDetailsPage.logic";
import Autocomplete from "@mui/material/Autocomplete";
import CustomLoader from "../../../components/CustomLoader";
import { checkPermission } from "../../../util/permissionUtils";
import DownloadPanel from "../../../components/DownloadPanel/DownloadPanel";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import IpOpPopUp from "../IpOpPopUp/IpOpPopUp";
import DropdownComponent from "../dropdown/DropdownMenu";

function IpOp() {
  const state = useIpOp();
  const [reportPanel, setReportPanel] = useState(false);
  const [surgeryPanel, setSurgeryPanel] = useState(false);
  const border = {
    width: "100%",
    height: "100vh",
    overflow: "scroll",
  };

  if (!state.dataLoaded) {
    return <CustomLoader />;
  }
  return (
    <div style={border}>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ width: "150%", padding: 10 }}>
          <div className="sticky">
            <div className="main-subContainer">
              <TopBar />
              {checkPermission("IP_OP_STATISTICS") && (
                <div className="row border1">
                  <div className="col-2 cor1">
                    <b>
                      <p className="size1">{state.ipOpStats?.totalIpCount}</p>
                    </b>
                    <b>
                      <p className="colour">Total Ips</p>
                    </b>
                  </div>
                  <div className="col-2 cor2">
                    <b>
                      <p className="size1">{state.ipOpStats?.totalOpCount}</p>
                    </b>
                    <b>
                      <p className="colour">Total Ops</p>
                    </b>
                  </div>
                  <div className="col-2 cor3">
                    <b>
                      <p className="size1">{state.ipOpStats?.todayIpdCount}</p>
                    </b>
                    <b>
                      <p className="colour">Today's Ips</p>
                    </b>
                  </div>
                  <div className="col-2 cor4">
                    <b>
                      <p className="size1">{state.ipOpStats?.todayOpdCount}</p>
                    </b>
                    <b>
                      <p className="colour">Today's Ops</p>
                    </b>
                  </div>

                  <div className="col-2 cor5">
                    <b>
                      <p className="size1">{state.ipOpStats?.totalMonthIpd}</p>
                    </b>
                    <b>
                      <p className="colour">Monthly Ips</p>
                    </b>
                  </div>

                  <div className="col-2 cor6">
                    <b>
                      <p className="size1">{state.ipOpStats?.totalMonthOpd}</p>
                    </b>
                    <b>
                      <p className="colour">Monthly Ops</p>
                    </b>
                  </div>
                </div>
              )}
            </div>
            <br />
            <br />
            <br />
            <div
              className="row"
              style={{ width: "100%", height: 70, marginLeft: 20 }}
            >
              {checkPermission("IP_OP_REPORT_BUTTON") && (
                <button
                  style={{
                    width: 200,
                    height: 50,
                    border: "2px solid",
                    borderColor: reportPanel ? "yellow" : "",
                  }}
                  // onClick={() => state.downloadReport()}
                  onClick={() => {
                    // setShowAddLead(false);
                    setReportPanel(!reportPanel);
                    setSurgeryPanel(false);
                  }}
                >
                  Report
                </button>
              )}
              &nbsp;
              {checkPermission("SURGERY_SUGGESTED_REPORT_BUTTON") && (
                <button
                  style={{
                    width: 200,
                    height: 50,
                    border: "2px solid",
                    borderColor: surgeryPanel ? "yellow" : "",
                  }}
                  onClick={() => {
                    setSurgeryPanel(!surgeryPanel);
                    setReportPanel(false);
                  }}
                >
                  Surgery Suggested Report
                </button>
              )}
            </div>

            {reportPanel ? (
              <DownloadPanel
                reportPanel={reportPanel}
                onClicked={(openPopUp) => setReportPanel(openPopUp)}
              />
            ) : null}
            {surgeryPanel ? (
              <DownloadPanel
                surgeryPanel={surgeryPanel}
                surgeryReport
                onClicked={(openPopUp) => {
                  setSurgeryPanel(openPopUp);
                }}
              />
            ) : null}

            <IpOpPopUp
              activeData={state?.activeData}
              setActiveData={state?.setActiveData}
              kareBuddyList={state?.agents}
              assignToKareBuddy={state?.assignToKareBuddy}
            />
            <div
              className="row scroll"
              style={{
                minHeight: "3rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {" "}
                Showing {state.offset * state.perPage} -{" "}
                {(state.offset + 1) * state.perPage < state.totalCount
                  ? (state.offset + 1) * state.perPage
                  : state.totalCount}{" "}
                out of {state.totalCount}
                <div className="row scroll">
                <div
                    className="container col-2 m-0"
                    style={{
                      backgroundColor: "white",
                      width: "272px",
                      height: 100,
                    }}
                  >
                    <b>Type</b>
                 
                    <DropdownComponent
                      data={state?.mainMenuItems}
                      setSelectedCelectedConsultationType={
                        state?.setSelectedCelectedConsultationType
                      }
                      handleMode={state?.handleMode}
                      setSelectedConsultationMode={state?.setSelectedConsultationMode}
                      selectedConsultationType={state?.selectedConsultationType}
                      setReload={state.setReload}
                      setIsActiveType={state?.setIsActiveType}
                    />
                  </div>
                  <div className="container col-1 m-0">
                    <b>Status</b>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        style={{
                          width: 130,
                          border: `1px solid gray`,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {state.selectedStatus ? state.selectedStatus : "All"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          maxHeight: 200,
                          overflowY: "scroll",
                          overflowX: "hidden",
                          width: 200,
                        }}
                      >
                        {state.statusList
                          ? state.statusList.map((data, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  href="#"
                                  style={{ width: 200 }}
                                  onClick={() => {
                                    state.handleStatus(data);
                                    state.setReload(true);
                                  }}
                                >
                                  {data}
                                </Dropdown.Item>
                              );
                            })
                          : null}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className=" col-1 m-0">
                    <b>Lead Status</b>
                    <div
                      style={{
                        width: 140,
                        height: 35,
                        backgroundColor: "white",
                        border: "1px solid  #00997c",
                        borderRadius: 5,
                        marginTop: 8,
                      }}
                    >
                      <ReactMultiselectCheckboxes
                        options={state.leadStatusData}
                        placeholderButtonLabel={"Choose"}
                        getDropdownButtonLabel={state.getDropdownButtonLabel}
                        value={state.selectedLeadStatuses}
                        onChange={(value, event) =>
                          state.handleSelectedLeadStatuses(value, event)
                        }
                        setState={state.selectedLeadStatuses}
                      />
                    </div>
                  </div>

                  {state.type === "OP" && (
                    <div className=" col-1 m-0">
                      <b>Sub Status</b>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          style={{
                            width: 270,
                            border: `1px solid gray`,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "0px",
                          }}
                        >
                          {state.selectedSubStatus || "Select Sub Status"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {state.subStatus.map((value, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                href="#"
                                style={{ width: 270, maxHeight: 200 }}
                                onClick={() =>
                                  state.handleSubStatus(value.id, value.name)
                                }
                              >
                                {value.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </div>
                <hr></hr>
              </div>
              {/* <div style={{ width: 50 }} className="container col-1 m-0">
                <b>Sl.No</b>
              </div> */}

              <div className="container col-1 m-0">
                <b>Appointment Date</b>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 160,
                      height: 40,
                      backgroundColor: "white",
                      border: "1px solid  #06c6a2",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.selectedAppointmentDateFilter
                      ? state.selectedAppointmentDateFilter
                      : "All"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {state.appointmentDateFilter.map((value, index) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 120 }}
                            onClick={() => {
                              state.setSelectedAppointmentDateFilter(value);
                              state.setOffset(0);
                              state.setReload(true);
                            }}
                          >
                            {value}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>

                {state.selectedAppointmentDateFilter === "Choose Date" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #00997c",
                      width: 200,
                      borderRadius: 5,
                      marginBottom: 7,
                    }}
                  >
                    <label style={{ marginLeft: 10 }}>From</label>
                    <DatePicker
                      className="mySearchEnquiryUpDatePicker followUpdate"
                      selected={
                        state.searchAppointmentFromDate
                          ? state.searchAppointmentFromDate
                          : new Date()
                      }
                      onChange={(date) => {
                        state.setSearchAppointmentFromDate(date);
                        state.setOffset(0);
                        state.setReload(true);
                      }}
                      // maxDate={new Date()}
                    />
                  </div>
                ) : null}
                {state.selectedAppointmentDateFilter === "Choose Date" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #00997c",
                      width: 200,
                      borderRadius: 5,
                    }}
                  >
                    <label style={{ marginLeft: 10 }}>To</label>
                    <DatePicker
                      className="mySearchEnquiryUpDatePicker followUpdate"
                      selected={
                        state.searchAppointmentToDate
                          ? state.searchAppointmentToDate
                          : new Date()
                      }
                      onChange={(date) => {
                        state.setSearchAppointmentToDate(date);
                        state.setOffset(0);
                        state.setReload(true);
                      }}
                      // maxDate={new Date()}
                    />
                  </div>
                ) : null}
              </div>

              {checkPermission("LEADS_ORIGIN_FIELD") && (
                <div className="container col-1 m-0">
                  <b>Source</b>
                  <div
                    style={{
                      width: 160,
                      height: 40,
                      backgroundColor: "white",
                      border: "1px solid  #06c6a2",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      marginTop:8
                    }}
                  >
                    <ReactMultiselectCheckboxes
                      options={state.sourceData}
                      getDropdownButtonLabel={state.getDropdownButtonLabel}
                      value={state.selectedSources}
                      onChange={(value, event) =>
                        state.handleSelectSources(value, event)
                      }
                      setState={state.setSelectedSources}
                    />
                  </div>
                </div>
              )}

              <div className="container col-1 m-0">
                <b>enquiry id</b>
                <input
                  style={{
                    width: 160,
                    height: 40,
                    backgroundColor: "white",
                    border: "1px solid  #06c6a2",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    marginTop:8
                  }}
                  placeholder="Search..."
                  value={state.searchEnquiryId}
                  onChange={state.handleSearchEnquiry}
                />
              </div>

              <div className="container col-1 m-0">
                <b>Patient Name</b>
                <br />
                <input
                  style={{
                    width: 160,
                    height: 40,
                    backgroundColor: "white",
                    border: "1px solid  #06c6a2",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    marginTop:8
                  }}
                  placeholder="Search"
                  value={state.searchItem}
                  onChange={state.handleSearchItemChange}
                />
              </div>

              {/* <div className="container col-1 m-0">
                <b>Phone Number</b>
              </div> */}

              <div className="container col-1 m-0">
                <b>Amount</b>
              </div>

              <div className="container col-1 m-0">
                <b>KareBuddy</b>
              </div>

              <div className="container col-1 m-0">
                <b>Location</b>
                <div
                 style={{
                  width: 160,
                  height: 40,
                  backgroundColor: "white",
                  border: "1px solid  #06c6a2",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  marginTop:8
                }}
                >
                  <ReactMultiselectCheckboxes
                    options={state.locationData}
                    getDropdownButtonLabel={state.getDropdownButtonLabel}
                    value={state.selectedLocations}
                    onChange={(value, event) =>
                      state.handleSelectLocations(value, event)
                    }
                    setState={state.setSelectedLocations}
                  />
                </div>
              </div>

              <div className="container col-1 m-0">
                <b>Doctor Name</b>
                <br />
                <input
                 style={{
                  width: 160,
                  height: 40,
                  backgroundColor: "white",
                  border: "1px solid  #06c6a2",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  marginTop:8
                }}
                  placeholder="Search"
                  value={state.searchDoctor}
                  onChange={state.handleSearchDoctorChange}
                />
              </div>

              <div className="container col-1 m-0">
                <b>Center Name</b>
                <br />
                <input
                 style={{
                  width: 160,
                  height: 40,
                  backgroundColor: "white",
                  border: "1px solid  #06c6a2",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  marginTop:8
                }}
                  placeholder="Search"
                  value={state.searchCenter}
                  onChange={state.handleSearchCenterChange}
                />
              </div>

              <div className="container col-1 m-0">
                <b>Treatment</b>
                <div
                  style={{
                    width: 160,
                    height: 40,
                    backgroundColor: "white",
                    border: "1px solid  #06c6a2",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    marginTop:8
                  }}
                >
                  <ReactMultiselectCheckboxes
                    options={state.treatmentData}
                    getDropdownButtonLabel={state.getDropdownButtonLabel}
                    value={state.selectedTreatments}
                    onChange={(value, event) =>
                      state.handleSelectTreatments(value, event)
                    }
                    setState={state.setSelectedTreatments}
                  />
                </div>
              </div>
              {checkPermission("IP_OP_ASSIGNED_FIELD") && (
                <div className="container col-1 m-0">
                  <b>Assigned To</b>
                  <br />
                  <div
                    style={{
                      width: 160,
                      height: 40,
                      backgroundColor: "white",
                      border: "1px solid  #06c6a2",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      marginTop:8
                    }}
                  >
                    <ReactMultiselectCheckboxes
                      options={state?.assignedUser?.list}
                      getDropdownButtonLabel={state.getDropdownButtonLabel}
                      value={state.selectedAssignedTo}
                      onChange={(value, event) =>
                        state.handleAssignedAgents(value, event)
                      }
                      setState={state.setSelectedAssignedTo}
                    />
                  </div>
                </div>
              )}

              <div className="container col-1 m-0">
                <b>Payment Type</b>
              </div>

              {/* <div className="container col-1 m-0">
                <b>Comments</b>
              </div> */}
            </div>
            <hr />
            <List component="nav" aria-label="mailbox folders">
              {state.homeData}
            </List>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={state.pageCount}
              initialPage={state.offset}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={state.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={state.offset}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IpOp;

export const CustomMenu = React.forwardRef(
  (
    { children, style, className, "aria-labelledby": labeledBy, searchStyle },
    ref
  ) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          style={searchStyle}
          autoFocus
          className={!searchStyle && "mx-3 my-2 w-auto"}
          placeholder="Search"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child?.props?.children?.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);





