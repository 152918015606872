import {DATA_URL } from "../../util/AppConstants";
import api from "../../axios-config";

export const getFilteredPatients = async (offset, perPage, search) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  var params = `pageNo=${offset}&pageSize=${perPage}`;
  if (search) {
    params += `&search=${search}`;
  }
  const response = await api.get(
    `/associate/patient/filtered?${params}`,
    {
      headers,
    }
  );
  return response;
};

export const savePatientsDetails = async (postData) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await api.post(
    `/associate/patient/save`,
    postData,
    {
      headers,
    }
  );

  return response;
};

export const getAllCountryList = async () => {
  try {
    const response = await api.get(`${DATA_URL}/country/all`);
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getAllStateList = async (countryId) => {
  try {
    const response = await api.get(
      `${DATA_URL}/country/state/${countryId}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getAllCities = async () => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const response = await api.get(`/associate/enquiry/city/all`, {
      headers,
    });
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getAllCenters = async () => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const response = await api.get(
      `/associate/enquiry/center/all`,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getAllAgents = async () => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const response = await api.get(`/associate/agent/all`, {
      headers,
    });
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const saveEnquiryDetails = async (postData) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await api.post(
    `/associate/enquiry/save`,
    postData,
    {
      headers,
    }
  );

  return response;
};

export const saveEnquiryDocuments = async (formData) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  };
  const response = await api.post(
    `/associate/enquiry/document/save`,
    formData,
    {
      headers,
    }
  );
  return response;
};

export const getAllSpecialities = async () => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const response = await api.get(
      `/associate/enquiry/speciality/all
    `,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getPatientDetails = async (patientId) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const response = await api.get(
      `/associate/enquiry/${patientId}
    `,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const deletePatientDetail = async (patientId) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const response = await api.delete(
      `/associate/enquiry/${patientId}
    `,
      {
        headers,
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const loadDocumentFiles = async (enquiryId) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const response = await api.get(
      `/internal/partner/enquiryDocuments/${enquiryId}`,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const deleteDocumentFile = async (documentId) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const response = await api.delete(
      `/associate/enquiry/document/delete/${documentId}`,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const downloadDocumentFile = async (document) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const response = await api.get(
      `/internal/partner/enquiryDocuments/file/${document}`,
      {
        responseType: "blob",
        headers,
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
