import axios from "axios";
import { useEffect, useState } from "react";
import * as appConstants from "../../util/AppConstants";
export const useSummary = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    handleTransfer();
  }, []);

  const handleTransfer = async () => {
    try {
      const response = await axios.get(
        appConstants.APP_URL +
          "/internal/mykare/partnership/enquiries/allStatistics",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      setData(response.data);
    } catch (error) {

    }
  };

  return {
    handleTransfer,
    data,
  };
};
