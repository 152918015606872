import "../../Styles/Common.css";
import "../../Styles/sideBar.css";
import "../../Styles/Pagination.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import ListItem from "@material-ui/core/ListItem";
import React, { useEffect, useState, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import "./PreLeads.css";
import { statusColorFinder } from "../../util/statusColorFinder";
import {
  getEnquiryPreventiveCareStatistics,
  getFilteredOtherLeadEnquiries,
  getUserTrxnStatuses,
  getSources,
  getActivePreLeads,
  getFilteredOtherPreLeadEnquiries,
} from "../../services/mykare/enquiryService";
import { getAgentData } from "../../services/commonService/commonService";
import Tooltip from "@material-ui/core/Tooltip";
import { useStyles } from "../../components/multi-select";
import { getTreatments } from "../../services/mykare/dataService";
import { checkPermission } from "../../util/permissionUtils";
import { getAllLocations } from "../../services/mykare/centerService";
import {
  getAllPreLeads,
  getAllPreLeadsTeam,
  getFaqsResponse,
} from "../../services/mykare/PreLeadsService";

const usePreLeads = () => {
  let history = useHistory();
  const location = useLocation();
  const [perPage] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [homeData, setHomeData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [filterLoaded, setFilterLoaded] = useState(true);
  const [enquiryStats, setEnquiryStats] = useState(null);
  const userRole = localStorage.getItem("user-role");
  const [reload, setReload] = useState(false);
  const [searchKeyName, setSearchKeyName] = useState("");
  const [searchKeyNumber, setSearchKeyNumber] = useState("");
  const [searchEnquiryId, setSearchEnquiryId] = useState("");
  const [searchByEnqiuryId, setSearchByEnqiuryId] = useState({});
  const [searchEnquiryFromDate, setSearchEnquiryFromDate] = useState(
    new Date()
  );
  const [searchEnquiryToDate, setSearchEnquiryToDate] = useState(new Date());
  const [searchFollowupFromDate, setSearchFollowupFromDate] = useState(
    new Date()
  );
  const [searchFollowupToDate, setSearchFollowupToDate] = useState(new Date());
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedSource, setSelectedSource] = useState("All");
  const [sources, setSources] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [selectedStatusIds, setSelectedStatusIds] = useState([0]);
  const [preSelectedStatusIds, setPreSelectedStatusIds] = useState([0]);
  const [preLeadStatusIds, setPreLeadStatusIds] = useState([0]);
  const isAllStatus =
    statusData.length > 0 && selectedStatuses.length === statusData.length;

  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([0]);
  const [treatmentData, setTreatmentData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([0]);
  const [preLeadTeams, setPreLeadTeams] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedSourceIds, setSelectedSourceIds] = useState([0]);
  const [sourceData, setSourceData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [preAssignedCode, setPreAssignedCode] = useState([]);
  const isAllTreatment =
    treatmentData.length > 0 &&
    selectedTreatments.length === treatmentData.length;

  const [enquiryDateFilter, setEnquiryDateFilter] = useState([
    "All",
    "Today",
    "Yesterday",
    "Choose Date",
    "Previous Month",
  ]);
  const followUpDateFilter = ["All", "Today", "Tomorrow", "Choose Date"];
  const [selectedEnquiryDateFilter, setSelectedEnquiryDateFilter] =
    useState("All");
  const [selectedFollowupDateFilter, setSelectedFollowupDateFilter] =
    useState("All");
  const [assignedUser, setAssignedUser] = useState({
    list: [{ id: 0, name: "All", code: "ALL" }],
    selected: "All",
    selectedCode: "ALL",
  });

  const preStatusList = [
    { id: 7, name: "DNP1" },
    { id: 8, name: "DNP2" },
    { id: 9, name: "DNP3" },
    { id: 10, name: "DNP4" },
    { id: 11, name: "DNP5" },
    { id: 1, name: "New Lead" },
    { id: 25, name: "Call Back" },
    { id: 15, name: "Not Interested" },
    { id: 22, name: "Out Of City" },
    { id: 23, name: "Supply Gap" },
    { id: 17, name: "Junk/irrelevent" },
    { id: 36, name: "Non-Workable Exhausted" },
    { id: 2, name: "Transfferd" },
    { id: 26, name: "Duplicate Lead" },
    { id: 27, name: "Cross BD" },
  ];

  const preLeadDefaultStatus = [{ id: 0, name: "ALL" }];
  preLeadDefaultStatus.push.apply(preLeadDefaultStatus, preStatusList);
  const preLeadStatus = preLeadDefaultStatus?.map((item) => {
    return {
      id: item?.id,
      value: item?.id,
      label: item?.name,
    };
  });

  const useTableStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      marginTop: 10,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));

  const tooltipStyles = {
    tooltip: {
      fontSize: "20px",
      borderRadius: "18px",
      boxShadow: "0 20px 80px 0",
      backgroundColor: "#FFFFE0",
      color: "black",
    },
  };

  const [pageData, setPageData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [selectType, setSelectType] = useState({
    list: ["OTHER_LEADS"],
    selected: "OTHER_LEADS",
  });
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [isClear, setIsClear] = useState(false);
  const [selectedAssignedToIds, setSelectedAssignedToIds] = useState([]);

  function getDropdownButtonLabel({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Select";
      return `${values}`;
    }
    return "Select";
  }

  const handleAssignedAgents = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedAssignedTo(
        selectedAssignedTo.length === assignedUser?.list?.length
          ? []
          : assignedUser?.list
      );
      ids = assignedUser?.list.map((item) => item.value);
      setSelectedAssignedToIds(
        selectedAssignedTo.length === assignedUser?.list.length ? [] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === assignedUser?.list.length) {
        value = assignedUser?.list;
      }
      setSelectedAssignedTo(value);
      ids = value.length === 0 ? [] : value.map((item) => item.value);
      setSelectedAssignedToIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedAssignedTo(data);
      ids = data.length === 0 ? [] : data.map((item) => item.value);
      setSelectedAssignedToIds(ids);
    }

    loadFilteredData(
      searchItem,
      searchPhone,
      selectedStatusIds,
      preAssignedCode,
      preSelectedStatusIds,
      preLeadStatusIds,
      ids,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedLocationIds,
      selectedSourceIds
    );
  };

  const loadAssignedUsers = async () => {
    try {
      getAgentData(false).then((res) => {
        let users = [{ id: 0, name: "All", code: "ALL" }];
        users.push.apply(users, res.data.users);
        users.push({ id: -1, name: "Not Assigned", code: "NOT_ASSIGNED" });
        const data = res?.data?.users
          .map((val, i) => {
            return { id: i, value: val.code, label: val.name };
          })
          .sort((a, b) => {
            return a.label.localeCompare(b.label);
          });
        setAssignedUser((s) => ({ ...s, list: data }));
      });
    } catch (error) {}
  };

  const loadLocations = async () => {
    try {
      const response = await getAllLocations();
      var status = [
        { id: 0, name: "ALL" },
        { id: -1, name: "Blank" },
      ];
      status.push.apply(status, response);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setLocationData(data);
    } catch (error) {}
  };

  const handleSelectLocations = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedLocations(
        selectedLocations.length === locationData.length ? [] : locationData
      );
      ids = locationData.map((item) => item.value);
      setSelectedLocationIds(
        selectedLocations.length === locationData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === locationData.length) {
        value = locationData;
      }
      setSelectedLocations(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedLocationIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedLocations(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedLocationIds(ids);
    }
    loadFilteredData(
      searchItem,
      searchPhone,
      selectType.selected,
      preAssignedCode,
      preSelectedStatusIds,
      preLeadStatusIds,
      selectedStatusIds,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      ids,
      selectedSourceIds
    );
  };

  const handleSelectSources = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedSources(
        selectedSources.length === sourceData.length ? [] : sourceData
      );
      ids = sourceData.map((item) => item.value);
      setSelectedSourceIds(
        selectedSources.length === sourceData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === sourceData.length) {
        value = sourceData;
      }
      setSelectedSources(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedSourceIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedSources(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedSourceIds(ids);
    }
    loadFilteredData(
      searchItem,
      searchPhone,
      selectType.selected,
      preAssignedCode,
      preSelectedStatusIds,
      preLeadStatusIds,
      selectedStatusIds,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedLocationIds,
      ids
    );
  };
  const handleSelectTreatments = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedTreatments(
        selectedTreatments.length === treatmentData.length ? [] : treatmentData
      );
      ids = treatmentData.map((item) => item.value);
      setSelectedTreatmentIds(
        selectedTreatments.length === treatmentData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === treatmentData.length) {
        value = treatmentData;
      }
      setSelectedTreatments(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedTreatmentIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedTreatments(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedTreatmentIds(ids);
    }

    loadFilteredData(
      searchItem,
      searchPhone,
      selectType.selected,
      preAssignedCode,
      preSelectedStatusIds,
      preLeadStatusIds,
      selectedStatusIds,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      ids,
      selectedLocationIds,
      selectedSourceIds
    );
  };

  const handleSelectStatuses = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedStatuses(
        selectedStatuses.length === statusData.length ? [] : statusData
      );
      ids = statusData.map((item) => item.value);
      setSelectedStatusIds(
        selectedStatuses.length === statusData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === statusData.length) {
        value = statusData;
      }
      setSelectedStatuses(value);
      ids = value.map((item) => item.value);
      setSelectedStatusIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedStatuses(data);
      ids = data.map((item) => item.value);
      setSelectedStatusIds(ids);
    }
    loadFilteredData(
      searchItem,
      searchPhone,
      selectType.selected,
      preAssignedCode,
      preSelectedStatusIds,
      preLeadStatusIds,
      ids,
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedLocationIds,
      selectedSourceIds
    );
  };

  const [selectStatus, setSelectStatus] = useState({
    list: [],
    selected: "ALL",
  });

  const loadStatus = async () => {
    try {
      const response = await getUserTrxnStatuses();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setStatusData(data);
    } catch (error) {}
  };

  const loadTreatments = async () => {
    try {
      const response = await getTreatments();
      var status = [
        { id: 0, name: "ALL" },
        { id: -1, name: "Blank" },
      ];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setTreatmentData(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.offset || location.state.selectedStatusIds) {
        setSelectedStatuses(location.state.selectedStatuses);
        setSelectedStatusIds(location.state.selectedStatusIds);
        setPreSelectedStatusIds(location.state.selectedStatusIds);
        setPreLeadStatusIds(location.state.selectedStatusIds);
        setSelectedTreatmentIds(location.state.selectedTreatmentIds);
        setSelectedTreatments(location.state.selectedTreatments);
        setOffset(location.state.offset);
        setSelectedEnquiryDateFilter(location.state.selectedEnquiryDateFilter);
        setSearchEnquiryFromDate(location.state.searchEnquiryFromDate);
        setSearchEnquiryToDate(location.state.searchEnquiryToDate);
        setSelectedFollowupDateFilter(
          location.state.selectedFollowupDateFilter
        );
        setSearchFollowupFromDate(location.state.searchFollowupFromDate);
        setSearchFollowupToDate(location.state.searchFollowupToDate);
        setAssignedUser(location.state.assignedUser);
        setSelectedAssignedTo(location.state.selectedAssignedTo);
        setSelectedAssignedToIds(location.state.selectedAssignedToIds);
        setReload(true);
      }
    } else {
      setReload(true);
    }
  }, []);

  const handleAssignedToChange = (assignedCode, assignedName) => {
    setAssignedUser((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
  };

  const [searchItem, setSearchItem] = useState("");
  const [searchPhone, setSearchPhone] = useState("");

  const handleSearchItemChange = (e) => {
    setSearchKeyName(e.target.value);
    if (e.target.value === "") {
      window.location.reload();
    }
  };
  const handleSearchPhone = (e) => {
    setSearchKeyNumber(e.target.value);
    if (e.target.value === "") {
      window.location.reload();
    }
  };

  const handleSearchEnquiryId = (e) => {
    setSearchByEnqiuryId(e.target.value);
    if (e.target.value === "") {
      window.location.reload();
    }
  };

  const handleSearch = (id) => {
    if (id === 1) {
      setSearchItem(searchKeyName);
    } else if (id === 2) {
      setSearchPhone(searchKeyNumber);
    } else if (id === 3) {
      setSearchEnquiryId(searchByEnqiuryId);
    }
  };

  const getTotalEnquiries = async () => {
    try {
      getEnquiryPreventiveCareStatistics().then((res) => {
        setEnquiryStats(res.data);
      });
    } catch (error) {}
  };

  useEffect(() => {
    loadAssignedUsers();
    getSource();
  }, []);



  useEffect(() => {
    loadStatus();
    loadTreatments();
    loadLocations();
    loadSources();
    if (reload) {
      setDataLoaded(false);
      getTotalEnquiries();
      getData(
        offset,
        searchItem,
        searchPhone,
        preAssignedCode,
        preSelectedStatusIds,
        preLeadStatusIds,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedLocationIds,
        selectedSourceIds
      );
      setReload(false);
    }
  }, [reload]);

  // [reload, isClear, searchItem, searchItem, selectedAssignedToIds]



  useEffect(() => {
    if (!pageData && !allData) return;
    if (searchPhone === "") {
      setReload(true);
      setDataLoaded(false);
    } else {
      getData(
        offset,
        searchItem,
        searchPhone,
        preAssignedCode,
        preSelectedStatusIds,
        preLeadStatusIds,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedLocationIds,
        selectedSourceIds
      );
      setReload(false);
    }
  }, [searchPhone, selectedAssignedToIds]);

  useEffect(() => {
    if (searchItem === "") {
      setReload(true);
      setDataLoaded(false);
    } else {
      getData(
        offset,
        searchItem,
        searchPhone,
        preAssignedCode,
        preSelectedStatusIds,
        preLeadStatusIds,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedLocationIds,
        selectedSourceIds
      );
      setReload(false);
    }
  }, [searchItem, selectedAssignedToIds]);

  useEffect(() => {
    if (searchEnquiryId === "") {
      setReload(true);
      setDataLoaded(false);
    } else {
      getData(
        offset,
        searchItem,
        searchPhone,
        preAssignedCode,
        preSelectedStatusIds,
        preLeadStatusIds,
        selectedStatusIds,
        selectedAssignedToIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedLocationIds,
        selectedSourceIds,
        searchEnquiryId
      );
      setReload(false);
    }
  }, [searchEnquiryId, selectedAssignedToIds]);

  const loadAllActivePreLeads = () => {
    getActivePreLeads().then((data) => {
      setPreAssignedCode(data?.data?.users?.map((data) => data?.code));
    });
  };
  const loadAllPreLeadsTeam = () => {
    getAllPreLeadsTeam(false).then((data) => {
      setPreLeadTeams(
        data?.data?.users?.map((data) => {
          return {
            value: data?.code,
            label: data?.name,
          };
        })
      );
    });
  };

  useEffect(() => {
    loadAllPreLeadsTeam();
    loadAllActivePreLeads();
  }, []);
  const loadFilteredData = async (
    searchItem,
    searchPhone,
    enquiryType,
    preAssignedCode,
    preSelectedStatusIds,
    preLeadStatusIds,
    statusIds,
    assignedTo,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    followUpFromDate,
    followUpToDate,
    treatmentIds,
    locationIds,
    sourceIds,
    searchEnquiryId
  ) => {
    const enquiryFilterFromDate = moment(enquiryFromDate)
      .format()
      .split("+")[0];
    const enquiryFilterToDate = moment(enquiryToDate).format().split("+")[0];
    const followupFilterFromDate = moment(followUpFromDate)
      .format()
      .split("+")[0];
    const followupFilterToDate = moment(followUpToDate).format().split("+")[0];

    const response = await getFilteredOtherPreLeadEnquiries(
      0,
      perPage,
      enquiryType,
      preAssignedCode,
      preSelectedStatusIds,
      preLeadStatusIds,
      statusIds,
      searchItem,
      searchPhone,
      assignedTo,
      enquiryDateFilter,
      enquiryFilterFromDate,
      enquiryFilterToDate,
      followUpDateFilter,
      followupFilterFromDate,
      followupFilterToDate,
      treatmentIds,
      locationIds,
      sourceIds,
      searchEnquiryId
    );

    if (response) {
      const data = response.data.enquiries;
      setTotalCount(response.data.count);
      setPageData(data);
      setPageCount(Math.ceil(response.data.count / perPage));
    }
  };

  const refreshTable = () => {
    setSelectedStatuses([]);
    setSelectedStatusIds([0]);
    setSelectedTreatments([]);
    setSelectedTreatmentIds([0]);
    setDataLoaded(true);
    getData(
      offset,
      searchItem,
      searchPhone,
      [0],
      selectedAssignedToIds,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      [0],
      [0],
      [0]
    );
    getTotalEnquiries();
    setDataLoaded(false);
  };

  const getSource = async () => {
    let defaultSource = [{ id: 0, name: "All" }];
    const response = await getSources();
    if (response.status === 200) {
      if (response.data) {
        defaultSource.push.apply(defaultSource, response.data);
        setSources(defaultSource);
      }
    }
  };

  useEffect(() => {
    if (!!pageData) {
      const postData = pageData.map((data, index) => (
        <div
          key={index}
          className="row pointer scroll"
          onClick={() => handleClickTale(data,"replace", "_self")}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {checkPermission("LEADS_SL_NO_FIELD") && (
            <div className="container col-1 m-0" style={{ width: 70 }}>
              {offset === 0 ? index + 1 : offset * 20 + (index + 1)}
            </div>
          )}

          {checkPermission("LEADS_ENQUIRY_DATE_FIELD") && (
            <div className="container col-1 m-0">
              <ListItem>
                <ListItemText primary={`${data.enquiryDate.split("T")[0]}`} />
              </ListItem>
              <p style={{ textAlign: "left", fontSize: "15px" }}>
                {moment(data?.enquiryDate).format("LTS")}
              </p>
            </div>
          )}
          <div className="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>

            <ListItem>
              <ListItemText primary={` ${data.patientName}`} />
            </ListItem>
          </div>

          {checkPermission("LEADS_PHONE_NUMBER_FIELD") && (
            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText primary={` ${data.phoneNumber}`} />
              </ListItem>
              {data.alternateContact && (
                <p
                  style={{ textAlign: "left" }}
                >{`${data.alternateContact}`}</p>
              )}
            </div>
          )}

          <div className="container col-1 m-0">
            <div className="row" style={{display:"flex"}}>
              <ListItemText primary={`# ${data.enquiryId}`} />
              <button
                style={{ width: 40, height: 30 ,backgroundColor:"#06c6a2",marginLeft:"20px"}}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickTale(data,"newTab")
                }}
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/722/722216.png"
                  style={{ width: 20, height: 20 }}
                />
              </button>
            </div>
          </div>
          {checkPermission("LEADS_ASSIGNED_FIELD") && (
            <div className="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText
                  primary={` ${
                    data?.preAssignToName
                      ? data?.preAssignToName
                      : "Not Assigned"
                  }`}
                />
              </ListItem>
            </div>
          )}

          <div className="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>

            <ListItem>
              <ListItemText primary={` ${data.reason}`} />
            </ListItem>
          </div>

          <div class="container col-1 m-0 capitalize">
            <p className={statusColorFinder(data?.preLeadStatus)}>
              <ListItem>
                <ListItemText
                  className="status-bold"
                  primary={data?.preLeadStatus || ""}
                />
              </ListItem>
            </p>
          </div>

          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText
                primary={`${data.followUpDate ? data.followUpDate : " "}`}
              />
            </ListItem>
          </div>

          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={` ${data.location}`} />
            </ListItem>
          </div>

          <div class="container col-1 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={` ${data.source}`} />
            </ListItem>
          </div>

          <div className="container col-1 m-0 ">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>

            <ListItem>
              <CustomTooltip title={` ${data.notes ? data.notes : ""}`}>
                <ListItemText
                  primary={` ${data.notes ? data.notes : ""}`}
                  style={{
                    whiteSpace: "nowrap",
                    width: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                />
              </CustomTooltip>
            </ListItem>
          </div>
          <hr />
        </div>
      ));
      setHomeData(postData);
    }
  }, [pageData]);

  const loadSources = async () => {
    try {
      const response = await getSources();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setSourceData(data);
    } catch (error) {}
  };

  const getData = async (
    offset,
    searchItem,
    searchPhone,
    preAssignedCode,
    preSelectedStatusIds,
    preLeadStatusIds,
    selectedStatusIds,
    assignedTo,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    followUpFromDate,
    followUpToDate,
    selectedTreatmentIds,
    selectedLocationIds,
    sourceIds,
    searchEnquiryId
  ) => {
    getFilteredOtherPreLeadEnquiries(
      offset,
      perPage,
      selectType.selected,
      preAssignedCode,
      preSelectedStatusIds,
      preLeadStatusIds,
      selectedStatusIds,
      searchItem,
      searchPhone,
      assignedTo,
      enquiryDateFilter,
      enquiryFromDate,
      enquiryToDate,
      followUpDateFilter,
      followUpFromDate,
      followUpToDate,
      selectedTreatmentIds,
      selectedLocationIds,
      sourceIds,
      searchEnquiryId
    )
      .then((res) => {
        const data = res.data.enquiries;
        setTotalCount(res.data.count);
        setPageCount(Math.ceil(res.data.count / perPage));
        setPageData(data);
        setAllData(data);
      })
      .catch((error) => {})
      .finally(() => {
        setDataLoaded(true);
      });
  };
  const handlePageClick = (e) => {
    setReload(false)
    const selectedPage = e.selected;
    setOffset(selectedPage);
    if (selectedPage != offset) {
      setReload(true);
      setDataLoaded(true);
    }
  };

  const handleClickTale = (enquiryData, type, target) => {
    const newPath = `preLeadsDetail/${enquiryData.enquiryId}`;
    const newState = {
      statusColorFinder: statusColorFinder(),
      enquiryDetails: enquiryData,
      offset,
      selectedStatuses,
      selectedStatusIds,
      selectedTreatmentIds,
      selectedTreatments,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      assignedUser,
      pathname: "/preleads",
    };
  
    if (type === "newTab") {
      const newWindow = window.open(newPath, target || "_blank");
      if (newWindow) {
        newWindow.state = newState;
      }
    } else {
      history.push({ pathname: newPath, state: newState });
    }
  };
  

  const clearAllFilter = () => {
    setAssignedUser({ list: [{ id: 0, name: "All", code: "ALL" }] });
    setSelectedStatusIds([0]);
    setSelectedStatuses([]);
    setSelectedLocations([]);
    setSelectedLocationIds([0]);
    setSelectedEnquiryDateFilter("All");
    setSelectedFollowupDateFilter("All");
    setSelectedTreatmentIds([0]);
    setSelectedTreatments([]);
    setSearchItem("");
    setSearchEnquiryFromDate(new Date());
    setSearchEnquiryToDate(new Date());
    setSearchFollowupFromDate(new Date());
    setSearchFollowupToDate(new Date());
    loadAssignedUsers();
    setSelectedAssignedTo([]);
    setSelectedAssignedToIds([]);
    setSelectedSources([]);
    setSelectedSourceIds([]);
    setIsClear((s) => !s);
  };

  const classes = useTableStyles();
  const menuClasses = useStyles();
  const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

  return {
    totalCount,
    offset,
    perPage,
    classes,
    menuClasses,
    homeData,
    userRole,
    pageCount,
    dataLoaded,
    setDataLoaded,
    handleClickTale,
    handlePageClick,
    enquiryStats,
    selectStatus,
    assignedUser,
    searchItem,
    preLeadStatus,
    enquiryDateFilter,

    handleSearchItemChange,
    handleAssignedToChange,
    selectType,
    // handleSelectTypeChange,
    selectStatus,
    filterLoaded,
    selectedEnquiryDateFilter,
    setSelectedEnquiryDateFilter,
    selectedFollowupDateFilter,
    setSelectedFollowupDateFilter,
    searchFollowupToDate,
    setSearchFollowupToDate,
    searchFollowupFromDate,
    setSearchFollowupFromDate,
    searchEnquiryFromDate,
    setSearchEnquiryFromDate,
    searchEnquiryToDate,
    setSearchEnquiryToDate,
    setReload,
    refreshTable,
    followUpDateFilter,
    preLeadTeams,
    selectedStatuses,
    handleSelectStatuses,
    handleSearchEnquiryId,
    isAllStatus,
    statusData,
    selectedStatusIds,
    selectedTreatments,
    handleSelectTreatments,
    isAllTreatment,
    treatmentData,
    selectedTreatmentIds,
    assignedUser,
    searchPhone,
    handleSearchPhone,
    sources,
    selectedSource,
    setSelectedSource,
    getDropdownButtonLabel,
    selectedAssignedTo,
    handleAssignedAgents,
    setSelectedAssignedTo,
    setSelectedTreatments,
    setSelectedStatuses,
    handleSelectLocations,
    setSelectedLocations,
    selectedLocations,
    locationData,
    clearAllFilter,
    selectedSources,
    setSelectedSources,
    sourceData,
    handleSearch,
    handleSelectSources,
    setIsShow,
    isShow
  };
};
export default usePreLeads;
