import moment from "moment";
import { useState, useEffect } from "react";
import { getPatientsDetails } from "../../../services/mykare/PatientsService";
import { getAllDocuments } from "../../../services/mykare/documentService";
import {
  PATIENT_IDENTITY_DOCUMENT,
  PRESCRIPTION_DOCUMENT,
} from "../../../util/documentUtil";

function useAppointment({ patientId, userTrxnId }) {
  const [remove, setRemove] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [ipCheckBox, setIpCheckBox] = useState(false);
  const [opCheckBox, setOpCheckBox] = useState(false);
  const [patientDetail, setPatientDetail] = useState({ age: "", gender: "" });
  const [prescriptionFiles, setPrescriptionFiles] = useState([]);
  const [aadhaarFiles, setAadhaarFiles] = useState([]);

  useEffect(() => {
    loadPatientDetails();
    if (ipCheckBox) {
      loadPatientDocs();
    }
  }, [ipCheckBox, opCheckBox]);

  const loadPatientDocs = () => {
    getAllDocuments(userTrxnId, PATIENT_IDENTITY_DOCUMENT, "aadhaar_card")
      .then((res) => {
        setAadhaarFiles(res.data);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleDropDown = () => {
    setRemove(!remove);
  };
  const handleStatus = (item) => {
    setSelectedStatus(item);
  };
  const handleCheckBox = (type) => {
    if (type == 1) {
      setIpCheckBox(true);
      setOpCheckBox(false);
    } else if (type == 2) {
      setOpCheckBox(true);
      setIpCheckBox(false);
    }
  };

  const loadPatientDetails = async () => {
    const response = await getPatientsDetails(patientId,userTrxnId);
    if (response.status == 200) {
      setPatientDetail({
        age: response.data.age,
        gender: response.data.gender,
      });
    }
  };

  return {
    ipCheckBox,
    opCheckBox,
    handleCheckBox,
    patientDetail,
    aadhaarFiles,
  };
}
export default useAppointment;
