import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import { getAuthHeader, getUserCode, getUserRole } from "../../store/DataStore";

export const getPreLeadsFaqs = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/enquiry/pre/question/all`,
    {
      headers,
    }
  );
  return response;
};

export const savePreLeadsAns = async (postData) => {
  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/pre/question/save`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getFaqsResponse = async (userTrxnId) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/enquiry/pre/answer?userTxnId=${userTrxnId}`,
    {
      headers,
    }
  );
  return response;
};

export const transferLeadsToSales = async (postData) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const response = await axios.post(
      `${APP_URL}/mykare/enquiry/transfer/sales`,
      postData,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllPreLeadsTeam = async (isActive) => {
  let userRole = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };
  if (userRole !== "PRE_SALES_LEAD") {
    userRole = "PRE_LEAD_AGENT";
  }
  const userCodeParam = userRole === "PRE_SALES_LEAD" ? `&userCode=${code}` : ``;
  const url = `${APP_URL}/mykare/team/pre/all?userRole=${userRole}&active=${isActive}${userCodeParam}`;
  try {
    const data = await axios.get(url, {
      headers,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
