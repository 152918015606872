import React from 'react';

function AddField({setName,setLocation,onClicked,onSave}) {
    return (
        <div>
          <hr/>
          <div className="row popup-view" >
          <div className="col-lg-2">
          <input
            onChange={(e)=>setName(e.target.value)}
            placeholder="Name"
            style={{ height: 38, borderRadius: 5, border: `1px solid black` }}
          />
        </div>
        <div className="col-lg-2">
          <input
            onChange={(e)=>setLocation(e.target.value)}
            placeholder="Location"
            style={{ height: 38, borderRadius: 5, border: `1px solid black` }}
          />
        </div>
        <div className="col-lg-1">
          <button style={{padding:10,width:80}} onClick={()=>onClicked(false)} >Cancel</button>
        </div>

        <div className="col-lg-1">
          <button 
            onClick={() =>onSave()}
            style={{padding:10,width:80}}>Save</button>
        </div>
          </div>
          <hr/>  
        </div>
    );
}

export default AddField;