import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
import * as appConstants from "../../util/AppConstants";
import axios from "axios";
const useMykarePartnerShip = () => {
  const location = useLocation();
  const [data, setData] = useState({});
  const [replied, setReplied] = useState(false);
  const [comments, setComments] = useState("");
  const [saveMsg,setSaveMsg] = useState("")
  let history = useHistory();

  const buttonStyle = {
    padding: 10,
    backgroundColor: "#06c6a2",
    width: 100,
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (!location.state) {
      history.push("/partnership");
    } else {
      var enquiryData = location.state.enquiryDetails;


      setData(enquiryData);
      setComments(enquiryData.comments)

      if (enquiryData.status == "replied" || replied) {
        setReplied(true);
      }
    }
  }, [data]);
  const handleComments = (event) => {
    setComments(event);
  };

  const postCommentsApi = async () => {
  
    try {
      const response = await axios.put(
        appConstants.APP_URL +
          "/internal/mykare/partnership/enquiries/updateComment",
          {
            comments: comments,
            id: data?.enquiryId,
            platform: data?.platform,
          },

        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

     if(response.status === 200){
      setSaveMsg("Successfully updated")
     }
     
      return response;
    } catch (error) {

    }
  };

  function back() {
    history.push({
      pathname: "/partnership",
    });
  }

  return {
    data,
    back,
    replied,
    classes,
    buttonStyle,
    saveMsg,
    comments,
    handleComments,
    postCommentsApi,
  };
};

export default useMykarePartnerShip;
