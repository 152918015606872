import React from "react";
import Sidebar from "../../Sidebar";
import { Grid } from "@mui/material";
import AlertDialogSlide from "./PopUp/PopUp";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import usePatientDetail from "./usePatientDetail.logic";
import AddPatientTabs from "./AddPatientTab/AddPatientTabs";
import PatientHistory from "./PatientHistory/PatientHistory";

export default function PatientDetail() {
  const state = usePatientDetail();
  let history = useHistory();

  function back() {
    history.push({
      pathname: "/patients",
    });
  }

  const data = history?.location?.state?.enquiryDetails;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));
  const classes = useStyles();
  return (
    <div className="d-flex">
      <Sidebar />
      <div className={classes.root}>
        <button className="width1" onClick={() => back()}>
          BACK
        </button>
        <hr />
        <div className="row">
          <div class="col-md-1">
            <p className="text-start">Patient Id</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.id}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Patient Name</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.name}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Phone Number</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.mobile}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Age</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.age}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Country</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.country}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">State</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.state}`}</div>
            </p>
          </div>
          <hr />
          <div>
            <Grid item container lg={10} marginLeft={"26px"} height={"70px"}>
              <Grid item lg={0.8}>
                <button
                  style={{ width: 80, height: 40,background:state?.isActive?.isTrue ? "#06c6a2":"gray" }}
                  onClick={() => state?.handleHistoryBtn()}
                >
                  History
                </button>
              </Grid>

              <Grid item lg={0.8}>
                <button
                  style={{ width: 80, height: 40,background:state?.isActive?.isTrue ? "gray" :"#06c6a2"}}
                  onClick={() => state?.handleAddBtn()}
                >
                  Add
                </button>
              </Grid>
            </Grid>
            {state?.isActive?.isTrue ? (
              <PatientHistory
                setIsActive={state?.setIsActive}
                patientDetail={state?.patientDetail}
                patientDetails={state?.patientDetails}
                editPatientDetails={state?.editPatientDetails}
                handleClickOpen={state?.handleClickOpen}
              />
            ) : (
              <AddPatientTabs
                patientDetail={state?.patientDetail}
                activeTab={state?.activeTab}
                handleClickOpen={state?.handleClickOpen}
                isDataLoaded={state?.isDataLoaded}
              />
            )}

            <AlertDialogSlide
              type={state?.type}
              open={state?.open}
              handleDelete={state?.handleDelete}
              handleClose={state?.handleClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}