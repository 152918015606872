import axios from "axios";
import { KAREBUDDY_APP_URL } from "../../util/AppConstants";

export const getAllActivities = async (type) => {
  console.log("=========KAREBUDDY_APP_URL===", KAREBUDDY_APP_URL);
  try {
    const response = await axios.get(
      `${KAREBUDDY_APP_URL}/kareBuddy/statusByType/v2?type=${type}`
    );
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getAllActivityDetails = async (userTxnId) => {
  try {
    const response = await axios.get(
      `${KAREBUDDY_APP_URL}/kareBuddy/detail/${userTxnId}`
    );
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getUpdatedActivity = async (userTxnId) => {
  try {
    const response = await axios.get(
      `${KAREBUDDY_APP_URL}/kareBuddy/activity/${userTxnId}`
    );
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getDocuments = async (context, userTxnId, category) => {
  const response = await axios.get(
    `${KAREBUDDY_APP_URL}/document/all?contextId=${userTxnId}&context=${context}&category=${category}`
  );
  return response;
};
