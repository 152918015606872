import React from "react";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import AppNavigator from "./AppNaviator";
// import ScreenTimeTracker from "./ScreenTimeTracker/ScreenTimeTracker";

function App() {
  return <AppNavigator />;
}

export default App;
