import React from 'react'
import MicRecorder from 'mic-recorder-to-mp3';
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
import './VoiceMessage.css'
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    makeStyles,
  } from "@material-ui/core";
  import DeleteIcon from '@mui/icons-material/Delete';
  import SendIcon from '@mui/icons-material/Send';

function TemplateRecored(props) {
    const {
        isrecording,
        blobURL,
        Recorder,
        globalVoiceMessage,
        sendDocument,
        file,
        patientId} = props



    // logic 
    const onSend =async()=>{
        const message = file
        await globalVoiceMessage({
            Recorder:false,
            isrecording:false,
            isBlocked:false,
            blobURL:"",
            file:""
        })
        await sendDocument(message,patientId)
      }
        const onDelete = async()=>{
         globalVoiceMessage({
            Recorder:false,
            isrecording:false,
            isBlocked:false,
            blobURL:"",
            file:"",
        })
      }
      const  stop = () => {
        Mp3Recorder
          .stop()
          .getMp3()
          .then(([buffer, blob]) => {

            const file = new File(buffer, 'me-at-thevoice.mp3', {
                type: blob.type,
                lastModified: Date.now()
              });
            const blobURL = URL.createObjectURL(blob)
            globalVoiceMessage({
                Recorder:true,
                isrecording:false,
                isBlocked:false,
                blobURL:blobURL,
                file:file
            })

            // this.setState({ blobURL, isRecording: false });
          }).catch((e) => console.log(e));
      };



  return (

    <Grid
    xs={12}
    direction="row"
    container
    item
    justifyContent="center"
    alignItems="center"
    >
        {/* sudden cancel of recoding  sets is recoding to false and deletes all value 
        and reset all state to default */}
        {   !isrecording&&
            <button 
            className='VoiceMessage-Toggle-Btn'
            onClick={onDelete}
            >
                <DeleteIcon/>
            </button>
        }
        <Grid
            item
            xs={6}
            direction="row"
            container
            justifyContent="center"
            alignItems="center"
        >   {/* only show after recording is complete so disable during recording 
            if is recording is false then show send button  */}
            {
                isrecording?
                <span className='Recording-blink'  >Recording...</span>
                :
                <audio src={blobURL} controls="controls"></audio>
            }
        </Grid>
        {/* only show after recording is complete so disable during recording 
        if is recording is false then show send button  */}
        {!isrecording&&
            <button 
            className='VoiceMessage-Toggle-Btn'
            onClick={onSend}     
            >
                <SendIcon/>
            </button>
        }
    </Grid>
  )
}

export default TemplateRecored