import React, { useState } from "react";
import "./TreatmentInfoBtn.css";
import PopupMain from "./PopupMain";
import axios from "axios";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { getAuthHeader } from "../../../store/DataStore";
import { APP_URL } from "../../../util/AppConstants";
import { postTreatmentInfo } from "../../../services/mykare/enquiryService";
import Tooltip from '@mui/material/Tooltip';

function TreatmentInfoBtn(props) {
    // props
    const { id, treatmentPackage } = props;
    // states
    const [toggle, setToggle] = useState(false);
    const [popup, setPopup] = useState({});

    const popupQuestion = async () => {
        
        await postTreatmentInfo(id,"INFO")
            .then((res) => {
                const data = res.data.details;
                setPopup({ ...data, type: "info" });
                setToggle(!toggle);
            })
            .catch((error) => {
                alert("No Data Found");
            });
    };
    const popupTreatment = async () => {
        try {
            
            const res = await postTreatmentInfo(id,"QUESTION");
            const data = res.data.details;
            setPopup({
                ...data,
                type: "questions",
                treatmentPackage: treatmentPackage,
            });
            setToggle(!toggle);
        } catch (e) {
            alert("No Data Found");
        }
    };

    const handletoggle = () => {
        setToggle(false);
    };
    // console.log(popup);
    return (
        <div className="TreatmentInfoBtn">
            <PopupMain
                handletoggle={handletoggle}
                popup={popup}
                toggle={toggle}
            />
            <div className="TreatmentInfoBtn-btn-div">
                <Tooltip title="Treatment Info">
                    <button onClick={popupQuestion}>
                    <InfoOutlinedIcon fontSize="small" />
                    </button>
                </Tooltip>
                
                <Tooltip title="Treatment Questions">
                    <button onClick={popupTreatment}>
                    <HelpOutlineOutlinedIcon fontSize="small" />
                    </button>
                </Tooltip>
                
            </div>
        </div>
    );
}

export default TreatmentInfoBtn;
