import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import * as appConstants from "../../../util/AppConstants";
import { useLocation, useHistory } from "react-router-dom";

import {
  getAllComents,
  getUserTrxnHistory,
} from "../../../services/mykare/enquiryService";
import { checkPermission } from "../../../util/permissionUtils";
import {
  PATIENT_IDENTITY_DOCUMENT,
  PRESCRIPTION_DOCUMENT,
} from "../../../util/documentUtil";

import { getEnquiryDetailsById } from "../../../services/associate/enquiryService";
import {
  getAppointmentDetails,
  saveAppointmentDetail,
} from "../../../services/mykare/AppointmentService";
import { clickToCall, makeACall } from "../../../services/mykare/callService";
import { getAllLocations } from "../../../services/mykare/centerService";
import {
  getAllExoPhoneNumbers,
  getCallerDetails,
  getCallRecordingLinks,
  getCenters,
  getDoctors,
  getTreatments,
  searchComment,
} from "../../../services/mykare/dataService";
import { getAllDocuments } from "../../../services/mykare/documentService";
import {
  getEnquiryByContext,
  getLostReasons,
  getOpSubStatus,
  getUserTrxnStatuses,
  mergeDuplicateLead,
  sendFeedbackCallNotPickedMsg,
  sendFeedbackLink,
  sendFeedbackMessage,
  sendPatientReferralLink,
  updateEnquiry,
  updatePreLeadEnquiry,
} from "../../../services/mykare/enquiryService";
import {
  getAllPreLeadsTeam,
  getFaqsResponse,
  getPreLeadsFaqs,
} from "../../../services/mykare/PreLeadsService";

const usePreLeadsDetail = () => {
  let history = useHistory();
  const isStatus = true;
  const [location, setLocation] = useState(useLocation());
  const [isIPDone, setIsIPDone] = useState(false);
  const [isOPDone, setIsOPDone] = useState(false);
  const [age, setAge] = useState();
  const [data, setData] = useState({});
  const [type, setType] = useState(null);
  const [offset, setOffset] = useState(0);
  const [transId, setTransId] = useState();
  const [message, setMessage] = useState();
  const [gender, setGender] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [comments, setComments] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [exoPhone, setExoPhone] = useState([]);
  const [replied, setReplied] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [centerData, setCenterData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const [smoDetails, setSmoDetails] = useState(null);
  const [assignedCode, setAssignedCode] = useState();
  const userRole = localStorage.getItem("user-role");
  const [assignedAgent, setAssignedAgent] = useState();
  const [resAgentCode, setResAgentCode] = useState("");
  const [patientName, setPatientName] = useState(null);
  const [insuranceId, setInsuranceId] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const [isMotivation, setIsMotivation] = useState(false);
  const [preLeadTeams, setPreLeadTeams] = useState([]);
  const [enquiryStatus, setEnquiryStatus] = useState("");
  const [surgeryDetail, setSurgeryDetail] = useState(null);
  const [enquiryDetail, setEnquiryDetail] = useState(null);
  const [selectedEnquiryDateFilter, setSelectedEnquiryDateFilter] =
    useState("All");
  const [searchEnquiryFromDate, setSearchEnquiryFromDate] = useState(null);
  const [searchEnquiryToDate, setSearchEnquiryToDate] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedStatusIds, setSelectedStatusIds] = useState([0]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([0]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedAssignedToIds, setSelectedAssignedToIds] = useState([]);
  const [selectedPreLeadSatus, setSelectedPreLeadSatus] = useState("");
  const [selectedFollowupDateFilter, setSelectedFollowupDateFilter] =
    useState("All");
  const [searchFollowupFromDate, setSearchFollowupFromDate] = useState(null);
  const [searchFollowupToDate, setSearchFollowupToDate] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);
  const [exoNumber, setExoNumber] = useState(null);
  const [selectedComment, setSelectedComment] = useState([]);
  const [kareBuddyName, setKareBuddyName] = useState(null);
  const [agentName, setAgentName] = useState(null);

  const [selectedComments, setSelectedComments] = useState([]);
  const [selectedCommentsIds, setSelectedCommentsIds] = useState([]);
  const [allComments, setAllcomments] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState("");

  const [query, setQuery] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [contactNumber, setContactNumber] = useState("");
  const [reasonForCall, setReasonForCall] = useState("");
  const [reason, setReason] = useState("");

  const preStatusList = [
    { id: 7, name: "DNP1" },
    { id: 8, name: "DNP2" },
    { id: 9, name: "DNP3" },
    { id: 10, name: "DNP4" },
    { id: 11, name: "DNP5" },
    { id: 1, name: "New Lead" },
    { id: 25, name: "Call Back" },
    { id: 15, name: "Not Interested" },
    { id: 22, name: "Out Of City" },
    { id: 23, name: "Supply Gap" },
    { id: 17, name: "Junk/irrelevent" },
    { id: 36, name: "Non-Workable Exhausted" },
    { id: 2, name: "Transferred" },
    { id: 26, name: "Duplicate Lead" },
    { id: 27, name: "Cross BD" },
  ];
  useEffect(() => {
    if (query !== "") {
      searchComment(query)
        .then((response) => {
          setSearchResults(response.data.comments);
        })
        .catch((error) => {
          console.error("Error searching comments:", error);
        });
    } else {
      setSearchResults([]);
    }
  }, [query]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const setHours = (date, hours) => {
    const newDate = new Date(date);
    newDate.setHours(hours);
    return newDate;
  };

  const setMinutes = (date, minutes) => {
    const newDate = new Date(date);
    newDate.setMinutes(minutes);
    return newDate;
  };

  const loadAllComments = () => {
    getAllComents().then((res) => {
      setAllcomments(res?.data?.comments);
    });
  };

  const [followUpDate, setFollowUpDate] = useState(
    setHours(setMinutes(new Date(), 0), 10)
  );

  const handleComments = (comments, commentIds) => {
    setSelectedComments((pre) => [...pre, comments]);
    setSelectedCommentsIds((preIds) => [...preIds, commentIds]);
  };

  const handleDelete = (index) => {
    setSelectedComments((prevComments) => {
      const newComments = [...prevComments];
      newComments.splice(index, 1);
      return newComments;
    });
    setSelectedCommentsIds((preIds) => {
      const newIds = [...preIds];
      newIds.splice(index, 1);
      return newIds;
    });
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    if (moment(selectedDate).isAfter(currentDate)) {
      if (selectedDate.getHours() < 8 || selectedDate.getHours() > 22) {
        return false;
      }
    }
    return currentDate.getTime() < selectedDate.getTime();
  };

  const [selectedTransferTo, setSelectedTransferTo] = useState();
  const [preventiveCareDetail, setPreventiveCareDetail] = useState(null);
  const [cabDetailsId, setCabDetailsId] = useState(null);
  const [loanDetailId, setLoanDetailId] = useState(null);
  const [appointmentDetailId, setAppointmentDetailId] = useState(null);
  const [usersHistory, setUsersHistory] = useState();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([0]);
  const [selectedTreatment, setselectedTreatment] = useState("");
  const [treatments, setTreatments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedSourceIds, setSelectedSourceIds] = useState([0]);
  const [callDetails, setCallDetails] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState(null);
  const [isTreatment, setIsTreatment] = useState(false);
  const [isLocation, setIsLocation] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [originalUserTxnId, setOriginalUserTxnId] = useState(null);
  const [prescriptionFiles, setPrescriptionFiles] = useState([]);
  const [aadhaarFiles, setAadhaarFiles] = useState([]);
  const [subStatus, setSubStatus] = useState([]);
  const [subStatusId, setSubStatusId] = useState(null);

  const [selectedTab, setSelectedTab] = useState({
    isOpen: true,
    isPreLeads: false,
    isPatientsDocs: false,
    isPatientsDetail: false,
  });
  const [selectedPaymentType, setSelectedPaymentType] = useState("All");
  const ipPaymentModes = ["All", "EMI", "CASH", "CASHLESS", "REIMBURSEMENT"];

  const [selectedIntentFilter, setSelectedIntentFilter] = useState("All");
  const [selectedPaymentModeFilter, setSelectedPaymentModeFilter] =
    useState("All");
  const [selectedIntent, setSelectedIntent] = useState(null);
  const intentModes = ["HOT", "WARM", "COLD"];

  const getUserEnquiryId = () => {
    const url = window?.location?.pathname;
    let path = url.slice(url?.lastIndexOf("/")).split("/")[1];
    return path;
  };

  const [faqData, setFaqData] = useState([]);
  const [faqAnswer, setFaqAnswer] = useState([]);
  const [questionResp, setQuestionResp] = useState([]);

  const [questionAnswered, setQuestionAnswered] = useState(false);

  const [isPreLeadEdit, setIsPreLeadEdit] = useState(false);
  const [editedAnswers, setEditedAnswers] = useState([...faqAnswer]);
  const [isEditing, setIsEditing] = useState(false);

  const loadPatientDocs = (contextId, context, category, setFiles) => {
    getAllDocuments(contextId, context, category)
      .then((res) => {
        setFiles(res.data);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const getEnquiryDetails = async () => {
    const enquiryId = data?.enquiryId ? data?.enquiryId : getUserEnquiryId();
    const response = await getEnquiryDetailsById(enquiryId,"prelead");
    if (response.data) {
      var enquiryData = response.data;
      setData(enquiryData);
      setPatientId(enquiryData.patientId);
      setCabDetailsId(enquiryData.cabDetailsId);
      setLoanDetailId(enquiryData.loanDetailId);
      setInsuranceId(enquiryData.insuranceDetailId);
      setAppointmentDetailId(enquiryData.appointmentDetailId);
      getUserTxnHistory(enquiryData.enquiryId);
      setSelectedIntent(enquiryData?.intent);
      setType(enquiryData.categoryContext);
      getCategoryDetails(
        enquiryData.categoryContextId,
        enquiryData.categoryContext
      );
      setSelectedReasons(enquiryData.lostReason);
      setStatus(enquiryData.preLeadStatus);
      setSubStatusId(enquiryData.userTxnSubStatus);
      setStatusId(enquiryData.preLeadStatusId);
      setGender(enquiryData.gender);
      setPatientName(enquiryData.patientName);
      setAge(enquiryData.age);
      setSourceId(enquiryData.sourceId);
      setResAgentCode(enquiryData.assignTo);
      setAgentName(enquiryData.assignToName);
      setKareBuddyName(enquiryData.kareBuddyName);
      if (enquiryData.followUpDate) {
        setFollowUpDate(new Date(enquiryData.followUpDate));
      }
      if (enquiryData.status == "replied" || replied) {
        setReplied(true);
      }
      if (enquiryData.location == null) {
        setIsLocation(true);
      }
    }
  };

  const getLostReason = async (statusId) => {
    const response = await getLostReasons(statusId);
    if (response.status === 200) {
      if (response.data) {
        setReasons(response.data);
      }
    }
  };

  useEffect(() => {
    loadTreatments();
    loadAllLocations();
    loadCallDetails();
    loadAllExophoneNumbers();
    if (data?.enquiryId) {
      loadPatientDocs(
        data.enquiryId,
        PRESCRIPTION_DOCUMENT,
        "prescription",
        setPrescriptionFiles
      );
      loadPatientDocs(
        data.enquiryId,
        PATIENT_IDENTITY_DOCUMENT,
        "aadhaar_card",
        setAadhaarFiles
      );
    }
  }, [data]);

  const loadCallDetails = async () => {
    if (data.enquiryId) {
      const response = await getCallerDetails(data.enquiryId);
      if (response.data) {
        setCallDetails(response.data.details);
      }
    }
  };

  const loadAllExophoneNumbers = async () => {
    const response = await getAllExoPhoneNumbers();
    if (response.status == 200) {
      if (response.data) {
        let data = response.data.map((val, id) => {
          if (response.data[0].id == val.id) {
            val.phoneNumber = val.phoneNumber + "(default)";
            return val;
          } else {
            return val;
          }
        });
        setExoPhone(data);
      }
    }
  };

  const getCallLinks = async (callSid) => {
    const response = await getCallRecordingLinks(callSid);
    if (response?.data?.recordingUrl) {
      window.confirm(`Do you want to play the record ?`) &&
        window.open(response.data.recordingUrl, "_blank");
    } else {
      alert("Sorry audio not found..!");
    }
  };

  useEffect(() => {
    if (data.statusId) {
      getLostReason(data.statusId);
    }
    getSubStatus();
  }, [data]);

  useEffect(() => {
    if (!!resAgentCode && userData.length > 0) {
      const assignedName = userData
        .filter((item) => item.code === resAgentCode)
        .map((item) => item.name)[0];
      setAssignedAgent(assignedName);
    }
  }, [resAgentCode, userData]);

  useEffect(() => {
    if (enquiryDetail && treatments.length > 0 && locations.length > 0) {
      const treatmentName = handleTreatmentName(enquiryDetail);
      if (!treatmentName) {
        setIsTreatment(true);
      }
      const treatment = treatments
        .filter(
          (item) => item.name?.toUpperCase() === treatmentName?.toUpperCase()
        )
        .map((item) => item)[0];
      setselectedTreatment(treatment);
      const location = locations
        .filter(
          (item) =>
            item.name?.toUpperCase() === enquiryDetail.location?.toUpperCase()
        )
        .map((item) => item)[0];
      setSelectedLocation(location);
    }
    if (data && reasons.length > 0) {
      const reason = reasons
        ?.filter(
          (item) => item.name?.toUpperCase() === data.lostReason?.toUpperCase()
        )
        .map((item) => item)[0];
      setSelectedReasons(reason);
    }
    setSelectedPaymentType(enquiryDetail?.paymentType);
  }, [enquiryDetail, treatments, locations, reasons, data, isTreatment]);

  useEffect(() => {
    if (checkPermission("ENQUIRY_UPDATE_SUB_TAB")) {
      handleHideFun(1);
    } else {
      handleHideFun(2);
    }
  }, []);

  useEffect(() => {
    if (!location.state) {
      if (window.state) {
      } else {
        getEnquiryDetails();
      }
    } else {
      getEnquiryDetails();
      var enquiryData = location.state.enquiryDetails;
      setData(enquiryData);
      setPatientId(enquiryData.patientId);
      setCabDetailsId(enquiryData.cabDetailsId);
      setLoanDetailId(enquiryData.loanDetailId);
      setInsuranceId(enquiryData.insuranceDetailId);
      setAppointmentDetailId(enquiryData.appointmentDetailId);
      getUserTxnHistory(enquiryData.enquiryId);
      setOffset(location.state.offset);
      setEnquiryStatus(location.state.enquiryStatus);
      setSelectedEnquiryDateFilter(location.state.selectedEnquiryDateFilter);
      setSelectedPaymentModeFilter(location.state.selectedPaymentModeFilter);
      setSelectedIntentFilter(location.state.selectedIntentFilter);
      setSearchEnquiryFromDate(location.state.searchEnquiryFromDate);
      setSearchEnquiryToDate(location.state.searchEnquiryToDate);
      setAssignedUser(location.state.assignedUser);
      setSelectedStatuses(location.state.selectedStatuses);
      setSelectedStatusIds(location.state.selectedStatusIds);
      setSelectedTreatments(location.state.selectedTreatments);
      setSelectedTreatmentIds(location.state.selectedTreatmentIds);
      setSelectedLocations(location.state.selectedLocations);
      setSelectedLocationIds(location.state.selectedLocationIds);
      setSelectedSources(location.state.selectedSources);
      setSelectedSourceIds(location.state.selectedSourceIds);
      setSelectedFollowupDateFilter(location.state.selectedFollowupDateFilter);
      setSearchFollowupFromDate(location.state.searchFollowupFromDate);
      setSearchFollowupToDate(location.state.searchFollowupToDate);
      setSelectedTransferTo(enquiryData.transferTo?.name);
      setSelectedAssignedTo(location.state?.selectedAssignedTo);
      setSelectedAssignedToIds(location.state?.selectedAssignedToIds);
      setType(enquiryData.categoryContext);
      getCategoryDetails(
        enquiryData.categoryContextId,
        enquiryData.categoryContext
      );
      setStatus(enquiryData.status);
      setSubStatusId(enquiryData.userTxnSubStatus);
      setStatusId(enquiryData.statusId);
      setGender(enquiryData.gender);
      setPatientName(enquiryData.patientName);
      setAge(enquiryData.age);
      setSourceId(enquiryData.sourceId);
      setResAgentCode(enquiryData.assignTo);
      if (enquiryData.followUpDate) {
        setFollowUpDate(new Date(enquiryData.followUpDate));
      }
      if (enquiryData.status == "replied" || replied) {
        setReplied(true);
      }
    }
  }, [location]);

  const click2Call = (phoneType) => {
    const postData = {
      phoneType,
      userTrxnId: data?.enquiryId,
    };
    setIsOpen(true);
    makeACall(postData)
      .then((res) => {
        alert("Please wait, your call is connecting!");
      })
      .catch((err) => {
        alert("Error Occuered, please contact for Tech support");
      });
  };
  const showFollowUpField = () => {
    if (statusId !== 12 && statusId !== 13 && statusId !== 14) {
      return true;
    } else {
      return true;
    }
  };

  const [open, setOpen] = useState(false);
  const [popUpType, setPopUpType] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setPopUpType(false);
    setOpen(false);
  };

  const checkAppointment = async () => {
    if (data.appointmentDetailId) {
      getAppointmentDetails(data.appointmentDetailId).then((res) => {
        setAppointmentDetails(res.data);
        if (res.data?.ipDetails) {
          setIsIPDone(true);
        }
        if (res.data?.opDetails) {
          setIsOPDone(true);
        }
      });
    }
  };

  useEffect(() => {
    if (
      statusId == 19 ||
      statusId == 18 ||
      statusId == 21 ||
      statusId == 20 ||
      statusId == 39 ||
      statusId == 40 ||
      statusId == 41 ||
      statusId == 42 ||
      statusId == 43 ||
      statusId == 44
    ) {
      checkAppointment();
    }
  }, [statusId, data]);

  const showEnquiryActionFields = () => {
    if (
      statusId !== 17 &&
      statusId !== 16 &&
      statusId !== 23 &&
      statusId !== 15
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showIntentFields = () => {
    if (
      statusId === 12 ||
      statusId === 13 ||
      statusId === 14 ||
      statusId === 21
    ) {
      return true;
    } else {
      return false;
    }
  };

  const loadTreatments = async () => {
    try {
      getTreatments().then((res) => {
        setTreatments(res.data);
      });
    } catch (error) {}
  };
  const loadAllLocations = async () => {
    try {
      getAllLocations().then((data) => {
        setLocations(data);
      });
    } catch (error) {}
  };

  const handleTreatmentName = (detail) => {
    var treatmentName = "";
    if (detail.packages) {
      treatmentName = detail.packages;
    } else if (detail.treatmentPackage) {
      treatmentName = detail.treatmentPackage;
    } else if (detail.speciality) {
      treatmentName = detail.speciality;
    }
    return treatmentName;
  };

  function handlePick(selectedItem, tnxId) {
    setSelectedTransferTo(selectedItem);
    setTransId(tnxId);
  }
  const handleStatus = (status, id) => {
    if (id === 1) {
      setComments(null);
    }
    setStatus(status);
    setStatusId(id);
    setSelectedPreLeadSatus(status);
  };
  const handleSubStatus = (val) => {
    setSubStatusId(val);
  };
  const handleHideFun = (select) => {
    if (select === 1) {
      getEnquiryDetails();
      setSelectedTab({ isOpen: true });
    } else if (select === 2) {
      setSelectedTab({ isPreLeads: true });
    } else if (select === 3) {
      setSelectedTab({ isPatientsDocs: true });
    } else if (select === 4) {
      setSelectedTab({ isPatientsDetail: true });
    }
  };
  const loadCenters = async () => {
    try {
      getCenters().then((res) => {
        setCenterData(res.data);
      });
    } catch (error) {}
  };
  const loadDoctors = async () => {
    try {
      getDoctors().then((res) => {
        setDoctorData(res.data);
      });
    } catch (error) {
      throw error;
    }
  };
  const loadStatuses = async () => {
    try {
      getUserTrxnStatuses().then((res) => {
        if (isStatus) {
          setStatusList(preStatusList);
        } else {
          setStatusList(res.data);
        }
      });
    } catch (error) {}
  };

  const getCategoryDetails = async (categoryContextId, categoryContext) => {
    try {
      getEnquiryByContext(categoryContextId, categoryContext).then(
        (response) => {
          if (response.data.smoDetail) {
            setSmoDetails(response.data.smoDetail);
          } else if (response.data.preventiveCareDetail) {
            setPreventiveCareDetail(response.data.preventiveCareDetail);
          } else if (response.data.surgeryDetail) {
            setSurgeryDetail(response.data.surgeryDetail);
          } else if (response.data.enquiryDetail) {
            setEnquiryDetail(response.data.enquiryDetail);
          }
        }
      );
    } catch (error) {}
  };

  const getUserTxnHistory = async (enquiryId) => {
    try {
      getUserTrxnHistory(enquiryId).then((res) => {
        setUsersHistory(res.data);
      });
    } catch (error) {}
  };

  const saveEnquiryData = async (postData) => {
    try {
      const data = await updateEnquiry(postData);
      const response = { ...data };
      if (response.status === 200) {
        getEnquiryDetails();
        alert("Successfully Updated");
        setQuery("");
        setSelectedComment([]);
      }
      return response;
    } catch (error) {
      console.log("error", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert("Error: " + error.response.data.message);
      } else {
        alert("An error occurred. Please try again.");
      }
    } finally {
      if (statusId == 21 || statusId === 19) setIsMotivation(true);
      setTimeout(() => {
        setIsMotivation(false);
      }, 3000);
    }
  };

  const loadPreLeadsFaqs = () => {
    getPreLeadsFaqs().then((faqs) => {
      if (faqs !== null) {
        setFaqData(
          faqs?.data?.preLeadQuestions?.map((faq) => ({
            question: faq?.question,
            questionId: faq?.id,
            answer: "",
            type: faq?.type,
            userTrxnId: data?.enquiryId,
          }))
        );
      }
    });
  };

  const handleChange = (value, id, index) => {
    const updatedQuestions = faqData.map((question) => {
      if (question.questionId === id) {
        return { ...question, answer: value };
      }
      return question;
    });
    setFaqData(updatedQuestions);
  };

  const loadFaqsResponse = () => {
    getFaqsResponse(data?.enquiryId).then((data) => {
      if (data?.status === 200) {
        setQuestionAnswered(false);
        setFaqAnswer(data?.data?.leadAnswers);
      }
    });
  };

  useEffect(() => {
    loadPreLeadsFaqs();
    loadFaqsResponse();
  }, [data]);

  const saveEnquiryPreLeadData = async () => {
    const postData = {
      followUpDate: moment(followUpDate).format().split("+")[0],
      leadFilterListRequest: {
        leadFilterRequests: faqData.some((val) => val.answer !== "")
          ? faqData.map((val) => ({
              questionId: val.questionId,
              answer: val.answer,
              userTxnId: val.userTxnId,
            }))
          : [],
      },
      locationId: selectedLocation?.id,
      notes: selectedNotes,
      paymentType: selectedPaymentType,
      preLeadAssignTo: assignedCode,
      preLeadStatusId: statusId,
      treatmentId: selectedTreatment?.id,
      userTxnId: data.enquiryId,
    };

    try {
      const data = await updatePreLeadEnquiry(postData);
      const response = { ...data };
      if (response.status === 200) {
        getEnquiryDetails();
        alert("Successfully Updated");
        setIsPreLeadEdit(false);
        setQuery("");
        setSelectedComment([]);
      }
      return response;
    } catch (error) {
      console.log("error", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert("Error: " + error.response.data.message);
      } else {
        alert("An error occurred. Please try again.");
      }
    } finally {
      if (statusId == 21 || statusId === 19) setIsMotivation(true);
      setTimeout(() => {
        setIsMotivation(false);
      }, 3000);
    }
  };

  const getSubStatus = async () => {
    const response = await getOpSubStatus();
    if (response.status == 200) {
      setSubStatus(response.data);
    }
  };

  const saveAppointmentDetails = async () => {
    let postData = null;
    if (statusId == 19 || statusId == 18) {
      const myDate = moment(appointmentDetails.ipDetails.arrivalDateTime)
        .format()
        .split("+")[0];
      postData = {
        userTxnId: data.enquiryId,
        iPDetail: { ...appointmentDetails.ipDetails, arrivalDateTime: myDate },
      };
    } else {
      const myDate = moment(appointmentDetails.opDetails?.dateTime)
        .format()
        .split("+")[0];
      postData = {
        userTxnId: data.enquiryId,
        oPDetail: { ...appointmentDetails.opDetails, dateTime: myDate },
      };
    }
    const response = await saveAppointmentDetail(postData);
    if (response.status == 200) {
      let myDate = null;
      if (followUpDate !== null) {
        myDate = moment(followUpDate).format().split("+")[0];
      }
      const postData = {
        userTxnId: data.enquiryId,
        statusId: statusId,
        transferTo: transId,
        followUpDate: myDate,
        treatmentId: selectedTreatment?.id,
        locationId: selectedLocation?.id,
        notes: selectedComment,
        reasonId: selectedReasons?.id !== undefined ? selectedReasons.id : null,
        userTxnSubStatusId:
          subStatusId?.id !== undefined ? subStatusId.id : null,
        age: age,
        assignTo: assignedCode,
        gender: gender ? gender.toUpperCase() : null,
        paymentType: selectedPaymentType,
      };
      saveEnquiryData(postData);
    }
    handleClose();
  };

  const postStatus = async () => {
    let myDate = null;
    if (followUpDate !== null) {
      myDate = moment(followUpDate).format().split("+")[0];
    }
    const postData = {
      userTxnId: data.enquiryId,
      statusId: statusId,
      transferTo: transId,
      followUpDate: myDate,
      treatmentId: selectedTreatment?.id,
      locationId: selectedLocation?.id,
      notes: selectedComment,
      reasonId: selectedReasons?.id !== undefined ? selectedReasons.id : null,
      userTxnSubStatusId: subStatusId?.id !== undefined ? subStatusId.id : null,
      age: age,
      assignTo: assignedCode,
      gender: gender ? gender.toUpperCase() : null,
      paymentType: selectedPaymentType,
      intent: selectedIntent,
    };

    const isAllDetailsFilled =
      postData.locationId && postData.treatmentId && postData.paymentType;

    if (
      statusId === 28 ||
      statusId === 30 ||
      statusId === 35 ||
      statusId === 32 ||
      statusId === 23
    ) {
      if (!isAllDetailsFilled) {
        alert(
          "Please fill the required details (Patient Location, Treatment, Payment Type)"
        );
      } else if (
        (statusId == 28 || statusId == 30) &&
        postData.reasonId === null
      ) {
        alert("Please select any reason!");
      } else {
        saveEnquiryData(postData);
      }
    } else if (
      statusId == 19 ||
      statusId == 18 ||
      statusId == 39 ||
      statusId == 40 ||
      statusId == 43
    ) {
      if (!isIPDone) {
        handleHideFun(4);
        alert("Please fill the appointment IPD details to update the enquiry.");
      } else if (!isAllDetailsFilled) {
        alert(
          "Please fill the required details (Patient Location, Treatment, Payment Type)"
        );
      } else {
        if (
          (selectedPaymentType === "REIMBURSEMENT" ||
            selectedPaymentType === "CASHLESS") &&
          insuranceId == null
        ) {
          alert(" Please upload insurance card details");
          handleHideFun(2);
        } else {
          setPopUpType(false);
          handleClickOpen();
        }
      }
    } else if (
      statusId == 21 ||
      statusId == 20 ||
      statusId == 30 ||
      statusId == 41 ||
      statusId == 42 ||
      statusId == 44
    ) {
      if (!isOPDone) {
        handleHideFun(4);
        alert("Please fill the appointment OPD details to update the enquiry.");
      } else if (!isAllDetailsFilled) {
        alert(
          "Please fill the required details (Patient Location, Treatment, Payment Type)"
        );
      } else {
        if (statusId == 21) {
          if (prescriptionFiles.length === 0) {
            alert("Please upload prescription if opd done");
            handleHideFun(6);
          } else if (!subStatusId) {
            alert("Please choose the substatus");
          } else {
            setPopUpType(false);
            handleClickOpen();
          }
        } else {
          setPopUpType(false);
          handleClickOpen();
        }
      }
    } else if (statusId >= 12 && statusId <= 14 && data.type == "Enquiry") {
      if (userRole == "SALES_AGENT" && followUpDate && isAllDetailsFilled) {
        saveEnquiryData(postData);
      } else if (userRole !== "SALES_AGENT" && isAllDetailsFilled) {
        saveEnquiryData(postData);
      } else {
        alert(
          "Please fill the required details (Patient Location, Treatment, Payment Type, Followup date)"
        );
      }
    } else {
      saveEnquiryData(postData);
    }
  };

  const loadAllPreLeadsTeam = () => {
    getAllPreLeadsTeam(true).then((data) => {
      setPreLeadTeams(
        data?.data?.users?.map((data) => {
          return {
            value: data?.code,
            label: data?.name,
          };
        })
      );
    });
  };

  useEffect(() => {
    loadCenters();
    loadDoctors();
    loadStatuses();
    getAgentData();
    loadAllComments();
    loadAllPreLeadsTeam();
  }, []);

  const mergeLead = async () => {
    let mergeData = null;
    mergeData = {
      userTxnId: data.enquiryId,
      originalUserTxnId: originalUserTxnId,
    };
    try {
      const response = await mergeDuplicateLead(mergeData);
      if (response.status == 200) {
        alert("Duplicate Lead Merged sucessfully");
        handleClose();
      }
    } catch (error) {
      alert("Unable to merge. No duplicate numbers found.");
      handleClose();
    }
  };
  const feedbackLink = async () => {
    let sendData = null;
    sendData = {
      userTxnId: data.enquiryId,
    };
    const response = await sendFeedbackLink(sendData);
    if (response.status == 200) {
      alert("Feedback link send sucessfully");
    }
  };

  const sendPatientReferral = async () => {
    let sendData = null;
    sendData = {
      userTxnId: data.enquiryId,
    };
    const response = await sendPatientReferralLink(sendData);
    if (response.status == 200) {
      alert("Patient Referral link send sucessfully");
    }
  };

  const sendFeedbackCallNotPicked = async () => {
    let sendData = null;
    sendData = {
      userTxnId: data.enquiryId,
    };
    const response = await sendFeedbackCallNotPickedMsg(sendData);
    if (response.status == 200) {
      alert("Feedback call not picked message send sucessfully");
    }
  };

  const feedbackMessage = async () => {
    let sendData = null;
    sendData = {
      userTxnId: data.enquiryId,
    };
    const response = await sendFeedbackMessage(sendData);
    if (response.status == 200) {
      alert("Feedback message send sucessfully");
    }
  };

  const handleToAssigned = (assignTo, assignCode) => {
    setAssignedAgent(assignTo);
    setAssignedCode(assignCode);
  };
  const handlePhonumber = (value) => {
    setExoNumber(value);
  };
  const buttonStyle = {
    padding: 10,
    backgroundColor: "#06c6a2",
    width: 100,
  };
  const refreshPersonalDetails = (personalData) => {
    setPatientName(personalData.patientName);
    setAge(personalData.age);
    setGender(personalData.gender);
  };

  const getAgentData = async () => {
    try {
      const data = await axios.get(
        appConstants.APP_URL + `/user/all?userRole=SALES_AGENT&active=true`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      setUserData(data.data.users);

      return data;
    } catch (error) {}
  };
  const updateReply = () => {
    setReplied(false);
  };

  const makeAcall = () => {
    const postData = {
      callReason: reasonForCall === "Other" ? reason : reasonForCall,
      callType: "OUTGOING",
      toNumber: contactNumber,
      userTrxnId: data?.enquiryId,
    };
    makeACall(postData)
      .then((res) => {
        alert("Please wait, your call is connecting!");
      })
      .catch((err) => {
        alert("Error Occuered, please contact for Tech support");
      });
  };

  function back() {
    const path = location?.state?.pathname
      ? location?.state?.pathname
      : "/preleads";
    history.push({
      pathname: path,
      state: {
        offset,
        selectedStatuses,
        selectedStatusIds,
        selectedTreatments,
        selectedTreatmentIds,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        selectedPaymentModeFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedSourceIds,
        selectedSources,
        selectedLocations,
        selectedLocationIds,
        assignedUser,
        selectedIntent: selectedIntentFilter,
        selectedPaymentType,
        selectedAssignedTo,
        selectedAssignedToIds,
      },
    });
  }

  return {
    age,
    data,
    gender,
    isOpen,
    replied,
    message,
    userData,
    userRole,
    comments,
    smoDetails,
    patientName,
    buttonStyle,
    centerData,
    followUpDate,
    assignedCode,
    resAgentCode,
    enquiryStatus,
    surgeryDetail,
    enquiryDetail,
    assignedAgent,
    selectedTransferTo,
    preventiveCareDetail,
    statusList,
    status,
    isMotivation,
    doctorData,
    usersHistory,
    selectedTab,
    cabDetailsId,
    setCabDetailsId,
    appointmentDetailId,
    setAppointmentDetailId,
    loanDetailId,
    setLoanDetailId,
    setInsuranceId,
    showEnquiryActionFields,
    showIntentFields,
    insuranceId,
    back,
    setAge,
    handleStatus,
    handlePick,
    postStatus,
    updateReply,
    handleHideFun,
    setFollowUpDate,
    setComments,
    handleToAssigned,
    handleTreatmentName,
    refreshPersonalDetails,
    showEnquiryActionFields,
    click2Call,
    patientId,
    selectedTreatment,
    setselectedTreatment,
    selectedLocation,
    setSelectedLocation,
    treatments,
    locations,
    setLocations,
    showFollowUpField,
    callDetails,
    getCallLinks,
    reasons,
    setSelectedReasons,
    selectedReasons,
    getLostReason,
    statusId,
    isTreatment,
    isLocation,
    open,
    setOpen,
    setIsOpen,
    handleClickOpen,
    handleClose,
    appointmentDetails,
    selectedPreLeadSatus,
    setAppointmentDetails,
    saveAppointmentDetails,
    ipPaymentModes,
    selectedPaymentType,
    setSelectedPaymentType,
    sourceId,
    setSourceId,
    intentModes,
    selectedIntent: selectedIntent,
    setSelectedIntent: setSelectedIntent,
    feedbackLink,
    feedbackMessage,
    exoNumber,
    setExoNumber,
    handlePhonumber,
    exoPhone,
    setHours,
    setMinutes,
    filterPassedTime,
    sendPatientReferral,
    sendFeedbackCallNotPicked,
    mergeLead,
    setPopUpType,
    popUpType,
    originalUserTxnId,
    setOriginalUserTxnId,
    setSelectedComment,
    selectedComment,
    setSearchResults,
    searchResults,
    setQuery,
    query,
    preLeadTeams,
    handleInputChange,
    prescriptionFiles,
    aadhaarFiles,
    handleSubStatus,
    subStatus,
    subStatusId,
    setContactNumber,
    contactNumber,
    reasonForCall,
    setReasonForCall,
    makeAcall,
    saveEnquiryPreLeadData,
    faqAnswer,
    setReason,
    reason,
    faqData,
    questionResp,
    setQuestionResp,
    handleChange,
    selectedComments,
    handleComments,
    handleDelete,
    allComments,
    setSelectedNotes,
    selectedNotes,
    setIsEditing,
    isEditing,
    setIsPreLeadEdit,
    isPreLeadEdit,
    editedAnswers,
    setFaqData,
  };
};

export default usePreLeadsDetail;
