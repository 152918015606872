import { Category } from "@material-ui/icons";
import { useState, useRef, useEffect } from "react";
import {
  getAllDocuments,
  uploadDocuments,
} from "../../../services/mykare/documentService";
import {
  getInsuranceDetails,
  getRejectedReasons,
  updateInsuranceDetails,
  uploadInsuranceDocs,
} from "../../../services/mykare/insuranceService";

import {
  getCenters,
  getInsuranceStatus,
} from "../../../services/mykare/dataService";
import { PATIENT_IDENTITY_DOCUMENT } from "../../../util/documentUtil";

function useInsurance({
  userTrxnId,
  insuranceDetailId,
  callBackFn,
  handleHide,
}) {
  const inputInsuranceFile = useRef(null);
  const inputInsuranceRejectedFile = useRef(null);

  const inputInsuranceApprovedFile = useRef(null);

  const inputInitialApprovalFile = useRef(null);
  const inputQueryRaisedFile = useRef(null);

  const inputFinalApprovalFile = useRef(null);
  const inputPreAuthFile = useRef(null);

  const inputAdharRef = useRef(null);
  const inputPanRef = useRef(null);
  const inputPrescriptionRef = useRef(null);
  const [insuranceFiles, setInsuranceFiles] = useState([]);
  const [insuranceApprovedFiles, setInsuranceApprovedFiles] = useState([]);

  const [initialApprovalFile, setInitialApprovalFile] = useState([]);
  const [queryRaisedFile, setQueryRaisedFile] = useState([]);
  const [preAuthFile, setPreAuthFile] = useState([]);

  const [insuranceRejectedFiles, setInsuranceRejectedFiles] = useState([]);
  const [savedInsuranceDocs, setSavedInsuranceDocs] = useState([]);
  const [savedInsuranceApprovedDocs, setSavedInsuranceApprovedDocs] = useState(
    []
  );

  const [
    savedInsuranceInitialApprovalDocs,
    setSavedInsuranceInitialApprovalDocs,
  ] = useState([]);
  const [savedInsuranceQueryRaisedDocs, setSavedInsuranceQueryRaisedDocs] =
    useState([]);
  const [savedInsurancePreAuthDocs, setSavedInsurancePreAuthDocs] = useState(
    []
  );

  const [savedInsuranceRejectedDocs, setSavedInsuranceRejectedDocs] = useState(
    []
  );
  const [followUpDate, setFollowUpDate] = useState(new Date());
  const [policyData, setPolicyData] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [tpa, setTpa] = useState("");
  const [sumInsured, setSumInsured] = useState("");

  const [balanceSumInsured, setBalanceSumInsured] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [selectedRoomType, setSelectedRoomType] = useState("Single");
  const [insuranceStatus, setInsuranceStatus] = useState(null);

  const [roomRent, setRoomRent] = useState("");
  const [comments, setComments] = useState("");
  const [roomTypes, setRoomTypes] = useState(["Single", "Double Sharing"]);
  const [statuses, setStatuses] = useState([
    "Approved",
    "Rejected",
    "UnderProcess",
  ]);
  const [editableMode, setEditableMode] = useState(true);
  const [rejectedReasons, setRejectedReasons] = useState("");
  const [reasons, setReasons] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState("");
  const [copayChecked, setCopayChecked] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [copayAmount, setCopayAmount] = useState("");

  const [selectedCenter, setSelectedCenter] = useState(null);
  const [centerData, setCenterData] = useState([]);

  const inputPaymentBill = useRef(null);

  const [copayPaymentBillFiles, setCopayPaymentBillFiles] = useState([]);

  const [savedCopayPaymentBillDocs, setSavedCopayPaymentBillDocs] = useState(
    []
  );

  const [initialApprovalAmount, setInitialApprovalAmount] = useState("");

  const [finalApprovalAmount, setFinalApprovalAmount] = useState("");

  const [paymentMode, setPaymentMode] = useState("");
  const paymentModes = ["CASHLESS", "REIMBURSEMENT"];

  const [insuranceType, setInsuranceType] = useState("");
  const insuranceTypes = ["INDIVIDUAL", "CORPORATE"];
  const [aadhaarFiles, setAadhaarFiles] = useState([]);

  useEffect(() => {
    loadCenters();
    loadPatientDocs();
    loadInsuranceStatus();
  }, []);

  const loadCenters = async () => {
    try {
      getCenters().then((res) => {
        setCenterData(res.data);
      });
    } catch (error) { }
  };

  const loadInsuranceStatus = async () => {
    getInsuranceStatus().then((res) => {
      setStatuses(res.data);
    });
  };

  const loadPatientDocs = () => {
    getAllDocuments(userTrxnId, PATIENT_IDENTITY_DOCUMENT, "aadhaar_card")
      .then((res) => {
        setAadhaarFiles(res.data);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  useEffect(() => {
    if (userTrxnId) {
      loadInsuranceDocs();
      loadInsuranceCopayDocs();
    }
    // getInsuranceRejectedReasons();
  }, [insuranceStatus]);

  useEffect(() => {
    if (insuranceDetailId) {
      loadInsuranceDetails(insuranceDetailId);
    }
  }, [centerData, insuranceDetailId]);

  const loadInsuranceDetails = (detailId) => {
    getInsuranceDetails(detailId)
      .then((res) => {
        if (res.data.insuranceStatusId === 1 && !res.data.policyNumber) {
          setEditableMode(true);
        } else {
          setEditableMode(false);
        }
        loadInsuranceData(res.data);
      })
      .catch((error) => { });
  };

  const loadInsuranceData = (insuranceData) => {
    setPolicyNumber(insuranceData.policyNumber);
    setInsuranceCompany(insuranceData.insuranceCompany);
    setTpa(insuranceData.tpa);
    setSumInsured(insuranceData.sumInsured);
    setBalanceSumInsured(insuranceData.balanceSumInsured);
    setRoomRent(insuranceData.roomRent);
    setSelectedReasons({
      id: insuranceData.rejectedReasonId,
      name: insuranceData.rejectedReason,
    });
    setSelectedRoomType(insuranceData.roomType);
    setInsuranceType(insuranceData.insuranceType);
    setPaymentMode(insuranceData.paymentType);
    setInsuranceStatus({
      name: insuranceData.insuranceStatus,
      id: insuranceData.insuranceStatusId,
    });
    setInitialApprovalAmount(insuranceData.initialApprovalAmount);
    setFinalApprovalAmount(insuranceData.finalApprovalAmount);
    setComments(insuranceData.comments);
    if (insuranceData.collectedDate) {
      setSelectedDate(new Date(insuranceData.collectedDate));
    }
    setCopayAmount(insuranceData.copayAmount);
    const center = centerData.filter(
      (item) => item.code == insuranceData.centerCode
    )[0];
    setSelectedCenter(center);
    if (insuranceData.copayAmount || insuranceData.centerCode) {
      setCopayChecked(true);
    } else {
      setCopayChecked(false);
    }
  };

  const loadInsuranceDocs = () => {
    const context =
      insuranceStatus?.id === 2
        ? "INSURANCE_INITIAL_APPROVAL_DOC"
        : insuranceStatus?.id === 6
          ? "INSURANCE_FINAL_APPROVAL_DOC"
          : insuranceStatus?.id === 4
            ? "INSURANCE_REJECTED_DOC"
            : insuranceStatus?.id === 3
              ? "INSURANCE_PRE_AUTH_DOC"
              : insuranceStatus?.id === 5
                ? "INSURANCE_QUERY_DOC"
                : "INSURANCE_DOCUMENT";
    const category =
      insuranceStatus?.id === 2
        ? "insurance_initial_approval_doc"
        : insuranceStatus?.id === 6
          ? "insurance_final_approval_doc"
          : insuranceStatus?.id === 4
            ? "insurance_rejected"
            : insuranceStatus?.id === 3
              ? "insurance_pre_auth_doc"
              : insuranceStatus?.id === 5
                ? "query_raised_doc"
                : "insurance_card";
    getAllDocuments(userTrxnId, context, category)
      .then((res) => {
        if (
          res.data.find((o) => o.documentContext === "INSURANCE_REJECTED_DOC")
        ) {
          setSavedInsuranceRejectedDocs(res.data);
        } else if (
          res.data.find((o) => o.documentContext === "INSURANCE_APPROVED_DOC")
        ) {
          setSavedInsuranceApprovedDocs(res.data);
        } else if (
          res.data.find(
            (o) => o.documentContext === "INSURANCE_INITIAL_APPROVAL_DOC"
          )
        ) {
          setSavedInsuranceApprovedDocs(res.data);
        } else if (
          res.data.find(
            (o) => o.documentContext === "INSURANCE_FINAL_APPROVAL_DOC"
          )
        ) {
          setSavedInsuranceApprovedDocs(res.data);
        } else if (
          res.data.find((o) => o.documentContext === "INSURANCE_PRE_AUTH_DOC")
        ) {
          setSavedInsurancePreAuthDocs(res.data);
        } else if (
          res.data.find((o) => o.documentContext === "INSURANCE_QUERY_DOC")
        ) {
          setSavedInsuranceQueryRaisedDocs(res.data);
        } else {
          setSavedInsuranceDocs(res.data);
        }
      })
      .catch((error) => { });
  };

  const loadInsuranceCopayDocs = () => {
    const context = "INSURANCE_COPAY_PAYMENT_DOC";
    const category = "insurance_copay_doc";
    getAllDocuments(userTrxnId, context, category)
      .then((res) => {
        if (
          res.data.find(
            (o) => o.documentContext === "INSURANCE_COPAY_PAYMENT_DOC"
          )
        ) {
          setSavedCopayPaymentBillDocs(res.data);
        }
      })
      .catch((error) => { });
  };

  // =======for insurancecard start======//
  const handleInsuranceFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setInsuranceFiles((state) => [...state, file]);
  };

  const removeItem = (id) => {
    const filesClone = insuranceFiles.filter((file) => file.id !== id);
    setInsuranceFiles(filesClone);
  };
  const removeCopayItem = (id) => {
    const filesClone = copayPaymentBillFiles.filter((file) => file.id !== id);
    setCopayPaymentBillFiles(filesClone);
  };

  const handleInsuranceApprovedFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setInsuranceApprovedFiles((state) => [...state, file]);
  };

  const removeApprovedItem = (id) => {
    const filesClone = insuranceApprovedFiles.filter((file) => file.id !== id);
    setInsuranceApprovedFiles(filesClone);
  };

  const handleInsuranceInitialFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setInitialApprovalFile((state) => [...state, file]);
  };

  const removeInitialFileItem = (id) => {
    const filesClone = initialApprovalFile.filter((file) => file.id !== id);
    setInitialApprovalFile(filesClone);
  };

  const handleInsuranceQueryRaisedFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setQueryRaisedFile((state) => [...state, file]);
  };

  const removeQueryRaisedFileItem = (id) => {
    const filesClone = queryRaisedFile.filter((file) => file.id !== id);
    setQueryRaisedFile(filesClone);
  };

  const handlePreAuthFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setPreAuthFile((state) => [...state, file]);
  };

  const removePreAuthFileItem = (id) => {
    const filesClone = preAuthFile.filter((file) => file.id !== id);
    setPreAuthFile(filesClone);
  };

  const handleInsuranceRejectedFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setInsuranceRejectedFiles((state) => [...state, file]);
  };

  const handleCopayPaymentBill = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    console.log("=======file=======", file);

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setCopayPaymentBillFiles((state) => [...state, file]);
  };

  const removeRejectedItem = (id) => {
    const filesClone = insuranceRejectedFiles.filter((file) => file.id !== id);
    setInsuranceRejectedFiles(filesClone);
  };

  const handleInsuranceStatus = (value) => {
    setInsuranceStatus({ name: value.name, id: value.id });
    getInsuranceRejectedReasons(value.id);
  };

  const saveInsuranceDetails = () => {
    const postData = {
      policyNumber,
      sumInsured,
      insuranceCompany,
      balanceSumInsured,
      roomType: selectedRoomType,
      tpa,
      roomRent,
      insuranceStatusId: insuranceStatus?.id,
      comments,
      insuranceDetailId,
      paymentType: paymentMode,
      initialApprovalAmount,
      finalApprovalAmount,
      insuranceType,
    };
    if (copayChecked) {
      postData.isCopay = copayChecked;
      postData.collectedDate = selectedDate;
      postData.copayAmount = copayAmount;
      postData.centerId = selectedCenter.id;
    }
    if (insuranceStatus.id === 4) {
      postData.rejectedReasonId = selectedReasons.id;
      if (postData.rejectedReasonId) {
        updateInsuranceDetails(postData)
          .then((res) => {
            loadInsuranceDetails(insuranceDetailId);
          })
          .catch((err) => { });
      } else {
        alert("Please Select the reason");
      }
    } else {
      updateInsuranceDetails(postData)
        .then((res) => {
          loadInsuranceDetails(insuranceDetailId);
        })
        .catch((err) => { });
    }

    if (copayPaymentBillFiles.length) {
      addCopayPaymentBillDocs();
    }
    console.log("insuranceRejectedLength: " + insuranceRejectedFiles.length);
    if (insuranceRejectedFiles.length) {
      addInsuranceRejectedDocs();
    }
    if (insuranceApprovedFiles.length) {
      addInsuranceApprovedDocs();
    }
    if (initialApprovalFile.length) {
      addInsuranceInitialDocs();
    }
    if (queryRaisedFile.length) {
      addInsuranceQueryRaisedDocs();
    }
    if (preAuthFile.length) {
      addInsurancePreAuthFileDocs();
    }
  };

  const addInsuranceDocs = async () => {
    let formData = new FormData();
    insuranceFiles.forEach((file) => {
      formData.append("insuranceDocs", file);
    });
    formData.append("userTxnId", userTrxnId);
    formData.append("documentContext", "INSURANCE_DOCUMENT");

    if (aadhaarFiles.length) {
      uploadInsuranceDocs(formData)
        .then((res) => {
          alert("Upload Successfully");
          setInsuranceFiles([]);
          loadInsuranceDocs();
          callBackFn(res.data);
        })
        .catch((err) => { });
    } else {
      alert(
        "Please upload patient aadhar document before uploading insurance card"
      );
      handleHide(6);
    }
  };

  const addInsuranceApprovedDocs = async () => {
    let formData = new FormData();
    insuranceApprovedFiles.forEach((file) => {
      formData.append("documents", file);
    });
    formData.append("category", "insurance_final_approval_doc");
    formData.append("contextId", userTrxnId);
    formData.append("context", "INSURANCE_FINAL_APPROVAL_DOC");

    uploadDocuments(formData)
      .then((data) => {
        alert("Upload Successfully");
        setInsuranceApprovedFiles([]);
        loadInsuranceDocs();
      })
      .catch((err) => { });
  };

  const addInsuranceInitialDocs = async () => {
    let formData = new FormData();
    initialApprovalFile.forEach((file) => {
      formData.append("documents", file);
    });
    formData.append("contextId", userTrxnId);
    formData.append("category", "insurance_initial_approval_doc");
    formData.append("context", "INSURANCE_INITIAL_APPROVAL_DOC");

    uploadDocuments(formData)
      .then((data) => {
        alert("Upload Successfully");
        setInitialApprovalFile([]);
        loadInsuranceDocs();
      })
      .catch((err) => { });
  };

  const addInsuranceQueryRaisedDocs = async () => {
    let formData = new FormData();
    queryRaisedFile.forEach((file) => {
      formData.append("documents", file);
    });
    formData.append("contextId", userTrxnId);
    formData.append("category", "query_raised_doc");
    formData.append("context", "INSURANCE_QUERY_DOC");

    uploadDocuments(formData)
      .then((data) => {
        alert("Upload Successfully");
        setQueryRaisedFile([]);
        loadInsuranceDocs();
      })
      .catch((err) => { });
  };

  const addInsurancePreAuthFileDocs = async () => {
    let formData = new FormData();
    preAuthFile.forEach((file) => {
      formData.append("documents", file);
    });
    formData.append("category", "insurance_pre_auth_doc");
    formData.append("contextId", userTrxnId);
    formData.append("context", "INSURANCE_PRE_AUTH_DOC");

    uploadDocuments(formData)
      .then((data) => {
        alert("Upload Successfully");
        setPreAuthFile([]);
        loadInsuranceDocs();
      })
      .catch((err) => { });
  };

  const addInsuranceRejectedDocs = async () => {
    let formData = new FormData();
    insuranceRejectedFiles.forEach((file) => {
      formData.append("documents", file);
    });
    formData.append("category", "insurance_rejected");
    formData.append("contextId", userTrxnId);
    formData.append("context", "INSURANCE_REJECTED_DOC");
    uploadDocuments(formData)
      .then((data) => {
        alert("Upload Successfully");
        setInsuranceRejectedFiles([]);
        loadInsuranceDocs();
      })
      .catch((err) => {
        console.log("err==================", err);
      });
  };

  const getInsuranceRejectedReasons = async (statusId) => {
    const response = await getRejectedReasons(statusId);
    if (response.status === 200) {
      if (response.data) {
        setReasons(response.data);
      }
    }
  };

  const addCopayPaymentBillDocs = async () => {
    let formData = new FormData();
    copayPaymentBillFiles.forEach((file) => {
      formData.append("documents", file);
    });
    formData.append("contextId", userTrxnId);
    formData.append("category", "insurance_copay_doc");
    formData.append("context", "INSURANCE_COPAY_PAYMENT_DOC");

    uploadDocuments(formData)
      .then((data) => {
        alert("Upload Successfully");
        setCopayPaymentBillFiles([]);
        loadInsuranceCopayDocs();
      })
      .catch((err) => { });
  };

  return {
    removeItem,
    handleInsuranceFile,
    removeApprovedItem,
    handleInsuranceApprovedFile,

    removeInitialFileItem,
    handleInsuranceInitialFile,

    removeQueryRaisedFileItem,
    handleInsuranceQueryRaisedFile,

    removePreAuthFileItem,
    handlePreAuthFile,

    removeRejectedItem,
    handleInsuranceRejectedFile,
    inputPrescriptionRef,
    inputInsuranceFile,
    inputInsuranceRejectedFile,
    inputInsuranceApprovedFile,

    savedInsurancePreAuthDocs,
    savedInsuranceInitialApprovalDocs,
    savedInsuranceQueryRaisedDocs,

    inputInitialApprovalFile,
    inputFinalApprovalFile,
    inputPreAuthFile,
    inputQueryRaisedFile,

    inputAdharRef,
    inputPanRef,
    followUpDate,
    insuranceFiles,
    insuranceApprovedFiles,
    setInsuranceApprovedFiles,

    setInitialApprovalFile,
    initialApprovalFile,
    queryRaisedFile,
    setQueryRaisedFile,

    preAuthFile,
    setPreAuthFile,

    insuranceRejectedFiles,
    setInsuranceRejectedFiles,
    savedInsuranceDocs,
    insuranceCompany,
    addInsuranceDocs,
    addInsuranceRejectedDocs,
    addInsuranceApprovedDocs,

    addInsuranceInitialDocs,

    setInsuranceCompany,
    policyNumber,
    setPolicyNumber,
    roomTypes,
    policyData,
    tpa,
    comments,
    setTpa,
    sumInsured,
    setSumInsured,
    balanceSumInsured,
    setBalanceSumInsured,
    statuses,
    roomRent,
    setRoomRent,
    insuranceStatus,
    setInsuranceStatus,
    selectedRoomType,
    setSelectedRoomType,
    saveInsuranceDetails,
    editableMode,
    setEditableMode,
    setComments,
    getInsuranceRejectedReasons,
    rejectedReasons,
    setRejectedReasons,
    reasons,
    selectedReasons,
    setSelectedReasons,
    savedInsuranceApprovedDocs,
    savedInsuranceRejectedDocs,
    copayChecked,
    setCopayChecked,
    selectedCenter,
    setSelectedCenter,
    centerData,
    setCenterData,
    selectedDate,
    setSelectedDate,
    copayAmount,
    setCopayAmount,
    inputPaymentBill,
    handleCopayPaymentBill,
    copayPaymentBillFiles,
    setCopayPaymentBillFiles,
    addCopayPaymentBillDocs,
    savedCopayPaymentBillDocs,
    handleInsuranceStatus,

    initialApprovalAmount,
    setInitialApprovalAmount,

    finalApprovalAmount,
    setFinalApprovalAmount,

    insuranceTypes,
    setInsuranceType,
    insuranceType,

    paymentMode,
    setPaymentMode,
    paymentModes,
    removeCopayItem,
  };
}

export default useInsurance;
