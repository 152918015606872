import { useState, useEffect } from "react";
import {
  getPatientsDetails,
  updatePatientsDetails,
} from "../../../services/karetrip/PatientsService";

function usePatientsDetail({ patientId, refreshFn, userTransaxtionId }) {
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [alternateContact, setAlternateContact] = useState("");
  const [selectedGender, setSelectedGender] = useState("Male");
  const [gender, setGender] = useState(["Male", "Female", "Other"]);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (patientId || userTransaxtionId) {
      loadPatientDetails(patientId, userTransaxtionId);
    }
  }, []);
  const loadPatientDetails = (patientId, userTransaxtionId) => {
    getPatientsDetails(patientId, userTransaxtionId)
      .then((res) => {
        setName(res.data.patientName);
        setAge(res.data.age);
        setEmail(res.data.email);
        setAlternateContact(res.data.alternateContact);

        if (res.data.gender) {
          setSelectedGender(res.data.gender);
        }
      })
      .catch((error) => {});
  };

  const editPatientDetail = async () => {
    const postData = {
      userId: patientId,
      gender: selectedGender.toUpperCase(),
      age: age,
      email: email,
      patientName: name,
      alternateContact: alternateContact,
      userTxnId: userTransaxtionId,
    };
    const response = await updatePatientsDetails(postData);
    refreshFn(postData);
    alert("Successfully updated");
  };

  return {
    name,
    successMessage,
    age,
    email,
    gender,
    selectedGender,
    setAge,
    setEmail,
    setName,
    alternateContact,
    setAlternateContact,
    setSelectedGender,
    editPatientDetail,
  };
}

export default usePatientsDetail;
