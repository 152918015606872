import React, { useEffect, useRef, useState } from "react";
import "./Popup.css";
import avatar from "../../../assets/icons/avatar.png";
import show_popup from "../../../assets/icons/icon.png";
import file_upload from "../../../assets/icons/upload.png";
import temp_icon from "../../../assets/icons/temp_icon.png";
import download from "../../../assets/icons/download.png";
import TextField from "@material-ui/core/TextField";
import {
  downloadAttachment,
  getAllMessages,
  getAllMessageTemplates,
  sendDocuments,
  sendWaMessage,
} from "../../../services/mykare/messageService";
import downloadDocument from "../../../util/documentUtil";
import useMykareEnquiry from "../../../Pages/myKareEnquiryDetail/useMykareEnquiry.logic";
import moment from "moment";

const ChatPopUp = () => {
  const state = useMykareEnquiry();

  const refObj = useRef();
  const targetRef = useRef(null);
  const [showChat, setShowChat] = useState(false);
  const [buttonHide, setButtonHide] = useState(true);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [listData, setListData] = useState([]);
  const [image, setImage] = useState("");
  const [templateList, setTemplates] = useState([]);
  const loadTemplates = async () => {
    try {
      getAllMessageTemplates('NOTIFIER', false).then((data) => {
        setTemplates(data);
      });
    } catch (error) {}
  };
  const handleClick = () => {
    refObj.current.click();
  };

  const handleFileUpload = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.target.files[0]) {
      sendDocument(event.target.files[0], state.patientId);
    }
  };
  const handleChange = (sendingData) => {
    sendMessage(sendingData);
    setData("");
  };

  const fileDownload = (documentId) => {
    downloadAttachment(documentId).then((data) => {
      downloadDocument(data.documentType, data.document, data.documentName);
    });
  };

  const getMessage = async () => {
    const message = await getAllMessages(state?.patientId, 0);
    if (message) {
      setListData(message);
    }
  };
  const handleScrollTo = () => {
    if (targetRef.current) {
      targetRef.current.scrollTop = targetRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.scrollTop = targetRef.current.scrollHeight;
    }
  }, [showChat, listData]);

  const sendDocument = async (data, patientId) => {
    const response = await sendDocuments(data, patientId);
    if (response.status == 200) {
      const responseData = response.data;
      if (responseData) {
        setListData((current) => ({
          ...current,
          messages: [...current.messages, responseData],
        }));
      }
    }
  };
  const handleTemplate = (template) => {
    const data = {
      id: 1,
      data: { text: template.value, documentId: null },
      type: "text",
      author: "me",
      date: Date.now(),
      value: null,
      senderName: null,
    };

    setListData((current) => ({
      ...current,
      messages: [...current.messages, data],
    }));

    const postData = {
      patientId: state.patientId,
      templateId: template.id,
    };
    sendWaMessage(postData).then((data) => {
      getMessage();
    });
  };

  const sendMessage = (message) => {
    const data = {
      id: 1,
      data: { text: message, documentId: null },
      type: "text",
      author: "me",
      date: Date.now(),
      value: null,
      senderName: null,
    };
    setListData((current) => ({
      ...current,
      messages: [...current.messages, data],
    }));
    const postData = {
      patientId: state.patientId,
      message: message,
    };

    sendWaMessage(postData).then(() => {
      getMessage();
    });
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  useEffect(() => {
    getMessage();
  }, [state.patientId]);

  return (
    <div>
      <div className="chat-container">
        {buttonHide && (
          <div className="button-hide">
            <img
              className="show-button"
              src={show_popup}
              alt=""
              onClick={() => {
                setShowChat(true);
                setButtonHide(false);
                handleScrollTo();
              }}
            />
          </div>
        )}
        {showChat && (
          <div className="popup-container">
            <div className="popup-head">
              <div className="chat-head">
                <h3>Kare Chat</h3>
              </div>
              <div className="close-button">
                <button
                  className="close-btn"
                  onClick={() => {
                    setShowChat(false);
                    setButtonHide(true);
                  }}>
                  X
                </button>
              </div>
            </div>
            <div className="popup-header">
              <div className="avatar-div">
                <img className="avatar" src={avatar} alt="avatar" />
              </div>
              <div className="details">
                <h5>{state.patientName}</h5>
              </div>
            </div>
            <div ref={targetRef} className="popup-body">
              {listData.messages &&
                listData?.messages?.map((value, key) => {
                  return (
                    <div>
                      <div className="main-msg-me">
                        {value.author == "me" && value.data.text && (
                          <div key={key} className="msg-in">
                            <p
                              style={{
                                padding: "5px 5px",
                                maxWidth: "100%",
                                wordBreak: "break-word",
                              }}>
                              {value.data.text}
                            </p>
                            <div
                              style={{
                                fontSize: "10px",
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: " 0 5px 5px",
                              }}>
                              {moment(value.date).format("DD-MM-YYYY hh:mm a")}
                            </div>
                          </div>
                        )}
                        {value.data?.documentId && value.author == "me" && (
                          <div className="document-dwnliad">
                            <div style={{ display: "flex" }}>
                              <img
                                className="download"
                                src={download}
                                alt=""
                                onClick={() =>
                                  fileDownload(value.data?.documentId)
                                }></img>
                              <h3
                                className="icon-dwnload"
                                onClick={() =>
                                  fileDownload(value.data?.documentId)
                                }>
                                {value.data?.documentId}
                              </h3>
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: " 0 5px 5px",
                              }}>
                              {moment(value.date).format("DD-MM-YYYY hh:mm a")}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="main-msg-them">
                        {value.author == "them" && value.data.text && (
                          <div className="msg-out">
                            <p
                              style={{
                                padding: "5px",
                                maxWidth: "100%",
                                wordBreak: "break-word",
                              }}>
                              {value.data.text}
                            </p>
                            <div
                              style={{
                                fontSize: "10px",
                                pading: "0px 0px 0px 20px",
                              }}>
                              {moment(value.date).format("DD-MM-YYYY hh:mm a")}
                            </div>
                          </div>
                        )}
                        {value.data?.documentId && value.author == "them" && (
                          <div className="document-dwnliad">
                            <div style={{ display: "flex" }}>
                              <img
                                className="download"
                                src={download}
                                alt=""
                                onClick={() =>
                                  fileDownload(value.data?.documentId)
                                }></img>
                              <h3
                                className="icon-dwnload"
                                onClick={() =>
                                  fileDownload(value.data?.documentId)
                                }>
                                {value.data?.documentId}
                              </h3>
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                margin: "5px",
                              }}>
                              {moment(value.date).format("DD-MM-YYYY hh:mm a")}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            {show && (
              <div className="msg-temp">
                {templateList?.map((template) => (
                  <button
                    style={{
                      width: "100%",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      handleTemplate(template);
                      setShow(false);
                    }}>
                    {template.name}
                  </button>
                ))}
              </div>
            )}
            <div className="popup-footer">
              <div className="chat">
                <TextField
                  multiline
                  onChange={(e) => setData(e.target.value)}
                  value={data}
                  style={{
                    paddingLeft: "10px",
                    width: "100%",
                    fontSize: "14px",
                  }}
                  InputProps={{ disableUnderline: true }}
                />
                <img
                  onClick={handleClick}
                  src={file_upload}
                  alt=""
                  style={{ width: "6%", cursor: "pointer" }}
                />
                <input
                  ref={refObj}
                  onChange={handleFileUpload.bind(this)}
                  type="file"
                  className="file-upload"
                />
                {image && <img src={image} alt="preview" />}
                <img
                  src={temp_icon}
                  alt=""
                  onClick={() => {
                    setShow(true);
                  }}
                  style={{ width: "20%", cursor: "pointer" }}
                />
              </div>
              <button
                onClick={() => handleChange(data)}
                className="send-button">
                send
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ChatPopUp;
