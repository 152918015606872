import moment from "moment";
import { useState, useEffect } from "react";
import {
  getAppointmentDetails,
  saveAppointmentDetail,
} from "../../../../services/mykare/AppointmentService";

function useOpDetail({
  userTrxnId,
  detailId,
  centerData,
  doctorData,
  locationData,
  callBackFn,
  treatment,
  handleHide,
  patientDetail,
}) {
  const [remove, setRemove] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [ipCheckBox, setIpCheckBox] = useState(false);
  const [opCheckBox, setOpCheckBox] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState({
    id: 0,
    name: "Dr. Name",
    code: "MYKDR000",
  });
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedCenter, setSelectedCenter] = useState({
    id: 0,
    name: "Select Center",
    code: "MYKCE000",
  });
  const [selectedConsultationMode, setSelectedConsultationMode] = useState("");
  const [detailsId, setDetailsId] = useState(detailId);
  const [opDetails, setOpDetails] = useState({
    consultationMode: "ONLINE",
    dateTime: new Date(),
  });
  const [editableMode, setEditableMode] = useState(true);

  const consultationModes = ["ONLINE", "OFFLINE", "HOME_VISIT"];

  const [opStatus, setOpStatus] = useState([
    "SCHEDULED",
    "COMPLETED",
    "RESCHEDULED",
    "CANCELLED",
    "PLANNED",
    "REPLANNED"
  ]);

  useEffect(() => {
    if (detailId) {
      loadAppointmentDetails(detailId);
    }
    if (!opDetails.treatment) {
      setOpDetails((s) => ({ ...s, treatment: treatment }));
    }
  }, []);

  const handleDropDown = () => {
    setRemove(!remove);
  };
  const handleStatus = (item) => {
    setSelectedStatus(item);
  };

  const loadAppointmentDetails = async (detailId) => {
    getAppointmentDetails(detailId)
      .then((res) => {
        if (res.data.opDetails) {
          const myDate = new Date(res.data.opDetails.dateTime);

          const center = centerData.filter(
            (item) => item.code === res.data.opDetails.centerCode
          )[0];
          setSelectedCenter(center);
          const location = locationData.filter(
            (item) => item.id === res.data.opDetails.locationId
          )[0];
          setSelectedLocation(location);

          const doctor = doctorData.filter(
            (item) => item.code === res.data.opDetails.doctorCode
          )[0];
          setSelectedDoctor(doctor);

          const { dateTime, ...datas } = res.data.opDetails;
          setOpDetails(datas);
          setOpDetails((s) => ({ ...s, dateTime: myDate }));

          setEditableMode(false);
        }
      })
      .catch((error) => {});
  };

  const handleCenter = (item) => {
    setSelectedCenter(item);
    setOpDetails((s) => ({ ...s, centerCode: item.code }));
  };
  const handleLocation = (item) => {
    setSelectedLocation(item);
    setOpDetails((s) => ({ ...s, locationId: item.id }));
  };

  const handleDoctor = (item) => {
    setSelectedDoctor(item);
    setOpDetails((s) => ({ ...s, doctorCode: item.code }));
  };

  const saveAppointmentDetails = async () => {
    console.log("Saved Successfully");
    const myDate = moment(opDetails.dateTime).format().split("+")[0];
    const postData = {
      userTxnId: userTrxnId,
      opDetailId: detailId,
      oPDetail: { ...opDetails, dateTime: myDate },
    };
    
    let isAllDetailsFilled = false;
    if (postData.oPDetail.opStatus === "PLANNED" || postData.oPDetail.opStatus === "REPLANNED") {
      isAllDetailsFilled = 
        postData.oPDetail.remarks &&
        postData.oPDetail.dateTime
    }
    else {
    isAllDetailsFilled =
      postData.oPDetail.consultationMode &&
      postData.oPDetail.dateTime &&
      postData.oPDetail.treatment &&
      postData.oPDetail.doctorCode &&
      postData.oPDetail.centerCode &&
      postData.oPDetail.opStatus;
    }
    if (isAllDetailsFilled) {
      if (patientDetail.age && patientDetail.gender) {
        try {
          const response = await saveAppointmentDetail(postData);
          if (response.status === 200) {
            alert("Saved Successfully");
            handleHide(1);
            callBackFn(response.data.appointmentDetailId);
            loadAppointmentDetails(response.data.appointmentDetailId);
            window.location.reload()
          }
        } catch (error) {
          console.error("Failed to save appointment details:", error);
          alert("Failed to save appointment details. Please try again.");
        }
      } else {
        alert(
          "Failed to update! Please fill Patient Details"
        );
        handleHide(7);
      }
    } else {
      alert("Failed to update !! Please check if you have filled all details?");
    }
  };

  return {
    remove,
    opStatus,
    doctorData,
    centerData,
    selectedStatus,
    consultationModes,
    selectedDoctor,
    selectedCenter,
    selectedConsultationMode,
    setRemove,
    handleStatus,
    handleDropDown,
    handleCenter,
    handleDoctor,
    editableMode,
    setEditableMode,
    setSelectedDoctor,
    setSelectedCenter,
    setSelectedConsultationMode,
    saveAppointmentDetails,
    ipCheckBox,
    opCheckBox,
    setOpDetails,
    opDetails,
    locationData,
    selectedLocation,
    handleLocation,
  };
}
export default useOpDetail;
