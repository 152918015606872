import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import { getAuthHeader } from "../../store/DataStore";
import { getUserCode, getUserRole } from "../../store/DataStore";

export const getAgentData = async (isActive) => {
  let userRole = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  if (userRole !== "SALES_LEAD") {
    userRole = "SALES_AGENT";
  }
  const userCodeParam = userRole === "SALES_LEAD" ? `&userCode=${code}` : ``;
  const url = `${APP_URL}/user/all?userRole=${userRole}&active=${isActive}${userCodeParam}`;
  try {
    const data = await axios.get(url, {
      headers,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
