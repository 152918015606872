import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import { getAuthHeader, getUserCode, getUserName } from "../../store/DataStore";

export const saveCabDetailApi = async (postData) => {
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/appointment/saveCabDetails`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const updateCabDetailApi = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.put(
    `${APP_URL}/mykare/appointment/updateCabDetails`,
    postData,
    {
      headers,
    }
  );
  return response;
};
export const getCabDetailApi = async (userTrxnId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/appointment/getCabDetails/${userTrxnId}`,
    {
      headers,
    }
  );
  return response;
};
