import axios from "axios";
import JSZip from "jszip";
import moment from "moment";
import { saveAs } from "file-saver";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import idFormatter from "../../util/idFormatter";
import * as appConstants from "../../util/AppConstants";
import { useHistory,useLocation } from "react-router-dom";

const useEnquiry = () => {
  let history = useHistory();
  const location = useLocation();
  const [data, setData] = useState({});
  const [replyId, setReplyId] = useState(0);
  const [replied, setReplied] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [inputs, setinputs] = useState(["input-0"]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [replyComments, setReplyComments] = useState("");
  const [replyDocuments, setReplyDocuments] = useState([]);
  const [disableDownload, setDisableDownload] = useState(false);
  const [offset, setOffset] = useState(null);

  function appendInput() {
    var newInput = `input-${inputs.length}`;
    setinputs([...inputs, newInput]);
  }

  const removeInput = (index) => {
    const list = [...inputs];
    list.splice(index, 1);
    setinputs(list);
  };

  const onFileChange = (event) => {
    var temp = selectedFiles;
    temp.push(event.target.files[0]);
    setselectedFiles(temp);
  };

  useEffect(() => {
    if (!location.state) {
      history.push("/home");
    } else {
      var enquiryData = location.state.enquiryDetails;
      setData(enquiryData);

      setOffset(location.state.offset);

      loadDocuments(enquiryData);

      if (enquiryData.status == "replied" || replied) {
        setReplied(true);
        loadEnquiryReply(enquiryData);
      }
    }
  }, []);

  function loadDocuments(data) {
    axios
      .get(
        appConstants.APP_URL + "/internal/partner/enquiryDocuments/" + data.id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setDocuments(res.data);
      });
  }

  function loadEnquiryReply(data) {
    axios
      .get(appConstants.APP_URL + "/internal/partner/enquiryReply/" + data.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setReplyId(res.data.id);
        setReplyComments(res.data.remark);
        loadReplyDocuments(res.data.id);
      });
  }

  async function updateEnquiry() {
    const postData = {
      enquiryId: data.id,
      transferTo: true,
    };
    try {
      const response = await axios.put(
        appConstants.APP_URL + "/internal/partner/updateEnquiry",
        postData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
    } catch (error) {}
  }

  function loadReplyDocuments(enquiryReplyId) {
    axios
      .get(
        appConstants.APP_URL +
          "/internal/partner/enquiryReplyDocuments/" +
          enquiryReplyId,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setReplyDocuments(res.data);
      });
  }

  function downloadDocument(fileName) {
    axios
      .get(
        appConstants.APP_URL +
          "/internal/partner/enquiryDocuments/file/" +
          fileName,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        fileDownload(res.data, fileName);
      });
  }

  function downloadReplyDocument(fileName) {
    axios
      .get(
        appConstants.APP_URL +
          "/internal/partner/enquiryReplyDocuments/file/" +
          fileName,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        fileDownload(res.data, fileName);
      });
  }
  const updateReply = () => {
    setReplied(false);
  };

  const replyEnquiry = (data) => {
    axios
      .post(
        appConstants.APP_URL + "/internal/partner/enquiryReply/save",
        { enquiryId: data.id, remark: replyComments, id: replyId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          if (selectedFiles.length !== 0) {
            uploadReplyDouments(resp.data.id);
          } else {
            setReplyId(resp.data.id);
            setReplied(true);
          }
        }
      });
  };

  function uploadReplyDouments(enquiryReplyId) {
    selectedFiles.forEach((selectedFile, index) => {
      var randomNum = Math.ceil(Math.random() * 999999);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("enquiryId", enquiryReplyId);
      formData.append("fileName", randomNum + "_" + selectedFile.name);
      formData.append("fileType", selectedFile.type);
      formData.append("type", "document");
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      axios
        .post(
          appConstants.APP_URL + "/internal/partner/enquiryReplyDocuments/save",
          formData,
          config
        )
        .then((res) => {
          loadReplyDocuments(enquiryReplyId);
          setReplied(true);
        })
        .catch((err) => {});
    });
    setselectedFiles([]);
  }
  function downloadAllDocuments() {
    var zip = new JSZip();
    var count = 0;
    var zipFilename = `${data.patientName}_documents.zip`;
    setDisableDownload(true);
    documents.forEach((doc, index) => {
      axios
        .get(
          appConstants.APP_URL +
            "/internal/partner/enquiryDocuments/file/" +
            doc.fileName,
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          zip.file(doc.fileName, res.data);
          count++;
          if (count == documents.length) {
            zip.generateAsync({ type: "blob" }).then((content) => {
              setDisableDownload(false);
              saveAs(content, zipFilename);
            });
          }
        });
    });
  }

  function back() {
    history.push({
      pathname: "/home",
      state: { offset },
    });
  }

  const Mailto = (subject, data) => {
    let params = subject ? "?" : "";
    let bodyValues = "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (data) {
      if (data.patientName) {
        bodyValues = "Patient Name : " + data.patientName + "\n";
      }
      if (data.patientId) {
        bodyValues += "Patient ID : " + idFormatter(data.patientId) + "\n";
      }
      if (data.type) {
        bodyValues += "Type : " + data.type + "\n";
      }
      if (data.patientAge) {
        bodyValues += "Age : " + data.patientAge + "Years \n";
      }
      if (data.status) {
        bodyValues += "Status : " + data.status + "\n";
      }
      if (data.id) {
        bodyValues += "Enquiry ID : " + idFormatter(data.id) + "\n";
      }
      if (data.centerName) {
        bodyValues += "Center Name : " + data.centerName + "\n";
      }
      if (data.specialityName) {
        bodyValues += "Speciality Name : " + data.specialityName + "\n";
      }
      if (data.doctorName) {
        bodyValues += "Doctor Name : " + data.doctorName + "\n";
      }
      if (data.preferredDate) {
        bodyValues +=
          "Preferred Date : " +
          moment(data.preferredDate).format("DD-MM-YYYY") +
          "\n";
      }
      if (data.testName) {
        bodyValues += "Test Name : " + data.testName + "\n";
      }
      if (data.preferredCity) {
        bodyValues += "Preferred City : " + data.preferredCity + "\n";
      }
      if (data.arrivalDate) {
        bodyValues +=
          "Arrival Date : " +
          moment(data.arrivalDate).format("DD-MM-YYYY") +
          "\n";
      }
      if (data.flightDetails) {
        bodyValues += "Flight Details : " + data.flightDetails + "\n";
      }
      if (data.numOfPassengers) {
        bodyValues += "Number Of Passengers : " + data.numOfPassengers + "\n";
      }
      if (data.comment) {
        bodyValues += "Comment : " + data.comment + "\n";
      }
    }
    if (data)
      params += `${subject ? "&" : ""}body=${encodeURIComponent(bodyValues)}`;

    window.open(`mailto:${params}`);
  };
  return {
    data,
    back,
    inputs,
    Mailto,
    replied,
    documents,
    updateEnquiry,
    updateReply,
    appendInput,
    removeInput,
    onFileChange,
    replyEnquiry,
    replyComments,
    replyDocuments,
    disableDownload,
    setReplyComments,
    downloadDocument,
    downloadAllDocuments,
    downloadReplyDocument,
  };
};

export default useEnquiry;
