import axios from "axios";
import { getAuthHeader } from "../../store/DataStore";
import { APP_URL } from "../../util/AppConstants";

// export const getAllCenters = async (offset, perPage) => {
//     const headers = {
//       Authorization: getAuthHeader(),
//     };
//     var params = `pageNo=${offset}&pageSize=${perPage}`;
//     const {data} = await axios.get(
//       `${APP_URL}/internal/mykare/hospitals?${params}`,
//       {
//         headers,
//       }
//     );
//     return data;
//   };

export const getAllCenters = async () => {
  // const role = getUserRole();
  // const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const postData = {
    pageNo: 0,
    pageSize: 40,
    // hospitalName,
    // location,
    // specialityIds,
    // treatmentIds
  };
  const response = await axios.post(
    `${APP_URL}/internal/mykare/hospitals`,
    postData,
    {
      headers,
    }
  );
  return response;
};
export const getAllBuddyCenters = async () => {
  // const role = getUserRole();
  // const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/internal/mykare/centers`, {
    headers,
  });
  return response;
};

export const getCenterById = async (centerId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(
    `${APP_URL}/internal/mykare/hospital/${centerId}`,
    {
      headers,
    }
  );
  return data;
};

export const deleteCenterById = async (centerId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const data = await axios.delete(
    `${APP_URL}/internal/mykare/deleteHospital/${centerId}`,
    {
      headers,
    }
  );
  return data;
};

export const addCenter = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.post(
    `${APP_URL}/internal/mykare/center/save`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getAllSpecialisations = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(
    `${APP_URL}/internal/mykare/specialisations`,
    {
      headers,
    }
  );
  return data;
};

export const getAllAgreementModels = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(
    `${APP_URL}/internal/mykare/agreementModels`,
    {
      headers,
    }
  );
  return data;
};

export const getAllQualifications = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(
    `${APP_URL}/internal/mykare/qualifications`,
    {
      headers,
    }
  );
  return data;
};

export const getAllLocations = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(`${APP_URL}/internal/mykare/locations`, {
    headers,
  });
  return data;
};

export const uploadCenterDocs = async (postData) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/internal/mykare/uploadCenterDoc`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const updateCenterDocs = async (postData) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: getAuthHeader(),
  };
  const response = await axios.put(
    `${APP_URL}/internal/mykare/updateCenterDoc`,
    postData,
    {
      headers,
    }
  );
  return response;
};
