import React from "react";
import useIpDetail from "./useIpDetail.logic";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import { CustomMenu } from "../../../../components/AddLead/AddLead";

function IpDetail(props) {
  const {
    userTrxnId,
    detailId,
    centerData,
    doctorData,
    callBackFn,
    treatment,
    handleHide,
    patientDetail,
    patientAadhar,
    locationData,
  } = props;
  const state = useIpDetail({
    userTrxnId,
    detailId,
    centerData,
    doctorData,
    callBackFn,
    treatment,
    handleHide,
    patientDetail,
    patientAadhar,
    locationData,
  });



  return (
    <div>
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <div className="col-lg-2">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 8 }}>
            Treatment
          </p>
          {state.editableMode ? (
            <input
              placeholder="Treatment"
              value={state.ipDetails.treatment}
              style={{
                width: 200,
                height: 40,
                borderRadius: 5,
                paddingLeft: 10,
                border: `1px solid gray`,
              }}
              onChange={(e) =>
                state.setIpDetails((s) => ({ ...s, treatment: e.target.value }))
              }
            />
          ) : (
            <span>{state.ipDetails.treatment}</span>
          )}
        </div>

        <div className="col-lg-2 col-12">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Amount
          </p>
          {state.editableMode ? (
            <input
              placeholder="Amount"
              value={state.ipDetails.amount}
              style={{
                width: 200,
                height: 40,
                borderRadius: 5,
                paddingLeft: 10,
                border: `1px solid gray`,
              }}
              onChange={(e) =>
                state.setIpDetails((s) => ({ ...s, amount: e.target.value }))
              }
            />
          ) : (
            <span>{state.ipDetails.amount}</span>
          )}
        </div>
        <div className="col-lg-2 col-12">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Dr.Name
          </p>
          {state.editableMode ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 260,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.selectedDoctor?.name}
              </Dropdown.Toggle>
              <Dropdown.Menu
                as={CustomMenu}
                style={{
                  height: 200,
                  width: 260,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {state.doctorData &&
                  state.doctorData.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200 }}
                        onClick={() => state.handleDoctor(item)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <span>{state.selectedDoctor?.name}</span>
          )}
        </div>

        <div className="col-lg-2 col-12">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Hospital Location
          </p>
          {state.editableMode ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 260,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.selectedLocation?.name || "Select Location"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                as={CustomMenu}
                style={{
                  height: 200,
                  width: 260,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {state.locationData &&
                  state.locationData.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 300 }}
                        onClick={() => state.handleLocation(item)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <span>{state.selectedLocation?.name || "Not Selected"}</span>
          )}
        </div>
        <div className="col-lg-2 col-12">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Hospital Name
          </p>
          {state.editableMode ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 260,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.selectedCenter?.name}
              </Dropdown.Toggle>
              <Dropdown.Menu
                as={CustomMenu}
                style={{
                  height: 200,
                  width: 260,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {state.centerData &&
                  state.centerData.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 300 }}
                        onClick={() => state.handleCenter(item)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <span>{state.selectedCenter?.name}</span>
          )}
        </div>

        <div className="col-2">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Status
          </p>
          {state.editableMode ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.ipDetails.ipStatus ? state.ipDetails.ipStatus : "Status"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {state.ipStatus.map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{ width: 200 }}
                      onClick={(e) =>
                        state.setIpDetails((s) => ({
                          ...s,
                          ipStatus: value,
                        }))
                      }
                    >
                      {value}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <span>{state.ipDetails.ipStatus}</span>
          )}
        </div>

        {/* <div className="col-2">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            PaymentType
          </p>
          {state.editableMode ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.ipDetails.paymentType
                  ? state.ipDetails.paymentType
                  : state.ipPaymentModes[0]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {state.ipPaymentModes.map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{ width: 200 }}
                      onClick={() =>
                        state.setIpDetails((s) => ({
                          ...s,
                          paymentType: value,
                        }))
                      }
                    >
                      {value}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <span>{state.ipDetails.paymentType}</span>
          )}
        </div> */}
      </div>
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <div className="col-2">

        <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0}}>
            {state.ipDetails.ipStatus ? `${state.ipDetails.ipStatus} DATE ` : "Date Time"}
          </p>
         
          {state.editableMode ? (
            <DatePicker
              className="myDatePicker"
              selected={
                state.ipDetails.arrivalDateTime
                  ? state.ipDetails.arrivalDateTime
                  : new Date()
              }
              onChange={(date) => {
                state.setIpDetails((s) => ({ ...s, arrivalDateTime: date }));
              }}
              minDate={new Date()}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          ) : (
            <span>
              {state.ipDetails.arrivalDateTime
                ? state.ipDetails.arrivalDateTime.toLocaleString()
                : null}
            </span>
          )}
        </div>
        <div class="col-2">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Remarks
          </p>
          {state.editableMode ? (
            <textarea
              className="comments-section"
              rows={5}
              placeholder="Remarks"
              value={state.ipDetails?.remarks}
              onChange={(e) =>
                state.setIpDetails((s) => ({ ...s, remarks: e.target.value }))
              }
            />
          ) : (
            <span>{state.ipDetails.remarks}</span>
          )}
        </div>
        {/* <div className="col-lg-2"></div> */}
        <div className="col-2"></div>
        <div className="col-2">
          {state.editableMode ? (
            <button
              onClick={() => state.saveIpAppointmentDetails()}
              style={{ width: 70, padding: 8 }}
            >
              Save
            </button>
          ) : (
            <button
              style={{ width: 70, padding: 8 }}
              onClick={() => state.setEditableMode(true)}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default IpDetail;
