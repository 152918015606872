import React, { useState } from "react";
import "../../Enquiry/Enquiry.css";
import "../../../Styles/Common.css";
import "../../../Styles/sideBar.css";
import { NavLink } from "react-router-dom";
import { checkPermission } from "../../../util/permissionUtils";

function TopBar() {
  const flexStyle = {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <div style={{ display: "flex", gap: 20 }} className="my-header-label">
      {" "}
      <NavLink to="/kareTripEnquiry" style={flexStyle}>
        <h5
          style={{
            color: "white",
            cursor: "pointer",
            marginBottom: 0,
          }}
        >
          Leads
        </h5>
      </NavLink>
      {checkPermission("KARETRIP_REPORTS") && (
        <NavLink to="/kareTripExicutiveReports" style={flexStyle}>
          <h5
            style={{
              color: "white",
              cursor: "pointer",
              marginBottom: 0,
            }}
          >
            Reports
          </h5>
        </NavLink>
      )}
      {checkPermission("KARECHAT_TAB") && (
        <a
          target="_blank"
          href={`${process.env.REACT_APP_CHAT_MODULE_URL}karechat/homechat`}
          style={flexStyle}
        >
          <h5
            style={{
              color: "white",
              cursor: "pointer",
              marginBottom: 0,
            }}
          >
            KareChat
          </h5>
        </a>
      )}
      {/* <NavLink
        to={`${process.env.REACT_APP_CHAT_MODULE_URL}karechat/homechat`}
        style={flexStyle}
      >
        <h5
          style={{
            color: "white",
            cursor: "pointer",
            marginBottom: 0,
          }}
        >
          KareChat
        </h5>
      </NavLink> */}
    </div>
  );
}

export default TopBar;
