import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import ReactDatePicker from "react-datepicker";

export default function OPiPpopup({ statusId,date,data,setFollowUpDate,label}) {

    const handleChangeDate = (e) => {
        let temp={...data}
        if(statusId == 19 || statusId == 18) {
            temp.ipDetails.arrivalDateTime = e            
        }else if(statusId == 21 || statusId == 20) {
            temp.opDetails.dateTime = e
        }
        setFollowUpDate(temp)
    }
  return (
    <Box style={{ minHeight: "300px" }}>
        <Typography variant="subtitle2">{label}</Typography>
      <ReactDatePicker
        className="myDatePicker w-50"
        selected={ date && new Date(date)}
        onChange={(e)=>handleChangeDate(e)}
        minDate={new Date()}
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mm aa"
      />
    </Box>
  );
}
