
import moment from "moment";
import fileDownload from "js-file-download";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import {
  getAllCities,
  getAllCenters,
  getAllAgents,
  saveEnquiryDetails,
  saveEnquiryDocuments,
  getAllSpecialities,
  deleteDocumentFile,
  downloadDocumentFile,
  loadDocumentFiles,
} from "../../../../../services/associate/patientService";

export default function useVil(patientDetail) {
  const history = useHistory();
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const attendarfileInputRef = useRef(null);
  const otherDocumentsfileRef = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const [comments, setComments] = useState("");
  const [fileList, setFileList] = useState([]);
  const [doctorName, setDoctorName] = useState("");
  const [citiesList, setCitiesList] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [centersList, setCentersList] = useState([]);
  const [documentFile, setDocumentFile] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const data = history?.location?.state?.enquiryDetails;
  const [selectedAgent, setSelectedAgent] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState("");
  const [documentFileId, setDocumentFileId] = useState({});
  const [selectedAgentId, setSelectedAgentId] = useState({});
  const [attendarfileList, setAttendarFileList] = useState([]);
  const [specialitiesList, setSpecialitiesList] = useState([]);
  const [selectedCenterId, setSelectedCenterId] = useState({});
  const [otherDocumentsList, setOtherDocumentsList] = useState([]);
  const [selectedArrivalDate, setSelectedArrivalDate] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedSpecialityId, setSelectedSpecialityId] = useState({});
  const [selectedPreferredDate, setSelectedPreferredDate] = useState("");
  const [selectedSpecialityName, setSelectedSpecialityName] = useState("");

  const formattedDate = moment(selectedPreferredDate).format();
  const handleDateChange = (e) => {
    setSelectedArrivalDate(e.target.value);
  };

  const handleFileUploadObj = (event, document) => {
    const files = event.target.files;
    if (document === "patient_documents") {
      const files = event.target.files;
      const newFiles = Array.from(files).map((file) => ({
        image: file,
        name: file.name,
      }));
      setFileList(newFiles);
    } else if (document === "attendar_documents") {
      const newFiles = Array.from(files).map((file) => ({
        image: file,
        name: file.name,
      }));
      setAttendarFileList(newFiles);
    } else if (document === "other_documents") {
      const newFiles = Array.from(files).map((file) => ({
        image: file,
        name: file.name,
      }));
      setOtherDocumentsList([...otherDocumentsList, ...newFiles]);
    }
  };

  const handleFileUpload = (document) => {
    setIsEdit(true);
    if (document === "patient_documents") {
      fileInputRef?.current?.click();
    } else if (document === "attendar_documents") {
      attendarfileInputRef?.current?.click();
    } else if (document === "other_documents") {
      otherDocumentsfileRef?.current?.click();
    }
  };

  const handleDeleteFile = (indexToDelete, document) => {
    if (document === "patient_documents") {
      setFileList((prevFileList) => {
        const updatedFileList = prevFileList.filter(
          (_, index) => index !== indexToDelete
        );
        return updatedFileList;
      });
    } else if (document === "attendar_documents") {
      setAttendarFileList((prevFileList) => {
        const updatedFileList = prevFileList.filter(
          (_, index) => index !== indexToDelete
        );
        return updatedFileList;
      });
    } else if (document === "other_documents") {
      setOtherDocumentsList((prevFileList) => {
        const updatedFileList = prevFileList.filter(
          (_, index) => index !== indexToDelete
        );
        return updatedFileList;
      });
    }
  };

  const submitDocumentsFile = async (getEnquiryId) => {
    try {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("enquiryDoc", file?.image, file?.name);
      });
      formData.append("enquiryId", getEnquiryId);
      formData.append("type", "patient_passport");

      const attendarformData = new FormData();
      attendarfileList.forEach((file) => {
        attendarformData.append("enquiryDoc", file?.image, file?.name);
      });
      attendarformData.append("enquiryId", getEnquiryId);
      attendarformData.append("type", "attender_passport");

      const otherDocumentformData = new FormData();
      otherDocumentsList.forEach((file) => {
        otherDocumentformData.append("enquiryDoc", file?.image, file?.name);
      });
      otherDocumentformData.append("enquiryId", getEnquiryId);
      otherDocumentformData.append("type", "document");

      const promises = [];

      if (formData.has("enquiryDoc")) {
        promises.push(saveEnquiryDocuments(formData));
      }
      if (attendarformData.has("enquiryDoc")) {
        promises.push(saveEnquiryDocuments(attendarformData));
      }
      if (otherDocumentformData.has("enquiryDoc")) {
        promises.push(saveEnquiryDocuments(otherDocumentformData));
      }
      if (promises.length > 0) {
        await Promise.race(promises);
      } else {
        console.log("No data to submit.");
      }
    } catch (error) {
      console.error("Error submitting documents:", error);
    }
  };

  const loadDocuments = () => {
    loadDocumentFiles(patientDetail?.id).then((res) => {
      setDocumentFile(res?.data);
    });
  };

  const downloadDocument = (fileName) => {
    downloadDocumentFile(fileName).then((res) => {
      fileDownload(res.data, fileName);
    });
  };

  const handleClickOpen = (documentId) => {
    setDocumentFileId(documentId);
    setOpen(true);
  };

  const handleDelete = () => {
    deleteDocumentFile(documentFileId).then((res) => {
      if (res?.status === 200) {
        setIsDataLoaded(true);
        setOpen(false);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const patientPassportFiles = documentFile
    .filter((item) => item.type === "patient_passport")
    .map((item) => item);

  const attendarPassportFiles = documentFile
    .filter((item) => item.type === "attender_passport")
    .map((item) => item);

  const otherDocumentFiles = documentFile
    .filter((item) => item.type === "document")
    .map((item) => item);

  const handleAgentDetails = (val) => {
    setSelectedAgentId(val?.id);
    setSelectedAgent(val?.label);
  };

  const handleCenterDetails = (val) => {
    setSelectedCenterId(val?.id);
    setSelectedCenter(val?.label);
  };

  const hadleSelectedSpeciality = (val) => {
    setSelectedSpecialityId(val?.id);
    setSelectedSpecialityName(val?.label);
  };

  const loadAllCities = () => {
    getAllCities().then((data) => {
      setCitiesList(
        data?.map((list) => {
          return {
            label: list?.name,
            id: list?.id,
          };
        })
      );
    });
  };

  const loadAllSpecialities = () => {
    getAllSpecialities().then((data) => {
      setSpecialitiesList(
        data?.map((items) => {
          return {
            label: items?.name,
            id: items?.id,
            centerId: items?.centerId,
          };
        })
      );
    });
  };

  const loadAllCenters = () => {
    getAllCenters().then((data) => {
      setCentersList(
        data?.map((list) => {
          return {
            label: list?.name,
            id: list?.id,
          };
        })
      );
    });
  };

  const handleImageClick = (index) => {
    if (selectedImageIndex === index) {
      setSelectedImageIndex(null);
    } else {
      setSelectedImageIndex(index);
    }
  };

  const submitEnquiryDetails = () => {
    if (
      formattedDate &&
      selectedCenter &&
      comments &&
      selectedAgent &&
      selectedCity &&
      doctorName &&
      selectedSpecialityName
    ) {
      const postData = {
        arrivalDate: formattedDate,
        centerId: selectedCenterId,
        centerName: selectedCenter,
        comment: comments,
        enquiryByAgentId: selectedAgentId,
        enquiryByAgentName: selectedAgent,
        preferredCity: selectedCity,
        dateTime: formattedDate,
        type: "vil",
        patientId: data?.id,
        patientName: data?.name,
        doctorName: doctorName,
        specialityName: selectedSpecialityName,
        specialityId: selectedSpecialityId,
        id: patientDetail?.id ? patientDetail?.id : null,
      };
      saveEnquiryDetails(postData).then((res) => {
        submitDocumentsFile(res?.data?.id);
        if (res.status === 200) {
          alert("successfully save");
          window.location.reload();
        }
      });
    } else {
      alert("Please fill in all required fields before saving.");
    }
  };

  const loadAllAgents = () => {
    getAllAgents().then((data) => {
      setAgentsList(
        data?.map((list) => {
          return {
            label: list?.name,
            id: list?.id,
          };
        })
      );
    });
  };

  useEffect(() => {
    loadDocuments();
    loadAllCities();
    loadAllAgents();
    loadAllCenters();
    loadAllSpecialities();
    if (patientDetail?.enquiryByAgentId) {
      setComments(patientDetail?.comment);
      setDoctorName(patientDetail?.doctorName);
      setSelectedCenter(patientDetail?.centerName);
      setSelectedCenterId(patientDetail?.centerId);
      setSelectedCity(patientDetail?.preferredCity);
      setSelectedAgent(patientDetail?.enquiryByAgentName);
      setSelectedAgentId(patientDetail?.enquiryByAgentId);
      setSelectedSpecialityId(patientDetail?.enquiryByAgentId);
      setSelectedSpecialityName(patientDetail?.specialityName);
      setSelectedPreferredDate(new Date(patientDetail?.arrivalDate));
    }
  }, []);

  useEffect(()=>{loadDocuments()},[isDataLoaded])

  return {
    open,
    isEdit,
    comments,
    fileList,
    agentsList,
    citiesList,
    doctorName,
    centersList,
    setComments,
    handleClose,
    fileInputRef,
    handleDelete,
    selectedCity,
    documentFile,
    isDataLoaded,
    selectedAgent,
    setDoctorName,
    selectedCenter,
    setSelectedCity,
    handleClickOpen,
    handleDeleteFile,
    handleDateChange,
    downloadDocument,
    handleImageClick,
    attendarfileList,
    handleFileUpload,
    specialitiesList,
    otherDocumentFiles,
    handleAgentDetails,
    selectedImageIndex,
    otherDocumentsList,
    selectedArrivalDate,
    handleFileUploadObj,
    handleCenterDetails,
    attendarfileInputRef,
    patientPassportFiles,
    submitEnquiryDetails,
    otherDocumentsfileRef,
    attendarPassportFiles,
    selectedPreferredDate,
    selectedSpecialityName,
    hadleSelectedSpeciality,
    setSelectedPreferredDate,
  };
}
