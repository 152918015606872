import { useState, useEffect } from "react";
import {
  getDownloadBudgetAchieved,
  getDownloadIpOpReportData,
  getDownloadMissedFollowUpReportData,
  getDownloadReportData,
  getDownloadSurgerySuggestedReportData,
} from "../../services/mykare/enquiryService";
import moment from "moment";
import { getAgentData } from "../../services/commonService/commonService";
import { getAllPreLeadsTeam } from "../../services/mykare/PreLeadsService";

function useDownloadPanel() {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedType, setType] = useState({ name: "Select", id: 0 });
  const [assignedUser, setAssignedUser] = useState({
    list: [{ id: 0, name: "All", code: "All" }],
    selected: "All",
    selectedCode: "ALL",
  });

  const [selectMonth, setSelectMonth] = useState([
    "This Month",
    "Previous Month",
  ]);

  const [selectedSelectMonth, setSelectedSelectMonth] = useState("This Month");
  const reportTypes = [
    { name: "Leads Report", id: 1 },
    { name: "Missed Followup", id: 2 },
    { name: "Budget vs Achieved", id: 3 },
    { name: "Pre Leads Report", id: 4 },
  ];

  const downloadReport = () => {
    const startDate = moment(fromDate).format().split("T")[0];
    const endDate = moment(toDate).format().split("T")[0];
    if (selectedType.id === 1) {
      const type = "lead";
      getDownloadReportData(
        startDate,
        endDate,
        assignedUser.selectedCode,
        type
      );
    } else if (selectedType.id === 2) {
      const postData = {
        fromDateTime: moment(fromDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        toDateTime: moment(toDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
      getDownloadMissedFollowUpReportData(postData).then((res) => {});
    } else if (selectedType.id === 4) {
      const type = "prelead";
      getDownloadReportData(
        startDate,
        endDate,
        assignedUser.selectedCode,
        type
      );
    } else if (selectedType.id === 3) {
      getDowloaddoc();
    }
  };

  const getDowloaddoc = () => {
    const selectMonthRemoveSpace = selectedSelectMonth.replace(/\s/g, "");
    const fromDate = moment(new Date())
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      .split("T")[0];
    const toDate = moment(new Date())
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      .split("T")[0];

    getDownloadBudgetAchieved(fromDate, toDate, selectMonthRemoveSpace).then(
      (res) => {}
    );
  };

  const downloadIpOpReport = () => {
    const startDate = moment(fromDate).format().split("T")[0];
    const endDate = moment(toDate).format().split("T")[0];
    getDownloadIpOpReportData(startDate, endDate).then((res) => {});
  };

  const downloadSurgerySuggestedReport = () => {
    const startDate = moment(fromDate).format().split("T")[0];
    const endDate = moment(toDate).format().split("T")[0];
    getDownloadSurgerySuggestedReportData(startDate, endDate).then((res) => {});
  };

  const handleAssignedToChange = (assignedCode, assignedName) => {
    setAssignedUser((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
  };

  const loadAssignedUsers = async (reportType) => {
    try {
      const users = [{ id: 0, name: "All", code: "ALL" }];
      let response;
      if (reportType?.name === "Leads Report") {
        response = await getAgentData(true);
      } else if (reportType?.name === "Pre Leads Report") {
        response = await getAllPreLeadsTeam(false);
      }
      if (response) {
        users.push(...response?.data?.users);
        users.push({ id: -1, name: "Not Assigned", code: "NOT_ASSIGNED" });
        setAssignedUser((s) => ({ ...s, list: users }));
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleLeadType = (leadType) => {
    setType(leadType);
    loadAssignedUsers(leadType);
  };

  return {
    fromDate,
    setFromDate,
    toDate,
    assignedUser,
    handleAssignedToChange,
    setToDate,
    downloadReport,
    downloadIpOpReport,
    downloadSurgerySuggestedReport,
    selectedType,
    setType,
    reportTypes,
    selectMonth,
    selectedSelectMonth,
    setSelectedSelectMonth,
    getDownloadBudgetAchieved,
    getDowloaddoc,
    handleLeadType,
  };
}

export default useDownloadPanel;
