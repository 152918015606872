
import { useEffect, useState } from "react"
import { getScanDetailsById } from "../../services/mykare/enquiryService"
import { useParams } from 'react-router-dom';
import { getEnquiryDetailsById } from "../../services/associate/enquiryService";
import { useLocation, useHistory } from "react-router-dom";
import { getAllDocuments } from "../../services/mykare/documentService";
import { PRESCRIPTION_DOCUMENT } from "../../util/documentUtil";
import { updateEnquiry } from "../../services/mykare/enquiryService";
const useScanDetails=()=> {
    let history = useHistory();
    const { categoryContextId, userTxnCode } = useParams();
  
   
    const [phoneNumber, setPhoneNumber] = useState("");
    const [preferredDate, setPreferredDate] = useState("");
    const [price, setPrice]= useState("");
    const [scanCenterId, setScanCenterId]=useState("");
    const [scanCenterName, setScanCenterName]= useState("");
    const [scanSubCategory, setScanSubCategory]= useState("");
    const [scanType, setScanType]= useState("");
    const [sublocation, setSubLocation]= useState("");

    const [patientName, setPatientName]= useState("");
    const [enquiryId, setEnquiryId]=useState("");
    const [assignTo, setAssignTo]=useState("");
    const [location,setLocation]= useState("");
    const [status, setStatus]= useState("");
    const [followingDate, setFollowingDate]=useState("");
    const [origin, setOrigin]=useState("");
    const [amount, setAmount]=useState("")
    const [scanName, setScanName]= useState("")
    const [enquiryDate, setEnquiryDate]= useState("")
    const [source, setSource]= useState("")
    const [mrp,setMrp]= useState("")
    const [percentageDiscount,setPercentAgeDiscount]=useState("")

    const [paymentStatus,setPaymentStatus]=useState("")

    const [transcationId, setTransactionId]=useState("")
    const [selectedTab, setSelectedTab] = useState({ 
        isOpen: true,
        isPatientsDocs: false,
        isPatientsDetail: false,

    })

    const [comments, setComments] = useState();
    const [prescriptionFiles, setPrescriptionFiles] = useState([]);


    const handleHideFun = (select) => {
        if(select == 1){
            setSelectedTab((prev) => ({ isOpen: true }));
        }
        else if (select == 2){
            setSelectedTab((prev) => ({ isPatientsDocs: true }));
        }
        else if (select == 3){
            setSelectedTab((prev)=> ({isPatientsDetail:true}))
        }

    }
  
    function back() {
        history.push({
            pathname:"/scan"
        })
    }
    

    const type="KARE_SCAN"
    const getUserScanDetails=async()=> { 
       
        const response1=await getScanDetailsById(categoryContextId,type,userTxnCode)
        const response2= await getEnquiryDetailsById(userTxnCode,type)

        let scanresponse=response1.data.scanEnquiryResponse
       
        if(response2.data){
          setPatientName(response2.data.patientName)
          setPhoneNumber(response2.data.phoneNumber) 
          setEnquiryId(response2.data.enquiryId)
          setAssignTo(response2.data.assignToName)
          setStatus(response2.data.status)
          setFollowingDate(response2.data.followUpDate)
          setOrigin(response2.data.source)
          setLocation(response2.data.subLocation)
          setScanName(response2.data.scanName)
          setEnquiryDate(response2.data.enquiryDate)
 
        }
        if(scanresponse){
            setPreferredDate(scanresponse?.preferredDate)
            setScanCenterName(scanresponse?.scanCenterName)
            setScanSubCategory(scanresponse?.scanSubCategory) 
            setScanType(scanresponse?.scanType)
            setPrice(scanresponse?.price)
            setSource(scanresponse?.source)
            setMrp(scanresponse?.mrp)
            setPercentAgeDiscount(scanresponse?.percentageDiscount)
            setPaymentStatus(scanresponse?.paymentTransactions?.paymentStatus)
            setTransactionId(scanresponse?.paymentTransactions?.transactionId)
            
        }

    }

    const loadDocuments = (contextId, context, category, setFiles) => {
        // debugger;
        getAllDocuments(contextId, context, category)
          .then((res) => {
            console.log("ressdeff",res)
            setFiles(res.data);
            setPrescriptionFiles(res.data)
          })
          .catch((error) => {})
          .finally(() => {
            // setDataLoaded(true);
          });
      };
    
    useEffect(()=> {
        getUserScanDetails()
        if(categoryContextId){
            loadDocuments(
                categoryContextId,
                "KARE_SCAN_DOC",
                "scan_doc",
                setPrescriptionFiles
              );

        }
        
    },[])

    const saveEnquiryData = async (postData) => {
        try {
          const data = await updateEnquiry(postData);
          const response = { ...data };
          if (response.status === 200) {
            // getEnquiryDetails();
            alert("Successfully Updated");
          
            setComments("")
          }
          return response;
        } catch (error) {
          console.log("error", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            alert("Error: " + error.response.data.message);
          } else {
            alert("An error occurred. Please try again.");
          }
        } 
      };

    const postStatus=() =>{

        const posData= {
            userTxnId:userTxnCode ,
            notes:comments,
            statusId:1

        }
        saveEnquiryData(posData)


    } 

   
    return {
        enquiryDate,
        patientName,
        phoneNumber,
        enquiryId,
        assignTo,
        scanName,
        status,
        followingDate,
        origin,
        location,
        preferredDate,
        price,
        scanCenterName,
        scanSubCategory,
        scanType,
        source,
        back,
        mrp,
        percentageDiscount,
        paymentStatus,
        transcationId,
        selectedTab,
        handleHideFun,
        comments,
        setComments,
        prescriptionFiles,
        postStatus



    }
}

export default useScanDetails

