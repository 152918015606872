import _ from "lodash";

export const getAdditionalInfo = (data) => {
  if (data) {
    const replace = data
      .replaceAll("=", " : ")
      .replace("{", "")
      .replace("}", "")
      .replaceAll("_", " ");
    return replace;
  }
};

export const checkLocation = (statusId) => {
  if (
    statusId == 12 ||
    statusId == 13 ||
    statusId == 14 ||
    statusId == 18 ||
    statusId == 19 ||
    statusId == 21 ||
    statusId == 20 ||
    statusId == 22
  ) {
    return true
  }else{
    return false
  }
};
