import React, { useState } from "react";
import "./Partnership.css";
import "../../Styles/Common.css";
import "../../Styles/sideBar.css";
import Sidebar from ".././Sidebar";
import "../../Styles/Pagination.css";
import List from "@material-ui/core/List";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import usePartnerShip from "./usePartnerShip.logic";
import * as ReactBootstrap from "react-bootstrap";
import refreshIcon from "../../assets/refreshIcon.png";
import PopUp from "../../components/AddLead/AddLead";
import TopBar from "./TopBar";
import CustomLoader from "../../components/CustomLoader";

function Partnership() {
  const state = usePartnerShip();
  const [openPopUp, setOpenPopUp] = useState(false);
  const border = {
    width: "100%",
    height: "100vh",
    overflow: "scroll",
  };
  const flexStyle = {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  if (!state.dataLoaded) {
    return <CustomLoader />;
  }

  return (
    <>
      <div style={border}>
        <div style={{ display: "flex" }}>
          <Sidebar />
          <div style={{ width: "100%", padding: 10 }}>
            <div className="sticky">
              <div className="main-subContainer">
                <TopBar />
                <div className="row border1">
                  <div className="col-12 cor1">
                    <b>
                      <p className="size1">{state.partnershipCount}</p>
                    </b>
                    <b>
                      <p className="colour">Total Partnership</p>
                    </b>
                  </div>{" "}
                </div>
                <div className="row border1">
                  <div className="col-6 cor1">
                    <b>
                      <p className="size1">{state.enquiryCount}</p>
                    </b>
                    <b>
                      <p className="colour">Total Enquiries</p>
                    </b>
                  </div>
                  <div className="col-6 cor3">
                    <b>
                      <p className="size1">{state.partnershipCount}</p>
                    </b>

                    <b>
                      <p className="colour">Total Partnership</p>
                    </b>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <div
                className="row"
                style={{ width: "100%", height: 70, marginLeft: 20 }}
              >
                <button
                  style={{ width: 80, height: 50, marginLeft: 20 }}
                  onClick={() => state.handleRefresh()}
                >
                  Refresh
                </button>
              </div>

              {openPopUp ? (
                <PopUp onClicked={(openPopUp) => setOpenPopUp(openPopUp)} />
              ) : null}
              {/* <img 
            style={{
            position: "relative",
            left: "95%",
            top : "28%"
            }}
            onClick={()=>{
              state.handleRefresh();
              console.log("refreshing..................")}}  width="30" height="30" src={refreshIcon} /> */}
              <div className="row scroll">
                <div className="container col-2 m-0">
                  <b>Enquiry Id</b>
                </div>
                <div className="container col-2 m-0">
                  <b>Name</b>
                  <br />
                  <input
                    name="search"
                    placeholder="Search"
                    value={state.searchItem}
                    onChange={state.handleSearchItemChange}
                  />
                </div>
                <div className="container col-2 m-0">
                  <b>Email</b>
                </div>
                <div className="container col-2 m-0">
                  <b>Phone</b>
                </div>
                <div className="container col-2 m-0">
                  <b>Type</b>
                  <br />
                  <select
                    value={state.selectType.selected}
                    onChange={state.handleSelectTypeChange}
                  >
                    {state.selectType.list.map((item, i) => (
                      <option key={i} value={item}>
                        {item?.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="container col-2 m-0">
                  <b>Status</b>
                  <br />
                  <select
                    value={state.selectType.selected}
                    onChange={state.handleSelectTypeChange}
                  >
                    {state.selectType.list.map((item, i) => (
                      <option key={i} value={item}>
                        {item?.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <hr />
              <List component="nav" aria-label="mailbox folders">
                {state.homeData}
              </List>
              <ReactPaginate
                previousLabel={"PREV"}
                nextLabel={"NEXT"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={state.pageCount}
                initialPage={state.offset}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={state.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Partnership;
