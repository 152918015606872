// import "./Lead.css";
import React from "react";
import useAddLead from "./useAddLead.logic";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { checkPermission } from "../../../util/permissionUtils";

function AddLead(props) {
  const state = useAddLead(props);
  return (
    <div>
      <hr />
      <div className="row popup-view">
        <div className="col-md-2">
          <input
            className=""
            onChange={(e) => state?.setName(e.target.value)}
            placeholder="Name"
            style={{ height: 38, borderRadius: 5, border: `1px solid black` }}
          />
        </div>
        <div className="col-md-2">
          <div
            style={{
              height: 40,
              borderRadius: 5,
              border: `1px solid black`,
              display: "flex",
              width: 220,
            }}
          >
            <Dropdown>
              <Dropdown.Toggle
                className="drop-down"
                variant="none"
                style={{
                  height: 25,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "none",
                }}
              >
                {state?.countryCode?.name || "+91"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  maxHeight: 40,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {state?.countryCodes.map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{ maxHeight: 60 }}
                      onClick={() => state?.setCountryCode(value)}
                    >
                      {value?.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <input
              onChange={(e) => state?.setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
              style={{
                height: "100%",
                width: "80%",
                borderRadius: 20,
                border: `1px solid white`,
                outline: "none",
              }}
              maxLength={10}
            />
          </div>
        </div>

        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {state?.selectedLocation
                ? state?.selectedLocation?.name
                : "Location"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={CustomMenu}
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 260,
              }}
            >
              {state?.locations.map((value, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{ width: 200, maxHeight: 200 }}
                    onClick={() => state?.setSelectedLocation(value)}
                  >
                    {value?.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {state?.selectedTreatment
                ? state?.selectedTreatment?.name
                : "Treatment"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={CustomMenu}
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 260,
              }}
            >
              {state?.treatments.map((value, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{ width: 200, maxHeight: 200 }}
                    onClick={() => state?.setselectedTreatment(value)}
                  >
                    {value?.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {state?.selectedSource ? state?.selectedSource?.name : "Origin"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={CustomMenu}
              style={{
                height: 200,
                width: 260,
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {state?.sources.map((value, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{ width: 200, maxHeight: 200 }}
                    onClick={() => state?.setSelectedSource(value)}
                  >
                    {value?.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {checkPermission("LEADS_ASSIGNED_FIELD") && (
          <div className="col-md-2">
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state?.preAssignedAgent || "Assign To"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {state?.userData.map((value, id) => {
                  return (
                    <Dropdown.Item
                      key={id}
                      href="#"
                      style={{ width: 200 }}
                      onClick={() =>
                        state?.handleToPreAssigned(value?.name, value?.code)
                      }
                    >
                      {value?.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width: "400px", marginTop: "10px" }}>
            <textarea
              style={{ width: "100%" }}
              placeholder="Add Comments"
              onChange={(e) => state?.setComment(e.target.value)}
            ></textarea>
          </div>

          <div className="col-md-1" style={{ marginLeft: "30px" }}>
            <button
              style={{ padding: 10, width: 80 }}
              onClick={() => props?.onClicked(false)}
            >
              Cancel
            </button>
          </div>

          <div className="col-md-1">
            <button
              onClick={() => state?.saveEnquiryDetails()}
              style={{ padding: 10, width: 80 }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <br></br>
      <hr style={{ marginTop: "40px" }} />
    </div>
  );
}

export default AddLead;

export const CustomMenu = React.forwardRef(
  (
    { children, style, className, "aria-labelledby": labeledBy, searchStyle },
    ref
  ) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          style={searchStyle}
          autoFocus
          className={!searchStyle && "mx-3 my-2 w-auto"}
          placeholder="Search"
          onChange={(e) => setValue(e?.target?.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children?.toArray(children)?.filter(
            (child) =>
              !value || child?.props?.children?.toLowerCase()?.startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);
