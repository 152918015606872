import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCGl16NDgnf3a9gVfj3MSF9fMkpT7u1gH4",
    authDomain: "kareflow-8fa02.firebaseapp.com",
    projectId: "kareflow-8fa02",
    storageBucket: "kareflow-8fa02.appspot.com",
    messagingSenderId: "794670885482",
    appId: "1:794670885482:web:83f0678b8eaec93232b813"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
// navigator.serviceWorker.register('/partners-web/firebase-messaging-sw.js')
//           .then(function (registration) {
//             // Registration was successful
//             console.log('firebase-message-sw :ServiceWorker registration successful with scope: ', registration.scope);
//             messaging.useServiceWorker(registration);
//           }, function (err) {
//             // registration failed :(
//             console.log('firebase-message-sw: ServiceWorker registration failed: ', err);
//           });


export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/partners-web/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register('/partners-web/firebase-messaging-sw.js', {
          scope: '/partners-web/firebase-push-notification-scope',
        });
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker()
    .then((serviceWorkerRegistration) =>
      getToken(messaging, { vapidKey: 'BH38yD5WfASJ2_4BrvD_eiGi6p9EzPtt8jP_6-g66-wP3s4c1j3ac6dd2FAupP91pWbSTv9X3fJl3MI_V2htHHY', serviceWorkerRegistration }));

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));


// export const getFirebaseToken = (setTokenFound) => {
//   return getToken(messaging, {vapidKey: 'BH38yD5WfASJ2_4BrvD_eiGi6p9EzPtt8jP_6-g66-wP3s4c1j3ac6dd2FAupP91pWbSTv9X3fJl3MI_V2htHHY'}).then((currentToken) => {
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);
//       setTokenFound(true);
//       // Track the token -> client mapping, by sending to backend server
//       // show on the UI that permission is secured
//     } else {
//       console.log('No registration token available. Request permission to generate one.');
//       setTokenFound(false);
//       // shows on the UI that permission is required 
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // catch error while creating client token
//   });
// }

// export const onForegroundMessage = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
// });