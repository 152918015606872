import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Select,
} from "@material-ui/core";
import { async } from "q";
import {
  createUser,
  getAllUsers,
} from "../../services/mykare/settingsServices";
import { getAllRole } from "../../services/mykare/dataService";
import Dropdown from "react-bootstrap/Dropdown";
import { CustomMenu } from "../../components/AddLead/AddLead";

function TexField(props) {
  return (
    <Grid item direction="column" container xs={2}>
      <b>{props.label}</b>
      <input
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.handleChange}
      />
    </Grid>
  );
}

const Form = () => {
  const [formData, setFormData] = useState({
    active: true,
    email: "",
    name: "",
    password: "",
    phoneNumber: "",
    roleId: 0,
    username: "",
  });
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [role, setRole] = useState([]);
  const userStatus = [
    { name: "All", value: null },
    { name: "Active", value: true },
    { name: "Non Active", value: false },
  ];
  const [selectedStatus, setSelectedStatus] = useState({});
  const [roleData, setRoleData] = useState({
    list: [{ id: 0, name: "select" }],
    selected: null,
    selectedCode: null,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveUser(formData);
  };

  const saveUser = async (postData) => {
    const response = await createUser(postData);
    if (response.status == 200) {
      alert("User Created sucessfully");
      loadAllUsers();
    }
  };

  const loadAllRole = async () => {
    try {
      getAllRole().then((res) => {
        setRoleData((s) => ({ ...s, list: res.data }));
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadAllUsers = async () => {
    const response = await getAllUsers();
    if (response.status == 200) {
      if (response.data) {
        setFilteredUsers(response.data.users);
        setAllUsers(response.data.users);
      }
    }
  };

  const searchByValue = (value) => {
    if (value === "" || value === null) {
      loadAllUsers();
    } else {
      const filteredData = filteredUsers.filter((filteredValue) => {
        let valueLowercase = filteredValue.name?.toUpperCase();
        let searchTermLowercase = value?.toUpperCase();
        return valueLowercase.indexOf(searchTermLowercase) > -1;
      });
      if (filteredData.length !== 0) {
        setAllUsers(filteredData);
      } else {
        alert("Sorry no data found");
        loadAllUsers();
      }
    }
  };
  const filterByStatus = (value) => {
    if (value === "" || value === null) {
      loadAllUsers();
    } else {
      const filteredData = filteredUsers.filter((filteredValue) => {
        return filteredValue.active == value;
      });
      if (filteredData.length !== 0) {
        setAllUsers(filteredData);
      }
    }
  };

  useEffect(() => {
    loadAllUsers();
    loadAllRole();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          style={{
            height: 100,
          }}
          justifyContent="center"
          xs={12}
        >
          <Grid
            container
            style={{
              height: 100,
            }}
            xs={11}
            justifyContent="space-around"
          >
            <Grid xs={1.8}>
              <TexField
                label="Name"
                name="name"
                value={formData.name}
                handleChange={handleChange}
              />
            </Grid>
            <Grid xs={1.8}>
              <TexField
                label="UserName"
                name="username"
                value={formData.username}
                handleChange={handleChange}
              />
            </Grid>
            <Grid xs={1.8}>
              <TexField
                label="Email"
                name="email"
                value={formData.email}
                handleChange={handleChange}
              />
            </Grid>
            <Grid xs={1.8}>
              <TexField
                label="PhoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                handleChange={handleChange}
              />
            </Grid>
            <Grid xs={1.8}>
              <TexField
                label="Password"
                name="password"
                value={formData.password}
                handleChange={handleChange}
              />
            </Grid>
            <Grid xs={1.8}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ marginTop: "15px" }}
                    checked={formData.active}
                    onChange={handleChange}
                    name="active"
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid xs={1.8}>
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 200,
                      height: 36,
                      marginTop: "15px",
                      border: `1px solid gray`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {role?.name ? role?.name : "Role"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      maxHeight: 200,
                      overflowY: "scroll",
                      overflowX: "hidden",
                      width: 260,
                    }}
                  >
                    {roleData.list.map((value, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          href="#"
                          style={{ width: 200, maxHeight: 200 }}
                          onClick={() => {
                            setRole(value);
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              roleId: value.id,
                            }));
                          }}
                        >
                          {value.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Grid>
            <Grid xs={1.8} style={{ marginTop: "12px" }}>
              <Button
                style={{
                  backgroundColor: "rgb(0, 200, 164)",
                  padding: "8px",
                }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <hr />

      <div
        style={{
          display: "flex",
          width: "100%",
          padding: 10,
        }}
      >
        <TexField
          label="Search Name"
          name="Search"
          handleChange={(e) => searchByValue(e.target.value)}
        />
        <div className="container col-1 m-0">
          <b>Status</b>
          <Dropdown style={{ marginTop: -6 }}>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {selectedStatus?.name ? selectedStatus?.name : "All"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 260,
              }}
            >
              {userStatus.map((value, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{ width: 200, maxHeight: 200 }}
                    onClick={() => {
                      setSelectedStatus(value);
                      filterByStatus(value.value);
                    }}
                  >
                    {value.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div
        className="row"
        style={{
          width: "100%",
          padding: 30,
        }}
      >
        <table>
          <thead>
            <tr>
              <th style={{ width: 70 }}>ID</th>
              <th style={{ width: "40%" }}>Name</th>
              <th style={{ width: "40%" }}>Code</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody style={{ height: "64vh" }}>
            {allUsers.length !== 0 &&
              allUsers?.map((item) => (
                <tr style={{ height: 70 }} key={item.id}>
                  <td style={{ width: 70 }}>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.code}</td>
                  <td>{item.active ? "Active" : "Not Active"}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Form;
