import { useEffect, useState } from "react";
import {
  getAllCountryList,
  getAllStateList,
  savePatientsDetails,
} from "../../../services/associate/patientService";
import { getAllAgents } from "../../../services/associate/settingsService";

function usePopUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [state, setState] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [country, setCountry] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedSatate, setSelectedSatate] = useState([]);
  const [userData, setUserData] = useState([]);
  const [nameErr, setnameErr] = useState(false);
  const [phoneNumberErr, setPhoneNumberErr] = useState(false);
  const [selectedGender, setSelectedGender] = useState("Male");
  const [allAgents, setAllAgents] = useState(null);
  const [assignedAgentId, setAssignedAgentId] = useState();
  const [assignedName, setAssignedName] = useState();
  const [countryList, setCountryList] = useState([]);
  const [selectCountry, setSelectCountry] = useState();
  const regexpPhoneNumber = "(?:\\+?(\\d{1,3}))?(\\d{10,12})";
  const regexpName = "^[a-zA-Z ]*$";

  const validateName = (name) => {
    const regex = new RegExp(regexpName);
    return regex.test(name);
  };
  const validatePhoneNumber = (phoneNumber) => {
    const regex = new RegExp(regexpPhoneNumber);
    return regex.test(phoneNumber);
  };

  const getAllAgent = async () => {
    const resp = await getAllAgents();
    if (resp.data) {
      setAllAgents(resp.data.reverse());
    }
  };

  const getAllCountries = () => {
    getAllCountryList()
      .then((data) => {
        setCountryList(
          data?.countries?.map((countries) => {
            return {
              label: countries?.name,
              id: countries?.id,
              countryCode: countries?.code,
            };
          })
        );
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };
  useEffect(() => {
    getAllCountries();
  }, []);

  const getSelectCountryDetails = (e, val) => {
    setSelectCountry(val)
    setCountryCode(val?.countryCode);
    getAllStateList(val?.id).then((data) => {
      if (data !== null) {
        setStateList(data);
      }
    });
  };

  const [gender, setGender] = useState([
    { id: 1, label: "Male" },
    { id: 2, label: "Female" },
    { id: 3, label: "Others" },
  ]);

  const handleNameChange = (e) => {
    const name = e.target.value;
    if (validateName(name)) {
      setName(name);
      setnameErr(false);
    } else {
      console.log("===🔥🔥🔥🔥nameError🔥🔥🔥🔥=======>");
      setnameErr(true);
    }
  };

  useEffect(() => {
    loadCountries();
    getAllAgent();
  }, []);

  const loadCountries = async () => {
    try {
      getAllCountries().then((res) => {
        setCountry(res.data);
      });
    } catch (error) {}
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value;
    if (validatePhoneNumber(phoneNumber)) {
      setPhoneNumber(phoneNumber);
      setPhoneNumberErr(false);
    } else {
      setPhoneNumberErr(true);
      console.log("===🔥🔥🔥🔥phoneNumber🔥🔥🔥🔥====errr===>");
    }
  };

  const handleToAssigned = (agentName, agentId) => {
    setAssignedName(agentName);
    setAssignedAgentId(agentId);
  };

  const savePatientDetail = async () => {
    const postData = {
      name: name,
      gender: selectedGender.toUpperCase(),
      age: age,
      email: email,
      mobileCode: countryCode,
      mobile: phoneNumber,
      state: selectedSatate,
      country: selectedCountry,
      agentId: assignedAgentId,
    };
    const response = await savePatientsDetails(postData);
    if(response?.status === 200){
      window.location.reload();
    }
    alert("Successfully Added New Patient");
  };
  return {
    setName,
    setAge,
    setPhoneNumber,
    setSelectedGender,
    setState,
    name,
    email,
    setEmail,
    phoneNumber,
    userData,
    countryCode,
    setCountryCode,
    gender,
    selectedGender,
    setCountry,
    country,
    stateList,
    countryList,
    selectedCountry,
    selectCountry,
    setSelectedCountry,
    setSelectedSatate,
    handleNameChange,
    handlePhoneNumberChange,
    phoneNumberErr,
    nameErr,
    savePatientDetail,
    setAssignedAgentId,
    setAssignedName,
    assignedAgentId,
    getSelectCountryDetails,
    assignedName,
    allAgents,
    setAllAgents,
    handleToAssigned,
  };
}

export default usePopUp;
