export const getWorkingHours = (duration) => {
  // Create a new Date object and set it to the reference time (0 milliseconds)
  const referenceTime = new Date(0);

  // Extract the hours, minutes, and seconds from the duration
  const hoursMatch = duration?.match(/(\d+)H/);
  const minutesMatch = duration?.match(/(\d+)M/);
  const secondsMatch = duration?.match(/(\d+(\.\d+)?)S/);

  const hours = hoursMatch ? parseInt(hoursMatch[1]) : 0;
  const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0;
  const seconds = secondsMatch ? parseFloat(secondsMatch[1]) : 0;

  // Set the reference time to the duration values
  referenceTime.setHours(hours);
  referenceTime.setMinutes(minutes);
  referenceTime.setSeconds(seconds);

  // Get the working hours from the reference time
  const workingHours = referenceTime.getHours();

  console.log(workingHours); // Output: 50

  return workingHours;
};
