import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../styles.css";
import axios from "axios";
import * as appConstants from "../../util/AppConstants";
import { getFirebaseToken } from "../../firebase";
import { updateDeviceToken } from "../../services/mykare/userService";
import api from "../../axios-config";

const useLogin = () => {
  let history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, seterror] = useState(false);
  const [userRole, setUserRole] = useState("");

  const handleGetFirebaseToken = (code) => {
    getFirebaseToken()
      .then((firebaseToken) => {
        console.log("Firebase token: ", firebaseToken);
        if (firebaseToken) {
          const postData = {
            code,
            token: firebaseToken,
            platform: "KAREFLOW_WEB",
          };
          updateDeviceToken(postData).then((data) =>
            console.log("====updateDeviceToken==")
          );
        }
      })
      .catch((err) =>
        console.error("An error occured while retrieving firebase token. ", err)
      );
  };

  function loginUser() {
    api
      .post("/user/login", {
        email: username,
        password: password,
      })
      .then((resp) => {
        if (resp.status === 200) {
          handleGetFirebaseToken(resp.data.code);
          localStorage.setItem("token", resp.data.token);
          localStorage.setItem("user-id", resp.data.id);
          localStorage.setItem("user-role", resp.data.role);
          localStorage.setItem("user-name", resp.data.name);
          localStorage.setItem("user-code", resp.data.code);
          localStorage.setItem(
            "user-permission",
            JSON.stringify(resp.data?.permissions)
          );

          if (resp.data.kareflowUrl) {
              const url = resp.data.kareflowUrl.split("/");
              if (url.includes("kareflow-focus")) {
                window.location.href =
                  process.env.REACT_APP_REPORT_MODULE_URL + resp.data.kareflowUrl;
              } else {
                history.push({ pathname: resp.data.kareflowUrl });
              }
            } else {
            history.push({ pathname: "/home" });
          }
        }
      })
      .catch((err) => {
        seterror(true);
        alert("You have entered an invalid username or password");
        console.log("error logging", err);
      });
  }
  return {
    loginUser,
    setUsername,
    seterror,
    setPassword,
    userRole,
  };
};
export default useLogin;
