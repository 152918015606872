import React from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import moment from "moment";

function StepperComponent({ steps, activities }) {
  const completedIds = activities.map((activity) => activity.statusId);

  const stepper = steps.map((step) => {
    const completed = completedIds.includes(step.id);
    return {
      ...step,
      completed,
    };
  });

  return (
    <Stepper activeStep={stepper.length}>
      {steps.map((step) => {
        const completed = activities.some(
          (activity) => activity.statusId === step.id
        );
        const dateTime = activities.find(
          (activity) =>
            activity.type === step.type && activity.statusId === step.id
        )?.dateTime;

        return (
          <Step key={step.id} completed={completed}>
            <StepLabel>{step.label}</StepLabel>
            <div>
              {dateTime &&
                `${moment(dateTime).format("MMM DD")},${moment(dateTime).format(
                  "hh:mm a"
                )}`}
            </div>
          </Step>
        );
      })}
    </Stepper>
  );
}

export default StepperComponent;
