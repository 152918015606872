import React, { useEffect, useState } from "react";
// import "./Enquiry.css";
import "../../Styles/Common.css";
import "../../Styles/sideBar.css";
import Sidebar from "../Sidebar";
import "../../Styles/Pagination.css";
import ReactPaginate from "react-paginate";
// import useEnquiry from "./useEnquiry.logic";
import useKareplus from "./useKareplus.logic";
import AddLead, { CustomMenu } from "../../components/AddLead/AddLead";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import { FiBell, FiPhoneCall } from "react-icons/fi";
// import TopBar from "./TopBar";
import TopBar from "../Enquiry/TopBar";
import Badge from "react-bootstrap/Badge";
import DownloadPanel from "../../components/DownloadPanel/DownloadPanel";
import { withStyles } from "@material-ui/core/styles";
import { List, Tooltip } from "@material-ui/core";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes";
import { checkPermission } from "../../util/permissionUtils";
import CustomLoader from "../../components/CustomLoader";
import { Button, Drawer, Table } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
// import BreakPopUp from "./BreakPopup";
// import ClockOutPopUp from "./ClocoutPopup";
import AutoCallPanel from "../../components/AutoCall/AutoCallPanel";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const openInNewTab = (enquiryId) => {
  window.open(`mykareEnquiryDetail/${enquiryId}`, "_blank");
};

const tooltipStyles = {
  tooltip: {
    fontSize: "20px",
    borderRadius: "18px",
    boxShadow: "0 20px 80px 0",
    backgroundColor: "#FFFFE0",
    color: "black",
  },
};

const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

function KarePlus() {
  const state = useKareplus();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("value");
  const label = queryParams.get("label");
  const [showAddLead, setShowAddLead] = useState(false);
  const [showDownloadPanel, setShowDownloadPanel] = useState(false);
  const [showAutoCallPanel, setShowAutoCallPanel] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // useEffect(() => {
  //   if (status !== null) {
  //     const value = [
  //       {
  //         id: 1,
  //         value: Number(status),
  //         label: label?.replace("_", " "),
  //       },
  //     ];
  //     const event = {
  //       action: "select-option",
  //       option: {
  //         id: 1,
  //         value: Number(status),
  //         label: label?.replace("_", " "),
  //       },
  //     };
  //     state.handleSelectStatuses(value, event);
  //   }
  // }, []);

  const border = {
    width: "100%",
    height: "100vh",
    overflow: "scroll",
  };

  const flexStyle = {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    color: "white",
  };
  if (!state.dataLoaded) {
    return <CustomLoader />;
  }
  return (
    <>
      {/* <BreakPopUp open={state.open} handleClose={state.handleClose} />
      <ClockOutPopUp
        open={state.popUpon}
        hour={state?.totalTime ? state.totalTime : 0}
        handleClose={state.handleCloseClockIn}
        handleClockOut={() =>
          state.saveActivity().then((res) => {
            state.handleCloseClockIn();
          })
        }
      /> */}
      <div style={border}>
        <div style={{ display: "flex" }}>
          <Sidebar />
          <div style={{ width: "150%", padding: 10 }}>
            <div className="sticky">
              <div className="main-subContainer">
                <TopBar
                  onClock={() => {
                    if (state.isClockIn) {
                      state.setPopupOn(true);
                      state.getWorkingTime();
                    } else {
                      state.saveActivity();
                    }
                  }}
                  onBreakClick={state.handleClickOpen}
                  isClockIn={state.isClockIn}
                  isOnBreak={state.isOnBreak}
                  lastCheckedOut={state.checkOutTime}
                  lastCheckedIn={state.checkInTime}
                />
                {checkPermission("REVENUE_STATISTICS") && (
                  <div>
                    <div className="row border1">
                      <div className="col cor1">
                        <b>
                          <p className="colour">Current Month Enquiries</p>
                        </b>
                        <b>
                          <p className="size1">
                            {state.enquiryStats?.currentMonthEnquiries}
                          </p>
                        </b>
                      </div>

                      <div className="col cor2">
                        <b>
                          <p className="colour">Current Month Opd's</p>
                        </b>
                        <div
                          className="row"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-6 cor9"
                            style={{
                              backgroundColor: "#cce2e2",
                              textAlign: "center",
                            }}
                          >
                            <b>Achieved</b>
                            <b>
                              <p style={{ color: "red", fontSize: "20px" }}>
                                {state.enquiryStats?.currentMonthOpdDone}
                              </p>
                            </b>
                          </div>
                          <div
                            className="col-6"
                            style={{ textAlign: "center" }}
                          >
                            <b>Target</b>
                            <b>
                              <p style={{ color: "green", fontSize: "20px" }}>
                                {state.enquiryStats?.opTargetCount}
                              </p>
                            </b>
                          </div>
                        </div>
                        {/* <b>
                        <p className="size1">
                          {state.enquiryStats?.currentMonthOpdDone}
                        </p>
                      </b> */}
                      </div>

                      <div className="col cor3">
                        <b>
                          <p className="colour">Current Month Ipd's</p>
                        </b>
                        <div
                          className="row"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-6 cor9"
                            style={{
                              backgroundColor: "#e2e1e8",
                              textAlign: "center",
                            }}
                          >
                            <b>Achieved</b>
                            <b>
                              <p style={{ color: "red", fontSize: "20px" }}>
                                {state.enquiryStats?.currentMonthIpdDoneCount}
                              </p>
                            </b>
                          </div>
                          <div
                            className="col-6"
                            style={{ textAlign: "center" }}
                          >
                            <b>Target</b>
                            <b>
                              <p style={{ color: "green", fontSize: "20px" }}>
                                {state.enquiryStats?.ipTargetCount}
                              </p>
                            </b>
                          </div>
                        </div>
                        {/* <b>
                    <p className="size1">
                      {state.enquiryStats?.currentMonthIpdDoneCount}
                    </p>
                    <p className="size1">
                      {state.enquiryStats?.currentMonthIpdDoneCount}
                    </p>
                  </b> */}
                      </div>

                      <div className="col cor4">
                        <b>
                          <p className="colour">Ip Conversion</p>
                        </b>
                        <b>
                          <p className="size1">
                            {state.enquiryStats?.ipConversion}
                          </p>
                        </b>
                      </div>

                      <div className="col cor5">
                        <b>
                          <p className="colour">Op Conversion</p>
                        </b>
                        <b>
                          <p className="size1">
                            {state.enquiryStats?.opConversion}
                          </p>
                        </b>
                      </div>

                      <div className="col cor6">
                        <b>
                          <p className="colour">ATS</p>
                        </b>
                        <b>
                          <p className="size1">{state.enquiryStats?.ats}</p>
                        </b>
                      </div>
                      {checkPermission("INCENTIVE_VIEW") && (
                        <div className="col cor7">
                          <b>
                            <p className="colour">Incentive</p>
                          </b>
                          <b>
                            <p className="size1">
                              {state.enquiryStats?.incentive}
                            </p>
                          </b>
                        </div>
                      )}

                      <div className="col cor8">
                        <b>
                          <p className="colour">Revenue</p>
                        </b>
                        <div
                          className="row"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-6 cor9"
                            style={{
                              backgroundColor: "antiquewhite",
                              textAlign: "center",
                            }}
                          >
                            <b>Achieved</b>
                            <b>
                              <p style={{ color: "red", fontSize: "15px" }}>
                                {state.enquiryStats?.revenue}
                              </p>
                            </b>
                          </div>
                          <div
                            className="col-6"
                            style={{ textAlign: "center" }}
                          >
                            <b>Target</b>
                            <b>
                              <p style={{ color: "green", fontSize: "15px" }}>
                                {state.enquiryStats?.achievedTargetRevenue}
                              </p>
                            </b>
                          </div>
                        </div>
                        {/* <b>
                        <p className="size1">
                          {state.enquiryStats?.currentMonthOpdDone}
                        </p>
                      </b> */}
                      </div>
                      {/* <b>
                        <p className="size1">{state.enquiryStats?.revenue}</p>
                      </b> */}
                    </div>
                  </div>
                )}
                {checkPermission("ENQUIRY_STATISTICS") && (
                  <div>
                    <div className="row border1">
                      <div className="col cor1">
                        <b>
                          <p className="size1">
                            {state.enquiryStats?.totalEnquiries}
                          </p>
                        </b>
                        <b>
                          <p className="colour">Total Enquiries</p>
                        </b>
                      </div>
                      <div className="col cor2">
                        <b>
                          <p className="size1">
                            {state.enquiryStats?.totalEnquiryFollowupCount}
                          </p>
                        </b>
                        <b>
                          <p className="colour">Total Followups</p>
                        </b>
                      </div>
                      <div className="col cor3">
                        <b>
                          <p className="size1">
                            {state.enquiryStats?.todayEnquiryCountNew}
                          </p>
                        </b>
                        <b>
                          <p className="colour">Today's Total</p>
                        </b>
                      </div>
                      <div className="col cor4">
                        <b>
                          <p className="size1">
                            {state.enquiryStats?.todayEnquiryCountFollowup}
                          </p>
                        </b>
                        <b>
                          <p className="colour">Today's Followup</p>
                        </b>
                      </div>
                      {checkPermission("LEADS_PARTNERSHIP_STATISTICS") && (
                        <div className="col cor5">
                          <b>
                            <p className="size1">
                              {state.enquiryStats?.totalPartnerShip}
                            </p>
                          </b>
                          <b>
                            <p className="colour">Total Partnerships</p>
                          </b>
                        </div>
                      )}{" "}
                    </div>
                  </div>
                )}
              </div>
              <br />
              <br />
              <br />
              {/* <marquee width="100%" direction="left">
                <div>
                  {state?.marquee?.topPerformers?.length ? (
                    <span style={{ color: "green", fontWeight: "bold" }}>
                      ◆Top Perfomers of this month :
                    </span>
                  ) : (
                    <></>
                  )}
                  {state?.marquee?.topPerformers?.map((val, index) => {
                    return (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {`  ${index + 1} . ${val}  `}
                      </span>
                    );
                  })}
                  <span />
                  {state?.marquee?.upComingTask?.length ? (
                    <span style={{ color: "green", fontWeight: "bold" }}>
                      ◆Upcoming Task :
                    </span>
                  ) : (
                    <></>
                  )}
                  {state?.marquee?.upComingTask?.map((val, index) => {
                    return (
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        {`🔔 ${val}`}
                      </span>
                    );
                  })}
                </div>
              </marquee> */}
              <div
                className="row"
                style={{
                  width: "100%",
                  marginLeft: 20,
                  display: "flex",
                }}
              >
                <div style={{ width: "90%" }}>
                  {checkPermission("LEADS_ADD_BUTTON") && (
                    <button
                      style={{ width: 120, height: 50 }}
                      onClick={() => {
                        setShowAddLead(!showAddLead);
                        setShowDownloadPanel(false);
                        setShowAutoCallPanel(false);
                      }}
                    >
                      Create Lead
                    </button>
                  )}
                  <button
                    style={{ width: 80, height: 50, marginLeft: 20 }}
                    onClick={() => {
                      setShowAddLead(false);
                      setShowDownloadPanel(false);
                      setShowAutoCallPanel(false);
                      state.refreshTable();
                    }}
                  >
                    Refresh
                  </button>
                  {/* {checkPermission("LEADS_REPORT_BUTTON") && (
                    <button
                      style={{ width: 200, height: 50, marginLeft: 20 }}
                      // onClick={() => state.downloadReport()}
                      onClick={() => {
                        setShowAddLead(false);
                        setShowAutoCallPanel(false);
                        setShowDownloadPanel(!showDownloadPanel);
                      }}
                    >
                      Report
                    </button>
                  )} */}
                  {/* <button
                    style={{ width: 200, height: 50, marginLeft: 20 }}
                    // onClick={() => state.downloadReport()}
                    onClick={() => {
                      state.clearAllFilter();
                      const url = window.location.href.split("?");
                      window.location.href = url[0];
                    }}
                  >
                    Clear Filter
                  </button> */}
                  {/* {checkPermission("AUTO_CALL_FIELD") && (
                    <button
                      style={{ width: 200, height: 50, marginLeft: 20 }}
                      // onClick={() => state.downloadReport()}
                      onClick={() => {
                        setShowAddLead(false);
                        setShowAutoCallPanel(!showAutoCallPanel);
                        setShowDownloadPanel(false);
                      }}
                    >
                      <FiPhoneCall style={{ width: 15 }}></FiPhoneCall>
                      &nbsp; Schedule Auto Call
                    </button>
                  )} */}

                  {/* <button
                    style={{ width: 120, height: 50, marginLeft: 20 }}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      state.loadAllTask(state.assignedAgentCode);
                      state.loadAssignedUsers();
                    }}
                  >
                    <FiBell style={{ width: 20 }}></FiBell>
                    Tasks{" "}
                    {kareChatCount > 0 && (
                      <Badge bg="danger">{kareChatCount}</Badge>
                    )}
                    Tasks &nbsp;
                    <span>
                      <Badge bg="danger" class="taskCount">
                        {state.taskCount}
                      </Badge>
                    </span>
                  </button> */}
                </div>
                <div style={{ width: "10%", justifyContent: "flex-end" }}>
                  <div> Select No. of Datas </div>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 140,
                        height: 36,
                        border: `1px solid #00997c`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {state.selectedPageFilter
                        ? state.selectedPageFilter
                        : "20"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {state.pageFilters.map((value, index) => {
                        return (
                          <>
                            <Dropdown.Item
                              key={index}
                              href="#"
                              style={{ width: 160 }}
                              onClick={() => {
                                state.setSelectedPageFilter(value);
                              }}
                            >
                              {value}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {showAddLead ? (
                <AddLead
                  // socket={socket}
                  onClicked={(openPopUp) => {
                    state.refreshTable();
                    setShowAddLead(openPopUp);
                  }}
                />
              ) : null}
              {showDownloadPanel ? (
                <DownloadPanel
                  onClicked={(openPopUp) => setShowDownloadPanel(openPopUp)}
                />
              ) : null}
              {showAutoCallPanel ? (
                <AutoCallPanel
                  onClicked={(callback) => setShowAutoCallPanel(callback)}
                />
              ) : null}
              {/* <TawkMessengerReact
                propertyId="63fa3b104247f20fefe29f0a"
                widgetId="1gq4ndeab"/> */}

              <div
                className="row scroll"
                style={{
                  minHeight: "3rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {" "}
                  Showing {state.offset * state.selectedPageFilter} -{" "}
                  {(state.offset + 1) * state.selectedPageFilter <
                  state.totalCount
                    ? (state.offset + 1) * state.selectedPageFilter
                    : state.totalCount}{" "}
                  out of {state.totalCount}
                </div>

                {checkPermission("LEADS_SL_NO_FIELD") && (
                  <div className="container col-1 m-0" style={{ width: 70 }}>
                    <b>Sl No.</b>
                  </div>
                )}

                <div className="container col-1 m-0">
                  <b>
                    {checkPermission("TRANSFERRED_DATE_VIEW")
                      ? "Date"
                      : "Enquiry Date"}
                  </b>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 140,
                        height: 36,
                        border: `1px solid #00997c`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "0px",
                      }}
                    >
                      {state.selectedEnquiryDateFilter
                        ? state.selectedEnquiryDateFilter
                        : "All"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {state.enquiryDateFilter.map((value, index) => {
                        return (
                          <>
                            <Dropdown.Item
                              key={index}
                              href="#"
                              style={{ width: 160 }}
                              onClick={() => {
                                state.setSelectedEnquiryDateFilter(value);
                                state.setReload(true);
                              }}
                            >
                              {value}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  {state.selectedEnquiryDateFilter === "Choose Date" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 200,
                        borderRadius: 5,
                        marginBottom: 7,
                      }}
                    >
                      <label style={{ marginLeft: 10 }}>From</label>
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchEnquiryFromDate
                            ? state.searchEnquiryFromDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchEnquiryFromDate(date);
                          state.setReload(true);
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  ) : null}
                  {state.selectedEnquiryDateFilter === "Choose Date" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 200,
                        borderRadius: 5,
                      }}
                    >
                      <label style={{ marginLeft: 10 }}>To</label>
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchEnquiryToDate
                            ? state.searchEnquiryToDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchEnquiryToDate(date);
                          state.setReload(true);
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="container col-1 m-0">
                  <b>Patient Name</b>
                  <br />
                  <div
                    style={{
                      border: `1px solid #00997c`,
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      style={{
                        width: 110,
                        height: "100%",
                        marginTop: 5,
                        border: "none",
                        outline: "none",
                        paddingLeft: "10px",
                      }}
                      name="search"
                      placeholder="Search Name"
                      // value={state.searchItem}
                      onChange={state.handleSearchItemChange}
                    />
                    <div
                      style={{
                        backgroundColor: "#06c6a2",
                        width: "40px",
                        height: "36px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => state?.handleSearch(1)}
                    >
                      <SearchIcon fontSize="small" />
                    </div>
                  </div>
                </div>

                <div className="container col-1 m-0">
                  <b>Phone Number</b>
                  <br />
                  <div
                    style={{
                      border: `1px solid #00997c`,
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      style={{
                        width: 110,
                        height: "100%",
                        marginTop: 5,
                        border: "none",
                        outline: "none",
                        paddingLeft: "10px",
                      }}
                      name="phone"
                      placeholder="Search"
                      // value={state.searchPhone}
                      onChange={state.handleSearchPhone}
                    />
                    <div
                      style={{
                        backgroundColor: "#06c6a2",
                        width: "40px",
                        height: "36px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => state?.handleSearch(2)}
                    >
                      <SearchIcon fontSize="small" />
                    </div>
                  </div>
                </div>

                <div className="container col-1 m-0">
                  <b>Enquiry ID</b>
                  <br />
                  <div
                    style={{
                      border: `1px solid #00997c`,
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      style={{
                        width: 110,
                        height: "100%",
                        marginTop: 5,
                        border: "none",
                        outline: "none",
                        paddingLeft: "10px",
                      }}
                      name="phone"
                      placeholder="Search"
                      // value={state.searchEnquiryId}
                      onChange={state.handleSearchEnquiryId}
                    />
                    <div
                      style={{
                        backgroundColor: "#06c6a2",
                        width: "40px",
                        height: "36px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => state?.handleSearch(3)}
                    >
                      <SearchIcon fontSize="small" />
                    </div>
                  </div>
                </div>

                <div className="container col-1 m-0">
                  <b>Assigned To</b>
                  <br />
                  {checkPermission("LEADS_ASSIGNED_FIELD") && (
                    <div
                      style={{
                        width: 120,
                        height: 35,
                        backgroundColor: "white",
                        border: "1px solid  #00997c",
                        borderRadius: 5,
                        marginTop: 8,
                      }}
                    >
                      <ReactMultiselectCheckboxes
                        options={state?.assignedUser?.list}
                        getDropdownButtonLabel={state.getDropdownButtonLabel}
                        value={state.selectedAssignedTo}
                        onChange={(value, event) =>
                          state.handleAssignedAgents(value, event)
                        }
                        setState={state.setSelectedAssignedTo}
                      />
                    </div>
                  )}
                </div>

                <div className="container col-1 m-0">
                  <b>Package Name</b>
                  {/* <div
                    style={{
                      width: 140,
                      height: 35,
                      backgroundColor: "white",
                      border: "1px solid  #00997c",
                      borderRadius: 5,
                      marginTop: 8,
                    }}
                  >
                    <ReactMultiselectCheckboxes
                      options={state.treatmentData}
                      getDropdownButtonLabel={state.getDropdownButtonLabel}
                      value={state.selectedTreatments}
                      onChange={(value, event) =>
                        state.handleSelectTreatments(value, event)
                      }
                      setState={state.setSelectedTreatments}
                    />
                  </div> */}
                </div>

                <div className="container col-1 m-0">
                  <b>Status</b>
                  <div
                    style={{
                      width: 140,
                      height: 35,
                      backgroundColor: "white",
                      border: "1px solid  #00997c",
                      borderRadius: 5,
                      marginTop: 8,
                    }}
                  >
                    <ReactMultiselectCheckboxes
                      options={state.statusData}
                      placeholderButtonLabel={"Choose"}
                      getDropdownButtonLabel={state.getDropdownButtonLabel}
                      value={state.selectedStatuses}
                      onChange={(value, event) =>
                        state.handleSelectStatuses(value, event)
                      }
                      setState={state.setSelectedStatuses}
                    />
                  </div>
                </div>
                {/* statusData search and dropdown section end*/}
                <div className="container col-1 m-0">
                  <b>FollowUp Date</b>
                </div>

                <div className="container col-1 m-0">
                  <b>Comments</b>
                </div>

                <div className="container col-1 m-0">
                  <b>Origin</b>
                  <div
                    style={{
                      width: 140,
                      height: 35,
                      backgroundColor: "white",
                      border: "1px solid  #00997c",
                      borderRadius: 5,
                      marginTop: 8,
                    }}
                  >
                    <ReactMultiselectCheckboxes
                      options={state.sourceData}
                      getDropdownButtonLabel={state.getDropdownButtonLabel}
                      value={state.selectedSources}
                      onChange={(value, event) =>
                        state.handleSelectSources(value, event)
                      }
                      setState={state.setSelectedSources}
                    />
                  </div>
                </div>

                <div className="container col-1 m-0">
                  <b>Location</b>
                  <div
                    style={{
                      width: 140,
                      height: 35,
                      backgroundColor: "white",
                      border: "1px solid  #00997c",
                      borderRadius: 5,
                      marginTop: 8,
                    }}
                  ></div>
                </div>

                <div className="container col-1 m-0">
                  <b>Price</b>
                </div>

                {checkPermission("LEAD_INTENT_FIELD") && (
                  <div className="container col-1 m-0">
                    <b>Intent</b>

                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        style={{
                          width: 100,
                          height: 36,
                          border: `1px solid #00997c`,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {state.selectedIntentFilter
                          ? state.selectedIntentFilter
                          : "All"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {state.intentFilter.map((value, index) => {
                          return (
                            <>
                              <Dropdown.Item
                                key={index}
                                href="#"
                                style={{ width: 160 }}
                                onClick={() => {
                                  state.setSelectedIntentFilter(value);
                                  state.setReload(true);
                                }}
                              >
                                {value}
                              </Dropdown.Item>
                            </>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
              <hr />

              <List component="nav" aria-label="mailbox folders">
                {state.homeData}
              </List>
              {/* chat section */}
              {/* <KareMessage /> */}
              {/* chat section */}
            </div>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={state.pageCount}
              initialPage={state.offset}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={state.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={state.offset}
            />
          </div>
          {isDrawerOpen && (
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
            >
              <div
                style={{
                  position: "fixed",
                  backgroundColor: "#06c6a2",
                  zIndex: 99,
                  width: "460px",
                  height: 70,
                }}
              >
                <h4 style={flexStyle}>Notifications </h4>

                <span class="closebtn" onClick={() => setIsDrawerOpen(false)}>
                  &times;
                </span>
                <div className="row" style={{ marginLeft: "8px" }}>
                  <button
                    onClick={() => {
                      state.setIsToday(true);
                      state.setPageNo(0);
                    }}
                    style={{
                      backgroundColor: state.isToday ? "#06c6a2" : "gray",
                      marginTop: "45px",
                      width: "42%",
                      marginLeft: "12px",
                      height: "50px",
                    }}
                  >
                    <span style={{ fontSize: "16px" }}>Today </span>
                    <span>
                      <Badge bg="danger">{state.todayCount}</Badge>
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      state.setIsToday(false);
                      state.setPageNo(0);
                    }}
                    style={{
                      backgroundColor: !state.isToday ? "#06c6a2" : "gray",
                      marginTop: "45px",
                      width: "42%",
                      marginLeft: "12px",
                      height: "50px",
                    }}
                  >
                    <span style={{ fontSize: "16px" }}>Missed </span>
                    <span>
                      <Badge bg="danger">{state.missedCount}</Badge>
                    </span>
                  </button>
                </div>
                {checkPermission("LEADS_ASSIGNED_FIELD") && (
                  <div className="col-md-2">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        style={{
                          width: 200,
                          border: `1px solid gray`,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginLeft: "15px",
                        }}
                      >
                        {!!state.assignedAgent
                          ? state.assignedAgent
                          : "Assign To"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        as={CustomMenu}
                        style={{
                          height: 200,
                          width: 260,
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {state?.assignedUser?.list?.map((value, id) => {
                          return (
                            <Dropdown.Item
                              key={id}
                              href="#"
                              style={{ width: 200 }}
                              onClick={() => {
                                state.setAssignedAgent(value.label);
                                state.setAssignedAgentCode(value.value);
                                state.loadAllTask(value.value);
                              }}
                            >
                              {value?.label}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
              <hr></hr>

              <div style={{ width: 460 }}>
                {state.isloader ? (
                  <p>Loading....</p>
                ) : (
                  <InfiniteScroll
                    style={{ marginTop: "20vh" }}
                    dataLength={state.taskData.length}
                    next={state.fetchMoreData}
                    hasMore={state.hasMore}
                    loader={
                      state.hasMore && (
                        <h6 style={{ textAlign: "center" }}>Loading...</h6>
                      )
                    }
                    height={"75vh"}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                  >
                    {state.taskData.length ? (
                      state.taskData.map((item, i) => {
                        return <NotificationCard item={item} />;
                      })
                    ) : (
                      <div>
                        <p style={{ margin: "auto" }}>
                          <b>Task fully finished... ✌🏻🥳</b>
                        </p>
                      </div>
                    )}
                  </InfiniteScroll>
                )}
              </div>
            </Drawer>
          )}
        </div>
      </div>
    </>
  );
}

export default KarePlus;
