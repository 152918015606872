import "./../Patients.css";
import useVil from "./useVil.logic";
import React, { Fragment } from "react";
import DatePicker from "react-datepicker";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { Autocomplete, Grid, Link, TextField, Typography } from "@mui/material";
import AlertDialogSlide from "../../PopUp/PopUp";

function Vil({ patientDetail, activeTab }) {
  const state = useVil(patientDetail);
  const tabTitle = (activeTab ? "Edit" : "Add") + " VIL Details";
  return (
    <Grid item sx={{ width: "100%" }}>
      <Grid
        item
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ width: "100%", height: 100, backgroundColor: "#EFF5F5" }}
      >
        <Typography sx={{ fontSize: "25px", fontWeight: "600" }}>
          {tabTitle}
        </Typography>
      </Grid>
      <Grid item container sx={{ width: "100%" }}>
        <Grid item lg={2} sx={{ width: "10%", height: 100 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Center Name
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={state?.centersList || []}
            sx={{
              width: 245,
              marginTop: "5px",
            }}
            value={state?.selectedCenter || ""}
            onChange={(e, val) => state?.handleCenterDetails(val)}
            isOptionEqualToValue={(option, value) =>
              option === value || value === ""
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Name" />
            )}
          />
        </Grid>
        <Grid item lg={2} sx={{ width: "10%", height: 100 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Speciality Name
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={state?.specialitiesList || []}
            sx={{
              width: 245,
              marginTop: "5px",
            }}
            value={state?.selectedSpecialityName || ""}
            onChange={(e, val) => state?.hadleSelectedSpeciality(val)}
            isOptionEqualToValue={(option, value) =>
              option === value || value === ""
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Speciality" />
            )}
          />
        </Grid>
        <Grid item lg={2} sx={{ width: "10%", height: 100 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Enquiry Name
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={state?.agentsList || []}
            sx={{
              width: 245,
              marginTop: "5px",
            }}
            value={state?.selectedAgent || ""}
            onChange={(e, val) => state?.handleAgentDetails(val)}
            isOptionEqualToValue={(option, value) =>
              option === value || value === ""
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Enquiry By" />
            )}
          />
        </Grid>
        <Grid item lg={2} sx={{ width: "10%", height: 100 }}>
          <Grid item sx={{ marginTop: "5px" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              Preferred Date To Travel
            </Typography>
            <DatePicker
              placeholderText="Date"
              className="searchField"
              selected={state?.selectedPreferredDate}
              onChange={(date) => {
                state?.setSelectedPreferredDate(date);
              }}
            />
          </Grid>
        </Grid>
        <Grid item lg={2} sx={{ width: "10%", height: 100 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Doctor Name
          </Typography>
          <input
            className=""
            placeholder="Name"
            style={{
              height: 55,
              borderRadius: 5,
              border: `1px solid #c4c4c4`,
              display: "flex",
              width: 245,
              paddingLeft: 10,
              marginTop: "5px",
            }}
            value={state?.doctorName}
            onChange={(e) => state?.setDoctorName(e?.target?.value)}
          />
        </Grid>
        <Grid item lg={2} sx={{ width: "10%", height: 100 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            Preferred City
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={state?.citiesList || []}
            sx={{
              width: 245,
              marginTop: "5px",
            }}
            value={state?.selectedCity || ""}
            onChange={(e, val) => state?.setSelectedCity(val?.label)}
            isOptionEqualToValue={(option, value) =>
              option === value || value === ""
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="City" />
            )}
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        alignItems={"flex-start"}
        sx={{
          width: "100%",
          height: 140,
          marginTop: "10px",
        }}
      >
        <Grid item lg={3}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "left",
            }}
          >
            Add Comments
          </Typography>
          <textarea
            placeholder="Comments..."
            style={{
              width: "100%",
              minHeight: 115,
              border: `1px solid #c4c4c4`,
            }}
            value={state?.comments}
            onChange={(e) => state?.setComments(e?.target?.value)}
          />
        </Grid>
        <input
          type="file"
          accept="image/png"
          onChange={(e) => state?.handleFileUploadObj(e, "patient_documents")}
          ref={state?.fileInputRef}
          style={{ display: "none" }}
        />
        <input
          type="file"
          accept="image/png"
          onChange={(e) => state?.handleFileUploadObj(e, "attendar_documents")}
          ref={state?.attendarfileInputRef}
          style={{ display: "none" }}
        />

        <input
          type="file"
          accept="image/png"
          onChange={(e) => state?.handleFileUploadObj(e, "other_documents")}
          ref={state?.otherDocumentsfileRef}
          style={{ display: "none" }}
        />

        <Grid item lg={9}>
          <Grid container>
            {!state?.patientPassportFiles?.length > 0 ? (
              <Grid item lg={1.5} sx={{ marginLeft: "20px" }}>
                <Typography component={"span"}>Patient Passport</Typography>
                {!state?.fileList?.length > 0 ? (
                  <Grid sx={{ padding: "10px 20px 43px 0px" }}>
                    <Grid
                      item
                      lg={9}
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                      onClick={() =>
                        state?.handleFileUpload("patient_documents")
                      }
                      sx={{
                        width: 160,
                        height: 50,
                        backgroundColor: "#A4EBF3",
                        borderRadius: 2,
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                    >
                      <Typography sx={{ fontWeight: "600" }}>Upload</Typography>
                      <UploadFileIcon />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    sx={{
                      width: 200,
                      height: "auto",
                    }}
                  >
                    {state?.fileList &&
                      state?.fileList?.map((file, index) => {
                        return (
                          <Grid
                            item
                            lg={12}
                            key={index}
                            container
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Grid
                              item
                              lg={12}
                              container
                              justifyContent={"flex-end"}
                              alignItems={"flex-end"}
                              sx={{
                                width: 120,
                                height: 90,
                                backgroundColor: "#CCF2F4",
                                border: "3px dotted black",
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                src={URL.createObjectURL(file?.image)}
                                style={{ width: "170px", marginTop: "10px" }}
                                alt={`Image ${index}`}
                              />
                              {!state?.isDataLoaded && (<Grid
                                item
                                container
                                justifyContent={"center"}
                                alignItems={"center"}
                                onClick={() =>
                                  state?.handleDeleteFile(
                                    index,
                                    "patient_documents"
                                  )
                                }
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  backgroundColor: "#a4ebf3",
                                  position: "absolute",
                                  cursor: "pointer",
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </Grid>)}
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              container
                              sx={{
                                width: 160,
                                backgroundColor: "#34b3f1",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                border: "1px solid #ccc",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "14px", color: "white" }}
                              >
                                {file?.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
              </Grid>
            ) : (
              <div
                style={{
                  width: "160px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                {state?.patientPassportFiles?.map((doc, index) => {
                  return (
                    <>
                      <div
                        style={{
                          height: "120px",
                          alignItems: "center",
                          marginTop: "85px",
                        }}
                      >
                        <Typography>Patient Passport</Typography>

                        <div
                          style={{
                            display: "flex",

                            height: "80px",
                            alignItems: "center",
                            padding: "10px",
                          }}
                        >
                          <div
                            style={{
                              width: "100px",
                              height: "40px",
                              backgroundColor: "#34b3f1",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <Link
                              className="pointer"
                              onClick={() =>
                                state?.downloadDocument(doc.fileName)
                              }
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              {`Document ${index + 1}`}
                            </Link>
                          </div>

                          <div
                            style={{
                              width: "50px",
                              height: "40px",
                              backgroundColor: "#6da9e4",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => state?.handleClickOpen(doc?.id)}
                          >
                            <DeleteSweepIcon />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            )}

            {!state?.attendarPassportFiles?.length > 0 ? (
              <Grid item lg={1.5} sx={{ marginLeft: "20px" }}>
                <Typography component={"span"}>Attendar Passport</Typography>
                {!state?.attendarfileList?.length > 0 ? (
                  <Grid sx={{ padding: "10px 20px 43px 0px" }}>
                    <Grid
                      item
                      lg={9}
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                      onClick={() =>
                        state?.handleFileUpload("attendar_documents")
                      }
                      sx={{
                        width: 160,
                        height: 50,
                        backgroundColor: "#A4EBF3",
                        borderRadius: 2,
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                    >
                      <Typography sx={{ fontWeight: "600" }}>Upload</Typography>
                      <UploadFileIcon />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    sx={{
                      width: 200,
                      height: "auto",
                    }}
                  >
                    {state?.attendarfileList &&
                      state?.attendarfileList?.map((file, index) => {
                        return (
                          <Grid
                            item
                            lg={12}
                            key={index}
                            container
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Grid
                              item
                              lg={12}
                              container
                              justifyContent={"flex-end"}
                              alignItems={"flex-end"}
                              sx={{
                                width: 120,
                                height: 90,
                                backgroundColor: "#CCF2F4",
                                border: "3px dotted black",
                                position: "relative",
                                overflow: "hidden",
                                // marginTop: "20px",
                              }}
                            >
                              <img
                                src={URL.createObjectURL(file?.image)}
                                style={{ width: "170px", marginTop: "10px" }}
                                alt={`Image ${index}`}
                              />
                              {!state?.isDataLoaded && (<Grid
                                item
                                container
                                justifyContent={"center"}
                                alignItems={"center"}
                                onClick={() =>
                                  state?.handleDeleteFile(
                                    index,
                                    "attendar_documents"
                                  )
                                }
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  backgroundColor: "#a4ebf3",
                                  position: "absolute",
                                  cursor: "pointer",
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </Grid>)}
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              container
                              sx={{
                                width: 160,
                                backgroundColor: "#34b3f1",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                border: "1px solid #ccc",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "14px", color: "white" }}
                              >
                                {file?.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
              </Grid>
            ) : (
              <div
                style={{
                  width: "160px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {state?.attendarPassportFiles?.map((doc, index) => {
                  return (
                    <>
                      <div
                        style={{
                          height: "120px",
                          alignItems: "center",
                          marginTop: "85px",
                        }}
                      >
                        <Typography>Attendar Passport</Typography>

                        <div
                          style={{
                            display: "flex",
                            height: "80px",
                            alignItems: "center",
                            padding: "10px",
                          }}
                        >
                          <div
                            style={{
                              width: "100px",
                              height: "40px",
                              backgroundColor: "#34b3f1",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <Link
                              className="pointer"
                              onClick={() =>
                                state?.downloadDocument(doc.fileName)
                              }
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              {`Document ${index + 1}`}
                            </Link>
                          </div>

                          <div
                            style={{
                              width: "50px",
                              height: "40px",
                              backgroundColor: "#6da9e4",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => state?.handleClickOpen(doc?.id)}
                          >
                            <DeleteSweepIcon />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            )}
            <Grid item container lg={6} alignItems={"flex-end"}>
              <Typography component={"span"} sx={{ marginLeft: "17px" }}>
                {" "}
                Other Passport
              </Typography>

              <Grid item container lg={12} height={"113px"}>
                <Grid
                  item
                  sx={{
                    height: "90px",
                    display: "flex",
                    marginLeft: "20px",
                    gap: "20px",
                  }}
                >
                  {!state?.isEdit ? (
                    <Fragment>
                      {!state?.documentFile?.length > 0 ? (
                        <>
                          {state?.otherDocumentsList &&
                            state?.otherDocumentsList.map((file, index) => {
                              return (
                                <Grid
                                  item
                                  sx={{ width: "200px" }}
                                  container
                                  key={index}
                                >
                                  <Grid
                                    item
                                    container
                                    justifyContent={"flex-end"}
                                    alignItems={"flex-end"}
                                    sx={{
                                      width: 200,
                                      height: 90,
                                      backgroundColor: "#CCF2F4",
                                      border: "3px dotted black",
                                      position: "relative",
                                    }}
                                  >
                                    <img
                                      src={URL.createObjectURL(file?.image)}
                                      alt=""
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                    {!state?.isDataLoaded && (<Grid
                                      item
                                      container
                                      justifyContent={"center"}
                                      alignItems={"center"}
                                      onClick={() =>
                                        state?.handleDeleteFile(index)
                                      }
                                      sx={{
                                        width: "30px",
                                        height: "30px",
                                        backgroundColor: "#a4ebf3",
                                        position: "absolute",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </Grid>)}
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    sx={{
                                      width: 200,
                                      backgroundColor: "#34b3f1",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      border: "1px solid #ccc",
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: "14px", color: "white" }}
                                    >
                                      {file?.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                        </>
                      ) : (
                        <div
                          style={{
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {state?.otherDocumentFiles?.map((doc, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  marginRight: "20px",
                                  marginTop: "45px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100px",
                                    height: "40px",
                                    backgroundColor: "#34b3f1",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Link
                                    className="pointer"
                                    onClick={() =>
                                      state?.downloadDocument(doc.fileName)
                                    }
                                    style={{
                                      color: "white",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {`Document ${index + 1}`}
                                  </Link>
                                </div>

                                <div
                                  style={{
                                    width: "50px",
                                    height: "40px",
                                    backgroundColor: "#6da9e4",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => state?.handleClickOpen(doc?.id)}
                                >
                                  <DeleteSweepIcon />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {state?.otherDocumentsList &&
                        state?.otherDocumentsList.map((file, index) => {
                          return (
                            <Grid
                              item
                              sx={{ width: "200px" }}
                              container
                              key={index}
                            >
                              <Grid
                                item
                                container
                                justifyContent={"flex-end"}
                                alignItems={"flex-end"}
                                sx={{
                                  width: 180,
                                  height: 100,
                                  backgroundColor: "#CCF2F4",
                                  border: "3px dotted black",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={URL.createObjectURL(file?.image)}
                                  alt=""
                                  style={{ width: "100%", height: "100%" }}
                                />
                                {!state?.isDataLoaded && (<Grid
                                  item
                                  container
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  onClick={() =>
                                    state?.handleDeleteFile(
                                      index,
                                      "other_documents"
                                    )
                                  }
                                  sx={{
                                    width: "30px",
                                    height: "30px",
                                    backgroundColor: "#a4ebf3",
                                    position: "absolute",
                                    cursor: "pointer",
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </Grid>)}
                              </Grid>
                              <Grid
                                item
                                container
                                sx={{
                                  width: 180,
                                  backgroundColor: "#34b3f1",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  border: "1px solid #ccc",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "14px", color: "white" }}
                                >
                                  {file?.name}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Fragment>
                  )}

                  <Grid
                    sx={{
                      display: "flex",
                      width: "240px",
                      gap: "20px",
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        lg={10}
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        onClick={() =>
                          state?.handleFileUpload("other_documents")
                        }
                        sx={{
                          width: 160,
                          height: 50,
                          backgroundColor: "#A4EBF3",
                          borderRadius: 2,
                          cursor: "pointer",
                          marginTop: "20px",
                        }}
                      >
                        <Typography sx={{ fontWeight: "600" }}>
                          Upload
                        </Typography>
                        <UploadFileIcon />
                      </Grid>
                    </Grid>

                    <Grid item lg={4} marginTop={"20px"}>
                      <button
                        style={{
                          width: "80px",
                          height: "50px",
                          backgroundColor: "#34B3F1",
                          color: "white",
                          margin: 0,
                        }}
                        onClick={state?.submitEnquiryDetails}
                      >
                        Save
                      </button>
                    </Grid>
                    <AlertDialogSlide
                      open={state?.open}
                      handleDelete={state?.handleDelete}
                      handleClose={state?.handleClose}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Vil;
