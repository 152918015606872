import {
  Grid,
  ListItem,
  ListItemText,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";
function PreLoadsFaqs({
  faqAnswer,
  faqData,
  handleChange,
  isPreLeadEdit,
  setIsPreLeadEdit,
  setIsEditing,
  isEditing,
  setFaqData,
  preLeadStatus
}) {
  const preLeadsAns = [
    { label: "Yes", id: 1 },
    { label: "No", id: 2 },
  ];
  const handleEditButtonClick = () => {
    setIsEditing(!isEditing);
    setIsPreLeadEdit(true);

    if (faqAnswer.length !== 0) {
      setFaqData(faqAnswer);
    }
  };
  return (
    <Grid item sx={{ width: "100%", height: "auto" }} container>
      {isPreLeadEdit || faqAnswer.length === 0 ? (
        <Grid item lg={4} sx={{ width: "30%", height: "auto" }}>
          {faqData?.length > 0 &&
            faqData?.map((faq, index) => {
              return (
                <Grid sx={{ width: "100%", height: 60 }} key={index}>
                  <ListItem key={index}>
                    <ListItemText>Q: {faq?.question}</ListItemText>
                    {faq?.type === "DROPDOWN" && (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={preLeadsAns}
                        sx={{ width: 200 }}
                        value={faq?.answer}
                        onChange={(e, value) =>
                          handleChange(value.label, faq?.questionId, index)
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select.." />
                        )}
                      />
                    )}
                    {faq?.type === "TEXT" && (
                      <input
                        value={faq?.answer}
                        style={{ height: 55, width: 200, paddingLeft: "15px" }}
                        onChange={(e) =>
                          handleChange(e.target.value, faq?.questionId, index)
                        }
                      />
                    )}
                  </ListItem>
                </Grid>
              );
            })}
        </Grid>
      ) : (
        <>
          <Grid item lg={4} sx={{ width: "30%", height: "auto" }}>
            {faqAnswer?.length > 0 &&
              faqAnswer?.map((faq, index) => {
                return (
                  <Grid sx={{ width: "100%", height: 50 }} key={index}>
                    <ListItem>
                      <span
                        style={{
                          fontWeight: "600",
                          color: "gray",
                          marginLeft: "10px",
                          marginRight: "5px",
                        }}
                      >
                        Q:
                      </span>
                      <span sx={{ bgcolor: "yellow", width: "auto" }}>
                        {faq?.question}
                      </span>
                      <span
                        style={{
                          fontWeight: "600",
                          color: "gray",
                          marginLeft: "10px",
                          marginRight: "5px",
                        }}
                      >
                        Ans:
                      </span>

                      <span>
                        {faq?.id} {faq?.answer}
                      </span>
                    </ListItem>
                  </Grid>
                );
              })}
          </Grid>
          {preLeadStatus !== "Transferred" && (
            <Grid item>
              <Button
                sx={{
                  backgroundColor: "#06c6a2",
                  width: "70px",
                  color: "white",
                  ":hover": { backgroundColor: "#06c6a2" },
                }}
                onClick={handleEditButtonClick}
              >
                Edit
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default PreLoadsFaqs;
