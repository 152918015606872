import React, { useRef, useState } from "react";
import { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { getAllDocuments } from "../../../../../services/karetrip/enquiryService";
import {
  uploadDocuments,
  uploadUserDocuments,
} from "../../../../../services/mykare/documentService";
import downloadDocument from "../../../../../util/documentUtil";

export default function PatientDoc({ userTrxnId, patientId }) {
  const patientDocRef = useRef(null);
  const [patientDocs, setPatientDocs] = useState([]);

  const [selectedDocType, setSelectedDocType] = useState({
    id: 0,
    name: "Select",
    category: "Select",
  });

  const [otherFiles, setOtherFile] = useState([]);
  const [prescriptionFiles, setPrescriptionFile] = useState([]);
  const [passportFiles, setPassPortFile] = useState([]);
  const context = "ENQUIRY_DOCUMENT";

  const [docTypes, setDocTypes] = useState([
    {
      id: 1,
      name: "Passport",
      category: "passport",
      setFun: setPassPortFile,
    },
    { id: 2, name: "Other Docs", category: "treatment", setFun: setOtherFile },
    {
      id: 5,
      name: "Prescription",
      category: "prescription",
      setFun: setPrescriptionFile,
    },
  ]);

  const handleSelectedDoc = (docType) => {
    setSelectedDocType(docType);
  };

  const removeItem = (id) => {
    const filesClone = patientDocs.filter((file) => file.id !== id);
    setPatientDocs(filesClone);
  };

  const handleTicketFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setPatientDocs((state) => [...state, file]);
  };

  useEffect(() => {
    if (patientId) {
      loadDocuments(patientId, context, "treatment", setOtherFile);
      loadDocuments(patientId, context, "passport", setPassPortFile);
      loadDocuments(patientId, context, "prescription", setPrescriptionFile);
    }
  }, []);

  const loadDocuments = (contextId, context, category, setFiles) => {
    getAllDocuments(contextId, context, category)
      .then((res) => {
        setFiles(res.data);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const uploadPatientDocs = (patientId) => {
    let formData = new FormData();
    patientDocs.forEach((doc) => {
      formData.append("userDocs", doc);
    });
    formData.append("category", selectedDocType.category);
    formData.append("documentContext", context);
    formData.append("userId", patientId);

    uploadUserDocuments(formData)
      .then((data) => {
        alert("Uploaded Successfully");
        setSelectedDocType({ id: 0, name: "Select", category: "Select" });
        setPatientDocs([]);
        loadDocuments(
          patientId,
          context,
          selectedDocType.category,
          selectedDocType.setFun
        );
      })
      .catch((err) => {});
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div style={{ display: "flex" }}>
            <p className="mb-0 mt-3 text-start">Other Docs: </p>
            {otherFiles &&
              otherFiles.map((doc, index) => (
                <div className="row">
                  <div class="col-6">
                    <div className="ed-txt-2 text-start"></div>
                  </div>
                  <div>
                    <b>
                      <Link
                        style={{ marginLeft: 10 }}
                        className="pointer"
                        onClick={() =>
                          downloadDocument(
                            doc.documentType,
                            doc.document,
                            doc.documentName
                          )
                        }
                      >
                        {`Other Doc ${index + 1}`}
                      </Link>
                    </b>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="col-lg-6">
          <div style={{ display: "flex" }}>
            <p className="mb-0 mt-3 text-start">Prescription: </p>
            {prescriptionFiles &&
              prescriptionFiles.map((doc, index) => (
                <div className="row">
                  <div class="col-6">
                    <div className="ed-txt-2 text-start"></div>
                  </div>
                  <div>
                    <b>
                      <Link
                        style={{ marginLeft: 10 }}
                        className="pointer"
                        onClick={() =>
                          downloadDocument(
                            doc.documentType,
                            doc.document,
                            doc.documentName
                          )
                        }
                      >
                        {`Prescription ${index + 1}`}
                      </Link>
                    </b>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="col-lg-6">
          <div style={{ display: "flex" }}>
            <p className="mb-0 mt-3 text-start">Passport: </p>
            {passportFiles &&
              passportFiles.map((doc, index) => (
                <div className="row">
                  <div class="col-6">
                    <div className="ed-txt-2 text-start"></div>
                  </div>
                  <div>
                    <b>
                      <Link
                        style={{ marginLeft: 10 }}
                        className="pointer"
                        onClick={() =>
                          downloadDocument(
                            doc.documentType,
                            doc.document,
                            doc.documentName
                          )
                        }
                      >
                        {`Passport ${index + 1}`}
                      </Link>
                    </b>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
          Choose Document Type
        </p>
        <Dropdown>
          <Dropdown.Toggle
            variant="none"
            style={{
              width: 200,
              border: `1px solid gray`,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px",
            }}
          >
            {selectedDocType ? selectedDocType.name : "Select"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {docTypes?.map((doc, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  href="#"
                  style={{ width: 200 }}
                  onClick={() => handleSelectedDoc(doc)}
                >
                  {doc.name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {selectedDocType.id != 0 && (
        <div className="col-10" style={{ marginTop: 20 }}>
          <div>
            <label
              for="ticketDoc"
              className="btn"
              style={{
                width: 250,
                backgroundColor: "#06c6a2",
                color: "white",
              }}
            >
              Upload {selectedDocType.name}
            </label>
            <br></br>
            <input
              id="ticketDoc"
              style={{
                width: 100,
                cursor: "pointer ",
                display: "none",
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              type="file"
              ref={patientDocRef}
              onChange={handleTicketFile.bind(this)}
            />
            {patientDocs.map((item, index) => {
              return (
                <>
                  <span
                    style={{
                      border: "1px solid gray",
                      marginLeft: 10,
                      cursor: "pointer ",
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingBottom: 10,
                      paddingTop: 5,
                      backgroundColor: "#e5e5e5",
                    }}
                    onClick={() => removeItem(item.id)}
                  >
                    {item.name}
                  </span>
                  <span
                    style={{
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingBottom: 10,
                      paddingTop: 5,
                      backgroundColor: "#06c6a2",
                      border: "1px solid gray",
                      color: "white",
                      cursor: "pointer ",
                    }}
                    onClick={() => removeItem(item.id)}
                  >
                    X
                  </span>
                </>
              );
            })}
          </div>
          &nbsp;
          <div className="col-2">
            <div>
              <button
                style={{ width: 70, padding: 8, marginBottom: 100 }}
                onClick={() => uploadPatientDocs(patientId)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
