import axios from "axios";
import { useEffect, useState } from "react";
import { getAllLocations } from "../../services/mykare/centerService";
import { getOrigins, getTreatments } from "../../services/mykare/dataService";
import { getSources, saveEnquiry } from "../../services/mykare/enquiryService";
import * as appConstants from "../../util/AppConstants";
import { saveEventActivityLogs } from "../../util/EventActivity";
import { getAllCountries } from "./../../services/mykare/userService";
import { checkDuplicate } from "../../services/mykare/enquiryService";

function usePopUp(props) {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedTreatment, setselectedTreatment] = useState("");
  const [treatments, setTreatments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedOrigin, setselectedOrigin] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  // const [countryCode, setCountryCode] = useState(null);
  const [countryCode, setCountryCode] = useState(
    {id: 11, name: 'India', code: '+91'});

  const [origins, setOrigins] = useState([]);
  const [assignedAgent, setAssignedAgent] = useState();
  const [assignedCode, setAssignedCode] = useState();
  const [userData, setUserData] = useState([]);
  const [comment, setComment] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [selectedSource, setSelectedSource] = useState("");
  const [sources, setSources] = useState([]);
  const [referral, setReferral] = useState("");


  const loadCountries = () => {
    getAllCountries()?.then((countryList) => {
      setCountryList(countryList?.data);
    });
  };

  useEffect(() => {
    loadTreatments();
    loadCountries();
    // loadOrigins();
    loadAllLocations();
    getAgentData();
    getSource();
  }, []);

  const loadTreatments = async () => {
    try {
      getTreatments().then((res) => {
        setTreatments(res.data);
      });
    } catch (error) {}
  };

  const getSource = async () => {
    const response = await getSources();
    if (response.status === 200) {
      if (response.data) {
        setSources(response.data);
      }
    }
  };

  const handleToAssigned = (assignTo, assignCode) => {
    setAssignedAgent(assignTo);
    setAssignedCode(assignCode);
  };

  const loadAllLocations = async () => {
    try {
      getAllLocations().then((data) => {
        setLocations(data);
      });
    } catch (error) {}
  };

  const getAgentData = async () => {
    try {
      const data = await axios.get(
        appConstants.APP_URL + `/user/all?userRole=SALES_AGENT&active=true`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      setUserData(data.data.users);

      return data;
    } catch (error) {}
  };

  const saveEnquiryDetails = async () => {
    const postData = {
      enquiryContext: "CENTER",
      enquiryContextId: 0,
      isPackage: true,
      name: name,
      sourceId: selectedSource.id,
      packageId: selectedTreatment.id,
      locationId: selectedLocation.id,
      phoneNumber: countryCode?.code + phoneNumber,
      platform: "MYKARE_WEB",
      assignedTo: assignedCode,
      notes: comment,
      additionalInfo: { notes: comment },
      referredName: referral,
    };
    try {
      if ([29, 18, 17].includes(selectedSource.id)) {
        if (referral && selectedSource && name && phoneNumber) {
          // const duplicatePostData = { phoneNumber: phoneNumber };
          // const duplicateCheckResponse = await checkDuplicate(
          //   duplicatePostData
          // );

          // if (duplicateCheckResponse.status === 409) {
          // alert("User with this phone number already exists");
          // } else {
          const response = await saveEnquiry(postData);
          if (response.status === 200) {
            props.onClicked(false);
            alert("New Lead added successfully");
          } else {
            alert("Please check all status");
          }
          // }
        } else {
          alert("Please fill Referral Name");
        }
      } else if (selectedSource && name && phoneNumber) {
        // const duplicatePostData = { phoneNumber: phoneNumber };
        // const duplicateCheckResponse = await checkDuplicate(duplicatePostData);
        // if (duplicateCheckResponse.status === 409) {
        //   alert("User with this phone number already exists");
        //   return;
        // } else {
        const response = await saveEnquiry(postData);
        if (response.status === 200) {
          props.onClicked(false);
          alert("New Lead added successfully");
        } else {
          alert("Please check all status");
        }
        // }
      } else {
        alert("Please fill all required fields");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert("User with this phone number already exists");
      } else {
        console.error("Error saving enquiry:", error);
      }
    }
  };

  return {
    setName,
    setPhoneNumber,
    saveEnquiryDetails,
    name,
    phoneNumber,
    treatments,
    selectedTreatment,
    selectedLocation,
    setselectedTreatment,
    locations,
    setSelectedLocation,
    origins,
    selectedOrigin,
    setselectedOrigin,
    handleToAssigned,
    setAssignedAgent,
    setAssignedCode,
    assignedAgent,
    assignedCode,
    userData,
    countryCode,
    countryList,
    setCountryCode,
    selectedSource,
    setSelectedSource,
    sources,
    setSources,
    setComment,
    setReferral,
  };
}

export default usePopUp;
