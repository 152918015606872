import React from "react";
import TopBar from "./TopBar";
import "../../Enquiry/Enquiry.css";
import "../../../Styles/Common.css";
import "../../../Styles/sideBar.css";
import "../../../Styles/Pagination.css";
import Sidebar from "../../../Pages/Sidebar";
import { getExecutivesReports } from "../../../services/karetrip/enquiryService";
import { useEffect } from "react";
import { useState } from "react";
function kareTripExicutiveReports() {
  const border = {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  };
  const [reportsData, setReportsData] = useState(null);
  useEffect(() => {
    getEnquiryReports();
  }, []);
  const reportList = [
    { label: "Count of Good + Hot Leads", name: "goodAndHotLeads" },
    { label: "Vil Sent", name: "vilShared" },
    { label: "Quotation Sent", name: "quotationSharedWithPatient" },
    { label: "Tickets Received", name: "ticketsReceivedArrivalConfirmed" },
    { label: "SurgerySuggested", name: "surgerySuggested" },
    { label: "VilRequestSentToHospital", name: "vilRequestSentToHospital" },
    {
      label: "Patients Arrived in last 14 days",
      name: "patientArrivedInLast14Days",
    },
    { label: "IPD Confirmed", name: "ipdscheduled" },
  ];

  const getEnquiryReports = async () => {
    await getExecutivesReports().then((res) => {
      let keys = Object.keys(res?.reports[0])
        .filter((fl) => fl !== "agentName")
        .map((list) => {
          let index = reportList.findIndex((fi) => fi.name === list);
          return {
            label: reportList[index]?.label,
            name: reportList[index]?.name,
            agents: res?.reports.map((list) => ({
              name: list.agentName,
              count: 0,
            })),
          };
        });
      res?.reports.map((list) => {
        keys.forEach((e) => {
          let index = e.agents.findIndex((i) => i.name === list.agentName);
          e.agents[index].count = list[e.name];
        });
      });
      setReportsData(keys);
    });
  };

  return (
    <div style={border}>
      <div style={{ width: "100%", display: "flex" }}>
        <Sidebar />
        <div style={{ width: "100%" }}>
          <div className="list-item-container">
            <TopBar />
          </div>
          <div style={{ padding: 20 }}>
            <div className="border-container">
              <div className="nav-container">
                <h2>Executive Reports</h2>
              </div>
              <hr />
              <div style={{ height: "auto" }}>
                <table>
                  <thead
                    style={{
                      height: "50px",
                      backgroundColor: "gray",
                      width: "100%",
                    }}
                  >
                    <tr>
                      <th></th>
                      {reportsData &&
                        reportsData[0].agents?.map((item, id) => {
                          return (
                            <th key={id} style={{ color: "white" }}>
                              {item.name}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody style={{ height: "auto" }}>
                    {reportsData?.map((item, id) => {
                      return (
                        <tr key={id}>
                          <td
                            style={{
                              paddingLeft: "10px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {item.label}
                          </td>
                          {item.agents.map((list, id) => {
                            return (
                              <React.Fragment key={id}>
                                <td
                                  style={{
                                    height: "50px",
                                    paddingLeft: "10px",
                                    fontSize: "18px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {list.count}
                                </td>
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default kareTripExicutiveReports;
