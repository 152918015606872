import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  getAppointmentDetails,
  saveAppointmentDetail,
} from "../../../../services/mykare/AppointmentService";
import { getPatientsDetails } from "../../../../services/mykare/PatientsService";

function useIpDetail({
  userTrxnId,
  detailId,
  centerData,
  doctorData,
  callBackFn,
  treatment,
  handleHide,
  patientDetail,
  patientAadhar,
  locationData,
}) {
  const [ipDetails, setIpDetails] = useState({});
  const [ipStatus, setIpStatus] = useState([
    "SCHEDULED",
    "COMPLETED",
    "RESCHEDULED",
    "CANCELLED",
    "PLANNED",
    "REPLANNED"
  ]);
  const [editableMode, setEditableMode] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedDoctor, setSelectedDoctor] = useState({
    id: 0,
    name: "Dr. Name",
    code: "MYKDR000",
  });

  const [status, setStatus] = useState(["COMPLETE", "RESCHEDULE", "CANCEL_IP"]);

  const [selectedCenter, setSelectedCenter] = useState({
    id: 0,
    name: "Select Center",
    code: "MYKCE000",
  });
  const [detailsId, setDetailsId] = useState(detailId);

  const ipPaymentModes = ["EMI", "CASH", "CASHLESS", "REIMBURSEMENT"];
  useEffect(() => {
    if (detailId) {
      loadAppointmentDetails(detailId);
    }
    if (!ipDetails.treatment) {
      setIpDetails((s) => ({ ...s, treatment: treatment }));
    }
  }, []);

  const handleCenter = (item) => {
    setSelectedCenter(item);
    setIpDetails((s) => ({ ...s, centerCode: item.code }));
  };
  const handleLocation = (item) => {
    setSelectedLocation(item);
    setIpDetails((s) => ({ ...s, locationId: item.id }));
  };

  const handleDoctor = (item) => {
    setSelectedDoctor(item);
    setIpDetails((s) => ({ ...s, doctorCode: item.code }));
  };

  const loadAppointmentDetails = async (detailId) => {
    getAppointmentDetails(detailId)
      .then((res) => {
        if (res?.data?.ipDetails) {
          const myDate = new Date(res.data.ipDetails.arrivalDateTime);
          const center = centerData.filter(
            (item) => item.code === res.data.ipDetails.centerCode
          )[0];

          const location = locationData.filter(
            (item) => item.id === res.data.ipDetails.locationId
          )[0];
          setSelectedLocation(location);

          const doctor = doctorData.filter(
            (item) => item.code === res.data.ipDetails.doctorCode
          )[0];
          const { arrivalDateTime, ...datas } = res?.data?.ipDetails;
          setIpDetails(datas);
          setIpDetails((s) => ({ ...s, arrivalDateTime: myDate }));
          setSelectedCenter(center);
          setSelectedDoctor(doctor);
          setEditableMode(false);
        }
      })
      .catch((error) => {});
  };

  // const saveIpAppointmentDetails = async () => {
  //   const myDate = moment(ipDetails.arrivalDateTime).format().split("+")[0];
  //   const postData = {
  //     userTxnId: userTrxnId,
  //     iPDetail: { ...ipDetails, arrivalDateTime: myDate },
  //   };
  //   const check =
  //     postData.iPDetail.arrivalDateTime &&
  //     postData.iPDetail.treatment &&
  //     postData.iPDetail.doctorCode &&
  //     postData.iPDetail.centerCode &&
  //     postData.iPDetail.ipStatus;
  //   if (check) {
  //     if (patientDetail.age && patientDetail.gender) {
  //       const response = await saveAppointmentDetail(postData);
  //       if (response.status == 200) {
  //         loadAppointmentDetails(response.data.appointmentDetailId);
  //         callBackFn(response.data.appointmentDetailId);
  //         alert("Saved Sucessfully");
  //         handleHide(1);
  //       }
  //     } else {
  //       alert(
  //         "Failed to update !! Please check if you have fill the required details of patient (Age,Gender)"
  //       );
  //       handleHide(7);
  //     }
  //   } else {
  //     alert("Failed to update !! Please check if you have filled all details?");
  //   }
  // };

  const saveIpAppointmentDetails = async () => {
    const formattedArrivalDateTime = moment(ipDetails.arrivalDateTime)
      .format()
      .split("+")[0];
    const postData = {
      userTxnId: userTrxnId,
      iPDetail: { ...ipDetails, arrivalDateTime: formattedArrivalDateTime },
    };

    let isAllDetailsFilled = false;
    
    if (postData.iPDetail.ipStatus === "PLANNED" || postData.iPDetail.ipStatus === "REPLANNED") {
      isAllDetailsFilled = 
        postData.iPDetail.remarks &&
        postData.iPDetail.arrivalDateTime
    }
    else {
      isAllDetailsFilled =
        postData.iPDetail.arrivalDateTime &&
        postData.iPDetail.treatment &&
        postData.iPDetail.doctorCode &&
        postData.iPDetail.centerCode &&
        postData.iPDetail.ipStatus;
    }
    if (isAllDetailsFilled) {
      if (patientDetail.age && patientDetail.gender) {
        if (patientAadhar?.length) {
          try {
            const response = await saveAppointmentDetail(postData);
            if (response.status === 200) {
              loadAppointmentDetails(response.data.appointmentDetailId);
              callBackFn(response.data.appointmentDetailId);
              alert("Saved Successfully");
              window.location.reload()
              handleHide(1);
            }
          } catch (error) {
            console.error("Failed to save appointment details:", error);
            alert("Failed to save appointment details. Please try again.");
          }
        } else {
          alert(
            "Failed to update! Please fill in the required details of the patient (Aadhar Document)."
          );
          handleHide(6);
        }
      } else {
        alert(
          "Failed to update! Please fill Patient Details"
        );
        handleHide(7);
      }
    } else {
      alert("Failed to update! Please fill in all the details.");
    }
    
  };

  return {
    centerData,
    doctorData,
    selectedCenter,
    selectedDoctor,
    ipPaymentModes,
    handleCenter,
    handleDoctor,
    ipDetails,
    setIpDetails,
    editableMode,
    setEditableMode,
    saveIpAppointmentDetails,
    ipStatus,
    selectedLocation,
    locationData,
    handleLocation,
  };
}

export default useIpDetail;
