export function insuranceStatusColor(statusColor) {
  if (statusColor === "PENDING") {
    return "text-3";
  } else if (statusColor === "APPROVED") {
    return "text-8";
  } else {
    return "text-3";
  }
}

export function statusColorFinder(statusColor) {
  if (
    statusColor === "DNP 1" ||
    statusColor === "DNP 2" ||
    statusColor === "DNP 3" ||
    statusColor === "DNP 4" ||
    statusColor === "DNP 5"
  ) {
    return "text-2";
  }
  if (statusColor === "New Lead") {
    return "text-8";
  }
  if (statusColor === "Exhausted") {
    return "text-4";
  }
  if (
    statusColor === "Followup 1" ||
    statusColor === "Followup 2" ||
    statusColor === "Followup 3"
  ) {
    return "text-5";
  }
  if (statusColor === "IPD Scheduled") {
    return "text-6";
  }
  if (statusColor === "Not Interested") {
    return "text-7";
  }
  if (statusColor === "transferred") {
    return "text-8";
  }
  if (statusColor === "out patient") {
    return "text-9";
  }
  if (statusColor === "for admission") {
    return "text-10";
  }
  if (statusColor === "admitted") {
    return "text-11";
  }
  if (statusColor === "Junk") {
    return "text-12";
  }
  if (statusColor === "Call Back") {
    return "text-1";
  }
  if (statusColor === "HOT") {
    return "text-14";
  }
  if (statusColor === "WARM") {
    return "text-15";
  }
  if (statusColor === "COLD") {
    return "text-16";
  }

  return "text-1";
}

export function statusColor(statusColor) {
  if (
    statusColor === "DNP 1" ||
    statusColor === "DNP 2" ||
    statusColor === "DNP 3" ||
    statusColor === "DNP 4" ||
    statusColor === "DNP 5"
  ) {
    return "text-2";
  } else if (statusColor === "New Lead") {
    return "text-8";
  } else if (statusColor === "Exhausted") {
    return "text-4";
  } else if (
    statusColor === "Followup 1" ||
    statusColor === "Followup 2" ||
    statusColor === "Followup 3"
  ) {
    return "text-5";
  } else if (statusColor === "IPD Scheduled") {
    return "text-6";
  } else if (statusColor === "Not Interested") {
    return "text-7";
  } else if (statusColor === "transferred") {
    return "text-8";
  } else if (statusColor === "out patient") {
    return "text-9";
  } else if (statusColor === "for admission") {
    return "text-10";
  } else if (statusColor === "admitted") {
    return "text-11";
  } else if (statusColor === "Call Back") {
    return "text-1";
  } else if (statusColor === "HOT") {
    return "text-14";
  } else if (statusColor === "WARM") {
    return "text-15";
  } else if (statusColor === "COLD") {
    return "text-16";
  } else if (
    statusColor === "Lost Lead" ||
    statusColor === "Junk Lead" ||
    statusColor === "Gone COLD" ||
    statusColor === "Patient Not Interested" ||
    statusColor === "Treatment not Available Abroad" ||
    statusColor === "Medical Travel Not Required" ||
    statusColor === "Duplicate"
  ) {
    return "text-17";
  }
  return "text-1";
}
