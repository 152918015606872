import { getAccordionActionsUtilityClass } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getAllAgents,
  getAllCountries,
  getAllManagers,
  saveAgent,
} from "../../../services/associate/settingsService";

export default function useSettings() {
  const [allManagers, setallManagers] = useState(null);
  const [manager, setManager] = useState([]);
  const [allAgents, setAllAgents] = useState(null);
  const [selectedManagers, setSelectedManagers] = useState([])
  const [selectedManagerIds, setSelectedManagerIds] = useState([]);
  const [form, setform] = useState({
    name: "",
    email: "",
    country: "",
    gender: "",
    state: "",
    managerIds: "",
  });

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burma (Myanmar)",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "Gabon",
    "Gambia",
    "Gaza Strip",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See (Vatican City)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Korea",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn Islands",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of the Congo",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Barthelemy",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "US Virgin Islands",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Wallis and Futuna",
    "West Bank",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const genderList = ["Male", "Female", "Other"];

  const getCountries = async () => {
    const resp = await getAllCountries();
  };

  // const getAllManager = async () => {
  //   const resp = await getAllManagers();
  //   if (resp.data) {
  //     setallManagers(resp.data);
  //   }
  // };


  // edit start

  function getDropdownButtonLabel({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Select";
      return `${values}`;
    }
    return "Select";
  }


  const getAllManager = async () => {
    try {
      const response = await getAllManagers();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return {
          id: i, value: val.id, label: val.name
        }
      })

      console.log("data===================", data);
      setManager(data);
    } catch (error) { }
  };

  const handleSelectManagers = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedManagers(
        selectedManagers.length === manager.length ? [] : manager
      );
      ids = manager.map((item) => item.value);
      // setSelectedManagerIds(
      //   selectedManagers.length === manager.length ? [0] : ids
      // );
      setform((s) => ({ ...s, managerIds: selectedManagers.length === manager.length ? [0] : ids }))
    } else if (event.action === "select-option") {
      if (value.length + 1 === manager.length) {
        value = manager;
      }
      setSelectedManagers(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      // setSelectedManagerIds(ids);
      setform((s) => ({ ...s, managerIds: ids }))
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedManagers(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      // setSelectedManagerIds(ids);
      setform((s) => ({ ...s, managerIds: ids }))
    }
  };




  // edit end


  const getAllAgent = async () => {
    const resp = await getAllAgents();
    if (resp.data) {
      setAllAgents(resp.data.reverse());
    }
  };
  const saveAgents = async (postData) => {
    if (postData.name &&
      postData.email &&
      postData.country &&
      postData.gender &&
      postData.state &&
      postData.managerIds.length) {
      const resp = await saveAgent(postData);
      if (resp.status == 200) {
        alert(`Partner ${postData.name} added...!`);
        setform({
          name: "",
          email: "",
          country: "",
          gender: "",
          state: "",
          managerIds: "",
        });
        getAllAgent();
        setSelectedManagers([]);
      }
    } else {
      alert("Failed to update !! Please fill all fields");
    }
  };

  useEffect(() => {
    getAllManager();
    getAllAgent();
  }, []);

  useEffect(() => {
    console.log("manager============", manager)
  }, [manager]);

  return {
    form,
    setform,
    genderList,
    countries,
    allManagers,
    saveAgents,
    manager,
    setManager,
    allAgents,
    getDropdownButtonLabel,
    selectedManagers,
    handleSelectManagers,
    selectedManagerIds,
    setSelectedManagers
  };
}
