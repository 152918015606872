import { useState } from "react";

export default function useSideBarSettings() {
  const [activeTab, setTabActive] = useState({
    isUserRole: true,
    isAssignRoleActive: false,
  });

  const handleTab = (selected) => {
    if (selected === 0) {
      setTabActive((s) => ({
        isAssignRoleActive: false,
        isUserRole: true,
      }));
    } else if (selected === 1) {
      setTabActive((s) => ({
        isUserRole: false,
        isAssignRoleActive: true,
      }));
    }
  };

  return {
    handleTab,
    activeTab,
  };
}
