import axios from "axios";
import moment from "moment";
import "../../Styles/Common.css";
import "../../Styles/sideBar.css";
import "../../Styles/Pagination.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import idFormatter from "../../util/idFormatter";
import ListItem from "@material-ui/core/ListItem";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as appConstants from "../../util/AppConstants";
import ListItemText from "@material-ui/core/ListItemText";
import { getFilteredEnquiries } from "../../services/associate/enquiryService";
import { getPartnerEnquiryReport } from "../../services/associate/enquiryService";
import { getUserCode, getUserRole } from "../../store/DataStore";
import { checkPermission } from './../../util/permissionUtils';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginLeft: 20,
    marginTop: 10,
    backgroundColor: theme.palette.background.paper,
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
  dividerInset: {
    margin: `5px 0 0 1000px`,
    marginRight: "12px",
  },
}));

const useHome = () => {
  let history = useHistory();
  const location = useLocation();
  const [perPage] = useState(20);
  const [offset, setOffset] = useState(0);
  const [homeData, setHomeData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [arrivalCount, setArrivalCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [statsLoaded, setStatsLoaded] = useState(false);
  const [appointmentCount, setAppointmentCount] = useState(0);

  const [showReportUI, setShowReportUI] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    if (location.state) {
      if (location.state.offset) {
        setOffset(location.state.offset);
        getData(location.state.offset);
      }
    }
  }, []);

  const [color, setColor] = useState("red");

  const [pageData, setPageData] = useState(null);
  const [allData, setAllData] = useState(null);

  const [selectType, setSelectType] = useState({
    list: ["all", "arrival", "appointment", "smo"],
    selected: "all",
  });
  const handleSelectTypeChange = (e) => {
    const type = e.target.value;
    setSelectType((s) => ({ ...s, selected: type }));
    loadFilteredData(searchItem, type);
  };

  const [selectStatus, setSelectStatus] = useState({
    list: ["all"],
    selected: "",
  });
  const handleSelectStatusChange = (e) => {
    setSelectStatus((s) => ({ ...s, selected: e.target.value }));
  };

  const [searchItem, setSearchItem] = useState("");
  const handleSearchItemChange = (e) => setSearchItem(e.target.value);

  useEffect(() => {
    setStatsLoaded(false);
    getStatistics();
  }, []);

  useEffect(() => {
    setDataLoaded(false);
    getData(offset);
  }, [offset]);

  useEffect(() => {
    if (!pageData && !allData) return;
    loadFilteredData(searchItem, selectType.selected);
  }, [searchItem]);

  const loadFilteredData = async (searchItem, enquiryType) => {
    const response = await getFilteredEnquiries(
      0,
      perPage,
      enquiryType,
      searchItem
    );
    if (response) {
      const data = response.data.enquiries;
      setPageData(data);
      setPageCount(Math.ceil(response.data.count / perPage));
    }
  };

  useEffect(() => {
    if (!!pageData) {
      const postData = pageData.map((data, index) => (
        <div
          key={index}
          className="row pointer scroll"
          onClick={() => handleClickTale(data)}
        >
          <div className="container col-2 m-0">
            <ListItem>
              <ListItemText
                primary={moment(data.dateTime).format("DD-MM-YYYY")}
              />
            </ListItem>
          </div>
          <div className="container col-2 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            >
              {`ID : ${idFormatter(data.patientId)}`}
            </Typography>

            <ListItem>
              <ListItemText primary={`Name: ${data.patientName}`} />
            </ListItem>
          </div>
          {checkPermission("PARTNER_AGENT_VIEW") && (
            <div class="container col-2 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              >
                {`ID : ${idFormatter(data.agentId)}`}
              </Typography>
              <ListItem>
                <ListItemText
                  primary={`Name:${getName(
                    data.agentName,
                    data.enquiryByAgentName
                  )}`}
                />
              </ListItem>
            </div>
          )}
          <div class="container col-2 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            >
              {`Enquiry Id : ${idFormatter(data.id)}`}
            </Typography>
            <ListItem>
              <ListItemText
                primary={`${data.type && data.type.toUpperCase()}`}
              />
            </ListItem>
          </div>
          <div class="container col-2 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={`${data.centerName}`} />
            </ListItem>
          </div>

          <div class="container col-2 m-0 capitalize">
            <p
              className={
                data.status === "transferred" ||
                data.status === "replied" ||
                data.status === "completed"
                  ? "text-2"
                  : "text-1"
              }
            >
              {data.status}
            </p>
          </div>

          <hr />
        </div>
      ));
      setHomeData(postData);
    }
  }, [pageData]);

  const getName = (agentName, enquiryByAgentName) => {
    if (agentName === null && !!enquiryByAgentName) {
      return enquiryByAgentName;
    }
    if (!!agentName && enquiryByAgentName === null) {
      return agentName;
    }
    if (!!agentName && !!enquiryByAgentName) {
      return `${enquiryByAgentName} (${agentName})`;
    }
  };
  const getData = async (offset) => {
    getFilteredEnquiries(offset, perPage, selectType.selected)
      .then((res) => {
        const data = res.data.enquiries;
        setPageCount(Math.ceil(res.data.count / perPage));
        setPageData(data);
        setAllData(data);
      })
      .catch((error) => {})
      .finally(() => {
        setDataLoaded(true);
      });
  };

  const getStatistics = async () => {
    const userRole = getUserRole();
    const userCode = getUserCode();
    axios
      .get(
        `${appConstants.APP_URL}/internal/partner/getStatistics?role=${userRole}&userCode=${userCode}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        const data = res.data;
        setTotalCount(data.totalCount);
        setPendingCount(data.pendingCount);
        setArrivalCount(data.arrivalCount);
        setAppointmentCount(data.appointmentCount);
      })
      .catch((error) => {})
      .finally(() => {
        setStatsLoaded(true);
      });
  };

  const handleDownloadReport = async () => {
    if (fromDate && toDate) {
      const startDate = moment(fromDate).format().split("T")[0];
      const endDate = moment(toDate).format().split("T")[0];
      try {
        const response = await getPartnerEnquiryReport(startDate, endDate);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage);
  };

  function handleClickTale(enquiryData) {
    history.push({
      pathname: "/enquiryDetails",
      state: { enquiryDetails: enquiryData, offset },
    });
  }

  const classes = useStyles();

  return {
    showReportUI,
    setShowReportUI,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    handleDownloadReport,
    classes,
    totalCount,
    pendingCount,
    arrivalCount,
    appointmentCount,
    handlePageClick,
    handleClickTale,
    getStatistics,
    pageCount,
    offset,
    dataLoaded,
    statsLoaded,
    homeData,
    setDataLoaded,
    useStyles,
    searchItem,
    handleSearchItemChange,
    selectType,
    handleSelectTypeChange,
    selectStatus,
    handleSelectStatusChange,
  };
};
export default useHome;
