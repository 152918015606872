import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import expiredIcon from "./expired.png";
import { useHistory } from "react-router-dom";

function SessionExpired() {
  const history = useHistory();
  const sessionExpired = () => {
    history.push("/");
  };
  return (
    <Grid
      sx={{
        width: "100%",
        minHeight: "100vh",
        background: "linear-gradient(to bottom, #bdfef6, #fafbce)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        sx={{
          width: "700px",
          height: "480px",
          backgroundColor: "white",
          borderRadius: "25px",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
        
        }}
      >
        <Grid
          sx={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop:"30px"
           
          }}
        >
          <Grid
            container
            justifyContent={"center"}
            sx={{ width: "100%",height:"240px"}}
            alignItems={"center"}
            bgcolor={"white"}
    

          >
            <Typography
              sx={{
                color: "#546986",
                fontSize: "200px",
                fontWeight: 700,
               
              }}
            >
              4
            </Typography>
            <Box
              component="img"
              sx={{
                height: 160,
                width: 160,
                marginBottom: "20px",
              }}
              alt="expired-con"
              src={expiredIcon}
            />
            <Typography
              sx={{
                color: "#546986",
                fontSize: "200px",
                fontWeight: 700,
              
              }}
            >
              8
            </Typography>
          </Grid>
          <Typography
            sx={{ color: "#546986", fontSize: "40px", fontWeight: 700 }}
          >
            Your session has expired.!
          </Typography>

          <Typography
            sx={{
              color: "#546986",
              fontSize: "20px",
              fontWeight: 700,
              paddingBottom: "10px",
            }}
          >
            Please log in again to continue.
          </Typography>
          <Grid
            onClick={sessionExpired}
            sx={{
              backgroundColor: "#1764e8",
              width: "160px",
              borderRadius: "100px",
              color: "white",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Button sx={{ color: "white", fontSize: "15px", fontWeight: 700,width:"200px" }}>
              <Typography>Go To Login</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SessionExpired;
