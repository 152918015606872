import axios from "axios";
import "../../../Styles/Common.css";
import "../../../Styles/sideBar.css";
import "../../../Styles/Pagination.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import * as ReactBootstrap from "react-bootstrap";
import ListItem from "@material-ui/core/ListItem";
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as appConstants from "../../../util/AppConstants";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import "../../Enquiry/Enquiry.css";
import {
  statusColor,
  statusColorFinder,
} from "../../../util/statusColorFinder";

import {
  getEnquiryStatistics,
  getDownloadReportData,
  getSources,
} from "../../../services/mykare/enquiryService";

import {
  getFilteredEnquiries,
  getKaretripUserTrxnStatuses,
  getNotificationCount,
  getNotifications,
  updateEnquiry,
} from "../../../services/karetrip/enquiryService";

import Tooltip from "@material-ui/core/Tooltip";
import { useStyles } from "../../../components/multi-select";
import { getTreatments } from "../../../services/mykare/dataService";
import { LocationSearching, SettingsCellSharp } from "@material-ui/icons";
import { getAllLocations } from "../../../services/mykare/centerService";
import CountComponent from "../../../components/CountContainer";
import { checkPermission } from "../../../util/permissionUtils";
import {
  getAuthHeader,
  getUserCode,
  getUserName,
  getUserRole,
} from "../../../store/DataStore";
import {
  getAllKaretripCountries,
  getAllKaretripSources,
  getAllKaretripTreatments,
} from "../../../services/karetrip/centerService";
import { Avatar } from "@mui/material";
import { getRandomColor } from "../../../util/randomColorPicker";

const useKareTripEnquiry = () => {
  let history = useHistory();
  const location = useLocation();
  const role = getUserRole();
  const code = getUserCode();
  const [perPage] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [homeData, setHomeData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [filterLoaded, setFilterLoaded] = useState(true);
  const [enquiryStats, setEnquiryStats] = useState(null);
  const [reload, setReload] = useState(false);
  const [searchEnquiryFromDate, setSearchEnquiryFromDate] = useState(
    new Date()
  );
  const [searchEnquiryToDate, setSearchEnquiryToDate] = useState(new Date());
  const [searchFollowupFromDate, setSearchFollowupFromDate] = useState(
    new Date()
  );
  const [searchFollowupToDate, setSearchFollowupToDate] = useState(new Date());
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [selectedStatusIds, setSelectedStatusIds] = useState([0]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([0]);
  const [treatmentData, setTreatmentData] = useState([]);
  const [selectedCountryList, setSelectedCountryList] = useState([]);
  const [selectedCountryIds, setSelectedCountryIds] = useState([]);
  const [activeData, setActiveData] = useState({ isActive: false });

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState([0]);
  const [locationData, setLocationData] = useState([]);

  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedSourceIds, setSelectedSourceIds] = useState([0]);
  const [sourceData, setSourceData] = useState([]);

  const [isClear, setIsClear] = useState(false);

  const pageFilters = ["20", "50", "100", "200"];
  const [selectedPageFilter, setSelectedPageFilter] = useState("20");

  const [userData, setUserData] = useState(null);

  const [enquiryDateFilter, setEnquiryDateFilter] = useState([
    "All",
    "Today",
    "This Month",
    "Yesterday",
    "Choose Date",
    "Previous Month",
  ]);
  const [countryList, setCountryList] = useState([]);

  const [type, setType] = useState([
    { id: 0, label: "All", value: "ALL" },
    { id: 1, label: "Doctor", value: "DOCTOR" },
    { id: 2, label: "Hospital", value: "HOSPITAL" },
    { id: 3, label: "Treatment", value: "TREATMENT" },
    { id: 4, label: "Partner Request", value: "PARTNER_REQUEST" },
    { id: 5, label: "Medical Opinion", value: "MEDICAL_OPINION" },
  ]);

  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedTypeIds, setSelectedTypeIds] = useState([0]);

  const followUpDateFilter = ["All", "Today", "Tomorrow", "Choose Date"];
  const [selectedEnquiryDateFilter, setSelectedEnquiryDateFilter] =
    useState("All");
  const [selectedFollowupDateFilter, setSelectedFollowupDateFilter] =
    useState("All");
  const [assignedUser, setAssignedUser] = useState({
    list: [{ id: 0, name: "All", code: "ALL" }],
    selected: "All",
    selectedCode: "ALL",
  });

  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationAllCount, setNotificationAllCount] = useState(0);
  const [notificationData, setNotificationData] = useState([]);
  const [isloader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = () => {
    if (taskData.length >= notificationCount) {
      setHasMore(false);
      return;
    }
    setPageNo(pageNo + 1);
    loadMoreNotification(pageNo + 1);
  };

  const loadMoreNotification = async (pageNo) => {
    setLoader(true);
    let posData = {
      pageNo: pageNo,
      pageSize: 10,
    };
    const response = await getNotifications(posData, code);
    if (response.status == 200) {
      setLoader(false);
      setNotificationData(response.data.notifications);
      setNotificationCount(response.data.notificationCount);
    } else {
      setNotificationCount(0);
    }
    setLoader(false);
  };

  const loadAllNotifications = async (code) => {
    setLoader(true);
    let posData = {
      pageNo: 0,
      pageSize: 10,
    };
    const response = await getNotifications(posData, code);
    if (response.status == 200) {
      setLoader(false);
      setNotificationData(response.data.notifications);
      setNotificationCount(response.data.notificationCount);
    } else {
      setNotificationCount(0);
    }
    setLoader(false);
  };

  function getDropdownButtonLabel({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Select";
      return `${values}`;
    }
    return "Select";
  }

  function getDropdownButtonLabel({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Select";
      return `${values}`;
    }
    return "Select";
  }
  function getDropdownButtonLabel2({ value }) {
    if (value) {
      const values =
        value.length !== 0 ? value.map((val) => val.label) : "Select";
      return `${values}`;
    }
    return "Select";
  }

  const useTableStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      marginTop: 10,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));

  const tooltipStyles = {
    tooltip: {
      fontSize: "20px",
      borderRadius: "18px",
      boxShadow: "0 20px 80px 0",
      backgroundColor: "#FFFFE0",
      color: "black",
    },
  };

  const loadKaretripCountries = async () => {
    try {
      getAllKaretripCountries().then((res) => {
        setCountryList(res);
      });
    } catch (error) {}
  };

  const [pageData, setPageData] = useState(null);
  const [allData, setAllData] = useState(null);

  const clearAllFilter = () => {
    setAssignedUser({ list: [{ id: 0, name: "All", code: "ALL" }] });
    setSelectedPageFilter(20);
    setSelectedStatusIds([0]);
    setSelectedStatuses([]);
    setSelectedSources([]);
    setSelectedSourceIds([0]);
    setSelectedLocations([]);
    setSelectedLocationIds([0]);
    setSelectedEnquiryDateFilter("All");
    setSelectedFollowupDateFilter("All");
    setSelectedTreatmentIds([0]);
    setSelectedTreatments([]);
    setSearchPhone("");
    setSearchItem("");
    setSearchEnquiryId("");
    setSearchEnquiryFromDate(new Date());
    setSearchEnquiryToDate(new Date());
    setSearchFollowupFromDate(new Date());
    setSearchFollowupToDate(new Date());
    setSelectedCountryIds([0]);
    setSelectedCountryList([]);
    setSelectedTypes([]);
    setIsClear((s) => !s);
  };

  const handleSelectSources = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedSources(
        selectedSources.length === sourceData.length ? [] : sourceData
      );
      ids = sourceData.map((item) => item.value);
      setSelectedSourceIds(
        selectedSources.length === sourceData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === sourceData.length) {
        value = sourceData;
      }
      setSelectedSources(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedSourceIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedSources(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedSourceIds(ids);
    }
  };

  const handleSelectLocations = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedLocations(
        selectedLocations.length === locationData.length ? [] : locationData
      );
      ids = locationData.map((item) => item.value);
      setSelectedLocationIds(
        selectedLocations.length === locationData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === locationData.length) {
        value = locationData;
      }
      setSelectedLocations(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedLocationIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedLocations(data);
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedLocationIds(ids);
    }
  };

  const handleSelectTreatments = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedTreatments(
        selectedTreatments.length === treatmentData.length ? [] : treatmentData
      );
      ids = treatmentData.map((item) => item.value);
      setSelectedTreatmentIds(
        selectedTreatments.length === treatmentData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === treatmentData.length) {
        value = treatmentData;
      }
      setSelectedTreatments(value);
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedTreatmentIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== "ALL");
      setSelectedTreatments(data);
      ids = data.length === 0 ? ["ALL"] : data.map((item) => item.value);
      setSelectedTreatmentIds(ids);
    }
  };
  const handleSelectTypes = (value, event) => {
    var ids;
    if (event.option.value === "ALL") {
      setSelectedTypes(selectedTypes.length === type.length ? [] : type);
      ids = type.map((item) => item.value);
      setSelectedTypeIds(
        selectedTypeIds.length === type.length ? ["ALL"] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === type.length) {
        value = type;
      }
      ids = value.length === 0 ? [0] : value.map((item) => item.value);
      setSelectedTypeIds(ids);
      setSelectedTypes(value);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== "ALL");
      ids = data.length === 0 ? [0] : data.map((item) => item.value);
      setSelectedTypes(data);
      setSelectedTypeIds(ids);
    }
  };

  useEffect(() => {
    getData(
      offset,
      selectedPageFilter,
      searchItem,
      searchPhone,
      selectedStatusIds,
      "ALL",
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds
    );
  }, [selectedTreatmentIds]);

  useEffect(() => {
    getData(
      offset,
      selectedPageFilter,
      searchItem,
      searchPhone,
      selectedStatusIds,
      "ALL",
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds
    );
  }, [selectedPageFilter]);

  useEffect(() => {
    getData(
      offset,
      selectedPageFilter,
      searchItem,
      searchPhone,
      selectedStatusIds,
      "ALL",
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds
    );
  }, [selectedTypeIds]);

  useEffect(() => {
    getData(
      offset,
      selectedPageFilter,
      searchItem,
      searchPhone,
      selectedStatusIds,
      "ALL",
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds
    );
  }, [selectedStatusIds]);

  useEffect(() => {
    getData(
      offset,
      selectedPageFilter,
      searchItem,
      searchPhone,
      selectedStatusIds,
      "ALL",
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds
    );
  }, [selectedSourceIds]);

  useEffect(() => {
    getData(
      offset,
      selectedPageFilter,
      searchItem,
      searchPhone,
      selectedStatusIds,
      "ALL",
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds
    );
  }, [selectedLocationIds]);

  const handleSelectStatuses = (value, event) => {
    var ids;
    if (event.option.value === 0) {
      setSelectedStatuses(
        selectedStatuses.length === statusData.length ? [] : statusData
      );
      ids = statusData.map((item) => item.value);
      setSelectedStatusIds(
        selectedStatuses.length === statusData.length ? [0] : ids
      );
    } else if (event.action === "select-option") {
      if (value.length + 1 === statusData.length) {
        value = statusData;
      }
      setSelectedStatuses(value);
      ids = value.map((item) => item.value);
      setSelectedStatusIds(ids);
    } else if (event.action === "deselect-option") {
      const data = value.filter((o) => o.value !== 0);
      setSelectedStatuses(data);
      ids = data.map((item) => item.value);
      setSelectedStatusIds(ids);
    }
    loadFilteredData(
      searchItem,
      searchPhone,
      ids,
      assignedUser.selectedCode,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds
    );
  };

  const [selectStatus, setSelectStatus] = useState({
    list: [],
    selected: "ALL",
  });

  const loadStatus = async () => {
    try {
      const response = await getKaretripUserTrxnStatuses();
      // setStatusData(response.data);
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response.data);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setStatusData(data);
    } catch (error) {}
  };

  const loadLocations = async () => {
    try {
      const response = await getAllLocations();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setLocationData(data);
    } catch (error) {}
  };

  const loadNotificationCount = async () => {
    try {
      const respone = await getNotificationCount();
      if (respone.status == 200) {
        setNotificationAllCount(respone.data.notificationCount);
      } else {
        notificationAllCount(0);
      }
    } catch (error) {
      setNotificationAllCount(0);
    }
  };

  const loadTreatments = async () => {
    try {
      const responseData = await getAllKaretripTreatments();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, responseData);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setTreatmentData(data);
    } catch (error) {}
  };

  const loadSources = async () => {
    try {
      const response = await getAllKaretripSources();
      var status = [{ id: 0, name: "ALL" }];
      status.push.apply(status, response);
      const data = status.map((val, i) => {
        return { id: i, value: val.id, label: val.name };
      });
      setSourceData(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (location.state) {
      if (
        location.state.offset ||
        location.state.selectedStatusIds ||
        location.state.selectedTreatmentIds ||
        location.state.selectedSourceIds ||
        location.state.selectedLocationIds
      ) {
        setSelectedStatuses(location.state.selectedStatuses);
        setSelectedStatusIds(location.state.selectedStatusIds);
        setSelectedTreatments(location.state.selectedTreatments);
        setSelectedTreatmentIds(location.state.selectedTreatmentIds);
        setSelectedSources(location.state.selectedSources);
        setSelectedSourceIds(location.state.selectedSourceIds);

        setSelectedLocations(location.state.selectedLocations);
        setSelectedLocationIds(location.state.selectedLocationIds);

        setOffset(location.state.offset);
        setSelectedEnquiryDateFilter(location.state.selectedEnquiryDateFilter);
        setSearchEnquiryFromDate(location.state.searchEnquiryFromDate);
        setSearchEnquiryToDate(location.state.searchEnquiryToDate);
        setSelectedFollowupDateFilter(
          location.state.selectedFollowupDateFilter
        );
        setSearchFollowupFromDate(location.state.searchFollowupFromDate);
        setSearchFollowupToDate(location.state.searchFollowupToDate);
        // setAssignedUser(location.state.assignedUser);
        // setSelectedSource(location.state.selctedSource);
        setReload(true);
      }
    } else {
      setReload(true);
    }
  }, []);

  const handleAssignedToChange = (assignedCode, assignedName) => {
    setAssignedUser((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedCode,
    }));
  };

  const [searchItem, setSearchItem] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [searchEnquiryId, setSearchEnquiryId] = useState("");

  const handleSearchItemChange = (e) => {
    setSearchItem(e.target.value);
  };

  const handleSearchPhone = (e) => {
    setSearchPhone(e.target.value);
  };

  const handleSearchEnquiryId = (e) => {
    setSearchEnquiryId(e.target.value);
  };
  const handleCountry = (value) => {
    setSelectedCountryList(value);
    setReload(true);
  };

  const getTotalEnquiries = async () => {
    try {
      getEnquiryStatistics().then((res) => {
        setEnquiryStats(res.data);
      });
    } catch (error) {}
  };

  useEffect(() => {
    loadStatus();
    loadTreatments();
    getAgentData();
    loadSources();
    loadLocations();
    loadKaretripCountries();
    loadNotificationCount();
  }, []);

  // const loadAssignedUsers = async () => {
  //   try {
  //     await getAgentData.then((res) => {
  //       let users = [{ id: 0, name: "All", code: "ALL" }];
  //       users.push.apply(users, res.data.users);
  //       users.push({ id: -1, name: "Not Assigned", code: "NOT_ASSIGNED" });
  //       setAssignedUser((s) => ({ ...s, list: users }));
  //     });
  //   } catch (error) {}
  // };

  const getAgentData = async () => {
    try {
      const data = await axios.get(
        appConstants.APP_URL + `/user/intlSalesTeam/all`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      let users = [{ id: 0, name: "All", code: "ALL" }];
      users.push.apply(users, data.data.users);
      users.push({ id: -1, name: "Not Assigned", code: "NOT_ASSIGNED" });
      setAssignedUser((s) => ({ ...s, list: users }));
      return data;
    } catch (error) {}
  };

  useEffect(() => {
    if (reload) {
      setDataLoaded(false);
      getTotalEnquiries();
      getData(
        offset,
        selectedPageFilter,
        searchItem,
        searchPhone,
        selectedStatusIds,
        assignedUser.selectedCode,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedSourceIds,
        selectedLocationIds
      );
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (!pageData && !allData) return;

    if (searchItem === "") {
      setReload(true);
    } else {
      getData(
        offset,
        selectedPageFilter,
        searchItem,
        searchPhone,
        selectedStatusIds,
        assignedUser.selectedCode,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedSourceIds,
        selectedLocationIds
      );
    }
  }, [searchItem]);

  useEffect(() => {
    if (!pageData && !allData) return;

    if (searchPhone === "") {
      setReload(true);
    } else {
      getData(
        offset,
        selectedPageFilter,
        searchItem,
        searchPhone,
        selectedStatusIds,
        assignedUser.selectedCode,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedSourceIds,
        selectedLocationIds
      );
    }
  }, [searchPhone]);

  useEffect(() => {
    if (!pageData && !allData) return;

    if (searchEnquiryId === "") {
      setReload(true);
    } else {
      getData(
        offset,
        selectedPageFilter,
        searchItem,
        searchPhone,
        selectedStatusIds,
        assignedUser.selectedCode,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedSourceIds,
        selectedLocationIds,
        searchEnquiryId
      );
    }
  }, [searchEnquiryId]);

  const loadFilteredData = async (
    searchItem,
    searchPhone,
    statusIds,
    assignedTo,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    followUpFromDate,
    followUpToDate,
    treatmentIds,
    sourceIds,
    locationIds
  ) => {
    const enquiryFilterFromDate = moment(enquiryFromDate)
      .format()
      .split("+")[0];
    const enquiryFilterToDate = moment(enquiryToDate).format().split("+")[0];
    const followupFilterFromDate = moment(followUpFromDate)
      .format()
      .split("+")[0];
    const followupFilterToDate = moment(followUpToDate).format().split("+")[0];

    const response = await getFilteredEnquiries(0, selectedPageFilter);

    if (response) {
      const data = response.data.enquiries;
      setTotalCount(response.data.count);
      setPageData(data);
      setPageCount(Math.ceil(response.data.count / selectedPageFilter));
    }
  };

  const refreshTable = () => {
    setDataLoaded(true);
    getData(
      offset,
      selectedPageFilter,
      searchItem,
      searchPhone,
      selectedStatusIds,
      assignedUser.selectedCode,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      selectedTreatmentIds,
      selectedSourceIds,
      selectedLocationIds
    );
    getTotalEnquiries();
    setDataLoaded(false);
  };

  const handleClickOpen = (data) => {
    setActiveData({
      isActive: true,
      data: data,
    });
  };

  const openInNewTab = (enquiryData) => {
    var newWindow = window.open(
      `KaretripEnquiryDetail/${enquiryData.enquiryId}`,
      "_blank"
    );
    newWindow.state = {
      statusColorFinder: statusColorFinder(),
      enquiryDetails: enquiryData,
      offset,
      selectedStatuses,
      selectedStatusIds,
      selectedTreatmentIds,
      selectedTreatments,
      selectedEnquiryDateFilter,
      searchEnquiryFromDate,
      searchEnquiryToDate,
      selectedFollowupDateFilter,
      searchFollowupFromDate,
      searchFollowupToDate,
      assignedUser,
      selectedSourceIds,
      selectedLocationIds,
    };
  };

  const postAssignTo = async (buddyCode) => {
    const postData = {
      userTxnId:buddyCode?.enquiryId,
      assignTo: buddyCode?.agentCode,
    };
    try {
      const response = await updateEnquiry(postData);
      if (response.status == 200) {
        refreshTable();
        setActiveData({ isActive: false })
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!!pageData) {
      const postData = pageData.map((data, index) => {
        return (
          <div
            key={index}
            className="row pointer scroll"
            onClick={() => {
              handleClickTale(data);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {checkPermission("LEADS_SL_NO_FIELD") && (
              <div className="col-1 m-0">
                {offset === 0 ? index + 1 : offset * 20 + (index + 1)}
              </div>
            )}

            {checkPermission("LEADS_ENQUIRY_DATE_FIELD") && (
              <div className="col-1 m-0">
                <ListItem>
                  <ListItemText primary={`${data.enquiryDate.split("T")[0]}`} />
                </ListItem>
                <p style={{ textAlign: "left" }}>{`${
                  data.enquiryDate.split("T")[1].split(".")[0]
                }`}</p>
              </div>
            )}

            <div className="col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText primary={` ${data.patientName} `} />
              </ListItem>
            </div>

            {checkPermission("LEADS_PHONE_NUMBER_FIELD") && (
              <div class="col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>
                {checkPermission("LEADS_PHONE_NUMBER_FIELD") && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={
                          data.phoneNumber
                            ? ` ${data.countryCode + data.phoneNumber}`
                            : "N/A"
                        }
                      />
                    </ListItem>
                    {data.alternateContact && (
                      <p
                        style={{
                          textAlign: "left",
                        }}
                      >{`${data.alternateContact}`}</p>
                    )}
                  </>
                )}
              </div>
            )}

            {/* Country */}
            <div className="col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText primary={` ${data.country} `} />
              </ListItem>
            </div>

            <div className="col-1 m-0">
              <ListItem>
                <ListItemText primary={`# ${data.enquiryId}`} />
                <br />
                <button
                  style={{ width: 40, height: 30 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openInNewTab(data);
                  }}
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/722/722216.png"
                    style={{ width: 20, height: 20 }}
                  />
                </button>
              </ListItem>
              {checkPermission("LEADS_ASSIGNED_FIELD") && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    maxHeight: 60,
                    minHeight: 40,
                    justifyContent: "space-between",
                  }}
                >
                  {/* {assignedUser.list.map((val, i) => {
                    if (val.name !== "All") {
                      return (
                        <Tooltip key={i} title={val.name}>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              postAssignTo(data.enquiryId, val.code);
                            }}
                            style={{ backgroundColor: "white" }}
                          >
                            <Avatar
                              sx={{
                                height: "30px",
                                width: "30px",
                                fontSize: "16px",
                                backgroundColor: getRandomColor(i),
                              }}
                            >
                              {val.name.charAt(0).toUpperCase()}
                            </Avatar>
                          </button>
                        </Tooltip>
                      );
                    }
                  })} */}
                </div>
              )}
            </div>

            {checkPermission("LEADS_ASSIGNED_FIELD") && (
              <div className="col-1 m-0">
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                ></Typography>

                <ListItem style={{ display: "flex", flexDirection: "column" }}>
                  <ListItemText
                    primary={` ${
                      data.assignToName ? data.assignToName : "Not Assigned"
                    }`}
                  />

                  {checkPermission("LEADS_ASSIGNED_FIELD") && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        maxHeight: 60,
                        minHeight: 40,
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: 70,
                          height: 30,
                          backgroundColor: "#06c6a2",

                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          color: "white",
                          fontSize: "14px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClickOpen(data);
                        }}
                      >
                        Assign
                      </div>
                    </div>
                  )}
                </ListItem>
              </div>
            )}

            <div className="col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText primary={` ${data.type}`} />
              </ListItem>
            </div>

            <div className="col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>

              <ListItem>
                <ListItemText primary={` ${data.treatment}`} />
              </ListItem>
            </div>

            <div class="col-1 m-0 capitalize">
              <p className={statusColor(data.status)}>
                <ListItem>
                  <ListItemText
                    className="status-bold"
                    primary={
                      data?.intent
                        ? `${data?.status} -- ${data?.intent}`
                        : data?.status
                        ? data?.status
                        : ""
                    }
                  />
                </ListItem>
              </p>
            </div>

            <div class="col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText
                  primary={`${data.followUpDate ? data.followUpDate : " N/A"}`}
                />
              </ListItem>
            </div>

            {/* {checkPermission("LEADS_ORIGIN_FIELD") && ( */}

            {/* )} */}

            <div class="container col-1 m-0">
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText primary={`${data.source && data.source}`} />
              </ListItem>
            </div>

            <div class="col-1 m-0" style={{ padding: "10px" }}>
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                display="block"
                variant="caption"
              ></Typography>
              <ListItem>
                <ListItemText primary={` ${data.location}`} />
              </ListItem>
            </div>
            {/* {checkPermission("LEAD_PAYMENT_FIELD") && (
                            <div class="container col-1 m-0">
                                <Typography
                                    className={classes.dividerFullWidth}
                                    color="textSecondary"
                                    display="block"
                                    variant="caption"
                                ></Typography>
                                <ListItem>
                                    <ListItemText
                                        primary={` ${data.paymentMode ? data.paymentMode : ""}`}
                                    />
                                </ListItem>
                            </div>
                        )} */}
            <hr />
          </div>
        );
      });
      setHomeData(postData);
    }
  }, [pageData]);

  useEffect(() => {
    // loadFilteredData(
    //   searchItem,
    //   searchPhone,
    //   selectedStatusIds,
    //   assignedUser.selectedCode,
    //   selectedEnquiryDateFilter,
    //   searchEnquiryFromDate,
    //   searchEnquiryToDate,
    //   selectedFollowupDateFilter,
    //   searchFollowupFromDate,
    //   searchFollowupToDate,
    //   selectedTreatmentIds,
    //   selectedSourceIds,
    //   selectedLocationIds
    // );
  }, [selectedPageFilter]);

  useEffect(() => {
    if (isClear) {
      getData(
        offset,
        selectedPageFilter,
        searchItem,
        searchPhone,
        selectedStatusIds,
        "ALL",
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        selectedTreatmentIds,
        selectedSourceIds,
        selectedLocationIds
      );
    }
  }, [isClear]);

  const getData = async (
    offset,
    selectedPageFilter,
    searchItem,
    searchPhone,
    selectedStatusIds,
    assignedTo,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    followUpFromDate,
    followUpToDate,
    selectedTreatmentIds,
    selectedSourceIds,
    selectedLocationIds,
    searchEnquiryId
  ) => {
    const postData = {
      pageNo: offset,
      pageSize: selectedPageFilter,
      assignedTo: assignedTo,
      enquiryDateFilter: enquiryDateFilter,
      followUpDateFilter: enquiryFromDate,
      role,
      code,
      enquiryFromDate: enquiryFromDate,
      enquiryToDate: enquiryToDate,
      followUpDateFilter: followUpDateFilter,
      followUpFromDate: followUpFromDate,
      followUpToDate: followUpToDate,
      patientName: searchItem,
      phone: searchPhone,
      enquiryId: searchEnquiryId,
    };

    if (selectedCountryList.id) {
      postData.countryIds = [selectedCountryList.id];
    }

    if (selectedTreatmentIds.length !== 0) {
      postData.treatmentIds = selectedTreatmentIds;
    }

    if (selectedStatusIds.length !== 0) {
      postData.statusIds = selectedStatusIds;
    }

    if (selectedTypeIds.length !== 0) {
      postData.categories = selectedTypeIds;
    }

    if (selectedSourceIds.length !== 0) {
      postData.sourceIds = selectedSourceIds;
    }

    if (selectedLocationIds.length !== 0) {
      postData.locationIds = selectedLocationIds;
    }

    const clearAllFilter = () => {
      setAssignedUser({ list: [{ id: 0, name: "All", code: "ALL" }] });
      setSelectedPageFilter(20);
      setSelectedStatusIds([0]);
      setSelectedStatuses([]);
      setSelectedSources([]);
      setSelectedSourceIds([0]);
      setSelectedLocations([]);
      setSelectedLocationIds([0]);
      setSelectedEnquiryDateFilter("All");
      setSelectedFollowupDateFilter("All");
      setSelectedTreatmentIds([0]);
      setSelectedTreatments([]);
      setSelectedCountryList([]);
      setSelectedTypes([]);

      setSelectedCountryIds([0]);
      setSearchItem("");
      setSearchEnquiryFromDate(new Date());
      setSearchEnquiryToDate(new Date());
      setSearchFollowupFromDate(new Date());
      setSearchFollowupToDate(new Date());
      setIsClear((s) => !s);
      setSelectedAssignedTo([]);
    };

    getFilteredEnquiries(postData)
      .then((res) => {
        const data = res.data.enquiries;
        // console.log("data 1", data);
        setTotalCount(res.data.count);
        setPageCount(Math.ceil(res.data.count / selectedPageFilter));
        setPageData(data);
        setAllData(data);
      })
      .catch((error) => {})
      .finally(() => {
        setDataLoaded(true);
      });
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage);
    if (selectedPage != offset) {
      setReload(true);
    }
  };

  function handleClickTale(enquiryData) {
    history.push({
      pathname: "/KaretripEnquiryDetail/" + enquiryData.enquiryId,
      state: {
        statusColorFinder: statusColorFinder(),
        enquiryDetails: enquiryData,
        offset,
        selectedStatuses,
        selectedStatusIds,
        selectedTreatmentIds,
        selectedTreatments,
        selectedEnquiryDateFilter,
        searchEnquiryFromDate,
        searchEnquiryToDate,
        selectedFollowupDateFilter,
        searchFollowupFromDate,
        searchFollowupToDate,
        assignedUser,
        selectedSources,
        selectedLocations,
        selectedSourceIds,
        selectedLocationIds,
      },
    });
  }

  const classes = useTableStyles();
  const menuClasses = useStyles();
  const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

  return {
    totalCount,
    offset,
    selectedPageFilter,
    classes,
    menuClasses,
    homeData,
    pageCount,
    dataLoaded,
    setDataLoaded,
    handleClickTale,
    handlePageClick,
    enquiryStats,
    selectStatus,
    assignedUser,
    searchItem,
    enquiryDateFilter,
    handleSearchItemChange,
    handleAssignedToChange,
    selectStatus,
    filterLoaded,
    selectedEnquiryDateFilter,
    setSelectedEnquiryDateFilter,
    selectedFollowupDateFilter,
    setSelectedFollowupDateFilter,
    searchFollowupToDate,
    setSearchFollowupToDate,
    searchFollowupFromDate,
    setSearchFollowupFromDate,
    searchEnquiryFromDate,
    setSearchEnquiryFromDate,
    searchEnquiryToDate,
    setSearchEnquiryToDate,
    setReload,
    refreshTable,
    followUpDateFilter,
    selectedStatuses,
    handleSelectStatuses,
    statusData,
    selectedStatusIds,
    countryList,
    setCountryList,
    handleSelectTreatments,
    selectedCountryList,
    setSelectedCountryList,
    treatmentData,
    selectedTreatmentIds,
    selectedTreatments,
    searchPhone,
    handleSearchPhone,
    searchEnquiryId,
    handleSearchEnquiryId,
    sourceData,
    selectedSourceIds,
    selectedSources,
    setSelectedSources,
    handleSelectSources,
    locationData,
    selectedLocationIds,
    selectedLocations,
    setSelectedLocations,
    handleSelectLocations,

    selectedPageFilter,
    setSelectedPageFilter,
    pageFilters,
    getDropdownButtonLabel,
    setSelectedTreatments,
    setSelectedStatuses,
    perPage,
    clearAllFilter,
    handleCountry,
    userData,
    type,
    handleSelectTypes,
    selectedTypes,
    setSelectedTypes,
    getDropdownButtonLabel2,
    loadAllNotifications,
    notificationCount,
    notificationData,
    setNotificationData,
    isloader,
    fetchMoreData,
    setNotificationCount,
    hasMore,
    notificationAllCount,
    setNotificationAllCount,
    clearAllFilter,
    handleClickOpen,
    setActiveData,
    activeData,
    postAssignTo
  };
};
export default useKareTripEnquiry;
