import React, { useState } from "react";

import "../../styles.css";
import useLogin from "./useLogin.logic";
import homeIcon from '../../assets/kareflow-home.svg';

const Login = () => {
  const state = useLogin();
  return (
    <div style={{background: 'linear-gradient(to bottom, #bdfef6, #fafbce)',height:"100vh"}}>
      <p className="padding">
        <br />
        <br />

        <div className="imgcontainer">
          <img
            src={homeIcon}
            alt="Pic"
            className="pic"
          />
          <br></br>
          {/* <p className="mt-2">
            <b>
              <h2>Login</h2>
            </b>
          </p> */}
          {state.error ? (
            <div>
              <p className="col-red">Invalid Login </p>
            </div>
          ) : null}
        </div>

        <input
          className="size"
          type="text"
          value={state.username}
          onChange={(e) => {
            state.setUsername(e.target.value);
          }}
          id="username"
          placeholder="Email Id"
        />
        <br />
        <input
          className="size"
          type="password"
          value={state.password}
          onChange={(e) => {
            state.setPassword(e.target.value);
          }}
          placeholder="Password"
        />
        <br />
        <br />

        <button onClick={() => state.loginUser()}>
          <h2>Login</h2>
        </button>
      </p>
    </div>
  );
};
export default Login;
