import axios from "axios";
import "../../Styles/Common.css";
import "../../Styles/sideBar.css";
import "../../Styles/Pagination.css";
import { useHistory } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import * as ReactBootstrap from "react-bootstrap";
import ListItem from "@material-ui/core/ListItem";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as appConstants from "../../util/AppConstants";
import ListItemText from "@material-ui/core/ListItemText";
import { getEnquiryStatistics } from "../../services/mykare/enquiryService";

const usePartnerShip = () => {
  let history = useHistory();
  const [perPage] = useState(20);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [arrivalCount, setArrivalCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [enquiryCount, setEnquiryCount] = useState(null);
  const [partnershipCount, setPartnershipCount] = useState(null);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      marginTop: 10,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));

  const [homeData, setHomeData] = useState([]);

  const [pageData, setPageData] = useState(null);
  const [allData, setAllData] = useState(null);

  const [selectType, setSelectType] = useState({
    list: ["all"],
    selected: "",
  });
  const handleSelectTypeChange = (e) => {
    setSelectType((s) => ({ ...s, selected: e.target.value }));
  };

  const [selectStatus, setSelectStatus] = useState({
    list: ["all"],
    selected: "",
  });
  const handleSelectStatusChange = (e) => {
    setSelectStatus((s) => ({ ...s, selected: e.target.value }));
  };
  const [searchKeyName, setSearchKeyName] = useState("");
  const [searchKeyNumber, setSearchKeyNumber] = useState("");

  const [searchItem, setSearchItem] = useState("");
  const handleSearchItemChange = (e) => setSearchItem(e.target.value);

  const getTotalEnquiries = async () => {
    try {
      getEnquiryStatistics()
        .then((res) => {
          //setEnquiryCount(res.data.count);
          //setPartnershipCount(res.data.totalPartnerShip);
        });
    } catch (error) {

    }
  };

  useEffect(() => {
    setDataLoaded(false);
    getTotalEnquiries();
    getData();
  }, [offset]);

  useEffect(() => {
    if (!pageData && !allData) return;

    setPageData((s) =>
      allData.filter((item) => {
        return !!item?.patientName
          ?.toLowerCase()
          .match(searchItem.toLowerCase());
      })
    );
  }, [searchItem]);

  useEffect(() => {
    if (!!pageData) {
      const postData = pageData.map((data, index) => (
        <div
          key={index}
          className="row pointer scroll"
          onClick={() => handleClickTale(data)}
        >
          <div className="container col-2 m-0">
            <ListItem>
              <ListItemText primary={`#${data.id}`} />
            </ListItem>
          </div>
          <div className="container col-2 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>

            <ListItem>
              <ListItemText primary={` ${data.name}`} />
            </ListItem>
          </div>
          <div class="container col-2 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={` ${data.email}`} />
            </ListItem>
          </div>
          <div class="container col-2 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={`${data.phoneNumber}`} />
            </ListItem>
          </div>
          <div class="container col-2 m-0">
            <Typography
              className={classes.dividerFullWidth}
              color="textSecondary"
              display="block"
              variant="caption"
            ></Typography>
            <ListItem>
              <ListItemText primary={`${data.type}`} />
            </ListItem>
          </div>

          <div class="container col-2 m-0 capitalize">
            <p
              className={data.enquiryStatus === "replied" ? "text-2" : "text-1"}
            >
              {data.enquiryStatus}
            </p>
          </div>
          <hr />
        </div>
      ));
      setHomeData(postData);
    }
  }, [pageData]);

  const getData = async () => {
    axios
      .get(
        appConstants.APP_URL + "/partner/enquiry/all",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {

        const data = res.data;
        console.log("ressssssssss", data)
        setPageCount(Math.ceil(res.data.count / perPage));

        // const typeList = Array.from(new Set(data.map(({ type }) => type)));
        // const statusList = Array.from(
        //   new Set(data.map(({ status }) => status))
        // );
        // setSelectType((s) => ({ ...s, list: [...s.list, ...typeList] }));
        // setSelectStatus((s) => ({ ...s, list: [...s.list, ...statusList] }));
        setPageData(data?.partners);
        setAllData(data?.partners);
        setEnquiryCount(data?.count);
        setPartnershipCount(data?.count);
      })
      .catch((error) => {

      })
      .finally(() => {
        setDataLoaded(true);
      });
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage);
  };

  function handleClickTale(enquiryData) {
    history.push({
      pathname: "/mykarePartnerShipDetail",
      state: { enquiryDetails: enquiryData },
    });
  }


  const handleRefresh = () => {
    getData();
    getTotalEnquiries();
  }

  const classes = useStyles();

  return {
    offset,
    classes,
    homeData,
    useStyles,

    pageCount,
    dataLoaded,
    totalCount,
    pendingCount,
    arrivalCount,
    setDataLoaded,
    enquiryCount,
    partnershipCount,
    handlePageClick,
    handleClickTale,
    appointmentCount,
    handleRefresh,
    searchItem,
    handleSearchItemChange,
    selectType,
    handleSelectTypeChange,
    selectStatus,
    handleSelectStatusChange,
  };
};
export default usePartnerShip;
