import React from "react";
import { checkPermission } from "../../util/permissionUtils";
import Roles from "./RolesAndPermission/Roles";
import Sidebar from "../Sidebar";
import Form from "./UserRole";
import useSideBarSettings from "./useSideBarSettings";
function SidebarSettings() {
  const state = useSideBarSettings();
  return (
    <>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ margin: "20px" }}>
          {checkPermission("ROLE_MANAGEMENT_TAB") && (
            <button
              style={{
                width: "auto",
                padding: `10px 10px`,
                marginLeft: 10,
                cursor: "pointer",
                backgroundColor: state.activeTab.isUserRole
                  ? "#06c6a2"
                  : "lightgray",
              }}
              onClick={() => state.handleTab(0)}
            >
              User Role
            </button>
          )}
          {checkPermission("ROLE_MANAGEMENT_TAB") && (
            <button
              style={{
                width: "10rem",
                padding: `10px 10px`,
                marginLeft: 10,
                cursor: "pointer",
                backgroundColor: state.activeTab.isAssignRoleActive
                  ? "#06c6a2"
                  : "lightgray",
              }}
              onClick={() => state.handleTab(1)}
            >
              Role Management
            </button>
          )}
          <div>
            {state.activeTab.isUserRole && <Form />}
            {state.activeTab.isAssignRoleActive && <Roles state={state} />}
          </div>
        </div>
      </div>
    </>
  );
}
export default SidebarSettings;
