import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { updateDoctorDoc, uploadDoctorDoc } from "../../../../services/mykare/doctorsService";
import { getAllDocuments } from "../../../../services/mykare/documentService";
import { saveEventActivityLogs } from "../../../../util/EventActivity";

function useDoctorInnerLogic(callBackFn) {
  const [agreementFiles, setAgreementFiles] = useState(null);
  const [savedDoctorDoc, setSavedDoctorDoc] = useState([]);
  const [doctorId, setDoctorId] = useState(null);
  const [editableMode, setEditableMode] = useState(true);
  const [data, setData] = useState(null);
  const routeParams = useLocation();
  const history = useHistory();
  useEffect(() => {
    const data = routeParams.state.data;
    if (data) {
      setData(data);
      setDoctorId(data.id);
    } else {
      back();
    }
  }, []);

  function back() {
    history.push({
      pathname: "/doctors",
    });
  }

  useEffect(() => {
    loadDoctorDoc();
  }, [doctorId]);

  const addDoctorDocs = async () => {
    let formData = new FormData();
    // agreementFiles.forEach((file) => {
    formData.append("doctorDoc", agreementFiles);
    // });
    formData.append("doctorId", doctorId);

    if(savedDoctorDoc.length !== 0) {
      updateDoctorDoc(formData)
      .then((data) => {
      alert("Updated Successfully");
      setAgreementFiles(null);
      saveEventActivityLogs('Update Doctor Document')
      loadDoctorDoc();
      callBackFn(data.data);
    })
    .catch((err) => {

    });
    } else {
      uploadDoctorDoc(formData)
        .then((data) => {
        alert("Upload Successfully");
        setAgreementFiles(null);
        saveEventActivityLogs('Upload Doctor Document')
        loadDoctorDoc();
        callBackFn(data.data);
      })
      .catch((err) => {

      });
    }
  };

  const handleAgreementFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setAgreementFiles(file);
  };

  const loadDoctorDoc = () => {
    getAllDocuments(doctorId, "DOCTOR_DOCUMENT", "doctor_agreement")
      .then((res) => {
        if (res.data) {
          setSavedDoctorDoc(res.data);
        }
      })
      .catch((error) => {

      });
  };

  const removeItem = () => {
    setAgreementFiles(null);
  };

  return {
    removeItem,
    loadDoctorDoc,
    handleAgreementFile,
    addDoctorDocs,
    savedDoctorDoc,
    editableMode,
    setEditableMode,
    agreementFiles,
    data,
    setData,
    back
  };
}

export default useDoctorInnerLogic;
