
import { Dropdown } from 'react-bootstrap';
import useAddDoctor from './useAddDoctor.logic';
import { MenuProps } from "../../../components/multi-select";
import { ListItemText, MenuItem, Select, Checkbox, ListItemIcon } from '@material-ui/core';

function AddDoctor({ onClicked }) {

  const state = useAddDoctor();

  return (
    <div>
      <hr />
      <div className="row popup-view" >
        <div className="col-lg-2">
          <p style={{ textAlign: "left", fontWeight: 700, marginBottom: 0, width: 200 }} >
            Name
          </p>
          <input
            onChange={(e) => state.setDoctorName(e.target.value)}
            style={{ height: 38, borderRadius: 5, border: `1px solid black` }}
          />
        </div>
        <div className="col-lg-2">
          <p style={{ textAlign: "left", fontWeight: 700, marginBottom: 0, width: 200 }} >
            Experience
          </p>
          <input
            onChange={(e) => state.setExperience(e.target.value)}
            style={{ height: 38, borderRadius: 5, border: `1px solid black` }}
          />
        </div>
        <div className="col-lg-2">
          <p style={{ textAlign: "left", fontWeight: 700, marginBottom: 0, width: 200 }} >
            Speciality
          </p>
          <Select
            labelId="mutiple-select-label"
            multiple
            value={state.selectedDoctorSpecialities}
            onChange={state.handleChangeDoctorSpecialities}
            renderValue={(value) => value.map(item => item.name).join(", ")}
            MenuProps={MenuProps}
            style={{ width: 250, height: 40, borderRadius: 5, paddingLeft: 10, border: `1px solid gray`, }}
          >
            <MenuItem
              key="0"
              value={{ id: 0, value: 'All' }}
              classes={{
                root: state.isAllSelectedDoctorSpecialities ? state.classes.selectedAll : ""
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: state.classes.indeterminateColor }}
                  checked={state.isAllSelectedDoctorSpecialities}
                  indeterminate={
                    state.selectedDoctorSpecialities.length > 0 && state.selectedDoctorSpecialities.length < state.specialityDoctorData.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: state.classes.selectAllText }}
                primary="Select All"
              />
            </MenuItem>
            {state.specialityDoctorData.map((speciality) => (
              <MenuItem key={speciality.id} value={speciality}>
                <ListItemIcon>
                  <Checkbox checked={state.doctorSpecialityIds.indexOf(speciality.id) > -1} />
                </ListItemIcon>
                <ListItemText primary={speciality.name} />
              </MenuItem>
            ))}
          </Select>

        </div>
        <div className="col-lg-2">
          <p style={{ textAlign: "left", fontWeight: 700, marginBottom: 0, width: 200 }} >
            Qualification
          </p>
          <Select
            labelId="mutiple-select-label"
            multiple
            value={state.selectedQualifications}
            onChange={state.handleChangeQualifications}
            renderValue={(value) => value.map(item => item.name).join(", ")}
            MenuProps={MenuProps}
            style={{ width: 250, height: 40, borderRadius: 5, paddingLeft: 10, border: `1px solid gray`, marginLeft: 10 }}
          >
            <MenuItem
              key="0"
              value={{ id: 0, value: 'All' }}
              classes={{
                root: state.isAllSelectedQualification ? state.classes.selectedAll : ""
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: state.classes.indeterminateColor }}
                  checked={state.isAllSelectedQualification}
                  indeterminate={
                    state.selectedQualifications.length > 0 && state.selectedQualifications.length < state.qualificationData.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: state.classes.selectAllText }}
                primary="Select All"
              />
            </MenuItem>
            {state.qualificationData.map((qualification) => (
              <MenuItem key={qualification.id} value={qualification}>
                <ListItemIcon>
                  <Checkbox checked={state.qualificationIds.indexOf(qualification.id) > -1} />
                </ListItemIcon>
                <ListItemText primary={qualification.name} />
              </MenuItem>
            ))}
          </Select>

        </div>
        <div className="col-lg-2">
          <p style={{ textAlign: "left", fontWeight: 700, marginBottom: 0, width: 200 }} >
            Center
          </p>
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {state.selectedCenter ? state.selectedCenter.name : "Center"}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: 100, overflowY: "scroll", overflowX: "hidden", width: 200 }} >
              {state.centerData.map((value, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{ width: 200, maxHeight: 200 }}
                    onClick={() => state.setSelectedCenter(value)
                    }
                  >
                    {value.name}

                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-lg-1">
          <button style={{ padding: 10, width: 80, marginTop: 30 }} onClick={() => {
            onClicked(false)
            state.setSelectedCenter('')
          }
          } >Cancel</button>
        </div>
        <div className="col-lg-1">
          <button
            onClick={() => state.addNewDoctor()}
            style={{ padding: 10, width: 80, marginTop: 30 }}>Save</button>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default AddDoctor;