import React from "react";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import "react-datepicker/dist/react-datepicker.css";
import useOpDetail from "./useOpDetail.logic";
import { CustomMenu } from "../../../../components/AddLead/AddLead";

function OpDetail(props) {
  const {
    userTrxnId,
    detailId,
    centerData,
    doctorData,
    callBackFn,
    treatment,
    handleHide,
    patientDetail,
    locationData,
  } = props;
  const state = useOpDetail({
    userTrxnId,
    detailId,
    centerData,
    doctorData,
    locationData,
    callBackFn,
    treatment,
    handleHide,
    patientDetail,
  });
  return (
    <div>
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <div className="col-lg-2 col-12">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Consultation Mode
          </p>
          {state.editableMode ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.opDetails.consultationMode
                  ? state.opDetails.consultationMode
                  : state.consultationModes[0]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {state.consultationModes.map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{ width: 200 }}
                      onClick={() =>
                        state.setOpDetails((s) => ({
                          ...s,
                          consultationMode: value,
                        }))
                      }
                    >
                      {value}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <span>{state.opDetails.consultationMode}</span>
          )}
        </div>

        <div className="col-lg-2 col-12">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Fee
          </p>
          {state.editableMode ? (
            <input
              placeholder="Fee"
              value={state.opDetails.fee}
              style={{
                width: 200,
                height: 40,
                borderRadius: 5,
                paddingLeft: 10,
                border: `1px solid gray`,
              }}
              onChange={(e) =>
                state.setOpDetails((s) => ({ ...s, fee: e.target.value }))
              }
            />
          ) : (
            <span>{state.opDetails.fee}</span>
          )}
        </div>
        <div className="col-lg-2 col-12">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Dr.Name
          </p>
          {state.editableMode ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                placeholder="Dr Name"
                style={{
                  width: 200,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.selectedDoctor?.name}
              </Dropdown.Toggle>
              <Dropdown.Menu
                as={CustomMenu}
                style={{
                  height: 200,
                  width: 260,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {state.doctorData &&
                  state.doctorData.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200 }}
                        onClick={() => state.handleDoctor(item)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <span>{state.selectedDoctor?.name}</span>
          )}
        </div>
        <div className="col-lg-2 col-12">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Hospital Location
          </p>
          {state.editableMode ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.selectedLocation?.name || "Select Location"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                as={CustomMenu}
                style={{
                  height: 200,
                  width: 260,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {state.locationData &&
                  state.locationData.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200 }}
                        onClick={() => state.handleLocation(item)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <span>{state.selectedLocation?.name || "Not Selceted"}</span>
          )}
        </div>
        <div className="col-lg-2 col-12">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Hospital
          </p>
          {state.editableMode ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.selectedCenter?.name}
              </Dropdown.Toggle>
              <Dropdown.Menu
                as={CustomMenu}
                style={{
                  height: 200,
                  width: 260,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {state.centerData &&
                  state.centerData.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200 }}
                        onClick={() => state.handleCenter(item)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <span>{state.selectedCenter?.name}</span>
          )}
        </div>
        <div className="col-2">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Status
          </p>
          {state.editableMode ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.opDetails.opStatus ? state.opDetails.opStatus : "Status"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {state.opStatus.map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{ width: 200 }}
                      onClick={(e) =>
                        state.setOpDetails((s) => ({
                          ...s,
                          opStatus: value,
                        }))
                      }
                    >
                      {value}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <span>{state.opDetails.opStatus}</span>
          )}
        </div>
      </div>
      <div
        className="row"
        style={{
          didplay: "flex",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <div className="col-lg-2">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0}}>
            {state.opDetails.opStatus ? `${state.opDetails.opStatus} DATE` : "Date Time"}
          </p>
          {state.editableMode ? (
            <DatePicker
              className="myDatePicker"
              selected={
                state.opDetails.dateTime ? state.opDetails.dateTime : new Date()
              }
              onChange={(date) =>
                state.setOpDetails((s) => ({ ...s, dateTime: date }))
              }
              // onChange={(date) => state.setFollowUpDate(date)}
              minDate={new Date()}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          ) : (
            <span>
              {state.opDetails.dateTime
                ? state.opDetails.dateTime.toLocaleString()
                : null}
            </span>
          )}
        </div>
        <div className="col-2">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 8 }}>
            Treatment
          </p>
          {state.editableMode ? (
            <input
              placeholder="Treatment"
              value={state.opDetails.treatment}
              style={{
                width: 200,
                height: 40,
                borderRadius: 5,
                paddingLeft: 10,
                border: `1px solid gray`,
              }}
              onChange={(e) =>
                state.setOpDetails((s) => ({ ...s, treatment: e.target.value }))
              }
            />
          ) : (
            <span>{state.opDetails.treatment}</span>
          )}
        </div>
        <div className="col-2">
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Remarks
          </p>
          {state.editableMode ? (
            <textarea
              className="comments-section"
              rows={5}
              placeholder="Remarks"
              value={state.opDetails.remarks}
              onChange={(e) =>
                state.setOpDetails((s) => ({ ...s, remarks: e.target.value }))
              }
            />
          ) : (
            <span>{state.opDetails.remarks}</span>
          )}
        </div>
        <div className="col-lg-2"></div>
        <div className="col-lg-2">
          {state.editableMode ? (
            <button
              onClick={() => state.saveAppointmentDetails()}
              style={{ width: 70, padding: 8 }}
            >
              Save
            </button>
          ) : (
            <button
              style={{ width: 70, padding: 8 }}
              onClick={() => state.setEditableMode(true)}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default OpDetail;
