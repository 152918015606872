import React from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Vil from "../Components/Vil/Vil";
import Smo from "../Components/Smo/Smo";
import Arrival from "../Components/Arrival/Arrival";
import Diagnostic from "../Components/Diagnostic/Diagnostic";
import Appointment from "../Components/Appointment/Appointment";

function TabPanel({ value, index, children }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function AddPatientTabs({ patientDetail,activeTab}) {

  const tabs = [
    { label: "ARRIVAL", content: <Arrival patientDetail={patientDetail} activeTab={activeTab}/> },
    { label: "APPOINTMENT", content: <Appointment patientDetail={patientDetail} activeTab={activeTab}/> },
    { label: "VIL", content: <Vil patientDetail={patientDetail} activeTab={activeTab}/> },
    { label: "DIAGNOSTIC", content: <Diagnostic patientDetail={patientDetail} activeTab={activeTab}/> },
    { label: "SMO", content: <Smo patientDetail={patientDetail} activeTab={activeTab}/> },
  ];


  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if(activeTab){
      const activeTabIndex = tabs.findIndex((tab) => tab.label.toUpperCase() === activeTab.toUpperCase());
      if (activeTabIndex !== -1) {
        setValue(activeTabIndex);
      }
    }
  }, [activeTab, tabs,value]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {activeTab === "" &&(<Tabs
        onChange={handleChange}
        value={value}
        indicatorColor="none"
        aria-label="Tabs where each tab needs to be selected manually"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            sx={{
              width: "140px",
              backgroundColor: value === index ? "#6DA9E4" : "gray",
              "&.Mui-selected": {
                textDecoration: "none",
                color: "white",
              },
              color: "#D8D8D8",
              marginLeft: index !== 0 ? "10px" : "26px",
            }}
          />
        ))}
      </Tabs>)}
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
}
export default AddPatientTabs;
