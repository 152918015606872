import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ServiceForm from "../ServiceForm/ServiceForm";

const SideDrawer = ({ isOpen, width, children, setIsOpen }) => {
  const list = () => (
    <Box
      sx={{
        width: width || 540,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
      role="presentation"
    >
      <ServiceForm/>
    </Box>
  );

  return (
    <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
      {list()}
    </Drawer>
  );
};

export default SideDrawer;
