import { useState, useRef, useEffect } from "react";
import {
  getAllDocuments,
  uploadDocuments,
} from "../../../services/mykare/documentService";
import {
  PATIENT_IDENTITY_DOCUMENT,
  PRESCRIPTION_DOCUMENT,
} from "../../../util/documentUtil";
import * as ReactBootstrap from "react-bootstrap";
import { getDocuments } from "../../../services/karebuddy/activityService";

function usePatientsDoc({ userTrxnId }) {
  const [selectedDocType, setSelectedDocType] = useState({
    id: 0,
    name: "Select",
    category: "Select",
    context: "SELECT",
  });
  const inputDocsRef = useRef(null);

  const [aadhaarFiles, setAadhaarFiles] = useState([]);
  const [panCardFiles, setPanCardFiles] = useState([]);
  const [prescriptionFiles, setPrescriptionFiles] = useState([]);
  const [dischargeBillFiles, setDischargeBillFiles] = useState([]);
  const [dischargeSummaryFiles, setDischargeSummaryFiles] = useState([]);
  const [drivingLicenseFiles, setDrivingLicenseFiles] = useState([]);
  const [idCardFiles, setIdCardFiles] = useState([]);
  const [patientsDocs, setPatientsDocs] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [docTypes, setDocTypes] = useState([
    {
      id: 1,
      name: "Aadhaar",
      context: PATIENT_IDENTITY_DOCUMENT,
      category: "aadhaar_card",
      setFun: setAadhaarFiles,
    },
    {
      id: 2,
      name: "Pancard",
      context: PATIENT_IDENTITY_DOCUMENT,
      category: "pan_card",
      setFun: setPanCardFiles,
    },
    {
      id: 3,
      name: "Driving Licence",

      context: PATIENT_IDENTITY_DOCUMENT,
      category: "driving_license",
      setFun: setDrivingLicenseFiles,
    },
    {
      id: 4,
      name: "Id Card",
      context: PATIENT_IDENTITY_DOCUMENT,
      category: "id_card",
      setFun: setIdCardFiles,
    },
    {
      id: 5,
      name: "Prescription",
      context: PRESCRIPTION_DOCUMENT,
      category: "prescription",
      setFun: setPrescriptionFiles,
    },
    {
      id: 6,
      name: "Discharge Bill",

      context: "KARE_BUDDY_DISCHARGE_BILL",
      category: "kare_buddy_discharge_bill",
      setFun: setDischargeBillFiles,
    },
    {
      id: 7,
      name: "Discharge Summary",
      context: "KARE_BUDDY_DISCHARGE_SUMMARY",
      category: "kare_buddy_discharge_summary",
      setFun: setDischargeSummaryFiles,
    },
  ]);

  const handleSelectedDoc = (docType) => {
    setSelectedDocType(docType);
    setPatientsDocs([]);
  };
  const handleDocsItems = (event) => {
    event.stopPropagation();
    event.preventDefault();

    let file = event.target.files[0];

    if (!file) {
      return;
    }
    file.id = Math.floor(Math.random() * Math.pow(10, 16));
    setPatientsDocs((state) => [...state, file]);
  };

  const removeDocsItems = (id) => {
    const filesClone = patientsDocs.filter((file) => file.id !== id);
    setPatientsDocs(filesClone);
  };

  useEffect(() => {
    if (userTrxnId) {
      loadDocuments(
        userTrxnId,
        PATIENT_IDENTITY_DOCUMENT,
        "aadhaar_card",
        setAadhaarFiles
      );
      loadDocuments(
        userTrxnId,
        PATIENT_IDENTITY_DOCUMENT,
        "pan_card",
        setPanCardFiles
      );
      loadDocuments(
        userTrxnId,
        PATIENT_IDENTITY_DOCUMENT,
        "driving_license",
        setDrivingLicenseFiles
      );
      loadDocuments(
        userTrxnId,
        PATIENT_IDENTITY_DOCUMENT,
        "id_card",
        setIdCardFiles
      );
      loadDocuments(
        userTrxnId,
        PRESCRIPTION_DOCUMENT,
        "prescription",
        setPrescriptionFiles
      );
      loadDocuments(
        userTrxnId,
        "KARE_BUDDY_DISCHARGE_BILL",
        "kare_buddy_discharge_bill",
        setDischargeBillFiles
      );
      loadDocuments(
        userTrxnId,
        "KARE_BUDDY_DISCHARGE_SUMMARY",
        "kare_buddy_discharge_summary",
        setDischargeSummaryFiles
      );
    }
  }, []);

  const loadDocuments = (contextId, context, category, setFiles) => {
    getAllDocuments(contextId, context, category)
      .then((res) => {
        setFiles(res.data);
      })
      .catch((error) => {})
      .finally(() => {
        setDataLoaded(true);
      });
  };

  const uploadPatientDocs = () => {
    // const context =
    //   selectedDocType.id == 5
    //     ? PRESCRIPTION_DOCUMENT
    //     : PATIENT_IDENTITY_DOCUMENT;
    let formData = new FormData();
    patientsDocs.forEach((doc) => {
      formData.append("documents", doc);
    });
    formData.append("context", selectedDocType.context);
    formData.append("category", selectedDocType.category);
    formData.append("contextId", userTrxnId);

    uploadDocuments(formData)
      .then((data) => {
        alert("Uploaded Successfully");
        setSelectedDocType({
          id: 0,
          name: "Select",
          category: "Select",
          context: "Select",
        });
        setPatientsDocs([]);
        loadDocuments(
          userTrxnId,
          selectedDocType.context,
          selectedDocType.category,
          selectedDocType.setFun
        );
      })
      .catch((err) => {});
  };

  return {
    docTypes,
    selectedDocType,
    inputDocsRef,
    patientsDocs,
    idCardFiles,
    drivingLicenseFiles,
    aadhaarFiles,
    panCardFiles,
    prescriptionFiles,
    handleDocsItems,
    removeDocsItems,
    handleSelectedDoc,
    uploadPatientDocs,
    dataLoaded,
    dischargeBillFiles,
    dischargeSummaryFiles,
  };
}

export default usePatientsDoc;
