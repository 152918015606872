import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { CustomMenu } from "../../../components/AddLead/AddLead";
import useCreateCampaign from "./useCreateCampaign";

export default function CreateCampaign() {
  const state = useCreateCampaign();
 
  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        <div style={{ width: "100%", height: "100px" }}>
          <div style={{ marginTop: 20 }}>
            <span style={{ marginLeft: "14px", fontWeight: 700 }}>
              Campaign Type
            </span>
            <Dropdown style={{ marginLeft: "15px" }}>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 210,
                  height: 36,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state?.selectEnquiryType || "Enquiry Type"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {state?.enquiryType.map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{ width: 208 }}
                      onClick={() => state?.setSelectedEnquiryType(value?.type)}
                    >
                      {value?.type}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {state?.selectEnquiryType === "Enquiry Id" && (
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%", marginTop: "10px", display: "flex" }}>
            <div style={{ width: "40%" }}>
              <span style={{ marginLeft: "14px", fontWeight: 700 }}>
                Add Enquiry Id's
              </span>
              <textarea
                style={{ width: "100%", marginLeft: "15px", height: "200px" }}
                onChange={state?.handleChange}
              ></textarea>
            </div>
            <div style={{ width: "55%", marginLeft: "30px"}}>
              <div
                className="row"
                style={{
                  width: "100%",

                  height: "70px",
                }}
              >
                <div className="col-3" style={{marginTop:"-10px"}}>
                  <div>
                    <b>Choose Template</b>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 280,
                        height: 36,
                        border: `1px solid gray`,
                        display: "flex",

                        marginRight: 20,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {state.selectedTemplate
                        ? state.selectedTemplate.name
                        : "Template"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      as={CustomMenu}
                      style={{
                        maxHeight: 200,
                        overflowY: "scroll",
                        overflowX: "hidden",
                        width: 280,
                      }}
                    >
                      {state?.template?.map((value, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 200, maxHeight: 200 }}
                            onClick={() => state.setselectedTemplate(value)}
                          >
                            {value.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
 
                <div className="col-3" style={{marginTop:"-10px"}}>
                  <b style={{ marginLeft: "20px" }}>Scheduled Date</b>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #00997c",
                      width: 250,
                      height: 40,
                      borderRadius: 5,
                      marginLeft: 20,
                      marginTop: 6,
                    }}
                  >
                    <DatePicker
                      className="mySearchEnquiryUpDatePicker dateTimePicker"
                      selected={
                        state.scheduledDate ? state.scheduledDate : new Date()
                      }
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      onChange={(date) => {
                        state.setScheduledDate(date);
                      }}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <button
                    style={{
                      width: 80,
                      height: 50,
                      marginLeft: 50,
                      cursor: "pointer",
                    }}
                    onClick={state?.handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  marginTop:"20px"
                }}
               >
                {state?.selectedTemplate?.value && (
                  <div className="col-8">
                    <b>Preview</b>
                    <div
                      style={{
                        border: "2px solid black",
                        borderRadius: 10,
                        marginRight: 30,
                        padding: "10px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <p style={{ fontSize: "14px" }}>
                        {state?.selectedTemplate?.value}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {state?.selectEnquiryType === "Enquiry Filter" && (
        <div>
          {" "}
          <div
            style={{
              width: "95%",
              display: "flex",
              flexWrap: "wrap",
              margin: "10px",
            }}
          >
            <div className="col">
              <b>Choose Template</b>
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 280,
                    height: 36,
                    border: `1px solid gray`,
                    display: "flex",
                    marginLeft: 10,
                    marginRight: 20,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {state.selectedTemplate
                    ? state.selectedTemplate.name
                    : "Template"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  as={CustomMenu}
                  style={{
                    maxHeight: 200,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    width: 260,
                  }}
                >
                  {state?.template?.map((value, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200, maxHeight: 200 }}
                        onClick={() => state.setselectedTemplate(value)}
                      >
                        {value.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col  m-0 ">
              <b>Location</b>
              <div
                style={{
                  width: 140,
                  height: 35,
                  backgroundColor: "white",
                  border: "1px solid  #00997c",
                  borderRadius: 5,
                  marginTop: 8,
                }}
              >
                <ReactMultiselectCheckboxes
                  options={state.locations}
                  getDropdownButtonLabel={state.getDropdownButtonLabel}
                  value={state.selectedLocation}
                  onChange={(value, event) =>
                    state.handleSelectedLocation(value, event)
                  }
                  setState={state.setSelectedLocation}
                />
              </div>
            </div>

            <div className="col  m-0 ">
              <b>Treatment</b>
              <div
                style={{
                  width: 140,
                  height: 35,
                  backgroundColor: "white",
                  border: "1px solid  #00997c",
                  borderRadius: 5,
                  marginTop: 8,
                }}
              >
                <ReactMultiselectCheckboxes
                  options={state.treatments}
                  getDropdownButtonLabel={state.getDropdownButtonLabel}
                  value={state.selectedTreatment}
                  onChange={(value, event) =>
                    state.handleSelectedTreatment(value, event)
                  }
                  setState={state.setselectedTreatment}
                />
              </div>
            </div>

            <div className="col  m-0 ">
              <b>Source</b>
              <div
                style={{
                  width: 140,
                  height: 35,
                  backgroundColor: "white",
                  border: "1px solid  #00997c",
                  borderRadius: 5,
                  marginTop: 8,
                }}
              >
                <ReactMultiselectCheckboxes
                  options={state.sourceData}
                  getDropdownButtonLabel={state.getDropdownButtonLabel}
                  value={state.selectedSources}
                  onChange={(value, event) =>
                    state.handleSelectSources(value, event)
                  }
                  setState={state.setSelectedSources}
                />
              </div>
            </div>

            <div className="col  m-0 ">
              <b>Status</b>
              <div
                style={{
                  width: 140,
                  height: 35,
                  backgroundColor: "white",
                  border: "1px solid  #00997c",
                  borderRadius: 5,
                  marginTop: 8,
                }}
              >
                <ReactMultiselectCheckboxes
                  options={state.statusLists}
                  getDropdownButtonLabel={state.getDropdownButtonLabel}
                  value={state.selectedCampStatus}
                  onChange={(value, event) =>
                    state.handleSelecCampStatus(value, event)
                  }
                  setState={state.setCampStatus}
                />
              </div>
            </div>

            <div className="col m-0">
              <b>Enquiry Date</b>
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 140,
                    height: 36,
                    border: `1px solid #00997c`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {state.assignedData.chooseDate
                    ? state.assignedData.chooseDate
                    : "All"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {state.enquiryDateFilter.map((value, index) => {
                    return (
                      <>
                        <Dropdown.Item
                          key={index}
                          href="#"
                          style={{
                            width: 160,
                          }}
                          onClick={() => {
                            state.setAssignedData((s) => ({
                              ...s,
                              chooseDate: value,
                            }));
                          }}
                        >
                          {value}
                        </Dropdown.Item>
                      </>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>

              {state.assignedData.chooseDate === "Choose Date" ? (
                <div style={{ display: "flex" }}>
                  <div className="col">
                    <b>From</b>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 160,
                        height: 40,
                        borderRadius: 5,
                        marginBottom: 7,
                        marginRight: 5,
                      }}
                    >
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchEnquiryFromDate
                            ? state.searchEnquiryFromDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchEnquiryFromDate(date);
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <b>To</b>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 160,
                        height: 40,
                        borderRadius: 5,
                      }}
                    >
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchEnquiryToDate
                            ? state.searchEnquiryToDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchEnquiryToDate(date);
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="col-2">
              <b>Scheduled Date</b>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #00997c",
                  width: 250,
                  height: 40,
                  borderRadius: 5,
                  marginBottom: 7,
                  marginRight: 5,
                }}
              >
                <DatePicker
                  className="mySearchEnquiryUpDatePicker dateTimePicker"
                  selected={
                    state.scheduledDate ? state.scheduledDate : new Date()
                  }
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  onChange={(date) => {
                    state.setScheduledDate(date);
                  }}
                />
              </div>
            </div>

            <div className="col mt-2">
              <button
                style={{
                  width: "100%",
                  height: 50,
                }}
                onClick={() => state.createCampign()}
              >
                Schedule Message
              </button>
            </div>
          </div>
          {state?.selectedTemplate?.value && (
            <div className="col-6">
              <b>Preview</b>
              <div
                style={{
                  border: "2px solid black",
                  borderRadius: 10,
                  marginRight: 30,
                  padding: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <p style={{ fontSize: "14px" }}>
                  {state?.selectedTemplate?.value}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
