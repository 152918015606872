import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { createACall, makeACall } from "../../services/mykare/callService";
import { Autocomplete, Button, Grid, Typography, styled } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function MakeCall({ setOpen, open }) {
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const reason = [
    "none",
    "Hospital Related",
    "Doctor Related",
    "Insurance Related",
    "Other",
  ];
  const handleClose = () => {
    setOpen(false);
  };

  const createCall = () => {
    const callReason =
      selectedReason === "Other" ? otherReason : selectedReason;
    const postData = {
      callReason,
      toNumber: contactNumber,
    };
    if (callReason && contactNumber) {
      createACall(postData)
        .then((res) => {
          if (res.status === 200) {
            alert("Please wait, your call is connecting!");
            setOpen(false);
          }
        })
        .catch((err) => {
          alert("Error Occuered, please contact for Tech support");
          throw err;
        });
    } else {
      alert("Please Fill All Fields");
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, color: "blue" }}
          id="customized-dialog-title"
        >
          Make a call
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 8,
            width: 80,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ width: 600 }}>
          <DialogTitle
            sx={{ m: 0, p: 0, fontSize: 16 }}
            id="customized-dialog-title"
          >
            Add Number
          </DialogTitle>
          <TextField
            placeholder="Enter Number"
            type="tel"
            InputProps={{
              sx: {
                border: "none",
                outline: "none",
                height: 40,
              },
            }}
            onChange={(e) => setContactNumber(e.target.value)}
          />
          <DialogTitle
            sx={{ m: 0, p: 0, fontSize: 16, marginTop: 2 }}
            id="customized-dialog-title"
          >
            Add Reason
          </DialogTitle>

          <div>
            <Autocomplete
              id="reason-select"
              options={reason}
              value={selectedReason}
              onChange={(event, newValue) => {
                setSelectedReason(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Reason..." />
              )}
            />
          </div>

          {selectedReason === "Other" && (
            <Grid sx={{ width: "100%", paddingY: "10px" }}>
              <Typography sx={{ textAlign: "left" }}>Other Reason</Typography>
              <TextField
                placeholder="Reason"
                type="text"
                sx={{ width: "100%" }}
                onChange={(e) => setOtherReason(e.target.value)}
              />
            </Grid>
          )}
        </DialogContent>
        <DialogActions style={{ margin: 10 }}>
          <Button
            autoFocus
            onClick={createCall}
            sx={{
              width: 120,
              backgroundColor: "green",
              color: "white",
              "&:hover": {
                backgroundColor: "green",
              },
            }}
          >
            Make Call
          </Button>
          <Button
            autoFocus
            onClick={handleClose}
            sx={{
              width: 80,
              backgroundColor: "red",
              color: "white",
              "&:hover": {
                backgroundColor: "red",
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
