import "./DownloadPanel.css";
import React from "react";
import useDownloadPanel from "./useDownloadPanel.logic";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";

function DownloadPanel({
  selectedSelectMonth = false,
  ipReport = false,
  surgeryReport = false,
  reportPanel,
  surgeryPanel,
  ...props
}) {
  const state = useDownloadPanel(props);
  return (
    <div>
      <hr />
      <div className="row popup-view">
        <div className="col-6">
          <div className="row">
            {!(reportPanel === true || surgeryPanel === true) && (
              <div className="col-3">
                Type<br></br>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 180,
                      height: 36,
                      border: `1px solid #00997c`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.selectedType.name || "select"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      maxHeight: 200,
                      overflowY: "scroll",
                      overflowX: "hidden",
                      width: 150,
                    }}
                  >
                    {state.reportTypes.map((val, index) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 160 }}
                            onClick={() => {
                              state.handleLeadType(val);
                            }}
                          >
                            {val.name}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {([1, 2, 4].includes(state.selectedType.id) ||
              reportPanel ||
              surgeryPanel) && (
              <>
                <div className="col-3">
                  From<br></br>
                  <DatePicker
                    className="mySearchEnquiryUpDatePicker"
                    selected={state.fromDate ? state.fromDate : new Date()}
                    onChange={(date) => {
                      state.setFromDate(date);
                    }}
                    maxDate={new Date()}
                    showTimeSelect={state.selectedType.id === 2}
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
                <div className="col-3">
                  To<br></br>
                  <DatePicker
                    className="mySearchEnquiryUpDatePicker"
                    selected={state.toDate ? state.toDate : new Date()}
                    onChange={(date) => {
                      state.setToDate(date);
                    }}
                    maxDate={new Date()}
                    showTimeSelect={state.selectedType.id === 2}
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
              </>
            )}
            {[1, 4].includes(state.selectedType.id) && (
              <div className="col-3">
                Assign To<br></br>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 160,
                      height: 36,
                      border: `1px solid #00997c`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.assignedUser.selected
                      ? state.assignedUser.selected
                      : "All"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      maxHeight: 200,
                      overflowY: "scroll",
                      overflowX: "hidden",
                      width: 150,
                    }}
                  >
                    {state.assignedUser.list.map(({ name, code }, index) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 160 }}
                            onClick={() => {
                              state.handleAssignedToChange(code, name);
                            }}
                          >
                            {name}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {state.selectedType.id === 3 && (
              <div className="col-3">
                Select a month<br></br>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 160,
                      height: 36,
                      border: `1px solid #00997c`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.selectedSelectMonth
                      ? state.selectedSelectMonth
                      : "All"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      maxHeight: 200,
                      overflowY: "scroll",
                      overflowX: "hidden",
                      width: 150,
                    }}
                  >
                    {state.selectMonth.map((value, index) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={index}
                            href="#"
                            style={{ width: 160 }}
                            onClick={() => {
                              state.setSelectedSelectMonth(value);
                            }}
                          >
                            {value}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-1">
          <button
            style={{ padding: 12, width: 80 }}
            onClick={() => props.onClicked(false)}
          >
            Cancel
          </button>
        </div>

        <div className="col-lg-1">
          <button
            onClick={() => {
              if (reportPanel === true) {
                state.downloadIpOpReport();
              } else if (surgeryPanel === true) {
                state.downloadSurgerySuggestedReport();
              } else if (selectedSelectMonth) {
                state.getDowloaddoc();
              } else {
                state.downloadReport();
              }
            }}
            style={{ padding: 12, width: 100 }}
          >
            Download
          </button>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default DownloadPanel;
