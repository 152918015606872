import "../AddLead/AddPatient.css";
import React from "react";
import usePatientAdd from "./useAddPatient.logic";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

function AddPatient(props) {
  const state = usePatientAdd(props);
  return (
    <div>
      <hr />
      <div className="row popup-view">
        <>
          <div className="col-md-2">
            <input
              className=""
              onChange={state.handleNameChange}
              placeholder="Name"
              style={{
                height: 55,
                borderRadius: 5,
                border: `1px solid #c4c4c4`,
                display: "flex",
                width: 220,
                paddingLeft: 10,
              }}
            />
          </div>
          {state.nameErr && (
            <span style={{ color: "red" }}>Only letters allowed</span>
          )}
          <div className="col-md-2">
            <input
              placeholder="Age"
              onChange={(e) => state.setAge(e.target.value)}
              style={{
                height: 55,
                borderRadius: 5,
                border: `1px solid #c4c4c4`,
                display: "flex",
                width: 220,
                paddingLeft: 10,
              }}
            />
          </div>
          <div className="col-md-2">
            <input
              placeholder="Email"
              onChange={(e) => state.setEmail(e.target.value)}
              style={{
                height: 55,
                borderRadius: 5,
                border: `1px solid #c4c4c4`,
                display: "flex",
                width: 220,
                paddingLeft: 10,
              }}
            />
          </div>
          <div className="col-lg-2">
            <div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={state?.gender}
                sx={{ width: 220 }}
                onChange={(e, val) => state?.setSelectedGender(val?.label)}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Gender" />
                )}
              />
            </div>
          </div>
          <div className="col-lg-2">
            <div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={state?.countryList}
                sx={{
                  width: 220,
                }}
                onChange={state?.getSelectCountryDetails}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Country" />
                )}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div
              style={{
                height: 55,
                borderRadius: 5,
                border: `1px solid #c4c4c4`,
                display: "flex",
                width: 220,
              }}
            >
              <div
                style={{
                  alignSelf: "center",
                  fontWeight: 600,
                  marginLeft: 10,
                }}
              ></div>
              <input
                type="tel"
                onChange={state.handlePhoneNumberChange}
                placeholder="Phone Number"
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 20,
                  border: `1px solid white`,
                  outline: "none",
                }}
              />
            </div>
          </div>
          {state?.selectCountry?.label === "India" && (
            <div className="col-md-2">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={state?.stateList?.states}
                getOptionLabel={(option) => option?.name}
                onChange={(e, val) => state?.setSelectedSatate(val?.name)}
                sx={{
                  width: 220,
                  marginTop: "15px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="State" />
                )}
              />
            </div>
          )}

          <div className="col-md-2">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={state?.allAgents}
              getOptionLabel={(option) => option.name}
              sx={{
                width: 220,
                marginTop: "15px",
              }}
              onChange={(e, val) => state?.setAssignedAgentId(val?.id)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Assign To" />
              )}
            />
          </div>
        </>
        <>
          <div className="col-md-1">
            <button
              onClick={() => state.savePatientDetail()}
              style={{ padding: 10, width: 80 }}
            >
              Save
            </button>
          </div>

          <div className="col-md-1">
            <button
              style={{ padding: 10, width: 80 }}
              onClick={() => props.onClicked(false)}
            >
              Cancel
            </button>
          </div>
        </>
      </div>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default AddPatient;
export const CustomMenu = React.forwardRef(
  (
    { children, style, className, "aria-labelledby": labeledBy, searchStyle },
    ref
  ) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          style={searchStyle}
          autoFocus
          className={!searchStyle && "mx-3 my-2 w-auto"}
          placeholder="Search"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children?.toArray(children)?.filter(
            (child) =>
              !value || child.props?.children?.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);
