import React from "react";
import usePatientsDoc from "./usePatientsDoc.logic";
import Dropdown from "react-bootstrap/Dropdown";
import downloadDocument from "../../../util/documentUtil";
import Link from "@material-ui/core/Link";
import { isInsuranceAgent } from "../../../store/DataStore";
import * as ReactBootstrap from "react-bootstrap";

function PatientsDoc({ userTrxnId }) {
  const state = usePatientsDoc({ userTrxnId });

  const margin = {
    marginLeft: 10,
  };

  return !state.dataLoaded ? (
    <div
      style={{
        position: "absolute",
        bottom: "25%",
      }}
      className="spinner"
    >
      <ReactBootstrap.Spinner animation="grow light" />
    </div>
  ) : (
    <div>
      <div>
        <div>
          <div className="row">
            <div className="col-lg-6">
              <div style={{ display: "flex" }}>
                <p className="mb-0 ml-1 mt-3 text-start">Aadhaar :</p>
                {state.aadhaarFiles &&
                  state.aadhaarFiles.map((doc, index) => (
                    <div className="row">
                      <div class="col-6">
                        <div className="ed-txt-2 text-start"> </div>
                      </div>
                      <div>
                        <b>
                          <Link
                            style={margin}
                            className="pointer"
                            onClick={() =>
                              downloadDocument(
                                doc.documentType,
                                doc.document,
                                doc.documentName
                              )
                            }
                          >
                            {`Aadhaar ${index + 1}`}
                          </Link>
                        </b>
                      </div>
                    </div>
                  ))}
              </div>

              <div style={{ display: "flex" }}>
                <p className="mb-0 mt-3 text-start">Pan Card: </p>
                {state.panCardFiles &&
                  state.panCardFiles.map((doc, index) => {
                    return (
                      <div className="row">
                        <div class="col-6">
                          <div className="ed-txt-2 text-start"></div>
                        </div>
                        <div>
                          <b>
                            <Link
                              style={margin}
                              className="pointer"
                              onClick={() =>
                                downloadDocument(
                                  doc.documentType,
                                  doc.document,
                                  doc.documentName
                                )
                              }
                            >
                              {`Pan Card ${index + 1}`}
                            </Link>
                          </b>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-6">
              <div style={{ display: "flex" }}>
                <p className="mb-0 mt-3 text-start">Driving License: </p>
                {state.drivingLicenseFiles &&
                  state.drivingLicenseFiles.map((doc, index) => (
                    <div className="row">
                      <div class="col-6">
                        <div className="ed-txt-2 text-start"></div>
                      </div>
                      <div>
                        <b>
                          <Link
                            style={margin}
                            className="pointer"
                            onClick={() =>
                              downloadDocument(
                                doc.documentType,
                                doc.document,
                                doc.documentName
                              )
                            }
                          >
                            {`Driving License ${index + 1}`}
                          </Link>
                        </b>
                      </div>
                    </div>
                  ))}
              </div>

              <div style={{ display: "flex" }}>
                <p className="mb-0 mt-3 text-start">Id Card: </p>
                {state.idCardFiles &&
                  state.idCardFiles.map((doc, index) => (
                    <div className="row">
                      <div class="col-6">
                        <div className="ed-txt-2 text-start"></div>
                      </div>
                      <div>
                        <b>
                          <Link
                            style={margin}
                            className="pointer"
                            onClick={() =>
                              downloadDocument(
                                doc.documentType,
                                doc.document,
                                doc.documentName
                              )
                            }
                          >
                            {`Id Card ${index + 1}`}
                          </Link>
                        </b>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-6">
              <div style={{ display: "flex" }}>
                <p className="mb-0 mt-3 text-start">Prescription: </p>
                {state.prescriptionFiles &&
                  state.prescriptionFiles.map((doc, index) => (
                    <div className="row">
                      <div class="col-6">
                        <div className="ed-txt-2 text-start"></div>
                      </div>
                      <div>
                        <b>
                          <Link
                            style={margin}
                            className="pointer"
                            onClick={() =>
                              downloadDocument(
                                doc.documentType,
                                doc.document,
                                doc.documentName
                              )
                            }
                          >
                            {`Prescription ${index + 1}`}
                          </Link>
                        </b>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-6">
              <div style={{ display: "flex" }}>
                <p className="mb-0 mt-3 text-start">Discharge Summary: </p>
                {state.dischargeSummaryFiles &&
                  state.dischargeSummaryFiles.map((doc, index) => (
                    <div className="row">
                      <div class="col-6">
                        <div className="ed-txt-2 text-start"></div>
                      </div>
                      <div>
                        <b>
                          <Link
                            style={margin}
                            className="pointer"
                            onClick={() =>
                              downloadDocument(
                                doc.documentType,
                                doc.document,
                                doc.documentName
                              )
                            }
                          >
                            {`Discharge Summary ${index + 1}`}
                          </Link>
                        </b>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-6">
              <div style={{ display: "flex" }}>
                <p className="mb-0 mt-3 text-start">Discharge Bill: </p>
                {state.dischargeBillFiles &&
                  state.dischargeBillFiles.map((doc, index) => (
                    <div className="row">
                      <div class="col-6">
                        <div className="ed-txt-2 text-start"></div>
                      </div>
                      <div>
                        <b>
                          <Link
                            style={margin}
                            className="pointer"
                            onClick={() =>
                              downloadDocument(
                                doc.documentType,
                                doc.document,
                                doc.documentName
                              )
                            }
                          >
                            {`Discharge Bill ${index + 1}`}
                          </Link>
                        </b>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      {!isInsuranceAgent() && (
        <div className="row">
          <div className="col-12">
            <div style={{ marginTop: 10 }}>
              <p
                style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}
              >
                Choose Document Type
              </p>
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 200,
                    border: `1px solid gray`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "0px",
                  }}
                >
                  {state.selectedDocType
                    ? state.selectedDocType.name
                    : "Select"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {state?.docTypes.map((doc, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200 }}
                        onClick={() => state.handleSelectedDoc(doc)}
                      >
                        {doc.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {state.selectedDocType.id != 0 && (
              <div className="row" style={{ marginTop: 20 }}>
                <div className="col-10">
                  <label
                    for="documents"
                    class="btn"
                    style={{
                      width: 180,
                      backgroundColor: "#06c6a2",
                      color: "white",
                    }}
                  >
                    Upload {state.selectedDocType.name}
                  </label>
                  <input
                    id="documents"
                    style={{
                      width: 100,
                      cursor: "pointer ",
                      display: "none",
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingBottom: 3,
                      paddingTop: 3,
                    }}
                    type="file"
                    ref={state.inputDocsRef}
                    onChange={state.handleDocsItems.bind(this)}
                  />

                  {state.patientsDocs.map((item, index) => {
                    return (
                      <>
                        <span
                          style={{
                            border: "1px solid gray",
                            marginLeft: 10,
                            cursor: "pointer ",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10,
                            paddingTop: 5,
                            backgroundColor: "#e5e5e5",
                          }}
                          onClick={() => state.removeDocsItems(item.id)}
                        >
                          {item.name}
                        </span>
                        <span
                          style={{
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingBottom: 10,
                            paddingTop: 5,
                            backgroundColor: "#06c6a2",
                            border: "1px solid gray",
                            color: "white",
                            cursor: "pointer ",
                          }}
                          onClick={() => state.removeDocsItems(item.id)}
                        >
                          X
                        </span>
                      </>
                    );
                  })}
                </div>

                <div className="col-2">
                  <div>
                    <button
                      style={{ width: 70, padding: 8 }}
                      onClick={() => state.uploadPatientDocs()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PatientsDoc;
