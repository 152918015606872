import axios from "axios";
import { getAuthHeader, getUserCode, getUserName } from "../../store/DataStore";
import { APP_URL } from "../../util/AppConstants";

export const saveLoanDetailApi = async (postData) => {

    postData.addedBy = getUserName();
    postData.addedByCode = getUserCode();
    const headers = {
        Authorization: getAuthHeader(),
    };
    const response = await axios.post(`${APP_URL}/mykare/appointment/saveLoanDetails`, postData, {
        headers,
    });
    return response;
};


export const getLoanDetailApi = async (loanDetailId) => {
    const headers = {
        Authorization: getAuthHeader(),
    };
    const response = await axios.get(`${APP_URL}/mykare/appointment/getLoanDetails/${loanDetailId}`,{
        headers,
    });
    return response;
};