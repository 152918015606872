import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { feedbackCall } from "../../../services/mykare/callService";
import { getAllKareBuddy } from "../../../services/mykare/AppointmentService";
import { getAllExoPhoneNumbers } from "../../../services/mykare/dataService";

function useAppointmentInner(data) {
  let history = useHistory();
  const [location, setLocation] = useState(useLocation());
  const [assignedAgent, setAssignedAgent] = useState();
  const [agents, setAgents] = useState([]);
  const [selectedAssigned, setSelectedAssigned] = useState("");
  const [resAgentCode, setResAgentCode] = useState("");
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedAssignedToIds, setSelectedAssignedToIds] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedSourceIds, setSelectedSourceIds] = useState([0]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([0]);
  const [searchAppointmentFromDate, setSearchAppointmentFromDate] =
    useState(null);
  const [searchAppointmentToDate, setSearchAppointmentToDate] = useState(null);
  const [selectedAppointmentDateFilter, setSelectedAppointmentDateFilter] =
    useState(location?.state?.selectedAppointmentDateFilter);
  const [type, setType] = useState("IP");
  const [reload, setReload] = useState(false);
  const [exoNumber, setExoNumber] = useState(null);
  const [exoPhone, setExoPhone] = useState([]);

  useEffect(() => {
    setResAgentCode(data?.assignedName);
  }, [data]);

  useEffect(() => {
    loadAllExophoneNumbers();
    getAgentData();
  }, []);

  useEffect(() => {

    setSelectedAssignedTo(location.state?.selectedAssignedTo);
    setSelectedAssignedToIds(location.state?.selectedAssignedToIds);
    setSelectedStatuses(location.state?.selectedStatus);
    setSelectedSources(location.state?.selectedSources);
    setSelectedSourceIds(location.state?.selectedSourceIds);
    setSelectedTreatments(location.state?.selectedTreatments);
    setSelectedTreatmentIds(location.state?.selectedTreatmentIds);
    setSearchAppointmentFromDate(location.state.searchAppointmentFromDate);
    setSearchAppointmentToDate(location.state.searchAppointmentToDate);
    setType(location.state?.type);

  }, [location]);

  function back() {
    const path = location?.state?.pathname
    ? location?.state?.pathname
    : "/ipOp";
    history.push({
      pathname:path,
      state: {
        selectedAssignedTo,
        selectedAssignedToIds,
        selectedAppointmentDateFilter,
        searchAppointmentFromDate,
        searchAppointmentToDate,
        selectedStatuses,
        selectedSources,
        selectedSourceIds,
        selectedTreatments,
        selectedTreatmentIds,
        type,
        reload,
      },
    });
  }

  const handleToAssigned = (assignTo) => {
    setAssignedAgent(assignTo);
  };

  const getAgentData = async () => {
    const response = await getAllKareBuddy();
    if (response.status === 200) {
      if (response.data) {
        setAgents(response.data);
      }
    }
  };

  useEffect(() => {
    if (!!resAgentCode && agents.length > 0) {
      const assignedName = agents
        .filter((item) => item.code === resAgentCode)
        .map((item) => item)[0];
      setSelectedAssigned(assignedName);
    }
  }, [resAgentCode, agents]);

  const handlePhoneumber = (value) => {
    setExoNumber(value);
  };

  const loadAllExophoneNumbers = async () => {
    const response = await getAllExoPhoneNumbers();
    if (response.status == 200) {
      if (response.data) {
        setExoPhone(response.data);
      }
    }
  };

  const feedbackCallToUser = (phoneType) => {
    const bridgePhoneId = exoNumber?.id || exoPhone[0]?.id;
    feedbackCall(data.enquiryId, phoneType, bridgePhoneId)
      .then((res) => {
        alert("Please wait, your call is connecting!");
      })
      .catch((err) => {
        alert("Error Occuered, please contact for Tech support");
      });
  };

  return {
    back,
    agents,
    exoPhone,
    exoNumber,
    setAgents,
    setExoNumber,
    assignedAgent,
    selectedAssigned,
    handleToAssigned,
    setAssignedAgent,
    handlePhoneumber,
    feedbackCallToUser,
    setSelectedAssigned,
  };
}
export default useAppointmentInner;
