import { makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { insuranceStatusColor, statusColorFinder } from "../../../util/statusColorFinder";
import Sidebar from "../../Sidebar";

export default function InsuranceInnerPage() {
  let history = useHistory();

  function back() {
    history.push({
      pathname: "/insurance",
    });
  }


  const data = history.location.state.enquiryDetails;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));
  const classes = useStyles();



  return (
    <div className="d-flex">
      <Sidebar />
      <div className={classes.root}>
        <button className="width1" onClick={() => back()}>
          BACK
        </button>
        <hr />
        <div className="row">
          <div class="col-md-1">
            <p className="text-start">Enquiry Date</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.enquiryDate.split('T')[0]}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Patient Name</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.patientName}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Phone Number</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.phoneNumber}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Assigned To</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.assignToName}`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">TPA</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.tpa
                }`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Doctor Name</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.doctorName
                }`}</div>
            </p>
          </div>
          <div class="col-md-2">
            <p className="text-start">Hospital Name</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.hospitalName
                }`}</div>
            </p>
          </div>
          <div class="col-md-1">
            <p className="text-start">Treatment</p>
            <p className="ed-txt-1 text-start">
              <div className="textColor">{`${data?.treatmentName
                }`}</div>
            </p>
          </div>
          <hr />
          <div className="row">
            {data.resource && (
              <div class="col-md-1">
                <p className="text-start">Lead Origin</p>
                <p className="ed-txt-1 text-start">
                  <div className="textColor">{`${data?.resource}`}</div>
                </p>
              </div>
            )}
            <div class="col-md-1">
              <p className="text-start">City</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{`${data?.location}`}</div>
              </p>
            </div>
            <div class="col-md-2">
              <p className="text-start">Insurance Company</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{`${data?.insuranceCompany
                  }`}</div>
              </p>
            </div>
            <div class="col-md-1">
              <p className="text-start">Initial Approval Amount</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{`${data?.initialApprovalAmount
                  }`}</div>
              </p>
            </div>
            <div class="col-md-1">
              <p className="text-start">Final Approval Amount</p>
              <p className="ed-txt-1 text-start">
                <div className="textColor">{`${data?.finalApprovalAmount
                  }`}</div>
              </p>
            </div>
            <div className="col-md-1 capitalize">
              <p className="text-start ">Insurance Status</p>
              <p
                className={insuranceStatusColor(data.insuranceStatus)}
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                {data.insuranceStatus ? data.insuranceStatus : ""}
              </p>
            </div>
            <div className="col-md-1 capitalize">
              <p className="text-start ">Payment Type</p>
              <p
                className="textColor"
                style={{ textAlign: "left" }}
              >
                {data.paymentType ? data.paymentType : ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
