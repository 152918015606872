import "./KaretripLead.css";
import React from "react";
import useLead from "./useKaretripLead.logic";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { checkPermission } from "../../../util/permissionUtils";

function KaretripAddLead(props) {
  const state = useLead(props);
  return (
    <div>
      <hr />
      <div className="row popup-view">
        {/* Enquiry type */}
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {state.selectedEnquiryType
                ? state.selectedEnquiryType
                : "Enquiry Type"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 200,
              }}
            >
              {state.enquiryType.map((value, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{ width: 200, maxHeight: 200 }}
                    onClick={() => state.setSelectedEnquiryType(value)}
                  >
                    {value}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {/* Enquiry type */}

        {(state.selectedEnquiryType === "NEW" ||
          state.selectedEnquiryType === "REFERRAL") && (
          <>
            <div className="col-md-2">
              <input
                className=""
                onChange={state.handleNameChange}
                placeholder="Name"
                style={{
                  height: 38,
                  borderRadius: 5,
                  border: `1px solid black`,
                }}
              />
            </div>
            {state.nameErr && (
              <span style={{ color: "red" }}>Only letters allowed</span>
            )}

            {/* {country} */}
            <div className="col-md-2">
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 200,
                    height: 36,
                    border: `1px solid gray`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {state.selectedCountry ? state.selectedCountry : "India"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  as={CustomMenu}
                  style={{
                    maxHeight: 200,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    width: 250,
                  }}
                >
                  {state.country.map((value, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200, maxHeight: 200 }}
                        onClick={() => {
                          state.setSelectedCountry(value.name);
                          state.setCountryCode(value.code);
                        }}
                      >
                        {value.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {state.selectedEnquiryType === "NEW" && (
              <div className="col-md-2">
                <div
                  style={{
                    height: 40,
                    borderRadius: 5,
                    border: `1px solid black`,
                    display: "flex",
                    width: 220,
                  }}
                >
                  <div
                    style={{
                      alignSelf: "center",
                      fontWeight: 600,
                      marginRight: 10,
                    }}
                  >
                    {state.countryCode ? state.countryCode : "+91"}
                  </div>
                  <input
                    type="tel"
                    onChange={state.handlePhoneNumberChange}
                    placeholder="Phone Number"
                    style={{
                      height: "100%",
                      width: "80%",
                      borderRadius: 20,
                      border: `1px solid white`,
                      outline: "none",
                    }}
                  />
                </div>
                {state.phoneNumberErr && (
                  <div style={{ color: "red" }}>Enter valid phoneNumber</div>
                )}
              </div>
            )}

            {/* type */}
            <div className="col-md-2">
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 200,
                    height: 36,
                    border: `1px solid gray`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {state.selectedType ? state.selectedType : "Type"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    maxHeight: 200,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    width: 200,
                  }}
                >
                  {state.type.map((value, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200, maxHeight: 200 }}
                        onClick={() => state.setSelectedType(value)}
                      >
                        {value}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {/* Location */}
            <div className="col-md-2">
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 200,
                    height: 36,
                    border: `1px solid gray`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {state.selectedLocation
                    ? state.selectedLocation.name
                    : "Location"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  as={CustomMenu}
                  style={{
                    maxHeight: 200,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    width: 260,
                  }}
                >
                  {state.locations.map((value, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200, maxHeight: 200 }}
                        onClick={() => state.setSelectedLocation(value)}
                      >
                        {value.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {/* hospital */}
            {state?.selectedType !== "DOCTOR" && (
              <div className="col-md-2">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 200,
                      height: 36,
                      border: `1px solid gray`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.selectedHospital
                      ? state.selectedHospital.name
                      : "Hospital"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    as={CustomMenu}
                    style={{
                      maxHeight: 200,
                      overflowY: "scroll",
                      overflowX: "hidden",
                      width: 260,
                    }}
                  >
                    {state.hospitals?.map((value, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          href="#"
                          style={{ width: 200, maxHeight: 200 }}
                          onClick={() => state.setSelectedHospital(value)}
                        >
                          {value.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {/* Doctor */}
            {state?.selectedType !== "HOSPITAL" && (
              <div className="col-md-2">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 200,
                      height: 36,
                      border: `1px solid gray`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.selectedDoctor
                      ? state.selectedDoctor.name
                      : "Doctor"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    as={CustomMenu}
                    style={{
                      maxHeight: 200,
                      overflowY: "scroll",
                      overflowX: "hidden",
                      width: 260,
                    }}
                  >
                    {state.doctors.map((value, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          href="#"
                          style={{ width: 200, maxHeight: 200 }}
                          onClick={() => state.setSelectedDoctor(value)}
                        >
                          {value.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {/* treatment */}
            <div className="col-md-2">
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 200,
                    height: 36,
                    border: `1px solid gray`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {state.selectedTreatment
                    ? state.selectedTreatment.name
                    : "Treatment"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  as={CustomMenu}
                  style={{
                    maxHeight: 200,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    width: 260,
                  }}
                >
                  {state.treatments.map((value, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200, maxHeight: 200 }}
                        onClick={() => state.setselectedTreatment(value)}
                      >
                        {value.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {/* Source */}
            <div className="col-md-2">
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 200,
                    height: 36,
                    border: `1px solid gray`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {state.selectedSource ? state.selectedSource.name : "Source"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  as={CustomMenu}
                  style={{
                    height: 200,
                    width: 260,
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {state.sources.map((value, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200, maxHeight: 200 }}
                        onClick={() => state.setSelectedSource(value)}
                      >
                        {value.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {checkPermission("LEADS_ASSIGNED_FIELD") && (
              <div className="col-md-2">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="none"
                    style={{
                      width: 200,
                      height: 36,
                      border: `1px solid gray`,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {state.selectedAssignedUser?.name || "Assign"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    as={CustomMenu}
                    style={{
                      height: 200,
                      width: 260,
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {state?.assignedUser?.map((value, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          href="#"
                          style={{ width: 200, maxHeight: 200 }}
                          onClick={() => state.setSelectedAssignedUser(value)}
                        >
                          {value.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          
          </>
        )}
        <>
          {state.selectedEnquiryType && state.selectedEnquiryType !== "NEW" && (
            <div className="col-md-2">
              <input
                className=""
                onChange={(e) => state.setOldEnquiryId(e.target.value)}
                value={state.oldEnquiryId}
                placeholder="Old Enquiry Id"
                style={{
                  height: 38,
                  borderRadius: 5,
                  border: `1px solid black`,
                }}
              />
            </div>
          )}
        </>

        {/* } */}
        {/* {checkPermission("LEADS_ASSIGNED_FIELD") && (
          <div className="col-md-2">
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {!!state.assignedAgent ? state.assignedAgent : "Assign To"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {state.userData.map((value, id) => {
                  return (
                    <Dropdown.Item
                      key={id}
                      href="#"
                      style={{ width: 200 }}
                      onClick={() =>
                        state.handleToAssigned(value.name, value.code)
                      }
                    >
                      {value.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )} */}

        {state.selectedEnquiryType && (
          <>
            <div className="col-md-1">
              <button
                style={{ padding: 10, width: 80 }}
                onClick={() => props.onClicked(false)}
              >
                Cancel
              </button>
            </div>

            <div className="col-md-1">
              <button
                onClick={() => state.saveKaretripEnquiryDetails()}
                style={{ padding: 10, width: 80 }}
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
      <br></br>
      <hr />
    </div>
  );
}

export default KaretripAddLead;

export const CustomMenu = React.forwardRef(
  (
    { children, style, className, "aria-labelledby": labeledBy, searchStyle },
    ref
  ) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          style={searchStyle}
          autoFocus
          className={!searchStyle && "mx-3 my-2 w-auto"}
          placeholder="Search"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children?.toArray(children)?.filter(
            (child) =>
              !value || child.props?.children?.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);
